<template>
  <v-dialog scrollable v-model="dialogComputed" transition="dialog-top-transition" width="350">
    <v-card dark tile width="350" height="380">
      <v-card-title>
        <!-- <v-system-bar window class="pl-0"> -->
        <v-img width="40" style="cursor: pointer" :src="require('@/assets/img/Logo-ECOLINEA-solo-maniglia.png')"
          contain></v-img>
        <v-spacer></v-spacer>
        <v-card-title style="margin-top: -1em" class="pb-0 justify-center">
          <h5>Accedi all’area riservata</h5>
        </v-card-title>
        <v-spacer></v-spacer>
        <v-icon @click="chiudi">close</v-icon>
        <!-- </v-system-bar> -->
      </v-card-title>

      <v-card-text class="pt-0 mt-5">
        <v-form v-on:submit.prevent="login()">
          <v-text-field label="E-Mail" required v-model="cred.username"
            :rules="[() => cred.username.length > 0 || 'Il campo utente è obbligatorio']" ref="inputMail" autofocus>
          </v-text-field>
          <v-text-field label="Password" required :type="showText ? 'text' : 'password'"
            :append-icon="showText ? 'visibility' : 'visibility_off'" @click:append="showText = !showText"
            v-model="cred.password" :rules="[
              () => cred.password.length > 0 || 'Il campo password è obbligatorio',
            ]"></v-text-field>

          <v-btn block type="submit" :disabled="attesaLogin" :loading="attesaLogin" color="primary" class="mt-4">
            Accedi
          </v-btn>
        </v-form>
      </v-card-text>

      <v-card-actions class="mb-1">
        <v-container class="pa-0">
          <v-row>
            <v-col class="pa-0">
              <v-btn text @click="recuperaPwd = true">Hai dimenticato la password?
              </v-btn>
            </v-col>
          </v-row>
        </v-container>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>


export default {
  props: {
    dialog: { type: Boolean },
  },
  data() {
    return {
      cred: { username: "", password: "" },
      showText: false,
      attesaLogin: false,
    };
  },

  methods: {
    async login() {
      // Validazione
      if (this.cred.username == "" || this.cred.password == "") {
        return;
      }

      // Attesa  login
      this.attesaLogin = true;

      try {
        // Login
        await this.$store.dispatch("auth/login", this.cred);

        //this.$store.commit("app/setCliente", null);

        // Chiudo la dialog
        this.chiudi();

        // Aggiorno i dati cliente
        this.$emit("userInfo");

      } catch (error) {
        this.$toast.alert(this.$i2kFunctions.getErrorMsg(error));
      }

      // Fine attesa
      this.attesaLogin = false;

      return false;
    },

    chiudi() {
      this.$emit("chiudiDialog");
    },

    isTelefono() {
      return window.innerWidth;
      //return this.$i2kFunctions.isSmartphone();
    },
  },

  mounted() { },

  computed: {
    dialogComputed: {
      get() {
        return this.dialog;
      },
      set(value) {
        this.closeDialog();
      },
    },
  },
};
</script>
