var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"mt-2",staticStyle:{"background-color":"white"}},[(_vm.noProdotti == true)?_c('h1',{staticStyle:{"text-align":"center","margin-top":"0%","margin-right":"25%"}},[_vm._v(" La ricerca non ha prodotto risultati ")]):_vm._e(),(_vm.loading === true)?_c('v-progress-circular',{staticClass:"mt-2",staticStyle:{"width":"100%"},attrs:{"size":50,"active":_vm.loading,"indeterminate":_vm.loading,"absolute":"","bottom":"","color":"#E40520"}}):_vm._e(),_c('v-row',{style:({
      'margin-left': _vm.stileRowProdotti.marginLeft,
      'margin-top': _vm.stileRowProdotti.marginTop,
      'margin-right': _vm.stileRowProdotti.marginRight,
      width: _vm.stileRowProdotti.width,
    })},_vm._l((_vm.getDati),function(item,i){return _c('v-col',{key:i,staticClass:"d-flex child-flex ml-10 mt-10",style:({
        width: _vm.stileRowProdotti.width,
        'margin-bottom': _vm.stileRowProdotti.marginBottom,
      })},[_c('div',{staticClass:"card",style:({
          'margin-bottom': _vm.stileCardProdotti.marginBottom,
          'margin-left': _vm.stileCardProdotti.marginLeft,
          height: _vm.stileCardProdotti.height,
        })},[_c('div',{staticClass:"face face1"},[_c('div',{staticClass:"content"},[(item.tipo != 'Maniglioni' && item.tipo != 'Alzanti')?_c('img',{style:({
                'min-width': _vm.stileImgProdottoNoManiglioneAlzante.minWidth,
                height: _vm.stileImgProdottoNoManiglioneAlzante.height,
                cursor: _vm.stileImgProdottoNoManiglioneAlzante.cursor,
                'background-color': _vm.stileImgProdottoNoManiglioneAlzante.backgroundColor,
                'margin-left': _vm.stileImgProdottoNoManiglioneAlzante.marginLeft,
                'margin-top': _vm.stileImgProdottoNoManiglioneAlzante.marginTop,
                'margin-bottom': _vm.stileImgProdottoNoManiglioneAlzante.marginBottom,
              }),attrs:{"src":item.url,"lazy-src":item.url,"aspect-ratio":"1"},on:{"click":function($event){return _vm.apri(item)}}}):_c('img',{style:({
                'min-width': _vm.stileImgProdottoSiManiglioneAlzante.minWidth,
                height: _vm.stileImgProdottoSiManiglioneAlzante.height,
                cursor: _vm.stileImgProdottoSiManiglioneAlzante.cursor,
                'background-color': _vm.stileImgProdottoSiManiglioneAlzante.backgroundColor,
                'margin-left': _vm.stileImgProdottoSiManiglioneAlzante.marginLeft,
                'margin-top': _vm.stileImgProdottoSiManiglioneAlzante.marginTop,
              }),attrs:{"src":item.url,"lazy-src":item.url,"aspect-ratio":"1"},on:{"click":function($event){return _vm.apri(item)}}}),(item.tipo != 'Maniglioni' && item.tipo != 'Alzanti')?_c('v-row',{style:([_vm.isTelefono() <= 768 ? {} : { 'margin-top': '-2.5em' }])},[(_vm.isTelefono() <= 2560)?_c('p',{staticClass:"text-h6",staticStyle:{"margin-bottom":"1em"},style:({
                  'margin-top': _vm.stileParagrafoNoManiglioneAlzante.marginTop,
                  'margin-left': _vm.stileParagrafoNoManiglioneAlzante.marginLeft,
                  width: _vm.stileParagrafoNoManiglioneAlzante.width,
                })},[_vm._v(" "+_vm._s(item.nomeProdotto)+" "),(_vm.isTelefono() <= 1540)?_c('v-icon',{staticStyle:{"float":"right"}},[_vm._v("zoom_in")]):_vm._e(),(item.novita == true)?_c('img',{style:({
                    width: _vm.stileIconProdotti.width,
                    height: _vm.stileIconProdotti.height,
                    'margin-top': _vm.stileIconProdotti.marginTop,
                    'margin-left': _vm.stileIconProdotti.marginLeft,
                  }),attrs:{"src":require('@/assets/img/Novità.png')}}):_vm._e(),(item.disponibileProssimamente == true)?_c('img',{style:({
                    width: _vm.stileIconProdotti.width,
                    height: _vm.stileIconProdotti.height,
                    'margin-top': _vm.stileIconProdotti.marginTop,
                    'margin-left': _vm.stileIconProdotti.marginLeft,
                  }),attrs:{"src":require('@/assets/img/DisponibileMaggio23.png')}}):_vm._e(),(item.restyling == true)?_c('img',{style:({
                    width: _vm.stileIconProdotti.width,
                    height: _vm.stileIconProdotti.height,
                    'margin-top': _vm.stileIconProdotti.marginTop,
                    'margin-left': _vm.stileIconProdotti.marginLeft,
                  }),attrs:{"src":require('@/assets/img/Restyling.png')}}):_vm._e(),_c('v-divider',{staticStyle:{"margin-top":"0em"},attrs:{"color":"black"}})],1):_c('p',{staticClass:"text-h4",staticStyle:{"margin-bottom":"1em"},style:({
                  'margin-top': _vm.stileParagrafoNoManiglioneAlzante.marginTop,
                  'margin-left': _vm.stileParagrafoNoManiglioneAlzante.marginLeft,
                  width: _vm.stileParagrafoNoManiglioneAlzante.width,
                })},[_vm._v(" "+_vm._s(item.nomeProdotto)+" "),(_vm.isTelefono() <= 1540)?_c('v-icon',{staticStyle:{"float":"right"}},[_vm._v("zoom_in")]):_vm._e(),(item.novita == true)?_c('img',{style:({
                    width: _vm.stileIconProdotti.width,
                    height: _vm.stileIconProdotti.height,
                    'margin-top': _vm.stileIconProdotti.marginTop,
                    'margin-left': _vm.stileIconProdotti.marginLeft,
                  }),attrs:{"src":require('@/assets/img/Novità.png')}}):_vm._e(),(item.disponibileProssimamente == true)?_c('img',{style:({
                    width: _vm.stileIconProdotti.width,
                    height: _vm.stileIconProdotti.height,
                    'margin-top': _vm.stileIconProdotti.marginTop,
                    'margin-left': _vm.stileIconProdotti.marginLeft,
                  }),attrs:{"src":require('@/assets/img/DisponibileMaggio23.png')}}):_vm._e(),(item.restyling == true)?_c('img',{style:({
                    width: _vm.stileIconProdotti.width,
                    height: _vm.stileIconProdotti.height,
                    'margin-top': _vm.stileIconProdotti.marginTop,
                    'margin-left': _vm.stileIconProdotti.marginLeft,
                  }),attrs:{"src":require('@/assets/img/Restyling.png')}}):_vm._e(),_c('v-divider',{staticStyle:{"margin-top":"0em"},attrs:{"color":"black"}})],1),_c('p',{style:({
                  'margin-top': _vm.stileLineaNoManiglioneAlzante.marginTop,
                  'margin-left': _vm.stileLineaNoManiglioneAlzante.marginLeft,
                  'font-size': _vm.stileLineaNoManiglioneAlzante.fontSize,
                })},[_vm._v(" Linea: "+_vm._s(item.nome)+" ")])]):_c('v-row',[(_vm.isTelefono() <= 2560)?_c('p',{staticClass:"text-h6",staticStyle:{"margin-bottom":"1em"},style:({
                  'margin-left': _vm.stileParagrafoSiManiglioneAlzante.marginLeft,
                  'margin-top': _vm.stileParagrafoSiManiglioneAlzante.marginTop,
                  width: _vm.stileParagrafoSiManiglioneAlzante.width,
                })},[_vm._v(" "+_vm._s(item.nomeProdotto)+" "),(_vm.isTelefono() <= 1540)?_c('v-icon',{staticStyle:{"float":"right"}},[_vm._v("zoom_in")]):_vm._e(),(item.novita == true)?_c('img',{style:({
                    width: _vm.stileIconProdotti.width,
                    height: _vm.stileIconProdotti.height,
                    'margin-top': _vm.stileIconProdotti.marginTop,
                    'margin-left': _vm.stileIconProdotti.marginLeft,
                  }),attrs:{"src":require('@/assets/img/Novità.png')}}):_vm._e(),(item.disponibileProssimamente == true)?_c('img',{style:({
                    width: _vm.stileIconProdotti.width,
                    height: _vm.stileIconProdotti.height,
                    'margin-top': _vm.stileIconProdotti.marginTop,
                    'margin-left': _vm.stileIconProdotti.marginLeft,
                  }),attrs:{"src":require('@/assets/img/DisponibileMaggio23.png')}}):_vm._e(),(item.restyling == true)?_c('img',{style:({
                    width: _vm.stileIconProdotti.width,
                    height: _vm.stileIconProdotti.height,
                    'margin-top': _vm.stileIconProdotti.marginTop,
                    'margin-left': _vm.stileIconProdotti.marginLeft,
                  }),attrs:{"src":require('@/assets/img/Restyling.png')}}):_vm._e(),_c('v-divider',{staticStyle:{"margin-top":"0em"},attrs:{"color":"black"}})],1):_c('p',{staticClass:"text-h4",staticStyle:{"margin-bottom":"1em"},style:({
                  'margin-left': _vm.stileParagrafoSiManiglioneAlzante.marginLeft,
                  'margin-top': _vm.stileParagrafoSiManiglioneAlzante.marginTop,
                  width: _vm.stileParagrafoSiManiglioneAlzante.width,
                })},[_vm._v(" "+_vm._s(item.nomeProdotto)+" "),(_vm.isTelefono() <= 1540)?_c('v-icon',{staticStyle:{"float":"right"}},[_vm._v("zoom_in")]):_vm._e(),(item.novita == true)?_c('img',{style:({
                    width: _vm.stileIconProdotti.width,
                    height: _vm.stileIconProdotti.height,
                    'margin-top': _vm.stileIconProdotti.marginTop,
                    'margin-left': _vm.stileIconProdotti.marginLeft,
                  }),attrs:{"src":require('@/assets/img/Novità.png')}}):_vm._e(),(item.disponibileProssimamente == true)?_c('img',{style:({
                    width: _vm.stileIconProdotti.width,
                    height: _vm.stileIconProdotti.height,
                    'margin-top': _vm.stileIconProdotti.marginTop,
                    'margin-left': _vm.stileIconProdotti.marginLeft,
                  }),attrs:{"src":require('@/assets/img/DisponibileMaggio23.png')}}):_vm._e(),(item.restyling == true)?_c('img',{style:({
                    width: _vm.stileIconProdotti.width,
                    height: _vm.stileIconProdotti.height,
                    'margin-top': _vm.stileIconProdotti.marginTop,
                    'margin-left': _vm.stileIconProdotti.marginLeft,
                  }),attrs:{"src":require('@/assets/img/Restyling.png')}}):_vm._e(),_c('v-divider',{staticStyle:{"margin-top":"0em"},attrs:{"color":"black"}})],1),_c('p',{staticStyle:{"margin-left":"-1.5em"},style:({
                  'margin-top': _vm.stileLineaSiManiglioneAlzante.marginTop,
                  'margin-left': _vm.stileLineaSiManiglioneAlzante.marginLeft,
                  'font-size': _vm.stileLineaSiManiglioneAlzante.fontSize,
                })},[_vm._v(" Linea: "+_vm._s(item.nome)+" ")])])],1)]),(_vm.isTelefono() < 3840)?_c('div',{staticClass:"face face2",on:{"mouseover":function($event){return _vm.showPermanent(item)}}},[_c('div',{staticClass:"content",style:({
              'margin-top': _vm.stileExtationPanel.marginTop,
              height: _vm.stileExtationPanel.height,
              width: _vm.stileExtationPanel.width,
            })},[(item.tipo != 'Maniglioni' && item.tipo != 'Alzanti')?_c('p',{style:({
                'margin-top': _vm.stilePanelNoManiglioneAlzante.marginTop,
                'margin-left': _vm.stilePanelNoManiglioneAlzante.marginLeft,
                'font-size': _vm.stilePanelNoManiglioneAlzante.fontSize,
              })},[_vm._v(" "+_vm._s(item.descrizione)+" ")]):_c('p',{style:({
                'margin-top': _vm.stilePanelSiManiglioneAlzante.marginTop,
                'margin-left': _vm.stilePanelSiManiglioneAlzante.marginLeft,
                'font-size': _vm.stilePanelSiManiglioneAlzante.fontSize,
              })},[_vm._v(" "+_vm._s(item.descrizione)+" ")]),(
                item.tipo != 'Maniglioni' &&
                item.tipo != 'Alzanti' &&
                item.tipo != 'Scorrevoli'
              )?_c('v-row',{style:({
                'min-width': _vm.stileFinitureStandardNoManiglioneAlzante.minWidth,
                'margin-left': _vm.stileFinitureStandardNoManiglioneAlzante.marginLeft,
                'margin-top': _vm.stileFinitureStandardNoManiglioneAlzante.marginTop,
              })},_vm._l((_vm.getDatiFinitureStandard(item)),function(item,i){return _c('v-col',{key:i,staticClass:"d-flex child-flex",staticStyle:{"padding":"0"},style:({
                  'min-width': _vm.stileColFinitureImg.minWidth,
                  'margin-top': _vm.stileColFinitureImg.marginTop,
                }),attrs:{"cols":"2"}},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                var on = ref.on;
                var attrs = ref.attrs;
return [_c('v-img',_vm._g(_vm._b({staticStyle:{"width":"auto","height":"auto","cursor":"pointer","z-index":"10"},attrs:{"src":item.url,"lazy-src":item.url},on:{"click":function($event){return _vm.getDettFiniture(item)}},scopedSlots:_vm._u([{key:"placeholder",fn:function(){return [_c('v-row',{staticClass:"fill-height ma-0",attrs:{"align":"center","justify":"center"}},[_c('v-progress-circular',{attrs:{"indeterminate":"","color":"black lighten-5"}})],1)]},proxy:true}],null,true)},'v-img',attrs,false),on))]}}],null,true)},[_c('span',[_vm._v(_vm._s(item.tipoFinitura))])])],1)}),1):_vm._e(),(
                item.tipo == 'Maniglioni' ||
                (item.tipo == 'Alzanti' && item.tipo != 'Scorrevoli')
              )?_c('v-row',{style:({
                width: _vm.stileFinitureStandardSiManiglioneAlzante.width,
                'margin-left': _vm.stileFinitureStandardSiManiglioneAlzante.marginLeft,
                'margin-top': _vm.stileFinitureStandardSiManiglioneAlzante.marginTop,
              })},_vm._l((_vm.getDatiFinitureStandard(item)),function(item,i){return _c('v-col',{key:i,staticClass:"d-flex child-flex",staticStyle:{"padding":"0"},style:({
                  'min-width': _vm.stileColFinitureImg.minWidth,
                  'margin-top': _vm.stileColFinitureImg.marginTop,
                }),attrs:{"cols":"2"}},[_c('v-card',{staticStyle:{"max-width":"30px"},attrs:{"elevation":"0"}},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                var on = ref.on;
                var attrs = ref.attrs;
return [_c('v-img',_vm._g(_vm._b({staticStyle:{"width":"auto","height":"auto","cursor":"pointer","z-index":"10"},attrs:{"src":item.url,"lazy-src":item.url},on:{"click":function($event){return _vm.getDettFiniture(item)}},scopedSlots:_vm._u([{key:"placeholder",fn:function(){return [_c('v-row',{staticClass:"fill-height ma-0",attrs:{"align":"center","justify":"center"}},[_c('v-progress-circular',{attrs:{"indeterminate":"","color":"black lighten-5"}})],1)]},proxy:true}],null,true)},'v-img',attrs,false),on))]}}],null,true)},[_c('span',[_vm._v(_vm._s(item.tipoFinitura))])])],1)],1)}),1):_vm._e(),(item.tipo == 'Scorrevoli')?_c('v-row',{style:({
                'min-width': _vm.stileFinitureStandardSiScorrevoli.minWidth,
                'margin-left': _vm.stileFinitureStandardSiScorrevoli.marginLeft,
                'margin-top': _vm.stileFinitureStandardSiScorrevoli.marginTop,
              })},_vm._l((_vm.getDatiFinitureStandard(item)),function(item,i){return _c('v-col',{key:i,staticClass:"d-flex child-flex",staticStyle:{"padding":"0"},style:({
                  'min-width': _vm.stileColFinitureImg.minWidth,
                  'margin-top': _vm.stileColFinitureImg.marginTop,
                }),attrs:{"cols":"2"}},[_c('v-card',{staticStyle:{"max-width":"30px"},attrs:{"elevation":"0"}},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                var on = ref.on;
                var attrs = ref.attrs;
return [_c('v-img',_vm._g(_vm._b({staticStyle:{"width":"auto","height":"auto","cursor":"pointer","z-index":"10"},attrs:{"src":item.url,"lazy-src":item.url},on:{"click":function($event){return _vm.getDettFiniture(item)}},scopedSlots:_vm._u([{key:"placeholder",fn:function(){return [_c('v-row',{staticClass:"fill-height ma-0",attrs:{"align":"center","justify":"center"}},[_c('v-progress-circular',{attrs:{"indeterminate":"","color":"black lighten-5"}})],1)]},proxy:true}],null,true)},'v-img',attrs,false),on))]}}],null,true)},[_c('span',[_vm._v(_vm._s(item.tipoFinitura))])])],1)],1)}),1):_vm._e(),(
                item.tipo != 'Maniglioni' &&
                item.tipo != 'Alzanti' &&
                item.tipo != 'Scorrevoli'
              )?_c('v-row',{style:({
                'min-width': _vm.stileFinitureStandardNoManiglioneAlzante.minWidth,
                'margin-left': _vm.stileFinitureStandardNoManiglioneAlzante.marginLeft,
                'margin-top': _vm.stileFinitureStandardNoManiglioneAlzante.marginTop,
              })},_vm._l((_vm.getDatiFiniturePvd(item)),function(item,i){return _c('v-col',{key:i,staticClass:"d-flex child-flex",staticStyle:{"padding":"0"},style:({
                  'min-width': _vm.stileColFinitureImg.minWidth,
                  'margin-top': _vm.stileColFinitureImg.marginTop,
                }),attrs:{"cols":"2"}},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                var on = ref.on;
                var attrs = ref.attrs;
return [_c('v-img',_vm._g(_vm._b({staticStyle:{"width":"auto","height":"auto","cursor":"pointer","z-index":"10"},attrs:{"src":item.url,"lazy-src":item.url},on:{"click":function($event){return _vm.getDettFiniture(item)}},scopedSlots:_vm._u([{key:"placeholder",fn:function(){return [_c('v-row',{staticClass:"fill-height ma-0",attrs:{"align":"center","justify":"center"}},[_c('v-progress-circular',{attrs:{"indeterminate":"","color":"black lighten-5"}})],1)]},proxy:true}],null,true)},'v-img',attrs,false),on))]}}],null,true)},[_c('span',[_vm._v(_vm._s(item.tipoFinitura))])])],1)}),1):_vm._e(),(
                item.tipo == 'Maniglioni' ||
                (item.tipo == 'Alzanti' && item.tipo != 'Scorrevoli')
              )?_c('v-row',{style:({
                width: _vm.stileFinitureStandardSiManiglioneAlzante.width,
                'margin-left': _vm.stileFinitureStandardSiManiglioneAlzante.marginLeft,
                'margin-top': _vm.stileFinitureStandardSiManiglioneAlzante.marginTop,
              })},_vm._l((_vm.getDatiFiniturePvd(item)),function(item,i){return _c('v-col',{key:i,staticClass:"d-flex child-flex",staticStyle:{"padding":"0"},style:({
                  'min-width': _vm.stileColFinitureImg.minWidth,
                  'margin-top': _vm.stileColFinitureImg.marginTop,
                }),attrs:{"cols":"2"}},[_c('v-card',{staticStyle:{"max-width":"30px"},attrs:{"elevation":"0"}},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                var on = ref.on;
                var attrs = ref.attrs;
return [_c('v-img',_vm._g(_vm._b({staticStyle:{"width":"auto","height":"auto","cursor":"pointer","z-index":"10"},attrs:{"src":item.url,"lazy-src":item.url},on:{"click":function($event){return _vm.getDettFiniture(item)}},scopedSlots:_vm._u([{key:"placeholder",fn:function(){return [_c('v-row',{staticClass:"fill-height ma-0",attrs:{"align":"center","justify":"center"}},[_c('v-progress-circular',{attrs:{"indeterminate":"","color":"black lighten-5"}})],1)]},proxy:true}],null,true)},'v-img',attrs,false),on))]}}],null,true)},[_c('span',[_vm._v(_vm._s(item.tipoFinitura))])])],1)],1)}),1):_vm._e(),(item.tipo == 'Scorrevoli')?_c('v-row',{style:({
                'min-width': _vm.stileFinitureStandardSiScorrevoli.minWidth,
                'margin-left': _vm.stileFinitureStandardSiScorrevoli.marginLeft,
                'margin-top': _vm.stileFinitureStandardSiScorrevoli.marginTop,
              })},_vm._l((_vm.getDatiFiniturePvd(item)),function(item,i){return _c('v-col',{key:i,staticClass:"d-flex child-flex",staticStyle:{"padding":"0"},style:({
                  'min-width': _vm.stileColFinitureImg.minWidth,
                  'margin-top': _vm.stileColFinitureImg.marginTop,
                }),attrs:{"cols":"2"}},[_c('v-card',{staticStyle:{"max-width":"30px"},attrs:{"elevation":"0"}},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                var on = ref.on;
                var attrs = ref.attrs;
return [_c('v-img',_vm._g(_vm._b({staticStyle:{"width":"auto","height":"auto","cursor":"pointer","z-index":"10"},attrs:{"src":item.url,"lazy-src":item.url},on:{"click":function($event){return _vm.getDettFiniture(item)}},scopedSlots:_vm._u([{key:"placeholder",fn:function(){return [_c('v-row',{staticClass:"fill-height ma-0",attrs:{"align":"center","justify":"center"}},[_c('v-progress-circular',{attrs:{"indeterminate":"","color":"black lighten-5"}})],1)]},proxy:true}],null,true)},'v-img',attrs,false),on))]}}],null,true)},[_c('span',[_vm._v(_vm._s(item.tipoFinitura))])])],1)],1)}),1):_vm._e(),(
                item.tipo != 'Maniglioni' &&
                item.tipo != 'Alzanti' &&
                item.tipo != 'Scorrevoli'
              )?_c('v-row',{staticClass:"mt-3",style:({
                'min-width': _vm.stileFinitureStandardNoManiglioneAlzante.minWidth,
                'margin-left': _vm.stileFinitureStandardNoManiglioneAlzante.marginLeft,
                'margin-top': _vm.stileFinitureStandardNoManiglioneAlzante.marginTop,
              })},_vm._l((_vm.getDatiFinitureRetro(item)),function(item,i){return _c('v-col',{key:i,staticClass:"d-flex child-flex",staticStyle:{"padding":"0"},style:({
                  'min-width': _vm.stileColFinitureImg.minWidth,
                  'margin-top': _vm.stileColFinitureImg.marginTop,
                }),attrs:{"cols":"2"}},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                var on = ref.on;
                var attrs = ref.attrs;
return [_c('v-img',_vm._g(_vm._b({staticStyle:{"width":"auto","height":"auto","cursor":"pointer","z-index":"10"},attrs:{"src":item.url,"lazy-src":item.url},on:{"click":function($event){return _vm.getDettFiniture(item)}},scopedSlots:_vm._u([{key:"placeholder",fn:function(){return [_c('v-row',{staticClass:"fill-height ma-0",attrs:{"align":"center","justify":"center"}},[_c('v-progress-circular',{attrs:{"indeterminate":"","color":"black lighten-5"}})],1)]},proxy:true}],null,true)},'v-img',attrs,false),on))]}}],null,true)},[_c('span',[_vm._v(_vm._s(item.tipoFinitura))])])],1)}),1):_vm._e(),(
                item.tipo == 'Maniglioni' ||
                (item.tipo == 'Alzanti' && item.tipo != 'Scorrevoli')
              )?_c('v-row',{staticClass:"mt-3",style:({
                width: _vm.stileFinitureStandardSiManiglioneAlzante.width,
                'margin-left': _vm.stileFinitureStandardSiManiglioneAlzante.marginLeft,
                'margin-top': _vm.stileFinitureStandardSiManiglioneAlzante.marginTop,
              })},_vm._l((_vm.getDatiFinitureRetro(item)),function(item,i){return _c('v-col',{key:i,staticClass:"d-flex child-flex",staticStyle:{"padding":"0"},style:({
                  'min-width': _vm.stileColFinitureImg.minWidth,
                  'margin-top': _vm.stileColFinitureImg.marginTop,
                }),attrs:{"cols":"2"}},[_c('v-card',{staticStyle:{"max-width":"30px"},attrs:{"elevation":"0"}},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                var on = ref.on;
                var attrs = ref.attrs;
return [_c('v-img',_vm._g(_vm._b({staticStyle:{"width":"auto","height":"auto","cursor":"pointer","z-index":"10"},attrs:{"src":item.url,"lazy-src":item.url},on:{"click":function($event){return _vm.getDettFiniture(item)}},scopedSlots:_vm._u([{key:"placeholder",fn:function(){return [_c('v-row',{staticClass:"fill-height ma-0",attrs:{"align":"center","justify":"center"}},[_c('v-progress-circular',{attrs:{"indeterminate":"","color":"black lighten-5"}})],1)]},proxy:true}],null,true)},'v-img',attrs,false),on))]}}],null,true)},[_c('span',[_vm._v(_vm._s(item.tipoFinitura))])])],1)],1)}),1):_vm._e(),(item.tipo == 'Scorrevoli')?_c('v-row',{staticClass:"mt-4",style:({
                'min-width': _vm.stileFinitureStandardSiScorrevoli.minWidth,
                'margin-left': _vm.stileFinitureStandardSiScorrevoli.marginLeft,
                'margin-top': _vm.stileFinitureStandardSiScorrevoli.marginTop,
              })},_vm._l((_vm.getDatiFinitureRetro(item)),function(item,i){return _c('v-col',{key:i,staticClass:"d-flex child-flex",staticStyle:{"padding":"0"},style:({
                  'min-width': _vm.stileColFinitureImg.minWidth,
                  'margin-top': _vm.stileColFinitureImg.marginTop,
                }),attrs:{"cols":"2"}},[_c('v-card',{staticStyle:{"max-width":"30px"},attrs:{"elevation":"0"}},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                var on = ref.on;
                var attrs = ref.attrs;
return [_c('v-img',_vm._g(_vm._b({staticStyle:{"width":"auto","height":"auto","cursor":"pointer","z-index":"10"},attrs:{"src":item.url,"lazy-src":item.url},on:{"click":function($event){return _vm.getDettFiniture(item)}},scopedSlots:_vm._u([{key:"placeholder",fn:function(){return [_c('v-row',{staticClass:"fill-height ma-0",attrs:{"align":"center","justify":"center"}},[_c('v-progress-circular',{attrs:{"indeterminate":"","color":"black lighten-5"}})],1)]},proxy:true}],null,true)},'v-img',attrs,false),on))]}}],null,true)},[_c('span',[_vm._v(_vm._s(item.tipoFinitura))])])],1)],1)}),1):_vm._e()],1)]):_c('div',{staticClass:"face face2",on:{"mouseover":function($event){return _vm.showPermanent(item)}}},[_c('div',{staticClass:"content",style:(_vm.isTelefono() >= 3840 && item.tipo == 'Scorrevoli'
                ? {
                    'margin-top': _vm.stileExtationPanel.marginTop,
                    height: _vm.stileExtationPanel.height,
                    'min-width': '125%',
                  }
                : {
                    'margin-top': _vm.stileExtationPanel.marginTop,
                    height: _vm.stileExtationPanel.height,
                    width: _vm.stileExtationPanel.width,
                  })},[(item.tipo != 'Maniglioni' && item.tipo != 'Alzanti')?_c('p',{style:({
                'margin-top': _vm.stilePanelNoManiglioneAlzante.marginTop,
                'margin-left': _vm.stilePanelNoManiglioneAlzante.marginLeft,
                'font-size': _vm.stilePanelNoManiglioneAlzante.fontSize,
              })},[_vm._v(" "+_vm._s(item.descrizione)+" ")]):_c('p',{style:({
                'margin-top': _vm.stilePanelSiManiglioneAlzante.marginTop,
                'margin-left': _vm.stilePanelSiManiglioneAlzante.marginLeft,
                'font-size': _vm.stilePanelSiManiglioneAlzante.fontSize,
              })},[_vm._v(" "+_vm._s(item.descrizione)+" ")]),(
                item.tipo != 'Maniglioni' &&
                item.tipo != 'Alzanti' &&
                item.tipo != 'Scorrevoli'
              )?_c('v-row',{style:({
                'min-width': _vm.stileFinitureStandardNoManiglioneAlzante.minWidth,
                'margin-left': _vm.stileFinitureStandardNoManiglioneAlzante.marginLeft,
                'margin-top': _vm.stileFinitureStandardNoManiglioneAlzante.marginTop,
              })},_vm._l((_vm.getDatiFinitureStandard(item)),function(item,i){return _c('v-col',{key:i,staticClass:"d-flex child-flex",staticStyle:{"padding":"0"},style:({
                  'min-width': _vm.stileColFinitureImg.minWidth,
                  'margin-top': _vm.stileColFinitureImg.marginTop,
                }),attrs:{"cols":"2"}},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                var on = ref.on;
                var attrs = ref.attrs;
return [_c('v-img',_vm._g(_vm._b({staticStyle:{"width":"auto","height":"auto","cursor":"pointer","z-index":"10"},attrs:{"src":item.url,"lazy-src":item.url},on:{"click":function($event){return _vm.getDettFiniture(item)}},scopedSlots:_vm._u([{key:"placeholder",fn:function(){return [_c('v-row',{staticClass:"fill-height ma-0",attrs:{"align":"center","justify":"center"}},[_c('v-progress-circular',{attrs:{"indeterminate":"","color":"black lighten-5"}})],1)]},proxy:true}],null,true)},'v-img',attrs,false),on))]}}],null,true)},[_c('span',[_vm._v(_vm._s(item.tipoFinitura))])])],1)}),1):_vm._e(),(
                item.tipo == 'Maniglioni' ||
                (item.tipo == 'Alzanti' && item.tipo != 'Scorrevoli')
              )?_c('v-row',{style:({
                width: _vm.stileFinitureStandardSiManiglioneAlzante.width,
                'margin-left': _vm.stileFinitureStandardSiManiglioneAlzante.marginLeft,
                'margin-top': _vm.stileFinitureStandardSiManiglioneAlzante.marginTop,
              })},_vm._l((_vm.getDatiFinitureStandard(item)),function(item,i){return _c('v-col',{key:i,staticClass:"d-flex child-flex",staticStyle:{"padding":"0"},style:({
                  'min-width': _vm.stileColFinitureImg.minWidth,
                  'margin-top': _vm.stileColFinitureImg.marginTop,
                }),attrs:{"cols":"2"}},[_c('v-card',{staticStyle:{"max-width":"30px"},attrs:{"elevation":"0"}},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                var on = ref.on;
                var attrs = ref.attrs;
return [_c('v-img',_vm._g(_vm._b({staticStyle:{"width":"auto","height":"auto","cursor":"pointer","z-index":"10"},attrs:{"src":item.url,"lazy-src":item.url},on:{"click":function($event){return _vm.getDettFiniture(item)}},scopedSlots:_vm._u([{key:"placeholder",fn:function(){return [_c('v-row',{staticClass:"fill-height ma-0",attrs:{"align":"center","justify":"center"}},[_c('v-progress-circular',{attrs:{"indeterminate":"","color":"black lighten-5"}})],1)]},proxy:true}],null,true)},'v-img',attrs,false),on))]}}],null,true)},[_c('span',[_vm._v(_vm._s(item.tipoFinitura))])])],1)],1)}),1):_vm._e(),(item.tipo == 'Scorrevoli')?_c('v-row',{style:([
                _vm.isTelefono() >= 3840
                  ? {
                      'min-width': '140%',
                      'margin-left': '-5em',
                      'margin-top': _vm.stileFinitureStandardSiScorrevoli.marginTop,
                    }
                  : {
                      'min-width': _vm.stileFinitureStandardSiScorrevoli.minWidth,
                      'margin-left': _vm.stileFinitureStandardSiScorrevoli.marginLeft,
                      'margin-top': _vm.stileFinitureStandardSiScorrevoli.marginTop,
                    } ])},_vm._l((_vm.getDatiFinitureStandard(item)),function(item,i){return _c('v-col',{key:i,staticClass:"d-flex child-flex",staticStyle:{"padding":"0"},style:({
                  'min-width': _vm.stileColFinitureImg.minWidth,
                  'margin-top': _vm.stileColFinitureImg.marginTop,
                }),attrs:{"cols":"2"}},[_c('v-card',{staticStyle:{"max-width":"30px"},attrs:{"elevation":"0"}},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                var on = ref.on;
                var attrs = ref.attrs;
return [_c('v-img',_vm._g(_vm._b({staticStyle:{"width":"auto","height":"auto","cursor":"pointer","z-index":"10"},attrs:{"src":item.url,"lazy-src":item.url},on:{"click":function($event){return _vm.getDettFiniture(item)}},scopedSlots:_vm._u([{key:"placeholder",fn:function(){return [_c('v-row',{staticClass:"fill-height ma-0",attrs:{"align":"center","justify":"center"}},[_c('v-progress-circular',{attrs:{"indeterminate":"","color":"black lighten-5"}})],1)]},proxy:true}],null,true)},'v-img',attrs,false),on))]}}],null,true)},[_c('span',[_vm._v(_vm._s(item.tipoFinitura))])])],1)],1)}),1):_vm._e(),(
                item.tipo != 'Maniglioni' &&
                item.tipo != 'Alzanti' &&
                item.tipo != 'Scorrevoli'
              )?_c('v-row',{style:({
                'min-width': _vm.stileFinitureStandardNoManiglioneAlzante.minWidth,
                'margin-left': _vm.stileFinitureStandardNoManiglioneAlzante.marginLeft,
                'margin-top': _vm.stileFinitureStandardNoManiglioneAlzante.marginTop,
              })},_vm._l((_vm.getDatiFiniturePvd(item)),function(item,i){return _c('v-col',{key:i,staticClass:"d-flex child-flex",staticStyle:{"padding":"0"},style:({
                  'min-width': _vm.stileColFinitureImg.minWidth,
                  'margin-top': _vm.stileColFinitureImg.marginTop,
                }),attrs:{"cols":"2"}},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                var on = ref.on;
                var attrs = ref.attrs;
return [_c('v-img',_vm._g(_vm._b({staticStyle:{"width":"auto","height":"auto","cursor":"pointer","z-index":"10"},attrs:{"src":item.url,"lazy-src":item.url},on:{"click":function($event){return _vm.getDettFiniture(item)}},scopedSlots:_vm._u([{key:"placeholder",fn:function(){return [_c('v-row',{staticClass:"fill-height ma-0",attrs:{"align":"center","justify":"center"}},[_c('v-progress-circular',{attrs:{"indeterminate":"","color":"black lighten-5"}})],1)]},proxy:true}],null,true)},'v-img',attrs,false),on))]}}],null,true)},[_c('span',[_vm._v(_vm._s(item.tipoFinitura))])])],1)}),1):_vm._e(),(
                item.tipo == 'Maniglioni' ||
                (item.tipo == 'Alzanti' && item.tipo != 'Scorrevoli')
              )?_c('v-row',{style:({
                width: _vm.stileFinitureStandardSiManiglioneAlzante.width,
                'margin-left': _vm.stileFinitureStandardSiManiglioneAlzante.marginLeft,
                'margin-top': _vm.stileFinitureStandardSiManiglioneAlzante.marginTop,
              })},_vm._l((_vm.getDatiFiniturePvd(item)),function(item,i){return _c('v-col',{key:i,staticClass:"d-flex child-flex",staticStyle:{"padding":"0"},style:({
                  'min-width': _vm.stileColFinitureImg.minWidth,
                  'margin-top': _vm.stileColFinitureImg.marginTop,
                }),attrs:{"cols":"2"}},[_c('v-card',{staticStyle:{"max-width":"30px"},attrs:{"elevation":"0"}},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                var on = ref.on;
                var attrs = ref.attrs;
return [_c('v-img',_vm._g(_vm._b({staticStyle:{"width":"auto","height":"auto","cursor":"pointer","z-index":"10"},attrs:{"src":item.url,"lazy-src":item.url},on:{"click":function($event){return _vm.getDettFiniture(item)}},scopedSlots:_vm._u([{key:"placeholder",fn:function(){return [_c('v-row',{staticClass:"fill-height ma-0",attrs:{"align":"center","justify":"center"}},[_c('v-progress-circular',{attrs:{"indeterminate":"","color":"black lighten-5"}})],1)]},proxy:true}],null,true)},'v-img',attrs,false),on))]}}],null,true)},[_c('span',[_vm._v(_vm._s(item.tipoFinitura))])])],1)],1)}),1):_vm._e(),(item.tipo == 'Scorrevoli')?_c('v-row',{style:([
                _vm.isTelefono() >= 3840
                  ? {
                      'min-width': '140%',
                      'margin-left': '-5em',
                      'margin-top': _vm.stileFinitureStandardSiScorrevoli.marginTop,
                    }
                  : {
                      'min-width': _vm.stileFinitureStandardSiScorrevoli.minWidth,
                      'margin-left': _vm.stileFinitureStandardSiScorrevoli.marginLeft,
                      'margin-top': _vm.stileFinitureStandardSiScorrevoli.marginTop,
                    } ])},_vm._l((_vm.getDatiFiniturePvd(item)),function(item,i){return _c('v-col',{key:i,staticClass:"d-flex child-flex",staticStyle:{"padding":"0"},style:({
                  'min-width': _vm.stileColFinitureImg.minWidth,
                  'margin-top': _vm.stileColFinitureImg.marginTop,
                }),attrs:{"cols":"2"}},[_c('v-card',{staticStyle:{"max-width":"30px"},attrs:{"elevation":"0"}},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                var on = ref.on;
                var attrs = ref.attrs;
return [_c('v-img',_vm._g(_vm._b({staticStyle:{"width":"auto","height":"auto","cursor":"pointer","z-index":"10"},attrs:{"src":item.url,"lazy-src":item.url},on:{"click":function($event){return _vm.getDettFiniture(item)}},scopedSlots:_vm._u([{key:"placeholder",fn:function(){return [_c('v-row',{staticClass:"fill-height ma-0",attrs:{"align":"center","justify":"center"}},[_c('v-progress-circular',{attrs:{"indeterminate":"","color":"black lighten-5"}})],1)]},proxy:true}],null,true)},'v-img',attrs,false),on))]}}],null,true)},[_c('span',[_vm._v(_vm._s(item.tipoFinitura))])])],1)],1)}),1):_vm._e(),(
                item.tipo != 'Maniglioni' &&
                item.tipo != 'Alzanti' &&
                item.tipo != 'Scorrevoli'
              )?_c('v-row',{staticClass:"mt-3",style:({
                'min-width': _vm.stileFinitureStandardNoManiglioneAlzante.minWidth,
                'margin-left': _vm.stileFinitureStandardNoManiglioneAlzante.marginLeft,
                'margin-top': _vm.stileFinitureStandardNoManiglioneAlzante.marginTop,
              })},_vm._l((_vm.getDatiFinitureRetro(item)),function(item,i){return _c('v-col',{key:i,staticClass:"d-flex child-flex",staticStyle:{"padding":"0"},style:({
                  'min-width': _vm.stileColFinitureImg.minWidth,
                  'margin-top': _vm.stileColFinitureImg.marginTop,
                }),attrs:{"cols":"2"}},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                var on = ref.on;
                var attrs = ref.attrs;
return [_c('v-img',_vm._g(_vm._b({staticStyle:{"width":"auto","height":"auto","cursor":"pointer","z-index":"10"},attrs:{"src":item.url,"lazy-src":item.url},on:{"click":function($event){return _vm.getDettFiniture(item)}},scopedSlots:_vm._u([{key:"placeholder",fn:function(){return [_c('v-row',{staticClass:"fill-height ma-0",attrs:{"align":"center","justify":"center"}},[_c('v-progress-circular',{attrs:{"indeterminate":"","color":"black lighten-5"}})],1)]},proxy:true}],null,true)},'v-img',attrs,false),on))]}}],null,true)},[_c('span',[_vm._v(_vm._s(item.tipoFinitura))])])],1)}),1):_vm._e(),(
                item.tipo == 'Maniglioni' ||
                (item.tipo == 'Alzanti' && item.tipo != 'Scorrevoli')
              )?_c('v-row',{staticClass:"mt-3",style:({
                width: _vm.stileFinitureStandardSiManiglioneAlzante.width,
                'margin-left': _vm.stileFinitureStandardSiManiglioneAlzante.marginLeft,
                'margin-top': _vm.stileFinitureStandardSiManiglioneAlzante.marginTop,
              })},_vm._l((_vm.getDatiFinitureRetro(item)),function(item,i){return _c('v-col',{key:i,staticClass:"d-flex child-flex",staticStyle:{"padding":"0"},style:({
                  'min-width': _vm.stileColFinitureImg.minWidth,
                  'margin-top': _vm.stileColFinitureImg.marginTop,
                }),attrs:{"cols":"2"}},[_c('v-card',{staticStyle:{"max-width":"30px"},attrs:{"elevation":"0"}},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                var on = ref.on;
                var attrs = ref.attrs;
return [_c('v-img',_vm._g(_vm._b({staticStyle:{"width":"auto","height":"auto","cursor":"pointer","z-index":"10"},attrs:{"src":item.url,"lazy-src":item.url},on:{"click":function($event){return _vm.getDettFiniture(item)}},scopedSlots:_vm._u([{key:"placeholder",fn:function(){return [_c('v-row',{staticClass:"fill-height ma-0",attrs:{"align":"center","justify":"center"}},[_c('v-progress-circular',{attrs:{"indeterminate":"","color":"black lighten-5"}})],1)]},proxy:true}],null,true)},'v-img',attrs,false),on))]}}],null,true)},[_c('span',[_vm._v(_vm._s(item.tipoFinitura))])])],1)],1)}),1):_vm._e(),(item.tipo == 'Scorrevoli')?_c('v-row',{staticClass:"mt-4",style:([
                _vm.isTelefono() >= 3840
                  ? {
                      'min-width': '140%',
                      'margin-left': '-5em',
                      'margin-top': _vm.stileFinitureStandardSiScorrevoli.marginTop,
                    }
                  : {
                      'min-width': _vm.stileFinitureStandardSiScorrevoli.minWidth,
                      'margin-left': _vm.stileFinitureStandardSiScorrevoli.marginLeft,
                      'margin-top': _vm.stileFinitureStandardSiScorrevoli.marginTop,
                    } ])},_vm._l((_vm.getDatiFinitureRetro(item)),function(item,i){return _c('v-col',{key:i,staticClass:"d-flex child-flex",staticStyle:{"padding":"0"},style:({
                  'min-width': _vm.stileColFinitureImg.minWidth,
                  'margin-top': _vm.stileColFinitureImg.marginTop,
                }),attrs:{"cols":"2"}},[_c('v-card',{staticStyle:{"max-width":"30px"},attrs:{"elevation":"0"}},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                var on = ref.on;
                var attrs = ref.attrs;
return [_c('v-img',_vm._g(_vm._b({staticStyle:{"width":"auto","height":"auto","cursor":"pointer","z-index":"10"},attrs:{"src":item.url,"lazy-src":item.url},on:{"click":function($event){return _vm.getDettFiniture(item)}},scopedSlots:_vm._u([{key:"placeholder",fn:function(){return [_c('v-row',{staticClass:"fill-height ma-0",attrs:{"align":"center","justify":"center"}},[_c('v-progress-circular',{attrs:{"indeterminate":"","color":"black lighten-5"}})],1)]},proxy:true}],null,true)},'v-img',attrs,false),on))]}}],null,true)},[_c('span',[_vm._v(_vm._s(item.tipoFinitura))])])],1)],1)}),1):_vm._e()],1)])])])}),1),_c('scroll-top')],1)}
var staticRenderFns = []

export { render, staticRenderFns }