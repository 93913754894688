<template>
  <div style="margin-left: 5%; margin-right: 5%; margin-top: 3%; background-color: #999">
    <h1
      style="
        margin-left: -5%;
        margin-right: -5%;
        background-color: #ce0538;
        color: white;
        text-align: center;
      "
    >
      COOKIES POLICY
    </h1>
    <br />
    <p style="margin-left: 3%; margin-right: 3%">
      <b>DICHIARAZIONE SULLA PROTEZIONE DEI DATI</b><br />
      Ti trovi nella pagina web di Battisti Francesco SNC.,
      <a href="http://www.ecolineamaniglie.com">http://www.ecolineamaniglie.com.</a> La
      protezione dei tuoi dati è importante per noi. Pertanto desideriamo informarti sugli
      scopi a cui utilizziamo i tuoi dati quando visiti il nostro sito web. <br /><br />
      <v-divider style="background-color: white"></v-divider> <br /><b
        >RILEVAMENTO ED ELABORAZIONE DATI IN MODO ANONIMO</b
      ><br />
      Se non diversamente indicato nei seguenti paragrafi, durante l'utilizzo del nostro
      sito web non vengo-no rilevati, elaborati o utilizzati dati personali. Il log di
      accesso al nostro server web è stato completa-mente disattivato. Tramite Google
      Analytics rileviamo tuttavia determinate informazioni tecniche sulla base dei tuoi
      dati forniti al browser (ad esempio versione/tipo di browser, sistema operativo
      utilizzato, pagine visitate nel nostro sito web e durata del collegamento, sito web
      precedentemente visitato). Queste informazioni ci servono solo a fini statistici.
      <br /><br />
      <v-divider style="background-color: white"></v-divider> <br /><b
        >MEMORIZZAZIONE DEI DATI DI ACCESSO </b
      ><br />
      A ogni tuo accesso a una pagina della nostra offerta web, nonché ogni volta che
      viene richiamato un file, tramite questa procedura i dati di accesso vengono
      rilevati e memorizzati all'interno di un file pro-tocollo. Per quanto attiene alle
      informazioni prelevate si tratta del protocollo standard. Ogni set di dati si
      compone di: • data/ora della richiesta • pagina dalla quale è stato richiesto il
      file • nome del file richiamato • stato di accesso della quantità di dati trasferita
      • stato di accesso (file trasferito, file non trovato) • descrizione del sistema
      operativo e del browser utilizzati indirizzo IP del Client e nome utente degli
      utenti autentificati I dati possono essere valutati a fini statistici e per la lotta
      all'abuso.
      <br /><br />
      <v-divider style="background-color: white"></v-divider> <br /><b
        >MODULI DI CONTATTO</b
      ><br />
      Nella misura in cui in queste pagine web sia possibile immettere dati di carattere
      personale o aziendale, l'indicazione di questi dati è sempre volontaria. I dati
      necessari ai fini dell'esecuzione di quanto riporta-to nella richiesta sono
      contrassegnati da un asterisco "*". Qualora, per mezzo dei moduli di contatto, ci
      comunicassi dati di carattere personale o aziendale, li utilizzeremo esclusivamente
      nell'ambito dell'uso previsto.
      <br /><br />
      <v-divider style="background-color: white"></v-divider> <br /><b>GOOGLE ANALYTICS</b
      ><br />
      Questo sito web utilizza Google Analytics, un servizio di analisi web fornito da
      Google, Inc. ("Google"). Google Analytics utilizza "cookies": file di testo che
      vengono salvati nel computer per consentire al sito web di analizzare come gli
      utenti utilizzano il sito. Le informazioni generate dai cookie sul tuo utilizzo del
      sito web sono generalmente trasmesse a un server di Google negli Stati Uniti e qui
      depositate. In caso di attivazione dell'anonimato IP in questo sito web, l'indirizzo
      IP dell'utente viene abbreviato da Google internamente agli Stati membri dell'Unione
      Europea o in altri Stati firmatari dell'Accordo sullo Spazio economico europeo. Solo
      in casi eccezionali viene trasmesso l'indirizzo IP completo a un ser-ver di Google
      negli USA e qui abbreviato. Su richiesta del gestore, Google utilizzerà queste
      informa-zioni allo scopo di tracciare e esaminare il tuo utilizzo del sito web,
      compilare report sulle attività del sito web e fornire al gestore del sito web altri
      servizi relativi alle attività del sito web e all’utilizzo di Internet. L'indirizzo
      IP trasferito dal browser dell'utente durante l'utilizzo di Google Analytics non è
      abbinato agli altri dati di Google. Puoi rifiutarti di usare i cookie selezionando
      la relativa impostazione nel browser, ma ciò potrebbe impedire l'utilizzo di tutte
      le funzionalità di questo sito web. Puoi inoltre impedire che Google registri i dati
      generati dai cookie e correlati all'utilizzo del sito web (incluso l'indirizzo IP
      dell'u-tente) nonché l'elaborazione di tali dati da parte di Google scaricando e
      installando il plugin del browser disponibile al seguente link: (<a
        href="http://tools.google.com/dlpage/gaoptout?hl=de"
        >http://tools.google.com/dlpage/gaoptout?hl=de</a
      >) Il rilevamento da parte di Google Analytics può essere impedito cliccando sul
      seguente link. Viene im-postato un cookie Opt-Out, che impedisce in futuro il
      rilevamento dei dati dell'utente durante la visita di questo sito web: disattiva
      Google Analytics Per ulteriori informazioni sulle condizioni di utilizzo e la
      protezione dei dati consulta:
      <a href="http://www.google.com/analytics/terms/de.html"
        >http://www.google.com/analytics/terms/de.html</a
      >
      o
      <a href="https://www.google.de/intl/de/policies/"
        >https://www.google.de/intl/de/policies/.</a
      >
      <br /><br />
      <v-divider style="background-color: white"></v-divider> <br /><b>GOOGLE ADWORDS</b
      ><br />
      Questo sito web utilizza il monitoraggio delle conversioni di Google. Si tratta di
      un servizio offerto da Google Inc., 1600 Amphitheatre Parkway, Mountain View, CA
      94043 USA. Accedendo al nostro sito web attraverso un annuncio postato da Google,
      Google Adwords salva un cookie sul computer dell’utente. Il cookie per il
      monitoraggio delle conversioni viene impostato, quando un utente clicchi su un
      annuncio inserito da Google. I cookie scadono dopo 30 giorni e non sono uti-lizzati
      per identificare personalmente l’utente. Se l’utente visita talune pagine del nostro
      sito web prima che il cookie sia scaduto, noi e Google possiamo vedere l’utente come
      se avesse cliccato sul nostro annuncio, inserito da Google, e quindi fosse stato
      trasferito alla nostra pagina. Google utilizza le infor-mazioni ottenute attraverso
      i cookie per generare statistiche sui visitatori del nostro sito web. Le
      stati-stiche in questione ci sono utili per determinare il numero totale degli
      utenti, che abbiano cliccato sul nostro annuncio, nonché quali utenti abbiano avuto
      quindi accesso al nostro sito web e su quali pagi-ne. Noi e altri inserzionisti,
      utilizzatori di “Google Adwords”, non riceviamo, tuttavia, alcuna informazio-ne, che
      possa essere utilizzata per identificare personalmente gli utenti. È possibile
      evitare l’installazione dei cookie, modificando le impostazioni del proprio browser,
      per esempio facendo in modo che il browser disattivi automaticamente il salvataggio
      dei cookie o blocchi solo i cookie prevenienti dal dominio “googleadservices.com”.
      Si consiglia di consultare il seguente link per la dichiarazione di consenso al
      trattamento dei dati di Google riguardante Adwords:
      <a href="https://services.google.com/sitestats/de.html"
        >https://services.google.com/sitestats/de.html</a
      >
      <br /><br />
      <v-divider style="background-color: white"></v-divider> <br /><b
        >COMPONENTI “LIKE” DI FACEBOOK</b
      ><br />
      Questo sito web integra un plug-in per il social network Facebook, fornito da
      Facebook Inc., 1 Hacker Way, Menlo Park, California 94025, USA. I plug-in di
      Facebook sono identificati dal logo Facebook o dal pulsante “Mi piace” sulla nostra
      pagina. Al seguente indirizzo è riportata una panoramica dei plug-in di Facebook:
      <a href="http://developers.facebook.com/docs/plugins/"
        >http://developers.facebook.com/docs/plugins/</a
      >. Ogni volta che l’utente accede a uno dei nostri siti web, dove sono visualizzati
      i suddetti componenti, gli stessi fanno sì che il browser dell’utente scarichi una
      serie di componenti da Facebook. Il processo informa Facebook in merito a quali
      pagine specifiche del nostro sito web siano visitate dall’utente in quel momento. Se
      l’utente ac-cede al nostro sito web mentre è collegato a Facebook, Facebook
      riconosce le specifiche pagine visi-tate, utilizzando le informazioni raccolte dai
      componenti, e quindi le associa al profilo Facebook perso-nale. Per esempio, se
      l’utente clicca sul pulsante “Mi piace” o inserisce un commento, questa
      informa-zione viene trasmessa al suo profilo Facebook personale per essere salvata.
      Inoltre, sono trasmesse a Facebook le informazioni di conferma sulla visita
      dell’utente alla nostra pagina. Ciò si verifica indipen-dentemente dal fatto che
      l’utente clicchi sui componenti o meno. Si noti che noi, in quanto provider delle
      pagine, non sappiamo quali dati siano trasmessi a Facebook né come siano utilizzati
      dalla società. Ulteriori informazioni sono disponibili nella Dichiarazione di
      consenso al trattamento dei dati di Facebook in
      <a href="http://de-de.facebook.com/policy.php"
        >http://de-de.facebook.com/policy.php</a
      >. Se si desidera che Facebook non associ la visita al nostro sito web con il
      profilo utente di Facebook, è necessario disconnettersi da Facebook prima di
      accedere alla nostra pagina. <br /><br />
      <v-divider style="background-color: white"></v-divider> <br /><b
        >COMPONENTI DELLE SEGNALAZIONI DI TWITTER</b
      ><br />
      Sulla nostra pagina utilizziamo componenti forniti dal provider Twitter. Si tratta
      di un servizio di Twitter Inc., 1355 Market Street, Suite 900, San Francisco, CA
      94103, USA Ogni volta che l’utente accede a uno dei nostri siti web, dove sono
      visualizzati tali componenti, gli stes-si fanno sì che il browser dell’utente
      scarichi una serie di componenti da Twitter. Il processo informa Twitter in merito a
      quali pagine specifiche del nostro sito web siano visitate dall’utente in quel
      momen-to. Se l’utente è connesso a Twitter e usa la funzione “Retweet”, i siti web
      visitati sono collegati al rela-tivo profilo Twitter e rivelati ad altri utenti. I
      dati sono anche trasmessi a Twitter. Noi non siamo in grado di influire sui dati
      registrati da Twitter attraverso questo processo, né sull’ambito dei dati raccolti
      da Twitter. Per quanto a nostra conoscenza, Twitter raccoglie gli URL dei siti web
      visitati dall’utente e i relativi indirizzi IP, sebbene non utilizzi i dati per fini
      diversi dalla visualizza-zione di componenti di Twitter. Ulteriori informazioni sono
      disponibili nella Dichiarazione di consenso al trattamento dei dati di Twitter in
      <a href="https://twitter.com/en/privacy">https://twitter.com/en/privacy</a> È
      possibile modificare le impostazioni riguardanti il consenso al trattamento dei dati
      attraverso le impo-stazioni del profilo. Se si desidera che Twitter non colleghi e
      associ le informazioni con dati prove-nienti dal proprio profilo Twitter, è
      necessario disconnettersi da Twitter prima di visitare il nostro sito web. Ulteriori
      informazioni sulle modalità con cui Twitter registra e utilizza i dati degli utenti
      sono disponibili in
      <a href="https://twitter.com/en/privacy">https://twitter.com/en/privacy</a>.
      <br /><br />
      <v-divider style="background-color: white"></v-divider> <br /><b>GOOGLE MAPS</b
      ><br />
      Noi utilizziamo il componente "Google Maps" fornito da Google Inc., 1600
      Amphitheatre Parkway, Mountain View, CA 94043 USA, di seguito indicata come
      “Google”. Ogni volta che l’utente accede a “Google Maps”, Google salva un cookie, in
      modo da elaborare impo-stazioni e dati dell’utente, durante la visualizzazione della
      pagina su cui “Google Maps” è integrato. Il cookie non viene di norma cancellato
      alla chiusura del browser, ma piuttosto scade dopo un certo pe-riodo di tempo, a
      meno che non lo si cancelli prima manualmente. Se l’utente non desidera dare il
      proprio consenso al trattamento dei dati secondo quanto indicato so-pra, può
      disattivare il servizio “Google Maps” ed evitare così la trasmissione dei dati a
      Google. Per farlo è necessario disattivare la funzione JavaScript nel proprio
      browser. Tuttavia, facciamo notare che, in questo caso, l’utente non sarà più in
      grado di usare “Google Maps” ovvero lo potrà usare soltanto in modo limitato.
      “Google Maps” e le informazioni ottenute attraverso “Google Maps” sono utilizzati
      conformemente alle condizioni d’uso di Google,
      <a href="http://www.google.de/intl/de/policies/terms/regional.html"
        >http://www.google.de/intl/de/policies/terms/regional.html</a
      >
      nonché secondo i termini e le condizioni ulteriori stabiliti per “Google Maps”
      <a href="https://www.google.com/intl/de_de/help/terms_maps.html"
        >https://www.google.com/intl/de_de/help/terms_maps.html</a
      >
      <br /><br />
      <v-divider style="background-color: white"></v-divider> <br /><b>YOUTUBE</b><br />
      Il nostro sito web utilizza plug-in di YouTube, azienda controllata da Google.
      L’operatore della pagina è YouTube, LLC, 901 Cherry Ave., San Bruno, CA 94066, USA.
      Quando l’utente visita le nostre pagine, in cui è incluso il plug-in di YouTube,
      viene creato un link ai server di YouTube. Il server di YouTube riceve, quindi, le
      informazioni sulle pagine visitate dall’utente. Se l’utente è connesso al proprio
      account YouTube, la sua navigazione viene associata direttamente al profilo
      personale. Ciò si può evitare disconnettendosi dall’account YouTube. Ulteriori
      informazioni sulle modalità di trattamento dei dati degli utenti sono disponibili
      nella Dichiarazio-ne di consenso al trattamento dei dati di YouTube all’indirizzo
      <a href="https://www.google.de/intl/de/policies/privacy"
        >https://www.google.de/intl/de/policies/privacy</a
      >
      <br /><br />
      <v-divider style="background-color: white"></v-divider> <br /><b>COOKIE</b><br />
      Questo sito web utilizza i Cookie per rendere i propri servizi semplici e efficienti
      per l’utenza che visiona le pagine. Gli utenti che visionano il Sito, vedranno
      inserite delle quantità minime di informazioni nei dispositivi in uso, che siano
      computer e periferiche mobili, in piccoli file di testo denominati “cookie” salvati
      nelle directory utilizzate dal browser web dell’Utente. Vi sono vari tipi di cookie,
      alcuni per rendere più efficace l’uso del Sito, altri per abilitare determinate
      funzionalità. Analizzandoli in maniera particolareggiata i nostri cookie permettono
      di: •memorizzare le preferenze inserite •evitare di reinserire le stesse
      informazioni più volte durante la visita quali ad esempio nome utente e password
      •analizzare l’utilizzo dei servizi e dei contenuti forniti dal Sito per ottimizzarne
      l’esperienza di navigazione e i servizi offerti Tipologie di Cookie utilizzati dal
      Sito A seguire i vari tipi di cookie utilizzati dal Sito in funzione delle finalità
      d’uso. Cookie Tecnici Questa tipologia di cookie permette il corretto funzionamento
      di alcune sezioni del Sito. Sono di due categorie: persistenti e di sessione:
      •persistenti: una volta chiuso il browser non vengono distrutti ma rimangono fino ad
      una data di scadenza preimpostata •di sessioni: vengono distrutti ogni volta che il
      browser viene chiuso Cookie analitici I cookie in questa categoria vengono
      utilizzati per collezionare informazioni sull’uso del sito. Il Sito userà queste
      informazioni in merito ad analisi statistiche anonime al fine di migliorare
      l’utilizzo del Sito e per rendere i contenuti più interessanti e attinenti ai
      desideri dell’utenza. Questa tipologia di cookie raccoglie dati in forma anonima
      sull’attività dell’utenza e su com’è arrivata sul Sito. I cookie analitici sono
      inviati dal Sito stesso o da domini di terze parti. Cookie di analisi di servizi di
      terze parti Questi cookie sono utilizzati al fine di raccogliere informazioni
      sull’uso del Sito da parte degli utenti in forma anonima quali: pagine visitate,
      tempo di permanenza, origini del traffico di provenienza geografica, età, genere e
      interessi ai fini di campagne di marketing. Questi cookie sono inviati da domini di
      terze parti esterni al Sito. Cookie per integrare prodotti e funzioni di software di
      terze parti Questa tipologia di cookie integra funzionalità sviluppate da terzi
      all’interno delle pagine del Sito come le icone e le preferenze espresse nei social
      network al fine di condivisione dei contenuti del sito o per l’uso di servizi
      software di terze parti (come i software per generare le mappe e ulteriori software
      che offrono servizi aggiuntivi). Questi cookie sono inviati da domini di terze parti
      e da siti partner che offrono le loro funzionalità tra le pagine del Sito. Il Sito,
      secondo la normativa vigente, non è tenuto a chiedere consenso per i cookie tecnici
      e di analytics, in quanto necessari a fornire i servizi richiesti. Per tutte le
      altre tipologie di cookie il consenso può essere espresso dall’Utente con una o più
      di una delle seguenti modalità: •Mediante specifiche configurazioni del browser
      utilizzato o dei relativi programmi informatici utilizzati per navigare le pagine
      che compongono il Sito. •Mediante modifica delle impostazioni nell’uso dei servizi
      di terze parti •Entrambe queste soluzioni potrebbero impedire all’utente di
      utilizzare o visualizzare parti del Sito. •Siti Web e servizi di terze parti Il Sito
      potrebbe contenere collegamenti ad altri siti Web che dispongono di una propria
      informativa sulla privacy che può essere diverse da quella adottata dal Sito e che
      che quindi non risponde di questi siti. Come disabilitare i cookie E’ solitamente
      possibile bloccare il salvataggio dei cookie, anche da un singolo sito web. Tutti i
      browser moderni consentono di modificare le impostazioni dei cookie. Di solito è
      possibile trovare queste impostazioni nelle “opzioni” o nel menu “preferenze” del
      tuo browser.
      <br /><br />
      <v-divider style="background-color: white"></v-divider> <br /><b>NEWSLETTER</b
      ><br />
      Qui hai la possibilità di abbonarti alla nostra Newsletter. Qui devi indicare il tuo
      indirizzo e-mail a cui possiamo inviare la Newsletter. Inserendo il tuo indirizzo
      e-mail accetti l'utilizzo da parte nostra dei tuoi dati per l'invio della Newsletter
      al fine di tenerti informato sulle nostre novità. Il tuo indirizzo e-mail non sarà
      utilizzato a nessun altro scopo, in particolare non sarà fornito a terzi.
      Naturalmente hai la possibilità di annullare in qualsiasi momento l'iscrizione alla
      Newsletter e di revocare per il futuro l'autorizzazione rilasciata. A tal fine
      clicca sul relativo tasto presente nella Newsletter che ricevi. Il tuo indirizzo
      e-mail sarà immediatamente eliminato dal nostro sistema
      <br /><br />
      <v-divider style="background-color: white"></v-divider> <br /><b>SICUREZZA</b><br />
      Applichiamo estesi sistemi di protezione sia di tipo tecnico sia gestionale per
      tutelare i tuoi dati da ma-nipolazione volontaria o involontaria, perdita,
      distruzione o accesso da parte di persone non autorizza-te. Le nostre procedure di
      sicurezza sono regolarmente controllate e adattate al progresso tecnologi-co.
      <br /><br />
      <v-divider style="background-color: white"></v-divider> <br /><b
        >NESSUN TRASFERIMENTO DI DATI A TERZI</b
      ><br />
      Non avviene alcun trasferimento dei tuoi dati a terzi, a meno che non ci sia imposto
      di legge. Se forni-tori di servizi esterni venissero a conoscenza dei tuoi dati
      personali, abbiamo garantito il rispetto delle disposizioni e delle legge sulla
      protezione dei dati tramite misure tecniche e organizzative nonché con-trolli
      regolari.
      <br /><br />
      <v-divider style="background-color: white"></v-divider> <br /><b
        >INCARICATO DELLA PROTEZIONE DATI</b
      ><br />
      Le tue domande o i suggerimenti che vorrai darci in materia di protezione dei dati
      sono molto importanti per noi. Per domande sulla protezione dei dati siamo a
      disposizione al seguente indirizzo: Battisti Francesco Snc Via San Martino 78 12032
      Barge Cn Tel. 0175-518900 Mail privacy@battisti.it
      <br /><br />
      <v-divider style="background-color: white"></v-divider> <br /><b
        >DISCLAIMER / LINK AD ALTRI SITI WEB</b
      ><br />
      La presente dichiarazione sulla protezione dei dati è valida esclusivamente per il
      nostro punto di ac-cesso Internet. Le pagine Internet nel nostro punto di accesso
      possono contenere link a pagine Inter-net di terzi. La nostra protezione dei dati
      non si estende a queste pagine Internet. Non abbiamo alcun influsso sull'utilizzo
      dei tuoi dati nelle pagine Internet di terzi e non controlliamo se altri fornitori
      di ser-vizi rispettano le normative vigenti in materia di tutela dei dati. Se esci
      dal nostro punto di accesso In-ternet, ti consigliamo di leggere attentamente il
      regolamento sulla protezione dei dati di ogni pagina In-ternet che rileva dati
      personali. Non ci accolliamo alcuna responsabilità per i contenuti di altri siti web
      a cui puoi accedere tramite i nostri link.
    </p>
  </div>
</template>

<script>
export default {
  props: {
    dialog: { type: Boolean },
    src: { type: String },
    nomeDelProdotto: { type: String },
    type: { type: String },
  },
  data() {
    return {
      page: 1,
      pageNo: 1,
      numPages: 0,
      pdfdata: undefined,
      errors: [],
      scale: 1, // 'page-width'
      isLoading: true,
    };
  },

  methods: {
    closeDialog() {
      this.$emit("chiudi");
    },

    isTelefono() {
      return window.innerWidth;
      //return this.$i2kFunctions.isSmartphone();
    },
  },
  mounted() {},

  computed: {
    dialogComputed: {
      get() {
        return this.dialog;
      },
      set(value) {
        this.closeDialog();
      },
    },
  },
};
</script>
