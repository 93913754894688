<template>
  <div>
    <!-- NOVITA' -->
    <img
      v-if="this.Icone.novitaProdotto == true"
      style="width: 30px; height: 30px; margin-top: 0em; margin-left: 0.5em; float: right"
      :src="require('@/assets/img/Novità.png')"
    />
    <!-- DISPONIBILE PROSSIMAMENTE -->
    <img
      v-if="this.Icone.disponibilitaProdotto == true"
      style="width: 30px; height: 30px; margin-top: 0em; margin-left: 0.5m; float: right"
      :src="require('@/assets/img/DisponibileMaggio23.png')"
    />
    <!-- RESTYLING -->
    <img
      v-if="this.Icone.restyling == true"
      style="width: 30px; height: 30px; margin-top: 0em; margin-left: 0.5em; float: right"
      :src="require('@/assets/img/Restyling.png')"
    />
  </div>
</template>

<script>
export default {
  props: {
    Icone: { type: Object, default: null },
  },

  components: {},

  data: () => ({}),

  methods: {},

  created() {},

  mounted() {},

  computed: {},
};
</script>
