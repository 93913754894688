var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticStyle:{"min-width":"100%"}},[(_vm.Url.Tipo != 'Maniglioni' && _vm.Url.Tipo != 'Alzanti')?_c('v-row',{staticClass:"d-flex child-fle",staticStyle:{"margin-left":"20%"}},[(_vm.finitureStandard != 0)?_c('v-col',{attrs:{"cols":"6"}},[_c('v-row',[_c('h2',{staticStyle:{"margin-top":"0em","text-align":"left","margin-right":"0%"}},[_vm._v(" Finiture Standard ")])]),_c('br'),_vm._l((_vm.finitureStandard),function(dettaglio,i){return _c('v-col',{key:i,staticClass:"d-flex child-flex",staticStyle:{"padding":"0"},style:([
          _vm.isTelefono() <= 768
            ? { 'min-width': '20%', 'margin-top': '0%' }
            : { 'min-width': '20%', 'margin-top': '0%' } ]),attrs:{"cols":"12"}},[_c('v-row',[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
        var attrs = ref.attrs;
return [(
                  dettaglio.nomeProdotto == 'Kit Sochi' ||
                  dettaglio.nomeProdotto == 'Kit Sochi Q' ||
                  dettaglio.nomeProdotto == 'Kit Ginevra'
                )?_c('v-card',{staticClass:"elevation-0",staticStyle:{"width":"100px","height":"100px","padding":"0px","margin-left":"15%","margin-bottom":"2em"}},[_c('v-img',_vm._g(_vm._b({staticStyle:{"width":"75px","height":"auto","cursor":"pointer"},attrs:{"src":dettaglio.url,"lazy-src":dettaglio.url},on:{"click":function($event){return _vm.getDettFiniture(dettaglio)}},scopedSlots:_vm._u([{key:"placeholder",fn:function(){return [_c('v-row',{staticClass:"fill-height ma-0",attrs:{"align":"center","justify":"center"}},[_c('v-progress-circular',{attrs:{"indeterminate":"","color":"black lighten-5"}})],1)]},proxy:true}],null,true)},'v-img',attrs,false),on))],1):_c('v-card',{staticClass:"elevation-0",staticStyle:{"width":"auto","height":"auto","padding":"0px","margin-left":"0em","margin-bottom":"3em"}},[_c('v-img',_vm._g(_vm._b({staticStyle:{"width":"180px","height":"auto","cursor":"pointer"},attrs:{"src":dettaglio.url,"lazy-src":dettaglio.url},on:{"click":function($event){return _vm.getDettFiniture(dettaglio)}},scopedSlots:_vm._u([{key:"placeholder",fn:function(){return [_c('v-row',{staticClass:"fill-height ma-0",attrs:{"align":"center","justify":"center"}},[_c('v-progress-circular',{attrs:{"indeterminate":"","color":"black lighten-5"}})],1)]},proxy:true}],null,true)},'v-img',attrs,false),on))],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(dettaglio.tipoFinitura))])])],1)],1)})],2):_vm._e(),(_vm.finiturePvd != 0)?_c('v-col',{attrs:{"cols":"6"}},[_c('v-row',[_c('h2',{staticStyle:{"margin-top":"0em","text-align":"left","margin-right":"0%"}},[_vm._v(" Finiture PVD ")])]),_c('br'),_vm._l((_vm.finiturePvd),function(dettaglio,i){return _c('v-col',{key:i,staticClass:"d-flex child-flex",staticStyle:{"padding":"0"},style:([
          _vm.isTelefono() <= 768
            ? { 'min-width': '20%', 'margin-top': '0%' }
            : { 'min-width': '20%', 'margin-top': '0%' } ]),attrs:{"cols":"12"}},[_c('v-row',[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
        var attrs = ref.attrs;
return [(
                  dettaglio.nomeProdotto == 'Kit Sochi' ||
                  dettaglio.nomeProdotto == 'Kit Sochi Q' ||
                  dettaglio.nomeProdotto == 'Kit Ginevra'
                )?_c('v-card',{staticClass:"elevation-0",staticStyle:{"width":"100px","height":"100px","padding":"0px","margin-left":"10%","margin-bottom":"2em"}},[_c('v-img',_vm._g(_vm._b({staticStyle:{"width":"75px","height":"auto","cursor":"pointer"},attrs:{"src":dettaglio.url,"lazy-src":dettaglio.url},on:{"click":function($event){return _vm.getDettFiniture(dettaglio)}},scopedSlots:_vm._u([{key:"placeholder",fn:function(){return [_c('v-row',{staticClass:"fill-height ma-0",attrs:{"align":"center","justify":"center"}},[_c('v-progress-circular',{attrs:{"indeterminate":"","color":"black lighten-5"}})],1)]},proxy:true}],null,true)},'v-img',attrs,false),on))],1):_c('v-card',{staticClass:"elevation-0",staticStyle:{"width":"auto","height":"auto","padding":"0px","margin-left":"0em","margin-bottom":"3em"}},[_c('v-img',_vm._g(_vm._b({staticStyle:{"width":"180px","height":"auto","cursor":"pointer"},attrs:{"src":dettaglio.url,"lazy-src":dettaglio.url},on:{"click":function($event){return _vm.getDettFiniture(dettaglio)}},scopedSlots:_vm._u([{key:"placeholder",fn:function(){return [_c('v-row',{staticClass:"fill-height ma-0",attrs:{"align":"center","justify":"center"}},[_c('v-progress-circular',{attrs:{"indeterminate":"","color":"black lighten-5"}})],1)]},proxy:true}],null,true)},'v-img',attrs,false),on))],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(dettaglio.tipoFinitura))])])],1)],1)})],2):_vm._e(),(_vm.finitureRetro != 0)?_c('v-col',{attrs:{"cols":"6"}},[_c('v-row',[_c('h2',{staticStyle:{"margin-top":"0em","text-align":"left","margin-right":"0%"}},[_vm._v(" Finiture Retrò ")])]),_c('br'),_vm._l((_vm.finitureRetro),function(dettaglio,i){return _c('v-col',{key:i,staticClass:"d-flex child-flex",staticStyle:{"padding":"0"},style:([
          _vm.isTelefono() <= 768
            ? { 'min-width': '20%', 'margin-top': '0%' }
            : { 'min-width': '20%', 'margin-top': '0%' } ]),attrs:{"cols":"12"}},[_c('v-row',[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
        var attrs = ref.attrs;
return [(
                  dettaglio.nomeProdotto == 'Kit Sochi' ||
                  dettaglio.nomeProdotto == 'Kit Sochi Q' ||
                  dettaglio.nomeProdotto == 'Kit Ginevra'
                )?_c('v-card',{staticClass:"elevation-0",staticStyle:{"width":"100px","height":"100px","padding":"0px","margin-left":"12%","margin-bottom":"2em"}},[_c('v-img',_vm._g(_vm._b({staticStyle:{"width":"75px","height":"auto","cursor":"pointer"},attrs:{"src":dettaglio.url,"lazy-src":dettaglio.url},on:{"click":function($event){return _vm.getDettFiniture(dettaglio)}},scopedSlots:_vm._u([{key:"placeholder",fn:function(){return [_c('v-row',{staticClass:"fill-height ma-0",attrs:{"align":"center","justify":"center"}},[_c('v-progress-circular',{attrs:{"indeterminate":"","color":"black lighten-5"}})],1)]},proxy:true}],null,true)},'v-img',attrs,false),on))],1):_c('v-card',{staticClass:"elevation-0",staticStyle:{"width":"auto","height":"auto","padding":"0px","margin-left":"-0.5em","margin-bottom":"3em"}},[_c('v-img',_vm._g(_vm._b({staticStyle:{"width":"200px","height":"auto","cursor":"pointer"},attrs:{"src":dettaglio.url,"lazy-src":dettaglio.url},on:{"click":function($event){return _vm.getDettFiniture(dettaglio)}},scopedSlots:_vm._u([{key:"placeholder",fn:function(){return [_c('v-row',{staticClass:"fill-height ma-0",attrs:{"align":"center","justify":"center"}},[_c('v-progress-circular',{attrs:{"indeterminate":"","color":"black lighten-5"}})],1)]},proxy:true}],null,true)},'v-img',attrs,false),on))],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(dettaglio.tipoFinitura))])])],1)],1)})],2):_vm._e()],1):_vm._e(),(_vm.Url.Tipo == 'Maniglioni' || _vm.Url.Tipo == 'Alzanti')?_c('v-col',{staticStyle:{"max-width":"100%","margin-left":"5vh"},attrs:{"cols":"12"}},[(_vm.finitureStandard != 0)?_c('v-row',[_c('div',[_c('h2',{staticStyle:{"margin-top":"0em","text-align":"left","margin-left":"0em"}},[_vm._v(" Finiture Standard ")])]),_c('br'),_c('v-sheet',{staticStyle:{"margin-top":"2em"},style:([
          _vm.Url.NomeProdotto == 'Dover' ||
          _vm.Url.NomeProdotto == 'Belfast' ||
          _vm.Url.NomeProdotto == 'Lahti'
            ? { 'margin-left': '-14.5em' }
            : { 'margin-left': '-11em' } ]),attrs:{"elevation":"0","max-width":"95%","min-height":"100%"}},[_c('v-slide-group',{attrs:{"show-arrows":""},model:{value:(_vm.finitureStandard),callback:function ($$v) {_vm.finitureStandard=$$v},expression:"finitureStandard"}},_vm._l((_vm.finitureStandard),function(dettaglio,i){return _c('v-slide-item',{key:i,staticClass:"pa-0"},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
        var attrs = ref.attrs;
return [_c('v-card',_vm._g(_vm._b({staticStyle:{"width":"auto","height":"auto"},attrs:{"elevation":"0"}},'v-card',attrs,false),on),[_c('v-img',{staticStyle:{"width":"150px","height":"200px","cursor":"pointer"},attrs:{"src":dettaglio.url,"lazy-src":dettaglio.url,"aspect-ratio":"1"},on:{"click":function($event){return _vm.getDettFiniture(dettaglio)}},scopedSlots:_vm._u([{key:"placeholder",fn:function(){return [_c('v-row',{staticClass:"fill-height ma-0",attrs:{"align":"center","justify":"center"}},[_c('v-progress-circular',{attrs:{"indeterminate":"","color":"black lighten-5"}})],1)]},proxy:true}],null,true)})],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(dettaglio.tipoFinitura))])])],1)}),1)],1)],1):_vm._e(),(_vm.finiturePvd != 0)?_c('v-row',[_c('div',[_c('h2',{staticStyle:{"margin-top":"0em","text-align":"left","margin-left":"0em"}},[_vm._v(" Finiture PVD ")])]),_c('br'),_c('v-sheet',{staticStyle:{"margin-top":"2em","margin-left":"-8em"},attrs:{"elevation":"0","min-height":"100%"}},[_c('v-slide-group',{attrs:{"show-arrows":""},model:{value:(_vm.finiturePvd),callback:function ($$v) {_vm.finiturePvd=$$v},expression:"finiturePvd"}},_vm._l((_vm.finiturePvd),function(dettaglio,i){return _c('v-slide-item',{key:i,staticClass:"pa-0"},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
        var attrs = ref.attrs;
return [_c('v-card',_vm._g(_vm._b({staticStyle:{"width":"auto","height":"auto"},attrs:{"elevation":"0"}},'v-card',attrs,false),on),[_c('v-img',{staticStyle:{"width":"150px","height":"200px","cursor":"pointer"},attrs:{"src":dettaglio.url,"lazy-src":dettaglio.url,"aspect-ratio":"1"},on:{"click":function($event){return _vm.getDettFiniture(dettaglio)}},scopedSlots:_vm._u([{key:"placeholder",fn:function(){return [_c('v-row',{staticClass:"fill-height ma-0",attrs:{"align":"center","justify":"center"}},[_c('v-progress-circular',{attrs:{"indeterminate":"","color":"black lighten-5"}})],1)]},proxy:true}],null,true)})],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(dettaglio.tipoFinitura))])])],1)}),1)],1)],1):_vm._e(),(_vm.finitureRetro != 0)?_c('v-row',[_c('div',[_c('h2',{staticStyle:{"margin-top":"0em","text-align":"left","margin-left":"0em"}},[_vm._v(" Finiture Retrò ")])]),_c('br'),_c('v-sheet',{staticStyle:{"margin-top":"2em","margin-left":"-8.5em"},attrs:{"elevation":"0","min-height":"100%"}},[_c('v-slide-group',{attrs:{"show-arrows":""},model:{value:(_vm.finitureRetro),callback:function ($$v) {_vm.finitureRetro=$$v},expression:"finitureRetro"}},_vm._l((_vm.finitureRetro),function(dettaglio,i){return _c('v-slide-item',{key:i,staticClass:"pa-0"},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
        var attrs = ref.attrs;
return [_c('v-card',_vm._g(_vm._b({staticStyle:{"width":"auto","height":"auto"},attrs:{"elevation":"0"}},'v-card',attrs,false),on),[_c('v-img',{staticStyle:{"width":"150px","height":"200px","cursor":"pointer"},attrs:{"src":dettaglio.url,"lazy-src":dettaglio.url,"aspect-ratio":"1"},on:{"click":function($event){return _vm.getDettFiniture(dettaglio)}},scopedSlots:_vm._u([{key:"placeholder",fn:function(){return [_c('v-row',{staticClass:"fill-height ma-0",attrs:{"align":"center","justify":"center"}},[_c('v-progress-circular',{attrs:{"indeterminate":"","color":"black lighten-5"}})],1)]},proxy:true}],null,true)})],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(dettaglio.tipoFinitura))])])],1)}),1)],1)],1):_vm._e()],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }