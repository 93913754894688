import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './vuex'

import Vuetify from 'vuetify'
import 'vuetify/dist/vuetify.min.css'

import FunctionsApp from "@/utils/functions.js";
import i2kFunctions from "i2kjslibrary"

import Toast from 'i2kjslibrary/plugins/vuetify/toast/toast.js';
import Confirm from 'i2kjslibrary/plugins/vuetify/confirm/confirm.js';
import Wait from 'i2kjslibrary/plugins/vuetify/wait/wait.js';

import Load from "./LOAD.js"

import './assets/css/style.css'

// Vuetify
Vue.use(Vuetify)

const opts = {
  theme: {
    themes: {
      light: {
        primary: '#E40520'
      },
      dark: {
        primary: '#E40520',
      },
    },
  },
  icons: {
    iconfont: 'md',
  },
}

// Plugins
Vue.use(Toast, {
  version: 2
})
Vue.use(Confirm, {
  version: 2,
  titleBackground: 'primary'
})
Vue.use(Wait, {
  version: 2
})

// Prototype library
Vue.prototype.$functionsApp = FunctionsApp;
Vue.prototype.$i2kFunctions = i2kFunctions;
Vue.prototype.$load = Load;

Vue.config.productionTip = false;

new Vue({
  render: h => h(App),
  router,
  store,
  vuetify: new Vuetify(opts)
}).$mount('#app')