<template>
  <v-fab-transition>
    <v-btn
      v-if="isTelefono() < 768"
      style="margin-bottom: 4em; margin-right: 2em"
      v-show="!hidden"
      color="primary"
      fab
      dark
      fixed
      bottom
      right
      @click="$vuetify.goTo(0)"
    >
      <v-icon>arrow_upward</v-icon>
    </v-btn>
    <v-btn
      v-else-if="isTelefono() > 768 && isTelefono() <= 2560"
      style="margin-bottom: 5em; margin-right: 2em"
      v-show="!hidden"
      color="primary"
      fab
      dark
      fixed
      bottom
      right
      @click="$vuetify.goTo(0)"
    >
      <v-icon>arrow_upward</v-icon>
    </v-btn>
    <v-btn
      v-else
      style="margin-bottom: 10em; margin-right: 5em"
      width="100"
      height="100"
      v-show="!hidden"
      color="primary"
      fab
      dark
      fixed
      bottom
      right
      @click="$vuetify.goTo(0)"
    >
      <v-icon x-large>arrow_upward</v-icon>
    </v-btn>

    <!-- <v-btn
      :style="[
        isTelefono() <= 768
          ? { 'margin-bottom': '4em', 'margin-right': '2em' }
          : { 'margin-bottom': '2em', 'margin-right': '2em' },
      ]"
      v-show="!hidden"
      color="primary"
      fab
      dark
      fixed
      bottom
      right
      @click="$vuetify.goTo(0)"
    >
      <v-icon>arrow_upward</v-icon>
    </v-btn> -->
  </v-fab-transition>
</template>

<script>
export default {
  name: "scroll-top",

  data: () => ({
    hidden: true,
  }),

  methods: {
    toggleOnScroll() {
      if (window.scrollY > window.innerHeight + 200) {
        this.hidden = false;
      } else {
        this.hidden = true;
      }
    },

    isTelefono() {
      return window.innerWidth;
    },
  },

  created() {
    window.addEventListener("scroll", this.toggleOnScroll);
  },
  beforeDestroy() {
    window.removeEventListener("scroll", this.toggleOnScroll);
  },
};
</script>
