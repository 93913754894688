<template>
  <!-- DISPLAY > 1920 / <= 2560  -->
  <div>
    <!-- IMG PRODOTTO MANIGLIE / POMOLI -->
    <v-img v-if="Url.Tipo != 'Maniglioni' && Url.Tipo != 'Alzanti' && Url.Tipo != 'Scorrevoli'" style="
        width: 1300px;
        height: 1500px;
        margin-top: 0em;
        margin-left: 0em;
        cursor: pointer;
        background-color: trasparent;
      " :src="urlImgProdotto" :lazy-src="urlImgProdotto" aspect-ratio="1">
      <template v-slot:placeholder>
        <v-row class="fill-height ma-0" align="center" justify="center">
          <v-progress-circular indeterminate color="black lighten-5"></v-progress-circular>
        </v-row>
      </template>
    </v-img>
    <!-- IMG PRODOTTO SCORREVOLE -->
    <v-img v-if="Url.Tipo == 'Scorrevoli'" style="
        width: 1000px;
        height: 1290px;
        margin-top: 0em;
        margin-left: 0em;
        cursor: pointer;
        background-color: trasparent;
      " :src="urlImgProdotto" :lazy-src="urlImgProdotto" aspect-ratio="1">
      <template v-slot:placeholder>
        <v-row class="fill-height ma-0" align="center" justify="center">
          <v-progress-circular indeterminate color="black lighten-5"></v-progress-circular>
        </v-row>
      </template>
    </v-img>
    <!-- IMG PRODOTTO MANIGLIONI -->
    <v-img v-if="Url.Tipo == 'Maniglioni'" style="
        width: 1200px;
        height: 1200px;
        margin-top: 0em;
        margin-left: -4em;
        cursor: pointer;
        background-color: trasparent;
      " :src="urlImgProdotto" :lazy-src="urlImgProdotto" aspect-ratio="1">
      <template v-slot:placeholder>
        <v-row class="fill-height ma-0" align="center" justify="center">
          <v-progress-circular indeterminate color="black lighten-5"></v-progress-circular>
        </v-row>
      </template>
    </v-img>
    <!-- IMG PRODOTTO ALZANTI -->
    <v-img v-if="Url.Tipo == 'Alzanti'" style="
        width: 1000px;
        height: 1300px;
        margin-top: 0em;
        margin-left: -4em;
        cursor: pointer;
        background-color: trasparent;
      " :src="urlImgProdotto" :lazy-src="urlImgProdotto" aspect-ratio="1">
      <template v-slot:placeholder>
        <v-row class="fill-height ma-0" align="center" justify="center">
          <v-progress-circular indeterminate color="black lighten-5"></v-progress-circular>
        </v-row>
      </template>
    </v-img>
    <!-- CODICE PRODOTTO - SECONDA DESCRIZIONE PRODOTTO - ICONA PRODOTTO -->
    <v-row :style="[
      Url.Tipo == 'Maniglioni' || Url.Tipo == 'Alzanti'
        ? { 'margin-left': '25vh', 'margin-top': '-15vh' }
        : { 'margin-left': '35vh', 'margin-top': '-20vh' },
    ]" class="d-flex child-flex">
      <v-col cols="12">
        <span style="margin-top: -2.5em; margin-left: 14em; position: absolute">
          <img v-if="Icone.novitaProdotto == true" style="width: 80px; height: 80px; float: right"
            :src="require('@/assets/img/Novità.png')" />
          <img v-if="Icone.disponibilitaProdotto == true" style="width: 80px; height: 80px; float: right"
            :src="require('@/assets/img/DisponibileMaggio23.png')" />
          <img v-if="Icone.restyling == true" style="width: 80px; height: 80px; float: right"
            :src="require('@/assets/img/Restyling.png')" />
        </span>
        <h1>
          {{ codiceComposizione }}
        </h1>
        <div style="width: 20em; font-size: 20px">
          <span v-html="secondaDescrizioneProdotto"> </span>
        </div>
        <h1 class="mt-5" v-if="Utente != '' && Prezzo.prezzoListino != 0">
          Prezzo Listino: <span style="color: #e40520">{{ Prezzo.prezzoListino }} €</span>
        </h1>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { get_prezzo } from "@/api/api.prodotti.js";
import { get_datiUserInfo } from "@/api/api.user.js";

export default {
  props: {
    Url: { type: Object, default: null },
    urlImgProdotto: { type: String, default: "" },
    secondaDescrizioneProdotto: { type: String, default: "" },
    codiceComposizione: { type: String, default: "" },
    Icone: { type: Object, default: null },
  },

  components: {},

  data: () => ({
    //prezzo
    Prezzo: {},
    //utente
    Utente: {},
  }),

  methods: {
    async GetPrezzo() {
      let par = {
        UserId: this.Utente.userId,
        CdArticolo: "ECO" + this.codiceComposizione.replace(/\s/g, ""), //this.Url.CodTestata + this.Url.CodiceFinitura + this.Url.Suffisso,
      };

      let res = await get_prezzo(par);
      this.Prezzo = res.data;
      //set to decimal
      this.Prezzo.prezzoListino = this.Prezzo.prezzoListino.toFixed(2);
      this.Prezzo.prezzoScontato = this.Prezzo.prezzoScontato.toFixed(2);
    },

    async userInfo() {
      try {
        // User info
        let res = await get_datiUserInfo();
        if (res.data != null) {
          this.$store.commit("user/SET_USER", res.data); //dati utente
          this.Utente = res.data;
        }
      } catch (error) {
        console.log(error);
      }
    },

    isTelefono() {
      // visualizzazione telefono
      return window.innerWidth;
    },
  },

  created() { },

  async mounted() {
    await this.userInfo();
    await this.GetPrezzo(); //prezzo dettaglio prodotto
  },

  watch: {
    codiceComposizione() {
      this.GetPrezzo();
    },
  },

  computed: {},
};
</script>
