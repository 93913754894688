<template>
  <v-dialog scrollable persistent v-model="dialogComputed" max-width="65%">
    <v-card tile color="black">
      <v-toolbar dense color="black" style="max-height: 2vh">
        <v-spacer></v-spacer>
        <v-btn style="color: white; margin-top: -2vh" small icon @click="closeDialog()">
          <v-icon>close</v-icon>
        </v-btn>
      </v-toolbar>
      <!-- PDF -->
      <v-card-text
        v-if="type === 'pdf'"
        :style="[
          isTelefono() > 768
            ? { height: '1600px', width: '100%' }
            : { height: '600px', width: '100%' },
        ]"
      >
        <div style="width: 100%; height: 100%; text-align: center">
          <iframe v-if="src" :src="src" width="100%" height="100%"
            >Caricamento....</iframe
          >
        </div>
      </v-card-text>
      <!-- IMG -->
      <v-card-text
        v-if="type === 'png'"
        :style="[
          isTelefono() > 768
            ? { height: '1600px', width: '100%' }
            : { height: '600px', width: '100%' },
        ]"
      >
        <div style="width: 100%; height: 100%; text-align: center">
          <iframe v-if="src" :src="src" width="100%" height="100%"
            >Caricamento....</iframe
          >
        </div>
      </v-card-text>
      <!-- VIDEO -->
      <v-card-text v-if="type === 'video'">
        <div style="width: 100%; padding-top: calc(100% * 9 / 16); position: relative">
          <iframe
            style="position: absolute; left: 0; top: 0; height: 100%; width: 100%"
            :src="src"
            frameborder="0"
            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen
          ></iframe>
        </div>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    dialog: { type: Boolean },
    src: { type: String },
    //nomeDelProdotto: { type: String },
    type: { type: String },
  },
  data() {
    return {
      page: 1,
      pageNo: 1,
      numPages: 0,
      pdfdata: undefined,
      errors: [],
      scale: 1, // 'page-width'
      isLoading: true,
    };
  },

  methods: {
    closeDialog() {
      this.$emit("chiudi");
    },

    isTelefono() {
      return window.innerWidth;
    },
  },

  created() {},

  mounted() {},

  computed: {
    dialogComputed: {
      get() {
        return this.dialog;
      },
      set(value) {
        this.closeDialog();
      },
    },
  },
};
</script>
