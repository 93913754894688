<template>
  <v-app-bar
    :extended="isTelefono() > 1920 ? true : false"
    color="black"
    elevate-on-scroll
    :clipped-left="$vuetify.breakpoint.mdAndUp"
    app
    dark
    fixed
    :style="isTelefono() <= 1920 && isTelefono() > 768 ? { height: '8vh' } : {}"
  >
    <v-app-bar-nav-icon
      v-if="isTelefono() <= 2560"
      :style="[isTelefono() >= 2560 ? { 'margin-top': '4vh' } : {}]"
      x-large
      @click.stop="leftBar()"
    >
    </v-app-bar-nav-icon>
    <v-app-bar-nav-icon v-else style="margin-top: 2.5vh" x-large @click.stop="leftBar()">
    </v-app-bar-nav-icon>

    <v-col cols="12">
      <v-row class="mr-10 mt-0">
        <div
          :style="
            isTelefono() > 1920
              ? { width: '9%', height: '9%', 'margin-top': '4vh' }
              : { width: '11%', height: '11%', 'margin-top': '1vh' }
          "
        >
          <v-img
            v-if="isTelefono() > 768 && isTelefono() < 3840"
            style="cursor: pointer"
            :style="[isTelefono() > 1360 ? { 'margin-left': '5vh' } : {}]"
            :src="require('@/assets/img/logobianco.png')"
            v-on:click="vaiTutti()"
          />

          <v-img
            v-if="isTelefono() === 3840"
            style="cursor: pointer; margin-left: 5vh; margin-top: -1vh"
            :src="require('@/assets/img/logobianco.png')"
            v-on:click="vaiTutti()"
          />
        </div>

        <div style="margin-top: 1vh; margin-left: -2vh">
          <v-img
            v-if="isTelefono() <= 768"
            :style="[isTelefono() > 768 ? {} : { width: '50px' }]"
            :src="require('@/assets/img/Logo-ECOLINEA-solo-maniglia.png')"
            v-on:click="vaiTutti()"
          />
        </div>

        <v-spacer></v-spacer>

        <v-row no-gutters :style="[isTelefono() >= 2560 ? { 'margin-top': '3vh' } : {}]">
          <v-btn hide-details text icon rounded @click="Ricerca()">
            <v-icon :large="isTelefono() >= 2560 ? true : false"> search </v-icon>
          </v-btn>
          <v-autocomplete
            v-if="isTelefono() > 768"
            style="width: auto"
            placeholder="Cerca Prodotto"
            hide-details
            dark
            v-model="Nome.NomeProdotto"
            :items="Prodotti"
            auto-select-first
            clearable
            @change="Ricerca()"
            v-on:keyup.enter="Ricerca()"
          >
          </v-autocomplete>

          <v-autocomplete
            v-if="isTelefono() <= 768"
            style="width: 30px"
            placeholder="Cerca"
            hide-details
            dark
            v-model="Nome.NomeProdotto"
            :items="Prodotti"
            auto-select-first
            @change="Ricerca()"
            v-on:keyup.enter="Ricerca()"
          >
          </v-autocomplete>

          <span
            class="mt-3 ml-3"
            v-if="isTelefono() > 768"
            v-on:click="chiudi()"
            hide-details
            style="cursor: pointer"
            :style="[isTelefono() >= 3840 ? { 'font-size': '20px' } : {}]"
          >
            <u>SFOGLIA IL CATALOGO</u>
          </span>
        </v-row>

        <v-spacer></v-spacer>

        <!-- ACCEDI -->
        <div :style="[isTelefono() >= 2560 ? { 'margin-top': '3vh' } : {}]">
          <v-btn
            v-if="!auth"
            color="primary"
            @click="chiudiDialog()"
            class="elevation-0 mt-1"
            :small="isTelefono() <= 768 ? true : false"
            >Accedi</v-btn
          >
          <div :style="[isTelefono() <= 768 ? { 'margin-top': '-1vh' } : {}]">
            <span
              v-if="auth && isTelefono() > 1050"
              :style="[isTelefono() >= 3840 ? { 'font-size': '20px' } : {}]"
              >{{ utente.ragioneSociale }}</span
            >
            <!-- Menu utente -->
            <v-menu
              bottom
              left
              offset-y
              v-model="showUserMenu"
              :nudge-width="200"
              :close-on-click="true"
              :close-on-content-click="auth ? true : false"
              v-if="auth"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-btn v-bind="attrs" v-on="on" icon dark>
                  <v-icon x-large>account_circle</v-icon>
                </v-btn>
              </template>

              <v-card light>
                <v-list class="pa-0">
                  <v-list-item class="unstyle-link"
                    ><!-- to="/profilo" -->
                    <v-list-item-avatar>
                      <v-icon :x-large="true">account_circle</v-icon>
                    </v-list-item-avatar>
                    <v-list-item-content>
                      <v-list-item-title>Utente:</v-list-item-title>
                      <v-list-item-subtitle>{{
                        utente.ragioneSociale
                      }}</v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                </v-list>

                <v-divider></v-divider>

                <v-card-actions>
                  <v-spacer></v-spacer>

                  <v-btn color="primary" v-on:click="esci()">Esci</v-btn>
                </v-card-actions>
              </v-card>
            </v-menu>
          </div>
        </div>
      </v-row>
    </v-col>

    <!-- LOGIN -->
    <login
      :dialog="dialogLogin"
      @chiudiDialog="chiudiDialog"
      @userInfo="userInfo"
    ></login>

    <!-- SCHEDA TECNICA -->
    <pdf-viewer :dialog="dialog" :src="src" :type="type" @chiudi="chiudi()"> </pdf-viewer>
  </v-app-bar>
</template>

<script>
import {
  get_prodottiRicerca,
  get_listaRicerca,
  get_downloadCatalogo,
} from "@/api/api.prodotti.js";
import { get_datiUserInfo } from "@/api/api.user.js";

import Login from "@/components/Dialog/Login.vue";
import PdfViewer from "@/components/Dialog/PdfViewer.vue";

export default {
  components: {
    Login,
    PdfViewer,
  },

  data() {
    return {
      text: "center",

      isFocused: false,
      dropdownOpen: false,

      Nome: { NomeProdotto: "", CodTestata: "", CodiceFinitura: "", Suffisso: "" },
      listaRicerca: [],
      Prodotti: [],

      //titolo pagina
      pageCurrent: "",

      //dialog login
      dialogLogin: false,
      showUserMenu: false,
      utente: "",
      //dialog pdf catalogo
      dialog: false,
      src: "",
      type: "pdf",
    };
  },

  methods: {
    //ricerca singolo prodotto
    async Ricerca() {
      this.listaRicerca = [];

      //per controllare il cambio pagina per il nome del prodotto cercato
      if (this.$store.getters["app/getBoolPage"] == false) {
        this.$store.commit("app/setBoolPage", true);
      }

      if (this.Nome.NomeProdotto > 0 || this.Nome.NomeProdotto != "") {
        let res = await get_prodottiRicerca(this.Nome);
        this.listaRicerca = res.data;

        this.$store.commit("app/setListaRicerca", this.listaRicerca);
        this.$store.commit("app/setNomeRicerca", this.Nome.NomeProdotto);
        //vado alla pagina della ricerca
        //this.Nome.NomeProdotto = "";
        if (window.location.pathname == "/ricerca/prodotto") {
          this.$router.push("/");
        } else {
          this.$router.push("/ricerca/prodotto").catch(() => {});
        }
      }
    },

    //carico lista ricerca prodotto
    async getProdottiRicerca() {
      this.Prodotti = [];

      let res = await get_listaRicerca();
      this.Prodotti = res.data;
    },

    isTelefono() {
      return window.innerWidth;
      //return this.$i2kFunctions.isSmartphone();
    },

    vaiTutti() {
      this.$router.push("/prodotti/tutto");
    },

    gotoPage(pagina) {
      this.$router.push({ path: pagina });
    },

    leftBar() {
      this.$emit("showLeftBar");
    },

    chiudiDialog() {
      if (this.dialogLogin == true) this.dialogLogin = false;
      else this.dialogLogin = true;
    },

    esci() {
      this.showUserMenu = false;
      this.logout(); //logout
    },

    async userInfo() {
      try {
        // User info
        let res = await get_datiUserInfo();
        if (res.data != null) {
          this.$store.commit("user/SET_USER", res.data); //dati utente
          this.utente = res.data;
        }
      } catch (error) {
        console.log(error);
      }
    },

    async logout() {
      try {
        let res = await this.$store.dispatch("auth/logout");
      } catch (error) {
        console.log(error);
      }

      // Aggiorno la pagina (attualmente non usato)
      //window.location.reload();

      this.$store.commit("user/RESET_USER");
    },

    //catalogo ecolinea
    async Catalogo() {
      let res = await get_downloadCatalogo();
      this.src = res.data;
    },
    // dialog catalogo ecolinea
    async chiudi() {
      if (this.dialog === false) {
        await this.Catalogo();
        this.dialog = true;
      } else this.dialog = false;
    },
  },

  created() {
    if (this.$store.getters["app/getNomePagina"] == "") {
      this.$store.commit("app/setNomePagina", "Home");
    }
    //get lista prodotti da cercare
    this.getProdottiRicerca();
  },

  mounted() {
    this.userInfo();
  },

  computed: {
    getVarBoolPage() {
      return this.$store.getters["app/getBoolPage"];
    },
    auth() {
      return this.$store.getters["auth/isAuthenticated"];
    },
    role() {
      return this.$store.getters["user/getRole"];
    },
    clienteSelezionato() {
      return this.$store.getters["app/getCliente"];
    },
  },

  watch: {
    Nome() {
      return this.Nome;
    },

    getVarBoolPage() {
      //per controllare il cambio pagina per il nome del prodotto cercato
      if (this.$store.getters["app/getBoolPage"] == false) {
        this.Nome.NomeProdotto = "";
      }
    },

    showUserMenu() {
      return this.showUserMenu;
    },
  },
};
</script>
