<template>
  <div>
    <!-- SCROLLBAR PER QUADRO E TONDO (MANIGLIE) -->
    <v-virtual-scroll
      id="scrollable"
      v-if="
        Url.Nome != 'Retrò' &&
        Url.Tipo == 'Maniglie' &&
        Url.NomeProdotto != 'Sion Ribassata' &&
        Url.NomeProdotto != 'Berna Ribassata'
      "
      :bench="benched"
      :items="composizioni"
      item-height="74"
      height="99%"
      style="width: 70em"
    >
      <v-col style="margin-top: -88em; background-color: white" cols="12">
        <v-row style="margin-top: 0em; margin-left: 8em">
          <v-col v-for="(dettaglio, i) in composizioni" :key="i">
            <v-card
              style="width: auto; height: auto; padding: 0px; margin-top: -1.5em"
              class="elevation-0 mb-0"
            >
              <v-img
                v-on:click="getDettComposizione(dettaglio)"
                style="width: 280px; height: 310px; margin-top: 0px; cursor: pointer"
                :src="dettaglio.url"
                :lazy-src="dettaglio.url"
                aspect-ratio="1"
                class="white lighten-2 mb-5"
              >
                <template v-slot:placeholder>
                  <v-row class="fill-height ma-0" align="center" justify="center">
                    <v-progress-circular
                      indeterminate
                      color="black lighten-5"
                    ></v-progress-circular>
                  </v-row>
                </template>
              </v-img>
              <span
                style="font-size: 12px"
                v-if="
                  dettaglio.nomeComposizione != '' &&
                  dettaglio.cdCompletoProdotto != '00000 00 28'
                "
                >{{ dettaglio.nomeComposizione }}
                <v-divider
                  v-if="
                    dettaglio.nomeComposizione != '' &&
                    dettaglio.cdCompletoProdotto != '00000 00 28'
                  "
                ></v-divider>
              </span>
            </v-card>
          </v-col>
        </v-row>
      </v-col>
    </v-virtual-scroll>

    <!-- SCROLLABAR PER SION RIBASSATA E BERNA RIBASSATA -->
    <v-virtual-scroll
      id="scrollable"
      v-if="
        Url.Nome != 'Retrò' &&
        Url.Tipo == 'Maniglie' &&
        (Url.NomeProdotto == 'Sion Ribassata' || Url.NomeProdotto == 'Berna Ribassata')
      "
      :bench="benched"
      :items="composizioni"
      item-height="74"
      height="1500"
      style="width: 70em"
    >
      <v-col style="margin-top: -47em; background-color: white" cols="12">
        <v-row style="margin-top: 0em; margin-left: 8em">
          <v-col v-for="(dettaglio, i) in composizioni" :key="i">
            <v-card
              style="width: auto; height: auto; padding: 0px; margin-top: -1.5em"
              class="elevation-0 mb-0"
            >
              <v-img
                v-on:click="getDettComposizione(dettaglio)"
                style="width: 240px; height: 280px; margin-top: 0px; cursor: pointer"
                :src="dettaglio.url"
                :lazy-src="dettaglio.url"
                aspect-ratio="1"
                class="white lighten-2 mb-5"
              >
                <template v-slot:placeholder>
                  <v-row class="fill-height ma-0" align="center" justify="center">
                    <v-progress-circular
                      indeterminate
                      color="black lighten-5"
                    ></v-progress-circular>
                  </v-row>
                </template>
              </v-img>
              <span
                style="font-size: 12px"
                v-if="
                  dettaglio.nomeComposizione != '' &&
                  dettaglio.nomeComposizione != 'Cremonese T'
                "
                >{{ dettaglio.nomeComposizione }}
                <v-divider
                  v-if="
                    dettaglio.nomeComposizione != '' &&
                    dettaglio.nomeComposizione != 'Cremonese T'
                  "
                ></v-divider>
              </span>
            </v-card>
          </v-col>
        </v-row>
      </v-col>
    </v-virtual-scroll>

    <!-- SCROLLBAR PER RETRO' (MANIGLIE) -->
    <v-virtual-scroll
      id="scrollable"
      v-if="Url.Nome == 'Retrò' && Url.Tipo == 'Maniglie'"
      :bench="benched"
      :items="composizioni"
      item-height="103"
      height="99%"
      style="width: 70em"
    >
      <v-col style="margin-top: -83em; background-color: white" cols="12">
        <v-row style="margin-top: 0em; margin-left: 8em">
          <v-col v-for="(dettaglio, i) in composizioni" :key="i">
            <v-card
              style="width: auto; height: auto; padding: 0px; margin-top: -1.5em"
              class="elevation-0 mb-0"
            >
              <v-img
                v-on:click="getDettComposizione(dettaglio)"
                style="width: 240px; height: 260px; margin-top: 0px; cursor: pointer"
                :src="dettaglio.url"
                :lazy-src="dettaglio.url"
                aspect-ratio="1"
                class="white lighten-2 mb-5"
              >
                <template v-slot:placeholder>
                  <v-row class="fill-height ma-0" align="center" justify="center">
                    <v-progress-circular
                      indeterminate
                      color="black lighten-5"
                    ></v-progress-circular>
                  </v-row>
                </template>
              </v-img>
              <span
                style="font-size: 12px"
                v-if="
                  dettaglio.nomeComposizione != '' &&
                  dettaglio.cdCompletoProdotto != '00000 00 28'
                "
                >{{ dettaglio.nomeComposizione }}
                <v-divider
                  v-if="
                    dettaglio.nomeComposizione != '' &&
                    dettaglio.cdCompletoProdotto != '00000 00 28'
                  "
                ></v-divider>
              </span>
            </v-card>
          </v-col>
        </v-row>
      </v-col>
    </v-virtual-scroll>

    <!-- SCROLLBAR PER QUADRO E TONDO (POMOLI) -->
    <v-virtual-scroll
      id="scrollable"
      v-if="Url.Nome != 'Retrò' && Url.Tipo == 'Pomoli'"
      :bench="benched"
      :items="composizioni"
      item-height="100"
      height="1600"
      style="width: 70em"
    >
      <v-col style="margin-top: -100em; background-color: white" cols="12">
        <v-row style="margin-top: 0em; margin-left: 8em">
          <v-col v-for="(dettaglio, i) in composizioni" :key="i">
            <v-card
              style="width: auto; height: auto; padding: 0px; margin-top: -1.5em"
              class="elevation-0 mb-0"
            >
              <v-img
                v-on:click="getDettComposizione(dettaglio)"
                style="width: 240px; height: 280px; margin-top: 0px; cursor: pointer"
                :src="dettaglio.url"
                :lazy-src="dettaglio.url"
                aspect-ratio="1"
                class="white lighten-2 mb-5"
              >
                <template v-slot:placeholder>
                  <v-row class="fill-height ma-0" align="center" justify="center">
                    <v-progress-circular
                      indeterminate
                      color="black lighten-5"
                    ></v-progress-circular>
                  </v-row>
                </template>
              </v-img>
              <span
                style="font-size: 12px"
                v-if="
                  dettaglio.nomeComposizione != '' &&
                  dettaglio.nomeComposizione != 'Cremonese T'
                "
                >{{ dettaglio.nomeComposizione }}
                <v-divider
                  v-if="
                    dettaglio.nomeComposizione != '' &&
                    dettaglio.nomeComposizione != 'Cremonese T'
                  "
                ></v-divider>
              </span>
            </v-card>
          </v-col>
        </v-row>
      </v-col>
    </v-virtual-scroll>

    <!-- SCROLLBAR PER RETRO' (POMOLI) -->
    <v-virtual-scroll
      id="scrollable"
      v-if="Url.Nome == 'Retrò' && Url.Tipo == 'Pomoli'"
      :bench="benched"
      :items="composizioni"
      item-height="119"
      height="1500"
      style="width: 70em"
    >
      <v-col style="margin-top: -72em; background-color: white" cols="12">
        <v-row style="margin-top: 0em; margin-left: 8em">
          <v-col v-for="(dettaglio, i) in composizioni" :key="i">
            <v-card
              style="width: auto; height: auto; padding: 0px; margin-top: -1.5em"
              class="elevation-0 mb-0"
            >
              <v-img
                v-on:click="getDettComposizione(dettaglio)"
                style="width: 240px; height: 280px; margin-top: 0px; cursor: pointer"
                :src="dettaglio.url"
                :lazy-src="dettaglio.url"
                aspect-ratio="1"
                class="white lighten-2 mb-5"
              >
                <template v-slot:placeholder>
                  <v-row class="fill-height ma-0" align="center" justify="center">
                    <v-progress-circular
                      indeterminate
                      color="black lighten-5"
                    ></v-progress-circular>
                  </v-row>
                </template>
              </v-img>
              <span
                style="font-size: 12px"
                v-if="
                  dettaglio.nomeComposizione != '' &&
                  dettaglio.nomeComposizione != 'Cremonese T'
                "
                >{{ dettaglio.nomeComposizione }}
                <v-divider
                  v-if="
                    dettaglio.nomeComposizione != '' &&
                    dettaglio.nomeComposizione != 'Cremonese T'
                  "
                ></v-divider>
              </span>
            </v-card>
          </v-col>
        </v-row>
      </v-col>
    </v-virtual-scroll>

    <!-- SCROLLBAR PER (MANIGLIONI) -->
    <!-- AYR -->
    <v-virtual-scroll
      id="scrollable"
      v-if="Url.Nome != 'Retrò' && Url.Tipo == 'Maniglioni' && Url.NomeProdotto == 'Ayr'"
      :bench="benched"
      :items="composizioni"
      item-height="210"
      height="1600"
      style="width: 50em"
    >
      <v-col style="margin-top: -70em; background-color: white" cols="12">
        <v-row style="margin-top: 0em; margin-left: 8em">
          <v-col v-for="(dettaglio, i) in composizioni" :key="i">
            <v-card
              style="width: auto; height: auto; padding: 0px; margin-top: -1.5em"
              class="elevation-0 mb-0"
            >
              <v-img
                v-on:click="getDettComposizione(dettaglio)"
                style="width: 210px; height: 560px; margin-top: 10px; cursor: pointer"
                :src="dettaglio.url"
                :lazy-src="dettaglio.url"
                aspect-ratio="1"
                class="white lighten-2 mb-5"
              >
                <template v-slot:placeholder>
                  <v-row class="fill-height ma-0" align="center" justify="center">
                    <v-progress-circular
                      indeterminate
                      color="black lighten-5"
                    ></v-progress-circular>
                  </v-row>
                </template>
              </v-img>
              <span
                style="font-size: 12px"
                v-if="
                  dettaglio.nomeComposizione != '' &&
                  dettaglio.nomeComposizione != 'Cremonese T'
                "
                >{{ dettaglio.nomeComposizione }}
                <v-divider
                  v-if="
                    dettaglio.nomeComposizione != '' &&
                    dettaglio.nomeComposizione != 'Cremonese T'
                  "
                ></v-divider>
              </span>
            </v-card>
          </v-col>
        </v-row>
      </v-col>
    </v-virtual-scroll>

    <!-- BELFAST -->
    <v-virtual-scroll
      id="scrollable"
      v-if="
        Url.Nome != 'Retrò' && Url.Tipo == 'Maniglioni' && Url.NomeProdotto == 'Belfast'
      "
      :bench="benched"
      :items="composizioni"
      item-height="40"
      height="1500"
      style="width: 50em"
    >
      <v-col style="margin-top: -15em; background-color: white" cols="12">
        <v-row style="margin-top: 0em; margin-left: 8em">
          <v-col v-for="(dettaglio, i) in composizioni" :key="i">
            <v-card
              style="width: auto; height: auto; padding: 0px; margin-top: -1.5em"
              class="elevation-0 mb-0"
            >
              <v-img
                v-on:click="getDettComposizione(dettaglio)"
                style="width: 210px; height: 560px; margin-top: 10px; cursor: pointer"
                :src="dettaglio.url"
                :lazy-src="dettaglio.url"
                aspect-ratio="1"
                class="white lighten-2 mb-5"
              >
                <template v-slot:placeholder>
                  <v-row class="fill-height ma-0" align="center" justify="center">
                    <v-progress-circular
                      indeterminate
                      color="black lighten-5"
                    ></v-progress-circular>
                  </v-row>
                </template>
              </v-img>
              <span
                style="font-size: 12px"
                v-if="
                  dettaglio.nomeComposizione != '' &&
                  dettaglio.nomeComposizione != 'Cremonese T'
                "
                >{{ dettaglio.nomeComposizione }}
                <v-divider
                  v-if="
                    dettaglio.nomeComposizione != '' &&
                    dettaglio.nomeComposizione != 'Cremonese T'
                  "
                ></v-divider>
              </span>
            </v-card>
          </v-col>
        </v-row>
      </v-col>
    </v-virtual-scroll>

    <!-- BORDEAUX -->
    <v-virtual-scroll
      id="scrollable"
      v-if="
        Url.Nome != 'Retrò' && Url.Tipo == 'Maniglioni' && Url.NomeProdotto == 'Bordeaux'
      "
      :bench="benched"
      :items="composizioni"
      item-height="100"
      height="1600"
      style="width: 50em"
    >
      <v-col style="margin-top: -20em; background-color: white" cols="12">
        <v-row style="margin-top: 0em; margin-left: 8em">
          <v-col v-for="(dettaglio, i) in composizioni" :key="i">
            <v-card
              style="width: auto; height: auto; padding: 0px; margin-top: -1.5em"
              class="elevation-0 mb-0"
            >
              <v-img
                v-on:click="getDettComposizione(dettaglio)"
                style="width: 410px; height: 760px; margin-top: 10px; cursor: pointer"
                :src="dettaglio.url"
                :lazy-src="dettaglio.url"
                aspect-ratio="1"
                class="white lighten-2 mb-5"
              >
                <template v-slot:placeholder>
                  <v-row class="fill-height ma-0" align="center" justify="center">
                    <v-progress-circular
                      indeterminate
                      color="black lighten-5"
                    ></v-progress-circular>
                  </v-row>
                </template>
              </v-img>
              <span style="font-size: 12px" v-if="dettaglio.nomeComposizione != '02000'"
                >{{ dettaglio.nomeComposizione }}
                <v-divider v-if="dettaglio.nomeComposizione != '02000'"></v-divider>
              </span>
            </v-card>
          </v-col>
        </v-row>
      </v-col>
    </v-virtual-scroll>

    <!-- BRERA -->
    <v-virtual-scroll
      id="scrollable"
      v-if="
        Url.Nome != 'Retrò' && Url.Tipo == 'Maniglioni' && Url.NomeProdotto == 'Brera'
      "
      :bench="benched"
      :items="composizioni"
      item-height="100"
      height="1600"
      style="width: 50em"
    >
      <v-col style="margin-top: -20em; background-color: white" cols="12">
        <v-row style="margin-top: 0em; margin-left: 8em">
          <v-col v-for="(dettaglio, i) in composizioni" :key="i">
            <v-card
              style="width: auto; height: auto; padding: 0px; margin-top: -1.5em"
              class="elevation-0 mb-0"
            >
              <v-img
                v-on:click="getDettComposizione(dettaglio)"
                style="width: 410px; height: 760px; margin-top: 10px; cursor: pointer"
                :src="dettaglio.url"
                :lazy-src="dettaglio.url"
                aspect-ratio="1"
                class="white lighten-2 mb-5"
              >
                <template v-slot:placeholder>
                  <v-row class="fill-height ma-0" align="center" justify="center">
                    <v-progress-circular
                      indeterminate
                      color="black lighten-5"
                    ></v-progress-circular>
                  </v-row>
                </template>
              </v-img>
              <span style="font-size: 12px" v-if="dettaglio.nomeComposizione != '03000'"
                >{{ dettaglio.nomeComposizione }}
                <v-divider v-if="dettaglio.nomeComposizione != '03000'"></v-divider>
              </span>
            </v-card>
          </v-col>
        </v-row>
      </v-col>
    </v-virtual-scroll>

    <!-- BRERA Q -->
    <v-virtual-scroll
      id="scrollable"
      v-if="
        Url.Nome != 'Retrò' && Url.Tipo == 'Maniglioni' && Url.NomeProdotto == 'Brera Q'
      "
      :bench="benched"
      :items="composizioni"
      item-height="100"
      height="1600"
      style="width: 50em"
    >
      <v-col style="margin-top: -20em; background-color: white" cols="12">
        <v-row style="margin-top: 0em; margin-left: 8em">
          <v-col v-for="(dettaglio, i) in composizioni" :key="i">
            <v-card
              style="width: auto; height: auto; padding: 0px; margin-top: -1.5em"
              class="elevation-0 mb-0"
            >
              <v-img
                v-on:click="getDettComposizione(dettaglio)"
                style="width: 410px; height: 760px; margin-top: 10px; cursor: pointer"
                :src="dettaglio.url"
                :lazy-src="dettaglio.url"
                aspect-ratio="1"
                class="white lighten-2 mb-5"
              >
                <template v-slot:placeholder>
                  <v-row class="fill-height ma-0" align="center" justify="center">
                    <v-progress-circular
                      indeterminate
                      color="black lighten-5"
                    ></v-progress-circular>
                  </v-row>
                </template>
              </v-img>
              <span style="font-size: 12px" v-if="dettaglio.nomeComposizione != '03000'"
                >{{ dettaglio.nomeComposizione }}
                <v-divider v-if="dettaglio.nomeComposizione != '03000'"></v-divider>
              </span>
            </v-card>
          </v-col>
        </v-row>
      </v-col>
    </v-virtual-scroll>

    <!-- BRISTOL -->
    <v-virtual-scroll
      id="scrollable"
      v-if="
        Url.Nome != 'Retrò' && Url.Tipo == 'Maniglioni' && Url.NomeProdotto == 'Bristol'
      "
      :bench="benched"
      :items="composizioni"
      item-height="40"
      height="1500"
      style="width: 50em"
    >
      <v-col style="margin-top: -15em; background-color: white" cols="12">
        <v-row style="margin-top: 0em; margin-left: 8em">
          <v-col v-for="(dettaglio, i) in composizioni" :key="i">
            <v-card
              style="width: auto; height: auto; padding: 0px; margin-top: -1.5em"
              class="elevation-0 mb-0"
            >
              <v-img
                v-on:click="getDettComposizione(dettaglio)"
                style="width: 410px; height: 760px; margin-top: 10px; cursor: pointer"
                :src="dettaglio.url"
                :lazy-src="dettaglio.url"
                aspect-ratio="1"
                class="white lighten-2 mb-5"
              >
                <template v-slot:placeholder>
                  <v-row class="fill-height ma-0" align="center" justify="center">
                    <v-progress-circular
                      indeterminate
                      color="black lighten-5"
                    ></v-progress-circular>
                  </v-row>
                </template>
              </v-img>
              <span
                style="font-size: 12px"
                v-if="
                  dettaglio.nomeComposizione != '' &&
                  dettaglio.nomeComposizione != 'Cremonese T'
                "
                >{{ dettaglio.nomeComposizione }}
                <v-divider
                  v-if="
                    dettaglio.nomeComposizione != '' &&
                    dettaglio.nomeComposizione != 'Cremonese T'
                  "
                ></v-divider>
              </span>
            </v-card>
          </v-col>
        </v-row>
      </v-col>
    </v-virtual-scroll>

    <!-- DERBY -->
    <v-virtual-scroll
      id="scrollable"
      v-if="
        Url.Nome != 'Retrò' && Url.Tipo == 'Maniglioni' && Url.NomeProdotto == 'Derby'
      "
      :bench="benched"
      :items="composizioni"
      item-height="40"
      height="1500"
      style="width: 40em"
    >
      <v-col style="margin-top: -15em; background-color: white" cols="12">
        <v-row style="margin-top: 0em; margin-left: 8em">
          <v-col v-for="(dettaglio, i) in composizioni" :key="i">
            <v-card
              style="width: auto; height: auto; padding: 0px; margin-top: -1.5em"
              class="elevation-0 mb-0"
            >
              <v-img
                v-on:click="getDettComposizione(dettaglio)"
                style="width: 410px; height: 760px; margin-top: 10px; cursor: pointer"
                :src="dettaglio.url"
                :lazy-src="dettaglio.url"
                aspect-ratio="1"
                class="white lighten-2 mb-5"
              >
                <template v-slot:placeholder>
                  <v-row class="fill-height ma-0" align="center" justify="center">
                    <v-progress-circular
                      indeterminate
                      color="black lighten-5"
                    ></v-progress-circular>
                  </v-row>
                </template>
              </v-img>
              <span
                style="font-size: 12px"
                v-if="
                  dettaglio.nomeComposizione != '' &&
                  dettaglio.nomeComposizione != 'Cremonese T'
                "
                >{{ dettaglio.nomeComposizione }}
                <v-divider
                  v-if="
                    dettaglio.nomeComposizione != '' &&
                    dettaglio.nomeComposizione != 'Cremonese T'
                  "
                ></v-divider>
              </span>
            </v-card>
          </v-col>
        </v-row>
      </v-col>
    </v-virtual-scroll>

    <!-- DOVER -->
    <v-virtual-scroll
      id="scrollable"
      v-if="
        Url.Nome != 'Retrò' && Url.Tipo == 'Maniglioni' && Url.NomeProdotto == 'Dover'
      "
      :bench="benched"
      :items="composizioni"
      item-height="40"
      height="1500"
      style="width: 50em"
    >
      <v-col style="margin-top: -15em; background-color: white" cols="12">
        <v-row style="margin-top: 0em; margin-left: 8em">
          <v-col v-for="(dettaglio, i) in composizioni" :key="i">
            <v-card
              style="width: auto; height: auto; padding: 0px; margin-top: -1.5em"
              class="elevation-0 mb-0"
            >
              <v-img
                v-on:click="getDettComposizione(dettaglio)"
                style="width: 210px; height: 560px; margin-top: 10px; cursor: pointer"
                :src="dettaglio.url"
                :lazy-src="dettaglio.url"
                aspect-ratio="1"
                class="white lighten-2 mb-5"
              >
                <template v-slot:placeholder>
                  <v-row class="fill-height ma-0" align="center" justify="center">
                    <v-progress-circular
                      indeterminate
                      color="black lighten-5"
                    ></v-progress-circular>
                  </v-row>
                </template>
              </v-img>
              <span
                style="font-size: 12px"
                v-if="
                  dettaglio.nomeComposizione != '' &&
                  dettaglio.nomeComposizione != 'Cremonese T'
                "
                >{{ dettaglio.nomeComposizione }}
                <v-divider
                  v-if="
                    dettaglio.nomeComposizione != '' &&
                    dettaglio.nomeComposizione != 'Cremonese T'
                  "
                ></v-divider>
              </span>
            </v-card>
          </v-col>
        </v-row>
      </v-col>
    </v-virtual-scroll>

    <!-- GLASGOW -->
    <v-virtual-scroll
      id="scrollable"
      v-if="
        Url.Nome != 'Retrò' && Url.Tipo == 'Maniglioni' && Url.NomeProdotto == 'Glasgow'
      "
      :bench="benched"
      :items="composizioni"
      item-height="40"
      height="1500"
      style="width: 50em"
    >
      <v-col style="margin-top: -15em; background-color: white" cols="12">
        <v-row style="margin-top: 0em; margin-left: 8em">
          <v-col v-for="(dettaglio, i) in composizioni" :key="i">
            <v-card
              style="width: auto; height: auto; padding: 0px; margin-top: 0em"
              class="elevation-0 mb-0"
            >
              <v-img
                v-on:click="getDettComposizione(dettaglio)"
                style="width: 410px; height: 760px; margin-top: 10px; cursor: pointer"
                :src="dettaglio.url"
                :lazy-src="dettaglio.url"
                aspect-ratio="1"
                class="white lighten-2 mb-5"
              >
                <template v-slot:placeholder>
                  <v-row class="fill-height ma-0" align="center" justify="center">
                    <v-progress-circular
                      indeterminate
                      color="black lighten-5"
                    ></v-progress-circular>
                  </v-row>
                </template>
              </v-img>
              <span style="font-size: 12px" v-if="dettaglio.nomeComposizione != '0200'"
                >{{ dettaglio.nomeComposizione }}
                <v-divider v-if="dettaglio.nomeComposizione != '0200'"></v-divider>
              </span>
            </v-card>
          </v-col>
        </v-row>
      </v-col>
    </v-virtual-scroll>

    <!-- KIEL -->
    <v-virtual-scroll
      id="scrollable"
      v-if="Url.Nome != 'Retrò' && Url.Tipo == 'Maniglioni' && Url.NomeProdotto == 'Kiel'"
      :bench="benched"
      :items="composizioni"
      item-height="210"
      height="1600"
      style="width: 50em"
    >
      <v-col style="margin-top: -70em; background-color: white" cols="12">
        <v-row style="margin-top: 0em; margin-left: 8em">
          <v-col v-for="(dettaglio, i) in composizioni" :key="i">
            <v-card
              style="width: auto; height: auto; padding: 0px; margin-top: -1.5em"
              class="elevation-0 mb-0"
            >
              <v-img
                v-on:click="getDettComposizione(dettaglio)"
                style="width: 210px; height: 560px; margin-top: 10px; cursor: pointer"
                :src="dettaglio.url"
                :lazy-src="dettaglio.url"
                aspect-ratio="1"
                class="white lighten-2 mb-5"
              >
                <template v-slot:placeholder>
                  <v-row class="fill-height ma-0" align="center" justify="center">
                    <v-progress-circular
                      indeterminate
                      color="black lighten-5"
                    ></v-progress-circular>
                  </v-row>
                </template>
              </v-img>
              <span
                style="font-size: 12px"
                v-if="
                  dettaglio.nomeComposizione != '' &&
                  dettaglio.nomeComposizione != 'Cremonese T'
                "
                >{{ dettaglio.nomeComposizione }}
                <v-divider
                  v-if="
                    dettaglio.nomeComposizione != '' &&
                    dettaglio.nomeComposizione != 'Cremonese T'
                  "
                ></v-divider>
              </span>
            </v-card>
          </v-col>
        </v-row>
      </v-col>
    </v-virtual-scroll>

    <!-- LAHTI -->
    <v-virtual-scroll
      id="scrollable"
      v-if="
        Url.Nome != 'Retrò' && Url.Tipo == 'Maniglioni' && Url.NomeProdotto == 'Lahti'
      "
      :bench="benched"
      :items="composizioni"
      item-height="40"
      height="1500"
      style="width: 50em"
    >
      <v-col style="margin-top: -15em; background-color: white" cols="12">
        <v-row style="margin-top: 0em; margin-left: 8em">
          <v-col v-for="(dettaglio, i) in composizioni" :key="i">
            <v-card
              style="width: auto; height: auto; padding: 0px; margin-top: -1.5em"
              class="elevation-0 mb-0"
            >
              <v-img
                v-on:click="getDettComposizione(dettaglio)"
                style="width: 210px; height: 560px; margin-top: 10px; cursor: pointer"
                :src="dettaglio.url"
                :lazy-src="dettaglio.url"
                aspect-ratio="1"
                class="white lighten-2 mb-5"
              >
                <template v-slot:placeholder>
                  <v-row class="fill-height ma-0" align="center" justify="center">
                    <v-progress-circular
                      indeterminate
                      color="black lighten-5"
                    ></v-progress-circular>
                  </v-row>
                </template>
              </v-img>
              <span
                style="font-size: 12px"
                v-if="
                  dettaglio.nomeComposizione != '' &&
                  dettaglio.nomeComposizione != 'Cremonese T'
                "
                >{{ dettaglio.nomeComposizione }}
                <v-divider
                  v-if="
                    dettaglio.nomeComposizione != '' &&
                    dettaglio.nomeComposizione != 'Cremonese T'
                  "
                ></v-divider>
              </span>
            </v-card>
          </v-col>
        </v-row>
      </v-col>
    </v-virtual-scroll>

    <!-- TOLEDO -->
    <v-virtual-scroll
      id="scrollable"
      v-if="
        Url.Nome != 'Retrò' && Url.Tipo == 'Maniglioni' && Url.NomeProdotto == 'Toledo'
      "
      :bench="benched"
      :items="composizioni"
      item-height="40"
      height="1500"
      style="width: 50em"
    >
      <v-col style="margin-top: -15em; background-color: white" cols="12">
        <v-row style="margin-top: 0em; margin-left: 8em">
          <v-col v-for="(dettaglio, i) in composizioni" :key="i">
            <v-card
              style="width: auto; height: auto; padding: 0px; margin-top: -1.5em"
              class="elevation-0 mb-0"
            >
              <v-img
                v-on:click="getDettComposizione(dettaglio)"
                style="width: 410px; height: 760px; margin-top: 10px; cursor: pointer"
                :src="dettaglio.url"
                :lazy-src="dettaglio.url"
                aspect-ratio="1"
                class="white lighten-2 mb-5"
              >
                <template v-slot:placeholder>
                  <v-row class="fill-height ma-0" align="center" justify="center">
                    <v-progress-circular
                      indeterminate
                      color="black lighten-5"
                    ></v-progress-circular>
                  </v-row>
                </template>
              </v-img>
              <span
                style="font-size: 12px"
                v-if="
                  dettaglio.nomeComposizione != '' &&
                  dettaglio.nomeComposizione != 'Cremonese T'
                "
                >{{ dettaglio.nomeComposizione }}
                <v-divider
                  v-if="
                    dettaglio.nomeComposizione != '' &&
                    dettaglio.nomeComposizione != 'Cremonese T'
                  "
                ></v-divider>
              </span>
            </v-card>
          </v-col>
        </v-row>
      </v-col>
    </v-virtual-scroll>

    <!-- GINEVRA -->
    <v-virtual-scroll
      id="scrollable"
      v-if="
        Url.Nome == 'Retrò' && Url.Tipo == 'Maniglioni' && Url.NomeProdotto == 'Ginevra'
      "
      :bench="benched"
      :items="composizioni"
      item-height="40"
      height="1500"
      style="width: 50em"
    >
      <v-col style="margin-top: -15em; background-color: white" cols="12">
        <v-row style="margin-top: 0em; margin-left: 8em">
          <v-col v-for="(dettaglio, i) in composizioni" :key="i">
            <v-card
              style="width: auto; height: auto; padding: 0px; margin-top: 0em"
              class="elevation-0 mb-0"
            >
              <v-img
                v-on:click="getDettComposizione(dettaglio)"
                style="width: 410px; height: 760px; margin-top: 10px; cursor: pointer"
                :src="dettaglio.url"
                :lazy-src="dettaglio.url"
                aspect-ratio="1"
                class="white lighten-2 mb-5"
              >
                <template v-slot:placeholder>
                  <v-row class="fill-height ma-0" align="center" justify="center">
                    <v-progress-circular
                      indeterminate
                      color="black lighten-5"
                    ></v-progress-circular>
                  </v-row>
                </template>
              </v-img>
              <span style="font-size: 12px" v-if="dettaglio.nomeComposizione != '02000'"
                >{{ dettaglio.nomeComposizione }}
                <v-divider v-if="dettaglio.nomeComposizione != '02000'"></v-divider>
              </span>
            </v-card>
          </v-col>
        </v-row>
      </v-col>
    </v-virtual-scroll>

    <!-- GINEVRA ZANCATO -->
    <v-virtual-scroll
      id="scrollable"
      v-if="
        Url.Nome == 'Retrò' &&
        Url.Tipo == 'Maniglioni' &&
        Url.NomeProdotto == 'Ginevra Zancato'
      "
      :bench="benched"
      :items="composizioni"
      item-height="40"
      height="1500"
      style="width: 50em"
    >
      <v-col style="margin-top: -15em; background-color: white" cols="12">
        <v-row style="margin-top: 0em; margin-left: 8em">
          <v-col v-for="(dettaglio, i) in composizioni" :key="i">
            <v-card
              style="width: auto; height: auto; padding: 0px; margin-top: 0em"
              class="elevation-0 mb-0"
            >
              <v-img
                v-on:click="getDettComposizione(dettaglio)"
                style="width: 410px; height: 760px; margin-top: 10px; cursor: pointer"
                :src="dettaglio.url"
                :lazy-src="dettaglio.url"
                aspect-ratio="1"
                class="white lighten-2 mb-5"
              >
                <template v-slot:placeholder>
                  <v-row class="fill-height ma-0" align="center" justify="center">
                    <v-progress-circular
                      indeterminate
                      color="black lighten-5"
                    ></v-progress-circular>
                  </v-row>
                </template>
              </v-img>
              <span style="font-size: 12px" v-if="dettaglio.nomeComposizione != '02000'"
                >{{ dettaglio.nomeComposizione }}
                <v-divider v-if="dettaglio.nomeComposizione != '02000'"></v-divider>
              </span>
            </v-card>
          </v-col>
        </v-row>
      </v-col>
    </v-virtual-scroll>

    <!-- SCROLLBAR PER (ALZANTI) -->
    <!-- TUTTI -->
    <v-virtual-scroll
      id="scrollable"
      v-if="Url.Tipo == 'Alzanti'"
      :bench="benched"
      :items="composizioni"
      item-height="100"
      height="1500"
      style="width: 50em"
    >
      <v-col style="margin-top: 0em; background-color: white" cols="12">
        <v-row style="margin-top: 0em; margin-left: 8em">
          <v-col v-for="(dettaglio, i) in composizioni" :key="i">
            <v-card
              style="width: auto; height: auto; padding: 0px; margin-top: -1.5em"
              class="elevation-0 mb-0"
            >
              <v-img
                v-on:click="getDettComposizione(dettaglio)"
                style="
                  width: 400px;
                  height: 500px;
                  margin-top: 10px;
                  cursor: pointer;
                  margin-left: 9em;
                "
                :src="dettaglio.url"
                :lazy-src="dettaglio.url"
                aspect-ratio="1"
                class="white lighten-2 mb-5"
              >
                <template v-slot:placeholder>
                  <v-row class="fill-height ma-0" align="center" justify="center">
                    <v-progress-circular
                      indeterminate
                      color="black lighten-5"
                    ></v-progress-circular>
                  </v-row>
                </template>
              </v-img>
              <span style="font-size: 12px" v-if="dettaglio.nomeComposizione != '02000'"
                >{{ dettaglio.nomeComposizione }}
                <v-divider v-if="dettaglio.nomeComposizione != '02000'"></v-divider>
              </span>
            </v-card>
          </v-col>
        </v-row>
      </v-col>
    </v-virtual-scroll>

    <!-- SCROLLBAR PER SCORREVOLI -->
    <!-- TUTTI -->
    <v-virtual-scroll
      id="scrollable"
      v-if="Url.Tipo == 'Scorrevoli'"
      :bench="benched"
      :items="composizioni"
      item-height="117"
      height="1500"
      style="width: 70em"
    >
      <v-col style="margin-top: -40em; background-color: white" cols="12">
        <v-row style="margin-top: 0em; margin-left: 8em">
          <v-col v-for="(dettaglio, i) in composizioni" :key="i">
            <v-card
              style="width: auto; height: auto; padding: 0px; margin-top: -1.5em"
              class="elevation-0 mb-0"
            >
              <v-img
                v-on:click="getDettComposizione(dettaglio)"
                style="width: 350px; height: auto; margin-top: 10px; cursor: pointer"
                :src="dettaglio.url"
                :lazy-src="dettaglio.url"
                aspect-ratio="1"
                class="white lighten-2 mb-5"
              >
                <template v-slot:placeholder>
                  <v-row class="fill-height ma-0" align="center" justify="center">
                    <v-progress-circular
                      indeterminate
                      color="black lighten-5"
                    ></v-progress-circular>
                  </v-row>
                </template>
              </v-img>
              <span style="font-size: 12px" v-if="dettaglio.nomeComposizione != '99'"
                >{{ dettaglio.nomeComposizione }}
                <v-divider v-if="dettaglio.nomeComposizione != '99'"></v-divider>
              </span>
            </v-card>
          </v-col>
        </v-row>
      </v-col>
    </v-virtual-scroll>
  </div>
</template>

<script>
export default {
  props: {
    Url: { type: Object, default: null },
    benched: { type: Number, default: 0 },
    datiComposizioni: { type: Array, default: null },
  },

  components: {},

  data: () => ({}),

  methods: {
    isTelefono() {
      // visualizzazione telefono
      return window.innerWidth;
    },

    getDettComposizione(item) {
      this.$emit("getDettComposizione", item);
    },
  },

  created() {},

  mounted() {},

  computed: {
    composizioni() {
      let res = this.datiComposizioni;
      return res;
    },
  },
};
</script>
