import request from '@/services/interceptor.js'


// --------------------
// Prodotti
// --------------------
export const get_prodotti = (par) => {
    return request({
        url: '/prodotti',
        method: 'post',
        data: par,
    })
}

export const get_prodottiRicerca = (par) => {
    return request({
        url: '/prodotti/ricerca',
        method: 'post',
        data: par,
    })
}

//finiture e colore finiture
export const get_dettFinitura = (par) => {
    return request({
        url: '/prodotti/finitura',
        method: 'post',
        data: par,
    })
}

//finiture
export const get_finiture = (par) => {
    return request({
        url: '/prodotti/finiture',
        method: 'post',
        data: par,
    })
}

//composizioni
export const get_composizione = (par) => {
    return request({
        url: '/prodotti/composizioni',
        method: 'post',
        data: par,
    })
}

//composizioni e colore composizioni
export const get_dettComposizione = (par) => {
    return request({
        url: '/prodotti/composizione',
        method: 'post',
        data: par,
    })
}

//lista prodotti ricerca
export const get_listaRicerca = () => {
    return request({
        url: '/prodotti/lista/ricerca',
        method: 'get',
    })
}

//download scheda tecnica PDF
export const get_downloadPdf = (par, data) => {
    return request({
        url: 'prodotti/download/Pdf/' + par,
        method: 'post',
        data: data,
    })
};

//download catalogo pdf ecolinea
export const get_downloadCatalogo = () => {
    return request({
        url: 'prodotti/download/Catalogo/Pdf',
        method: 'get',
    })
};

//download img composizione
export const get_downloadImgComposizione = (par, data) => {
    return request({
        url: 'prodotti/download/composizione/' + par,
        method: 'post',
        data: data,
    })
};

//descrizione prodotto dettaglio
export const get_descrizione = (par, data) => {
    return request({
        url: 'prodotti/descrizione/' + par,
        method: 'post',
        data: data,
    })
};

//icon prodotto dettaglio
export const get_icone = (par, data) => {
    return request({
        url: 'prodotti/icone/' + par,
        method: 'post',
        data: data,
    })
};

//prezzo dettaglio prodotto
export const get_prezzo = (data) => {
    return request({
        url: 'prodotti/prezzo',
        method: 'post',
        data: data,
    })
};

//filtri menu 'Finiture'
export const get_filtriFiniture = () => {
    return request({
        url: 'prodotti/menu/finiture',
        method: 'get',
    })
};

//prodotti indice con filtro finiture
export const get_prodottiFiniture = (data) => {
    return request({
        url: 'prodotti/filtro/finitura',
        method: 'post',
        data: data,
    })
};






//pagina Qr
export const get_parametriDettProdotto = (par, data) => {
    return request({
        url: 'prodotti/qr/' + par,
        method: 'post',
        data: data,
    })
};