export default {
    methods: {
        getFinitura() {
            return {
                codice: 0,
                finiture: "",
                id: 0,
                nome: "",
                nomeProdotto: "",
                tipo: "",
                tipoFinitura: "",
                url: "",
            }
        },

        //TUTTO
        getFiltriMenuTutto() {
            return {
                nomeProdotto: "",
                nomeProdotto2: "",
                nomeProdotto3: "",
                text: "Tutti i Modelli",
                url: "",
                finitura: "",
                codiceFinitura: "",
            }
        },

        //MANIGLIE
        getFiltriMenuManiglie() {
            return {
                nomeProdotto: "",
                nomeProdotto2: "",
                nomeProdotto3: "",
                text: "Maniglie",
                url: "",
                finitura: "",
                codiceFinitura: "",
            }
        },

        //POMOLI
        getFiltriMenuPomoli() {
            return {
                nomeProdotto: "",
                nomeProdotto2: "",
                nomeProdotto3: "",
                text: "Pomoli",
                url: "",
                finitura: "",
                codiceFinitura: "",
            }
        },

        //MANIGLIONI
        getFiltriMenuManiglioni() {
            return {
                nomeProdotto: "",
                nomeProdotto2: "",
                nomeProdotto3: "",
                text: "Maniglioni",
                url: "",
                finitura: "",
                codiceFinitura: "",
            }
        },

        //ALZANTI
        getFiltriMenuAlzanti() {
            return {
                nomeProdotto: "",
                nomeProdotto2: "",
                nomeProdotto3: "",
                text: "Alzanti",
                url: "",
                finitura: "",
                codiceFinitura: "",
            }
        },

        //SCORREVOLI
        getFiltriMenuScorrevoli() {
            return {
                nomeProdotto: "",
                nomeProdotto2: "",
                nomeProdotto3: "",
                text: "Scorrevoli",
                url: "",
                finitura: "",
                codiceFinitura: "",
            }
        },

        //QUADRO - TONDO - RETRO' - STANDARD - PVD - RETRO
        getFiltriMenuAll() {
            return {
                nomeProdotto: "",
                nomeProdotto2: "",
                nomeProdotto3: "",
                text: "Quadro",
                url: "",
                finitura: "",
                codiceFinitura: "",
            }
        },

        //DETTAGLIO PRODOTTO ICONE
        getIcone() {
            return {
                novitaProdotto: false,
                disponibilitaProdotto: false,
                restyling: false
            }
        },
    },
}