<template>
  <div style="min-width: 100%">
    <!-- FINITURE PER DETTAGLIO PRODOTTO NO MANIGLIONE / ALZANTE -->
    <v-row
      v-if="Url.Tipo != 'Maniglioni' && Url.Tipo != 'Alzanti'"
      class="d-flex child-fle"
      style="margin-left: 10%; margin-top: -20%"
    >
      <!-- FINITURE STANDARD -->
      <v-col cols="6" v-if="finitureStandard != 0">
        <v-row>
          <h4 style="margin-top: 0em; text-align: left; margin-right: 0%">
            Finiture Standard
          </h4>
        </v-row>
        <br />

        <v-col
          :style="[
            isTelefono() <= 768
              ? { 'min-width': '20%', 'margin-top': '0%' }
              : { 'min-width': '20%', 'margin-top': '0%' },
          ]"
          style="padding: 0"
          v-for="(dettaglio, i) in finitureStandard"
          :key="i"
          class="d-flex child-flex"
          cols="12"
        >
          <v-row>
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <!-- SI SCORREVOLI -->
                <!-- margin-left: 15%; -->
                <v-card
                  v-if="
                    dettaglio.nomeProdotto == 'Kit Sochi' ||
                    dettaglio.nomeProdotto == 'Kit Sochi Q' ||
                    dettaglio.nomeProdotto == 'Kit Ginevra'
                  "
                  style="
                    width: auto;
                    height: auto;
                    padding: 0px;
                    margin-left: 0%;
                    margin-bottom: 3em;
                  "
                  class="elevation-0"
                >
                  <v-img
                    v-on:click="getDettFiniture(dettaglio)"
                    v-bind="attrs"
                    v-on="on"
                    style="width: 55px; height: auto; cursor: pointer"
                    :src="dettaglio.url"
                    :lazy-src="dettaglio.url"
                  >
                    <template v-slot:placeholder>
                      <v-row class="fill-height ma-0" align="center" justify="center">
                        <v-progress-circular
                          indeterminate
                          color="black lighten-5"
                        ></v-progress-circular>
                      </v-row>
                    </template>
                  </v-img>
                </v-card>

                <!-- NO SCORREVOLI -->
                <v-card
                  v-else
                  style="
                    width: auto;
                    height: auto;
                    padding: 0px;
                    margin-left: -0.5em;
                    margin-bottom: 3em;
                  "
                  class="elevation-0"
                >
                  <v-img
                    v-on:click="getDettFiniture(dettaglio)"
                    v-bind="attrs"
                    v-on="on"
                    style="width: 120px; height: auto; cursor: pointer"
                    :src="dettaglio.url"
                    :lazy-src="dettaglio.url"
                  >
                    <template v-slot:placeholder>
                      <v-row class="fill-height ma-0" align="center" justify="center">
                        <v-progress-circular
                          indeterminate
                          color="black lighten-5"
                        ></v-progress-circular>
                      </v-row>
                    </template>
                  </v-img>
                </v-card>
              </template>
              <span>{{ dettaglio.tipoFinitura }}</span>
            </v-tooltip>
          </v-row>
        </v-col>
      </v-col>

      <!-- FINITURE PVD -->
      <v-col cols="6" v-if="finiturePvd != 0" style="margin-left: 0%">
        <v-row>
          <h4 style="margin-top: 0em; text-align: left; margin-right: 0%">
            Finiture PVD
          </h4>
        </v-row>
        <br />
        <v-col
          :style="[
            isTelefono() <= 768
              ? { 'min-width': '20%', 'margin-top': '0%' }
              : { 'min-width': '20%', 'margin-top': '0%' },
          ]"
          style="padding: 0"
          v-for="(dettaglio, i) in finiturePvd"
          :key="i"
          class="d-flex child-flex"
          cols="12"
        >
          <v-row>
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <!-- SI SCORREVOLI -->
                <!-- margin-left: 7.5%; -->
                <v-card
                  v-if="
                    dettaglio.nomeProdotto == 'Kit Sochi' ||
                    dettaglio.nomeProdotto == 'Kit Sochi Q' ||
                    dettaglio.nomeProdotto == 'Kit Ginevra'
                  "
                  style="
                    width: auto;
                    height: auto;
                    padding: 0px;
                    margin-left: 0%;
                    margin-bottom: 3em;
                  "
                  class="elevation-0"
                >
                  <v-img
                    v-on:click="getDettFiniture(dettaglio)"
                    v-bind="attrs"
                    v-on="on"
                    style="width: 55px; height: auto; cursor: pointer"
                    :src="dettaglio.url"
                    :lazy-src="dettaglio.url"
                  >
                    <template v-slot:placeholder>
                      <v-row class="fill-height ma-0" align="center" justify="center">
                        <v-progress-circular
                          indeterminate
                          color="black lighten-5"
                        ></v-progress-circular>
                      </v-row>
                    </template>
                  </v-img>
                </v-card>

                <!-- NO SCORREVOLI -->
                <v-card
                  v-else
                  style="
                    width: auto;
                    height: auto;
                    padding: 0px;
                    margin-left: -0.5em;
                    margin-bottom: 3em;
                  "
                  class="elevation-0"
                >
                  <v-img
                    v-on:click="getDettFiniture(dettaglio)"
                    v-bind="attrs"
                    v-on="on"
                    style="width: 120px; height: auto; cursor: pointer"
                    :src="dettaglio.url"
                    :lazy-src="dettaglio.url"
                  >
                    <template v-slot:placeholder>
                      <v-row class="fill-height ma-0" align="center" justify="center">
                        <v-progress-circular
                          indeterminate
                          color="black lighten-5"
                        ></v-progress-circular>
                      </v-row>
                    </template>
                  </v-img>
                </v-card>
              </template>
              <span>{{ dettaglio.tipoFinitura }}</span>
            </v-tooltip>
          </v-row>
        </v-col>
      </v-col>

      <!-- FINITURE RETRO' -->
      <v-col cols="6" v-if="finitureRetro != 0" style="margin-left: 0%">
        <v-row>
          <h4 style="margin-top: 0em; text-align: left; margin-right: 0%">
            Finiture Retrò
          </h4>
        </v-row>
        <br />
        <v-col
          :style="[
            isTelefono() <= 768
              ? { 'min-width': '20%', 'margin-top': '0%' }
              : { 'min-width': '20%', 'margin-top': '0%' },
          ]"
          style="padding: 0"
          v-for="(dettaglio, i) in finitureRetro"
          :key="i"
          class="d-flex child-flex"
          cols="12"
        >
          <v-row>
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <!-- SI SCORREVOLI -->
                <!-- margin-left: 10%; -->
                <v-card
                  v-if="
                    dettaglio.nomeProdotto == 'Kit Sochi' ||
                    dettaglio.nomeProdotto == 'Kit Sochi Q' ||
                    dettaglio.nomeProdotto == 'Kit Ginevra'
                  "
                  style="
                    width: auto;
                    height: auto;
                    padding: 0px;
                    margin-left: 0%;
                    margin-bottom: 3em;
                  "
                  class="elevation-0"
                >
                  <v-img
                    v-on:click="getDettFiniture(dettaglio)"
                    v-bind="attrs"
                    v-on="on"
                    style="width: 55px; height: auto; cursor: pointer"
                    :src="dettaglio.url"
                    :lazy-src="dettaglio.url"
                  >
                    <template v-slot:placeholder>
                      <v-row class="fill-height ma-0" align="center" justify="center">
                        <v-progress-circular
                          indeterminate
                          color="black lighten-5"
                        ></v-progress-circular>
                      </v-row>
                    </template>
                  </v-img>
                </v-card>

                <!-- NO SCORREVOLI -->
                <v-card
                  v-else
                  style="
                    width: auto;
                    height: auto;
                    padding: 0px;
                    margin-left: -0.5em;
                    margin-bottom: 3em;
                  "
                  class="elevation-0"
                >
                  <v-img
                    v-on:click="getDettFiniture(dettaglio)"
                    v-bind="attrs"
                    v-on="on"
                    style="width: 120px; height: auto; cursor: pointer"
                    :src="dettaglio.url"
                    :lazy-src="dettaglio.url"
                  >
                    <template v-slot:placeholder>
                      <v-row class="fill-height ma-0" align="center" justify="center">
                        <v-progress-circular
                          indeterminate
                          color="black lighten-5"
                        ></v-progress-circular>
                      </v-row>
                    </template>
                  </v-img>
                </v-card>
              </template>
              <span>{{ dettaglio.tipoFinitura }}</span>
            </v-tooltip>
          </v-row>
        </v-col>
      </v-col>
    </v-row>

    <!-- FINITURE PER DETTAGLIO PRODOTTO SI MANIGLIONI / ALZANTI -->
    <v-col
      cols="12"
      v-if="Url.Tipo == 'Maniglioni' || Url.Tipo == 'Alzanti'"
      style="max-width: 100%; margin-left: -2vh; margin-top: -5vh"
    >
      <!-- FINITURE STANDARD -->
      <v-row v-if="finitureStandard != 0">
        <div>
          <h4 style="margin-top: 0em; text-align: left; margin-left: 0em">
            Finiture Standard
          </h4>
        </div>
        <br />

        <!-- MANIGLIONI / ALZANTI DIVERSI -->
        <v-sheet
          v-if="
            Url.NomeProdotto == 'Bristol' ||
            Url.NomeProdotto == 'Derby' ||
            Url.NomeProdotto == 'Ginevra' ||
            Url.NomeProdotto == 'Ginevra Zancato'
          "
          elevation="0"
          max-width="105%"
          min-height="100%"
          style="margin-top: 2em; margin-left: -6.5em"
        >
          <v-slide-group show-arrows v-model="finitureStandard">
            <v-slide-item
              class="pa-0"
              v-for="(dettaglio, i) in finitureStandard"
              :key="i"
            >
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-card
                    elevation="0"
                    style="width: auto; height: auto"
                    v-bind="attrs"
                    v-on="on"
                  >
                    <v-img
                      v-on:click="getDettFiniture(dettaglio)"
                      style="width: 50px; height: 150px; cursor: pointer"
                      :src="dettaglio.url"
                      :lazy-src="dettaglio.url"
                      aspect-ratio="1"
                    >
                      <template v-slot:placeholder>
                        <v-row class="fill-height ma-0" align="center" justify="center">
                          <v-progress-circular
                            indeterminate
                            color="black lighten-5"
                          ></v-progress-circular>
                        </v-row>
                      </template>
                    </v-img>
                  </v-card>
                </template>
                <span>{{ dettaglio.tipoFinitura }}</span>
              </v-tooltip>
            </v-slide-item>
          </v-slide-group>
        </v-sheet>

        <!-- MANIGLIONI / ALZANTI OK -->
        <v-sheet
          v-if="
            Url.NomeProdotto != 'Bristol' &&
            Url.NomeProdotto != 'Derby' &&
            Url.NomeProdotto != 'Ginevra' &&
            Url.NomeProdotto != 'Ginevra Zancato'
          "
          elevation="0"
          max-width="110%"
          min-height="100%"
          :style="[
            Url.NomeProdotto == 'Dover' ||
            Url.NomeProdotto == 'Belfast' ||
            Url.NomeProdotto == 'Lahti'
              ? { 'margin-left': '-2em' }
              : { 'margin-left': '-6.5em', 'margin-top': '2em' },
          ]"
          style="margin-top: 0em"
        >
          <v-slide-group show-arrows v-model="finitureStandard">
            <v-slide-item
              class="pa-0"
              v-for="(dettaglio, i) in finitureStandard"
              :key="i"
            >
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-card
                    elevation="0"
                    style="width: auto; height: auto"
                    v-bind="attrs"
                    v-on="on"
                  >
                    <v-img
                      v-on:click="getDettFiniture(dettaglio)"
                      style="width: 50px; height: 150px; cursor: pointer"
                      :src="dettaglio.url"
                      :lazy-src="dettaglio.url"
                      aspect-ratio="1"
                    >
                      <template v-slot:placeholder>
                        <v-row class="fill-height ma-0" align="center" justify="center">
                          <v-progress-circular
                            indeterminate
                            color="black lighten-5"
                          ></v-progress-circular>
                        </v-row>
                      </template>
                    </v-img>
                  </v-card>
                </template>
                <span>{{ dettaglio.tipoFinitura }}</span>
              </v-tooltip>
            </v-slide-item>
          </v-slide-group>
        </v-sheet>
      </v-row>

      <!-- FINITURE PVD -->
      <v-row v-if="finiturePvd != 0">
        <div>
          <h4 style="margin-top: 0em; text-align: left; margin-left: 0em">
            Finiture PVD
          </h4>
        </div>
        <br />

        <!-- max-width="95%" -->
        <v-sheet
          elevation="0"
          min-height="100%"
          style="margin-top: 2em; margin-left: -4.5em"
        >
          <v-slide-group show-arrows v-model="finiturePvd">
            <v-slide-item class="pa-0" v-for="(dettaglio, i) in finiturePvd" :key="i">
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-card
                    elevation="0"
                    style="width: auto; height: auto"
                    v-bind="attrs"
                    v-on="on"
                  >
                    <v-img
                      v-on:click="getDettFiniture(dettaglio)"
                      style="width: 50px; height: 150px; cursor: pointer"
                      :src="dettaglio.url"
                      :lazy-src="dettaglio.url"
                      aspect-ratio="1"
                    >
                      <template v-slot:placeholder>
                        <v-row class="fill-height ma-0" align="center" justify="center">
                          <v-progress-circular
                            indeterminate
                            color="black lighten-5"
                          ></v-progress-circular>
                        </v-row>
                      </template>
                    </v-img>
                  </v-card>
                </template>
                <span>{{ dettaglio.tipoFinitura }}</span>
              </v-tooltip>
            </v-slide-item>
          </v-slide-group>
        </v-sheet>
      </v-row>

      <!-- FINITURE RETRO' -->
      <v-row v-if="finitureRetro != 0">
        <div>
          <h4 style="margin-top: 0em; text-align: left; margin-left: 0em">
            Finiture Retrò
          </h4>
        </div>
        <br />

        <!-- max-width="95%" -->
        <v-sheet
          elevation="0"
          min-height="100%"
          style="margin-top: 2em; margin-left: -5.5em"
        >
          <v-slide-group show-arrows v-model="finitureRetro">
            <v-slide-item class="pa-0" v-for="(dettaglio, i) in finitureRetro" :key="i">
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-card
                    elevation="0"
                    style="width: auto; height: auto"
                    v-bind="attrs"
                    v-on="on"
                  >
                    <v-img
                      v-on:click="getDettFiniture(dettaglio)"
                      style="width: 50px; height: 150px; cursor: pointer"
                      :src="dettaglio.url"
                      :lazy-src="dettaglio.url"
                      aspect-ratio="1"
                    >
                      <template v-slot:placeholder>
                        <v-row class="fill-height ma-0" align="center" justify="center">
                          <v-progress-circular
                            indeterminate
                            color="black lighten-5"
                          ></v-progress-circular>
                        </v-row>
                      </template>
                    </v-img>
                  </v-card>
                </template>
                <span>{{ dettaglio.tipoFinitura }}</span>
              </v-tooltip>
            </v-slide-item>
          </v-slide-group>
        </v-sheet>
      </v-row>
    </v-col>
  </div>
</template>

<script>
export default {
  props: {
    Url: { type: Object, default: null },
    finitureStandard: { type: Array, default: null },
    finiturePvd: { type: Array, default: null },
    finitureRetro: { type: Array, default: null },
  },

  components: {},

  data: () => ({}),

  methods: {
    isTelefono() {
      // visualizzazione telefono
      return window.innerWidth;
    },

    getDettFiniture(item) {
      this.$emit("getDettFiniture", item);
    },
  },

  created() {},

  mounted() {},

  computed: {},
};
</script>
