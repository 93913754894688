<template>
  <v-dialog v-model="dialog" persistent max-width="700px" mix-height="1000px">
    <v-card elevation="0">
      <!-- <v-card :style="[isTelefono() ? { height: '850px', width: '100%' } : {}]"> -->
      <v-toolbar dense dark color="black" style="height: 60px">
        <!-- <v-icon style="color: black; font-size: 30px;">local_shipping</v-icon> -->
        <div class="mt-4" style="position: absolute; width: auto; margin-left: 5px">
          <v-img style="width: 60px" :src="require('@/assets/img/3d_Bianco.png')"></v-img>
        </div>
        <v-spacer></v-spacer>
        <v-btn
          class="mt-3"
          style="margin-right: 0em; margin-left: 44.5em"
          fab
          color="transparent"
          elevation="0"
          @click="chiudiDialog()"
        >
          <v-icon color="white" large> close </v-icon>
        </v-btn>
      </v-toolbar>

      <!-- IMMAGINA 360 -->
      <!-- <v-card elevation="0"> -->

      <!-- src="http://www.ecolineamaniglie.com/immagini/3D/Sochi%20XR%20prova%20SFER%20OGG%20NOROTAMB%20360.533.html" -->
      <iframe style="width: 100%; height: 700px" :src="getUrlDettaglio()" />
      <!-- </v-card> -->
      <!-- </v-card> -->
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    dialog: { type: Boolean, default: false },
  },

  data: () => ({
    keyshotXR: "",
    blockEvent: true,
  }),

  methods: {
    chiudiDialog() {
      this.$emit("chiudiDialog");
    },

    getUrlDettaglio() {
      return (
        this.$load.getUrlDettProd() +
        "/immagini/3D/Sochi%20XR%20prova%20SFER%20OGG%20NOROTAMB%20360.533.html"
      );
    },

    if(blockEvent) {
      var overlay = document.getElementById("overlay");

      document.addEventListener(
        "mousedown",
        function (e) {
          overlay.style.pointerEvents = "none";
        },
        false
      );

      document.addEventListener(
        "mouseup",
        function (e) {
          overlay.style.pointerEvents = "auto";
        },
        false
      );
    },

    initKeyShotXR() {
      var nameOfDiv = "KeyShotXR";
      var folderName = "Sochi XR prova SFER OGG NOROTAMB 360.533";
      var viewPortWidth = 1000;
      var viewPortHeight = 1000;
      var backgroundColor = "#FFFFFF";
      var uCount = 16;
      var vCount = 16;
      var uWrap = true;
      var vWrap = false;
      var uMouseSensitivity = -0.0444444;
      var vMouseSensitivity = 0.1;
      var uStartIndex = 8;
      var vStartIndex = 10;
      var minZoom = 1;
      var maxZoom = 1;
      var rotationDamping = 0.96;
      var downScaleToBrowser = true;
      var addDownScaleGUIButton = false;
      var downloadOnInteraction = false;
      var imageExtension = "png";
      var showLoading = true;
      var loadingIcon = "Ecolinea.png"; // Set to empty string for default icon.
      var allowFullscreen = true; // Double-click in desktop browsers for fullscreen.
      var uReverse = false;
      var vReverse = false;
      var hotspots = {};
      var isIBooksWidget = false;

      this.keyshotXR = new keyshotXR(
        nameOfDiv,
        folderName,
        viewPortWidth,
        viewPortHeight,
        backgroundColor,
        uCount,
        vCount,
        uWrap,
        vWrap,
        uMouseSensitivity,
        vMouseSensitivity,
        uStartIndex,
        vStartIndex,
        minZoom,
        maxZoom,
        rotationDamping,
        downScaleToBrowser,
        addDownScaleGUIButton,
        downloadOnInteraction,
        imageExtension,
        showLoading,
        loadingIcon,
        allowFullscreen,
        uReverse,
        vReverse,
        hotspots,
        isIBooksWidget
      );

      window.onload = initKeyShotXR;
    },

    isTelefono() {
      return window.innerWidth;
      //return this.$i2kFunctions.isSmartphone();
    },
  },

  created() {},

  mounted() {},

  computed: {},

  watch: {},
};
</script>
