<template>
  <div style="margin-left: 5%; margin-right: 5%; margin-top: 3%; background-color: #999">
    <h1
      style="
        margin-left: -5%;
        margin-right: -5%;
        background-color: #ce0538;
        color: white;
        text-align: center;
      "
    >
      PRIVACY
    </h1>
    <br />
    <p style="margin-left: 3%; margin-right: 3%">
      <b>1 - SOGGETTI DEL TRATTAMENTO</b><br />
      TITOLARE DEL TRATTAMENTO, ai sensi dell’art. 28 del D. Lgs. 196/03 nonché degli
      artt. 4 e 24 del Regolamento (UE) 679/2016 è Battisti Francesco S.n.c. con sede
      legale in Via San Martino,78 12032 Barge (CN) nella persona del legale
      rappresentante. RESPONSABILE DEL TRATTAMENTO, ai sensi dell’art. 29 del D. Lgs.
      196/03 e degli artt. 4 e 28 del Regolamento (UE) 679/2016, è Battisti Franco.
      <br /><br /><v-divider style="background-color: white"></v-divider> <br /><b
        >2 - FINALITA' E LICEITA' DEL TRATTAMENTO</b
      ><br />
      I dati di natura personale forniti, previo consenso, saranno oggetto di trattamento
      nel rispetto delle condizioni di liceità del trattamento ex art. 6 GDPR, per
      attività finalità di gestione operativa, amministrativa, contabile e di adempimento
      di obblighi contrattuali, di legge e per l’esercizio di diritti in sede giudiziaria;
      inoltre sono finalizzati ad attività di marketing diretto, ricerche di mercato,
      attività promozionali in genere o altre ricerche campionarie e vendita diretta per
      ricevere - tramite mezzi automatizzati posta elettronica, telefax, messaggi del tipo
      MMS (Multimedia Messaging Service) o SMS (Short Message Service) o di altro tipo,
      nonché mediante telefonate tramite operatore e della posta cartacea - materiale
      informativo, per la rilevazione del grado di soddisfazione, materiale promozionale,
      commerciale e pubblicitario o inerente eventi ed iniziative, da parte di Battisti
      Francesco S.n.c.. Il trattamento dati per tale finalità risponde all’art. 6 comma 1
      lett. a della L. 2016/679.
      <br /><br /><v-divider style="background-color: white"></v-divider> <br /><b
        >3 - DESTINATARI O CATEGORIE DEI DESTINATARI DEI DATI</b
      ><br />
      I dati di natura personale forniti, potranno essere comunicati a destinatari che
      tratteranno i dati in qualità di responsabili del trattamento, nominati ex art. 29
      del D. Lgs. 196/03 o ex art. 28 del Regolamento UE 679/2016 e/o in qualità di
      persone fisiche che agiscono sotto l’autorità del titolare e del responsabile.
      Precisamente, i dati potranno essere comunicati a destinatari appartenenti alle
      seguenti categorie: - soggetti che forniscono servizi per la gestione del sistema
      informativo usato dal Titolare del trattamento; - liberi professionisti che
      collaborano con il Titolare del trattamento. I soggetti appartenenti alle categorie
      suddette svolgono la funzione di Responsabile del trattamento dei dati, oppure
      operano in totale autonomia come distinti Titolari del trattamento.
      <br /><br /><v-divider style="background-color: white"></v-divider> <br /><b
        >4 - TRASFERIMENTO DATI VERSO UN PAESE TERZO E/O UN ORGANIZZAZIONE
        INTERNAZIONALE</b
      ><br />
      I dati di natura personale forniti, potranno essere trasferiti all’estero
      all’interno o all’esterno dell’Unione Europea, nei limiti e alle condizioni di cui
      agli articoli 42 e ss. del D. Lgs. 196/03 e nel rispetto degli artt. 44 ss. del
      Regolamento UE 679/2016, al fine di ottemperare a finalità connesse al trasferimento
      medesimo. Nello specifico i dati potranno essere eventualmente trasferiti extra UE
      nel rispetto di quanto previsto dall’art. 49 comma 1 lett. b) e c) del GDPR e
      dall’art. 46 . L’interessato potrà ottenere una copia di tali dati scrivendo una
      mail all’indirizzo privacy@battisti.it
      <br /><br /><v-divider style="background-color: white"></v-divider> <br /><b
        >5 - MODALITÀ DEL TRATTAMENTO | PERIODO DI CONSERVAZIONE O CRITERI</b
      ><br />
      Il trattamento sarà svolto in forma automatizzata e/o manuale, con modalità e
      strumenti volti a garantire la massima sicurezza e riservatezza, ad opera di
      soggetti di ciò appositamente incaricati. Nel rispetto di quanto previsto dall’art.
      5 comma 1 lett. e) della L. 2016/679 i dati personali raccolti verranno conservati
      in una forma che consenta l’identificazione degli interessati per un arco di tempo
      non superiore al conseguimento delle finalità per le quali i dati personali sono
      trattati. La conservazione dei dati di natura personale forniti viene determinata
      sulla base di criteri definiti nella Policy adottata dal Titolare del trattamento e
      disponibile a richiesta.
      <br /><br /><v-divider style="background-color: white"></v-divider> <br /><b
        >6 - NATURA DEL CONFERIMENTO E RIFIUTO</b
      ><br />
      Il conferimento dei dati e il consenso al trattamento per le finalità sopra indicata
      è facoltativo; in mancanza i suoi dati non saranno trattati per tale finalità senza
      pregiudizio per le finalità amministrativo-contabili legate ai rapporti in essere
      tra le parti.
      <br /><br /><v-divider style="background-color: white"></v-divider> <br /><b
        >7 - DIRITTI DEGLI INTERESSATI</b
      ><br />
      Lei potrà far valere i propri diritti come espressi dagli art. 7 e ss. del D. Lgs.
      196/03 e come espressi dagli artt. 15, 16, 17, 18, 19, 20, 21, 22 del Regolamento UE
      679/2016, rivolgendosi al titolare, oppure al responsabile del trattamento, inviando
      una mail all’indirizzo privacy@battisti.it. Lei ha il diritto, in qualunque momento,
      di chiedere al titolare del trattamento l’accesso ai Suoi dati personali, la
      rettifica, la cancellazione degli stessi, la limitazione del trattamento che lo
      riguardano o di opporsi al loro trattamento nonché di esercitare il diritto alla
      portabilità di tali dati. Qualora il trattamento sia basato sull’articolo 6,
      paragrafo 1, lettera a) oppure sull’articolo 9, paragrafo 2, lettera a), Lei ha il
      diritto di revocare il consenso in qualsiasi momento senza pregiudicare la liceità
      del trattamento basata sul consenso prestato prima della revoca. Lei ha il diritto
      di proporre reclamo all’Autorità di controllo, così come previsto dal Reg. UE
      2016/679.Nel caso di richiesta di portabilità del dato il Titolare del trattamento
      Le fornirà in un formato strutturato, di uso comune e leggibile, da dispositivo
      automatico i dati personali che la riguardano, fatto salvo i commi 3 e 4 dell’art.
      20 del Regolamento Europeo.
    </p>
  </div>
</template>

<script>
export default {
  props: {
    dialog: { type: Boolean },
    src: { type: String },
    nomeDelProdotto: { type: String },
    type: { type: String },
  },
  data() {
    return {
      page: 1,
      pageNo: 1,
      numPages: 0,
      pdfdata: undefined,
      errors: [],
      scale: 1, // 'page-width'
      isLoading: true,
    };
  },

  methods: {
    closeDialog() {
      this.$emit("chiudi");
    },

    isTelefono() {
      return window.innerWidth;
      //return this.$i2kFunctions.isSmartphone();
    },
  },
  mounted() {},

  computed: {
    dialogComputed: {
      get() {
        return this.dialog;
      },
      set(value) {
        this.closeDialog();
      },
    },
  },
};
</script>
