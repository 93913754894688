import Vue from 'vue'
import Router from 'vue-router'

import Main from '@/views/Main'
import NotFound from '@/views/404.vue'
import Qr from '@/views/Qr.vue'

//import Home from '@/views/Home'
//import Prodotti from '@/views/Prodotti'
//PRODOTTI
import DettaglioProdottoProva from '@/views/DettaglioProdottoProva'
import DettaglioProdotto from '@/views/DettaglioProdotto'
import Tutto from '@/views/Tutto'
import Maniglie from '@/views/Maniglie'
import Pomoli from '@/views/Pomoli'
import Maniglioni from '@/views/Maniglioni'
import ManiglioniAlzanti from '@/views/ManiglioniAlzanti'
import Coordinati from '@/views/Coordinati'
//import Accessori from '@/views/Accessori'
import KitScorrevoli from '@/views/KitScorrevoli'
//FINITURE
import Standard from '@/views/Standard'
import Pvd from '@/views/Pvd'
import Retro from '@/views/Retro'
import Retrò from '@/views/Retrò'
//LINEA
import Quadro from '@/views/Quadro'
import Tondo from '@/views/Tondo'
import TutteLinee from '@/views/TutteLinee'
//FILTRO FINITURE
import Finiture from '@/views/PageFiniture'
//AREA RISERVATA
import AreaRiservata from '@/views/AreaRiservata'
//RICERCA PRODOTTO
import Ricerca from '@/views/Ricerca'
//PRIVACY
import Privacy from '@/views/Privacy'
import Cookies from '@/views/Cookies'

import storage from '@/services/storage.js'

Vue.use(Router)

const routerOptions = [{
    path: '/',
    component: Main,
    children: [{
        path: '/',
        component: Tutto,
        meta: {
            title: "Home",
            pathLink: "tutto"
        }
    },
    //PRODOTTI
    /* {
        path: '/prodotti',
        component: Prodotti,
        meta: {
            title: "Prodotti",
            pathLink: "prodotti"
        }
    }, */
    {
        path: '/dettaglioOld',
        component: DettaglioProdotto,
        meta: {
            title: "Dettaglio",
            pathLink: "dettaglio"
        }
    },
    {
        path: '/dettaglio',
        component: DettaglioProdottoProva,
        meta: {
            title: "Dettaglio",
            pathLink: "dettaglio"
        }
    },
    {
        path: '/prodotti/tutto',
        component: Tutto,
        meta: {
            title: "Tutto",
            pathLink: "tutto"
        }
    },
    {
        path: '/prodotti/maniglie',
        component: Maniglie,
        meta: {
            title: "Maniglie",
            pathLink: "maniglie"
        }
    },
    {
        path: '/prodotti/pomoli',
        component: Pomoli,
        meta: {
            title: "Pomoli",
            pathLink: "pomoli"
        }
    },
    {
        path: '/prodotti/maniglioni',
        component: Maniglioni,
        meta: {
            title: "Maniglioni",
            pathLink: "maniglioni"
        }
    },
    {
        path: '/prodotti/alzanti',
        component: ManiglioniAlzanti,
        meta: {
            title: "Alzanti",
            pathLink: "maniglioniAlzanti"
        }
    },
    {
        path: '/prodotti/coordinati',
        component: Coordinati,
        meta: {
            title: "Coordinati&Accessori",
            pathLink: "coordinati"
        }
    },
    /* {
        path: '/prodotti/accessori',
        component: Accessori,
        meta: {
            title: "Accessori",
            pathLink: "accessori"
        }
    }, */
    {
        path: '/prodotti/scorrevoli',
        component: KitScorrevoli,
        meta: {
            title: "KitScorrevoli",
            pathLink: "KitScorrevoli"
        }
    },
    //LINEA
    {
        path: '/linea/quadra',
        component: Quadro,
        meta: {
            title: "Quadro",
            pathLink: "quadro"
        }
    },
    {
        path: '/linea/tonda',
        component: Tondo,
        meta: {
            title: "Tondo",
            pathLink: "tondo"
        }
    },
    {
        path: '/linea/retro',
        component: Retrò,
        meta: {
            title: "Retro",
            pathLink: "retro"
        }
    },
    {
        path: '/linea/tutte',
        component: TutteLinee,
        meta: {
            title: "TutteLinee",
            pathLink: "tutteLinee"
        }
    },
    //FINITURE
    {
        path: '/finiture/standard',
        component: Standard,
        meta: {
            title: "Standard",
            pathLink: "standard"
        }
    },
    {
        path: '/finiture/pvd',
        component: Pvd,
        meta: {
            title: "Pvd",
            pathLink: "pvd"
        }
    },
    {
        path: '/finiture/retro',
        component: Retro,
        meta: {
            title: "Retro",
            pathLink: "retro"
        }
    },
    //FILTRO FINITURE
    {
        //name: "Finiture",
        path: '/finiture',
        component: Finiture,
        //props: true,
        meta: {
            title: "Finiture",
            pathLink: "finiture"
        }
    },
    //AREA RISERVATA
    {
        path: '/areaRiservata',
        component: AreaRiservata,
        meta: {
            title: "Area Riservata",
            pathLink: "areaRiservata"
        }
    },
    //RICERCA PRODOTTO
    {
        path: '/ricerca/prodotto',
        component: Ricerca,
        meta: {
            title: "Ricerca",
            pathLink: "ricerca"
        }
    },
    //PRIVACY
    {
        path: '/privacy',
        component: Privacy,
        meta: {
            title: "Privacy",
            pathLink: "privacy"
        }
    },
    {
        path: '/cookies',
        component: Cookies,
        meta: {
            title: "Cookies",
            pathLink: "cookies"
        }
    },
    {
        path: '/qr/:parametro',
        component: Qr,
        meta: {
            title: "Qr",
            pathLink: "qr"
        },
        props: true
    },
    {
        name: '404',
        path: '*',
        component: NotFound,
        props: false
    },
    ],

},
]


const rou = new Router({
    mode: 'history',
    routes: routerOptions
})

rou.beforeEach((to, from, next) => {

    // Controllo se è richiesta l'autenticazione
    if (to.meta.checkAuth && !storage.checkAuth()) {

        next({
            path: '/prodotti/tutto' // Ritorno alla home
        })

        return
    }

    next();

})


rou.afterEach((to, from) => {

    // Imposto il nome della pagina
    window.document.title = "EcoLinea - " + (to.meta.title || '')

})

export default rou;