<template>
  <div>
    <h1 style="text-align: center">Benvenuto nell'area riservata</h1>
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  data: () => ({}),

  methods: {
    isTelefono() {
      return window.innerWidth;
      //return this.$i2kFunctions.isSmartphone();
    },
  },

  mounted() {},

  computed: {
    ...mapState({
      filtroProdotti: (state) => state.app.filtroProdotti,
    }),
  },
};
</script>