
import request from '@/services/interceptor.js'




// --------------------
// User
// --------------------
export const get_datiUserInfo = () => {
    return request({
        url: '/user/info',
        method: 'get',
    })
}