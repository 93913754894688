var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticStyle:{"max-width":"100%"}},[(
      _vm.Url.Tipo != 'Maniglioni' &&
      _vm.Url.Tipo != 'Alzanti' &&
      _vm.Url.Tipo != 'Scorrevoli' &&
      _vm.finitureStandard != 0
    )?_c('v-sheet',{staticStyle:{"margin-top":"10px","margin-left":"0%, margin-right: 0%"},attrs:{"elevation":"0","max-width":"95%"}},[_c('v-slide-group',{attrs:{"show-arrows":""},model:{value:(this.finitureStandard),callback:function ($$v) {_vm.$set(this, "finitureStandard", $$v)},expression:"this.finitureStandard"}},_vm._l((_vm.getDatiFinitureTelefono()),function(dettaglio,i){return _c('v-slide-item',{key:i,staticClass:"pa-0"},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
    var on = ref.on;
    var attrs = ref.attrs;
return [_c('v-img',_vm._g(_vm._b({staticClass:"ma-1",staticStyle:{"width":"90px","height":"40px","margin-top":"0px","cursor":"pointer","padding":"0px","padding-bottom":"0px","padding-top":"0px","padding-left":"0px","padding-right":"0px"},attrs:{"src":dettaglio.url,"lazy-src":dettaglio.url,"aspect-ratio":"1"},on:{"click":function($event){return _vm.getDettFiniture(dettaglio)}},scopedSlots:_vm._u([{key:"placeholder",fn:function(){return [_c('v-row',{staticClass:"fill-height ma-0",attrs:{"align":"center","justify":"center"}},[_c('v-progress-circular',{attrs:{"indeterminate":"","color":"black lighten-5"}})],1)]},proxy:true}],null,true)},'v-img',attrs,false),on))]}}],null,true)},[_c('span',[_vm._v(_vm._s(dettaglio.tipoFinitura))])])],1)}),1)],1):_vm._e(),((_vm.Url.Tipo == 'Maniglioni' || _vm.Url.Tipo == 'Alzanti') && _vm.finitureStandard != 0)?_c('v-sheet',{staticStyle:{"margin-top":"0px","margin-left":"0%, margin-right: 0%"},attrs:{"elevation":"0","max-width":"95%"}},[_c('v-slide-group',{attrs:{"show-arrows":""},model:{value:(this.finitureStandard),callback:function ($$v) {_vm.$set(this, "finitureStandard", $$v)},expression:"this.finitureStandard"}},_vm._l((_vm.getDatiFinitureTelefono()),function(dettaglio,i){return _c('v-slide-item',{key:i,staticClass:"pa-0"},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
    var on = ref.on;
    var attrs = ref.attrs;
return [_c('v-img',_vm._g(_vm._b({staticClass:"ma-1",staticStyle:{"width":"60px","height":"110px","margin-top":"0px","cursor":"pointer","padding":"0px","padding-bottom":"0px","padding-top":"0px","padding-left":"0px","padding-right":"0px"},attrs:{"src":dettaglio.url,"lazy-src":dettaglio.url,"aspect-ratio":"1"},on:{"click":function($event){return _vm.getDettFiniture(dettaglio)}},scopedSlots:_vm._u([{key:"placeholder",fn:function(){return [_c('v-row',{staticClass:"fill-height ma-0",attrs:{"align":"center","justify":"center"}},[_c('v-progress-circular',{attrs:{"indeterminate":"","color":"black lighten-5"}})],1)]},proxy:true}],null,true)},'v-img',attrs,false),on))]}}],null,true)},[_c('span',[_vm._v(_vm._s(dettaglio.tipoFinitura))])])],1)}),1)],1):_vm._e(),(_vm.Url.Tipo == 'Scorrevoli' && (_vm.finitureStandard != 0 || _vm.finitureRetro != 0))?_c('v-sheet',{staticStyle:{"margin-top":"0px","margin-left":"0%, margin-right: 0%"},attrs:{"elevation":"0","max-width":"95%"}},[(_vm.Url.NomeProdotto != 'Kit Ginevra')?_c('v-slide-group',{attrs:{"show-arrows":""},model:{value:(this.finitureStandard),callback:function ($$v) {_vm.$set(this, "finitureStandard", $$v)},expression:"this.finitureStandard"}},_vm._l((_vm.getDatiFinitureTelefono()),function(dettaglio,i){return _c('v-slide-item',{key:i,staticClass:"pa-0"},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
    var on = ref.on;
    var attrs = ref.attrs;
return [_c('v-img',_vm._g(_vm._b({staticClass:"ma-1",staticStyle:{"width":"45px","height":"45px","margin-top":"0px","cursor":"pointer","padding":"0px","padding-bottom":"0px","padding-top":"0px","padding-left":"0px","padding-right":"0px"},attrs:{"src":dettaglio.url,"lazy-src":dettaglio.url,"aspect-ratio":"1"},on:{"click":function($event){return _vm.getDettFiniture(dettaglio)}},scopedSlots:_vm._u([{key:"placeholder",fn:function(){return [_c('v-row',{staticClass:"fill-height ma-0",attrs:{"align":"center","justify":"center"}},[_c('v-progress-circular',{attrs:{"indeterminate":"","color":"black lighten-5"}})],1)]},proxy:true}],null,true)},'v-img',attrs,false),on))]}}],null,true)},[_c('span',[_vm._v(_vm._s(dettaglio.tipoFinitura))])])],1)}),1):_c('v-slide-group',{attrs:{"show-arrows":""},model:{value:(this.finitureRetro),callback:function ($$v) {_vm.$set(this, "finitureRetro", $$v)},expression:"this.finitureRetro"}},_vm._l((_vm.getDatiFinitureTelefono()),function(dettaglio,i){return _c('v-slide-item',{key:i,staticClass:"pa-0"},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
    var on = ref.on;
    var attrs = ref.attrs;
return [_c('v-card',{staticStyle:{"width":"60px","height":"auto"},attrs:{"elevation":"0"}},[_c('v-img',_vm._g(_vm._b({staticClass:"ma-1",staticStyle:{"width":"45px","height":"45px","margin-top":"0px","cursor":"pointer","padding":"0px","padding-bottom":"0px","padding-top":"0px","padding-left":"0px","padding-right":"0px"},attrs:{"src":dettaglio.url,"lazy-src":dettaglio.url,"aspect-ratio":"1"},on:{"click":function($event){return _vm.getDettFiniture(dettaglio)}},scopedSlots:_vm._u([{key:"placeholder",fn:function(){return [_c('v-row',{staticClass:"fill-height ma-0",attrs:{"align":"center","justify":"center"}},[_c('v-progress-circular',{attrs:{"indeterminate":"","color":"black lighten-5"}})],1)]},proxy:true}],null,true)},'v-img',attrs,false),on))],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(dettaglio.tipoFinitura))])])],1)}),1)],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }