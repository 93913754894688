<template>
  <div>
    <!-- VISUALIZZAZIONE DA PC / TV -->
    <v-row v-if="isTelefono() > 1540" style="margin-top: 0em">
      <!-- CONFIGURAZIONI -->
      <v-responsive class="overflow-x-auto" style="margin-top: 3%" max-height="1000">
        <h3 class="mb-6" style="margin-left: 4em">Configurazioni</h3>

        <!-- SCROLLBAR PER QUADRO E TONDO (MANIGLIE) -->
        <v-virtual-scroll
          v-if="
            composizioneNome != 'Retrò' &&
            (extantionNome == '1_Maniglie' ||
              extantionNome == 'Maniglie' ||
              extantionNome == 'Tutti i Modelli') &&
            (filtriMenu.text == 'Tutti i Modelli' ||
              filtriMenu.text == 'Maniglie' ||
              filtriMenu.text == 'Maniglie' ||
              filtriMenu.text == '') &&
            dettaglio.nomeProdotto != 'Sion Ribassata' &&
            dettaglio.nomeProdotto != 'Berna Ribassata'
          "
          :bench="benched"
          :items="getDati"
          item-height="74"
          height="720"
          style="width: 31em"
        >
          <v-col style="margin-top: -100em; background-color: white" cols="12">
            <v-row style="margin-top: 0em; margin-left: 3.5em">
              <v-col v-for="(dettaglio, i) in getDati" :key="i">
                <v-card
                  style="width: auto; height: auto; padding: 0px; margin-top: -1.5em"
                  class="elevation-0 mb-0"
                >
                  <!-- <v-img
                    v-on:click="getDettComposizione(dettaglio)"
                    style="width: 180px; height: 200px; margin-top: 0px; cursor: pointer"
                    :src="dettaglio.url"
                    :lazy-src="dettaglio.url"
                    aspect-ratio="1"
                    class="white lighten-2 mb-5"
                  >
                    <template v-slot:placeholder>
                      <v-row class="fill-height ma-0" align="center" justify="center">
                        <v-progress-circular
                          indeterminate
                          color="black lighten-5"
                        ></v-progress-circular>
                      </v-row>
                    </template>
                  </v-img>
                  <span style="font-size: 12px" v-if="dettaglio.nomeComposizione != ''"
                    >{{ dettaglio.nomeComposizione }}
                    <v-divider v-if="dettaglio.nomeComposizione != ''"></v-divider>
                  </span> -->
                </v-card>
              </v-col>
            </v-row>
          </v-col>
        </v-virtual-scroll>

        <!-- SCROLLABAR PER SION RIBASSATA E BERNA RIBASSATA -->
        <v-virtual-scroll
          v-if="
            composizioneNome != 'Retrò' &&
            (extantionNome == '1_Maniglie' ||
              extantionNome == 'Maniglie' ||
              extantionNome == 'Tutti i Modelli') &&
            (filtriMenu.text == 'Tutti i Modelli' ||
              filtriMenu.text == 'Maniglie' ||
              filtriMenu.text == 'Maniglie' ||
              filtriMenu.text == '') &&
            (dettaglio.nomeProdotto == 'Sion Ribassata' ||
              dettaglio.nomeProdotto == 'Berna Ribassata')
          "
          :bench="benched"
          :items="getDati"
          item-height="74"
          height="720"
          style="width: 31em"
        >
          <v-col style="margin-top: -45em; background-color: white" cols="12">
            <v-row style="margin-top: 0em; margin-left: 3.5em">
              <v-col v-for="(dettaglio, i) in getDati" :key="i">
                <v-card
                  style="width: auto; height: auto; padding: 0px; margin-top: -1.5em"
                  class="elevation-0 mb-0"
                >
                  <v-img
                    v-on:click="getDettComposizione(dettaglio)"
                    style="width: 180px; height: 200px; margin-top: 0px; cursor: pointer"
                    :src="dettaglio.url"
                    :lazy-src="dettaglio.url"
                    aspect-ratio="1"
                    class="white lighten-2 mb-5"
                  >
                    <template v-slot:placeholder>
                      <v-row class="fill-height ma-0" align="center" justify="center">
                        <v-progress-circular
                          indeterminate
                          color="black lighten-5"
                        ></v-progress-circular>
                      </v-row>
                    </template>
                  </v-img>
                  <span
                    style="font-size: 12px"
                    v-if="
                      dettaglio.nomeComposizione != '' &&
                      dettaglio.nomeComposizione != 'Cremonese T'
                    "
                    >{{ dettaglio.nomeComposizione }}
                    <v-divider
                      v-if="
                        dettaglio.nomeComposizione != '' &&
                        dettaglio.nomeComposizione != 'Cremonese T'
                      "
                    ></v-divider>
                  </span>
                </v-card>
              </v-col>
            </v-row>
          </v-col>
        </v-virtual-scroll>

        <!-- SCROLLBAR PER RETRO' (MANIGLIE) -->
        <v-virtual-scroll
          v-if="
            composizioneNome == 'Retrò' &&
            (extantionNome == '1_Maniglie' ||
              extantionNome == 'Maniglie' ||
              extantionNome == 'Tutti i Modelli') &&
            (filtriMenu.text == 'Tutti i Modelli' ||
              filtriMenu.text == 'Maniglie' ||
              filtriMenu.text == 'Maniglie' ||
              filtriMenu.text == '')
          "
          :bench="benched"
          :items="getDati"
          item-height="103"
          height="720"
          style="width: 31em"
        >
          <v-col style="margin-top: -100em; background-color: white" cols="12">
            <v-row style="margin-top: 0em; margin-left: 3.5em">
              <v-col v-for="(dettaglio, i) in getDati" :key="i">
                <v-card
                  style="width: auto; height: auto; padding: 0px; margin-top: -1.5em"
                  class="elevation-0 mb-0"
                >
                  <!-- <v-img
                    v-on:click="getDettComposizione(dettaglio)"
                    style="width: 180px; height: 200px; margin-top: 0px; cursor: pointer"
                    :src="dettaglio.url"
                    :lazy-src="dettaglio.url"
                    aspect-ratio="1"
                    class="white lighten-2 mb-5"
                  >
                    <template v-slot:placeholder>
                      <v-row class="fill-height ma-0" align="center" justify="center">
                        <v-progress-circular
                          indeterminate
                          color="black lighten-5"
                        ></v-progress-circular>
                      </v-row>
                    </template>
                  </v-img>
                  <span style="font-size: 12px" v-if="dettaglio.nomeComposizione != ''"
                    >{{ dettaglio.nomeComposizione }}
                    <v-divider v-if="dettaglio.nomeComposizione != ''"></v-divider>
                  </span> -->
                </v-card>
              </v-col>
            </v-row>
          </v-col>
        </v-virtual-scroll>

        <!-- SCROLLBAR PER QUADRO E TONDO (POMOLI) -->
        <v-virtual-scroll
          v-if="
            composizioneNome != 'Retrò' &&
            (extantionNome == '2_Pomoli' ||
              extantionNome == 'Pomoli' ||
              extantionNome == 'Tutti i Modelli') &&
            (filtriMenu.text == 'Tutti i Modelli' ||
              filtriMenu.text == 'Pomoli' ||
              filtriMenu.text == '')
          "
          :bench="benched"
          :items="getDati"
          item-height="103"
          height="720"
          style="width: 31em"
        >
          <v-col style="margin-top: -100em; background-color: white" cols="12">
            <v-row style="margin-top: 0em; margin-left: 3.5em">
              <v-col v-for="(dettaglio, i) in getDati" :key="i">
                <v-card
                  style="width: auto; height: auto; padding: 0px; margin-top: -1.5em"
                  class="elevation-0 mb-0"
                >
                  <v-img
                    v-on:click="getDettComposizione(dettaglio)"
                    style="width: 180px; height: 200px; margin-top: 0px; cursor: pointer"
                    :src="dettaglio.url"
                    :lazy-src="dettaglio.url"
                    aspect-ratio="1"
                    class="white lighten-2 mb-5"
                  >
                    <template v-slot:placeholder>
                      <v-row class="fill-height ma-0" align="center" justify="center">
                        <v-progress-circular
                          indeterminate
                          color="black lighten-5"
                        ></v-progress-circular>
                      </v-row>
                    </template>
                  </v-img>
                  <span
                    style="font-size: 12px"
                    v-if="
                      dettaglio.nomeComposizione != '' &&
                      dettaglio.nomeComposizione != 'Cremonese T'
                    "
                    >{{ dettaglio.nomeComposizione }}
                    <v-divider
                      v-if="
                        dettaglio.nomeComposizione != '' &&
                        dettaglio.nomeComposizione != 'Cremonese T'
                      "
                    ></v-divider>
                  </span>
                </v-card>
              </v-col>
            </v-row>
          </v-col>
        </v-virtual-scroll>

        <!-- SCROLLBAR PER RETRO' (POMOLI) -->
        <v-virtual-scroll
          v-if="
            composizioneNome == 'Retrò' &&
            (extantionNome == '2_Pomoli' ||
              extantionNome == 'Pomoli' ||
              extantionNome == 'Tutti i Modelli') &&
            (filtriMenu.text == 'Tutti i Modelli' ||
              filtriMenu.text == 'Pomoli' ||
              filtriMenu.text == '')
          "
          :bench="benched"
          :items="getDati"
          item-height="119"
          height="720"
          style="width: 31em"
        >
          <v-col style="margin-top: -70em; background-color: white" cols="12">
            <v-row style="margin-top: 0em; margin-left: 3.5em">
              <v-col v-for="(dettaglio, i) in getDati" :key="i">
                <v-card
                  style="width: auto; height: auto; padding: 0px; margin-top: -1.5em"
                  class="elevation-0 mb-0"
                >
                  <v-img
                    v-on:click="getDettComposizione(dettaglio)"
                    style="width: 180px; height: 200px; margin-top: 0px; cursor: pointer"
                    :src="dettaglio.url"
                    :lazy-src="dettaglio.url"
                    aspect-ratio="1"
                    class="white lighten-2 mb-5"
                  >
                    <template v-slot:placeholder>
                      <v-row class="fill-height ma-0" align="center" justify="center">
                        <v-progress-circular
                          indeterminate
                          color="black lighten-5"
                        ></v-progress-circular>
                      </v-row>
                    </template>
                  </v-img>
                  <span
                    style="font-size: 12px"
                    v-if="
                      dettaglio.nomeComposizione != '' &&
                      dettaglio.nomeComposizione != 'Cremonese T'
                    "
                    >{{ dettaglio.nomeComposizione }}
                    <v-divider
                      v-if="
                        dettaglio.nomeComposizione != '' &&
                        dettaglio.nomeComposizione != 'Cremonese T'
                      "
                    ></v-divider>
                  </span>
                </v-card>
              </v-col>
            </v-row>
          </v-col>
        </v-virtual-scroll>

        <!-- SCROLLBAR PER (MANIGLIONI) -->
        <!-- AYR -->
        <v-virtual-scroll
          v-if="
            composizioneNome != 'Retrò' &&
            (extantionNome == '3_Maniglioni' ||
              extantionNome == 'Maniglioni' ||
              extantionNome == 'Tutti i Modelli') &&
            (filtriMenu.text == 'Tutti i Modelli' ||
              filtriMenu.text == 'Maniglioni' ||
              filtriMenu.text == '') &&
            dettaglio.nomeProdotto == 'Ayr'
          "
          :bench="benched"
          :items="getDati"
          item-height="210"
          height="720"
          style="width: 31em"
        >
          <v-col style="margin-top: -70em; background-color: white" cols="12">
            <v-row style="margin-top: 0em; margin-left: 3.5em">
              <v-col v-for="(dettaglio, i) in getDati" :key="i">
                <v-card
                  style="width: auto; height: auto; padding: 0px; margin-top: -1.5em"
                  class="elevation-0 mb-0"
                >
                  <v-img
                    v-on:click="getDettComposizione(dettaglio)"
                    style="width: 150px; height: 500px; margin-top: 10px; cursor: pointer"
                    :src="dettaglio.url"
                    :lazy-src="dettaglio.url"
                    aspect-ratio="1"
                    class="white lighten-2 mb-5"
                  >
                    <template v-slot:placeholder>
                      <v-row class="fill-height ma-0" align="center" justify="center">
                        <v-progress-circular
                          indeterminate
                          color="black lighten-5"
                        ></v-progress-circular>
                      </v-row>
                    </template>
                  </v-img>
                  <span
                    style="font-size: 12px"
                    v-if="
                      dettaglio.nomeComposizione != '' &&
                      dettaglio.nomeComposizione != 'Cremonese T'
                    "
                    >{{ dettaglio.nomeComposizione }}
                    <v-divider
                      v-if="
                        dettaglio.nomeComposizione != '' &&
                        dettaglio.nomeComposizione != 'Cremonese T'
                      "
                    ></v-divider>
                  </span>
                </v-card>
              </v-col>
            </v-row>
          </v-col>
        </v-virtual-scroll>

        <!-- BELFAST -->
        <v-virtual-scroll
          v-if="
            composizioneNome != 'Retrò' &&
            (extantionNome == '3_Maniglioni' ||
              extantionNome == 'Maniglioni' ||
              extantionNome == 'Tutti i Modelli') &&
            (filtriMenu.text == 'Tutti i Modelli' ||
              filtriMenu.text == 'Maniglioni' ||
              filtriMenu.text == '') &&
            dettaglio.nomeProdotto == 'Belfast'
          "
          :bench="benched"
          :items="getDati"
          item-height="40"
          height="720"
          style="width: 31em"
        >
          <v-col style="margin-top: -15em; background-color: white" cols="12">
            <v-row style="margin-top: 0em; margin-left: 3.5em">
              <v-col v-for="(dettaglio, i) in getDati" :key="i">
                <v-card
                  style="width: auto; height: auto; padding: 0px; margin-top: -1.5em"
                  class="elevation-0 mb-0"
                >
                  <v-img
                    v-on:click="getDettComposizione(dettaglio)"
                    style="width: 150px; height: 500px; margin-top: 10px; cursor: pointer"
                    :src="dettaglio.url"
                    :lazy-src="dettaglio.url"
                    aspect-ratio="1"
                    class="white lighten-2 mb-5"
                  >
                    <template v-slot:placeholder>
                      <v-row class="fill-height ma-0" align="center" justify="center">
                        <v-progress-circular
                          indeterminate
                          color="black lighten-5"
                        ></v-progress-circular>
                      </v-row>
                    </template>
                  </v-img>
                  <span
                    style="font-size: 12px"
                    v-if="
                      dettaglio.nomeComposizione != '' &&
                      dettaglio.nomeComposizione != 'Cremonese T'
                    "
                    >{{ dettaglio.nomeComposizione }}
                    <v-divider
                      v-if="
                        dettaglio.nomeComposizione != '' &&
                        dettaglio.nomeComposizione != 'Cremonese T'
                      "
                    ></v-divider>
                  </span>
                </v-card>
              </v-col>
            </v-row>
          </v-col>
        </v-virtual-scroll>

        <!-- BORDEAUX -->
        <v-virtual-scroll
          v-if="
            composizioneNome != 'Retrò' &&
            (extantionNome == '3_Maniglioni' ||
              extantionNome == 'Maniglioni' ||
              extantionNome == 'Tutti i Modelli') &&
            (filtriMenu.text == 'Tutti i Modelli' ||
              filtriMenu.text == 'Maniglioni' ||
              filtriMenu.text == '') &&
            dettaglio.nomeProdotto == 'Bordeaux'
          "
          :bench="benched"
          :items="getDati"
          item-height="80"
          height="720"
          style="width: 31em"
        >
          <v-col style="margin-top: -20em; background-color: white" cols="12">
            <v-row style="margin-top: 0em; margin-left: 3.5em">
              <v-col v-for="(dettaglio, i) in getDati" :key="i">
                <v-card
                  style="width: auto; height: auto; padding: 0px; margin-top: -1.5em"
                  class="elevation-0 mb-0"
                >
                  <v-img
                    v-on:click="getDettComposizione(dettaglio)"
                    style="width: 350px; height: 500px; margin-top: 10px; cursor: pointer"
                    :src="dettaglio.url"
                    :lazy-src="dettaglio.url"
                    aspect-ratio="1"
                    class="white lighten-2 mb-5"
                  >
                    <template v-slot:placeholder>
                      <v-row class="fill-height ma-0" align="center" justify="center">
                        <v-progress-circular
                          indeterminate
                          color="black lighten-5"
                        ></v-progress-circular>
                      </v-row>
                    </template>
                  </v-img>
                  <span
                    style="font-size: 12px"
                    v-if="dettaglio.nomeComposizione != '02000'"
                    >{{ dettaglio.nomeComposizione }}
                    <v-divider v-if="dettaglio.nomeComposizione != '02000'"></v-divider>
                  </span>
                </v-card>
              </v-col>
            </v-row>
          </v-col>
        </v-virtual-scroll>

        <!-- BRERA -->
        <v-virtual-scroll
          v-if="
            composizioneNome != 'Retrò' &&
            (extantionNome == '3_Maniglioni' ||
              extantionNome == 'Maniglioni' ||
              extantionNome == 'Tutti i Modelli') &&
            (filtriMenu.text == 'Tutti i Modelli' ||
              filtriMenu.text == 'Maniglioni' ||
              filtriMenu.text == '') &&
            dettaglio.nomeProdotto == 'Brera'
          "
          :bench="benched"
          :items="getDati"
          item-height="80"
          height="720"
          style="width: 31em"
        >
          <v-col style="margin-top: -20em; background-color: white" cols="12">
            <v-row style="margin-top: 0em; margin-left: 3.5em">
              <v-col v-for="(dettaglio, i) in getDati" :key="i">
                <v-card
                  style="width: auto; height: auto; padding: 0px; margin-top: -1.5em"
                  class="elevation-0 mb-0"
                >
                  <v-img
                    v-on:click="getDettComposizione(dettaglio)"
                    style="width: 350px; height: 500px; margin-top: 10px; cursor: pointer"
                    :src="dettaglio.url"
                    :lazy-src="dettaglio.url"
                    aspect-ratio="1"
                    class="white lighten-2 mb-5"
                  >
                    <template v-slot:placeholder>
                      <v-row class="fill-height ma-0" align="center" justify="center">
                        <v-progress-circular
                          indeterminate
                          color="black lighten-5"
                        ></v-progress-circular>
                      </v-row>
                    </template>
                  </v-img>
                  <span
                    style="font-size: 12px"
                    v-if="dettaglio.nomeComposizione != '03000'"
                    >{{ dettaglio.nomeComposizione }}
                    <v-divider v-if="dettaglio.nomeComposizione != '03000'"></v-divider>
                  </span>
                </v-card>
              </v-col>
            </v-row>
          </v-col>
        </v-virtual-scroll>

        <!-- BRERA Q -->
        <v-virtual-scroll
          v-if="
            composizioneNome != 'Retrò' &&
            (extantionNome == '3_Maniglioni' ||
              extantionNome == 'Maniglioni' ||
              extantionNome == 'Tutti i Modelli') &&
            (filtriMenu.text == 'Tutti i Modelli' ||
              filtriMenu.text == 'Maniglioni' ||
              filtriMenu.text == '') &&
            dettaglio.nomeProdotto == 'Brera Q'
          "
          :bench="benched"
          :items="getDati"
          item-height="80"
          height="720"
          style="width: 31em"
        >
          <v-col style="margin-top: -20em; background-color: white" cols="12">
            <v-row style="margin-top: 0em; margin-left: 3.5em">
              <v-col v-for="(dettaglio, i) in getDati" :key="i">
                <v-card
                  style="width: auto; height: auto; padding: 0px; margin-top: -1.5em"
                  class="elevation-0 mb-0"
                >
                  <v-img
                    v-on:click="getDettComposizione(dettaglio)"
                    style="width: 350px; height: 500px; margin-top: 10px; cursor: pointer"
                    :src="dettaglio.url"
                    :lazy-src="dettaglio.url"
                    aspect-ratio="1"
                    class="white lighten-2 mb-5"
                  >
                    <template v-slot:placeholder>
                      <v-row class="fill-height ma-0" align="center" justify="center">
                        <v-progress-circular
                          indeterminate
                          color="black lighten-5"
                        ></v-progress-circular>
                      </v-row>
                    </template>
                  </v-img>
                  <span
                    style="font-size: 12px"
                    v-if="dettaglio.nomeComposizione != '03000'"
                    >{{ dettaglio.nomeComposizione }}
                    <v-divider v-if="dettaglio.nomeComposizione != '03000'"></v-divider>
                  </span>
                </v-card>
              </v-col>
            </v-row>
          </v-col>
        </v-virtual-scroll>

        <!-- BRISTOL -->
        <v-virtual-scroll
          v-if="
            composizioneNome != 'Retrò' &&
            (extantionNome == '3_Maniglioni' ||
              extantionNome == 'Maniglioni' ||
              extantionNome == 'Tutti i Modelli') &&
            (filtriMenu.text == 'Tutti i Modelli' ||
              filtriMenu.text == 'Maniglioni' ||
              filtriMenu.text == '') &&
            dettaglio.nomeProdotto == 'Bristol'
          "
          :bench="benched"
          :items="getDati"
          item-height="40"
          height="720"
          style="width: 26em"
        >
          <v-col style="margin-top: -15em; background-color: white" cols="12">
            <v-row style="margin-top: 0em; margin-left: 3.5em">
              <v-col v-for="(dettaglio, i) in getDati" :key="i">
                <v-card
                  style="width: auto; height: auto; padding: 0px; margin-top: -1.5em"
                  class="elevation-0 mb-0"
                >
                  <v-img
                    v-on:click="getDettComposizione(dettaglio)"
                    style="width: 350px; height: 550px; margin-top: 10px; cursor: pointer"
                    :src="dettaglio.url"
                    :lazy-src="dettaglio.url"
                    aspect-ratio="1"
                    class="white lighten-2 mb-5"
                  >
                    <template v-slot:placeholder>
                      <v-row class="fill-height ma-0" align="center" justify="center">
                        <v-progress-circular
                          indeterminate
                          color="black lighten-5"
                        ></v-progress-circular>
                      </v-row>
                    </template>
                  </v-img>
                  <span
                    style="font-size: 12px"
                    v-if="
                      dettaglio.nomeComposizione != '' &&
                      dettaglio.nomeComposizione != 'Cremonese T'
                    "
                    >{{ dettaglio.nomeComposizione }}
                    <v-divider
                      v-if="
                        dettaglio.nomeComposizione != '' &&
                        dettaglio.nomeComposizione != 'Cremonese T'
                      "
                    ></v-divider>
                  </span>
                </v-card>
              </v-col>
            </v-row>
          </v-col>
        </v-virtual-scroll>

        <!-- DERBY -->
        <v-virtual-scroll
          v-if="
            composizioneNome != 'Retrò' &&
            (extantionNome == '3_Maniglioni' ||
              extantionNome == 'Maniglioni' ||
              extantionNome == 'Tutti i Modelli') &&
            (filtriMenu.text == 'Tutti i Modelli' ||
              filtriMenu.text == 'Maniglioni' ||
              filtriMenu.text == '') &&
            dettaglio.nomeProdotto == 'Derby'
          "
          :bench="benched"
          :items="getDati"
          item-height="40"
          height="720"
          style="width: 26em"
        >
          <v-col style="margin-top: -15em; background-color: white" cols="12">
            <v-row style="margin-top: 0em; margin-left: 3.5em">
              <v-col v-for="(dettaglio, i) in getDati" :key="i">
                <v-card
                  style="width: auto; height: auto; padding: 0px; margin-top: -1.5em"
                  class="elevation-0 mb-0"
                >
                  <v-img
                    v-on:click="getDettComposizione(dettaglio)"
                    style="width: 350px; height: 550px; margin-top: 10px; cursor: pointer"
                    :src="dettaglio.url"
                    :lazy-src="dettaglio.url"
                    aspect-ratio="1"
                    class="white lighten-2 mb-5"
                  >
                    <template v-slot:placeholder>
                      <v-row class="fill-height ma-0" align="center" justify="center">
                        <v-progress-circular
                          indeterminate
                          color="black lighten-5"
                        ></v-progress-circular>
                      </v-row>
                    </template>
                  </v-img>
                  <span
                    style="font-size: 12px"
                    v-if="
                      dettaglio.nomeComposizione != '' &&
                      dettaglio.nomeComposizione != 'Cremonese T'
                    "
                    >{{ dettaglio.nomeComposizione }}
                    <v-divider
                      v-if="
                        dettaglio.nomeComposizione != '' &&
                        dettaglio.nomeComposizione != 'Cremonese T'
                      "
                    ></v-divider>
                  </span>
                </v-card>
              </v-col>
            </v-row>
          </v-col>
        </v-virtual-scroll>

        <!-- DOVER -->
        <v-virtual-scroll
          v-if="
            composizioneNome != 'Retrò' &&
            (extantionNome == '3_Maniglioni' ||
              extantionNome == 'Maniglioni' ||
              extantionNome == 'Tutti i Modelli') &&
            (filtriMenu.text == 'Tutti i Modelli' ||
              filtriMenu.text == 'Maniglioni' ||
              filtriMenu.text == '') &&
            dettaglio.nomeProdotto == 'Dover'
          "
          :bench="benched"
          :items="getDati"
          item-height="40"
          height="720"
          style="width: 31em"
        >
          <v-col style="margin-top: -15em; background-color: white" cols="12">
            <v-row style="margin-top: 0em; margin-left: 3.5em">
              <v-col v-for="(dettaglio, i) in getDati" :key="i">
                <v-card
                  style="width: auto; height: auto; padding: 0px; margin-top: -1.5em"
                  class="elevation-0 mb-0"
                >
                  <v-img
                    v-on:click="getDettComposizione(dettaglio)"
                    style="width: 150px; height: 500px; margin-top: 10px; cursor: pointer"
                    :src="dettaglio.url"
                    :lazy-src="dettaglio.url"
                    aspect-ratio="1"
                    class="white lighten-2 mb-5"
                  >
                    <template v-slot:placeholder>
                      <v-row class="fill-height ma-0" align="center" justify="center">
                        <v-progress-circular
                          indeterminate
                          color="black lighten-5"
                        ></v-progress-circular>
                      </v-row>
                    </template>
                  </v-img>
                  <span
                    style="font-size: 12px"
                    v-if="
                      dettaglio.nomeComposizione != '' &&
                      dettaglio.nomeComposizione != 'Cremonese T'
                    "
                    >{{ dettaglio.nomeComposizione }}
                    <v-divider
                      v-if="
                        dettaglio.nomeComposizione != '' &&
                        dettaglio.nomeComposizione != 'Cremonese T'
                      "
                    ></v-divider>
                  </span>
                </v-card>
              </v-col>
            </v-row>
          </v-col>
        </v-virtual-scroll>

        <!-- GLASGOW -->
        <v-virtual-scroll
          v-if="
            composizioneNome != 'Retrò' &&
            (extantionNome == '3_Maniglioni' ||
              extantionNome == 'Maniglioni' ||
              extantionNome == 'Tutti i Modelli') &&
            (filtriMenu.text == 'Tutti i Modelli' ||
              filtriMenu.text == 'Maniglioni' ||
              filtriMenu.text == '') &&
            dettaglio.nomeProdotto == 'Glasgow'
          "
          :bench="benched"
          :items="getDati"
          item-height="40"
          height="720"
          style="width: 26em"
        >
          <v-col style="margin-top: -15em; background-color: white" cols="12">
            <v-row style="margin-top: 0em; margin-left: 3.5em">
              <v-col v-for="(dettaglio, i) in getDati" :key="i">
                <v-card
                  style="width: auto; height: auto; padding: 0px; margin-top: 0em"
                  class="elevation-0 mb-0"
                >
                  <v-img
                    v-on:click="getDettComposizione(dettaglio)"
                    style="width: 350px; height: 550px; margin-top: 10px; cursor: pointer"
                    :src="dettaglio.url"
                    :lazy-src="dettaglio.url"
                    aspect-ratio="1"
                    class="white lighten-2 mb-5"
                  >
                    <template v-slot:placeholder>
                      <v-row class="fill-height ma-0" align="center" justify="center">
                        <v-progress-circular
                          indeterminate
                          color="black lighten-5"
                        ></v-progress-circular>
                      </v-row>
                    </template>
                  </v-img>
                  <span
                    style="font-size: 12px"
                    v-if="dettaglio.nomeComposizione != '0200'"
                    >{{ dettaglio.nomeComposizione }}
                    <v-divider v-if="dettaglio.nomeComposizione != '0200'"></v-divider>
                  </span>
                </v-card>
              </v-col>
            </v-row>
          </v-col>
        </v-virtual-scroll>

        <!-- KIEL -->
        <v-virtual-scroll
          v-if="
            composizioneNome != 'Retrò' &&
            (extantionNome == '3_Maniglioni' ||
              extantionNome == 'Maniglioni' ||
              extantionNome == 'Tutti i Modelli') &&
            (filtriMenu.text == 'Tutti i Modelli' ||
              filtriMenu.text == 'Maniglioni' ||
              filtriMenu.text == '') &&
            dettaglio.nomeProdotto == 'Kiel'
          "
          :bench="benched"
          :items="getDati"
          item-height="210"
          height="720"
          style="width: 31em"
        >
          <v-col style="margin-top: -70em; background-color: white" cols="12">
            <v-row style="margin-top: 0em; margin-left: 3.5em">
              <v-col v-for="(dettaglio, i) in getDati" :key="i">
                <v-card
                  style="width: auto; height: auto; padding: 0px; margin-top: -1.5em"
                  class="elevation-0 mb-0"
                >
                  <v-img
                    v-on:click="getDettComposizione(dettaglio)"
                    style="width: 150px; height: 500px; margin-top: 10px; cursor: pointer"
                    :src="dettaglio.url"
                    :lazy-src="dettaglio.url"
                    aspect-ratio="1"
                    class="white lighten-2 mb-5"
                  >
                    <template v-slot:placeholder>
                      <v-row class="fill-height ma-0" align="center" justify="center">
                        <v-progress-circular
                          indeterminate
                          color="black lighten-5"
                        ></v-progress-circular>
                      </v-row>
                    </template>
                  </v-img>
                  <span
                    style="font-size: 12px"
                    v-if="
                      dettaglio.nomeComposizione != '' &&
                      dettaglio.nomeComposizione != 'Cremonese T'
                    "
                    >{{ dettaglio.nomeComposizione }}
                    <v-divider
                      v-if="
                        dettaglio.nomeComposizione != '' &&
                        dettaglio.nomeComposizione != 'Cremonese T'
                      "
                    ></v-divider>
                  </span>
                </v-card>
              </v-col>
            </v-row>
          </v-col>
        </v-virtual-scroll>

        <!-- LAHTI -->
        <v-virtual-scroll
          v-if="
            composizioneNome != 'Retrò' &&
            (extantionNome == '3_Maniglioni' ||
              extantionNome == 'Maniglioni' ||
              extantionNome == 'Tutti i Modelli') &&
            (filtriMenu.text == 'Tutti i Modelli' ||
              filtriMenu.text == 'Maniglioni' ||
              filtriMenu.text == '') &&
            dettaglio.nomeProdotto == 'Lahti'
          "
          :bench="benched"
          :items="getDati"
          item-height="40"
          height="720"
          style="width: 31em"
        >
          <v-col style="margin-top: -15em; background-color: white" cols="12">
            <v-row style="margin-top: 0em; margin-left: 3.5em">
              <v-col v-for="(dettaglio, i) in getDati" :key="i">
                <v-card
                  style="width: auto; height: auto; padding: 0px; margin-top: -1.5em"
                  class="elevation-0 mb-0"
                >
                  <v-img
                    v-on:click="getDettComposizione(dettaglio)"
                    style="width: 150px; height: 500px; margin-top: 10px; cursor: pointer"
                    :src="dettaglio.url"
                    :lazy-src="dettaglio.url"
                    aspect-ratio="1"
                    class="white lighten-2 mb-5"
                  >
                    <template v-slot:placeholder>
                      <v-row class="fill-height ma-0" align="center" justify="center">
                        <v-progress-circular
                          indeterminate
                          color="black lighten-5"
                        ></v-progress-circular>
                      </v-row>
                    </template>
                  </v-img>
                  <span
                    style="font-size: 12px"
                    v-if="
                      dettaglio.nomeComposizione != '' &&
                      dettaglio.nomeComposizione != 'Cremonese T'
                    "
                    >{{ dettaglio.nomeComposizione }}
                    <v-divider
                      v-if="
                        dettaglio.nomeComposizione != '' &&
                        dettaglio.nomeComposizione != 'Cremonese T'
                      "
                    ></v-divider>
                  </span>
                </v-card>
              </v-col>
            </v-row>
          </v-col>
        </v-virtual-scroll>

        <!-- TOLEDO -->
        <v-virtual-scroll
          v-if="
            composizioneNome != 'Retrò' &&
            (extantionNome == '3_Maniglioni' ||
              extantionNome == 'Maniglioni' ||
              extantionNome == 'Tutti i Modelli') &&
            (filtriMenu.text == 'Tutti i Modelli' ||
              filtriMenu.text == 'Maniglioni' ||
              filtriMenu.text == '') &&
            dettaglio.nomeProdotto == 'Toledo'
          "
          :bench="benched"
          :items="getDati"
          item-height="40"
          height="720"
          style="width: 26em"
        >
          <v-col style="margin-top: -15em; background-color: white" cols="12">
            <v-row style="margin-top: 0em; margin-left: 3.5em">
              <v-col v-for="(dettaglio, i) in getDati" :key="i">
                <v-card
                  style="width: auto; height: auto; padding: 0px; margin-top: -1.5em"
                  class="elevation-0 mb-0"
                >
                  <v-img
                    v-on:click="getDettComposizione(dettaglio)"
                    style="width: 350px; height: 550px; margin-top: 10px; cursor: pointer"
                    :src="dettaglio.url"
                    :lazy-src="dettaglio.url"
                    aspect-ratio="1"
                    class="white lighten-2 mb-5"
                  >
                    <template v-slot:placeholder>
                      <v-row class="fill-height ma-0" align="center" justify="center">
                        <v-progress-circular
                          indeterminate
                          color="black lighten-5"
                        ></v-progress-circular>
                      </v-row>
                    </template>
                  </v-img>
                  <span
                    style="font-size: 12px"
                    v-if="
                      dettaglio.nomeComposizione != '' &&
                      dettaglio.nomeComposizione != 'Cremonese T'
                    "
                    >{{ dettaglio.nomeComposizione }}
                    <v-divider
                      v-if="
                        dettaglio.nomeComposizione != '' &&
                        dettaglio.nomeComposizione != 'Cremonese T'
                      "
                    ></v-divider>
                  </span>
                </v-card>
              </v-col>
            </v-row>
          </v-col>
        </v-virtual-scroll>

        <!-- GINEVRA -->
        <v-virtual-scroll
          v-if="
            composizioneNome == 'Retrò' &&
            (extantionNome == '3_Maniglioni' ||
              extantionNome == 'Maniglioni' ||
              extantionNome == 'Tutti i Modelli') &&
            (filtriMenu.text == 'Tutti i Modelli' ||
              filtriMenu.text == 'Maniglioni' ||
              filtriMenu.text == '') &&
            dettaglio.nomeProdotto == 'Ginevra'
          "
          :bench="benched"
          :items="getDati"
          item-height="40"
          height="720"
          style="width: 26em"
        >
          <v-col style="margin-top: -15em; background-color: white" cols="12">
            <v-row style="margin-top: 0em; margin-left: 3.5em">
              <v-col v-for="(dettaglio, i) in getDati" :key="i">
                <v-card
                  style="width: auto; height: auto; padding: 0px; margin-top: 0em"
                  class="elevation-0 mb-0"
                >
                  <v-img
                    v-on:click="getDettComposizione(dettaglio)"
                    style="width: 350px; height: 550px; margin-top: 10px; cursor: pointer"
                    :src="dettaglio.url"
                    :lazy-src="dettaglio.url"
                    aspect-ratio="1"
                    class="white lighten-2 mb-5"
                  >
                    <template v-slot:placeholder>
                      <v-row class="fill-height ma-0" align="center" justify="center">
                        <v-progress-circular
                          indeterminate
                          color="black lighten-5"
                        ></v-progress-circular>
                      </v-row>
                    </template>
                  </v-img>
                  <span
                    style="font-size: 12px"
                    v-if="dettaglio.nomeComposizione != '02000'"
                    >{{ dettaglio.nomeComposizione }}
                    <v-divider v-if="dettaglio.nomeComposizione != '02000'"></v-divider>
                  </span>
                </v-card>
              </v-col>
            </v-row>
          </v-col>
        </v-virtual-scroll>

        <!-- GINEVRA ZANCATO -->
        <v-virtual-scroll
          v-if="
            composizioneNome == 'Retrò' &&
            (extantionNome == '3_Maniglioni' ||
              extantionNome == 'Maniglioni' ||
              extantionNome == 'Tutti i Modelli') &&
            (filtriMenu.text == 'Tutti i Modelli' ||
              filtriMenu.text == 'Maniglioni' ||
              filtriMenu.text == '') &&
            dettaglio.nomeProdotto == 'Ginevra Zancato'
          "
          :bench="benched"
          :items="getDati"
          item-height="40"
          height="720"
          style="width: 26em"
        >
          <v-col style="margin-top: -15em; background-color: white" cols="12">
            <v-row style="margin-top: 0em; margin-left: 3.5em">
              <v-col v-for="(dettaglio, i) in getDati" :key="i">
                <v-card
                  style="width: auto; height: auto; padding: 0px; margin-top: 0em"
                  class="elevation-0 mb-0"
                >
                  <v-img
                    v-on:click="getDettComposizione(dettaglio)"
                    style="width: 350px; height: 550px; margin-top: 10px; cursor: pointer"
                    :src="dettaglio.url"
                    :lazy-src="dettaglio.url"
                    aspect-ratio="1"
                    class="white lighten-2 mb-5"
                  >
                    <template v-slot:placeholder>
                      <v-row class="fill-height ma-0" align="center" justify="center">
                        <v-progress-circular
                          indeterminate
                          color="black lighten-5"
                        ></v-progress-circular>
                      </v-row>
                    </template>
                  </v-img>
                  <span
                    style="font-size: 12px"
                    v-if="dettaglio.nomeComposizione != '02000'"
                    >{{ dettaglio.nomeComposizione }}
                    <v-divider v-if="dettaglio.nomeComposizione != '02000'"></v-divider>
                  </span>
                </v-card>
              </v-col>
            </v-row>
          </v-col>
        </v-virtual-scroll>

        <!-- SCROLLBAR PER (ALZANTI) -->
        <!-- BRERA -->
        <v-virtual-scroll
          v-if="
            composizioneNome != 'Retrò' &&
            (extantionNome == '4_Alzanti' ||
              extantionNome == 'Alzanti' ||
              extantionNome == 'Tutti i Modelli') &&
            (filtriMenu.text == 'Tutti i Modelli' ||
              filtriMenu.text == 'Alzanti' ||
              filtriMenu.text == '') &&
            dettaglio.nomeProdotto == 'Brera'
          "
          :bench="benched"
          :items="getDati"
          item-height="80"
          height="720"
          style="width: 31em"
        >
          <v-col style="margin-top: 0em; background-color: white" cols="12">
            <v-row style="margin-top: 0em; margin-left: 3.5em">
              <v-col v-for="(dettaglio, i) in getDati" :key="i">
                <v-card
                  style="width: auto; height: auto; padding: 0px; margin-top: -1.5em"
                  class="elevation-0 mb-0"
                >
                  <v-img
                    v-on:click="getDettComposizione(dettaglio)"
                    style="
                      width: 150px;
                      height: 300px;
                      margin-top: 10px;
                      cursor: pointer;
                      margin-left: 9em;
                    "
                    :src="dettaglio.url"
                    :lazy-src="dettaglio.url"
                    aspect-ratio="1"
                    class="white lighten-2 mb-5"
                  >
                    <template v-slot:placeholder>
                      <v-row class="fill-height ma-0" align="center" justify="center">
                        <v-progress-circular
                          indeterminate
                          color="black lighten-5"
                        ></v-progress-circular>
                      </v-row>
                    </template>
                  </v-img>
                  <span
                    style="font-size: 12px"
                    v-if="dettaglio.nomeComposizione != '02000'"
                    >{{ dettaglio.nomeComposizione }}
                    <v-divider v-if="dettaglio.nomeComposizione != '02000'"></v-divider>
                  </span>
                </v-card>
              </v-col>
            </v-row>
          </v-col>
        </v-virtual-scroll>

        <!-- BRERA Q -->
        <v-virtual-scroll
          v-if="
            composizioneNome != 'Retrò' &&
            (extantionNome == '4_Alzanti' ||
              extantionNome == 'Alzanti' ||
              extantionNome == 'Tutti i Modelli') &&
            (filtriMenu.text == 'Tutti i Modelli' ||
              filtriMenu.text == 'Alzanti' ||
              filtriMenu.text == '') &&
            dettaglio.nomeProdotto == 'Brera Q'
          "
          :bench="benched"
          :items="getDati"
          item-height="80"
          height="720"
          style="width: 31em"
        >
          <v-col style="margin-top: 0em; background-color: white" cols="12">
            <v-row style="margin-top: 0em; margin-left: 3.5em">
              <v-col v-for="(dettaglio, i) in getDati" :key="i">
                <v-card
                  style="width: auto; height: auto; padding: 0px; margin-top: -1.5em"
                  class="elevation-0 mb-0"
                >
                  <v-img
                    v-on:click="getDettComposizione(dettaglio)"
                    style="
                      width: 150px;
                      height: 300px;
                      margin-top: 10px;
                      cursor: pointer;
                      margin-left: 9em;
                    "
                    :src="dettaglio.url"
                    :lazy-src="dettaglio.url"
                    aspect-ratio="1"
                    class="white lighten-2 mb-5"
                  >
                    <template v-slot:placeholder>
                      <v-row class="fill-height ma-0" align="center" justify="center">
                        <v-progress-circular
                          indeterminate
                          color="black lighten-5"
                        ></v-progress-circular>
                      </v-row>
                    </template>
                  </v-img>
                  <span
                    style="font-size: 12px"
                    v-if="dettaglio.nomeComposizione != '02000'"
                    >{{ dettaglio.nomeComposizione }}
                    <v-divider v-if="dettaglio.nomeComposizione != '02000'"></v-divider>
                  </span>
                </v-card>
              </v-col>
            </v-row>
          </v-col>
        </v-virtual-scroll>

        <!-- BRISTOL -->
        <v-virtual-scroll
          v-if="
            composizioneNome != 'Retrò' &&
            (extantionNome == '4_Alzanti' ||
              extantionNome == 'Alzanti' ||
              extantionNome == 'Tutti i Modelli') &&
            (filtriMenu.text == 'Tutti i Modelli' ||
              filtriMenu.text == 'Alzanti' ||
              filtriMenu.text == '') &&
            dettaglio.nomeProdotto == 'Bristol'
          "
          :bench="benched"
          :items="getDati"
          item-height="80"
          height="720"
          style="width: 31em"
        >
          <v-col style="margin-top: 0em; background-color: white" cols="12">
            <v-row style="margin-top: 0em; margin-left: 3.5em">
              <v-col v-for="(dettaglio, i) in getDati" :key="i">
                <v-card
                  style="width: auto; height: auto; padding: 0px; margin-top: -1.5em"
                  class="elevation-0 mb-0"
                >
                  <v-img
                    v-on:click="getDettComposizione(dettaglio)"
                    style="
                      width: 150px;
                      height: 300px;
                      margin-top: 10px;
                      cursor: pointer;
                      margin-left: 9em;
                    "
                    :src="dettaglio.url"
                    :lazy-src="dettaglio.url"
                    aspect-ratio="1"
                    class="white lighten-2 mb-5"
                  >
                    <template v-slot:placeholder>
                      <v-row class="fill-height ma-0" align="center" justify="center">
                        <v-progress-circular
                          indeterminate
                          color="black lighten-5"
                        ></v-progress-circular>
                      </v-row>
                    </template>
                  </v-img>
                  <span
                    style="font-size: 12px"
                    v-if="dettaglio.nomeComposizione != '02000'"
                    >{{ dettaglio.nomeComposizione }}
                    <v-divider v-if="dettaglio.nomeComposizione != '02000'"></v-divider>
                  </span>
                </v-card>
              </v-col>
            </v-row>
          </v-col>
        </v-virtual-scroll>

        <!-- GINEVRA -->
        <v-virtual-scroll
          v-if="
            composizioneNome == 'Retrò' &&
            (extantionNome == '4_Alzanti' ||
              extantionNome == 'Alzanti' ||
              extantionNome == 'Tutti i Modelli') &&
            (filtriMenu.text == 'Tutti i Modelli' ||
              filtriMenu.text == 'Alzanti' ||
              filtriMenu.text == '') &&
            dettaglio.nomeProdotto == 'Ginevra'
          "
          :bench="benched"
          :items="getDati"
          item-height="80"
          height="720"
          style="width: 31em"
        >
          <v-col style="margin-top: 0em; background-color: white" cols="12">
            <v-row style="margin-top: 0em; margin-left: 3.5em">
              <v-col v-for="(dettaglio, i) in getDati" :key="i">
                <v-card
                  style="width: auto; height: auto; padding: 0px; margin-top: -1.5em"
                  class="elevation-0 mb-0"
                >
                  <v-img
                    v-on:click="getDettComposizione(dettaglio)"
                    style="
                      width: 150px;
                      height: 300px;
                      margin-top: 10px;
                      cursor: pointer;
                      margin-left: 9em;
                    "
                    :src="dettaglio.url"
                    :lazy-src="dettaglio.url"
                    aspect-ratio="1"
                    class="white lighten-2 mb-5"
                  >
                    <template v-slot:placeholder>
                      <v-row class="fill-height ma-0" align="center" justify="center">
                        <v-progress-circular
                          indeterminate
                          color="black lighten-5"
                        ></v-progress-circular>
                      </v-row>
                    </template>
                  </v-img>
                  <span
                    style="font-size: 12px"
                    v-if="dettaglio.nomeComposizione != '02000'"
                    >{{ dettaglio.nomeComposizione }}
                    <v-divider v-if="dettaglio.nomeComposizione != '02000'"></v-divider>
                  </span>
                </v-card>
              </v-col>
            </v-row>
          </v-col>
        </v-virtual-scroll>

        <!-- GLASGOW -->
        <v-virtual-scroll
          v-if="
            composizioneNome != 'Retrò' &&
            (extantionNome == '4_Alzanti' ||
              extantionNome == 'Alzanti' ||
              extantionNome == 'Tutti i Modelli') &&
            (filtriMenu.text == 'Tutti i Modelli' ||
              filtriMenu.text == 'Alzanti' ||
              filtriMenu.text == '') &&
            dettaglio.nomeProdotto == 'Glasgow'
          "
          :bench="benched"
          :items="getDati"
          item-height="80"
          height="720"
          style="width: 31em"
        >
          <v-col style="margin-top: 0em; background-color: white" cols="12">
            <v-row style="margin-top: 0em; margin-left: 3.5em">
              <v-col v-for="(dettaglio, i) in getDati" :key="i">
                <v-card
                  style="width: auto; height: auto; padding: 0px; margin-top: -1.5em"
                  class="elevation-0 mb-0"
                >
                  <v-img
                    v-on:click="getDettComposizione(dettaglio)"
                    style="
                      width: 150px;
                      height: 300px;
                      margin-top: 10px;
                      cursor: pointer;
                      margin-left: 9em;
                    "
                    :src="dettaglio.url"
                    :lazy-src="dettaglio.url"
                    aspect-ratio="1"
                    class="white lighten-2 mb-5"
                  >
                    <template v-slot:placeholder>
                      <v-row class="fill-height ma-0" align="center" justify="center">
                        <v-progress-circular
                          indeterminate
                          color="black lighten-5"
                        ></v-progress-circular>
                      </v-row>
                    </template>
                  </v-img>
                  <span
                    style="font-size: 12px"
                    v-if="dettaglio.nomeComposizione != '02000'"
                    >{{ dettaglio.nomeComposizione }}
                    <v-divider v-if="dettaglio.nomeComposizione != '02000'"></v-divider>
                  </span>
                </v-card>
              </v-col>
            </v-row>
          </v-col>
        </v-virtual-scroll>

        <!-- LEEDS -->
        <v-virtual-scroll
          v-if="
            composizioneNome != 'Retrò' &&
            (extantionNome == '4_Alzanti' ||
              extantionNome == 'Alzanti' ||
              extantionNome == 'Tutti i Modelli') &&
            (filtriMenu.text == 'Tutti i Modelli' ||
              filtriMenu.text == 'Alzanti' ||
              filtriMenu.text == '') &&
            dettaglio.nomeProdotto == 'Leeds'
          "
          :bench="benched"
          :items="getDati"
          item-height="80"
          height="720"
          style="width: 31em"
        >
          <v-col style="margin-top: 0em; background-color: white" cols="12">
            <v-row style="margin-top: 0em; margin-left: 3.5em">
              <v-col v-for="(dettaglio, i) in getDati" :key="i">
                <v-card
                  style="width: auto; height: auto; padding: 0px; margin-top: -1.5em"
                  class="elevation-0 mb-0"
                >
                  <v-img
                    v-on:click="getDettComposizione(dettaglio)"
                    style="
                      width: 150px;
                      height: 300px;
                      margin-top: 10px;
                      cursor: pointer;
                      margin-left: 9em;
                    "
                    :src="dettaglio.url"
                    :lazy-src="dettaglio.url"
                    aspect-ratio="1"
                    class="white lighten-2 mb-5"
                  >
                    <template v-slot:placeholder>
                      <v-row class="fill-height ma-0" align="center" justify="center">
                        <v-progress-circular
                          indeterminate
                          color="black lighten-5"
                        ></v-progress-circular>
                      </v-row>
                    </template>
                  </v-img>
                  <span
                    style="font-size: 12px"
                    v-if="dettaglio.nomeComposizione != '02000'"
                    >{{ dettaglio.nomeComposizione }}
                    <v-divider v-if="dettaglio.nomeComposizione != '02000'"></v-divider>
                  </span>
                </v-card>
              </v-col>
            </v-row>
          </v-col>
        </v-virtual-scroll>

        <!-- LISBONA -->
        <v-virtual-scroll
          v-if="
            composizioneNome == 'Retrò' &&
            (extantionNome == '4_Alzanti' ||
              extantionNome == 'Alzanti' ||
              extantionNome == 'Tutti i Modelli') &&
            (filtriMenu.text == 'Tutti i Modelli' ||
              filtriMenu.text == 'Alzanti' ||
              filtriMenu.text == '') &&
            dettaglio.nomeProdotto == 'Lisbona'
          "
          :bench="benched"
          :items="getDati"
          item-height="80"
          height="720"
          style="width: 31em"
        >
          <v-col style="margin-top: 0em; background-color: white" cols="12">
            <v-row style="margin-top: 0em; margin-left: 3.5em">
              <v-col v-for="(dettaglio, i) in getDati" :key="i">
                <v-card
                  style="width: auto; height: auto; padding: 0px; margin-top: -1.5em"
                  class="elevation-0 mb-0"
                >
                  <v-img
                    v-on:click="getDettComposizione(dettaglio)"
                    style="
                      width: 150px;
                      height: 300px;
                      margin-top: 10px;
                      cursor: pointer;
                      margin-left: 9em;
                    "
                    :src="dettaglio.url"
                    :lazy-src="dettaglio.url"
                    aspect-ratio="1"
                    class="white lighten-2 mb-5"
                  >
                    <template v-slot:placeholder>
                      <v-row class="fill-height ma-0" align="center" justify="center">
                        <v-progress-circular
                          indeterminate
                          color="black lighten-5"
                        ></v-progress-circular>
                      </v-row>
                    </template>
                  </v-img>
                  <span
                    style="font-size: 12px"
                    v-if="dettaglio.nomeComposizione != '02000'"
                    >{{ dettaglio.nomeComposizione }}
                    <v-divider v-if="dettaglio.nomeComposizione != '02000'"></v-divider>
                  </span>
                </v-card>
              </v-col>
            </v-row>
          </v-col>
        </v-virtual-scroll>

        <!-- SOCHI -->
        <v-virtual-scroll
          v-if="
            composizioneNome != 'Retrò' &&
            (extantionNome == '4_Alzanti' ||
              extantionNome == 'Alzanti' ||
              extantionNome == 'Tutti i Modelli') &&
            (filtriMenu.text == 'Tutti i Modelli' ||
              filtriMenu.text == 'Alzanti' ||
              filtriMenu.text == '') &&
            dettaglio.nomeProdotto == 'Sochi'
          "
          :bench="benched"
          :items="getDati"
          item-height="80"
          height="720"
          style="width: 31em"
        >
          <v-col style="margin-top: 0em; background-color: white" cols="12">
            <v-row style="margin-top: 0em; margin-left: 3.5em">
              <v-col v-for="(dettaglio, i) in getDati" :key="i">
                <v-card
                  style="width: auto; height: auto; padding: 0px; margin-top: -1.5em"
                  class="elevation-0 mb-0"
                >
                  <v-img
                    v-on:click="getDettComposizione(dettaglio)"
                    style="
                      width: 150px;
                      height: 300px;
                      margin-top: 10px;
                      cursor: pointer;
                      margin-left: 9em;
                    "
                    :src="dettaglio.url"
                    :lazy-src="dettaglio.url"
                    aspect-ratio="1"
                    class="white lighten-2 mb-5"
                  >
                    <template v-slot:placeholder>
                      <v-row class="fill-height ma-0" align="center" justify="center">
                        <v-progress-circular
                          indeterminate
                          color="black lighten-5"
                        ></v-progress-circular>
                      </v-row>
                    </template>
                  </v-img>
                  <span
                    style="font-size: 12px"
                    v-if="dettaglio.nomeComposizione != '02000'"
                    >{{ dettaglio.nomeComposizione }}
                    <v-divider v-if="dettaglio.nomeComposizione != '02000'"></v-divider>
                  </span>
                </v-card>
              </v-col>
            </v-row>
          </v-col>
        </v-virtual-scroll>

        <!-- SOCHI Q -->
        <v-virtual-scroll
          v-if="
            composizioneNome != 'Retrò' &&
            (extantionNome == '4_Alzanti' ||
              extantionNome == 'Alzanti' ||
              extantionNome == 'Tutti i Modelli') &&
            (filtriMenu.text == 'Tutti i Modelli' ||
              filtriMenu.text == 'Alzanti' ||
              filtriMenu.text == '') &&
            dettaglio.nomeProdotto == 'Sochi Q'
          "
          :bench="benched"
          :items="getDati"
          item-height="80"
          height="720"
          style="width: 31em"
        >
          <v-col style="margin-top: 0em; background-color: white" cols="12">
            <v-row style="margin-top: 0em; margin-left: 3.5em">
              <v-col v-for="(dettaglio, i) in getDati" :key="i">
                <v-card
                  style="width: auto; height: auto; padding: 0px; margin-top: -1.5em"
                  class="elevation-0 mb-0"
                >
                  <v-img
                    v-on:click="getDettComposizione(dettaglio)"
                    style="
                      width: 150px;
                      height: 300px;
                      margin-top: 10px;
                      cursor: pointer;
                      margin-left: 9em;
                    "
                    :src="dettaglio.url"
                    :lazy-src="dettaglio.url"
                    aspect-ratio="1"
                    class="white lighten-2 mb-5"
                  >
                    <template v-slot:placeholder>
                      <v-row class="fill-height ma-0" align="center" justify="center">
                        <v-progress-circular
                          indeterminate
                          color="black lighten-5"
                        ></v-progress-circular>
                      </v-row>
                    </template>
                  </v-img>
                  <span
                    style="font-size: 12px"
                    v-if="dettaglio.nomeComposizione != '02000'"
                    >{{ dettaglio.nomeComposizione }}
                    <v-divider v-if="dettaglio.nomeComposizione != '02000'"></v-divider>
                  </span>
                </v-card>
              </v-col>
            </v-row>
          </v-col>
        </v-virtual-scroll>

        <!-- SCROLLBAR PER SCORREVOLI -->
        <!-- KIT SOCHI -->
        <v-virtual-scroll
          v-if="
            composizioneNome != 'Retrò' &&
            (extantionNome == '5_Scorrevoli' ||
              extantionNome == 'Scorrevoli' ||
              extantionNome == 'Tutti i Modelli') &&
            (filtriMenu.text == 'Tutti i Modelli' ||
              filtriMenu.text == 'Scorrevoli' ||
              filtriMenu.text == '') &&
            dettaglio.nomeProdotto == 'Kit Sochi'
          "
          :bench="benched"
          :items="getDati"
          item-height="117"
          height="720"
          style="width: 31em"
        >
          <v-col style="margin-top: -40em; background-color: white" cols="12">
            <v-row style="margin-top: 0em; margin-left: 3.5em">
              <v-col v-for="(dettaglio, i) in getDati" :key="i">
                <v-card
                  style="width: auto; height: auto; padding: 0px; margin-top: -1.5em"
                  class="elevation-0 mb-0"
                >
                  <v-img
                    v-on:click="getDettComposizione(dettaglio)"
                    style="width: 180px; height: 200px; margin-top: 10px; cursor: pointer"
                    :src="dettaglio.url"
                    :lazy-src="dettaglio.url"
                    aspect-ratio="1"
                    class="white lighten-2 mb-5"
                  >
                    <template v-slot:placeholder>
                      <v-row class="fill-height ma-0" align="center" justify="center">
                        <v-progress-circular
                          indeterminate
                          color="black lighten-5"
                        ></v-progress-circular>
                      </v-row>
                    </template>
                  </v-img>
                  <span style="font-size: 12px" v-if="dettaglio.nomeComposizione != '99'"
                    >{{ dettaglio.nomeComposizione }}
                    <v-divider v-if="dettaglio.nomeComposizione != '99'"></v-divider>
                  </span>
                </v-card>
              </v-col>
            </v-row>
          </v-col>
        </v-virtual-scroll>

        <!-- KIT SOCHI Q -->
        <v-virtual-scroll
          v-if="
            composizioneNome != 'Retrò' &&
            (extantionNome == '5_Scorrevoli' ||
              extantionNome == 'Scorrevoli' ||
              extantionNome == 'Tutti i Modelli') &&
            (filtriMenu.text == 'Tutti i Modelli' ||
              filtriMenu.text == 'Scorrevoli' ||
              filtriMenu.text == '') &&
            dettaglio.nomeProdotto == 'Kit Sochi Q'
          "
          :bench="benched"
          :items="getDati"
          item-height="117"
          height="720"
          style="width: 31em"
        >
          <v-col style="margin-top: -40em; background-color: white" cols="12">
            <v-row style="margin-top: 0em; margin-left: 3.5em">
              <v-col v-for="(dettaglio, i) in getDati" :key="i">
                <v-card
                  style="width: auto; height: auto; padding: 0px; margin-top: -1.5em"
                  class="elevation-0 mb-0"
                >
                  <v-img
                    v-on:click="getDettComposizione(dettaglio)"
                    style="width: 180px; height: 200px; margin-top: 10px; cursor: pointer"
                    :src="dettaglio.url"
                    :lazy-src="dettaglio.url"
                    aspect-ratio="1"
                    class="white lighten-2 mb-5"
                  >
                    <template v-slot:placeholder>
                      <v-row class="fill-height ma-0" align="center" justify="center">
                        <v-progress-circular
                          indeterminate
                          color="black lighten-5"
                        ></v-progress-circular>
                      </v-row>
                    </template>
                  </v-img>
                  <span style="font-size: 12px" v-if="dettaglio.nomeComposizione != '99'"
                    >{{ dettaglio.nomeComposizione }}
                    <v-divider v-if="dettaglio.nomeComposizione != '99'"></v-divider>
                  </span>
                </v-card>
              </v-col>
            </v-row>
          </v-col>
        </v-virtual-scroll>

        <!-- KIT GINEVRA -->
        <v-virtual-scroll
          v-if="
            composizioneNome == 'Retrò' &&
            (extantionNome == '5_Scorrevoli' ||
              extantionNome == 'Scorrevoli' ||
              extantionNome == 'Tutti i Modelli') &&
            (filtriMenu.text == 'Tutti i Modelli' ||
              filtriMenu.text == 'Scorrevoli' ||
              filtriMenu.text == '') &&
            dettaglio.nomeProdotto == 'Kit Ginevra'
          "
          :bench="benched"
          :items="getDati"
          item-height="117"
          height="720"
          style="width: 31em"
        >
          <v-col style="margin-top: -40em; background-color: white" cols="12">
            <v-row style="margin-top: 0em; margin-left: 3.5em">
              <v-col v-for="(dettaglio, i) in getDati" :key="i">
                <v-card
                  style="width: auto; height: auto; padding: 0px; margin-top: -1.5em"
                  class="elevation-0 mb-0"
                >
                  <v-img
                    v-on:click="getDettComposizione(dettaglio)"
                    style="width: 180px; height: 200px; margin-top: 10px; cursor: pointer"
                    :src="dettaglio.url"
                    :lazy-src="dettaglio.url"
                    aspect-ratio="1"
                    class="white lighten-2 mb-5"
                  >
                    <template v-slot:placeholder>
                      <v-row class="fill-height ma-0" align="center" justify="center">
                        <v-progress-circular
                          indeterminate
                          color="black lighten-5"
                        ></v-progress-circular>
                      </v-row>
                    </template>
                  </v-img>
                  <span style="font-size: 12px" v-if="dettaglio.nomeComposizione != '99'"
                    >{{ dettaglio.nomeComposizione }}
                    <v-divider v-if="dettaglio.nomeComposizione != '99'"></v-divider>
                  </span>
                </v-card>
              </v-col>
            </v-row>
          </v-col>
        </v-virtual-scroll>
      </v-responsive>

      <!-- PRODOTTO NO MANIGLIONI / ALZANTI -->
      <v-col
        v-if="
          dettaglioTipo != '3_Maniglioni' &&
          dettaglioTipo != '4_Alzanti' &&
          dettaglioTipo != 'Maniglioni' &&
          dettaglioTipo != 'Alzanti'
        "
        style="margin-top: -3em; margin-left: 0%"
        cols="5"
      >
        <div>
          <!-- CAROSELLO IMMAGINE -->
          <!-- hide-delimiter-background -->
          <!-- hide-delimiter-background -->
          <!-- height="625" -->
          <!-- <v-carousel
                        light
                        hide-delimiters
                        style="margin-top: 15em; margin-left: -3em"
                        height="600"
                      >
                        <template v-slot:prev="{ on, attrs }">
                          <v-icon
                            style="background-color: white"
                            x-large
                            color="#e40520"
                            v-bind="attrs"
                            v-on="on"
                            >arrow_back_ios
                          </v-icon>
                        </template>
                        <template v-slot:next="{ on, attrs }">
                          <v-icon
                            style="background-color: white"
                            x-large
                            color="#e40520"
                            v-bind="attrs"
                            v-on="on"
                            >arrow_forward_ios
                          </v-icon>
                        </template>
                        <v-carousel-item
                          style="margin-left: -10em"
                          v-for="(item, i) in items"
                          :key="i"
                          :src="item.src"
                          reverse-transition="fade-transition"
                          transition="fade-transition"
                        >
                        </v-carousel-item>
                      </v-carousel> -->

          <!-- IMG PRODOTTO -->
          <!-- v-on:click="getImgComposizione(dettaglio)" -->
          <v-img
            style="
              width: 600px;
              height: 890px;
              margin-top: 0em;
              margin-left: -2em;
              cursor: pointer;
            "
            :src="dettaglio.url"
            :lazy-src="dettaglio.url"
            aspect-ratio="1"
            class="white lighten-2"
          >
            <template v-slot:placeholder>
              <v-row class="fill-height ma-0" align="center" justify="center">
                <v-progress-circular
                  indeterminate
                  color="black lighten-5"
                ></v-progress-circular>
              </v-row>
            </template>
          </v-img>
        </div>
      </v-col>

      <!-- PRODOTTO SI MANIGLIONI / ALZANTI -->
      <v-col v-else style="margin-top: -3em; margin-left: 0%" cols="3">
        <div>
          <!-- v-on:click="getImgComposizione(dettaglio)" -->
          <v-img
            style="width: 600px; height: 890px; margin-top: 2em; margin-left: -6em"
            :src="dettaglio.url"
            :lazy-src="dettaglio.url"
            aspect-ratio="1"
          >
            <template v-slot:placeholder>
              <v-row class="fill-height ma-0" align="center" justify="center">
                <v-progress-circular
                  indeterminate
                  color="black lighten-5"
                ></v-progress-circular>
              </v-row>
            </template>
          </v-img>
        </div>
      </v-col>

      <!-- DESCRIZIONE PRODOTTO -->
      <v-col
        v-if="
          dettaglioTipo != '3_Maniglioni' &&
          dettaglioTipo != '4_Alzanti' &&
          dettaglioTipo != 'Maniglioni' &&
          dettaglioTipo != 'Alzanti'
        "
        style="margin-top: 0em"
        cols="3"
      >
        <v-row style="margin-left: -60%" class="d-flex child-flex">
          <h1 style="background-color: trasparent; position: absolute; margin-top: 3%">
            {{ dettaglio.nomeProdotto.toUpperCase() }}
          </h1>

          <div
            style="
              position: absolute;
              cursor: pointer;
              margin-left: 31em;
              margin-top: 3%;
              width: auto;
            "
          >
            <!-- <v-tooltip left>
                          <template v-slot:activator="{ on, attrs }">
                            <v-img
                              style="width: 60px; margin-left: -10px"
                              v-bind="attrs"
                              v-on="on"
                              v-on:click="vistaAngolare()"
                              :src="require('@/assets/img/3d_.png')"
                            >
                              <template v-slot:placeholder>
                                <v-row class="fill-height ma-0" align="center" justify="center">
                                  <v-progress-circular
                                    indeterminate
                                    color="black lighten-5"
                                  ></v-progress-circular>
                                </v-row>
                              </template>
                            </v-img>
                          </template>
                          <span>Vista 360°</span>
                        </v-tooltip>

                        <v-tooltip left>
                          <template v-slot:activator="{ on, attrs }">
                            <v-img
                              style="width: 70px; margin-left: -10px"
                              v-bind="attrs"
                              v-on="on"
                              v-on:click="vistaAngolare()"
                              class="mt-5"
                              :src="require('@/assets/img/Esploso.png')"
                            >
                              <template v-slot:placeholder>
                                <v-row class="fill-height ma-0" align="center" justify="center">
                                  <v-progress-circular
                                    indeterminate
                                    color="black lighten-5"
                                  ></v-progress-circular>
                                </v-row>
                              </template>
                            </v-img>
                          </template>
                          <span>Esploso</span>
                        </v-tooltip> -->

            <v-tooltip left>
              <template v-slot:activator="{ on, attrs }">
                <v-img
                  style="width: 40px; margin-left: -2px"
                  v-bind="attrs"
                  v-on="on"
                  v-on:click="chiudi(dettaglio)"
                  class="mt-6"
                  :src="require('@/assets/img/Scheda Tecnica.png')"
                >
                  <template v-slot:placeholder>
                    <v-row class="fill-height ma-0" align="center" justify="center">
                      <v-progress-circular
                        indeterminate
                        color="black lighten-5"
                      ></v-progress-circular>
                    </v-row>
                  </template>
                </v-img>
              </template>
              <span>Scheda tecnica</span>
            </v-tooltip>
          </div>

          <span
            style="
              background-color: trasparent;
              position: absolute;
              font-size: 15px;
              width: 350px;
              margin-top: 6%;
            "
          >
            {{ descrizioneProdotto }}
          </span>
        </v-row>

        <!-- FINITURE PER DETTAGLIO PRODOTTO NO MANIGLIONE / ALZANTE -->
        <v-row
          v-if="
            dettaglioTipo != '3_Maniglioni' &&
            dettaglioTipo != '4_Alzanti' &&
            dettaglioTipo != 'Maniglioni' &&
            dettaglioTipo != 'Alzanti'
          "
          style="margin-top: 100%"
          class="d-flex child-fle"
        >
          <v-col cols="6">
            <!-- FINITURE STANDARD -->
            <v-row>
              <h3
                v-if="getDatiFinitureStandard(dettaglio) != 0"
                style="margin-top: -1em; text-align: left; margin-right: 0%"
              >
                Finiture Standard
              </h3>
            </v-row>
            <br />

            <v-col
              :style="[
                isTelefono() <= 768
                  ? { 'min-width': '20%', 'margin-top': '0%' }
                  : { 'min-width': '20%', 'margin-top': '0%' },
              ]"
              style="padding: 0"
              v-for="(dettaglio, i) in getDatiFinitureStandard(dettaglio)"
              :key="i"
              class="d-flex child-flex"
              cols="12"
            >
              <v-row>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <!-- SI SCORREVOLI -->
                    <v-card
                      v-if="
                        dettaglio.nomeProdotto == 'Kit Sochi' ||
                        dettaglio.nomeProdotto == 'Kit Sochi Q' ||
                        dettaglio.nomeProdotto == 'Kit Ginevra'
                      "
                      style="
                        width: auto;
                        height: auto;
                        padding: 0px;
                        margin-left: 15%;
                        margin-bottom: 3em;
                      "
                      class="elevation-0"
                    >
                      <v-img
                        v-on:click="getDettFiniture(dettaglio)"
                        v-bind="attrs"
                        v-on="on"
                        style="width: 75px; height: auto; cursor: pointer"
                        :src="dettaglio.url"
                        :lazy-src="dettaglio.url"
                      >
                        <template v-slot:placeholder>
                          <v-row class="fill-height ma-0" align="center" justify="center">
                            <v-progress-circular
                              indeterminate
                              color="black lighten-5"
                            ></v-progress-circular>
                          </v-row>
                        </template>
                      </v-img>
                    </v-card>

                    <!-- NO SCORREVOLI -->
                    <v-card
                      v-else
                      style="
                        width: auto;
                        height: auto;
                        padding: 0px;
                        margin-left: -0.5em;
                        margin-bottom: 3em;
                      "
                      class="elevation-0"
                    >
                      <v-img
                        v-on:click="getDettFiniture(dettaglio)"
                        v-bind="attrs"
                        v-on="on"
                        style="width: 155px; height: auto; cursor: pointer"
                        :src="dettaglio.url"
                        :lazy-src="dettaglio.url"
                      >
                        <template v-slot:placeholder>
                          <v-row class="fill-height ma-0" align="center" justify="center">
                            <v-progress-circular
                              indeterminate
                              color="black lighten-5"
                            ></v-progress-circular>
                          </v-row>
                        </template>
                      </v-img>
                    </v-card>
                  </template>
                  <span>{{ dettaglio.tipoFinitura }}</span>
                </v-tooltip>
              </v-row>
            </v-col>
          </v-col>

          <v-col cols="6" v-if="getDatiFiniturePvd(dettaglio) != 0">
            <!-- FINITURE PVD -->
            <v-row>
              <h3 style="margin-top: -1em; text-align: left; margin-right: 0%">
                Finiture PVD
              </h3>
            </v-row>
            <br />
            <v-col
              :style="[
                isTelefono() <= 768
                  ? { 'min-width': '20%', 'margin-top': '0%' }
                  : { 'min-width': '20%', 'margin-top': '0%' },
              ]"
              style="padding: 0"
              v-for="(dettaglio, i) in getDatiFiniturePvd(dettaglio)"
              :key="i"
              class="d-flex child-flex"
              cols="12"
            >
              <v-row>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <!-- SI SCORREVOLI -->
                    <v-card
                      v-if="
                        dettaglio.nomeProdotto == 'Kit Sochi' ||
                        dettaglio.nomeProdotto == 'Kit Sochi Q' ||
                        dettaglio.nomeProdotto == 'Kit Ginevra'
                      "
                      style="
                        width: auto;
                        height: auto;
                        padding: 0px;
                        margin-left: 7.5%;
                        margin-bottom: 3em;
                      "
                      class="elevation-0"
                    >
                      <v-img
                        v-on:click="getDettFiniture(dettaglio)"
                        v-bind="attrs"
                        v-on="on"
                        style="width: 75px; height: auto; cursor: pointer"
                        :src="dettaglio.url"
                        :lazy-src="dettaglio.url"
                      >
                        <template v-slot:placeholder>
                          <v-row class="fill-height ma-0" align="center" justify="center">
                            <v-progress-circular
                              indeterminate
                              color="black lighten-5"
                            ></v-progress-circular>
                          </v-row>
                        </template>
                      </v-img>
                    </v-card>

                    <!-- NO SCORREVOLI -->
                    <v-card
                      v-else
                      style="
                        width: auto;
                        height: auto;
                        padding: 0px;
                        margin-left: -0.5em;
                        margin-bottom: 3em;
                      "
                      class="elevation-0"
                    >
                      <v-img
                        v-on:click="getDettFiniture(dettaglio)"
                        v-bind="attrs"
                        v-on="on"
                        style="width: 155px; height: auto; cursor: pointer"
                        :src="dettaglio.url"
                        :lazy-src="dettaglio.url"
                      >
                        <template v-slot:placeholder>
                          <v-row class="fill-height ma-0" align="center" justify="center">
                            <v-progress-circular
                              indeterminate
                              color="black lighten-5"
                            ></v-progress-circular>
                          </v-row>
                        </template>
                      </v-img>
                    </v-card>
                  </template>
                  <span>{{ dettaglio.tipoFinitura }}</span>
                </v-tooltip>
              </v-row>
            </v-col>
          </v-col>

          <v-col cols="6" v-else>
            <!-- FINITURE RETRO' -->
            <v-row>
              <h3
                v-if="getDatiFinitureRetro(dettaglio) != 0"
                style="margin-top: -1em; text-align: left; margin-right: 0%"
              >
                Finiture Retrò
              </h3>
            </v-row>
            <br />
            <v-col
              :style="[
                isTelefono() <= 768
                  ? { 'min-width': '20%', 'margin-top': '0%' }
                  : { 'min-width': '20%', 'margin-top': '0%' },
              ]"
              style="padding: 0"
              v-for="(dettaglio, i) in getDatiFinitureRetro(dettaglio)"
              :key="i"
              class="d-flex child-flex"
              cols="12"
            >
              <v-row>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <!-- SI SCORREVOLI -->
                    <v-card
                      v-if="
                        dettaglio.nomeProdotto == 'Kit Sochi' ||
                        dettaglio.nomeProdotto == 'Kit Sochi Q' ||
                        dettaglio.nomeProdotto == 'Kit Ginevra'
                      "
                      style="
                        width: auto;
                        height: auto;
                        padding: 0px;
                        margin-left: 10%;
                        margin-bottom: 3em;
                      "
                      class="elevation-0"
                    >
                      <v-img
                        v-on:click="getDettFiniture(dettaglio)"
                        v-bind="attrs"
                        v-on="on"
                        style="width: 75px; height: auto; cursor: pointer"
                        :src="dettaglio.url"
                        :lazy-src="dettaglio.url"
                      >
                        <template v-slot:placeholder>
                          <v-row class="fill-height ma-0" align="center" justify="center">
                            <v-progress-circular
                              indeterminate
                              color="black lighten-5"
                            ></v-progress-circular>
                          </v-row>
                        </template>
                      </v-img>
                    </v-card>

                    <!-- NO SCORREVOLI -->
                    <v-card
                      v-else
                      style="
                        width: auto;
                        height: auto;
                        padding: 0px;
                        margin-left: -0.5em;
                        margin-bottom: 3em;
                      "
                      class="elevation-0"
                    >
                      <v-img
                        v-on:click="getDettFiniture(dettaglio)"
                        v-bind="attrs"
                        v-on="on"
                        style="width: 155px; height: auto; cursor: pointer"
                        :src="dettaglio.url"
                        :lazy-src="dettaglio.url"
                      >
                        <template v-slot:placeholder>
                          <v-row class="fill-height ma-0" align="center" justify="center">
                            <v-progress-circular
                              indeterminate
                              color="black lighten-5"
                            ></v-progress-circular>
                          </v-row>
                        </template>
                      </v-img>
                    </v-card>
                  </template>
                  <span>{{ dettaglio.tipoFinitura }}</span>
                </v-tooltip>
              </v-row>
            </v-col>
          </v-col>

          <!-- CODICE E SECONDA DESCRIZIONE PRODOTTO NO MANIGLIONI E ALZANTI -->
          <span style="margin-top: 15em; margin-left: -22em; position: absolute">
            <img
              v-if="novitaProdotto == true"
              style="
                width: 60px;
                height: 60px;
                margin-top: -5em;
                margin-left: -10em;
                float: right;
              "
              :src="require('@/assets/img/Novità.png')"
            />
            <img
              v-if="disponibilitaProdotto == true"
              style="
                width: 60px;
                height: 60px;
                margin-top: -5em;
                margin-left: -10em;
                float: right;
              "
              :src="require('@/assets/img/DisponibileMaggio23.png')"
            />
            <img
              v-if="restyling == true"
              style="
                width: 60px;
                height: 60px;
                margin-top: -5em;
                margin-left: -10em;
                float: right;
              "
              :src="require('@/assets/img/Restyling.png')"
            />
            <v-row>
              <h3 v-if="dettaglio.cdCompletoProdottoDettaglio == ''">
                {{ dettaglio.cdCompletoProdotto }}
              </h3>
              <h3 v-else>
                {{ dettaglio.cdCompletoProdottoDettaglio }}
              </h3>
              <!-- <v-tooltip right>
                            <template v-slot:activator="{ on, attrs }">
                              <v-icon
                                v-bind="attrs"
                                v-on="on"
                                style="cursor: pointer"
                                class="ml-1"
                                color="primary"
                                v-on:click="imgProdottoDetttaglio(dettaglio)"
                                >add</v-icon
                              >
                            </template>
                            <span>sotto composizioni</span>
                          </v-tooltip> -->
            </v-row>
            <v-row>
              <div style="width: 18.5em; font-size: 15px">
                <span v-html="dettaglio.secondaDescrizione"></span>
              </div>
            </v-row>
          </span>
        </v-row>
      </v-col>

      <!-- DESCRIZIONE PRODOTTO SI MANIGLIONI E ALZANTI -->
      <v-col v-else style="margin-top: 0em; margin-left: 16em" cols="3">
        <v-row style="margin-left: -50%" class="d-flex child-flex">
          <h1 style="background-color: trasparent; position: absolute; margin-top: 3%">
            {{ dettaglio.nomeProdotto.toUpperCase() }}
          </h1>

          <v-col
            cols="12"
            style="position: absolute; cursor: pointer; margin-left: 33%; margin-top: 3%"
          >
            <!-- <v-tooltip left>
                          <template v-slot:activator="{ on, attrs }">
                            <v-img
                              style="width: 60px; margin-left: -6em"
                              v-bind="attrs"
                              v-on="on"
                              v-on:click="vistaAngolare()"
                              :src="require('@/assets/img/3d_.png')"
                            >
                              <template v-slot:placeholder>
                                <v-row class="fill-height ma-0" align="center" justify="center">
                                  <v-progress-circular
                                    indeterminate
                                    color="black lighten-5"
                                  ></v-progress-circular>
                                </v-row>
                              </template>
                            </v-img>
                          </template>
                          <span>Vista 360°</span>
                        </v-tooltip>

                        <v-tooltip left>
                          <template v-slot:activator="{ on, attrs }">
                            <v-img
                              style="width: 60px; margin-left: -6em"
                              v-bind="attrs"
                              v-on="on"
                              v-on:click="vistaAngolare()"
                              class="mt-4"
                              :src="require('@/assets/img/Esploso.png')"
                            >
                              <template v-slot:placeholder>
                                <v-row class="fill-height ma-0" align="center" justify="center">
                                  <v-progress-circular
                                    indeterminate
                                    color="black lighten-5"
                                  ></v-progress-circular>
                                </v-row>
                              </template>
                            </v-img>
                          </template>
                          <span>Esploso</span>
                        </v-tooltip> -->

            <v-tooltip left>
              <template v-slot:activator="{ on, attrs }">
                <v-img
                  style="width: 40px; margin-left: -5.5em"
                  v-bind="attrs"
                  v-on="on"
                  v-on:click="chiudi(dettaglio)"
                  class="mt-6"
                  :src="require('@/assets/img/Scheda Tecnica.png')"
                >
                  <template v-slot:placeholder>
                    <v-row class="fill-height ma-0" align="center" justify="center">
                      <v-progress-circular
                        indeterminate
                        color="black lighten-5"
                      ></v-progress-circular>
                    </v-row>
                  </template>
                </v-img>
              </template>
              <span>Scheda tecnica</span>
            </v-tooltip>
          </v-col>

          <span
            style="
              background-color: trasparent;
              position: absolute;
              font-size: 15px;
              width: 350px;
              margin-top: 6%;
            "
          >
            {{ descrizioneProdotto }}
          </span>
        </v-row>

        <!-- FINITURE PER DETTAGLIO PRODOTTO SI MANIGLIONI / ALZANTI -->
        <!-- FINITURE STANDARD -->
        <v-col
          v-if="
            dettaglioTipo == '3_Maniglioni' ||
            dettaglioTipo == '4_Alzanti' ||
            dettaglioTipo == 'Maniglioni' ||
            dettaglioTipo == 'Alzanti'
          "
          style="margin-top: 120%; min-width: 200%; margin-left: -10%"
          class="d-flex child-flex"
          cols="12"
        >
          <v-row>
            <div>
              <h3 style="margin-top: -8em; text-align: left; margin-left: -8em">
                Finiture Standard
              </h3>
            </div>
            <br />

            <v-sheet
              elevation="0"
              max-width="73%"
              min-height="100%"
              :style="[
                dettaglio.nomeProdotto == 'Dover' ||
                dettaglio.nomeProdotto == 'Belfast' ||
                dettaglio.nomeProdotto == 'Lahti'
                  ? { 'margin-left': '-11em' }
                  : { 'margin-left': '-7.8em' },
              ]"
              style="margin-top: -8em"
            >
              <!-- dettaglio.nomeProdotto == 'Glasgow' || -->
              <v-slide-group show-arrows v-model="this.datiFinitureStandard">
                <v-slide-item
                  class="pa-0"
                  v-for="(dettaglio, i) in getDatiFinitureStandard(dettaglio)"
                  :key="i"
                >
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-card
                        elevation="0"
                        style="width: auto; height: auto"
                        v-bind="attrs"
                        v-on="on"
                      >
                        <v-img
                          v-on:click="getDettFiniture(dettaglio)"
                          style="width: 100px; height: 200px; cursor: pointer"
                          :src="dettaglio.url"
                          :lazy-src="dettaglio.url"
                          aspect-ratio="1"
                        >
                          <template v-slot:placeholder>
                            <v-row
                              class="fill-height ma-0"
                              align="center"
                              justify="center"
                            >
                              <v-progress-circular
                                indeterminate
                                color="black lighten-5"
                              ></v-progress-circular>
                            </v-row>
                          </template>
                        </v-img>
                      </v-card>
                    </template>
                    <span>{{ dettaglio.tipoFinitura }}</span>
                  </v-tooltip>
                </v-slide-item>
              </v-slide-group>
            </v-sheet>
          </v-row>
        </v-col>

        <!-- FINITURE PVD -->
        <v-col
          v-if="
            (dettaglioTipo == '3_Maniglioni' ||
              dettaglioTipo == '4_Alzanti' ||
              dettaglioTipo == 'Maniglioni' ||
              dettaglioTipo == 'Alzanti') &&
            getDatiFiniturePvd(dettaglio) != 0
          "
          style="margin-top: 50%; min-width: 200%; margin-left: -10%"
          class="d-flex child-flex"
          cols="12"
        >
          <v-row>
            <div>
              <h3 style="margin-top: -8em; text-align: left; margin-left: -8em">
                Finiture PVD
              </h3>
            </div>
            <br />

            <v-sheet
              elevation="0"
              max-width="73%"
              min-height="100%"
              style="margin-top: -8em; margin-left: -7.8em"
            >
              <v-slide-group show-arrows v-model="this.datiFiniturePvd">
                <v-slide-item
                  class="pa-0"
                  v-for="(dettaglio, i) in getDatiFiniturePvd(dettaglio)"
                  :key="i"
                >
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-card
                        elevation="0"
                        style="width: auto; height: auto"
                        v-bind="attrs"
                        v-on="on"
                      >
                        <v-img
                          v-on:click="getDettFiniture(dettaglio)"
                          style="width: 100px; height: 200px; cursor: pointer"
                          :src="dettaglio.url"
                          :lazy-src="dettaglio.url"
                          aspect-ratio="1"
                        >
                          <template v-slot:placeholder>
                            <v-row
                              class="fill-height ma-0"
                              align="center"
                              justify="center"
                            >
                              <v-progress-circular
                                indeterminate
                                color="black lighten-5"
                              ></v-progress-circular>
                            </v-row>
                          </template>
                        </v-img>
                      </v-card>
                    </template>
                    <span>{{ dettaglio.tipoFinitura }}</span>
                  </v-tooltip>
                </v-slide-item>
              </v-slide-group>
            </v-sheet>
          </v-row>
        </v-col>

        <!-- FINITURE RETRO' -->
        <v-col
          v-if="
            (dettaglioTipo == '3_Maniglioni' ||
              dettaglioTipo == '4_Alzanti' ||
              dettaglioTipo == 'Maniglioni' ||
              dettaglioTipo == 'Alzanti') &&
            getDatiFinitureRetro(dettaglio) != 0
          "
          style="margin-top: 50%; min-width: 200%; margin-left: -10%"
          class="d-flex child-flex"
          cols="12"
        >
          <v-row>
            <div>
              <h3 style="margin-top: -8em; text-align: left; margin-left: -8em">
                Finiture Retrò
              </h3>
            </div>
            <br />

            <v-sheet
              elevation="0"
              max-width="73%"
              min-height="100%"
              style="margin-top: -8em; margin-left: -7.8em"
            >
              <v-slide-group show-arrows v-model="this.datiFinitureRetro">
                <v-slide-item
                  class="pa-0"
                  v-for="(dettaglio, i) in getDatiFinitureRetro(dettaglio)"
                  :key="i"
                >
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-card
                        elevation="0"
                        style="width: auto; height: auto"
                        v-bind="attrs"
                        v-on="on"
                      >
                        <v-img
                          v-on:click="getDettFiniture(dettaglio)"
                          style="width: 100px; height: 200px; cursor: pointer"
                          :src="dettaglio.url"
                          :lazy-src="dettaglio.url"
                          aspect-ratio="1"
                        >
                          <template v-slot:placeholder>
                            <v-row
                              class="fill-height ma-0"
                              align="center"
                              justify="center"
                            >
                              <v-progress-circular
                                indeterminate
                                color="black lighten-5"
                              ></v-progress-circular>
                            </v-row>
                          </template>
                        </v-img>
                      </v-card>
                    </template>
                    <span>{{ dettaglio.tipoFinitura }}</span>
                  </v-tooltip>
                </v-slide-item>
              </v-slide-group>
            </v-sheet>
          </v-row>
        </v-col>

        <!-- CODICE E SECONDA DESCRIZIONE PRODOTTO -->
        <span style="margin-top: -10em; margin-left: -35em; position: absolute">
          <img
            v-if="novitaProdotto == true"
            style="
              width: 60px;
              height: 60px;
              margin-top: -5em;
              margin-left: 0em;
              float: right;
            "
            :src="require('@/assets/img/Novità.png')"
          />
          <img
            v-if="disponibilitaProdotto == true"
            style="
              width: 60px;
              height: 60px;
              margin-top: -5em;
              margin-left: 0em;
              float: right;
            "
            :src="require('@/assets/img/DisponibileMaggio23.png')"
          />
          <img
            v-if="restyling == true"
            style="
              width: 60px;
              height: 60px;
              margin-top: -5em;
              margin-left: -10em;
              float: right;
            "
            :src="require('@/assets/img/Restyling.png')"
          />
          <h3 v-if="dettaglio.cdCompletoProdottoDettaglio == ''">
            {{ dettaglio.cdCompletoProdotto }}
          </h3>
          <h3 v-else>
            {{ dettaglio.cdCompletoProdottoDettaglio }}
          </h3>
          <div style="width: 20em; font-size: 15px">
            <span v-html="dettaglio.secondaDescrizione"></span>
          </div>
        </span>
      </v-col>
    </v-row>

    <!-- VISUALIZZAZIONE DA TABLET -->
    <v-row v-if="isTelefono() > 768 && isTelefono() <= 1540" style="margin-top: 0em">
      <!-- CONFIGURAZIONI -->
      <v-responsive
        class="overflow-x-auto"
        style="margin-top: 0%; margin-left: 2em"
        max-height="700"
        max-width="250"
      >
        <h3 class="mb-6" style="margin-left: 2em">Configurazioni</h3>

        <!-- SCROLLBAR PER QUADRO E TONDO (MANIGLIE) -->
        <v-virtual-scroll
          v-if="
            composizioneNome != 'Retrò' &&
            (extantionNome == '1_Maniglie' ||
              extantionNome == 'Maniglie' ||
              extantionNome == 'Tutti i Modelli') &&
            (filtriMenu.text == 'Tutti i Modelli' ||
              filtriMenu.text == 'Maniglie' ||
              filtriMenu.text == 'Maniglie' ||
              filtriMenu.text == '') &&
            dettaglio.nomeProdotto != 'Sion Ribassata' &&
            dettaglio.nomeProdotto != 'Berna Ribassata'
          "
          :bench="benched"
          :items="getDati"
          item-height="170"
          height="720"
          style="width: 15em"
        >
          <v-col style="margin-top: -174em; background-color: white" cols="12">
            <v-row style="margin-top: 0em; margin-left: 1em">
              <v-col v-for="(dettaglio, i) in getDati" :key="i">
                <v-card
                  style="width: auto; height: auto; padding: 0px; margin-top: -1.5em"
                  class="elevation-0 mb-0"
                >
                  <v-img
                    v-on:click="getDettComposizione(dettaglio)"
                    style="width: 180px; height: 200px; margin-top: 0px; cursor: pointer"
                    :src="dettaglio.url"
                    :lazy-src="dettaglio.url"
                    aspect-ratio="1"
                    class="white lighten-2 mb-5"
                  >
                    <template v-slot:placeholder>
                      <v-row class="fill-height ma-0" align="center" justify="center">
                        <v-progress-circular
                          indeterminate
                          color="black lighten-5"
                        ></v-progress-circular>
                      </v-row>
                    </template>
                  </v-img>
                  <span
                    style="font-size: 12px"
                    v-if="
                      dettaglio.nomeComposizione != '' &&
                      dettaglio.nomeComposizione != 'Cremonese T'
                    "
                    >{{ dettaglio.nomeComposizione }}
                    <v-divider
                      v-if="
                        dettaglio.nomeComposizione != '' &&
                        dettaglio.nomeComposizione != 'Cremonese T'
                      "
                    ></v-divider>
                  </span>
                </v-card>
              </v-col>
            </v-row>
          </v-col>
        </v-virtual-scroll>

        <!-- SCROLLABAR PER SION RIBASSATA E BERNA RIBASSATA -->
        <v-virtual-scroll
          v-if="
            composizioneNome != 'Retrò' &&
            (extantionNome == '1_Maniglie' ||
              extantionNome == 'Maniglie' ||
              extantionNome == 'Tutti i Modelli') &&
            (filtriMenu.text == 'Tutti i Modelli' ||
              filtriMenu.text == 'Maniglie' ||
              filtriMenu.text == '') &&
            (dettaglio.nomeProdotto == 'Sion Ribassata' ||
              dettaglio.nomeProdotto == 'Berna Ribassata')
          "
          :bench="benched"
          :items="getDati"
          item-height="170"
          height="720"
          style="width: 15em"
        >
          <v-col style="margin-top: -100em; background-color: white" cols="12">
            <v-row style="margin-top: 0em; margin-left: 1em">
              <v-col v-for="(dettaglio, i) in getDati" :key="i">
                <v-card
                  style="width: auto; height: auto; padding: 0px; margin-top: -1.5em"
                  class="elevation-0 mb-0"
                >
                  <v-img
                    v-on:click="getDettComposizione(dettaglio)"
                    style="width: 180px; height: 200px; margin-top: 0px; cursor: pointer"
                    :src="dettaglio.url"
                    :lazy-src="dettaglio.url"
                    aspect-ratio="1"
                    class="white lighten-2 mb-5"
                  >
                    <template v-slot:placeholder>
                      <v-row class="fill-height ma-0" align="center" justify="center">
                        <v-progress-circular
                          indeterminate
                          color="black lighten-5"
                        ></v-progress-circular>
                      </v-row>
                    </template>
                  </v-img>
                  <span
                    style="font-size: 12px"
                    v-if="
                      dettaglio.nomeComposizione != '' &&
                      dettaglio.nomeComposizione != 'Cremonese T'
                    "
                    >{{ dettaglio.nomeComposizione }}
                    <v-divider
                      v-if="
                        dettaglio.nomeComposizione != '' &&
                        dettaglio.nomeComposizione != 'Cremonese T'
                      "
                    ></v-divider>
                  </span>
                </v-card>
              </v-col>
            </v-row>
          </v-col>
        </v-virtual-scroll>

        <!-- SCROLLBAR PER RETRO' (MANIGLIE) -->
        <v-virtual-scroll
          v-if="
            composizioneNome == 'Retrò' &&
            (extantionNome == '1_Maniglie' ||
              extantionNome == 'Maniglie' ||
              extantionNome == 'Tutti i Modelli') &&
            (filtriMenu.text == 'Tutti i Modelli' ||
              filtriMenu.text == 'Maniglie' ||
              filtriMenu.text == 'Maniglie' ||
              filtriMenu.text == '')
          "
          :bench="benched"
          :items="getDati"
          item-height="170"
          height="720"
          style="width: 15em"
        >
          <v-col style="margin-top: -164em; background-color: white" cols="12">
            <v-row style="margin-top: 0em; margin-left: 1em">
              <v-col v-for="(dettaglio, i) in getDati" :key="i">
                <v-card
                  style="width: auto; height: auto; padding: 0px; margin-top: -1.5em"
                  class="elevation-0 mb-0"
                >
                  <v-img
                    v-on:click="getDettComposizione(dettaglio)"
                    style="width: 180px; height: 200px; margin-top: 0px; cursor: pointer"
                    :src="dettaglio.url"
                    :lazy-src="dettaglio.url"
                    aspect-ratio="1"
                    class="white lighten-2 mb-5"
                  >
                    <template v-slot:placeholder>
                      <v-row class="fill-height ma-0" align="center" justify="center">
                        <v-progress-circular
                          indeterminate
                          color="black lighten-5"
                        ></v-progress-circular>
                      </v-row>
                    </template>
                  </v-img>
                  <span
                    style="font-size: 12px"
                    v-if="
                      dettaglio.nomeComposizione != '' &&
                      dettaglio.nomeComposizione != 'Cremonese T'
                    "
                    >{{ dettaglio.nomeComposizione }}
                    <v-divider
                      v-if="
                        dettaglio.nomeComposizione != '' &&
                        dettaglio.nomeComposizione != 'Cremonese T'
                      "
                    ></v-divider>
                  </span>
                </v-card>
              </v-col>
            </v-row>
          </v-col>
        </v-virtual-scroll>

        <!-- SCROLLBAR PER QUADRO E TONDO (POMOLI) -->
        <v-virtual-scroll
          v-if="
            composizioneNome != 'Retrò' &&
            (extantionNome == '2_Pomoli' ||
              extantionNome == 'Pomoli' ||
              extantionNome == 'Tutti i Modelli') &&
            (filtriMenu.text == 'Tutti i Modelli' ||
              filtriMenu.text == 'Pomoli' ||
              filtriMenu.text == '')
          "
          :bench="benched"
          :items="getDati"
          item-height="150"
          height="720"
          style="width: 15em"
        >
          <v-col style="margin-top: -145em; background-color: white" cols="12">
            <v-row style="margin-top: 0em; margin-left: 1em">
              <v-col v-for="(dettaglio, i) in getDati" :key="i">
                <v-card
                  style="width: auto; height: auto; padding: 0px; margin-top: -1.5em"
                  class="elevation-0 mb-0"
                >
                  <v-img
                    v-on:click="getDettComposizione(dettaglio)"
                    style="width: 180px; height: 200px; margin-top: 0px; cursor: pointer"
                    :src="dettaglio.url"
                    :lazy-src="dettaglio.url"
                    aspect-ratio="1"
                    class="white lighten-2 mb-5"
                  >
                    <template v-slot:placeholder>
                      <v-row class="fill-height ma-0" align="center" justify="center">
                        <v-progress-circular
                          indeterminate
                          color="black lighten-5"
                        ></v-progress-circular>
                      </v-row>
                    </template>
                  </v-img>
                  <span
                    style="font-size: 12px"
                    v-if="
                      dettaglio.nomeComposizione != '' &&
                      dettaglio.nomeComposizione != 'Cremonese T'
                    "
                    >{{ dettaglio.nomeComposizione }}
                    <v-divider
                      v-if="
                        dettaglio.nomeComposizione != '' &&
                        dettaglio.nomeComposizione != 'Cremonese T'
                      "
                    ></v-divider>
                  </span>
                </v-card>
              </v-col>
            </v-row>
          </v-col>
        </v-virtual-scroll>

        <!-- SCROLLBAR PER RETRO' (POMOLI) -->
        <v-virtual-scroll
          v-if="
            composizioneNome == 'Retrò' &&
            (extantionNome == '2_Pomoli' ||
              extantionNome == 'Pomoli' ||
              extantionNome == 'Tutti i Modelli') &&
            (filtriMenu.text == 'Tutti i Modelli' ||
              filtriMenu.text == 'Pomoli' ||
              filtriMenu.text == '')
          "
          :bench="benched"
          :items="getDati"
          item-height="119"
          height="720"
          style="width: 15em"
        >
          <v-col style="margin-top: -71em; background-color: white" cols="12">
            <v-row style="margin-top: 0em; margin-left: 1em">
              <v-col v-for="(dettaglio, i) in getDati" :key="i">
                <v-card
                  style="width: auto; height: auto; padding: 0px; margin-top: -1.5em"
                  class="elevation-0 mb-0"
                >
                  <v-img
                    v-on:click="getDettComposizione(dettaglio)"
                    style="width: 180px; height: 200px; margin-top: 0px; cursor: pointer"
                    :src="dettaglio.url"
                    :lazy-src="dettaglio.url"
                    aspect-ratio="1"
                    class="white lighten-2 mb-5"
                  >
                    <template v-slot:placeholder>
                      <v-row class="fill-height ma-0" align="center" justify="center">
                        <v-progress-circular
                          indeterminate
                          color="black lighten-5"
                        ></v-progress-circular>
                      </v-row>
                    </template>
                  </v-img>
                  <span
                    style="font-size: 12px"
                    v-if="
                      dettaglio.nomeComposizione != '' &&
                      dettaglio.nomeComposizione != 'Cremonese T'
                    "
                    >{{ dettaglio.nomeComposizione }}
                    <v-divider
                      v-if="
                        dettaglio.nomeComposizione != '' &&
                        dettaglio.nomeComposizione != 'Cremonese T'
                      "
                    ></v-divider>
                  </span>
                </v-card>
              </v-col>
            </v-row>
          </v-col>
        </v-virtual-scroll>

        <!-- SCROLLBAR PER (MANIGLIONI) -->
        <!-- AYR -->
        <v-virtual-scroll
          v-if="
            composizioneNome != 'Retrò' &&
            (extantionNome == '3_Maniglioni' ||
              extantionNome == 'Maniglioni' ||
              extantionNome == 'Tutti i Modelli') &&
            (filtriMenu.text == 'Tutti i Modelli' ||
              filtriMenu.text == 'Maniglioni' ||
              filtriMenu.text == '') &&
            dettaglio.nomeProdotto == 'Ayr'
          "
          :bench="benched"
          :items="getDati"
          item-height="210"
          height="720"
          style="width: 15em"
        >
          <v-col style="margin-top: -73em; background-color: white" cols="12">
            <v-row style="margin-top: 0em; margin-left: 1em">
              <v-col v-for="(dettaglio, i) in getDati" :key="i">
                <v-card
                  style="width: auto; height: auto; padding: 0px; margin-top: -1.5em"
                  class="elevation-0 mb-0"
                >
                  <v-img
                    v-on:click="getDettComposizione(dettaglio)"
                    style="width: 150px; height: 500px; margin-top: 10px; cursor: pointer"
                    :src="dettaglio.url"
                    :lazy-src="dettaglio.url"
                    aspect-ratio="1"
                    class="white lighten-2 mb-5"
                  >
                    <template v-slot:placeholder>
                      <v-row class="fill-height ma-0" align="center" justify="center">
                        <v-progress-circular
                          indeterminate
                          color="black lighten-5"
                        ></v-progress-circular>
                      </v-row>
                    </template>
                  </v-img>
                  <span
                    style="font-size: 12px"
                    v-if="
                      dettaglio.nomeComposizione != '' &&
                      dettaglio.nomeComposizione != 'Cremonese T'
                    "
                    >{{ dettaglio.nomeComposizione }}
                    <v-divider
                      v-if="
                        dettaglio.nomeComposizione != '' &&
                        dettaglio.nomeComposizione != 'Cremonese T'
                      "
                    ></v-divider>
                  </span>
                </v-card>
              </v-col>
            </v-row>
          </v-col>
        </v-virtual-scroll>

        <!-- BELFAST -->
        <v-virtual-scroll
          v-if="
            composizioneNome != 'Retrò' &&
            (extantionNome == '3_Maniglioni' ||
              extantionNome == 'Maniglioni' ||
              extantionNome == 'Tutti i Modelli') &&
            (filtriMenu.text == 'Tutti i Modelli' ||
              filtriMenu.text == 'Maniglioni' ||
              filtriMenu.text == '') &&
            dettaglio.nomeProdotto == 'Belfast'
          "
          :bench="benched"
          :items="getDati"
          item-height="210"
          height="720"
          style="width: 15em"
        >
          <v-col style="margin-top: -45em; background-color: white" cols="12">
            <v-row style="margin-top: 0em; margin-left: 1em">
              <v-col v-for="(dettaglio, i) in getDati" :key="i">
                <v-card
                  style="width: auto; height: auto; padding: 0px; margin-top: -1.5em"
                  class="elevation-0 mb-0"
                >
                  <v-img
                    v-on:click="getDettComposizione(dettaglio)"
                    style="width: 150px; height: 500px; margin-top: 10px; cursor: pointer"
                    :src="dettaglio.url"
                    :lazy-src="dettaglio.url"
                    aspect-ratio="1"
                    class="white lighten-2 mb-5"
                  >
                    <template v-slot:placeholder>
                      <v-row class="fill-height ma-0" align="center" justify="center">
                        <v-progress-circular
                          indeterminate
                          color="black lighten-5"
                        ></v-progress-circular>
                      </v-row>
                    </template>
                  </v-img>
                  <span
                    style="font-size: 12px"
                    v-if="
                      dettaglio.nomeComposizione != '' &&
                      dettaglio.nomeComposizione != 'Cremonese T'
                    "
                    >{{ dettaglio.nomeComposizione }}
                    <v-divider
                      v-if="
                        dettaglio.nomeComposizione != '' &&
                        dettaglio.nomeComposizione != 'Cremonese T'
                      "
                    ></v-divider>
                  </span>
                </v-card>
              </v-col>
            </v-row>
          </v-col>
        </v-virtual-scroll>

        <!-- BORDEAUX -->
        <v-virtual-scroll
          v-if="
            composizioneNome != 'Retrò' &&
            (extantionNome == '3_Maniglioni' ||
              extantionNome == 'Maniglioni' ||
              extantionNome == 'Tutti i Modelli') &&
            (filtriMenu.text == 'Tutti i Modelli' ||
              filtriMenu.text == 'Maniglioni' ||
              filtriMenu.text == '') &&
            dettaglio.nomeProdotto == 'Bordeaux'
          "
          :bench="benched"
          :items="getDati"
          item-height="210"
          height="720"
          style="width: 15em"
        >
          <v-col style="margin-top: -30em; background-color: white" cols="12">
            <v-row style="margin-top: 0em; margin-left: 1em">
              <v-col v-for="(dettaglio, i) in getDati" :key="i">
                <v-card
                  style="width: auto; height: auto; padding: 0px; margin-top: -1.5em"
                  class="elevation-0 mb-0"
                >
                  <v-img
                    v-on:click="getDettComposizione(dettaglio)"
                    style="width: 350px; height: 500px; margin-top: 10px; cursor: pointer"
                    :src="dettaglio.url"
                    :lazy-src="dettaglio.url"
                    aspect-ratio="1"
                    class="white lighten-2 mb-5"
                  >
                    <template v-slot:placeholder>
                      <v-row class="fill-height ma-0" align="center" justify="center">
                        <v-progress-circular
                          indeterminate
                          color="black lighten-5"
                        ></v-progress-circular>
                      </v-row>
                    </template>
                  </v-img>
                  <span
                    style="font-size: 12px"
                    v-if="dettaglio.nomeComposizione != '02000'"
                    >{{ dettaglio.nomeComposizione }}
                    <v-divider v-if="dettaglio.nomeComposizione != '02000'"></v-divider>
                  </span>
                </v-card>
              </v-col>
            </v-row>
          </v-col>
        </v-virtual-scroll>

        <!-- BRERA -->
        <v-virtual-scroll
          v-if="
            composizioneNome != 'Retrò' &&
            (extantionNome == '3_Maniglioni' ||
              extantionNome == 'Maniglioni' ||
              extantionNome == 'Tutti i Modelli') &&
            (filtriMenu.text == 'Tutti i Modelli' ||
              filtriMenu.text == 'Maniglioni' ||
              filtriMenu.text == '') &&
            dettaglio.nomeProdotto == 'Brera'
          "
          :bench="benched"
          :items="getDati"
          item-height="210"
          height="720"
          style="width: 15em"
        >
          <v-col style="margin-top: -30em; background-color: white" cols="12">
            <v-row style="margin-top: 0em; margin-left: 1em">
              <v-col v-for="(dettaglio, i) in getDati" :key="i">
                <v-card
                  style="width: auto; height: auto; padding: 0px; margin-top: -1.5em"
                  class="elevation-0 mb-0"
                >
                  <v-img
                    v-on:click="getDettComposizione(dettaglio)"
                    style="width: 350px; height: 500px; margin-top: 10px; cursor: pointer"
                    :src="dettaglio.url"
                    :lazy-src="dettaglio.url"
                    aspect-ratio="1"
                    class="white lighten-2 mb-5"
                  >
                    <template v-slot:placeholder>
                      <v-row class="fill-height ma-0" align="center" justify="center">
                        <v-progress-circular
                          indeterminate
                          color="black lighten-5"
                        ></v-progress-circular>
                      </v-row>
                    </template>
                  </v-img>
                  <span
                    style="font-size: 12px"
                    v-if="dettaglio.nomeComposizione != '03000'"
                    >{{ dettaglio.nomeComposizione }}
                    <v-divider v-if="dettaglio.nomeComposizione != '03000'"></v-divider>
                  </span>
                </v-card>
              </v-col>
            </v-row>
          </v-col>
        </v-virtual-scroll>

        <!-- BRERA Q -->
        <v-virtual-scroll
          v-if="
            composizioneNome != 'Retrò' &&
            (extantionNome == '3_Maniglioni' ||
              extantionNome == 'Maniglioni' ||
              extantionNome == 'Tutti i Modelli') &&
            (filtriMenu.text == 'Tutti i Modelli' ||
              filtriMenu.text == 'Maniglioni' ||
              filtriMenu.text == '') &&
            dettaglio.nomeProdotto == 'Brera Q'
          "
          :bench="benched"
          :items="getDati"
          item-height="210"
          height="720"
          style="width: 15em"
        >
          <v-col style="margin-top: -30em; background-color: white" cols="12">
            <v-row style="margin-top: 0em; margin-left: 1em">
              <v-col v-for="(dettaglio, i) in getDati" :key="i">
                <v-card
                  style="width: auto; height: auto; padding: 0px; margin-top: -1.5em"
                  class="elevation-0 mb-0"
                >
                  <v-img
                    v-on:click="getDettComposizione(dettaglio)"
                    style="width: 350px; height: 500px; margin-top: 10px; cursor: pointer"
                    :src="dettaglio.url"
                    :lazy-src="dettaglio.url"
                    aspect-ratio="1"
                    class="white lighten-2 mb-5"
                  >
                    <template v-slot:placeholder>
                      <v-row class="fill-height ma-0" align="center" justify="center">
                        <v-progress-circular
                          indeterminate
                          color="black lighten-5"
                        ></v-progress-circular>
                      </v-row>
                    </template>
                  </v-img>
                  <span
                    style="font-size: 12px"
                    v-if="dettaglio.nomeComposizione != '03000'"
                    >{{ dettaglio.nomeComposizione }}
                    <v-divider v-if="dettaglio.nomeComposizione != '03000'"></v-divider>
                  </span>
                </v-card>
              </v-col>
            </v-row>
          </v-col>
        </v-virtual-scroll>

        <!-- BRISTOL -->
        <v-virtual-scroll
          v-if="
            composizioneNome != 'Retrò' &&
            (extantionNome == '3_Maniglioni' ||
              extantionNome == 'Maniglioni' ||
              extantionNome == 'Tutti i Modelli') &&
            (filtriMenu.text == 'Tutti i Modelli' ||
              filtriMenu.text == 'Maniglioni' ||
              filtriMenu.text == '') &&
            dettaglio.nomeProdotto == 'Bristol'
          "
          :bench="benched"
          :items="getDati"
          item-height="150"
          height="720"
          style="width: 15em"
        >
          <v-col style="margin-top: -15em; background-color: white" cols="12">
            <v-row style="margin-top: 0em; margin-left: 1em">
              <v-col v-for="(dettaglio, i) in getDati" :key="i">
                <v-card
                  style="width: auto; height: auto; padding: 0px; margin-top: -1.5em"
                  class="elevation-0 mb-0"
                >
                  <v-img
                    v-on:click="getDettComposizione(dettaglio)"
                    style="width: 350px; height: 550px; margin-top: 10px; cursor: pointer"
                    :src="dettaglio.url"
                    :lazy-src="dettaglio.url"
                    aspect-ratio="1"
                    class="white lighten-2 mb-5"
                  >
                    <template v-slot:placeholder>
                      <v-row class="fill-height ma-0" align="center" justify="center">
                        <v-progress-circular
                          indeterminate
                          color="black lighten-5"
                        ></v-progress-circular>
                      </v-row>
                    </template>
                  </v-img>
                  <span
                    style="font-size: 12px"
                    v-if="
                      dettaglio.nomeComposizione != '' &&
                      dettaglio.nomeComposizione != 'Cremonese T'
                    "
                    >{{ dettaglio.nomeComposizione }}
                    <v-divider
                      v-if="
                        dettaglio.nomeComposizione != '' &&
                        dettaglio.nomeComposizione != 'Cremonese T'
                      "
                    ></v-divider>
                  </span>
                </v-card>
              </v-col>
            </v-row>
          </v-col>
        </v-virtual-scroll>

        <!-- DERBY -->
        <v-virtual-scroll
          v-if="
            composizioneNome != 'Retrò' &&
            (extantionNome == '3_Maniglioni' ||
              extantionNome == 'Maniglioni' ||
              extantionNome == 'Tutti i Modelli') &&
            (filtriMenu.text == 'Tutti i Modelli' ||
              filtriMenu.text == 'Maniglioni' ||
              filtriMenu.text == '') &&
            dettaglio.nomeProdotto == 'Derby'
          "
          :bench="benched"
          :items="getDati"
          item-height="150"
          height="720"
          style="width: 15em"
        >
          <v-col style="margin-top: -15em; background-color: white" cols="12">
            <v-row style="margin-top: 0em; margin-left: 1em">
              <v-col v-for="(dettaglio, i) in getDati" :key="i">
                <v-card
                  style="width: auto; height: auto; padding: 0px; margin-top: -1.5em"
                  class="elevation-0 mb-0"
                >
                  <v-img
                    v-on:click="getDettComposizione(dettaglio)"
                    style="width: 350px; height: 550px; margin-top: 10px; cursor: pointer"
                    :src="dettaglio.url"
                    :lazy-src="dettaglio.url"
                    aspect-ratio="1"
                    class="white lighten-2 mb-5"
                  >
                    <template v-slot:placeholder>
                      <v-row class="fill-height ma-0" align="center" justify="center">
                        <v-progress-circular
                          indeterminate
                          color="black lighten-5"
                        ></v-progress-circular>
                      </v-row>
                    </template>
                  </v-img>
                  <span
                    style="font-size: 12px"
                    v-if="
                      dettaglio.nomeComposizione != '' &&
                      dettaglio.nomeComposizione != 'Cremonese T'
                    "
                    >{{ dettaglio.nomeComposizione }}
                    <v-divider
                      v-if="
                        dettaglio.nomeComposizione != '' &&
                        dettaglio.nomeComposizione != 'Cremonese T'
                      "
                    ></v-divider>
                  </span>
                </v-card>
              </v-col>
            </v-row>
          </v-col>
        </v-virtual-scroll>

        <!-- DOVER -->
        <v-virtual-scroll
          v-if="
            composizioneNome != 'Retrò' &&
            (extantionNome == '3_Maniglioni' ||
              extantionNome == 'Maniglioni' ||
              extantionNome == 'Tutti i Modelli') &&
            (filtriMenu.text == 'Tutti i Modelli' ||
              filtriMenu.text == 'Maniglioni' ||
              filtriMenu.text == '') &&
            dettaglio.nomeProdotto == 'Dover'
          "
          :bench="benched"
          :items="getDati"
          item-height="150"
          height="720"
          style="width: 15em"
        >
          <v-col style="margin-top: -30em; background-color: white" cols="12">
            <v-row style="margin-top: 0em; margin-left: 1em">
              <v-col v-for="(dettaglio, i) in getDati" :key="i">
                <v-card
                  style="width: auto; height: auto; padding: 0px; margin-top: -1.5em"
                  class="elevation-0 mb-0"
                >
                  <v-img
                    v-on:click="getDettComposizione(dettaglio)"
                    style="width: 150px; height: 500px; margin-top: 10px; cursor: pointer"
                    :src="dettaglio.url"
                    :lazy-src="dettaglio.url"
                    aspect-ratio="1"
                    class="white lighten-2 mb-5"
                  >
                    <template v-slot:placeholder>
                      <v-row class="fill-height ma-0" align="center" justify="center">
                        <v-progress-circular
                          indeterminate
                          color="black lighten-5"
                        ></v-progress-circular>
                      </v-row>
                    </template>
                  </v-img>
                  <span
                    style="font-size: 12px"
                    v-if="
                      dettaglio.nomeComposizione != '' &&
                      dettaglio.nomeComposizione != 'Cremonese T'
                    "
                    >{{ dettaglio.nomeComposizione }}
                    <v-divider
                      v-if="
                        dettaglio.nomeComposizione != '' &&
                        dettaglio.nomeComposizione != 'Cremonese T'
                      "
                    ></v-divider>
                  </span>
                </v-card>
              </v-col>
            </v-row>
          </v-col>
        </v-virtual-scroll>

        <!-- GLASGOW -->
        <v-virtual-scroll
          v-if="
            composizioneNome != 'Retrò' &&
            (extantionNome == '3_Maniglioni' ||
              extantionNome == 'Maniglioni' ||
              extantionNome == 'Tutti i Modelli') &&
            (filtriMenu.text == 'Tutti i Modelli' ||
              filtriMenu.text == 'Maniglioni' ||
              filtriMenu.text == '') &&
            dettaglio.nomeProdotto == 'Glasgow'
          "
          :bench="benched"
          :items="getDati"
          item-height="150"
          height="720"
          style="width: 15em"
        >
          <v-col style="margin-top: -15em; background-color: white" cols="12">
            <v-row style="margin-top: 0em; margin-left: 1em">
              <v-col v-for="(dettaglio, i) in getDati" :key="i">
                <v-card
                  style="width: auto; height: auto; padding: 0px; margin-top: 0em"
                  class="elevation-0 mb-0"
                >
                  <v-img
                    v-on:click="getDettComposizione(dettaglio)"
                    style="width: 350px; height: 550px; margin-top: 10px; cursor: pointer"
                    :src="dettaglio.url"
                    :lazy-src="dettaglio.url"
                    aspect-ratio="1"
                    class="white lighten-2 mb-5"
                  >
                    <template v-slot:placeholder>
                      <v-row class="fill-height ma-0" align="center" justify="center">
                        <v-progress-circular
                          indeterminate
                          color="black lighten-5"
                        ></v-progress-circular>
                      </v-row>
                    </template>
                  </v-img>
                  <span
                    style="font-size: 12px"
                    v-if="dettaglio.nomeComposizione != '0200'"
                    >{{ dettaglio.nomeComposizione }}
                    <v-divider v-if="dettaglio.nomeComposizione != '0200'"></v-divider>
                  </span>
                </v-card>
              </v-col>
            </v-row>
          </v-col>
        </v-virtual-scroll>

        <!-- KIEL -->
        <v-virtual-scroll
          v-if="
            composizioneNome != 'Retrò' &&
            (extantionNome == '3_Maniglioni' ||
              extantionNome == 'Maniglioni' ||
              extantionNome == 'Tutti i Modelli') &&
            (filtriMenu.text == 'Tutti i Modelli' ||
              filtriMenu.text == 'Maniglioni' ||
              filtriMenu.text == '') &&
            dettaglio.nomeProdotto == 'Kiel'
          "
          :bench="benched"
          :items="getDati"
          item-height="210"
          height="720"
          style="width: 15em"
        >
          <v-col style="margin-top: -73em; background-color: white" cols="12">
            <v-row style="margin-top: 0em; margin-left: 1em">
              <v-col v-for="(dettaglio, i) in getDati" :key="i">
                <v-card
                  style="width: auto; height: auto; padding: 0px; margin-top: -1.5em"
                  class="elevation-0 mb-0"
                >
                  <v-img
                    v-on:click="getDettComposizione(dettaglio)"
                    style="width: 150px; height: 500px; margin-top: 10px; cursor: pointer"
                    :src="dettaglio.url"
                    :lazy-src="dettaglio.url"
                    aspect-ratio="1"
                    class="white lighten-2 mb-5"
                  >
                    <template v-slot:placeholder>
                      <v-row class="fill-height ma-0" align="center" justify="center">
                        <v-progress-circular
                          indeterminate
                          color="black lighten-5"
                        ></v-progress-circular>
                      </v-row>
                    </template>
                  </v-img>
                  <span
                    style="font-size: 12px"
                    v-if="
                      dettaglio.nomeComposizione != '' &&
                      dettaglio.nomeComposizione != 'Cremonese T'
                    "
                    >{{ dettaglio.nomeComposizione }}
                    <v-divider
                      v-if="
                        dettaglio.nomeComposizione != '' &&
                        dettaglio.nomeComposizione != 'Cremonese T'
                      "
                    ></v-divider>
                  </span>
                </v-card>
              </v-col>
            </v-row>
          </v-col>
        </v-virtual-scroll>

        <!-- LAHTI -->
        <v-virtual-scroll
          v-if="
            composizioneNome != 'Retrò' &&
            (extantionNome == '3_Maniglioni' ||
              extantionNome == 'Maniglioni' ||
              extantionNome == 'Tutti i Modelli') &&
            (filtriMenu.text == 'Tutti i Modelli' ||
              filtriMenu.text == 'Maniglioni' ||
              filtriMenu.text == '') &&
            dettaglio.nomeProdotto == 'Lahti'
          "
          :bench="benched"
          :items="getDati"
          item-height="210"
          height="720"
          style="width: 15em"
        >
          <v-col style="margin-top: -45em; background-color: white" cols="12">
            <v-row style="margin-top: 0em; margin-left: 1em">
              <v-col v-for="(dettaglio, i) in getDati" :key="i">
                <v-card
                  style="width: auto; height: auto; padding: 0px; margin-top: -1.5em"
                  class="elevation-0 mb-0"
                >
                  <v-img
                    v-on:click="getDettComposizione(dettaglio)"
                    style="width: 150px; height: 500px; margin-top: 10px; cursor: pointer"
                    :src="dettaglio.url"
                    :lazy-src="dettaglio.url"
                    aspect-ratio="1"
                    class="white lighten-2 mb-5"
                  >
                    <template v-slot:placeholder>
                      <v-row class="fill-height ma-0" align="center" justify="center">
                        <v-progress-circular
                          indeterminate
                          color="black lighten-5"
                        ></v-progress-circular>
                      </v-row>
                    </template>
                  </v-img>
                  <span
                    style="font-size: 12px"
                    v-if="
                      dettaglio.nomeComposizione != '' &&
                      dettaglio.nomeComposizione != 'Cremonese T'
                    "
                    >{{ dettaglio.nomeComposizione }}
                    <v-divider
                      v-if="
                        dettaglio.nomeComposizione != '' &&
                        dettaglio.nomeComposizione != 'Cremonese T'
                      "
                    ></v-divider>
                  </span>
                </v-card>
              </v-col>
            </v-row>
          </v-col>
        </v-virtual-scroll>

        <!-- TOLEDO -->
        <v-virtual-scroll
          v-if="
            composizioneNome != 'Retrò' &&
            (extantionNome == '3_Maniglioni' ||
              extantionNome == 'Maniglioni' ||
              extantionNome == 'Tutti i Modelli') &&
            (filtriMenu.text == 'Tutti i Modelli' ||
              filtriMenu.text == 'Maniglioni' ||
              filtriMenu.text == '') &&
            dettaglio.nomeProdotto == 'Toledo'
          "
          :bench="benched"
          :items="getDati"
          item-height="150"
          height="720"
          style="width: 15em"
        >
          <v-col style="margin-top: -15em; background-color: white" cols="12">
            <v-row style="margin-top: 0em; margin-left: 1em">
              <v-col v-for="(dettaglio, i) in getDati" :key="i">
                <v-card
                  style="width: auto; height: auto; padding: 0px; margin-top: -1.5em"
                  class="elevation-0 mb-0"
                >
                  <v-img
                    v-on:click="getDettComposizione(dettaglio)"
                    style="width: 350px; height: 550px; margin-top: 10px; cursor: pointer"
                    :src="dettaglio.url"
                    :lazy-src="dettaglio.url"
                    aspect-ratio="1"
                    class="white lighten-2 mb-5"
                  >
                    <template v-slot:placeholder>
                      <v-row class="fill-height ma-0" align="center" justify="center">
                        <v-progress-circular
                          indeterminate
                          color="black lighten-5"
                        ></v-progress-circular>
                      </v-row>
                    </template>
                  </v-img>
                  <span
                    style="font-size: 12px"
                    v-if="
                      dettaglio.nomeComposizione != '' &&
                      dettaglio.nomeComposizione != 'Cremonese T'
                    "
                    >{{ dettaglio.nomeComposizione }}
                    <v-divider
                      v-if="
                        dettaglio.nomeComposizione != '' &&
                        dettaglio.nomeComposizione != 'Cremonese T'
                      "
                    ></v-divider>
                  </span>
                </v-card>
              </v-col>
            </v-row>
          </v-col>
        </v-virtual-scroll>

        <!-- GINEVRA -->
        <v-virtual-scroll
          v-if="
            composizioneNome == 'Retrò' &&
            (extantionNome == '3_Maniglioni' ||
              extantionNome == 'Maniglioni' ||
              extantionNome == 'Tutti i Modelli') &&
            (filtriMenu.text == 'Tutti i Modelli' ||
              filtriMenu.text == 'Maniglioni' ||
              filtriMenu.text == '') &&
            dettaglio.nomeProdotto == 'Ginevra'
          "
          :bench="benched"
          :items="getDati"
          item-height="150"
          height="720"
          style="width: 15em"
        >
          <v-col style="margin-top: -15em; background-color: white" cols="12">
            <v-row style="margin-top: 0em; margin-left: 1em">
              <v-col v-for="(dettaglio, i) in getDati" :key="i">
                <v-card
                  style="width: auto; height: auto; padding: 0px; margin-top: 0em"
                  class="elevation-0 mb-0"
                >
                  <v-img
                    v-on:click="getDettComposizione(dettaglio)"
                    style="width: 350px; height: 550px; margin-top: 10px; cursor: pointer"
                    :src="dettaglio.url"
                    :lazy-src="dettaglio.url"
                    aspect-ratio="1"
                    class="white lighten-2 mb-5"
                  >
                    <template v-slot:placeholder>
                      <v-row class="fill-height ma-0" align="center" justify="center">
                        <v-progress-circular
                          indeterminate
                          color="black lighten-5"
                        ></v-progress-circular>
                      </v-row>
                    </template>
                  </v-img>
                  <span
                    style="font-size: 12px"
                    v-if="dettaglio.nomeComposizione != '02000'"
                    >{{ dettaglio.nomeComposizione }}
                    <v-divider v-if="dettaglio.nomeComposizione != '02000'"></v-divider>
                  </span>
                </v-card>
              </v-col>
            </v-row>
          </v-col>
        </v-virtual-scroll>

        <!-- GINEVRA ZANCATO -->
        <v-virtual-scroll
          v-if="
            composizioneNome == 'Retrò' &&
            (extantionNome == '3_Maniglioni' ||
              extantionNome == 'Maniglioni' ||
              extantionNome == 'Tutti i Modelli') &&
            (filtriMenu.text == 'Tutti i Modelli' ||
              filtriMenu.text == 'Maniglioni' ||
              filtriMenu.text == '') &&
            dettaglio.nomeProdotto == 'Ginevra Zancato'
          "
          :bench="benched"
          :items="getDati"
          item-height="150"
          height="720"
          style="width: 15em"
        >
          <v-col style="margin-top: -15em; background-color: white" cols="12">
            <v-row style="margin-top: 0em; margin-left: 1em">
              <v-col v-for="(dettaglio, i) in getDati" :key="i">
                <v-card
                  style="width: auto; height: auto; padding: 0px; margin-top: 0em"
                  class="elevation-0 mb-0"
                >
                  <v-img
                    v-on:click="getDettComposizione(dettaglio)"
                    style="width: 350px; height: 550px; margin-top: 10px; cursor: pointer"
                    :src="dettaglio.url"
                    :lazy-src="dettaglio.url"
                    aspect-ratio="1"
                    class="white lighten-2 mb-5"
                  >
                    <template v-slot:placeholder>
                      <v-row class="fill-height ma-0" align="center" justify="center">
                        <v-progress-circular
                          indeterminate
                          color="black lighten-5"
                        ></v-progress-circular>
                      </v-row>
                    </template>
                  </v-img>
                  <span
                    style="font-size: 12px"
                    v-if="dettaglio.nomeComposizione != '02000'"
                    >{{ dettaglio.nomeComposizione }}
                    <v-divider v-if="dettaglio.nomeComposizione != '02000'"></v-divider>
                  </span>
                </v-card>
              </v-col>
            </v-row>
          </v-col>
        </v-virtual-scroll>

        <!-- SCROLLBAR PER (ALZANTI) -->
        <!-- BRERA -->
        <v-virtual-scroll
          v-if="
            composizioneNome != 'Retrò' &&
            (extantionNome == '4_Alzanti' ||
              extantionNome == 'Alzanti' ||
              extantionNome == 'Tutti i Modelli') &&
            (filtriMenu.text == 'Tutti i Modelli' ||
              filtriMenu.text == 'Alzanti' ||
              filtriMenu.text == '') &&
            dettaglio.nomeProdotto == 'Brera'
          "
          :bench="benched"
          :items="getDati"
          item-height="80"
          height="720"
          style="width: 15em"
        >
          <v-col style="margin-top: 0em; background-color: white" cols="12">
            <v-row style="margin-top: 0em; margin-left: 1em">
              <v-col v-for="(dettaglio, i) in getDati" :key="i">
                <v-card
                  style="width: auto; height: auto; padding: 0px; margin-top: -1.5em"
                  class="elevation-0 mb-0"
                >
                  <v-img
                    v-on:click="getDettComposizione(dettaglio)"
                    style="
                      width: 150px;
                      height: 300px;
                      margin-top: 10px;
                      cursor: pointer;
                      margin-left: 3em;
                    "
                    :src="dettaglio.url"
                    :lazy-src="dettaglio.url"
                    aspect-ratio="1"
                    class="white lighten-2 mb-5"
                  >
                    <template v-slot:placeholder>
                      <v-row class="fill-height ma-0" align="center" justify="center">
                        <v-progress-circular
                          indeterminate
                          color="black lighten-5"
                        ></v-progress-circular>
                      </v-row>
                    </template>
                  </v-img>
                  <span
                    style="font-size: 12px"
                    v-if="dettaglio.nomeComposizione != '02000'"
                    >{{ dettaglio.nomeComposizione }}
                    <v-divider v-if="dettaglio.nomeComposizione != '02000'"></v-divider>
                  </span>
                </v-card>
              </v-col>
            </v-row>
          </v-col>
        </v-virtual-scroll>

        <!-- BRERA Q -->
        <v-virtual-scroll
          v-if="
            composizioneNome != 'Retrò' &&
            (extantionNome == '4_Alzanti' ||
              extantionNome == 'Alzanti' ||
              extantionNome == 'Tutti i Modelli') &&
            (filtriMenu.text == 'Tutti i Modelli' ||
              filtriMenu.text == 'Alzanti' ||
              filtriMenu.text == '') &&
            dettaglio.nomeProdotto == 'Brera Q'
          "
          :bench="benched"
          :items="getDati"
          item-height="80"
          height="720"
          style="width: 15em"
        >
          <v-col style="margin-top: 0em; background-color: white" cols="12">
            <v-row style="margin-top: 0em; margin-left: 1em">
              <v-col v-for="(dettaglio, i) in getDati" :key="i">
                <v-card
                  style="width: auto; height: auto; padding: 0px; margin-top: -1.5em"
                  class="elevation-0 mb-0"
                >
                  <v-img
                    v-on:click="getDettComposizione(dettaglio)"
                    style="
                      width: 150px;
                      height: 300px;
                      margin-top: 10px;
                      cursor: pointer;
                      margin-left: 3em;
                    "
                    :src="dettaglio.url"
                    :lazy-src="dettaglio.url"
                    aspect-ratio="1"
                    class="white lighten-2 mb-5"
                  >
                    <template v-slot:placeholder>
                      <v-row class="fill-height ma-0" align="center" justify="center">
                        <v-progress-circular
                          indeterminate
                          color="black lighten-5"
                        ></v-progress-circular>
                      </v-row>
                    </template>
                  </v-img>
                  <span
                    style="font-size: 12px"
                    v-if="dettaglio.nomeComposizione != '02000'"
                    >{{ dettaglio.nomeComposizione }}
                    <v-divider v-if="dettaglio.nomeComposizione != '02000'"></v-divider>
                  </span>
                </v-card>
              </v-col>
            </v-row>
          </v-col>
        </v-virtual-scroll>

        <!-- BRISTOL -->
        <v-virtual-scroll
          v-if="
            composizioneNome != 'Retrò' &&
            (extantionNome == '4_Alzanti' ||
              extantionNome == 'Alzanti' ||
              extantionNome == 'Tutti i Modelli') &&
            (filtriMenu.text == 'Tutti i Modelli' ||
              filtriMenu.text == 'Alzanti' ||
              filtriMenu.text == '') &&
            dettaglio.nomeProdotto == 'Bristol'
          "
          :bench="benched"
          :items="getDati"
          item-height="80"
          height="720"
          style="width: 15em"
        >
          <v-col style="margin-top: 0em; background-color: white" cols="12">
            <v-row style="margin-top: 0em; margin-left: 1em">
              <v-col v-for="(dettaglio, i) in getDati" :key="i">
                <v-card
                  style="width: auto; height: auto; padding: 0px; margin-top: -1.5em"
                  class="elevation-0 mb-0"
                >
                  <v-img
                    v-on:click="getDettComposizione(dettaglio)"
                    style="
                      width: 150px;
                      height: 300px;
                      margin-top: 10px;
                      cursor: pointer;
                      margin-left: 3em;
                    "
                    :src="dettaglio.url"
                    :lazy-src="dettaglio.url"
                    aspect-ratio="1"
                    class="white lighten-2 mb-5"
                  >
                    <template v-slot:placeholder>
                      <v-row class="fill-height ma-0" align="center" justify="center">
                        <v-progress-circular
                          indeterminate
                          color="black lighten-5"
                        ></v-progress-circular>
                      </v-row>
                    </template>
                  </v-img>
                  <span
                    style="font-size: 12px"
                    v-if="dettaglio.nomeComposizione != '02000'"
                    >{{ dettaglio.nomeComposizione }}
                    <v-divider v-if="dettaglio.nomeComposizione != '02000'"></v-divider>
                  </span>
                </v-card>
              </v-col>
            </v-row>
          </v-col>
        </v-virtual-scroll>

        <!-- GINEVRA -->
        <v-virtual-scroll
          v-if="
            composizioneNome == 'Retrò' &&
            (extantionNome == '4_Alzanti' ||
              extantionNome == 'Alzanti' ||
              extantionNome == 'Tutti i Modelli') &&
            (filtriMenu.text == 'Tutti i Modelli' ||
              filtriMenu.text == 'Alzanti' ||
              filtriMenu.text == '') &&
            dettaglio.nomeProdotto == 'Ginevra'
          "
          :bench="benched"
          :items="getDati"
          item-height="80"
          height="720"
          style="width: 15em"
        >
          <v-col style="margin-top: 0em; background-color: white" cols="12">
            <v-row style="margin-top: 0em; margin-left: 1em">
              <v-col v-for="(dettaglio, i) in getDati" :key="i">
                <v-card
                  style="width: auto; height: auto; padding: 0px; margin-top: -1.5em"
                  class="elevation-0 mb-0"
                >
                  <v-img
                    v-on:click="getDettComposizione(dettaglio)"
                    style="
                      width: 150px;
                      height: 300px;
                      margin-top: 10px;
                      cursor: pointer;
                      margin-left: 3em;
                    "
                    :src="dettaglio.url"
                    :lazy-src="dettaglio.url"
                    aspect-ratio="1"
                    class="white lighten-2 mb-5"
                  >
                    <template v-slot:placeholder>
                      <v-row class="fill-height ma-0" align="center" justify="center">
                        <v-progress-circular
                          indeterminate
                          color="black lighten-5"
                        ></v-progress-circular>
                      </v-row>
                    </template>
                  </v-img>
                  <span
                    style="font-size: 12px"
                    v-if="dettaglio.nomeComposizione != '02000'"
                    >{{ dettaglio.nomeComposizione }}
                    <v-divider v-if="dettaglio.nomeComposizione != '02000'"></v-divider>
                  </span>
                </v-card>
              </v-col>
            </v-row>
          </v-col>
        </v-virtual-scroll>

        <!-- GLASGOW -->
        <v-virtual-scroll
          v-if="
            composizioneNome != 'Retrò' &&
            (extantionNome == '4_Alzanti' ||
              extantionNome == 'Alzanti' ||
              extantionNome == 'Tutti i Modelli') &&
            (filtriMenu.text == 'Tutti i Modelli' ||
              filtriMenu.text == 'Alzanti' ||
              filtriMenu.text == '') &&
            dettaglio.nomeProdotto == 'Glasgow'
          "
          :bench="benched"
          :items="getDati"
          item-height="80"
          height="720"
          style="width: 15em"
        >
          <v-col style="margin-top: 0em; background-color: white" cols="12">
            <v-row style="margin-top: 0em; margin-left: 1em">
              <v-col v-for="(dettaglio, i) in getDati" :key="i">
                <v-card
                  style="width: auto; height: auto; padding: 0px; margin-top: -1.5em"
                  class="elevation-0 mb-0"
                >
                  <v-img
                    v-on:click="getDettComposizione(dettaglio)"
                    style="
                      width: 150px;
                      height: 300px;
                      margin-top: 10px;
                      cursor: pointer;
                      margin-left: 3em;
                    "
                    :src="dettaglio.url"
                    :lazy-src="dettaglio.url"
                    aspect-ratio="1"
                    class="white lighten-2 mb-5"
                  >
                    <template v-slot:placeholder>
                      <v-row class="fill-height ma-0" align="center" justify="center">
                        <v-progress-circular
                          indeterminate
                          color="black lighten-5"
                        ></v-progress-circular>
                      </v-row>
                    </template>
                  </v-img>
                  <span
                    style="font-size: 12px"
                    v-if="dettaglio.nomeComposizione != '02000'"
                    >{{ dettaglio.nomeComposizione }}
                    <v-divider v-if="dettaglio.nomeComposizione != '02000'"></v-divider>
                  </span>
                </v-card>
              </v-col>
            </v-row>
          </v-col>
        </v-virtual-scroll>

        <!-- LEEDS -->
        <v-virtual-scroll
          v-if="
            composizioneNome != 'Retrò' &&
            (extantionNome == '4_Alzanti' ||
              extantionNome == 'Alzanti' ||
              extantionNome == 'Tutti i Modelli') &&
            (filtriMenu.text == 'Tutti i Modelli' ||
              filtriMenu.text == 'Alzanti' ||
              filtriMenu.text == '') &&
            dettaglio.nomeProdotto == 'Leeds'
          "
          :bench="benched"
          :items="getDati"
          item-height="80"
          height="720"
          style="width: 15em"
        >
          <v-col style="margin-top: 0em; background-color: white" cols="12">
            <v-row style="margin-top: 0em; margin-left: 1em">
              <v-col v-for="(dettaglio, i) in getDati" :key="i">
                <v-card
                  style="width: auto; height: auto; padding: 0px; margin-top: -1.5em"
                  class="elevation-0 mb-0"
                >
                  <v-img
                    v-on:click="getDettComposizione(dettaglio)"
                    style="
                      width: 150px;
                      height: 300px;
                      margin-top: 10px;
                      cursor: pointer;
                      margin-left: 3em;
                    "
                    :src="dettaglio.url"
                    :lazy-src="dettaglio.url"
                    aspect-ratio="1"
                    class="white lighten-2 mb-5"
                  >
                    <template v-slot:placeholder>
                      <v-row class="fill-height ma-0" align="center" justify="center">
                        <v-progress-circular
                          indeterminate
                          color="black lighten-5"
                        ></v-progress-circular>
                      </v-row>
                    </template>
                  </v-img>
                  <span
                    style="font-size: 12px"
                    v-if="dettaglio.nomeComposizione != '02000'"
                    >{{ dettaglio.nomeComposizione }}
                    <v-divider v-if="dettaglio.nomeComposizione != '02000'"></v-divider>
                  </span>
                </v-card>
              </v-col>
            </v-row>
          </v-col>
        </v-virtual-scroll>

        <!-- LISBONA -->
        <v-virtual-scroll
          v-if="
            composizioneNome == 'Retrò' &&
            (extantionNome == '4_Alzanti' ||
              extantionNome == 'Alzanti' ||
              extantionNome == 'Tutti i Modelli') &&
            (filtriMenu.text == 'Tutti i Modelli' ||
              filtriMenu.text == 'Alzanti' ||
              filtriMenu.text == '') &&
            dettaglio.nomeProdotto == 'Lisbona'
          "
          :bench="benched"
          :items="getDati"
          item-height="80"
          height="720"
          style="width: 15em"
        >
          <v-col style="margin-top: 0em; background-color: white" cols="12">
            <v-row style="margin-top: 0em; margin-left: 1em">
              <v-col v-for="(dettaglio, i) in getDati" :key="i">
                <v-card
                  style="width: auto; height: auto; padding: 0px; margin-top: -1.5em"
                  class="elevation-0 mb-0"
                >
                  <v-img
                    v-on:click="getDettComposizione(dettaglio)"
                    style="
                      width: 150px;
                      height: 300px;
                      margin-top: 10px;
                      cursor: pointer;
                      margin-left: 3em;
                    "
                    :src="dettaglio.url"
                    :lazy-src="dettaglio.url"
                    aspect-ratio="1"
                    class="white lighten-2 mb-5"
                  >
                    <template v-slot:placeholder>
                      <v-row class="fill-height ma-0" align="center" justify="center">
                        <v-progress-circular
                          indeterminate
                          color="black lighten-5"
                        ></v-progress-circular>
                      </v-row>
                    </template>
                  </v-img>
                  <span
                    style="font-size: 12px"
                    v-if="dettaglio.nomeComposizione != '02000'"
                    >{{ dettaglio.nomeComposizione }}
                    <v-divider v-if="dettaglio.nomeComposizione != '02000'"></v-divider>
                  </span>
                </v-card>
              </v-col>
            </v-row>
          </v-col>
        </v-virtual-scroll>

        <!-- SOCHI -->
        <v-virtual-scroll
          v-if="
            composizioneNome != 'Retrò' &&
            (extantionNome == '4_Alzanti' ||
              extantionNome == 'Alzanti' ||
              extantionNome == 'Tutti i Modelli') &&
            (filtriMenu.text == 'Tutti i Modelli' ||
              filtriMenu.text == 'Alzanti' ||
              filtriMenu.text == '') &&
            dettaglio.nomeProdotto == 'Sochi'
          "
          :bench="benched"
          :items="getDati"
          item-height="80"
          height="720"
          style="width: 15em"
        >
          <v-col style="margin-top: 0em; background-color: white" cols="12">
            <v-row style="margin-top: 0em; margin-left: 1em">
              <v-col v-for="(dettaglio, i) in getDati" :key="i">
                <v-card
                  style="width: auto; height: auto; padding: 0px; margin-top: -1.5em"
                  class="elevation-0 mb-0"
                >
                  <v-img
                    v-on:click="getDettComposizione(dettaglio)"
                    style="
                      width: 150px;
                      height: 300px;
                      margin-top: 10px;
                      cursor: pointer;
                      margin-left: 3em;
                    "
                    :src="dettaglio.url"
                    :lazy-src="dettaglio.url"
                    aspect-ratio="1"
                    class="white lighten-2 mb-5"
                  >
                    <template v-slot:placeholder>
                      <v-row class="fill-height ma-0" align="center" justify="center">
                        <v-progress-circular
                          indeterminate
                          color="black lighten-5"
                        ></v-progress-circular>
                      </v-row>
                    </template>
                  </v-img>
                  <span
                    style="font-size: 12px"
                    v-if="dettaglio.nomeComposizione != '02000'"
                    >{{ dettaglio.nomeComposizione }}
                    <v-divider v-if="dettaglio.nomeComposizione != '02000'"></v-divider>
                  </span>
                </v-card>
              </v-col>
            </v-row>
          </v-col>
        </v-virtual-scroll>

        <!-- SOCHI Q -->
        <v-virtual-scroll
          v-if="
            composizioneNome != 'Retrò' &&
            (extantionNome == '4_Alzanti' ||
              extantionNome == 'Alzanti' ||
              extantionNome == 'Tutti i Modelli') &&
            (filtriMenu.text == 'Tutti i Modelli' ||
              filtriMenu.text == 'Alzanti' ||
              filtriMenu.text == '') &&
            dettaglio.nomeProdotto == 'Sochi Q'
          "
          :bench="benched"
          :items="getDati"
          item-height="80"
          height="720"
          style="width: 15em"
        >
          <v-col style="margin-top: 0em; background-color: white" cols="12">
            <v-row style="margin-top: 0em; margin-left: 1em">
              <v-col v-for="(dettaglio, i) in getDati" :key="i">
                <v-card
                  style="width: auto; height: auto; padding: 0px; margin-top: -1.5em"
                  class="elevation-0 mb-0"
                >
                  <v-img
                    v-on:click="getDettComposizione(dettaglio)"
                    style="
                      width: 150px;
                      height: 300px;
                      margin-top: 10px;
                      cursor: pointer;
                      margin-left: 3em;
                    "
                    :src="dettaglio.url"
                    :lazy-src="dettaglio.url"
                    aspect-ratio="1"
                    class="white lighten-2 mb-5"
                  >
                    <template v-slot:placeholder>
                      <v-row class="fill-height ma-0" align="center" justify="center">
                        <v-progress-circular
                          indeterminate
                          color="black lighten-5"
                        ></v-progress-circular>
                      </v-row>
                    </template>
                  </v-img>
                  <span
                    style="font-size: 12px"
                    v-if="dettaglio.nomeComposizione != '02000'"
                    >{{ dettaglio.nomeComposizione }}
                    <v-divider v-if="dettaglio.nomeComposizione != '02000'"></v-divider>
                  </span>
                </v-card>
              </v-col>
            </v-row>
          </v-col>
        </v-virtual-scroll>

        <!-- SCROLLBAR PER SCORREVOLI -->
        <!-- KIT SOCHI -->
        <v-virtual-scroll
          v-if="
            composizioneNome != 'Retrò' &&
            (extantionNome == '5_Scorrevoli' ||
              extantionNome == 'Scorrevoli' ||
              extantionNome == 'Tutti i Modelli') &&
            (filtriMenu.text == 'Tutti i Modelli' ||
              filtriMenu.text == 'Scorrevoli' ||
              filtriMenu.text == '') &&
            dettaglio.nomeProdotto == 'Kit Sochi'
          "
          :bench="benched"
          :items="getDati"
          item-height="117"
          height="720"
          style="width: 15em"
        >
          <v-col style="margin-top: -40em; background-color: white" cols="12">
            <v-row style="margin-top: 0em; margin-left: 1em">
              <v-col v-for="(dettaglio, i) in getDati" :key="i">
                <v-card
                  style="width: auto; height: auto; padding: 0px; margin-top: -1.5em"
                  class="elevation-0 mb-0"
                >
                  <v-img
                    v-on:click="getDettComposizione(dettaglio)"
                    style="width: 180px; height: 200px; margin-top: 10px; cursor: pointer"
                    :src="dettaglio.url"
                    :lazy-src="dettaglio.url"
                    aspect-ratio="1"
                    class="white lighten-2 mb-5"
                  >
                    <template v-slot:placeholder>
                      <v-row class="fill-height ma-0" align="center" justify="center">
                        <v-progress-circular
                          indeterminate
                          color="black lighten-5"
                        ></v-progress-circular>
                      </v-row>
                    </template>
                  </v-img>
                  <span style="font-size: 12px" v-if="dettaglio.nomeComposizione != '99'"
                    >{{ dettaglio.nomeComposizione }}
                    <v-divider v-if="dettaglio.nomeComposizione != '99'"></v-divider>
                  </span>
                </v-card>
              </v-col>
            </v-row>
          </v-col>
        </v-virtual-scroll>

        <!-- KIT SOCHI Q -->
        <v-virtual-scroll
          v-if="
            composizioneNome != 'Retrò' &&
            (extantionNome == '5_Scorrevoli' ||
              extantionNome == 'Scorrevoli' ||
              extantionNome == 'Tutti i Modelli') &&
            (filtriMenu.text == 'Tutti i Modelli' ||
              filtriMenu.text == 'Scorrevoli' ||
              filtriMenu.text == '') &&
            dettaglio.nomeProdotto == 'Kit Sochi Q'
          "
          :bench="benched"
          :items="getDati"
          item-height="117"
          height="720"
          style="width: 15em"
        >
          <v-col style="margin-top: -40em; background-color: white" cols="12">
            <v-row style="margin-top: 0em; margin-left: 1em">
              <v-col v-for="(dettaglio, i) in getDati" :key="i">
                <v-card
                  style="width: auto; height: auto; padding: 0px; margin-top: -1.5em"
                  class="elevation-0 mb-0"
                >
                  <v-img
                    v-on:click="getDettComposizione(dettaglio)"
                    style="width: 180px; height: 200px; margin-top: 10px; cursor: pointer"
                    :src="dettaglio.url"
                    :lazy-src="dettaglio.url"
                    aspect-ratio="1"
                    class="white lighten-2 mb-5"
                  >
                    <template v-slot:placeholder>
                      <v-row class="fill-height ma-0" align="center" justify="center">
                        <v-progress-circular
                          indeterminate
                          color="black lighten-5"
                        ></v-progress-circular>
                      </v-row>
                    </template>
                  </v-img>
                  <span style="font-size: 12px" v-if="dettaglio.nomeComposizione != '99'"
                    >{{ dettaglio.nomeComposizione }}
                    <v-divider v-if="dettaglio.nomeComposizione != '99'"></v-divider>
                  </span>
                </v-card>
              </v-col>
            </v-row>
          </v-col>
        </v-virtual-scroll>

        <!-- KIT GINEVRA -->
        <v-virtual-scroll
          v-if="
            composizioneNome == 'Retrò' &&
            (extantionNome == '5_Scorrevoli' ||
              extantionNome == 'Scorrevoli' ||
              extantionNome == 'Tutti i Modelli') &&
            (filtriMenu.text == 'Tutti i Modelli' ||
              filtriMenu.text == 'Scorrevoli' ||
              filtriMenu.text == '') &&
            dettaglio.nomeProdotto == 'Kit Ginevra'
          "
          :bench="benched"
          :items="getDati"
          item-height="117"
          height="720"
          style="width: 15em"
        >
          <v-col style="margin-top: -40em; background-color: white" cols="12">
            <v-row style="margin-top: 0em; margin-left: 1em">
              <v-col v-for="(dettaglio, i) in getDati" :key="i">
                <v-card
                  style="width: auto; height: auto; padding: 0px; margin-top: -1.5em"
                  class="elevation-0 mb-0"
                >
                  <v-img
                    v-on:click="getDettComposizione(dettaglio)"
                    style="width: 180px; height: 200px; margin-top: 10px; cursor: pointer"
                    :src="dettaglio.url"
                    :lazy-src="dettaglio.url"
                    aspect-ratio="1"
                    class="white lighten-2 mb-5"
                  >
                    <template v-slot:placeholder>
                      <v-row class="fill-height ma-0" align="center" justify="center">
                        <v-progress-circular
                          indeterminate
                          color="black lighten-5"
                        ></v-progress-circular>
                      </v-row>
                    </template>
                  </v-img>
                  <span style="font-size: 12px" v-if="dettaglio.nomeComposizione != '99'"
                    >{{ dettaglio.nomeComposizione }}
                    <v-divider v-if="dettaglio.nomeComposizione != '99'"></v-divider>
                  </span>
                </v-card>
              </v-col>
            </v-row>
          </v-col>
        </v-virtual-scroll>
      </v-responsive>

      <!-- PRODOTTO NO MANIGLIONI / ALZANTI (MANIGLIE - POMOLI - SCORREVOLI) -->
      <v-col
        v-if="
          dettaglioTipo != '3_Maniglioni' &&
          dettaglioTipo != '4_Alzanti' &&
          dettaglioTipo != 'Maniglioni' &&
          dettaglioTipo != 'Alzanti'
        "
        style="margin-top: -3em; margin-left: 3em"
        cols="4"
      >
        <!-- MANIGLIA - POMOLO -->
        <div v-if="dettaglioTipo != 'Scorrevoli' && dettaglioTipo != '5_Scorrevoli'">
          <v-img
            style="
              width: 600px;
              height: 890px;
              margin-top: 0em;
              margin-left: 0em;
              cursor: pointer;
              margin-top: -7em;
            "
            :src="dettaglio.url"
            :lazy-src="dettaglio.url"
            aspect-ratio="1"
            class="white lighten-2"
          >
            <template v-slot:placeholder>
              <v-row class="fill-height ma-0" align="center" justify="center">
                <v-progress-circular
                  indeterminate
                  color="black lighten-5"
                ></v-progress-circular>
              </v-row>
            </template>
          </v-img>
        </div>
        <!-- SCORREVOLE -->
        <div v-if="dettaglioTipo == 'Scorrevoli' || dettaglioTipo == '5_Scorrevoli'">
          <v-img
            style="
              width: 500px;
              height: 690px;
              margin-top: 0em;
              margin-left: 0em;
              cursor: pointer;
            "
            :src="dettaglio.url"
            :lazy-src="dettaglio.url"
            aspect-ratio="1"
            class="white lighten-2"
          >
            <template v-slot:placeholder>
              <v-row class="fill-height ma-0" align="center" justify="center">
                <v-progress-circular
                  indeterminate
                  color="black lighten-5"
                ></v-progress-circular>
              </v-row>
            </template>
          </v-img>
        </div>
      </v-col>

      <!-- PRODOTTO SI MANIGLIONI / ALZANTI -->
      <v-col
        v-if="
          dettaglioTipo == '3_Maniglioni' ||
          dettaglioTipo == '4_Alzanti' ||
          dettaglioTipo == 'Maniglioni' ||
          dettaglioTipo == 'Alzanti'
        "
        style="margin-top: -7em; margin-left: -5%"
        cols="4"
      >
        <div>
          <!-- v-on:click="getImgComposizione(dettaglio)" -->
          <!-- MANIGLIONE -->
          <v-img
            v-if="dettaglioTipo == '3_Maniglioni' || dettaglioTipo == 'Maniglioni'"
            style="width: 600px; height: 890px; margin-top: 2em; margin-left: 0em"
            :src="dettaglio.url"
            :lazy-src="dettaglio.url"
            aspect-ratio="1"
          >
            <template v-slot:placeholder>
              <v-row class="fill-height ma-0" align="center" justify="center">
                <v-progress-circular
                  indeterminate
                  color="black lighten-5"
                ></v-progress-circular>
              </v-row>
            </template>
          </v-img>
          <!-- ALZANTE -->
          <v-img
            v-else
            style="width: 450px; height: 740px; margin-top: 3em; margin-left: 0em"
            :src="dettaglio.url"
            :lazy-src="dettaglio.url"
            aspect-ratio="1"
          >
            <template v-slot:placeholder>
              <v-row class="fill-height ma-0" align="center" justify="center">
                <v-progress-circular
                  indeterminate
                  color="black lighten-5"
                ></v-progress-circular>
              </v-row>
            </template>
          </v-img>
        </div>
      </v-col>

      <!-- DESCRIZIONE PRODOTTO (NO MANIGLIONI E ALZANTI) -->
      <v-col
        v-if="
          dettaglioTipo != '3_Maniglioni' &&
          dettaglioTipo != '4_Alzanti' &&
          dettaglioTipo != 'Maniglioni' &&
          dettaglioTipo != 'Alzanti'
        "
        style="margin-top: 0em"
        cols="5"
      >
        <v-row style="margin-left: -5%" class="d-flex child-flex">
          <h1 style="background-color: trasparent; position: absolute; margin-top: 3%">
            {{ dettaglio.nomeProdotto.toUpperCase() }}
          </h1>

          <div
            style="
              position: absolute;
              cursor: pointer;
              margin-left: 28em;
              margin-top: 3%;
              width: auto;
            "
          >
            <!-- <v-tooltip left>
                          <template v-slot:activator="{ on, attrs }">
                            <v-img
                              style="width: 60px; margin-left: -10px"
                              v-bind="attrs"
                              v-on="on"
                              v-on:click="vistaAngolare()"
                              :src="require('@/assets/img/3d_.png')"
                            >
                              <template v-slot:placeholder>
                                <v-row class="fill-height ma-0" align="center" justify="center">
                                  <v-progress-circular
                                    indeterminate
                                    color="black lighten-5"
                                  ></v-progress-circular>
                                </v-row>
                              </template>
                            </v-img>
                          </template>
                          <span>Vista 360°</span>
                        </v-tooltip>

                        <v-tooltip left>
                          <template v-slot:activator="{ on, attrs }">
                            <v-img
                              style="width: 70px; margin-left: -10px"
                              v-bind="attrs"
                              v-on="on"
                              v-on:click="vistaAngolare()"
                              class="mt-5"
                              :src="require('@/assets/img/Esploso.png')"
                            >
                              <template v-slot:placeholder>
                                <v-row class="fill-height ma-0" align="center" justify="center">
                                  <v-progress-circular
                                    indeterminate
                                    color="black lighten-5"
                                  ></v-progress-circular>
                                </v-row>
                              </template>
                            </v-img>
                          </template>
                          <span>Esploso</span>
                        </v-tooltip> -->
            <v-tooltip left>
              <template v-slot:activator="{ on, attrs }">
                <v-img
                  style="width: 40px; margin-left: -2px"
                  v-bind="attrs"
                  v-on="on"
                  v-on:click="chiudi(dettaglio)"
                  class="mt-6"
                  :src="require('@/assets/img/Scheda Tecnica.png')"
                >
                  <template v-slot:placeholder>
                    <v-row class="fill-height ma-0" align="center" justify="center">
                      <v-progress-circular
                        indeterminate
                        color="black lighten-5"
                      ></v-progress-circular>
                    </v-row>
                  </template>
                </v-img>
              </template>
              <span>Scheda tecnica</span>
            </v-tooltip>
          </div>

          <span
            style="
              background-color: trasparent;
              position: absolute;
              font-size: 15px;
              width: 350px;
              margin-top: 6%;
            "
          >
            {{ descrizioneProdotto }}
          </span>
        </v-row>

        <!-- FINITURE PER DETTAGLIO PRODOTTO NO MANIGLIONE / ALZANTE -->
        <v-row
          v-if="
            dettaglioTipo != '3_Maniglioni' &&
            dettaglioTipo != '4_Alzanti' &&
            dettaglioTipo != 'Maniglioni' &&
            dettaglioTipo != 'Alzanti'
          "
          style="margin-top: 50%; margin-left: 35%"
          class="d-flex child-fle"
        >
          <v-col cols="6">
            <!-- FINITURE STANDARD -->
            <v-row>
              <h3
                v-if="getDatiFinitureStandard(dettaglio) != 0"
                style="margin-top: -1em; text-align: left; margin-right: 0%"
              >
                Finiture Standard
              </h3>
            </v-row>
            <br />

            <v-col
              :style="[
                isTelefono() <= 768
                  ? { 'min-width': '20%', 'margin-top': '0%' }
                  : { 'min-width': '20%', 'margin-top': '0%' },
              ]"
              style="padding: 0"
              v-for="(dettaglio, i) in getDatiFinitureStandard(dettaglio)"
              :key="i"
              class="d-flex child-flex"
              cols="12"
            >
              <v-row>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <!-- SI SCORREVOLI -->
                    <v-card
                      v-if="
                        dettaglio.nomeProdotto == 'Kit Sochi' ||
                        dettaglio.nomeProdotto == 'Kit Sochi Q' ||
                        dettaglio.nomeProdotto == 'Kit Ginevra'
                      "
                      style="
                        width: auto;
                        height: auto;
                        padding: 0px;
                        margin-left: 15%;
                        margin-bottom: 3em;
                      "
                      class="elevation-0"
                    >
                      <v-img
                        v-on:click="getDettFiniture(dettaglio)"
                        v-bind="attrs"
                        v-on="on"
                        style="width: 75px; height: auto; cursor: pointer"
                        :src="dettaglio.url"
                        :lazy-src="dettaglio.url"
                      >
                        <template v-slot:placeholder>
                          <v-row class="fill-height ma-0" align="center" justify="center">
                            <v-progress-circular
                              indeterminate
                              color="black lighten-5"
                            ></v-progress-circular>
                          </v-row>
                        </template>
                      </v-img>
                    </v-card>

                    <!-- NO SCORREVOLI -->
                    <v-card
                      v-else
                      style="
                        width: auto;
                        height: auto;
                        padding: 0px;
                        margin-left: -0.5em;
                        margin-bottom: 3em;
                      "
                      class="elevation-0"
                    >
                      <v-img
                        v-on:click="getDettFiniture(dettaglio)"
                        v-bind="attrs"
                        v-on="on"
                        style="width: 155px; height: auto; cursor: pointer"
                        :src="dettaglio.url"
                        :lazy-src="dettaglio.url"
                      >
                        <template v-slot:placeholder>
                          <v-row class="fill-height ma-0" align="center" justify="center">
                            <v-progress-circular
                              indeterminate
                              color="black lighten-5"
                            ></v-progress-circular>
                          </v-row>
                        </template>
                      </v-img>
                    </v-card>
                  </template>
                  <span>{{ dettaglio.tipoFinitura }}</span>
                </v-tooltip>
              </v-row>
            </v-col>
          </v-col>

          <v-col cols="6" v-if="getDatiFiniturePvd(dettaglio) != 0">
            <!-- FINITURE PVD -->
            <v-row>
              <h3 style="margin-top: -1em; text-align: left; margin-right: 0%">
                Finiture PVD
              </h3>
            </v-row>
            <br />
            <v-col
              :style="[
                isTelefono() <= 768
                  ? { 'min-width': '20%', 'margin-top': '0%' }
                  : { 'min-width': '20%', 'margin-top': '0%' },
              ]"
              style="padding: 0"
              v-for="(dettaglio, i) in getDatiFiniturePvd(dettaglio)"
              :key="i"
              class="d-flex child-flex"
              cols="12"
            >
              <v-row>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <!-- SI SCORREVOLI -->
                    <v-card
                      v-if="
                        dettaglio.nomeProdotto == 'Kit Sochi' ||
                        dettaglio.nomeProdotto == 'Kit Sochi Q' ||
                        dettaglio.nomeProdotto == 'Kit Ginevra'
                      "
                      style="
                        width: auto;
                        height: auto;
                        padding: 0px;
                        margin-left: 7.5%;
                        margin-bottom: 3em;
                      "
                      class="elevation-0"
                    >
                      <v-img
                        v-on:click="getDettFiniture(dettaglio)"
                        v-bind="attrs"
                        v-on="on"
                        style="width: 75px; height: auto; cursor: pointer"
                        :src="dettaglio.url"
                        :lazy-src="dettaglio.url"
                      >
                        <template v-slot:placeholder>
                          <v-row class="fill-height ma-0" align="center" justify="center">
                            <v-progress-circular
                              indeterminate
                              color="black lighten-5"
                            ></v-progress-circular>
                          </v-row>
                        </template>
                      </v-img>
                    </v-card>

                    <!-- NO SCORREVOLI -->
                    <v-card
                      v-else
                      style="
                        width: auto;
                        height: auto;
                        padding: 0px;
                        margin-left: -0.5em;
                        margin-bottom: 3em;
                      "
                      class="elevation-0"
                    >
                      <v-img
                        v-on:click="getDettFiniture(dettaglio)"
                        v-bind="attrs"
                        v-on="on"
                        style="width: 155px; height: auto; cursor: pointer"
                        :src="dettaglio.url"
                        :lazy-src="dettaglio.url"
                      >
                        <template v-slot:placeholder>
                          <v-row class="fill-height ma-0" align="center" justify="center">
                            <v-progress-circular
                              indeterminate
                              color="black lighten-5"
                            ></v-progress-circular>
                          </v-row>
                        </template>
                      </v-img>
                    </v-card>
                  </template>
                  <span>{{ dettaglio.tipoFinitura }}</span>
                </v-tooltip>
              </v-row>
            </v-col>
          </v-col>

          <v-col cols="6" v-else>
            <!-- FINITURE RETRO' -->
            <v-row>
              <h3
                v-if="getDatiFinitureRetro(dettaglio) != 0"
                style="margin-top: -1em; text-align: left; margin-right: 0%"
              >
                Finiture Retrò
              </h3>
            </v-row>
            <br />
            <v-col
              :style="[
                isTelefono() <= 768
                  ? { 'min-width': '20%', 'margin-top': '0%' }
                  : { 'min-width': '20%', 'margin-top': '0%' },
              ]"
              style="padding: 0"
              v-for="(dettaglio, i) in getDatiFinitureRetro(dettaglio)"
              :key="i"
              class="d-flex child-flex"
              cols="12"
            >
              <v-row>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <!-- SI SCORREVOLI -->
                    <v-card
                      v-if="
                        dettaglio.nomeProdotto == 'Kit Sochi' ||
                        dettaglio.nomeProdotto == 'Kit Sochi Q' ||
                        dettaglio.nomeProdotto == 'Kit Ginevra'
                      "
                      style="
                        width: auto;
                        height: auto;
                        padding: 0px;
                        margin-left: 10%;
                        margin-bottom: 3em;
                      "
                      class="elevation-0"
                    >
                      <v-img
                        v-on:click="getDettFiniture(dettaglio)"
                        v-bind="attrs"
                        v-on="on"
                        style="width: 75px; height: auto; cursor: pointer"
                        :src="dettaglio.url"
                        :lazy-src="dettaglio.url"
                      >
                        <template v-slot:placeholder>
                          <v-row class="fill-height ma-0" align="center" justify="center">
                            <v-progress-circular
                              indeterminate
                              color="black lighten-5"
                            ></v-progress-circular>
                          </v-row>
                        </template>
                      </v-img>
                    </v-card>

                    <!-- NO SCORREVOLI -->
                    <v-card
                      v-else
                      style="
                        width: auto;
                        height: auto;
                        padding: 0px;
                        margin-left: -0.5em;
                        margin-bottom: 3em;
                      "
                      class="elevation-0"
                    >
                      <v-img
                        v-on:click="getDettFiniture(dettaglio)"
                        v-bind="attrs"
                        v-on="on"
                        style="width: 155px; height: auto; cursor: pointer"
                        :src="dettaglio.url"
                        :lazy-src="dettaglio.url"
                      >
                        <template v-slot:placeholder>
                          <v-row class="fill-height ma-0" align="center" justify="center">
                            <v-progress-circular
                              indeterminate
                              color="black lighten-5"
                            ></v-progress-circular>
                          </v-row>
                        </template>
                      </v-img>
                    </v-card>
                  </template>
                  <span>{{ dettaglio.tipoFinitura }}</span>
                </v-tooltip>
              </v-row>
            </v-col>
          </v-col>

          <!-- CODICE E SECONDA DESCRIZIONE PRODOTTO NO MANIGLIONI E ALZANTI -->
          <span style="margin-top: 15em; margin-left: -20em; position: absolute">
            <img
              v-if="novitaProdotto == true"
              style="
                width: 60px;
                height: 60px;
                margin-top: -5em;
                margin-left: -10em;
                float: right;
              "
              :src="require('@/assets/img/Novità.png')"
            />
            <img
              v-if="disponibilitaProdotto == true"
              style="
                width: 60px;
                height: 60px;
                margin-top: -5em;
                margin-left: -10em;
                float: right;
              "
              :src="require('@/assets/img/DisponibileMaggio23.png')"
            />
            <img
              v-if="restyling == true"
              style="
                width: 60px;
                height: 60px;
                margin-top: -5em;
                margin-left: -10em;
                float: right;
              "
              :src="require('@/assets/img/Restyling.png')"
            />
            <v-row>
              <h3 v-if="dettaglio.cdCompletoProdottoDettaglio == ''">
                {{ dettaglio.cdCompletoProdotto }}
              </h3>
              <h3 v-else>
                {{ dettaglio.cdCompletoProdottoDettaglio }}
              </h3>
            </v-row>
            <v-row>
              <div style="width: 18.5em; font-size: 15px">
                <span v-html="dettaglio.secondaDescrizione"></span>
              </div>
            </v-row>
          </span>
        </v-row>
      </v-col>

      <!-- DESCRIZIONE PRODOTTO (SI MANIGLIONI E ALZANTI) -->
      <v-col
        v-if="
          dettaglioTipo == '3_Maniglioni' ||
          dettaglioTipo == '4_Alzanti' ||
          dettaglioTipo == 'Maniglioni' ||
          dettaglioTipo == 'Alzanti'
        "
        style="margin-top: 0em"
        cols="5"
      >
        <v-row style="margin-left: -10%" class="d-flex child-flex">
          <h1 style="background-color: trasparent; position: absolute; margin-top: 3%">
            {{ dettaglio.nomeProdotto.toUpperCase() }}
          </h1>

          <div
            style="
              position: absolute;
              cursor: pointer;
              margin-left: 34em;
              margin-top: 3%;
              width: auto;
            "
          >
            <!-- <v-tooltip left>
                          <template v-slot:activator="{ on, attrs }">
                            <v-img
                              style="width: 60px; margin-left: -10px"
                              v-bind="attrs"
                              v-on="on"
                              v-on:click="vistaAngolare()"
                              :src="require('@/assets/img/3d_.png')"
                            >
                              <template v-slot:placeholder>
                                <v-row class="fill-height ma-0" align="center" justify="center">
                                  <v-progress-circular
                                    indeterminate
                                    color="black lighten-5"
                                  ></v-progress-circular>
                                </v-row>
                              </template>
                            </v-img>
                          </template>
                          <span>Vista 360°</span>
                        </v-tooltip>

                        <v-tooltip left>
                          <template v-slot:activator="{ on, attrs }">
                            <v-img
                              style="width: 70px; margin-left: -10px"
                              v-bind="attrs"
                              v-on="on"
                              v-on:click="vistaAngolare()"
                              class="mt-5"
                              :src="require('@/assets/img/Esploso.png')"
                            >
                              <template v-slot:placeholder>
                                <v-row class="fill-height ma-0" align="center" justify="center">
                                  <v-progress-circular
                                    indeterminate
                                    color="black lighten-5"
                                  ></v-progress-circular>
                                </v-row>
                              </template>
                            </v-img>
                          </template>
                          <span>Esploso</span>
                        </v-tooltip> -->
            <v-tooltip left>
              <template v-slot:activator="{ on, attrs }">
                <v-img
                  style="width: 40px; margin-left: -2px"
                  v-bind="attrs"
                  v-on="on"
                  v-on:click="chiudi(dettaglio)"
                  class="mt-6"
                  :src="require('@/assets/img/Scheda Tecnica.png')"
                >
                  <template v-slot:placeholder>
                    <v-row class="fill-height ma-0" align="center" justify="center">
                      <v-progress-circular
                        indeterminate
                        color="black lighten-5"
                      ></v-progress-circular>
                    </v-row>
                  </template>
                </v-img>
              </template>
              <span>Scheda tecnica</span>
            </v-tooltip>
          </div>

          <span
            style="
              background-color: trasparent;
              position: absolute;
              font-size: 15px;
              width: 350px;
              margin-top: 6%;
            "
          >
            {{ descrizioneProdotto }}
          </span>
        </v-row>

        <!-- FINITURE PER DETTAGLIO PRODOTTO SI MANIGLIONI / ALZANTI -->
        <v-row
          v-if="
            dettaglioTipo == '3_Maniglioni' ||
            dettaglioTipo == '4_Alzanti' ||
            dettaglioTipo == 'Maniglioni' ||
            dettaglioTipo == 'Alzanti'
          "
          style="margin-top: 50%; margin-left: 15%"
          class="d-flex child-fle"
        >
          <!-- FINITURE STANDARD -->
          <v-col v-if="getDatiFinitureStandard(dettaglio) != 0" cols="12">
            <v-row>
              <div>
                <h3 style="margin-top: -1em; text-align: left; margin-right: 0%">
                  Finiture Standard
                </h3>
              </div>
              <!-- <br /> -->
            </v-row>

            <v-row>
              <v-sheet
                style="margin-top: 0em"
                elevation="0"
                max-width="116%"
                :style="[
                  dettaglio.nomeProdotto == 'Dover' ||
                  dettaglio.nomeProdotto == 'Belfast' ||
                  dettaglio.nomeProdotto == 'Lahti'
                    ? { 'margin-left': '-3.5em' }
                    : { 'margin-left': '0em' },
                ]"
              >
                <v-slide-group show-arrows v-model="this.datiFinitureStandard">
                  <v-slide-item
                    class="pa-0"
                    v-for="(dettaglio, i) in getDatiFinitureStandard(dettaglio)"
                    :key="i"
                  >
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <v-card
                          elevation="0"
                          style="width: auto; height: auto"
                          v-bind="attrs"
                          v-on="on"
                        >
                          <v-img
                            v-on:click="getDettFiniture(dettaglio)"
                            style="width: 100px; height: 200px; cursor: pointer"
                            :src="dettaglio.url"
                            :lazy-src="dettaglio.url"
                            aspect-ratio="1"
                          >
                            <template v-slot:placeholder>
                              <v-row
                                class="fill-height ma-0"
                                align="center"
                                justify="center"
                              >
                                <v-progress-circular
                                  indeterminate
                                  color="black lighten-5"
                                ></v-progress-circular>
                              </v-row>
                            </template>
                          </v-img>
                        </v-card>
                      </template>
                      <span>{{ dettaglio.tipoFinitura }}</span>
                    </v-tooltip>
                  </v-slide-item>
                </v-slide-group>
              </v-sheet>
            </v-row>
          </v-col>

          <!-- FINITURE PVD -->
          <v-col v-if="getDatiFiniturePvd(dettaglio) != 0" cols="12">
            <v-row>
              <div>
                <h3 style="margin-top: 0em; text-align: left; margin-right: 0%">
                  Finiture PVD
                </h3>
              </div>
              <!-- <br /> -->
            </v-row>
            <v-row>
              <v-sheet
                style="margin-top: 0em"
                elevation="0"
                max-width="116%"
                :style="[
                  dettaglio.nomeProdotto == 'Dover' ||
                  dettaglio.nomeProdotto == 'Belfast' ||
                  dettaglio.nomeProdotto == 'Lahti' ||
                  dettaglio.nomeProdotto == 'Lisbona'
                    ? { 'margin-left': '0em' }
                    : { 'margin-left': '0em' },
                ]"
              >
                <v-slide-group show-arrows v-model="this.datiFiniturePvd">
                  <v-slide-item
                    class="pa-0"
                    v-for="(dettaglio, i) in getDatiFiniturePvd(dettaglio)"
                    :key="i"
                  >
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <v-card
                          elevation="0"
                          style="width: auto; height: auto"
                          v-bind="attrs"
                          v-on="on"
                        >
                          <v-img
                            v-on:click="getDettFiniture(dettaglio)"
                            style="width: 100px; height: 200px; cursor: pointer"
                            :src="dettaglio.url"
                            :lazy-src="dettaglio.url"
                            aspect-ratio="1"
                          >
                            <template v-slot:placeholder>
                              <v-row
                                class="fill-height ma-0"
                                align="center"
                                justify="center"
                              >
                                <v-progress-circular
                                  indeterminate
                                  color="black lighten-5"
                                ></v-progress-circular>
                              </v-row>
                            </template>
                          </v-img>
                        </v-card>
                      </template>
                      <span>{{ dettaglio.tipoFinitura }}</span>
                    </v-tooltip>
                  </v-slide-item>
                </v-slide-group>
              </v-sheet>
            </v-row>
          </v-col>

          <!-- FINITURE RETRO' -->
          <v-col v-if="getDatiFinitureRetro(dettaglio) != 0" cols="12">
            <v-row>
              <div>
                <h3 style="margin-top: 0em; text-align: left; margin-right: 0%">
                  Finiture Retrò
                </h3>
              </div>
              <!-- <br /> -->
            </v-row>
            <v-row>
              <v-sheet
                style="margin-top: 0em"
                elevation="0"
                max-width="116%"
                :style="[
                  dettaglio.nomeProdotto == 'Dover' ||
                  dettaglio.nomeProdotto == 'Belfast' ||
                  dettaglio.nomeProdotto == 'Lahti' ||
                  dettaglio.nomeProdotto == 'Lisbona'
                    ? { 'margin-left': '0em' }
                    : { 'margin-left': '0em' },
                ]"
              >
                <v-slide-group show-arrows v-model="this.datiFinitureRetro">
                  <v-slide-item
                    class="pa-0"
                    v-for="(dettaglio, i) in getDatiFinitureRetro(dettaglio)"
                    :key="i"
                  >
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <v-card
                          elevation="0"
                          style="width: auto; height: auto"
                          v-bind="attrs"
                          v-on="on"
                        >
                          <v-img
                            v-on:click="getDettFiniture(dettaglio)"
                            style="width: 100px; height: 200px; cursor: pointer"
                            :src="dettaglio.url"
                            :lazy-src="dettaglio.url"
                            aspect-ratio="1"
                          >
                            <template v-slot:placeholder>
                              <v-row
                                class="fill-height ma-0"
                                align="center"
                                justify="center"
                              >
                                <v-progress-circular
                                  indeterminate
                                  color="black lighten-5"
                                ></v-progress-circular>
                              </v-row>
                            </template>
                          </v-img>
                        </v-card>
                      </template>
                      <span>{{ dettaglio.tipoFinitura }}</span>
                    </v-tooltip>
                  </v-slide-item>
                </v-slide-group>
              </v-sheet>
            </v-row>
          </v-col>

          <!-- CODICE E SECONDA DESCRIZIONE PRODOTTO NO MANIGLIONI E ALZANTI -->
          <span style="margin-top: 15em; margin-left: -17em; position: absolute">
            <img
              v-if="novitaProdotto == true"
              style="
                width: 60px;
                height: 60px;
                margin-top: -5em;
                margin-left: -10em;
                float: right;
              "
              :src="require('@/assets/img/Novità.png')"
            />
            <img
              v-if="disponibilitaProdotto == true"
              style="
                width: 60px;
                height: 60px;
                margin-top: -5em;
                margin-left: -10em;
                float: right;
              "
              :src="require('@/assets/img/DisponibileMaggio23.png')"
            />
            <img
              v-if="restyling == true"
              style="
                width: 60px;
                height: 60px;
                margin-top: -5em;
                margin-left: -10em;
                float: right;
              "
              :src="require('@/assets/img/Restyling.png')"
            />
            <v-row>
              <h3 v-if="dettaglio.cdCompletoProdottoDettaglio == ''">
                {{ dettaglio.cdCompletoProdotto }}
              </h3>
              <h3 v-else>
                {{ dettaglio.cdCompletoProdottoDettaglio }}
              </h3>
            </v-row>
            <v-row>
              <div style="width: 18.5em; font-size: 15px">
                <span v-html="dettaglio.secondaDescrizione"></span>
              </div>
            </v-row>
          </span>
        </v-row>
      </v-col>
    </v-row>

    <!-- VISUALIZZAZIONE DA TELEFONO -->
    <v-row v-if="isTelefono() <= 768" style="margin-top: 0em">
      <v-col class="d-flex child-flex mb-0">
        <v-col cols="12">
          <v-card style="margin-top: -2em" elevation="0">
            <!-- NOME PRODOTTO -->
            <v-row>
              <v-card-title
                v-on:click="DescrizioneProdotto()"
                style="font-size: 25px; margin-left: 0%; margin-right: 0%"
              >
                {{ dettaglio.nomeProdotto }}
                <img
                  v-if="novitaProdotto == true"
                  style="
                    width: 30px;
                    height: 30px;
                    margin-top: 0em;
                    margin-left: 0.5em;
                    float: right;
                  "
                  :src="require('@/assets/img/Novità.png')"
                />
                <img
                  v-if="disponibilitaProdotto == true"
                  style="
                    width: 30px;
                    height: 30px;
                    margin-top: 0em;
                    margin-left: 0.5m;
                    float: right;
                  "
                  :src="require('@/assets/img/DisponibileMaggio23.png')"
                />
                <img
                  v-if="restyling == true"
                  style="
                    width: 60px;
                    height: 60px;
                    margin-top: -5em;
                    margin-left: -10em;
                    float: right;
                  "
                  :src="require('@/assets/img/Restyling.png')"
                />
              </v-card-title>

              <v-spacer></v-spacer>

              <v-row style="margin-top: -0.5em; margin-left: 10em">
                <!-- PDF PRODOTTO -->
                <v-tooltip left>
                  <template v-slot:activator="{ on, attrs }">
                    <v-card elevation="0" style="width: 25px">
                      <v-img
                        style="width: auto; height: auto; margin-left: 0px"
                        v-bind="attrs"
                        v-on="on"
                        v-on:click="chiudi(dettaglio)"
                        class="mt-6"
                        :src="require('@/assets/img/Scheda Tecnica.png')"
                      >
                        <template v-slot:placeholder>
                          <v-row class="fill-height ma-0" align="center" justify="center">
                            <v-progress-circular
                              indeterminate
                              color="black lighten-5"
                            ></v-progress-circular>
                          </v-row>
                        </template>
                      </v-img>
                    </v-card>
                  </template>
                  <span>Scheda tecnica</span>
                </v-tooltip>
              </v-row>
            </v-row>

            <!-- DESCRIZIONE PRODOTTO -->
            <!-- SBACKBAR -->
            <v-snackbar
              v-if="dettaglio.descrizione != ''"
              color="#ce0538"
              v-model="snackbar"
              :multi-line="true"
              style="margin-bottom: 100%"
              left
              shaped
              center
            >
              {{ dettaglio.descrizione }}
            </v-snackbar>

            <!-- FINITURE NO MANIGLIONI / ALZANTI / SCORREVOLI -->
            <v-sheet
              v-if="
                dettaglioTipo.substring(0, 1) != '3' &&
                dettaglioTipo.substring(0, 1) != '4' &&
                dettaglioTipo.substring(0, 1) != '5' &&
                dettaglioTipo != 'Maniglioni' &&
                dettaglioTipo != 'Alzanti' &&
                dettaglioTipo != 'Scorrevoli' &&
                getDatiFinitureStandard(dettaglio) != 0
              "
              elevation="0"
              max-width="100%"
              style="margin-top: 10px; margin-left: 0%; margin-right: 0%"
            >
              <v-slide-group show-arrows v-model="this.datiFinitureStandard">
                <v-slide-item
                  class="pa-0"
                  v-for="(dettaglio, i) in getDatiFinitureTelefono(dettaglio)"
                  :key="i"
                >
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-img
                        v-on:click="getDettFiniture(dettaglio)"
                        v-bind="attrs"
                        v-on="on"
                        style="
                          width: 90px;
                          height: 40px;
                          margin-top: 0px;
                          cursor: pointer;
                          margin-top: 0px;
                          padding: 0px;
                          padding-bottom: 0px;
                          padding-top: 0px;
                          padding-left: 0px;
                          padding-right: 0px;
                        "
                        :src="dettaglio.url"
                        :lazy-src="dettaglio.url"
                        aspect-ratio="1"
                        class="ma-1"
                      >
                        <template v-slot:placeholder>
                          <v-row class="fill-height ma-0" align="center" justify="center">
                            <v-progress-circular
                              indeterminate
                              color="black lighten-5"
                            ></v-progress-circular>
                          </v-row>
                        </template>
                      </v-img>
                    </template>
                    <span>{{ dettaglio.tipoFinitura }}</span>
                  </v-tooltip>
                </v-slide-item>
              </v-slide-group>
            </v-sheet>

            <!-- FINITURE SI MANIGLIONI / ALZANTI -->
            <v-sheet
              v-if="
                dettaglioTipo.substring(0, 1) == '3' ||
                dettaglioTipo.substring(0, 1) == '4' ||
                dettaglioTipo == 'Maniglioni' ||
                (dettaglioTipo == 'Alzanti' && getDatiFinitureStandard(dettaglio) != 0)
              "
              elevation="0"
              max-width="100%"
              style="margin-top: 0px; margin-left: 0%; margin-right: 0%;"
            >
              <v-slide-group
                show-arrows
                v-model="this.datiFinitureStandard"
                style="z-index: 10"
              >
                <v-slide-item
                  class="pa-0"
                  v-for="(dettaglio, i) in getDatiFinitureTelefono(dettaglio)"
                  :key="i"
                >
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-img
                        v-on:click="getDettFiniture(dettaglio)"
                        v-bind="attrs"
                        v-on="on"
                        style="
                          width: 60px;
                          height: 110px;
                          margin-top: 0px;
                          cursor: pointer;
                          margin-top: 0px;
                          padding: 0px;
                          padding-bottom: 0px;
                          padding-top: 0px;
                          padding-left: 0px;
                          padding-right: 0px;
                        "
                        :src="dettaglio.url"
                        :lazy-src="dettaglio.url"
                        aspect-ratio="1"
                        class="ma-1"
                      >
                        <template v-slot:placeholder>
                          <v-row class="fill-height ma-0" align="center" justify="center">
                            <v-progress-circular
                              indeterminate
                              color="black lighten-5"
                            ></v-progress-circular>
                          </v-row>
                        </template>
                      </v-img>
                    </template>
                    <span>{{ dettaglio.tipoFinitura }}</span>
                  </v-tooltip>
                </v-slide-item>
              </v-slide-group>
            </v-sheet>

            <!-- FINITURE SI SCORREVOLI -->
            <v-sheet
              v-if="
                dettaglioTipo.substring(0, 1) == '5' ||
                (dettaglioTipo == 'Scorrevoli' &&
                  (getDatiFinitureStandard(dettaglio) != 0 ||
                    getDatiFinitureRetro(dettaglio) != 0))
              "
              elevation="0"
              max-width="120%"
              style="margin-top: 0px; margin-left: 0%; margin-right: 0%"
            >
              <!-- FINITURE STANDARD - PVD -->
              <v-slide-group
                v-if="dettaglio.nomeProdotto != 'Kit Ginevra'"
                show-arrows
                v-model="this.datiFinitureStandard"
              >
                <v-slide-item
                  class="pa-0"
                  v-for="(dettaglio, i) in getDatiFinitureTelefono(dettaglio)"
                  :key="i"
                >
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-img
                        v-on:click="getDettFiniture(dettaglio)"
                        v-bind="attrs"
                        v-on="on"
                        style="
                          width: 60px;
                          height: 60px;
                          margin-top: 0px;
                          cursor: pointer;
                          margin-top: 0px;
                          padding: 0px;
                          padding-bottom: 0px;
                          padding-top: 0px;
                          padding-left: 0px;
                          padding-right: 0px;
                        "
                        :src="dettaglio.url"
                        :lazy-src="dettaglio.url"
                        aspect-ratio="1"
                        class="ma-1"
                      >
                        <template v-slot:placeholder>
                          <v-row class="fill-height ma-0" align="center" justify="center">
                            <v-progress-circular
                              indeterminate
                              color="black lighten-5"
                            ></v-progress-circular>
                          </v-row>
                        </template>
                      </v-img>
                    </template>
                    <span>{{ dettaglio.tipoFinitura }}</span>
                  </v-tooltip>
                </v-slide-item>
              </v-slide-group>

              <!-- FINITURE RETRO' -->
              <v-slide-group v-else show-arrows v-model="this.datiFinitureRetro">
                <v-slide-item
                  class="pa-0"
                  v-for="(dettaglio, i) in getDatiFinitureTelefono(dettaglio)"
                  :key="i"
                >
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-card elevation="0" style="width: 60px; height: auto">
                        <v-img
                          v-on:click="getDettFiniture(dettaglio)"
                          v-bind="attrs"
                          v-on="on"
                          style="
                            width: auto;
                            height: auto;
                            margin-top: 0px;
                            cursor: pointer;
                            margin-top: 0px;
                            padding: 0px;
                            padding-bottom: 0px;
                            padding-top: 0px;
                            padding-left: 0px;
                            padding-right: 0px;
                          "
                          :src="dettaglio.url"
                          :lazy-src="dettaglio.url"
                          aspect-ratio="1"
                          class="ma-1"
                        >
                          <template v-slot:placeholder>
                            <v-row
                              class="fill-height ma-0"
                              align="center"
                              justify="center"
                            >
                              <v-progress-circular
                                indeterminate
                                color="black lighten-5"
                              ></v-progress-circular>
                            </v-row>
                          </template>
                        </v-img>
                      </v-card>
                    </template>
                    <span>{{ dettaglio.tipoFinitura }}</span>
                  </v-tooltip>
                </v-slide-item>
              </v-slide-group>
            </v-sheet>

            <!-- PRODOTTO NO MANIGLIONI - ALZANTI-->
            <v-row
              v-if="
                dettaglioTipo.substring(0, 1) != '3' &&
                dettaglioTipo.substring(0, 1) != '4' &&
                dettaglioTipo.substring(0, 1) != '5' &&
                dettaglioTipo != 'Maniglioni' &&
                dettaglioTipo != 'Alzanti' &&
                dettaglioTipo != 'Scorrevoli'
              "
              style="width: 100%"
            >
              <v-col cols="9">
                <!-- v-on:click="DescrizioneProdotto(), getImgComposizione(dettaglio)" -->
                <v-img
                  style="
                    width: auto;
                    height: 410px;
                    margin-top: 0px;
                    cursor: pointer;
                    padding-bottom: 0px;
                    padding-top: 0px;
                  "
                  :src="dettaglio.url"
                  :lazy-src="dettaglio.url"
                  aspect-ratio="1"
                  class="white lighten-2 center"
                  v-on:click="DescrizioneProdotto()"
                >
                  <template v-slot:placeholder>
                    <v-row class="fill-height ma-0" align="center" justify="center">
                      <v-progress-circular
                        indeterminate
                        color="black lighten-5"
                      ></v-progress-circular>
                    </v-row>
                  </template>
                </v-img>
              </v-col>

              <v-col style="z-index: 10; margin-left: -20%; margin-top: 70%" cols="3">
                <div style="width: 12em">
                  <h4 v-if="dettaglio.cdCompletoProdottoDettaglio == ''">
                    {{ dettaglio.cdCompletoProdotto }}
                  </h4>
                  <h4 v-else>
                    {{ dettaglio.cdCompletoProdottoDettaglio }}
                  </h4>
                </div>
                <div style="width: 12em; font-size: 12px">
                  <span v-html="dettaglio.secondaDescrizione"></span>
                </div>
              </v-col>
            </v-row>

            <!-- PRODOTTO SI MANIGLIONI -->
            <v-row
              v-if="dettaglioTipo.substring(0, 1) == '3' || dettaglioTipo == 'Maniglioni'"
              style="width: 100%; margin-top: -20%"
            >
              <v-col cols="9">
                <!-- v-on:click="DescrizioneProdotto(), getImgComposizione(dettaglio)" -->
                <v-img
                  style="
                    width: auto;
                    height: 480px;
                    margin-top: 1em;
                    margin-bottom: 0em;
                    cursor: pointer;
                    padding-bottom: 30px;
                    padding-top: 0px;
                  "
                  :src="dettaglio.url"
                  :lazy-src="dettaglio.url"
                  aspect-ratio="1"
                  class="white lighten-2 center"
                  v-on:click="DescrizioneProdotto()"
                >
                  <template v-slot:placeholder>
                    <v-row class="fill-height ma-0" align="center" justify="center">
                      <v-progress-circular
                        indeterminate
                        color="black lighten-5"
                      ></v-progress-circular>
                    </v-row>
                  </template>
                </v-img>
              </v-col>

              <v-col style="z-index: 10; margin-left: -20%; margin-top: 95%" cols="3">
                <div style="width: 12em">
                  <h4 v-if="dettaglio.cdCompletoProdottoDettaglio == ''">
                    {{ dettaglio.cdCompletoProdotto }}
                  </h4>
                  <h4 v-else>
                    {{ dettaglio.cdCompletoProdottoDettaglio }}
                  </h4>
                </div>
                <div style="width: 12em; font-size: 12px">
                  <span v-html="dettaglio.secondaDescrizione"></span>
                </div>
              </v-col>
            </v-row>

            <!-- PRODOTTO SI ALZANTI -->
            <v-row
              v-if="dettaglioTipo.substring(0, 1) == '4' || dettaglioTipo == 'Alzanti'"
              style="width: 100%"
            >
              <v-col cols="9">
                <!-- v-on:click="DescrizioneProdotto(), getImgComposizione(dettaglio)" -->
                <v-img
                  style="
                    width: auto;
                    height: 450px;
                    margin-top: -1em;
                    cursor: pointer;
                    padding-bottom: 0px;
                    padding-top: 0px;
                  "
                  :src="dettaglio.url"
                  :lazy-src="dettaglio.url"
                  aspect-ratio="1"
                  class="white lighten-2 center"
                  v-on:click="DescrizioneProdotto()"
                >
                  <template v-slot:placeholder>
                    <v-row class="fill-height ma-0" align="center" justify="center">
                      <v-progress-circular
                        indeterminate
                        color="black lighten-5"
                      ></v-progress-circular>
                    </v-row>
                  </template>
                </v-img>
              </v-col>

              <v-col style="z-index: 10; margin-left: -20%; margin-top: 90%" cols="3">
                <div style="width: 12em">
                  <h4 v-if="dettaglio.cdCompletoProdottoDettaglio == ''">
                    {{ dettaglio.cdCompletoProdotto }}
                  </h4>
                  <h4 v-else>
                    {{ dettaglio.cdCompletoProdottoDettaglio }}
                  </h4>
                </div>
                <div style="width: 12em; font-size: 12px">
                  <span v-html="dettaglio.secondaDescrizione"></span>
                </div>
              </v-col>
            </v-row>

            <!-- PRODOTTO SI SCORREVOLI -->
            <v-row
              v-if="dettaglioTipo.substring(0, 1) == '5' || dettaglioTipo == 'Scorrevoli'"
              style="width: 100%"
            >
              <v-col cols="9">
                <!-- v-on:click="DescrizioneProdotto(), getImgComposizione(dettaglio)" -->
                <v-img
                  style="
                    width: auto;
                    height: 410px;
                    margin-top: 0px;
                    cursor: pointer;
                    padding-bottom: 0px;
                    padding-top: 0px;
                  "
                  :src="dettaglio.url"
                  :lazy-src="dettaglio.url"
                  aspect-ratio="1"
                  class="white lighten-2 center"
                  v-on:click="DescrizioneProdotto()"
                >
                  <template v-slot:placeholder>
                    <v-row class="fill-height ma-0" align="center" justify="center">
                      <v-progress-circular
                        indeterminate
                        color="black lighten-5"
                      ></v-progress-circular>
                    </v-row>
                  </template>
                </v-img>
              </v-col>

              <v-col style="z-index: 10; margin-left: -20%; margin-top: 80%" cols="3">
                <div style="width: 12em">
                  <h4 v-if="dettaglio.cdCompletoProdottoDettaglio == ''">
                    {{ dettaglio.cdCompletoProdotto }}
                  </h4>
                  <h4 v-else>
                    {{ dettaglio.cdCompletoProdottoDettaglio }}
                  </h4>
                </div>
                <div style="width: 12em; font-size: 12px">
                  <span v-html="dettaglio.secondaDescrizione"></span>
                </div>
              </v-col>
            </v-row>
          </v-card>

          <!-- IMMAGINI COMPOSIZIONI -->
          <v-sheet
            v-if="dettaglioTipo.substring(0, 1) != '4' && dettaglioTipo != 'Alzanti'"
            elevation="0"
            max-width="100%"
            style="margin-top: -15%; margin-left: -5%; margin-right: -5%"
          >
            <v-slide-group show-arrows v-model="this.getDati">
              <v-slide-item class="pa-0" v-for="(item, i) in getDati" :key="i">
                <v-img
                  v-on:click="getDettComposizione(item)"
                  style="
                    width: 90px;
                    height: 140px;
                    margin-top: 0px;
                    cursor: pointer;
                    margin-top: 0px;
                    padding: 0px;
                    padding-bottom: 0px;
                    padding-top: 0px;
                    padding-left: 0px;
                    padding-right: 0px;
                  "
                  :src="item.url"
                  :lazy-src="item.url"
                  aspect-ratio="1"
                  class="ma-1"
                >
                  <template v-slot:placeholder>
                    <v-row class="fill-height ma-0" align="center" justify="center">
                      <v-progress-circular
                        indeterminate
                        color="black lighten-5"
                      ></v-progress-circular>
                    </v-row>
                  </template>
                </v-img>
              </v-slide-item>
            </v-slide-group>
          </v-sheet>
        </v-col>
      </v-col>
    </v-row>

    <!-- VISTA PRODOTTO 360° -->
    <dialog-angolare :dialog="dialogAngolare" @chiudiDialog="vistaAngolare()">
    </dialog-angolare>

    <!-- SOTTO COMPOSIZIONI -->
    <img-prodotto
      :dialog="dialogProdotto"
      :src="src"
      :nomeDelProdotto="nomeDelProdotto"
      @chiudiDialog="imgProdottoDetttaglio(dettaglio)"
    >
    </img-prodotto>

    <!-- SCHEDA TECNICA -->
    <pdf-viewer
      :dialog="dialog"
      :src="src"
      :type="type"
      :nomeDelProdotto="nomeDelProdotto"
      @chiudi="chiudi(dettaglio)"
    >
    </pdf-viewer>
  </div>
</template>

<script>
import {
  get_prodotti,
  get_dettFinitura,
  get_composizione,
  get_dettComposizione,
  get_downloadPdf,
  get_downloadImgComposizione,
} from "@/api/api.prodotti.js";

import PdfViewer from "@/components/Dialog/PdfViewer.vue";
import DialogAngolare from "@/components/Dialog/DialogAngolare.vue";
import ImgProdotto from "@/components/Dialog/ImgProdotto.vue";

export default {
  components: {
    DialogAngolare,
    ImgProdotto,
    PdfViewer,
  },

  data: () => ({
    //liste
    datiFinitureStandard: [],
    datiFiniturePvd: [],
    datiFinitureRetro: [],
    datiComposizioni: [],
    listaSupp: [],

    //finitura / composizione
    dettaglio: {},
    codice: "",
    tipoFinituraComposizione: "",
    tipoProdotto: "",
    tipoFinituraComposizioni: "",
    flagSecondaDescrizione: false,
    nomeProdottoSupp: "",
    dettaglioTipo: "",
    descrizioneProdotto: "",
    novitaProdotto: "",
    disponibilitaProdotto: "",
    restyling: "",

    //nome del menu
    filtriMenu: {
      nomeProdotto: "",
      nomeProdotto2: "",
      nomeProdotto3: "",
      text: "",
      url: "",
    },
    //codice in descrizione
    codTestataDesc: "",
    //carosello img composizioni
    snackbar: false,
    //scrollbar composizioni
    benched: 12,

    //dialog
    dialogAngolare: false,
    dialogProdotto: false,
    dialog: false,
    src: "",
    type: "pdf",

    //img prodotto
    nomeDelProdotto: "",
    cartella: "",
    composizioneNome: "",
    extantionNome: "",

    //download scheda tecnica
    tipo: "",
    tipoComposizione: "",

    imgComposizione: "",

    //carosello immagini dettaglio prodotto
    model: 0,
    /* items: [
      {
        src:
          "http://www.ecolineamaniglie.com/immagini/HomeProva/10125_Sochi_Q_RB_02.734.jpg",
      },
      {
        src:
          "http://www.ecolineamaniglie.com/immagini/HomeProva/10125_Sochi_Q_RB_02.735.jpg",
      },
      {
        src:
          "http://www.ecolineamaniglie.com/immagini/HomeProva/10125_Sochi_Q_RB_02.736.jpg",
      },
      {
        src:
          "http://www.ecolineamaniglie.com/immagini/HomeProva/10125_Sochi_Q_RB_02.737.jpg",
      },
      {
        src:
          "http://www.ecolineamaniglie.com/immagini/HomeProva/10125_Sochi_Q_RB_02.738.jpg",
      },
    ], */
  }),

  methods: {
    //carico prodotto dettaglio con finiture e composizioni
    async caricaDati() {
      //this.filtriMenu.url = window.location.pathname;
      this.datiProdotti = [];
      this.datiFinitureStandard = [];
      this.datiFiniturePvd = [];
      this.datiFinitureRetro = [];

      //composizioni
      this.GetComposizioni();

      //per ricerca prodotto
      if (this.listaSupp > 0 || this.listaSupp != "") {
        //prodotto + finiture (tutte)
        this.datiProdotti = this.listaSupp.listaProdotti;
        this.datiFinitureStandard = this.listaSupp.listaFinitureStandard;
        this.datiFiniturePvd = this.listaSupp.listaFiniturePvd;
        this.datiFinitureRetro = this.listaSupp.listaFinitureRetro;
      } else {
        //per dettaglio prodotto normale
        //prodotto + finiture (tutte)
        this.filtriMenu.text = this.dettaglio.tipo.substring(
          2,
          this.dettaglio.tipo.lenght
        );

        let res = await get_prodotti(this.filtriMenu);

        this.datiProdotti = res.data.listaProdotti;
        this.datiFinitureStandard = res.data.listaFinitureStandard;
        this.datiFiniturePvd = res.data.listaFiniturePvd;
        this.datiFinitureRetro = res.data.listaFinitureRetro;
      }
    },

    async GetComposizioni() {
      if (this.tipoFinituraComposizioni != "")
        this.dettaglio.tipoFinitura = this.tipoFinituraComposizioni;

      this.dettaglio.nome = this.composizioneNome;
      let res1 = await get_composizione(this.dettaglio);
      this.datiComposizioni = res1.data;

      //controllo per caricare solo la prima volta la seconda descrizione
      //le altre volte prendo solo quella della composizione selezionata senza finitura
      if (this.flagSecondaDescrizione == false) {
        if (this.dettaglio.tipo.substring(0, 1) != "5") {
          //controllo per Scorrevoli
          //codice prodotto
          this.dettaglio.cdCompletoProdotto = this.datiComposizioni[0].cdCompletoProdotto;
          this.dettaglio.cdCompletoProdottoDettaglio = this.datiComposizioni[0].cdCompletoProdottoDettaglio;

          //seconda descrizione prodotto
          this.dettaglio.secondaDescrizione = this.datiComposizioni[0].secondaDescrizione;
          this.dettaglio.secondaDescrizione = this.convertiTestoGrassetto(
            this.dettaglio.secondaDescrizione
          );

          this.flagSecondaDescrizione = true;
        } else {
          //codice prodotto
          this.dettaglio.cdCompletoProdotto = this.dettaglio.cdCompleto;
          this.dettaglio.cdCompletoProdottoDettaglio = this.dettaglio.cdCompleto;

          //seconda descrizione prodotto
          this.datiComposizioni.forEach((element) => {
            if (element.suffisso == this.dettaglio.suffisso) {
              this.dettaglio.secondaDescrizione = element.secondaDescrizione;
            }
          });

          //this.dettaglio.secondaDescrizione = this.datiComposizioni[0].secondaDescrizione;
          this.dettaglio.secondaDescrizione = this.convertiTestoGrassetto(
            this.dettaglio.secondaDescrizione
          );

          this.flagSecondaDescrizione = true;
        }
      }
    },

    //colore prodotto finitura
    async getDettFiniture(dettaglio) {
      this.tipoFinituraComposizione = dettaglio.codiceFinitura;
      dettaglio.tipoProdotto = this.tipoProdotto;
      dettaglio.tipo = this.tipoProdotto;
      //dettaglio.tipo = this.dettaglioTipo;

      this.tipoFinituraComposizioni = dettaglio.tipoFinitura;
      dettaglio.nome = this.composizioneNome;

      //se utente clicca subito finitura di maniglione / alzante
      if (dettaglio.tipo == null || dettaglio.tipo == "") {
        dettaglio.tipo = this.datiComposizioni[0].tipoProdotto;
        dettaglio.tipoProdotto = this.datiComposizioni[0].tipoProdotto;
      }

      let res = await get_dettFinitura(dettaglio);
      this.dettaglio = res.data;
      this.dettaglio.tipo = this.cartella;

      this.GetComposizioni();
    },

    //colore prodotto composizione
    async getDettComposizione(dettaglio) {
      if (dettaglio != undefined) {
        if (dettaglio.nomeComposizione) {
          this.tipoProdotto = dettaglio.tipoProdotto;
          if (this.tipoFinituraComposizione != "")
            dettaglio.codiceFinitura = this.tipoFinituraComposizione;

          dettaglio.tipo = this.filtriMenu.text;
          dettaglio.nome = this.composizioneNome;

          let res = await get_dettComposizione(dettaglio);
          this.dettaglio = res.data;
        }
      }
    },

    //filtro finiture 'Standard' per prodotto
    getDatiFinitureStandard(dettaglio) {
      let res = this.datiFinitureStandard;
      //if (dettaglio.tipo.substring(0, 1) == "5") {
      if (this.filtriMenu.text.substring(0, 1) == "S") {
        //controllo per Kit Scorrevoli (necessita del filtro 'Suffisso')
        res = res.filter(
          (f) =>
            f.codTestata == dettaglio.codTestata &&
            f.nomeProdotto == dettaglio.nomeProdotto &&
            f.suffisso == dettaglio.suffisso
        );
      } else {
        res = res.filter(
          (f) =>
            f.codTestata == dettaglio.codTestata &&
            f.nomeProdotto == dettaglio.nomeProdotto
        );
      }

      return res;
    },

    //filtro finiture 'Pvp' per prodotto
    getDatiFiniturePvd(dettaglio) {
      let res = this.datiFiniturePvd;
      //if (dettaglio.tipo.substring(0, 1) == "5") {
      if (this.filtriMenu.text.substring(0, 1) == "S") {
        //controllo per Kit Scorrevoli (necessita del filtro 'Suffisso')
        res = res.filter(
          (f) =>
            f.codTestata == dettaglio.codTestata &&
            f.nomeProdotto == dettaglio.nomeProdotto &&
            f.suffisso == dettaglio.suffisso
        );
      } else {
        res = res.filter(
          (f) =>
            f.codTestata == dettaglio.codTestata &&
            f.nomeProdotto == dettaglio.nomeProdotto
        );
      }

      return res;
    },

    //filtro finiture 'Retro' per prodotto
    getDatiFinitureRetro(dettaglio) {
      let res = this.datiFinitureRetro;
      //if (dettaglio.tipo.substring(0, 1) == "5") {
      if (this.filtriMenu.text.substring(0, 1) == "S") {
        //controllo per Kit Scorrevoli (necessita del filtro 'Suffisso')
        res = res.filter(
          (f) =>
            f.codTestata == dettaglio.codTestata &&
            f.nomeProdotto == dettaglio.nomeProdotto &&
            f.suffisso == dettaglio.suffisso
        );
      } else {
        res = res.filter(
          (f) =>
            f.codTestata == dettaglio.codTestata &&
            f.nomeProdotto == dettaglio.nomeProdotto
        );
      }

      return res;
    },

    //per Maniglioni - Alzanti visualizzazione telefono
    getDatiFinitureTelefono(dettaglio) {
      //lista all finiture
      let listaTot = [];
      this.listaFinitureScorrevoli = [];

      let res = this.datiFinitureStandard;
      let res1 = this.datiFiniturePvd;
      let res2 = this.datiFinitureRetro;

      //STANDARD
      if (this.filtriMenu.text.substring(0, 1) == "S") {
        //controllo per Kit Scorrevoli (necessita del filtro 'Suffisso')
        res = res.filter(
          (f) =>
            f.codTestata == dettaglio.codTestata &&
            f.nomeProdotto == dettaglio.nomeProdotto &&
            f.suffisso == dettaglio.suffisso
        );
      } else {
        res = res.filter(
          (f) =>
            f.codTestata == dettaglio.codTestata &&
            f.nomeProdotto == dettaglio.nomeProdotto
        );
      }

      //PVD
      if (this.filtriMenu.text.substring(0, 1) == "S") {
        //controllo per Kit Scorrevoli (necessita del filtro 'Suffisso')
        res1 = res1.filter(
          (f) =>
            f.codTestata == dettaglio.codTestata &&
            f.nomeProdotto == dettaglio.nomeProdotto &&
            f.suffisso == dettaglio.suffisso
        );
      } else {
        res1 = res1.filter(
          (f) =>
            f.codTestata == dettaglio.codTestata &&
            f.nomeProdotto == dettaglio.nomeProdotto
        );
      }

      //RETRO'
      if (this.filtriMenu.text.substring(0, 1) == "S") {
        //controllo per Kit Scorrevoli (necessita del filtro 'Suffisso')
        res2 = res2.filter(
          (f) =>
            f.codTestata == dettaglio.codTestata &&
            f.nomeProdotto == dettaglio.nomeProdotto &&
            f.suffisso == dettaglio.suffisso
        );
      } else {
        res2 = res2.filter(
          (f) =>
            f.codTestata == dettaglio.codTestata &&
            f.nomeProdotto == dettaglio.nomeProdotto
        );
      }

      //unisco tutte le liste finiture
      listaTot = res.concat(res1.concat(res2));

      return listaTot;
    },

    DescrizioneProdotto() {
      //SNACKBAR
      if (this.snackbar == true) {
        this.snackbar = false;
      } else if (this.snackbar == false) {
        if (this.dettaglio.descrizione != null) {
          this.snackbar = true;
        } else {
          this.$toast.info("Nessuna descrizione disponibile.");
        }
      } else if (this.snackbar == null) {
        this.snackbar = false;
      }
    },

    isTelefono() {
      return window.innerWidth;
      //return this.$i2kFunctions.isSmartphone();
    },

    //VISTA 3D PRODOTTO
    vistaAngolare() {
      if (this.dialogAngolare == true) {
        this.dialogAngolare = false;
      } else {
        this.dialogAngolare = true;
      }
    },

    //DOWNMLOAD IMG PRODOTTO /SOTTO COMPOSIZIONI
    imgProdottoDetttaglio(dettaglio) {
      if (this.dialogProdotto == true) {
        this.dialogProdotto = false;
      } else {
        this.nomeDelProdotto = dettaglio.tipoProdotto;
        this.src = dettaglio.url;
        this.dialogProdotto = true;
      }
    },

    //SCHEDA TECNICA
    async chiudi(dettaglio) {
      if (this.dialog == true) {
        this.dialog = false;
      } else {
        this.dettaglio.tipo = this.tipo; //se vuoto per sicurezza
        this.dettaglio.tipoComposizione = this.tipoComposizione; //se vuoto per sicurezza

        let res = await get_downloadPdf(
          dettaglio.tipo +
            "/" +
            dettaglio.tipoComposizione +
            "/" +
            dettaglio.nomeProdotto,
          dettaglio
        );

        this.src = res.data;

        if (res.data != null) {
          this.dialog = true;
        }
      }
    },

    //DOWNLOAD SINGOLA IMG COMPOSIZIONE
    async getImgComposizione(dettaglio) {
      this.dettaglio.tipo = this.tipo; //se vuoto per sicurezza
      this.dettaglio.tipoComposizione = this.tipoComposizione; //se vuoto per sicurezza

      let res = await get_downloadImgComposizione(
        dettaglio.tipo + "/" + dettaglio.tipoComposizione + "/" + dettaglio.nomeProdotto,
        dettaglio
      );
      this.imgComposizione = res.data;

      window.open(res.data);
    },

    //grassetto testo
    convertiTestoGrassetto(valore) {
      let firstvariable = '"';
      let secondvariable = '"';

      let regExString = new RegExp(
        "(?:" + firstvariable + ")(.*?)(?:" + secondvariable + ")",
        "ig"
      );

      let testRE = regExString.exec(valore);

      if (testRE && testRE.length > 1) {
        valore = valore.replace(
          firstvariable + testRE[1] + secondvariable,
          "<b>" + testRE[1] + "</b><br>"
        );

        // Controllo ricorsione
        if (valore.includes(firstvariable)) valore = this.convertiTestoGrassetto(valore);
      }

      // Sostituisce underscore
      if (valore) valore = valore.replaceAll("_", "&nbsp;").replaceAll("#", "&nbsp;");

      return valore;
    },

    //URL immagini
    getUrlImmagini() {
      console.log("!");
      return this.$load.getUrlDettProd() + "/immagini";
    },
  },

  created() {
    //this.filtriMenu = this.$store.getters["app/getFiltriMenu"];
    /* this.filtriMenu.text = this.$store.getters["app/getNomeMenu"];
    this.filtriMenu.nomeProdotto = this.$store.getters["app/getNomeRicerca"]; */
    this.filtriMenu.url = "/dettaglio";
    this.dettaglio = this.$store.getters["app/getDettaglio"];
    //this.listaSupp = this.$store.getters["app/getListaRicerca"];

    //per composizioni tipo
    switch (this.filtriMenu.text) {
      case "Ricerca":
        // extantionNome = Maniglie / 1_Maniglie
        //filtriMenu.text = Tutti i Modelli / Maniglie4
        //this.extantionNome = this.dettaglio.tipo;
        if (this.extantionNome.substring(0, 1) == "1") {
          this.filtriMenu.text = "Maniglie";
        } else {
          this.filtriMenu.text = "Maniglie";
        }
        break;
      case "Tutti i Modelli":
        //this.extantionNome = this.dettaglio.tipo;
        break;
      case "Maniglie":
        this.dettaglio.tipo = "1_Maniglie";
        //this.extantionNome = this.dettaglio.tipo;
        break;
      case "Pomoli":
        this.dettaglio.tipo = "2_Pomoli";
        //this.extantionNome = this.dettaglio.tipo;
        break;
      case "Maniglioni":
        this.dettaglio.tipo = "3_Maniglioni";
        //this.extantionNome = this.dettaglio.tipo;
        break;
      case "Alzanti":
        this.dettaglio.tipo = "4_Alzanti";
        //this.extantionNome = this.dettaglio.tipo;
        break;
      case "Scorrevoli":
        this.dettaglio.tipo = "5_Scorrevoli";
        //this.extantionNome = this.dettaglio.tipo;
        break;
      case "Coordinati":
        this.dettaglio.tipo = "6_Coordinati";
        //this.extantionNome = this.dettaglio.tipo;
        break;
      /* case "Accessori":
        this.dettaglio.tipo = "6_Accessori";
        this.extantionNome = this.dettaglio.tipo;
        break; */
      case "Quadro":
        this.dettaglio.tipo = "Quadro";
        //this.extantionNome = this.dettaglio.tipo;
        break;
      case "Tondo":
        this.dettaglio.tipo = "Tondo";
        //this.extantionNome = this.dettaglio.tipo;
        break;
      case "Retrò":
        this.dettaglio.tipo = "Retrò";
        //this.extantionNome = this.dettaglio.tipo;
        break;
      case "Standard":
        this.dettaglio.tipo = "Standard";
        //this.extantionNome = this.dettaglio.tipo;
        break;
      case "Pvd":
        this.dettaglio.tipo = "Pvd";
        //this.extantionNome = this.dettaglio.tipo;
        break;
      case "Retro":
        this.dettaglio.tipo = "Retro";
        //this.extantionNome = this.dettaglio.tipo;
        break;
    }

    this.extantionNome = this.dettaglio.tipo;
    this.composizioneNome = this.dettaglio.nome;

    //PER CONTROLLI MANIGLIONI E ALZANTI
    this.$store.commit("app/setDettaglioTipo", this.dettaglio.tipo);
    this.dettaglioTipo = this.$store.getters["app/getDettaglioTipo"];

    //DESCRIZIONE DEL PRODOTTO
    this.$store.commit("app/setDescrizioneProdotto", this.dettaglio.descrizione);
    this.descrizioneProdotto = this.$store.getters["app/getDescrizioneProdotto"];

    //NOVITA' DEL PRODOTTO
    this.novitaProdotto = this.dettaglio.novita;
    this.disponibilitaProdotto = this.dettaglio.disponibileProssimamente;
    this.restyling = this.dettaglio.restyling;
  },

  mounted() {
    //per percorso immagine in cartella (costruisco url per immagine)
    this.cartella = "";

    switch (this.filtriMenu.text) {
      case "Maniglie":
        this.cartella = "1_Maniglie";
        break;
      case "Pomoli":
        this.cartella = "2_Pomoli";
        break;
      case "Maniglioni":
        this.cartella = "3_Maniglioni";
        break;
      case "Alzanti":
        this.cartella = "4_Alzanti";
        break;
      case "Scorrevoli":
        this.cartella = "5_Scorrevoli";
        break;
      case "Coordinati":
        this.cartella = "6_Coordinati";
        break;
      /* case "Accessori":
        this.cartella = "6_Accessori";
        break; */
      case "Quadro":
        this.cartella = "Quadro";
        break;
      case "Tondo":
        this.cartella = "Tondo";
        break;
      case "Retrò":
        this.cartella = "Retrò";
        break;
      case "Standard":
        this.cartella = "Standard";
        break;
      case "Pvd":
        this.cartella = "Pvd";
        break;
      case "Retro":
        this.cartella = "Retro";
        break;
    }

    //per menu TUTTO
    if (this.cartella == "") {
      switch (this.dettaglio.tipo) {
        case "Maniglie":
          this.cartella = "1_Maniglie";
          this.dettaglio.tipo = "1_Maniglie";
          break;
        case "Pomoli":
          this.cartella = "2_Pomoli";
          this.dettaglio.tipo = "2_Pomoli";
          break;
        case "Maniglioni":
          this.cartella = "3_Maniglioni";
          this.dettaglio.tipo = "3_Maniglioni";
          break;
        case "Alzanti":
          this.cartella = "4_Alzanti";
          this.dettaglio.tipo = "4_Alzanti";
          break;
        case "Scorrevoli":
          this.cartella = "5_Scorrevoli";
          this.dettaglio.tipo = "5_Scorrevoli";
          break;
        case "Coordinati":
          this.cartella = "5_Coordinati";
          this.dettaglio.tipo = "5_Coordinati";
          break;
        /* case "Accessori":
          this.cartella = "6_Accessori";
          this.dettaglio.tipo = "6_Accessori";
          break; */
      }
    }

    var ff = this.dettaglio.tipoProdotto.split("-");
    var supporto = "";
    if (
      this.dettaglio.tipo.substring(0, 1) == "3" ||
      this.dettaglio.tipo.substring(0, 1) == "4" ||
      this.dettaglio.tipo.substring(0, 1) == "5" ||
      this.dettaglio.tipo.substring(0, 1) == "6"
    ) {
      //Setto tipoProdotto (per url) nel caso di Maniglioni e Alzanti
      let codiceSupp = "";
      if (this.dettaglio.tipo.substring(0, 1) == "3") {
        //MANIGLIONI
        switch (this.dettaglio.nomeProdotto) {
          case "Ayr":
            codiceSupp = "04";
            break;
          case "Belfast":
            codiceSupp = "04-00";
            break;
          case "Bordeaux":
            codiceSupp = "02";
            break;
          case "Brera":
            codiceSupp = "03";
            break;
          case "Brera Q":
            codiceSupp = "03";
            break;
          case "Bristol":
            codiceSupp = "02-00";
            break;
          case "Derby":
            codiceSupp = "02-00";
            break;
          case "Dover":
            codiceSupp = "04-00";
            break;
          case "Glasgow":
            codiceSupp = "02";
            break;
          case "Kiel":
            codiceSupp = "04";
            break;
          case "Lahti":
            codiceSupp = "04-00";
            break;
          case "Toledo":
            codiceSupp = "03-00";
            break;
          case "Ginevra":
            codiceSupp = "02-00";
            break;
          case "Ginevra Zancato":
            codiceSupp = "02-00";
            break;
        }
        this.dettaglio.tipoProdotto = ff[0] + "-" + ff[2] + "-" + codiceSupp;
      } else if (this.dettaglio.tipo.substring(0, 1) == "4") {
        //ALZANTI
        switch (this.dettaglio.nomeProdotto) {
          case "Brera":
            codiceSupp = "00";
            break;
          case "Brera Q":
            codiceSupp = "00";
            break;
          case "Bristol":
            codiceSupp = "00";
            break;
          case "Ginevra":
            codiceSupp = "00";
            break;
          case "Glasgow":
            codiceSupp = "00";
            break;
          case "Leeds":
            codiceSupp = "00";
            break;
          case "Lisbona":
            codiceSupp = "00";
            break;
          case "Sochi":
            codiceSupp = "00";
            break;
          case "Sochi Q":
            codiceSupp = "00";
            break;
        }
        this.dettaglio.tipoProdotto = ff[0] + "-" + ff[2] + "-" + codiceSupp;
      } else if (this.dettaglio.tipo.substring(0, 1) == "5") {
        //SCORREVOLI
        var fff = this.dettaglio.tipoProdotto.split(" ");
        var ffff = [];
        if (fff[3] != undefined) {
          ffff = fff[3].split("-");
          codiceSupp = ffff[2];
          this.dettaglio.tipoProdotto =
            this.dettaglio.nomeProdotto +
            "_ECO " +
            ffff[0] +
            "-" +
            ffff[1] +
            "-" +
            codiceSupp;
        } else {
          ffff = fff[2].split("-");
          codiceSupp = ffff[2];
          this.dettaglio.tipoProdotto =
            this.dettaglio.nomeProdotto +
            "_ECO " +
            ffff[0] +
            "-" +
            ffff[1] +
            "-" +
            codiceSupp;
        }
      } /* else{
        //COORDINATI E ACCESSORI

        this.dettaglio.tipoProdotto = ff[0] + "-" + ff[2] + "-" + codiceSupp;
      } */

      //this.dettaglio.tipoProdotto = ff[0] + "-" + ff[2] + "-" + codiceSupp;
    } else {
      //Setto tipoProdotto (per url) nel caso di Maniglie e Pomoli
      if (ff[2].lenght > 2) {
        ff[2] = ff[2].substring(0, 2);
        supporto = ff[2].substring(2, 4);
        this.dettaglio.tipoProdotto = ff[0] + "-" + ff[2] + "-" + supporto + "-" + "01";
      } else {
        this.dettaglio.tipoProdotto = ff[0] + "-" + ff[2] + "-" + "01";
      }
    }

    //inverto suffisso con codiceFinitura e costruisco URL img
    this.nomeDelProdotto =
      this.dettaglio.nomeProdotto + "_" + this.dettaglio.codTestata.substring(3, 8);

    //controllo per url img in RICERCA PRODOTTO
    if (
      (this.$store.getters["app/getUrlRicerca"] != "" &&
        this.dettaglio.tipoComposizione == null) ||
      this.dettaglio.tipoComposizione == ""
    ) {
      switch (this.dettaglio.nome) {
        case "Quadra":
          this.dettaglio.tipoComposizione = "Quadro";
          break;
        case "Tonda":
          this.dettaglio.tipoComposizione = "Tondo";
          break;
        case "Retrò":
          this.dettaglio.tipoComposizione = "Retrò";
          break;
      }
    }

    //URl
    if (
      this.dettaglio.tipo.substring(0, 1) == "1" ||
      this.dettaglio.tipo.substring(0, 1) == "2"
    ) {
      this.dettaglio.url =
        this.getUrlImmagini() +
        "/PRODOTTI_COMPOSIZIONI/" +
        this.cartella +
        "/" +
        this.dettaglio.tipoComposizione +
        "/" +
        this.nomeDelProdotto +
        "/" +
        this.dettaglio.tipoProdotto +
        ".png";
    } else {
      this.dettaglio.url =
        this.getUrlImmagini() +
        "/PRODOTTI_COMPOSIZIONI/" +
        this.cartella +
        "/" +
        this.nomeDelProdotto +
        "/" +
        this.dettaglio.tipoProdotto +
        ".png";
    }

    this.tipo = this.dettaglio.tipo;
    this.tipoComposizione = this.dettaglio.tipoComposizione;

    this.$store.commit("app/setDettaglio", this.dettaglio);

    this.$store.commit("app/setUrlRicerca", "");

    this.caricaDati();
  },

  computed: {
    getDati() {
      let res = this.datiComposizioni;

      return res;
    },
  },

  watch: {
    dettaglio() {
      this.dettaglio.secondaDescrizione = this.convertiTestoGrassetto(
        this.dettaglio.secondaDescrizione
      );

      return this.dettaglio;
    },

    imgComposizione() {
      return this.imgComposizione;
    },
  },
};
</script>

<style>
/* width */
::-webkit-scrollbar {
  width: 5px;
  height: 200px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #e40520;
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #e40520;
}

.v-carousel__controls__item {
  color: #e40520 !important;
}
</style>
