
import { get_datiUserInfo } from "@/api/api.user.js";


const defaultState = () => {
  return {
    user: '',
    role: 0,
    group: '',
    carrello: [],
    descUser: '',
    menuIntranet: {},
    menuCom: {},
    numInterno: '',
    internoSel: '',
    statoInterno: ''
  }
}

const user = {
  namespaced: true,

  state: defaultState(),

  mutations: {
    SET_USER: (state, user) => {
      state.user = user.username
      state.role = user.roleId
      state.group = user.groupId
      state.carrello = user.carrello
      state.descUser = user.ragioneSociale
      state.menuIntranet = user.menu
      state.numInterno = user.numTelInterno
    },

    RESET_USER: (state) => {
      Object.assign(state, defaultState())
    },

    SET_CARRELLO: (state, items) => {
      state.carrello = items
    },

    SET_STATOINTERNO: (state, item) => {
      state.statoInterno = item
    },

    SET_INTERNOSEL: (state, item) => {
      state.internoSel = item
    },

    SET_MENUCOM: (state, item) => {
      state.menuCom = item
    }

  },

  actions: {

    userInfo({
      commit,
      dispatch
    }) {

      return new Promise((resolve, reject) => {

        get_datiUserInfo().then(resp => {

          commit("SET_USER", resp.data)

          resolve(resp)

        }).catch(err => {
          reject(err)
        })
      })
    },
  },

  getters: {
    getRole: state => state.role,
    getGroup: state => state.group,
    getUser: state => state.user,
    getMenu: state => state.menuIntranet,
    getMenuCom: state => state.menuCom,
    getDescUser: state => state.descUser,
    getNumInterno: state => state.numInterno,
    getInternoSel: state => state.internoSel,
    getStatoInterno: state => state.statoInterno
  }
}


export default user