<template>
  <v-app id="inspire" lang="it" class="notraslate" traslate="no" content="notraslate">
    <v-main>
      <router-view></router-view>
    </v-main>

    <!-- Plugin -->
    <toast></toast>
    <confirm></confirm>
    <wait></wait>
  </v-app>
</template>

<script>
export default {
  name: "App",
};
</script>
