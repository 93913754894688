<template>
  <div>
    <!-- VISUALIZZAZIONE NO TELEFONO -->
    <v-col cols="12">
      <!-- SCHEDA TECNICA -->
      <v-tooltip top>
        <template v-slot:activator="{ on, attrs }">
          <v-img
            style="width: 45px; cursor: pointer"
            v-bind="attrs"
            v-on="on"
            v-on:click="chiudi()"
            :src="require('@/assets/img/Scheda Tecnica.png')"
          >
            <template v-slot:placeholder>
              <v-row class="fill-height ma-0" align="center" justify="center">
                <v-progress-circular
                  indeterminate
                  color="black lighten-5"
                ></v-progress-circular>
              </v-row>
            </template>
          </v-img>
        </template>
        <span>Scheda tecnica</span>
      </v-tooltip>
      <!-- VISTA 360 -->
      <!-- <v-tooltip left>
          <template v-slot:activator="{ on, attrs }">
            <v-img
              class="mt-7"
              style="width: 60px; margin-left: -0.5em; cursor: pointer"
              v-bind="attrs"
              v-on="on"
              v-on:click="vistaAngolare()"
              :src="require('@/assets/img/3d_.png')"
            >
              <template v-slot:placeholder>
                <v-row class="fill-height ma-0" align="center" justify="center">
                  <v-progress-circular
                    indeterminate
                    color="black lighten-5"
                  ></v-progress-circular>
                </v-row>
              </template>
            </v-img>
          </template>
          <span>Vista 360°</span>
        </v-tooltip> -->
      <!-- ESLPOSO -->
      <!-- <v-tooltip left>
          <template v-slot:activator="{ on, attrs }">
            <v-img
              style="width: 70px; margin-left: -0.5em; cursor: pointer"
              v-bind="attrs"
              v-on="on"
              v-on:click="vistaAngolare()"
              class="mt-4"
              :src="require('@/assets/img/Esploso.png')"
            >
              <template v-slot:placeholder>
                <v-row class="fill-height ma-0" align="center" justify="center">
                  <v-progress-circular
                    indeterminate
                    color="black lighten-5"
                  ></v-progress-circular>
                </v-row>
              </template>
            </v-img>
          </template>
          <span>Esploso</span>
        </v-tooltip> -->
    </v-col>
  </div>
</template>

<script>
export default {
  props: {},

  components: {},

  data: () => ({}),

  methods: {
    isTelefono() {
      // visualizzazione telefono
      return window.innerWidth;
    },

    chiudi() {
      this.$emit("chiudi");
    },

    vistaAngolare() {
      this.$emit("vistaAngolare");
    },
  },

  created() {},

  mounted() {},

  computed: {},
};
</script>
