export default {
    methods: {
        //MENU TUTTO
        getStiliMenuTutto(t, value) {
            //controll dimension display devices
            //fare proporzione tra una dimensione e l'altra per scalare tutti gli stili //2340 --> 4K = +50%
            t.stileRowProdotti = {}; //riga prodotti
            t.stileIconProdotti = {}; // icons prodotti
            t.stileColFinitureImg = {}; //colonna finiture
            t.stileExtationPanel = {}; //extation panel finiture
            t.stileCardProdotti = {}; // card prodotti
            t.stileImgProdottoNoManiglioneAlzante = {}; //img prodotti NO maniglioni / alzanti
            t.stileImgProdottoSiManiglioneAlzante = {}; //img prodotti SI maniglioni / alzanti
            t.stileParagrafoNoManiglioneAlzante = {}; //paragrafo NO maniglioni / alzanti
            t.stileParagrafoSiManiglioneAlzante = {}; //paragrafo SI maniglioni / alzanti
            t.stileLineaNoManiglioneAlzante = {}; //linea NO maniglioni / alzanti
            t.stileLineaSiManiglioneAlzante = {}; //linea SI maniglioni / alzanti
            t.stilePanelNoManiglioneAlzante = {}; //paragrafo extation panel NO maniglioni / alzanti
            t.stilePanelSiManiglioneAlzante = {}; //paragrafo extation panel SI maniglioni / alzanti
            t.stileFinitureStandardNoManiglioneAlzante = {}; //finiture standard NO maniglioni / alzanti
            t.stileFinitureStandardSiManiglioneAlzante = {}; //finiture standard SI maniglioni / alzanti
            t.stileFinitureStandardSiScorrevoli = {}; //finiture standard scorrevoli

            if (value <= 768) {
                //smartphone
                //img prodotto NO maniglioni / alzanti
                t.stileImgProdottoNoManiglioneAlzante = {
                    minWidth: "371px",
                    height: "auto",
                    cursor: "pointer",
                    backgroundColor: "white",
                    marginLeft: "-2em",
                    marginTop: "3.3em",
                    marginBottom: "6.3em",
                };
                //img prodotto SI maniglioni / alzanti
                t.stileImgProdottoSiManiglioneAlzante = {
                    height: "0px",
                    minWidth: "auto",
                    cursor: "pointer",
                    backgroundColor: "white",
                    marginLeft: "-2em",
                    marginTop: "0em",
                };
                //stile riga prodotti
                t.stileRowProdotti = {
                    marginTop: "-3em",
                    marginLeft: "0em",
                    marginRight: "0em",
                    width: "100%",
                    marginBottom: "0em",
                };
                //stile icon prodotti
                t.stileIconProdotti = {
                    width: "25px",
                    height: "25px",
                    marginTop: "0em",
                    marginLeft: "0em",
                };
                //stile Col Img fintire (standard / pvd / retrò)
                t.stileColFinitureImg = {
                    minWidth: "20%",
                    marginTop: "5%",
                };
                //stile extation panel finiture
                t.stileExtationPanel = {
                    width: "87%",
                    height: "0%",
                    marginTop: "-2em",
                };
                //stile card prodotti
                t.stileCardProdotti = {
                    marginBottom: "15em",
                    marginLeft: "0em",
                    height: "auto",
                };
                //stile img SI maniglioni / alzanti
                t.stileImgProdottoSiManiglioneAlzante.height = "500px";
                //stile paragrafo NO maniglioni / alzanti
                t.stileParagrafoNoManiglioneAlzante = {
                    marginTop: "-2em",
                    width: "300px",
                };
                //stile paragrafo SI maniglioni / alzanti
                t.stileParagrafoSiManiglioneAlzante = {
                    marginLeft: "-0.9em",
                    marginTop: "-2.2em",
                    width: "300px",
                };
                //paragrafo extation panel NO maniglioni / alzanti
                t.stilePanelNoManiglioneAlzante = {
                    marginTop: "6em",
                    marginLeft: "-4.5em",
                    fontSize: "11px",
                };
                //paragrafo extation panel SI maniglioni / alzanti
                t.stilePanelSiManiglioneAlzante = {
                    marginTop: "12em",
                    marginLeft: "-4.5em",
                    fontSize: "11px",
                };
                //linea NO maniglioni / alzanti
                t.stileLineaNoManiglioneAlzante = {
                    marginTop: "-1.5em",
                    marginLeft: "0em",
                    fontSize: "12px",
                };
                //linea SI maniglioni / alzanti
                t.stileLineaSiManiglioneAlzante = {
                    marginTop: "-1.5em",
                    marginLeft: "-1.5em",
                    fontSize: "12px",
                };
                //finiture standard NO maniglioni / alzanti
                t.stileFinitureStandardNoManiglioneAlzante = {
                    minWidth: "120%",
                    marginLeft: "-3.3em",
                    marginTop: "0em",
                };
                //finiture standard SI maniglioni / alzanti
                t.stileFinitureStandardSiManiglioneAlzante = {
                    width: "120%",
                    marginLeft: "-3.3em",
                    marginTop: "0em",
                };
                //finiture standard scorrevoli
                t.stileFinitureStandardSiScorrevoli = {
                    minWidth: "130%",
                    marginLeft: "-2.7em",
                    marginTop: "-0.5em",
                };
            } else if (value > 768 && value <= 1050) {
                //tablet (1024)
                //img prodotto NO maniglioni / alzanti
                t.stileImgProdottoNoManiglioneAlzante = {
                    minWidth: "371px",
                    height: "auto",
                    cursor: "pointer",
                    backgroundColor: "white",
                    marginLeft: "-2em",
                    marginTop: "3.3em",
                    marginBottom: "6.3em",
                };
                //img prodotto SI maniglioni / alzanti
                t.stileImgProdottoSiManiglioneAlzante = {
                    height: "0px",
                    minWidth: "auto",
                    cursor: "pointer",
                    backgroundColor: "white",
                    marginLeft: "-2em",
                    marginTop: "0em",
                };
                //stile riga prodotti
                t.stileRowProdotti = {
                    marginLeft: "2em",
                    marginRight: "2em",
                    marginTop: "-1.5em",
                    width: "100%",
                    marginBottom: "0em",
                };
                //stile icon prodotti
                t.stileIconProdotti = {
                    width: "25px",
                    height: "25px",
                    marginTop: "0em",
                    marginLeft: "0em",
                };
                //stile Img fintire (standard / pvd / retrò)
                t.stileColFinitureImg = {
                    minWidth: "20%",
                    marginTop: "2%",
                };
                //stile extation panel finiture
                t.stileExtationPanel = {
                    width: "90%",
                    height: "0%",
                    marginTop: "-1em",
                };
                //stile card prodotti
                t.stileCardProdotti = {
                    marginBottom: "10em",
                    marginLeft: "0em",
                    height: "auto",
                };
                //stile img SI maniglioni / alzanti
                t.stileImgProdottoSiManiglioneAlzante.height = "385px";
                //stile paragrafo NO maniglioni / alzanti
                t.stileParagrafoNoManiglioneAlzante = {
                    marginTop: "0em",
                    width: "300px",
                };
                //stile paragrafo SI maniglioni / alzanti
                t.stileParagrafoSiManiglioneAlzante = {
                    marginTop: "-2.2em",
                    marginLeft: "0em",
                    width: "300px",
                };
                //paragrafo extation panel NO maniglioni / alzanti
                t.stilePanelNoManiglioneAlzante = {
                    marginTop: "6em",
                    marginLeft: "-4.1em",
                    fontSize: "11px",
                };
                //paragrafo extation panel SI maniglioni / alzanti
                t.stilePanelSiManiglioneAlzante = {
                    marginTop: "6em",
                    marginLeft: "-2.3em",
                    fontSize: "11px",
                };
                //linea NO maniglioni / alzanti
                t.stileLineaNoManiglioneAlzante = {
                    marginTop: "-1.5em",
                    marginLeft: "0em",
                    fontSize: "12px",
                };
                //linea SI maniglioni / alzanti
                t.stileLineaSiManiglioneAlzante = {
                    marginTop: "-1.5em",
                    marginLeft: "0em",
                    fontSize: "12px",
                };
                //finiture standard NO maniglioni / alzanti
                t.stileFinitureStandardNoManiglioneAlzante = {
                    minWidth: "100%",
                    marginLeft: "-2.8em",
                    marginTop: "0em",
                };
                //finiture standard SI maniglioni / alzanti
                t.stileFinitureStandardSiManiglioneAlzante = {
                    width: "120%",
                    marginLeft: "-1.7em",
                    marginTop: "0em",
                };
                //finiture standard scorrevoli
                t.stileFinitureStandardSiScorrevoli = {
                    minWidth: "100%",
                    marginLeft: "-3em",
                    marginTop: "0.3em",
                };
            } else if (value > 1050 && value <= 1280) {
                //HD (16:9 / 16:10)
                //img prodotto NO maniglioni / alzanti
                t.stileImgProdottoNoManiglioneAlzante = {
                    minWidth: "371px",
                    height: "auto",
                    cursor: "pointer",
                    backgroundColor: "white",
                    marginLeft: "-2em",
                    marginTop: "3.3em",
                    marginBottom: "6.3em",
                };
                //img prodotto SI maniglioni / alzanti
                t.stileImgProdottoSiManiglioneAlzante = {
                    height: "0px",
                    minWidth: "auto",
                    cursor: "pointer",
                    backgroundColor: "white",
                    marginLeft: "-2em",
                    marginTop: "0em",
                };
                //stile riga prodotti
                t.stileRowProdotti = {
                    marginLeft: "2em",
                    marginRight: "2em",
                    marginTop: "-1em",
                    width: "100%",
                    marginBottom: "1em",
                };
                //stile icon prodotti
                t.stileIconProdotti = {
                    width: "25px",
                    height: "25px",
                    marginTop: "0em",
                    marginLeft: "0em",
                };
                //stile Img fintire (standard / pvd / retrò)
                t.stileColFinitureImg = {
                    minWidth: "20%",
                    marginTop: "2%",
                };
                //stile extation panel finiture
                t.stileExtationPanel = {
                    width: "90%",
                    height: "0%",
                    marginTop: "-1em",
                };
                //stile card prodotti
                t.stileCardProdotti = {
                    marginBottom: "10em",
                    marginLeft: "0em",
                    height: "auto",
                };
                //stile img SI maniglioni / alzanti
                t.stileImgProdottoSiManiglioneAlzante.height = "385px";
                //stile paragrafo NO maniglioni / alzanti
                t.stileParagrafoNoManiglioneAlzante = {
                    marginTop: "0em",
                    width: "300px",
                };
                //stile paragrafo SI maniglioni / alzanti
                t.stileParagrafoSiManiglioneAlzante = {
                    marginTop: "-2.2em",
                    marginLeft: "0em",
                    width: "300px",
                };
                //paragrafo extation panel NO maniglioni / alzanti
                t.stilePanelNoManiglioneAlzante = {
                    marginTop: "6em",
                    marginLeft: "-4.1em",
                    fontSize: "11px",
                };
                //paragrafo extation panel SI maniglioni / alzanti
                t.stilePanelSiManiglioneAlzante = {
                    marginTop: "6em",
                    marginLeft: "-2.3em",
                    fontSize: "11px",
                };
                //linea NO maniglioni / alzanti
                t.stileLineaNoManiglioneAlzante = {
                    marginTop: "-1.5em",
                    marginLeft: "0em",
                    fontSize: "12px",
                };
                //linea SI maniglioni / alzanti
                t.stileLineaSiManiglioneAlzante = {
                    marginTop: "-1.5em",
                    marginLeft: "0em",
                };
                //finiture standard NO maniglioni / alzanti
                t.stileFinitureStandardNoManiglioneAlzante = {
                    minWidth: "100%",
                    marginLeft: "-2.8em",
                    marginTop: "0em",
                };
                //finiture standard SI maniglioni / alzanti
                t.stileFinitureStandardSiManiglioneAlzante = {
                    width: "120%",
                    marginLeft: "-1.7em",
                    marginTop: "0em",
                };
                //finiture standard scorrevoli
                t.stileFinitureStandardSiScorrevoli = {
                    minWidth: "100%",
                    marginLeft: "-3em",
                    marginTop: "0.3em",
                };
            } else if (value > 1280 && value <= 1540) {
                //tablet apple / surface (windows)
                //img prodotto NO maniglioni / alzanti
                t.stileImgProdottoNoManiglioneAlzante = {
                    minWidth: "371px",
                    height: "auto",
                    cursor: "pointer",
                    backgroundColor: "white",
                    marginLeft: "-2em",
                    marginTop: "3.3em",
                    marginBottom: "6.3em",
                };
                //img prodotto SI maniglioni / alzanti
                t.stileImgProdottoSiManiglioneAlzante = {
                    height: "0px",
                    minWidth: "auto",
                    cursor: "pointer",
                    backgroundColor: "white",
                    marginLeft: "-2em",
                    marginTop: "0em",
                };
                //stile riga prodotti
                t.stileRowProdotti = {
                    marginLeft: "2em",
                    marginRight: "2em",
                    marginTop: "-2em",
                    width: "100%",
                    marginBottom: "0em",
                };
                //stile icon prodotti
                t.stileIconProdotti = {
                    width: "25px",
                    height: "25px",
                    marginTop: "0em",
                    marginLeft: "0em",
                };
                //stile Img fintire (standard / pvd / retrò)
                t.stileColFinitureImg = {
                    minWidth: "20%",
                    marginTop: "2%",
                };
                //stile extation panel finiture
                t.stileExtationPanel = {
                    width: "90%",
                    height: "0%",
                    marginTop: "-1em",
                };
                //stile card prodotti
                t.stileCardProdotti = {
                    marginBottom: "10em",
                    marginLeft: "0em",
                    height: "auto",
                };
                //stile img SI maniglioni / alzanti
                t.stileImgProdottoSiManiglioneAlzante.height = "385px";
                //stile paragrafo NO maniglioni / alzanti
                t.stileParagrafoNoManiglioneAlzante = {
                    marginTop: "0em",
                    width: "300px",
                };
                //stile paragrafo SI maniglioni / alzanti
                t.stileParagrafoSiManiglioneAlzante = {
                    marginLeft: "-1em",
                    marginTop: "-2.2em",
                    width: "300px",
                };
                //paragrafo extation panel NO maniglioni / alzanti
                t.stilePanelNoManiglioneAlzante = {
                    marginTop: "6em",
                    marginLeft: "-4.1em",
                    fontSize: "11px",
                };
                //paragrafo extation panel SI maniglioni / alzanti
                t.stilePanelSiManiglioneAlzante = {
                    marginTop: "6em",
                    marginLeft: "-4.1em",
                    fontSize: "11px",
                };
                //linea NO maniglioni / alzanti
                t.stileLineaNoManiglioneAlzante = {
                    marginTop: "-1.5em",
                    marginLeft: "0em",
                    fontSize: "12px",
                };
                //linea SI maniglioni / alzanti
                t.stileLineaSiManiglioneAlzante = {
                    marginTop: "-1.5em",
                    marginLeft: "-1.6em",
                    fontSize: "12px",
                };
                //finiture standard NO maniglioni / alzanti
                t.stileFinitureStandardNoManiglioneAlzante = {
                    minWidth: "100%",
                    marginLeft: "-2.8em",
                    marginTop: "0em",
                };
                //finiture standard SI maniglioni / alzanti
                t.stileFinitureStandardSiManiglioneAlzante = {
                    width: "120%",
                    marginLeft: "-2.8em",
                    marginTop: "0em",
                };
                //finiture standard scorrevoli
                t.stileFinitureStandardSiScorrevoli = {
                    minWidth: "100%",
                    marginLeft: "-3em",
                    marginTop: "0.3em",
                };
            } else if (value > 1540 && value <= 1920) {
                //classic pc FULL HD 16:9 / 	16:10 --> 1680
                //img prodotto NO maniglioni / alzanti
                t.stileImgProdottoNoManiglioneAlzante = {
                    minWidth: "371px",
                    height: "auto",
                    cursor: "pointer",
                    backgroundColor: "white",
                    marginLeft: "-2em",
                    marginTop: "3.3em",
                    marginBottom: "6.3em",
                };
                //img prodotto SI maniglioni / alzanti
                t.stileImgProdottoSiManiglioneAlzante = {
                    height: "0px",
                    minWidth: "auto",
                    cursor: "pointer",
                    backgroundColor: "white",
                    marginLeft: "-2em",
                    marginTop: "0em",
                };
                //stile riga prodotti
                t.stileRowProdotti = {
                    marginLeft: "3em",
                    marginRight: "2em",
                    marginTop: "-2em",
                    width: "100%",
                    marginBottom: "1em",
                };
                //stile icon prodotti
                t.stileIconProdotti = {
                    width: "30px",
                    height: "30px",
                    marginTop: "0em",
                    marginLeft: "0em",
                };
                //stile Img fintire (standard / pvd / retrò)
                t.stileColFinitureImg = {
                    minWidth: "20%",
                    marginTop: "2%",
                };
                //stile extation panel finiture
                t.stileExtationPanel = {
                    width: "90%",
                    height: "0%",
                    marginTop: "-1em",
                };
                //stile card prodotti
                t.stileCardProdotti = {
                    marginBottom: "10em",
                    marginLeft: "0em",
                    height: "auto",
                };
                //stile img SI maniglioni / alzanti
                t.stileImgProdottoSiManiglioneAlzante.height = "385px";
                //stile paragrafo NO maniglioni / alzanti
                t.stileParagrafoNoManiglioneAlzante = {
                    marginTop: "0em",
                    width: "300px",
                };
                //stile paragrafo SI maniglioni / alzanti
                t.stileParagrafoSiManiglioneAlzante = {
                    marginTop: "-2.2em",
                    width: "300px",
                    marginLeft: "-1em",
                };
                //paragrafo extation panel NO maniglioni / alzanti
                t.stilePanelNoManiglioneAlzante = {
                    marginTop: "6em",
                    marginLeft: "-4.1em",
                    fontSize: "11px",
                };
                //paragrafo extation panel SI maniglioni / alzanti
                t.stilePanelSiManiglioneAlzante = {
                    marginTop: "6em",
                    marginLeft: "-4.1em",
                    fontSize: "11px",
                };
                //linea NO maniglioni / alzanti
                t.stileLineaNoManiglioneAlzante = {
                    marginTop: "-1.5em",
                    marginLeft: "0em",
                    fontSize: "12px",
                };
                //linea SI maniglioni / alzanti
                t.stileLineaSiManiglioneAlzante = {
                    marginTop: "-1.5em",
                    marginLeft: "-1.6em",
                    fontSize: "12px",
                };
                //finiture standard NO maniglioni / alzanti
                t.stileFinitureStandardNoManiglioneAlzante = {
                    minWidth: "100%",
                    marginLeft: "-2.8em",
                    marginTop: "0em",
                };
                //finiture standard SI maniglioni / alzanti
                t.stileFinitureStandardSiManiglioneAlzante = {
                    width: "120%",
                    marginLeft: "-2.8em",
                    marginTop: "0em",
                };
                //finiture standard scorrevoli
                t.stileFinitureStandardSiScorrevoli = {
                    minWidth: "100%",
                    marginLeft: "-3em",
                    marginTop: "0.3em",
                };
            } else if (value > 1920 && value <= 2100) {
                //classic pc (16:10 / 16:9 / 21:9)
            } else if (value > 2100 && value <= 2560) {
                //pc monitor (16:9)
                //img prodotto NO maniglioni / alzanti
                t.stileImgProdottoNoManiglioneAlzante = {
                    minWidth: "500px",
                    height: "auto",
                    cursor: "pointer",
                    backgroundColor: "white",
                    marginLeft: "0em",
                    marginTop: "9.5em",
                    marginBottom: "0em",
                };
                //img prodotto SI maniglioni / alzanti
                t.stileImgProdottoSiManiglioneAlzante = {
                    height: "650px",
                    minWidth: "auto",
                    cursor: "pointer",
                    backgroundColor: "white",
                    marginLeft: "-1em",
                    marginTop: "-5em",
                };
                //stile riga prodotti
                t.stileRowProdotti = {
                    marginLeft: "7em",
                    marginRight: "0em",
                    marginTop: "-2em",
                    width: "95%",
                    marginBottom: "25em",
                };
                //stile card prodotti
                t.stileCardProdotti = {
                    marginBottom: "10em",
                    marginLeft: "5em",
                    height: "10em",
                };
                //stile paragrafo NO maniglioni / alzanti
                t.stileParagrafoNoManiglioneAlzante = {
                    marginTop: "4.5em",
                    marginLeft: "2em",
                    width: "390px",
                };
                //stile paragrafo SI maniglioni / alzanti
                t.stileParagrafoSiManiglioneAlzante = {
                    marginTop: "-3em",
                    marginLeft: "0em",
                    marginBottom: "1em",
                    width: "390px",
                };
                //stile icon prodotti
                t.stileIconProdotti = {
                    width: "30",
                    height: "30px",
                    marginTop: "0em",
                    marginLeft: "0em",
                };
                //linea NO maniglioni / alzanti
                t.stileLineaNoManiglioneAlzante = {
                    marginTop: "-1.5em",
                    marginLeft: "3.3em",
                    fontSize: "12px",
                };
                //linea SI maniglioni / alzanti
                t.stileLineaSiManiglioneAlzante = {
                    marginTop: "-1.5em",
                    marginLeft: "0em",
                    fontSize: "12px",
                };
                //paragrafo extation panel NO maniglioni / alzanti
                t.stilePanelNoManiglioneAlzante = {
                    marginTop: "32em",
                    marginLeft: "-6.5em",
                    fontSize: "11px",
                };
                //paragrafo extation panel SI maniglioni / alzanti
                t.stilePanelSiManiglioneAlzante = {
                    marginTop: "32em",
                    marginLeft: "-4em",
                    fontSize: "11px",
                };
                //stile extation panel finiture
                t.stileExtationPanel = {
                    width: "100%",
                    height: "0%",
                    marginTop: "-26em",
                };
                //stile Img fintire (standard / pvd / retrò)
                t.stileColFinitureImg = {
                    minWidth: "20%",
                    marginTop: "2%",
                };
                //finiture standard NO maniglioni / alzanti
                t.stileFinitureStandardNoManiglioneAlzante = {
                    minWidth: "100%",
                    marginLeft: "-4.5em",
                    marginTop: "0em",
                };
                //finiture standard SI maniglioni / alzanti
                t.stileFinitureStandardSiManiglioneAlzante = {
                    width: "120%",
                    marginLeft: "-2.8em",
                    marginTop: "0em",
                };
                //finiture standard scorrevoli
                t.stileFinitureStandardSiScorrevoli = {
                    minWidth: "100%",
                    marginLeft: "-4.5em",
                    marginTop: "0.3em",
                };
            } else if (value > 2560 && value <= 3840) {
                //TV 4K
                //img prodotto NO maniglioni / alzanti
                t.stileImgProdottoNoManiglioneAlzante = {
                    minWidth: "650px",
                    height: "auto",
                    cursor: "pointer",
                    backgroundColor: "white",
                    marginLeft: "0em",
                    marginTop: "15em",
                    marginBottom: "0em",
                };
                //img prodotto SI maniglioni / alzanti
                t.stileImgProdottoSiManiglioneAlzante = {
                    height: "750px",
                    minWidth: "auto",
                    cursor: "pointer",
                    backgroundColor: "white",
                    marginLeft: "-1em",
                    marginTop: "-5em",
                };
                //stile riga prodotti
                t.stileRowProdotti = {
                    marginLeft: "12em",
                    marginRight: "0em",
                    marginTop: "-5em",
                    width: "95%",
                    marginBottom: "38em",
                };
                //stile card prodotti
                t.stileCardProdotti = {
                    marginBottom: "10em",
                    marginLeft: "15em",
                    height: "10em",
                };
                //stile paragrafo NO maniglioni / alzanti
                t.stileParagrafoNoManiglioneAlzante = {
                    marginTop: "1em",
                    marginLeft: "1.5em",
                    width: "500px",
                };
                //stile paragrafo SI maniglioni / alzanti
                t.stileParagrafoSiManiglioneAlzante = {
                    marginTop: "-1em",
                    marginLeft: "0em",
                    marginBottom: "1em",
                    width: "500px",
                };
                //stile icon prodotto
                t.stileIconProdotti = {
                    width: "40",
                    height: "40px",
                    marginTop: "0em",
                    marginLeft: "0em",
                };
                //linea NO maniglioni / alzanti
                t.stileLineaNoManiglioneAlzante = {
                    marginTop: "-1.5em",
                    marginLeft: "2.9em",
                    fontSize: "18px",
                };
                //linea SI maniglioni / alzanti
                t.stileLineaSiManiglioneAlzante = {
                    marginTop: "-1.5em",
                    marginLeft: "0em",
                    fontSize: "18px",
                };
                //stile extation panel finiture
                t.stileExtationPanel = {
                    width: "125%",
                    height: "0%",
                    marginTop: "-37em",
                };
                //paragrafo extation panel NO maniglioni / alzanti
                t.stilePanelNoManiglioneAlzante = {
                    marginTop: "32em",
                    marginLeft: "-6em",
                    fontSize: "16px",
                };
                //paragrafo extation panel SI maniglioni / alzanti
                t.stilePanelSiManiglioneAlzante = {
                    marginTop: "32em",
                    marginLeft: "-6.2em",
                    fontSize: "16px",
                };
                //stile Img fintire (standard / pvd / retrò)
                t.stileColFinitureImg = {
                    minWidth: "20%",
                    marginTop: "2%",
                };
                //finiture standard NO maniglioni / alzanti
                t.stileFinitureStandardNoManiglioneAlzante = {
                    minWidth: "130%",
                    marginLeft: "-6.5em",
                    marginTop: "0em",
                };
                //finiture standard SI maniglioni / alzanti
                t.stileFinitureStandardSiManiglioneAlzante = {
                    width: "130%",
                    marginLeft: "-6em",
                    marginTop: "0em",
                };
                //finiture standard scorrevoli
                t.stileFinitureStandardSiScorrevoli = {
                    minWidth: "150%",
                    marginLeft: "-8.5em",
                    marginTop: "0.3em",
                };
            }
        },

        //MENU MANIGLIE
        getStiliMenuManiglie(t, value) {
            //controll dimension display devices
            //fare proporzione tra una dimensione e l'altra per scalare tutti gli stili //2340 --> 4K = +50%
            t.stileRowProdotti = {}; //riga prodotti
            t.stileCardProdotti = {}; //card img prodotto
            t.stileImgProdotto = {}; //img prodotto
            t.stileParagrafoNoManiglioneAlzante = {}; //paragrafo NO maniglioni / alzanti
            t.stileParagrafoDescrizione = {}; //paragrafo descrizione
            t.stileLineaNoManiglioneAlzante = {}; //linea NO maniglioni / alzanti
            t.stileIconProdotti = {}; // icons prodotti
            t.stileRowFiniture = {}; //riga finiture
            t.stileColFinitureImg = {}; //colonna finiture
            t.stileImgFiniture = {}; //img finiture

            if (value <= 768) {
                //smartphone
                //stile riga prodotti
                t.stileRowProdotti = {
                    marginTop: "-3em",
                    marginLeft: "0em",
                    marginRight: "0em",
                    width: "100%",
                };
                //img prodotto
                t.stileImgProdotto = {
                    minWidth: "360px",
                    height: "auto",
                    cursor: "pointer",
                    backgroundColor: "white",
                    marginLeft: "-2em",
                };
                //stile icon prodotti
                t.stileIconProdotti = {
                    width: "25px",
                    height: "25px",
                    marginTop: "0em",
                    marginLeft: "0em",
                };
                //paragrafo NO maniglioni / alzanti
                t.stilePanelNoManiglioneAlzante = {
                    minWidth: "100%",
                    height: "150%",
                    marginLeft: "0em",
                };
                //stile paragrafo NO maniglioni / alzanti
                t.stileParagrafoNoManiglioneAlzante = {
                    marginTop: "-2em",
                    marginLeft: "0em",
                    width: "300px",
                };
                //paragrafo descrizione
                t.stileParagrafoDescrizione = {
                    fontSize: "11px",
                    marginTop: "2.9em",
                    marginLeft: "-4.1em",
                };
                //linea NO maniglioni / alzanti
                t.stileLineaNoManiglioneAlzante = {
                    marginTop: "-1.3em",
                    marginLeft: "0em",
                    fontSize: "12px",
                };
                //stile row finiture (standard / pvd / retrò)
                t.stileRowFiniture = {
                    minWidth: "120%",
                    marginLeft: "-3em",
                    marginTop: "-0.7em",
                };
                //stile Col Img fintire (standard / pvd / retrò)
                t.stileColFinitureImg = {
                    minWidth: "20%",
                    marginTop: "5%",
                };
                //stile Img fintire (standard / pvd / retrò)
                t.stileImgFiniture = {
                    width: "25px",
                    height: "25px",
                };
            } else if (value > 768 && value <= 1050) {
                //tablet (1024)
                //stile riga prodotti
                t.stileRowProdotti = {
                    marginLeft: "2em",
                    marginRight: "2em",
                    marginTop: "-4em",
                    width: "100%",
                };
                //img prodotto
                t.stileImgProdotto = {
                    minWidth: "360px",
                    height: "auto",
                    cursor: "pointer",
                    backgroundColor: "white",
                    marginLeft: "-2em",
                };
                //stile paragrafo NO maniglioni / alzanti
                t.stileParagrafoNoManiglioneAlzante = {
                    marginTop: "-2em",
                    marginLeft: "0em",
                    width: "300px",
                };
                //stile icon prodotti
                t.stileIconProdotti = {
                    width: "25px",
                    height: "25px",
                    marginTop: "0em",
                    marginLeft: "0em",
                };
                //paragrafo NO maniglioni / alzanti
                t.stilePanelNoManiglioneAlzante = {
                    minWidth: "100%",
                    height: "150%",
                    marginLeft: "0em",
                };
                //paragrafo descrizione
                t.stileParagrafoDescrizione = {
                    fontSize: "11px",
                    marginTop: "2.9em",
                    marginLeft: "-4.1em",
                };
                //linea NO maniglioni / alzanti
                t.stileLineaNoManiglioneAlzante = {
                    marginTop: "-1.3em",
                    marginLeft: "0em",
                    fontSize: "12px",
                };
                //stile row finiture (standard / pvd / retrò)
                t.stileRowFiniture = {
                    minWidth: "120%",
                    marginLeft: "-3em",
                    marginTop: "-0.7em",
                };
                //stile Img fintire (standard / pvd / retrò)
                t.stileColFinitureImg = {
                    minWidth: "20%",
                    marginTop: "2%",
                };
                //stile Img fintire (standard / pvd / retrò)
                t.stileImgFiniture = {
                    width: "30px",
                    height: "30px",
                };
            } else if (value > 1050 && value <= 1280) {
                //HD (16:9 / 16:10)
                //stile riga prodotti
                t.stileRowProdotti = {
                    marginLeft: "2em",
                    marginRight: "2em",
                    marginTop: "-4em",
                    width: "100%",
                };
                //img prodotto
                t.stileImgProdotto = {
                    minWidth: "360px",
                    height: "auto",
                    cursor: "pointer",
                    backgroundColor: "white",
                    marginLeft: "-2em",
                };
                //stile paragrafo NO maniglioni / alzanti
                t.stileParagrafoNoManiglioneAlzante = {
                    marginTop: "-2em",
                    marginLeft: "0em",
                    width: "300px",
                };
                //stile icon prodotti
                t.stileIconProdotti = {
                    width: "25px",
                    height: "25px",
                    marginTop: "0em",
                    marginLeft: "0em",
                };
                //paragrafo NO maniglioni / alzanti
                t.stilePanelNoManiglioneAlzante = {
                    minWidth: "100%",
                    height: "150%",
                    marginLeft: "0em",
                };
                //paragrafo descrizione
                t.stileParagrafoDescrizione = {
                    fontSize: "11px",
                    marginTop: "2.9em",
                    marginLeft: "-4.1em",
                };
                //linea NO maniglioni / alzanti
                t.stileLineaNoManiglioneAlzante = {
                    marginTop: "-1.3em",
                    marginLeft: "0em",
                    fontSize: "12px",
                };
                //stile row finiture (standard / pvd / retrò)
                t.stileRowFiniture = {
                    minWidth: "120%",
                    marginLeft: "-3em",
                    marginTop: "-0.7em",
                };
                //stile Img fintire (standard / pvd / retrò)
                t.stileColFinitureImg = {
                    minWidth: "20%",
                    marginTop: "2%",
                };
                //stile Img fintire (standard / pvd / retrò)
                t.stileImgFiniture = {
                    width: "30px",
                    height: "30px",
                };
            } else if (value > 1280 && value <= 1540) {
                //tablet apple / surface (windows)
                //stile riga prodotti
                t.stileRowProdotti = {
                    marginLeft: "3em",
                    marginRight: "2em",
                    marginTop: "-4em",
                    width: "100%",
                };
                //img prodotto
                t.stileImgProdotto = {
                    minWidth: "360px",
                    height: "auto",
                    cursor: "pointer",
                    backgroundColor: "white",
                    marginLeft: "-2em",
                };
                //stile paragrafo NO maniglioni / alzanti
                t.stileParagrafoNoManiglioneAlzante = {
                    marginTop: "-2em",
                    marginLeft: "0em",
                    width: "300px",
                };
                //stile icon prodotti
                t.stileIconProdotti = {
                    width: "25px",
                    height: "25px",
                    marginTop: "0em",
                    marginLeft: "0em",
                };
                //paragrafo NO maniglioni / alzanti
                t.stilePanelNoManiglioneAlzante = {
                    minWidth: "100%",
                    height: "150%",
                    marginLeft: "0em",
                };
                //paragrafo descrizione
                t.stileParagrafoDescrizione = {
                    fontSize: "11px",
                    marginTop: "2.9em",
                    marginLeft: "-4.1em",
                };
                //linea NO maniglioni / alzanti
                t.stileLineaNoManiglioneAlzante = {
                    marginTop: "-1.3em",
                    marginLeft: "0em",
                    fontSize: "12px",
                };
                //stile row finiture (standard / pvd / retrò)
                t.stileRowFiniture = {
                    minWidth: "100%",
                    marginLeft: "-2.8em",
                    marginTop: "-0.2em",
                };
                //stile Img fintire (standard / pvd / retrò)
                t.stileColFinitureImg = {
                    minWidth: "20%",
                    marginTop: "2%",
                };
                //stile Img fintire (standard / pvd / retrò)
                t.stileImgFiniture = {
                    width: "30px",
                    height: "30px",
                };
            } else if (value > 1540 && value <= 1920) {
                //classic pc FULL HD 16:9 / 	16:10 --> 1680
                //stile riga prodotti
                t.stileRowProdotti = {
                    marginLeft: "3em",
                    marginRight: "2em",
                    marginTop: "-4em",
                    width: "100%",
                };
                //img prodotto
                t.stileImgProdotto = {
                    minWidth: "360px",
                    height: "auto",
                    cursor: "pointer",
                    backgroundColor: "white",
                    marginLeft: "-2em",
                };
                //stile paragrafo NO maniglioni / alzanti
                t.stileParagrafoNoManiglioneAlzante = {
                    marginTop: "-2em",
                    marginLeft: "0em",
                    width: "300px",
                };
                //stile icon prodotti
                t.stileIconProdotti = {
                    width: "30px",
                    height: "30px",
                    marginTop: "0em",
                    marginLeft: "0em",
                };
                //paragrafo NO maniglioni / alzanti
                t.stilePanelNoManiglioneAlzante = {
                    minWidth: "100%",
                    height: "150%",
                    marginLeft: "0em",
                };
                //paragrafo descrizione
                t.stileParagrafoDescrizione = {
                    fontSize: "11px",
                    marginTop: "2.9em",
                    marginLeft: "-4.1em",
                };
                //linea NO maniglioni / alzanti
                t.stileLineaNoManiglioneAlzante = {
                    marginTop: "-1.3em",
                    marginLeft: "0em",
                    fontSize: "12px",
                };
                //stile row finiture (standard / pvd / retrò)
                t.stileRowFiniture = {
                    minWidth: "100%",
                    marginLeft: "-2.8em",
                    marginTop: "-0.2em",
                };
                //stile Img fintire (standard / pvd / retrò)
                t.stileColFinitureImg = {
                    minWidth: "20%",
                    marginTop: "2%",
                };
                //stile Img fintire (standard / pvd / retrò)
                t.stileImgFiniture = {
                    width: "30px",
                    height: "30px",
                };
            } else if (value > 1920 && value <= 2100) {
                //classic pc (16:10 / 16:9 / 21:9)
            } else if (value > 2100 && value <= 2560) {
                //pc monitor (16:9)
                //stile riga prodotti
                t.stileRowProdotti = {
                    marginLeft: "6em",
                    marginRight: "0em",
                    marginTop: "2em",
                    width: "95%",
                    marginBottom: "2em",
                };
                //stile card prodotti
                t.stileCardProdotti = {
                    marginBottom: "10em",
                    marginLeft: "5em",
                    height: "10em",
                };
                //stile paragrafo NO maniglioni / alzanti
                t.stileParagrafoNoManiglioneAlzante = {
                    marginTop: "-1em",
                    marginLeft: "2em",
                    width: "390px",
                };
                //img prodotto
                t.stileImgProdotto = {
                    minWidth: "500px",
                    height: "auto",
                    cursor: "pointer",
                    backgroundColor: "white",
                    marginLeft: "0em",
                };
                //stile icon prodotti
                t.stileIconProdotti = {
                    width: "30px",
                    height: "30px",
                    marginTop: "0em",
                    marginLeft: "0em",
                };
                //linea NO maniglioni / alzanti
                t.stileLineaNoManiglioneAlzante = {
                    marginTop: "-1.3em",
                    marginLeft: "3.3em",
                    fontSize: "12px",
                };
                //paragrafo NO maniglioni / alzanti
                t.stilePanelNoManiglioneAlzante = {
                    minWidth: "180%",
                    height: "150%",
                    marginLeft: "0em",
                };
                //paragrafo descrizione
                t.stileParagrafoDescrizione = {
                    fontSize: "11px",
                    marginTop: "-5em",
                    marginLeft: "8.4em",
                };
                //stile row finiture (standard / pvd / retrò)
                t.stileRowFiniture = {
                    minWidth: "85%",
                    marginLeft: "5.6em",
                    marginTop: "0em",
                };
                //stile Img fintire (standard / pvd / retrò)
                t.stileColFinitureImg = {
                    minWidth: "20%",
                    marginTop: "2%",
                };
                //stile Img fintire (standard / pvd / retrò)
                t.stileImgFiniture = {
                    width: "auto",
                    height: "auto",
                };
            } else if (value > 2560 && value <= 3840) {
                //TV 4K
                //stile riga prodotti
                t.stileRowProdotti = {
                    marginLeft: "10em",
                    marginRight: "0em",
                    marginTop: "5em",
                    width: "95%",
                    marginBottom: "15em",
                };
                //stile card prodotti
                t.stileCardProdotti = {
                    marginBottom: "10em",
                    marginLeft: "15em",
                    height: "10em",
                };
                //stile paragrafo NO maniglioni / alzanti
                t.stileParagrafoNoManiglioneAlzante = {
                    marginTop: "-1em",
                    marginLeft: "2em",
                    width: "500px",
                };
                //img prodotto
                t.stileImgProdotto = {
                    minWidth: "650px",
                    height: "auto",
                    cursor: "pointer",
                    backgroundColor: "white",
                    marginLeft: "0em",
                };
                //stile icon prodotti
                t.stileIconProdotti = {
                    width: "40px",
                    height: "40px",
                    marginTop: "0em",
                    marginLeft: "0em",
                };
                //linea NO maniglioni / alzanti
                t.stileLineaNoManiglioneAlzante = {
                    marginTop: "-1.5em",
                    marginLeft: "3.8em",
                    fontSize: "18px",
                };
                //paragrafo NO maniglioni / alzanti
                t.stilePanelNoManiglioneAlzante = {
                    minWidth: "200%",
                    height: "150%",
                    marginLeft: "29em",
                };
                //paragrafo descrizione
                t.stileParagrafoDescrizione = {
                    fontSize: "16px",
                    marginTop: "0em",
                    marginLeft: "0em",
                };
                //stile row finiture (standard / pvd / retrò)
                t.stileRowFiniture = {
                    minWidth: "85%",
                    marginLeft: "0em",
                    marginTop: "0em",
                };
                //stile Img fintire (standard / pvd / retrò)
                t.stileColFinitureImg = {
                    minWidth: "20%",
                    marginTop: "2%",
                };
                //stile Img fintire (standard / pvd / retrò)
                t.stileImgFiniture = {
                    width: "auto",
                    height: "auto",
                };
            }
        },

        //MENU POMOLI
        getStiliMenuPomoli(t, value) {
            //controll dimension display devices
            //fare proporzione tra una dimensione e l'altra per scalare tutti gli stili //2340 --> 4K = +50%
            t.stileRowProdotti = {}; //riga prodotti
            t.stileCardProdotti = {}; //card img prodotto
            t.stileImgProdotto = {}; //img prodotto
            t.stileParagrafoNoManiglioneAlzante = {}; //paragrafo NO maniglioni / alzanti
            t.stileParagrafoDescrizione = {}; //paragrafo descrizione
            t.stileLineaNoManiglioneAlzante = {}; //linea NO maniglioni / alzanti
            t.stileIconProdotti = {}; // icons prodotti
            t.stileRowFiniture = {}; //riga finiture
            t.stileColFinitureImg = {}; //colonna finiture
            t.stileImgFiniture = {}; //img finiture

            if (value <= 768) {
                //smartphone
                //stile riga prodotti
                t.stileRowProdotti = {
                    marginTop: "-3em",
                    marginLeft: "0em",
                    marginRight: "0em",
                    width: "100%",
                };
                //img prodotto
                t.stileImgProdotto = {
                    minWidth: "360px",
                    height: "auto",
                    cursor: "pointer",
                    backgroundColor: "white",
                    marginLeft: "-2em",
                };
                //stile icon prodotti
                t.stileIconProdotti = {
                    width: "25px",
                    height: "25px",
                    marginTop: "0em",
                    marginLeft: "0em",
                };
                //paragrafo NO maniglioni / alzanti
                t.stilePanelNoManiglioneAlzante = {
                    minWidth: "100%",
                    height: "150%",
                };
                //stile paragrafo NO maniglioni / alzanti
                t.stileParagrafoNoManiglioneAlzante = {
                    marginTop: "-2em",
                    marginLeft: "0em",
                    width: "300px",
                };
                //paragrafo descrizione
                t.stileParagrafoDescrizione = {
                    fontSize: "11px",
                    marginTop: "2.9em",
                    marginLeft: "-4.1em",
                };
                //linea NO maniglioni / alzanti
                t.stileLineaNoManiglioneAlzante = {
                    marginTop: "-1.3em",
                    marginLeft: "0em",
                    fontSize: "12px",
                };
                //stile row finiture (standard / pvd / retrò)
                t.stileRowFiniture = {
                    minWidth: "120%",
                    marginLeft: "-3em",
                    marginTop: "-0.7em",
                };
                //stile Col Img fintire (standard / pvd / retrò)
                t.stileColFinitureImg = {
                    minWidth: "20%",
                    marginTop: "5%",
                };
                //stile Img fintire (standard / pvd / retrò)
                t.stileImgFiniture = {
                    width: "25px",
                    height: "25px",
                };
            } else if (value > 768 && value <= 1050) {
                //tablet (1024)
                //stile riga prodotti
                t.stileRowProdotti = {
                    marginLeft: "2em",
                    marginRight: "2em",
                    marginTop: "-4em",
                    width: "100%",
                };
                //img prodotto
                t.stileImgProdotto = {
                    minWidth: "360px",
                    height: "auto",
                    cursor: "pointer",
                    backgroundColor: "white",
                    marginLeft: "-2em",
                };
                //stile paragrafo NO maniglioni / alzanti
                t.stileParagrafoNoManiglioneAlzante = {
                    marginTop: "-2em",
                    marginLeft: "0em",
                    width: "300px",
                };
                //stile icon prodotti
                t.stileIconProdotti = {
                    width: "25px",
                    height: "25px",
                    marginTop: "0em",
                    marginLeft: "0em",
                };
                //paragrafo NO maniglioni / alzanti
                t.stilePanelNoManiglioneAlzante = {
                    minWidth: "100%",
                    height: "150%",
                };
                //paragrafo descrizione
                t.stileParagrafoDescrizione = {
                    fontSize: "11px",
                    marginTop: "2.9em",
                    marginLeft: "-4.1em",
                };
                //linea NO maniglioni / alzanti
                t.stileLineaNoManiglioneAlzante = {
                    marginTop: "-1.3em",
                    marginLeft: "0em",
                    fontSize: "12px",
                };
                //stile row finiture (standard / pvd / retrò)
                t.stileRowFiniture = {
                    minWidth: "120%",
                    marginLeft: "-3em",
                    marginTop: "-0.7em",
                };
                //stile Img fintire (standard / pvd / retrò)
                t.stileColFinitureImg = {
                    minWidth: "20%",
                    marginTop: "2%",
                };
                //stile Img fintire (standard / pvd / retrò)
                t.stileImgFiniture = {
                    width: "30px",
                    height: "30px",
                };
            } else if (value > 1050 && value <= 1280) {
                //HD (16:9 / 16:10)
                //stile riga prodotti
                t.stileRowProdotti = {
                    marginLeft: "2em",
                    marginRight: "2em",
                    marginTop: "-4em",
                    width: "100%",
                };
                //img prodotto
                t.stileImgProdotto = {
                    minWidth: "360px",
                    height: "auto",
                    cursor: "pointer",
                    backgroundColor: "white",
                    marginLeft: "-2em",
                };
                //stile paragrafo NO maniglioni / alzanti
                t.stileParagrafoNoManiglioneAlzante = {
                    marginTop: "-2em",
                    marginLeft: "0em",
                    width: "300px",
                };
                //stile icon prodotti
                t.stileIconProdotti = {
                    width: "25px",
                    height: "25px",
                    marginTop: "0em",
                    marginLeft: "0em",
                };
                //paragrafo NO maniglioni / alzanti
                t.stilePanelNoManiglioneAlzante = {
                    minWidth: "100%",
                    height: "150%",
                };
                //paragrafo descrizione
                t.stileParagrafoDescrizione = {
                    fontSize: "11px",
                    marginTop: "2.9em",
                    marginLeft: "-4.1em",
                };
                //linea NO maniglioni / alzanti
                t.stileLineaNoManiglioneAlzante = {
                    marginTop: "-1.3em",
                    marginLeft: "0em",
                    fontSize: "12px",
                };
                //stile row finiture (standard / pvd / retrò)
                t.stileRowFiniture = {
                    minWidth: "120%",
                    marginLeft: "-3em",
                    marginTop: "-0.7em",
                };
                //stile Img fintire (standard / pvd / retrò)
                t.stileColFinitureImg = {
                    minWidth: "20%",
                    marginTop: "2%",
                };
                //stile Img fintire (standard / pvd / retrò)
                t.stileImgFiniture = {
                    width: "30px",
                    height: "30px",
                };
            } else if (value > 1280 && value <= 1540) {
                //tablet apple / surface (windows)
                //stile riga prodotti
                t.stileRowProdotti = {
                    marginLeft: "3em",
                    marginRight: "2em",
                    marginTop: "-4em",
                    width: "100%",
                };
                //img prodotto
                t.stileImgProdotto = {
                    minWidth: "360px",
                    height: "auto",
                    cursor: "pointer",
                    backgroundColor: "white",
                    marginLeft: "-2em",
                };
                //stile paragrafo NO maniglioni / alzanti
                t.stileParagrafoNoManiglioneAlzante = {
                    marginTop: "-2em",
                    marginLeft: "0em",
                    width: "300px",
                };
                //stile icon prodotti
                t.stileIconProdotti = {
                    width: "25px",
                    height: "25px",
                    marginTop: "0em",
                    marginLeft: "0em",
                };
                //paragrafo NO maniglioni / alzanti
                t.stilePanelNoManiglioneAlzante = {
                    minWidth: "100%",
                    height: "150%",
                };
                //paragrafo descrizione
                t.stileParagrafoDescrizione = {
                    fontSize: "11px",
                    marginTop: "2.9em",
                    marginLeft: "-4.1em",
                };
                //linea NO maniglioni / alzanti
                t.stileLineaNoManiglioneAlzante = {
                    marginTop: "-1.3em",
                    marginLeft: "0em",
                    fontSize: "12px",
                };
                //stile row finiture (standard / pvd / retrò)
                t.stileRowFiniture = {
                    minWidth: "100%",
                    marginLeft: "-2.8em",
                    marginTop: "-0.2em",
                };
                //stile Img fintire (standard / pvd / retrò)
                t.stileColFinitureImg = {
                    minWidth: "20%",
                    marginTop: "2%",
                };
                //stile Img fintire (standard / pvd / retrò)
                t.stileImgFiniture = {
                    width: "30px",
                    height: "30px",
                };
            } else if (value > 1540 && value <= 1920) {
                //classic pc FULL HD 16:9 / 	16:10 --> 1680
                //stile riga prodotti
                t.stileRowProdotti = {
                    marginLeft: "3em",
                    marginRight: "2em",
                    marginTop: "-4em",
                    width: "100%",
                };
                //img prodotto
                t.stileImgProdotto = {
                    minWidth: "360px",
                    height: "auto",
                    cursor: "pointer",
                    backgroundColor: "white",
                    marginLeft: "-2em",
                };
                //stile paragrafo NO maniglioni / alzanti
                t.stileParagrafoNoManiglioneAlzante = {
                    marginTop: "-2em",
                    marginLeft: "0em",
                    width: "300px",
                };
                //stile icon prodotti
                t.stileIconProdotti = {
                    width: "30px",
                    height: "30px",
                    marginTop: "0em",
                    marginLeft: "0em",
                };
                //paragrafo NO maniglioni / alzanti
                t.stilePanelNoManiglioneAlzante = {
                    minWidth: "100%",
                    height: "150%",
                    marginLeft: "0em",
                };
                //paragrafo descrizione
                t.stileParagrafoDescrizione = {
                    fontSize: "11px",
                    marginTop: "2.9em",
                    marginLeft: "-4.1em",
                };
                //linea NO maniglioni / alzanti
                t.stileLineaNoManiglioneAlzante = {
                    marginTop: "-1.3em",
                    marginLeft: "0em",
                    fontSize: "12px",
                };
                //stile row finiture (standard / pvd / retrò)
                t.stileRowFiniture = {
                    minWidth: "100%",
                    marginLeft: "-2.8em",
                    marginTop: "-0.2em",
                };
                //stile Img fintire (standard / pvd / retrò)
                t.stileColFinitureImg = {
                    minWidth: "20%",
                    marginTop: "2%",
                };
                //stile Img fintire (standard / pvd / retrò)
                t.stileImgFiniture = {
                    width: "30px",
                    height: "30px",
                };
            } else if (value > 1920 && value <= 2100) {
                //classic pc (16:10 / 16:9 / 21:9)
            } else if (value > 2100 && value <= 2560) {
                //pc monitor (16:9)
                //stile riga prodotti
                t.stileRowProdotti = {
                    marginLeft: "6em",
                    marginRight: "0em",
                    marginTop: "2em",
                    width: "95%",
                    marginBottom: "2em",
                };
                //stile card prodotti
                t.stileCardProdotti = {
                    marginBottom: "10em",
                    marginLeft: "5em",
                    height: "10em",
                };
                //stile paragrafo NO maniglioni / alzanti
                t.stileParagrafoNoManiglioneAlzante = {
                    marginTop: "-1em",
                    marginLeft: "2em",
                    width: "390px",
                };
                //img prodotto
                t.stileImgProdotto = {
                    minWidth: "500px",
                    height: "auto",
                    cursor: "pointer",
                    backgroundColor: "white",
                    marginLeft: "0em",
                };
                //stile icon prodotti
                t.stileIconProdotti = {
                    width: "30px",
                    height: "30px",
                    marginTop: "0em",
                    marginLeft: "0em",
                };
                //linea NO maniglioni / alzanti
                t.stileLineaNoManiglioneAlzante = {
                    marginTop: "-1.3em",
                    marginLeft: "3.3em",
                    fontSize: "12px",
                };
                //paragrafo NO maniglioni / alzanti
                t.stilePanelNoManiglioneAlzante = {
                    minWidth: "180%",
                    height: "150%",
                    marginLeft: "0em",
                };
                //paragrafo descrizione
                t.stileParagrafoDescrizione = {
                    fontSize: "11px",
                    marginTop: "-5em",
                    marginLeft: "8.4em",
                };
                //stile row finiture (standard / pvd / retrò)
                t.stileRowFiniture = {
                    minWidth: "85%",
                    marginLeft: "5.6em",
                    marginTop: "0em",
                };
                //stile Img fintire (standard / pvd / retrò)
                t.stileColFinitureImg = {
                    minWidth: "20%",
                    marginTop: "2%",
                };
                //stile Img fintire (standard / pvd / retrò)
                t.stileImgFiniture = {
                    width: "auto",
                    height: "auto",
                };
            } else if (value > 2560 && value <= 3840) {
                //TV 4K
                //stile riga prodotti
                t.stileRowProdotti = {
                    marginLeft: "10em",
                    marginRight: "0em",
                    marginTop: "5em",
                    width: "95%",
                    marginBottom: "15em",
                };
                //stile card prodotti
                t.stileCardProdotti = {
                    marginBottom: "10em",
                    marginLeft: "15em",
                    height: "10em",
                };
                //stile paragrafo NO maniglioni / alzanti
                t.stileParagrafoNoManiglioneAlzante = {
                    marginTop: "-1em",
                    marginLeft: "2em",
                    width: "500px",
                };
                //img prodotto
                t.stileImgProdotto = {
                    minWidth: "650px",
                    height: "auto",
                    cursor: "pointer",
                    backgroundColor: "white",
                    marginLeft: "0em",
                };
                //stile icon prodotti
                t.stileIconProdotti = {
                    width: "40px",
                    height: "40px",
                    marginTop: "0em",
                    marginLeft: "0em",
                };
                //linea NO maniglioni / alzanti
                t.stileLineaNoManiglioneAlzante = {
                    marginTop: "-1.5em",
                    marginLeft: "3.8em",
                    fontSize: "18px",
                };
                //paragrafo NO maniglioni / alzanti
                t.stilePanelNoManiglioneAlzante = {
                    minWidth: "200%",
                    height: "150%",
                    marginLeft: "29em",
                };
                //paragrafo descrizione
                t.stileParagrafoDescrizione = {
                    fontSize: "16px",
                    marginTop: "0em",
                    marginLeft: "0em",
                };
                //stile row finiture (standard / pvd / retrò)
                t.stileRowFiniture = {
                    minWidth: "85%",
                    marginLeft: "0em",
                    marginTop: "0em",
                };
                //stile Img fintire (standard / pvd / retrò)
                t.stileColFinitureImg = {
                    minWidth: "20%",
                    marginTop: "2%",
                };
                //stile Img fintire (standard / pvd / retrò)
                t.stileImgFiniture = {
                    width: "auto",
                    height: "auto",
                };
            }
        },

        //MENU MANIGLIONI
        getStiliMenuManiglioni(t, value) {
            //controll dimension display devices
            //fare proporzione tra una dimensione e l'altra per scalare tutti gli stili //2340 --> 4K = +50%
            t.stileRowProdotti = {}; //riga prodotti
            t.stileCardProdotti = {}; //card img prodotto
            t.stileImgProdotto = {}; //img prodotto
            t.stileParagrafoNoManiglioneAlzante = {}; //paragrafo NO maniglioni / alzanti
            t.stileIconProdotti = {}; // icons prodotti
            t.stileLineaNoManiglioneAlzante = {}; //linea NO maniglioni / alzanti
            t.stileRowFiniture = {}; //riga finiture
            t.stileColFinitureImg = {}; //colonna finiture
            t.stileImgFiniture = {}; //img finiture
            t.stileExtationPanel = {}; //extation panel finiture
            t.stileParagrafoDescrizione = {}; //descrizione extation panel finiture

            if (value <= 768) {
                //smartphone
                //stile riga prodotti
                t.stileRowProdotti = {
                    marginTop: "5em",
                    marginLeft: "0em",
                    marginRight: "0em",
                    width: "100%",
                };
                //img prodotto
                t.stileImgProdotto = {
                    minWidth: "360px",
                    height: "auto",
                    cursor: "pointer",
                    backgroundColor: "white",
                    marginLeft: "-2em",
                };
                //stile paragrafo NO maniglioni / alzanti
                t.stileParagrafoNoManiglioneAlzante = {
                    marginTop: "-1em",
                    marginLeft: "0em",
                    marginBottom: "1em",
                    width: "300px",
                };
                //stile icon prodotti
                t.stileIconProdotti = {
                    width: "25px",
                    height: "25px",
                    marginTop: "0em",
                    marginLeft: "0em",
                };
                //linea NO maniglioni / alzanti
                t.stileLineaNoManiglioneAlzante = {
                    marginTop: "-1.5em",
                    marginLeft: "0em",
                    fontSize: "12px",
                };
                //stile row finiture (standard / pvd / retrò)
                t.stileRowFiniture = {
                    minWidth: "100%",
                    marginLeft: "-2.7em",
                    marginTop: "0em",
                };
                //stile Col Img fintire (standard / pvd / retrò)
                t.stileColFinitureImg = {
                    minWidth: "20%",
                    marginTop: "5%",
                };
                //stile Img fintire (standard / pvd / retrò)
                t.stileImgFiniture = {
                    width: "25px",
                    height: "25px",
                };
                //stile extation panel finiture
                t.stileExtationPanel = {
                    minWidth: "100%",
                    height: "0%",
                    marginLeft: "0em",
                };
                //stile descrizione extation panel finiture
                t.stileParagrafoDescrizione = {
                    fontSize: "11px",
                    marginTop: "10em",
                    marginLeft: "-4.1em",
                };
            } else if (value > 768 && value <= 1050) {
                //tablet (1024)
                //stile riga prodotti
                t.stileRowProdotti = {
                    marginLeft: "1em",
                    marginRight: "2em",
                    marginTop: "7em",
                    width: "100%",
                };
                //img prodotto
                t.stileImgProdotto = {
                    minWidth: "360px",
                    height: "auto",
                    cursor: "pointer",
                    backgroundColor: "white",
                    marginLeft: "-2em",
                };
                //stile paragrafo NO maniglioni / alzanti
                t.stileParagrafoNoManiglioneAlzante = {
                    marginTop: "-1em",
                    marginLeft: "0em",
                    marginBottom: "1em",
                    width: "300px",
                };
                //stile icon prodotti
                t.stileIconProdotti = {
                    width: "25px",
                    height: "25px",
                    marginTop: "0em",
                    marginLeft: "0em",
                };
                //linea NO maniglioni / alzanti
                t.stileLineaNoManiglioneAlzante = {
                    marginTop: "-1.5em",
                    marginLeft: "0em",
                    fontSize: "12px",
                };
                //stile row finiture (standard / pvd / retrò)
                t.stileRowFiniture = {
                    minWidth: "100%",
                    marginLeft: "-2.7em",
                    marginTop: "0.3em",
                };
                //stile Img fintire (standard / pvd / retrò)
                t.stileColFinitureImg = {
                    minWidth: "20%",
                    marginTop: "2%",
                };
                //stile Img fintire (standard / pvd / retrò)
                t.stileImgFiniture = {
                    width: "30px",
                    height: "30px",
                };
                //stile extation panel finiture
                t.stileExtationPanel = {
                    minWidth: "90%",
                    height: "0%",
                    marginLeft: "0em",
                };
                //stile descrizione extation panel finiture
                t.stileParagrafoDescrizione = {
                    fontSize: "11px",
                    marginTop: "10em",
                    marginLeft: "-4.1em",
                };
            } else if (value > 1050 && value <= 1280) {
                //HD (16:9 / 16:10)
                //stile riga prodotti
                t.stileRowProdotti = {
                    marginLeft: "1em",
                    marginRight: "2em",
                    marginTop: "7em",
                    width: "100%",
                };
                //img prodotto
                t.stileImgProdotto = {
                    minWidth: "360px",
                    height: "auto",
                    cursor: "pointer",
                    backgroundColor: "white",
                    marginLeft: "-2em",
                };
                //stile paragrafo NO maniglioni / alzanti
                t.stileParagrafoNoManiglioneAlzante = {
                    marginTop: "-1em",
                    marginLeft: "0em",
                    marginBottom: "1em",
                    width: "300px",
                };
                //stile icon prodotti
                t.stileIconProdotti = {
                    width: "25px",
                    height: "25px",
                    marginTop: "0em",
                    marginLeft: "0em",
                };
                //linea NO maniglioni / alzanti
                t.stileLineaNoManiglioneAlzante = {
                    marginTop: "-1.5em",
                    marginLeft: "0em",
                    fontSize: "12px",
                };
                //stile row finiture (standard / pvd / retrò)
                t.stileRowFiniture = {
                    minWidth: "100%",
                    marginLeft: "-2.7em",
                    marginTop: "0.3em",
                };
                //stile Img fintire (standard / pvd / retrò)
                t.stileColFinitureImg = {
                    minWidth: "20%",
                    marginTop: "2%",
                };
                //stile Img fintire (standard / pvd / retrò)
                t.stileImgFiniture = {
                    width: "30px",
                    height: "30px",
                };
                //stile extation panel finiture
                t.stileExtationPanel = {
                    minWidth: "90%",
                    height: "0%",
                    marginLeft: "0em",
                };
                //stile descrizione extation panel finiture
                t.stileParagrafoDescrizione = {
                    fontSize: "11px",
                    marginTop: "10em",
                    marginLeft: "-4.1em",
                };
            } else if (value > 1280 && value <= 1540) {
                //tablet apple / surface (windows)
                //stile riga prodotti
                t.stileRowProdotti = {
                    marginLeft: "2em",
                    marginRight: "2em",
                    marginTop: "7em",
                    width: "100%",
                };
                //img prodotto
                t.stileImgProdotto = {
                    minWidth: "360px",
                    height: "auto",
                    cursor: "pointer",
                    backgroundColor: "white",
                    marginLeft: "-2em",
                };
                //stile paragrafo NO maniglioni / alzanti
                t.stileParagrafoNoManiglioneAlzante = {
                    marginTop: "-1em",
                    marginLeft: "0em",
                    marginBottom: "1em",
                    width: "300px",
                };
                //stile icon prodotti
                t.stileIconProdotti = {
                    width: "25px",
                    height: "25px",
                    marginTop: "0em",
                    marginLeft: "0em",
                };
                //linea NO maniglioni / alzanti
                t.stileLineaNoManiglioneAlzante = {
                    marginTop: "-1.5em",
                    marginLeft: "0em",
                    fontSize: "12px",
                };
                //stile row finiture (standard / pvd / retrò)
                t.stileRowFiniture = {
                    minWidth: "100%",
                    marginLeft: "-2.7em",
                    marginTop: "0.3em",
                };
                //stile Img fintire (standard / pvd / retrò)
                t.stileColFinitureImg = {
                    minWidth: "20%",
                    marginTop: "2%",
                };
                //stile Img fintire (standard / pvd / retrò)
                t.stileImgFiniture = {
                    width: "30px",
                    height: "30px",
                };
                //stile extation panel finiture
                t.stileExtationPanel = {
                    minWidth: "90%",
                    height: "0%",
                    marginLeft: "0em",
                };
                //stile descrizione extation panel finiture
                t.stileParagrafoDescrizione = {
                    fontSize: "11px",
                    marginTop: "10em",
                    marginLeft: "-4.1em",
                };
            } else if (value > 1540 && value <= 1920) {
                //classic pc FULL HD 16:9 / 	16:10 --> 1680
                //stile riga prodotti
                t.stileRowProdotti = {
                    marginLeft: "3em",
                    marginRight: "2em",
                    marginTop: "10em",
                    width: "100%",
                };
                //img prodotto
                t.stileImgProdotto = {
                    minWidth: "360px",
                    height: "auto",
                    cursor: "pointer",
                    backgroundColor: "white",
                    marginLeft: "-2em",
                };
                //stile paragrafo NO maniglioni / alzanti
                t.stileParagrafoNoManiglioneAlzante = {
                    marginTop: "-1em",
                    marginLeft: "0em",
                    marginBottom: "1em",
                    width: "300px",
                };
                //stile icon prodotti
                t.stileIconProdotti = {
                    width: "30px",
                    height: "30px",
                    marginTop: "0em",
                    marginLeft: "0em",
                };
                //linea NO maniglioni / alzanti
                t.stileLineaNoManiglioneAlzante = {
                    marginTop: "-1.5em",
                    marginLeft: "0em",
                    fontSize: "12px",
                };
                //stile row finiture (standard / pvd / retrò)
                t.stileRowFiniture = {
                    minWidth: "100%",
                    marginLeft: "-2.7em",
                    marginTop: "0.3em",
                };
                //stile Img fintire (standard / pvd / retrò)
                t.stileColFinitureImg = {
                    minWidth: "20%",
                    marginTop: "2%",
                };
                //stile Img fintire (standard / pvd / retrò)
                t.stileImgFiniture = {
                    width: "30px",
                    height: "30px",
                };
                //stile extation panel finiture
                t.stileExtationPanel = {
                    minWidth: "90%",
                    height: "0%",
                    marginLeft: "0em",
                };
                //stile descrizione extation panel finiture
                t.stileParagrafoDescrizione = {
                    fontSize: "11px",
                    marginTop: "10em",
                    marginLeft: "-4.1em",
                };
            } else if (value > 1920 && value <= 2100) {
                //classic pc (16:10 / 16:9 / 21:9)
            } else if (value > 2100 && value <= 2560) {
                //pc monitor (16:9)
                //stile riga prodotti
                t.stileRowProdotti = {
                    marginLeft: "6em",
                    marginRight: "0em",
                    marginTop: "15em",
                    width: "95%",
                    marginBottom: "20em",
                };
                //stile card prodotti
                t.stileCardProdotti = {
                    marginBottom: "10em",
                    marginLeft: "5em",
                    height: "10em",
                };
                //img prodotto
                t.stileImgProdotto = {
                    minWidth: "500px",
                    height: "auto",
                    cursor: "pointer",
                    backgroundColor: "white",
                    marginLeft: "-2em",
                };
                //stile icon prodotti
                t.stileIconProdotti = {
                    width: "30px",
                    height: "30px",
                    marginTop: "0em",
                    marginLeft: "0em",
                };
                //stile paragrafo NO maniglioni / alzanti
                t.stileParagrafoNoManiglioneAlzante = {
                    marginTop: "-1em",
                    marginLeft: "0em",
                    marginBottom: "1em",
                    width: "450px",
                };
                //linea NO maniglioni / alzanti
                t.stileLineaNoManiglioneAlzante = {
                    marginTop: "-1.5em",
                    marginLeft: "0em",
                    fontSize: "12px",
                };
                //stile extation panel finiture
                t.stileExtationPanel = {
                    minWidth: "200%",
                    height: "0%",
                    marginLeft: "0em",
                };
                //stile descrizione extation panel finiture
                t.stileParagrafoDescrizione = {
                    fontSize: "11px",
                    marginTop: "8em",
                    marginLeft: "8.5em",
                };
                //stile row finiture (standard / pvd / retrò)
                t.stileRowFiniture = {
                    minWidth: "100%",
                    marginLeft: "6em",
                    marginTop: "0.3em",
                };
                //stile Img fintire (standard / pvd / retrò)
                t.stileColFinitureImg = {
                    minWidth: "20%",
                    marginTop: "2%",
                };
            } else if (value > 2560 && value <= 3840) {
                //TV 4K
                //stile riga prodotti
                t.stileRowProdotti = {
                    marginLeft: "10em",
                    marginRight: "0em",
                    marginTop: "15em",
                    width: "95%",
                    marginBottom: "25em",
                };
                //stile card prodotti
                t.stileCardProdotti = {
                    marginBottom: "10em",
                    marginLeft: "15em",
                    height: "10em",
                };
                //img prodotto
                t.stileImgProdotto = {
                    minWidth: "550px",
                    height: "auto",
                    cursor: "pointer",
                    backgroundColor: "white",
                    marginLeft: "-2em",
                };
                //stile icon prodotti
                t.stileIconProdotti = {
                    width: "40px",
                    height: "40px",
                    marginTop: "0em",
                    marginLeft: "0em",
                };
                //stile paragrafo NO maniglioni / alzanti
                t.stileParagrafoNoManiglioneAlzante = {
                    marginTop: "-1em",
                    marginLeft: "0em",
                    marginBottom: "1em",
                    width: "500px",
                };
                //linea NO maniglioni / alzanti
                t.stileLineaNoManiglioneAlzante = {
                    marginTop: "-1.5em",
                    marginLeft: "0em",
                    fontSize: "18px",
                };
                //stile extation panel finiture
                t.stileExtationPanel = {
                    minWidth: "200%",
                    height: "150%",
                    marginLeft: "29em",
                };
                //stile descrizione extation panel finiture
                t.stileParagrafoDescrizione = {
                    fontSize: "16px",
                    marginTop: "16em",
                    marginLeft: "0em",
                };
                //stile row finiture (standard / pvd / retrò)
                t.stileRowFiniture = {
                    minWidth: "100%",
                    marginLeft: "0em",
                    marginTop: "0.3em",
                };
                //stile Img fintire (standard / pvd / retrò)
                t.stileColFinitureImg = {
                    minWidth: "20%",
                    marginTop: "2%",
                };
            }
        },

        //MENU ALZANTI
        getStiliMenuAlzanti(t, value) {
            //controll dimension display devices
            //fare proporzione tra una dimensione e l'altra per scalare tutti gli stili //2340 --> 4K = +50%
            t.stileRowProdotti = {}; //riga prodotti
            t.stileCardProdotti = {}; //card img prodotto
            t.stileImgProdotto = {}; //img prodotto
            t.stileParagrafoNoManiglioneAlzante = {}; //paragrafo NO maniglioni / alzanti
            t.stileIconProdotti = {}; // icons prodotti
            t.stileLineaNoManiglioneAlzante = {}; //linea NO maniglioni / alzanti
            t.stileRowFiniture = {}; //riga finiture
            t.stileColFinitureImg = {}; //colonna finiture
            t.stileImgFiniture = {}; //img finiture
            t.stileExtationPanel = {}; //extation panel finiture
            t.stileParagrafoDescrizione = {}; //descrizione extation panel finiture

            if (value <= 768) {
                //smartphone
                //stile riga prodotti
                t.stileRowProdotti = {
                    marginTop: "5em",
                    marginLeft: "0em",
                    marginRight: "0em",
                    width: "100%",
                };
                //img prodotto
                t.stileImgProdotto = {
                    minWidth: "360px",
                    height: "auto",
                    cursor: "pointer",
                    backgroundColor: "white",
                    marginLeft: "-2em",
                };
                //stile paragrafo NO maniglioni / alzanti
                t.stileParagrafoNoManiglioneAlzante = {
                    marginTop: "-1em",
                    marginLeft: "0em",
                    marginBottom: "1em",
                    width: "300px",
                };
                //stile icon prodotti
                t.stileIconProdotti = {
                    width: "25px",
                    height: "25px",
                    marginTop: "0em",
                    marginLeft: "0em",
                };
                //linea NO maniglioni / alzanti
                t.stileLineaNoManiglioneAlzante = {
                    marginTop: "-1.5em",
                    marginLeft: "0em",
                    fontSize: "12px",
                };
                //stile row finiture (standard / pvd / retrò)
                t.stileRowFiniture = {
                    minWidth: "100%",
                    marginLeft: "-2.7em",
                    marginTop: "0em",
                };
                //stile Col Img fintire (standard / pvd / retrò)
                t.stileColFinitureImg = {
                    minWidth: "20%",
                    marginTop: "5%",
                };
                //stile Img fintire (standard / pvd / retrò)
                t.stileImgFiniture = {
                    width: "25px",
                    height: "25px",
                };
                //stile extation panel finiture
                t.stileExtationPanel = {
                    minWidth: "100%",
                    height: "0%",
                };
                //stile descrizione extation panel finiture
                t.stileParagrafoDescrizione = {
                    fontSize: "11px",
                    marginTop: "10em",
                    marginLeft: "-4.1em",
                };
            } else if (value > 768 && value <= 1050) {
                //tablet (1024)
                //stile riga prodotti
                t.stileRowProdotti = {
                    marginLeft: "1em",
                    marginRight: "2em",
                    marginTop: "5em",
                    width: "100%",
                };
                //img prodotto
                t.stileImgProdotto = {
                    minWidth: "360px",
                    height: "auto",
                    cursor: "pointer",
                    backgroundColor: "white",
                    marginLeft: "-2em",
                };
                //stile paragrafo NO maniglioni / alzanti
                t.stileParagrafoNoManiglioneAlzante = {
                    marginTop: "-1em",
                    marginLeft: "0em",
                    marginBottom: "1em",
                    width: "300px",
                };
                //stile icon prodotti
                t.stileIconProdotti = {
                    width: "25px",
                    height: "25px",
                    marginTop: "0em",
                    marginLeft: "0em",
                };
                //linea NO maniglioni / alzanti
                t.stileLineaNoManiglioneAlzante = {
                    marginTop: "-1.5em",
                    marginLeft: "0em",
                    fontSize: "12px",
                };
                //stile row finiture (standard / pvd / retrò)
                t.stileRowFiniture = {
                    minWidth: "100%",
                    marginLeft: "-2.7em",
                    marginTop: "0.3em",
                };
                //stile Img fintire (standard / pvd / retrò)
                t.stileColFinitureImg = {
                    minWidth: "20%",
                    marginTop: "2%",
                };
                //stile Img fintire (standard / pvd / retrò)
                t.stileImgFiniture = {
                    width: "30px",
                    height: "30px",
                };
                //stile extation panel finiture
                t.stileExtationPanel = {
                    minWidth: "90%",
                    height: "0%",
                };
                //stile descrizione extation panel finiture
                t.stileParagrafoDescrizione = {
                    fontSize: "11px",
                    marginTop: "10em",
                    marginLeft: "-4.1em",
                };
            } else if (value > 1050 && value <= 1280) {
                //HD (16:9 / 16:10)
                //stile riga prodotti
                t.stileRowProdotti = {
                    marginLeft: "1em",
                    marginRight: "2em",
                    marginTop: "5em",
                    width: "100%",
                };
                //img prodotto
                t.stileImgProdotto = {
                    minWidth: "360px",
                    height: "auto",
                    cursor: "pointer",
                    backgroundColor: "white",
                    marginLeft: "-2em",
                };
                //stile paragrafo NO maniglioni / alzanti
                t.stileParagrafoNoManiglioneAlzante = {
                    marginTop: "-1em",
                    marginLeft: "0em",
                    marginBottom: "1em",
                    width: "300px",
                };
                //stile icon prodotti
                t.stileIconProdotti = {
                    width: "25px",
                    height: "25px",
                    marginTop: "0em",
                    marginLeft: "0em",
                };
                //linea NO maniglioni / alzanti
                t.stileLineaNoManiglioneAlzante = {
                    marginTop: "-1.5em",
                    marginLeft: "0em",
                    fontSize: "12px",
                };
                //stile row finiture (standard / pvd / retrò)
                t.stileRowFiniture = {
                    minWidth: "100%",
                    marginLeft: "-2.7em",
                    marginTop: "0.3em",
                };
                //stile Img fintire (standard / pvd / retrò)
                t.stileColFinitureImg = {
                    minWidth: "20%",
                    marginTop: "2%",
                };
                //stile Img fintire (standard / pvd / retrò)
                t.stileImgFiniture = {
                    width: "30px",
                    height: "30px",
                };
                //stile extation panel finiture
                t.stileExtationPanel = {
                    minWidth: "90%",
                    height: "0%",
                };
                //stile descrizione extation panel finiture
                t.stileParagrafoDescrizione = {
                    fontSize: "11px",
                    marginTop: "10em",
                    marginLeft: "-4.1em",
                };
            } else if (value > 1280 && value <= 1540) {
                //tablet apple / surface (windows)
                //stile riga prodotti
                t.stileRowProdotti = {
                    marginLeft: "2em",
                    marginRight: "2em",
                    marginTop: "5em",
                    width: "100%",
                };
                //img prodotto
                t.stileImgProdotto = {
                    minWidth: "360px",
                    height: "auto",
                    cursor: "pointer",
                    backgroundColor: "white",
                    marginLeft: "-2em",
                };
                //stile paragrafo NO maniglioni / alzanti
                t.stileParagrafoNoManiglioneAlzante = {
                    marginTop: "-1em",
                    marginLeft: "0em",
                    marginBottom: "1em",
                    width: "300px",
                };
                //stile icon prodotti
                t.stileIconProdotti = {
                    width: "25px",
                    height: "25px",
                    marginTop: "0em",
                    marginLeft: "0em",
                };
                //linea NO maniglioni / alzanti
                t.stileLineaNoManiglioneAlzante = {
                    marginTop: "-1.5em",
                    marginLeft: "0em",
                    fontSize: "12px",
                };
                //stile row finiture (standard / pvd / retrò)
                t.stileRowFiniture = {
                    minWidth: "100%",
                    marginLeft: "-2.7em",
                    marginTop: "0.3em",
                };
                //stile Img fintire (standard / pvd / retrò)
                t.stileColFinitureImg = {
                    minWidth: "20%",
                    marginTop: "2%",
                };
                //stile Img fintire (standard / pvd / retrò)
                t.stileImgFiniture = {
                    width: "30px",
                    height: "30px",
                };
                //stile extation panel finiture
                t.stileExtationPanel = {
                    minWidth: "90%",
                    height: "0%",
                };
                //stile descrizione extation panel finiture
                t.stileParagrafoDescrizione = {
                    fontSize: "11px",
                    marginTop: "10em",
                    marginLeft: "-4.1em",
                };
            } else if (value > 1540 && value <= 1920) {
                //classic pc FULL HD 16:9 / 	16:10 --> 1680
                //stile riga prodotti
                t.stileRowProdotti = {
                    marginLeft: "3em",
                    marginRight: "2em",
                    marginTop: "10em",
                    width: "100%",
                };
                //img prodotto
                t.stileImgProdotto = {
                    minWidth: "360px",
                    height: "auto",
                    cursor: "pointer",
                    backgroundColor: "white",
                    marginLeft: "-2em",
                };
                //stile paragrafo NO maniglioni / alzanti
                t.stileParagrafoNoManiglioneAlzante = {
                    marginTop: "-1em",
                    marginLeft: "0em",
                    marginBottom: "1em",
                    width: "300px",
                };
                //stile icon prodotti
                t.stileIconProdotti = {
                    width: "30px",
                    height: "30px",
                    marginTop: "0em",
                    marginLeft: "0em",
                };
                //linea NO maniglioni / alzanti
                t.stileLineaNoManiglioneAlzante = {
                    marginTop: "-1.5em",
                    marginLeft: "0em",
                    fontSize: "12px",
                };
                //stile row finiture (standard / pvd / retrò)
                t.stileRowFiniture = {
                    minWidth: "100%",
                    marginLeft: "-2.7em",
                    marginTop: "0.3em",
                };
                //stile Img fintire (standard / pvd / retrò)
                t.stileColFinitureImg = {
                    minWidth: "20%",
                    marginTop: "2%",
                };
                //stile Img fintire (standard / pvd / retrò)
                t.stileImgFiniture = {
                    width: "30px",
                    height: "30px",
                };
                //stile extation panel finiture
                t.stileExtationPanel = {
                    minWidth: "90%",
                    height: "0%",
                };
                //stile descrizione extation panel finiture
                t.stileParagrafoDescrizione = {
                    fontSize: "11px",
                    marginTop: "10em",
                    marginLeft: "-4.1em",
                };
            } else if (value > 1920 && value <= 2100) {
                //classic pc (16:10 / 16:9 / 21:9)
            } else if (value > 2100 && value <= 2560) {
                //pc monitor (16:9)
                //stile riga prodotti
                t.stileRowProdotti = {
                    marginLeft: "6em",
                    marginRight: "0em",
                    marginTop: "15em",
                    width: "95%",
                    marginBottom: "20em",
                };
                //stile card prodotti
                t.stileCardProdotti = {
                    marginBottom: "10em",
                    marginLeft: "5em",
                    height: "10em",
                };
                //img prodotto
                t.stileImgProdotto = {
                    minWidth: "500px",
                    height: "auto",
                    cursor: "pointer",
                    backgroundColor: "white",
                    marginLeft: "-2em",
                };
                //stile icon prodotti
                t.stileIconProdotti = {
                    width: "30px",
                    height: "30px",
                    marginTop: "0em",
                    marginLeft: "0em",
                };
                //stile paragrafo NO maniglioni / alzanti
                t.stileParagrafoNoManiglioneAlzante = {
                    marginTop: "-1em",
                    marginLeft: "0em",
                    marginBottom: "1em",
                    width: "450px",
                };
                //linea NO maniglioni / alzanti
                t.stileLineaNoManiglioneAlzante = {
                    marginTop: "-1.5em",
                    marginLeft: "0em",
                    fontSize: "12px",
                };
                //stile extation panel finiture
                t.stileExtationPanel = {
                    minWidth: "200%",
                    height: "0%",
                    marginLeft: "0em",
                };
                //stile descrizione extation panel finiture
                t.stileParagrafoDescrizione = {
                    fontSize: "11px",
                    marginTop: "8em",
                    marginLeft: "8.5em",
                };
                //stile row finiture (standard / pvd / retrò)
                t.stileRowFiniture = {
                    minWidth: "100%",
                    marginLeft: "6em",
                    marginTop: "0.3em",
                };
                //stile Img fintire (standard / pvd / retrò)
                t.stileColFinitureImg = {
                    minWidth: "20%",
                    marginTop: "2%",
                };
            } else if (value > 2560 && value <= 3840) {
                //TV 4K
                //stile riga prodotti
                t.stileRowProdotti = {
                    marginLeft: "10em",
                    marginRight: "0em",
                    marginTop: "15em",
                    width: "95%",
                    marginBottom: "25em",
                };
                //stile card prodotti
                t.stileCardProdotti = {
                    marginBottom: "10em",
                    marginLeft: "15em",
                    height: "10em",
                };
                //img prodotto
                t.stileImgProdotto = {
                    minWidth: "550px",
                    height: "auto",
                    cursor: "pointer",
                    backgroundColor: "white",
                    marginLeft: "-2em",
                };
                //stile icon prodotti
                t.stileIconProdotti = {
                    width: "40px",
                    height: "40px",
                    marginTop: "0em",
                    marginLeft: "0em",
                };
                //stile paragrafo NO maniglioni / alzanti
                t.stileParagrafoNoManiglioneAlzante = {
                    marginTop: "-1em",
                    marginLeft: "0em",
                    marginBottom: "1em",
                    width: "500px",
                };
                //linea NO maniglioni / alzanti
                t.stileLineaNoManiglioneAlzante = {
                    marginTop: "-1.5em",
                    marginLeft: "0em",
                    fontSize: "18px",
                };
                //stile extation panel finiture
                t.stileExtationPanel = {
                    minWidth: "200%",
                    height: "150%",
                    marginLeft: "29em",
                };
                //stile descrizione extation panel finiture
                t.stileParagrafoDescrizione = {
                    fontSize: "16px",
                    marginTop: "16em",
                    marginLeft: "0em",
                };
                //stile row finiture (standard / pvd / retrò)
                t.stileRowFiniture = {
                    minWidth: "100%",
                    marginLeft: "0em",
                    marginTop: "0.3em",
                };
                //stile Img fintire (standard / pvd / retrò)
                t.stileColFinitureImg = {
                    minWidth: "20%",
                    marginTop: "2%",
                };
            }
        },

        //MENU SCORREVOLI
        getStiliMenuScorrevoli(t, value) {
            //controll dimension display devices
            //fare proporzione tra una dimensione e l'altra per scalare tutti gli stili //2340 --> 4K = +50%
            t.stileRowProdotti = {}; //riga prodotti
            t.stileCardProdotti = {}; //card img prodotto
            t.stileImgProdotto = {}; //img prodotto
            t.stileParagrafoNoManiglioneAlzante = {}; //paragrafo NO maniglioni / alzanti
            t.stileLineaNoManiglioneAlzante = {}; //linea NO maniglioni / alzanti
            t.stileIconProdotti = {}; // icons prodotti
            t.stileParagrafoDescrizione = {}; //paragrafo descrizione
            t.stilePanelNoManiglioneAlzante = {}; //paragrafo NO maniglioni / alzanti
            t.stileRowFiniture = {}; //riga finiture
            t.stileColFinitureImg = {}; //colonna finiture
            t.stileImgFiniture = {}; //img finiture

            if (value <= 768) {
                //smartphone
                //stile riga prodotti
                t.stileRowProdotti = {
                    marginTop: "-3em",
                    marginLeft: "0em",
                    marginRight: "0em",
                    width: "100%",
                };
                //stile paragrafo NO maniglioni / alzanti
                t.stileParagrafoNoManiglioneAlzante = {
                    marginTop: "-2em",
                    marginLeft: "0em",
                    width: "300px",
                };
                //img prodotto
                t.stileImgProdotto = {
                    minWidth: "360px",
                    height: "auto",
                    cursor: "pointer",
                    backgroundColor: "white",
                    marginLeft: "-2em",
                };
                //linea NO maniglioni / alzanti
                t.stileLineaNoManiglioneAlzante = {
                    marginTop: "-1.5em",
                    marginLeft: "0em",
                    fontSize: "12px",
                };
                //stile icon prodotti
                t.stileIconProdotti = {
                    width: "25px",
                    height: "25px",
                    marginTop: "0em",
                    marginLeft: "0em",
                };
                //paragrafo NO maniglioni / alzanti
                t.stilePanelNoManiglioneAlzante = {
                    width: "100%",
                    height: "150%",
                    marginLeft: "0em",
                };
                //paragrafo descrizione
                t.stileParagrafoDescrizione = {
                    fontSize: "11px",
                    marginTop: "2.9em",
                    marginLeft: "-4.1em",
                };
                //stile row finiture (standard / pvd / retrò)
                t.stileRowFiniture = {
                    minWidth: "130%",
                    marginLeft: "-2.7em",
                    marginTop: "-0.5em",
                };
                //stile Col Img fintire (standard / pvd / retrò)
                t.stileColFinitureImg = {
                    minWidth: "20%",
                    marginTop: "5%",
                };
                //stile Img fintire (standard / pvd / retrò)
                t.stileImgFiniture = {
                    width: "25px",
                    height: "25px",
                };
            } else if (value > 768 && value <= 1050) {
                //tablet (1024)
                //stile riga prodotti
                t.stileRowProdotti = {
                    marginLeft: "2em",
                    marginRight: "2em",
                    marginTop: "-4em",
                    width: "100%",
                    marginBottom: "-1em",
                };
                //stile paragrafo NO maniglioni / alzanti
                t.stileParagrafoNoManiglioneAlzante = {
                    marginTop: "-2em",
                    marginLeft: "0em",
                    width: "300px",
                };
                //img prodotto
                t.stileImgProdotto = {
                    minWidth: "360px",
                    height: "auto",
                    cursor: "pointer",
                    backgroundColor: "white",
                    marginLeft: "-2em",
                };
                //linea NO maniglioni / alzanti
                t.stileLineaNoManiglioneAlzante = {
                    marginTop: "-1.5em",
                    marginLeft: "0em",
                    fontSize: "12px",
                };
                //stile icon prodotti
                t.stileIconProdotti = {
                    width: "25px",
                    height: "25px",
                    marginTop: "0em",
                    marginLeft: "0em",
                };
                //paragrafo NO maniglioni / alzanti
                t.stilePanelNoManiglioneAlzante = {
                    width: "100%",
                    height: "150%",
                    marginLeft: "0em",
                };
                //paragrafo descrizione
                t.stileParagrafoDescrizione = {
                    fontSize: "11px",
                    marginTop: "2.9em",
                    marginLeft: "-4.1em",
                };
                //stile row finiture (standard / pvd / retrò)
                t.stileRowFiniture = {
                    minWidth: "100%",
                    marginLeft: "-3em",
                    marginTop: "0.3em",
                };
                //stile Img fintire (standard / pvd / retrò)
                t.stileColFinitureImg = {
                    minWidth: "20%",
                    marginTop: "2%",
                };
                //stile Img fintire (standard / pvd / retrò)
                t.stileImgFiniture = {
                    width: "30px",
                    height: "30px",
                };
            } else if (value > 1050 && value <= 1280) {
                //HD (16:9 / 16:10)
                //stile riga prodotti
                t.stileRowProdotti = {
                    marginLeft: "2em",
                    marginRight: "2em",
                    marginTop: "-4em",
                    width: "100%",
                    marginBottom: "-1em",
                };
                //stile paragrafo NO maniglioni / alzanti
                t.stileParagrafoNoManiglioneAlzante = {
                    marginTop: "-2em",
                    marginLeft: "0em",
                    width: "300px",
                };
                //img prodotto
                t.stileImgProdotto = {
                    minWidth: "360px",
                    height: "auto",
                    cursor: "pointer",
                    backgroundColor: "white",
                    marginLeft: "-2em",
                };
                //linea NO maniglioni / alzanti
                t.stileLineaNoManiglioneAlzante = {
                    marginTop: "-1.5em",
                    marginLeft: "0em",
                    fontSize: "12px",
                };
                //stile icon prodotti
                t.stileIconProdotti = {
                    width: "25px",
                    height: "25px",
                    marginTop: "0em",
                    marginLeft: "0em",
                };
                //paragrafo NO maniglioni / alzanti
                t.stilePanelNoManiglioneAlzante = {
                    width: "100%",
                    height: "150%",
                    marginLeft: "0em",
                };
                //paragrafo descrizione
                t.stileParagrafoDescrizione = {
                    fontSize: "11px",
                    marginTop: "2.9em",
                    marginLeft: "-4.1em",
                };
                //stile row finiture (standard / pvd / retrò)
                t.stileRowFiniture = {
                    minWidth: "100%",
                    marginLeft: "-3em",
                    marginTop: "0.3em",
                };
                //stile Img fintire (standard / pvd / retrò)
                t.stileColFinitureImg = {
                    minWidth: "20%",
                    marginTop: "2%",
                };
                //stile Img fintire (standard / pvd / retrò)
                t.stileImgFiniture = {
                    width: "30px",
                    height: "30px",
                };
            } else if (value > 1280 && value <= 1540) {
                //tablet apple / surface (windows)
                //stile riga prodotti
                t.stileRowProdotti = {
                    marginLeft: "3em",
                    marginRight: "2em",
                    marginTop: "-4em",
                    width: "100%",
                    marginBottom: "-1em",
                };
                //stile paragrafo NO maniglioni / alzanti
                t.stileParagrafoNoManiglioneAlzante = {
                    marginTop: "-2em",
                    marginLeft: "0em",
                    width: "300px",
                };
                //linea NO maniglioni / alzanti
                t.stileLineaNoManiglioneAlzante = {
                    marginTop: "-1.5em",
                    marginLeft: "0em",
                    fontSize: "12px",
                };
                //img prodotto
                t.stileImgProdotto = {
                    minWidth: "360px",
                    height: "auto",
                    cursor: "pointer",
                    backgroundColor: "white",
                    marginLeft: "-2em",
                };
                //stile icon prodotti
                t.stileIconProdotti = {
                    width: "25px",
                    height: "25px",
                    marginTop: "0em",
                    marginLeft: "0em",
                };
                //paragrafo NO maniglioni / alzanti
                t.stilePanelNoManiglioneAlzante = {
                    width: "100%",
                    height: "150%",
                    marginLeft: "0em",
                };
                //paragrafo descrizione
                t.stileParagrafoDescrizione = {
                    fontSize: "11px",
                    marginTop: "2.9em",
                    marginLeft: "-4.1em",
                };
                //stile row finiture (standard / pvd / retrò)
                t.stileRowFiniture = {
                    minWidth: "100%",
                    marginLeft: "-2.8em",
                    marginTop: "-0.2em",
                };
                //stile Img fintire (standard / pvd / retrò)
                t.stileColFinitureImg = {
                    minWidth: "20%",
                    marginTop: "2%",
                };
                //stile Img fintire (standard / pvd / retrò)
                t.stileImgFiniture = {
                    width: "30px",
                    height: "30px",
                };
            } else if (value > 1540 && value <= 1920) {
                //classic pc FULL HD 16:9 / 	16:10 --> 1680
                //stile riga prodotti
                t.stileRowProdotti = {
                    marginLeft: "3em",
                    marginRight: "2em",
                    marginTop: "-3em",
                    width: "100%",
                    marginBottom: "-1em",
                };
                //stile paragrafo NO maniglioni / alzanti
                t.stileParagrafoNoManiglioneAlzante = {
                    marginTop: "-2em",
                    marginLeft: "0em",
                    width: "300px",
                };
                //linea NO maniglioni / alzanti
                t.stileLineaNoManiglioneAlzante = {
                    marginTop: "-1.5em",
                    marginLeft: "0em",
                    fontSize: "12px",
                };
                //img prodotto
                t.stileImgProdotto = {
                    minWidth: "360px",
                    height: "auto",
                    cursor: "pointer",
                    backgroundColor: "white",
                    marginLeft: "-2em",
                };
                //stile icon prodotti
                t.stileIconProdotti = {
                    width: "30px",
                    height: "30px",
                    marginTop: "0em",
                    marginLeft: "0em",
                };
                //paragrafo NO maniglioni / alzanti
                t.stilePanelNoManiglioneAlzante = {
                    width: "100%",
                    height: "150%",
                    marginLeft: "0em",
                };
                //paragrafo descrizione
                t.stileParagrafoDescrizione = {
                    fontSize: "11px",
                    marginTop: "2.9em",
                    marginLeft: "-4.1em",
                };
                //stile row finiture (standard / pvd / retrò)
                t.stileRowFiniture = {
                    minWidth: "100%",
                    marginLeft: "-2.8em",
                    marginTop: "-0.2em",
                };
                //stile Img fintire (standard / pvd / retrò)
                t.stileColFinitureImg = {
                    minWidth: "20%",
                    marginTop: "2%",
                };
                //stile Img fintire (standard / pvd / retrò)
                t.stileImgFiniture = {
                    width: "30px",
                    height: "30px",
                };
            } else if (value > 1920 && value <= 2100) {
                //classic pc (16:10 / 16:9 / 21:9)
            } else if (value > 2100 && value <= 2560) {
                //pc monitor (16:9)
                //stile riga prodotti
                t.stileRowProdotti = {
                    marginLeft: "6em",
                    marginRight: "0em",
                    marginTop: "2em",
                    width: "95%",
                    marginBottom: "8em",
                };
                //stile card prodotti
                t.stileCardProdotti = {
                    marginBottom: "10em",
                    marginLeft: "5em",
                    height: "10em",
                };
                //img prodotto
                t.stileImgProdotto = {
                    minWidth: "500px",
                    height: "auto",
                    cursor: "pointer",
                    backgroundColor: "white",
                    marginLeft: "-2em",
                };
                //stile paragrafo NO maniglioni / alzanti
                t.stileParagrafoNoManiglioneAlzante = {
                    marginTop: "-1em",
                    marginLeft: "2em",
                    width: "390px",
                };
                //linea NO maniglioni / alzanti
                t.stileLineaNoManiglioneAlzante = {
                    marginTop: "-1.5em",
                    marginLeft: "3.3em",
                    fontSize: "12px",
                };
                //stile icon prodotti
                t.stileIconProdotti = {
                    width: "30px",
                    height: "30px",
                    marginTop: "0em",
                    marginLeft: "0em",
                };
                //paragrafo NO maniglioni / alzanti
                t.stilePanelNoManiglioneAlzante = {
                    width: "200%",
                    height: "150%",
                    marginLeft: "0em",
                };
                //paragrafo descrizione
                t.stileParagrafoDescrizione = {
                    fontSize: "11px",
                    marginTop: "7.5em",
                    marginLeft: "-7em",
                };
                //stile row finiture (standard / pvd / retrò)
                t.stileRowFiniture = {
                    minWidth: "100%",
                    marginLeft: "-5em",
                    marginTop: "-0.2em",
                };
                //stile Img fintire (standard / pvd / retrò)
                t.stileColFinitureImg = {
                    minWidth: "20%",
                    marginTop: "2%",
                };
                //stile Img fintire (standard / pvd / retrò)
                t.stileImgFiniture = {
                    width: "30px",
                    height: "30px",
                };
            } else if (value > 2560 && value <= 3840) {
                //TV 4K
                //stile riga prodotti
                t.stileRowProdotti = {
                    marginLeft: "10em",
                    marginRight: "0em",
                    marginTop: "5em",
                    width: "95%",
                    marginBottom: "20em",
                };
                //stile card prodotti
                t.stileCardProdotti = {
                    marginBottom: "10em",
                    marginLeft: "15em",
                    height: "10em",
                };
                //stile paragrafo NO maniglioni / alzanti
                t.stileParagrafoNoManiglioneAlzante = {
                    marginTop: "-1em",
                    marginLeft: "2em",
                    width: "500px",
                };
                //img prodotto
                t.stileImgProdotto = {
                    minWidth: "650px",
                    height: "auto",
                    cursor: "pointer",
                    backgroundColor: "white",
                    marginLeft: "0em",
                };
                //stile icon prodotti
                t.stileIconProdotti = {
                    width: "40px",
                    height: "40px",
                    marginTop: "0em",
                    marginLeft: "0em",
                };
                //linea NO maniglioni / alzanti
                t.stileLineaNoManiglioneAlzante = {
                    marginTop: "-1.5em",
                    marginLeft: "3.8em",
                    fontSize: "18px",
                };
                //paragrafo NO maniglioni / alzanti
                t.stilePanelNoManiglioneAlzante = {
                    minWidth: "205%",
                    height: "auto",
                    marginLeft: "0em",
                };
                //paragrafo descrizione
                t.stileParagrafoDescrizione = {
                    fontSize: "16px",
                    marginTop: "11em",
                    marginLeft: "0em",
                };
                //stile row finiture (standard / pvd / retrò)
                t.stileRowFiniture = {
                    minWidth: "85%",
                    marginLeft: "0em",
                    marginTop: "0em",
                };
                //stile Img fintire (standard / pvd / retrò)
                t.stileColFinitureImg = {
                    minWidth: "20%",
                    marginTop: "2%",
                };
                //stile Img fintire (standard / pvd / retrò)
                t.stileImgFiniture = {
                    width: "40px",
                    height: "40px",
                };
            }
        },

        //MENU QUADRO
        getStiliMenuQuadro(t, value) {
            //controll dimension display devices
            //fare proporzione tra una dimensione e l'altra per scalare tutti gli stili //2340 --> 4K = +50%
            t.stileRowProdotti = {}; //riga prodotti
            t.stileIconProdotti = {}; // icons prodotti
            t.stileColFinitureImg = {}; //colonna finiture
            t.stileExtationPanel = {}; //extation panel finiture
            t.stileCardProdotti = {}; // card prodotti
            t.stileImgProdottoNoManiglioneAlzante = {}; //img prodotti NO maniglioni / alzanti
            t.stileImgProdottoSiManiglioneAlzante = {}; //img prodotti SI maniglioni / alzanti
            t.stileParagrafoNoManiglioneAlzante = {}; //paragrafo NO maniglioni / alzanti
            t.stileParagrafoSiManiglioneAlzante = {}; //paragrafo SI maniglioni / alzanti
            t.stileLineaNoManiglioneAlzante = {}; //linea NO maniglioni / alzanti
            t.stileLineaSiManiglioneAlzante = {}; //linea SI maniglioni / alzanti
            t.stilePanelNoManiglioneAlzante = {}; //paragrafo extation panel NO maniglioni / alzanti
            t.stilePanelSiManiglioneAlzante = {}; //paragrafo extation panel SI maniglioni / alzanti
            t.stileFinitureStandardNoManiglioneAlzante = {}; //finiture standard NO maniglioni / alzanti
            t.stileFinitureStandardSiManiglioneAlzante = {}; //finiture standard SI maniglioni / alzanti
            t.stileFinitureStandardSiScorrevoli = {}; //finiture standard scorrevoli

            if (value <= 768) {
                //smartphone
                //img prodotto NO maniglioni / alzanti
                t.stileImgProdottoNoManiglioneAlzante = {
                    minWidth: "371px",
                    height: "auto",
                    cursor: "pointer",
                    backgroundColor: "white",
                    marginLeft: "-2em",
                    marginTop: "3.3em",
                    marginBottom: "6.3em",
                };
                //img prodotto SI maniglioni / alzanti
                t.stileImgProdottoSiManiglioneAlzante = {
                    height: "0px",
                    minWidth: "auto",
                    cursor: "pointer",
                    backgroundColor: "white",
                    marginLeft: "-2em",
                    marginTop: "0em",
                };
                //stile riga prodotti
                t.stileRowProdotti = {
                    marginTop: "-3em",
                    marginLeft: "0em",
                    marginRight: "0em",
                    width: "100%",
                    marginBottom: "0em",
                };
                //stile icon prodotti
                t.stileIconProdotti = {
                    width: "25px",
                    height: "25px",
                    marginTop: "0em",
                    marginLeft: "0em",
                };
                //stile Col Img fintire (standard / pvd / retrò)
                t.stileColFinitureImg = {
                    minWidth: "20%",
                    marginTop: "5%",
                };
                //stile extation panel finiture
                t.stileExtationPanel = {
                    width: "87%",
                    height: "0%",
                    marginTop: "-2em",
                };
                //stile card prodotti
                t.stileCardProdotti = {
                    marginBottom: "15em",
                    marginLeft: "0em",
                    height: "auto",
                };
                //stile img SI maniglioni / alzanti
                t.stileImgProdottoSiManiglioneAlzante.height = "500px";
                //stile paragrafo NO maniglioni / alzanti
                t.stileParagrafoNoManiglioneAlzante = {
                    marginTop: "-2em",
                    width: "300px",
                };
                //stile paragrafo SI maniglioni / alzanti
                t.stileParagrafoSiManiglioneAlzante = {
                    marginLeft: "-0.9em",
                    marginTop: "-2.2em",
                    width: "300px",
                };
                //paragrafo extation panel NO maniglioni / alzanti
                t.stilePanelNoManiglioneAlzante = {
                    marginTop: "6em",
                    marginLeft: "-4.5em",
                    fontSize: "11px",
                };
                //paragrafo extation panel SI maniglioni / alzanti
                t.stilePanelSiManiglioneAlzante = {
                    marginTop: "12em",
                    marginLeft: "-4.5em",
                    fontSize: "11px",
                };
                //linea NO maniglioni / alzanti
                t.stileLineaNoManiglioneAlzante = {
                    marginTop: "-1.5em",
                    marginLeft: "0em",
                    fontSize: "12px",
                };
                //linea SI maniglioni / alzanti
                t.stileLineaSiManiglioneAlzante = {
                    marginTop: "-1.5em",
                    marginLeft: "-1.5em",
                    fontSize: "12px",
                };
                //finiture standard NO maniglioni / alzanti
                t.stileFinitureStandardNoManiglioneAlzante = {
                    minWidth: "120%",
                    marginLeft: "-3.3em",
                    marginTop: "0em",
                };
                //finiture standard SI maniglioni / alzanti
                t.stileFinitureStandardSiManiglioneAlzante = {
                    width: "120%",
                    marginLeft: "-3.3em",
                    marginTop: "0em",
                };
                //finiture standard scorrevoli
                t.stileFinitureStandardSiScorrevoli = {
                    minWidth: "130%",
                    marginLeft: "-2.7em",
                    marginTop: "-0.5em",
                };
            } else if (value > 768 && value <= 1050) {
                //tablet (1024)
                //img prodotto NO maniglioni / alzanti
                t.stileImgProdottoNoManiglioneAlzante = {
                    minWidth: "371px",
                    height: "auto",
                    cursor: "pointer",
                    backgroundColor: "white",
                    marginLeft: "-2em",
                    marginTop: "3.3em",
                    marginBottom: "6.3em",
                };
                //img prodotto SI maniglioni / alzanti
                t.stileImgProdottoSiManiglioneAlzante = {
                    height: "0px",
                    minWidth: "auto",
                    cursor: "pointer",
                    backgroundColor: "white",
                    marginLeft: "-2em",
                    marginTop: "0em",
                };
                //stile riga prodotti
                t.stileRowProdotti = {
                    marginLeft: "2em",
                    marginRight: "2em",
                    marginTop: "-1.5em",
                    width: "100%",
                    marginBottom: "0em",
                };
                //stile icon prodotti
                t.stileIconProdotti = {
                    width: "25px",
                    height: "25px",
                    marginTop: "0em",
                    marginLeft: "0em",
                };
                //stile Img fintire (standard / pvd / retrò)
                t.stileColFinitureImg = {
                    minWidth: "20%",
                    marginTop: "2%",
                };
                //stile extation panel finiture
                t.stileExtationPanel = {
                    width: "90%",
                    height: "0%",
                    marginTop: "-1em",
                };
                //stile card prodotti
                t.stileCardProdotti = {
                    marginBottom: "10em",
                    marginLeft: "0em",
                    height: "auto",
                };
                //stile img SI maniglioni / alzanti
                t.stileImgProdottoSiManiglioneAlzante.height = "385px";
                //stile paragrafo NO maniglioni / alzanti
                t.stileParagrafoNoManiglioneAlzante = {
                    marginTop: "0em",
                    width: "300px",
                };
                //stile paragrafo SI maniglioni / alzanti
                t.stileParagrafoSiManiglioneAlzante = {
                    marginTop: "-2.2em",
                    marginLeft: "0em",
                    width: "300px",
                };
                //paragrafo extation panel NO maniglioni / alzanti
                t.stilePanelNoManiglioneAlzante = {
                    marginTop: "6em",
                    marginLeft: "-4.1em",
                    fontSize: "11px",
                };
                //paragrafo extation panel SI maniglioni / alzanti
                t.stilePanelSiManiglioneAlzante = {
                    marginTop: "6em",
                    marginLeft: "-2.3em",
                    fontSize: "11px",
                };
                //linea NO maniglioni / alzanti
                t.stileLineaNoManiglioneAlzante = {
                    marginTop: "-1.5em",
                    marginLeft: "0em",
                    fontSize: "12px",
                };
                //linea SI maniglioni / alzanti
                t.stileLineaSiManiglioneAlzante = {
                    marginTop: "-1.5em",
                    marginLeft: "0em",
                    fontSize: "12px",
                };
                //finiture standard NO maniglioni / alzanti
                t.stileFinitureStandardNoManiglioneAlzante = {
                    minWidth: "100%",
                    marginLeft: "-2.8em",
                    marginTop: "0em",
                };
                //finiture standard SI maniglioni / alzanti
                t.stileFinitureStandardSiManiglioneAlzante = {
                    width: "120%",
                    marginLeft: "-1.7em",
                    marginTop: "0em",
                };
                //finiture standard scorrevoli
                t.stileFinitureStandardSiScorrevoli = {
                    minWidth: "100%",
                    marginLeft: "-3em",
                    marginTop: "0.3em",
                };
            } else if (value > 1050 && value <= 1280) {
                //HD (16:9 / 16:10)
                //img prodotto NO maniglioni / alzanti
                t.stileImgProdottoNoManiglioneAlzante = {
                    minWidth: "371px",
                    height: "auto",
                    cursor: "pointer",
                    backgroundColor: "white",
                    marginLeft: "-2em",
                    marginTop: "3.3em",
                    marginBottom: "6.3em",
                };
                //img prodotto SI maniglioni / alzanti
                t.stileImgProdottoSiManiglioneAlzante = {
                    height: "0px",
                    minWidth: "auto",
                    cursor: "pointer",
                    backgroundColor: "white",
                    marginLeft: "-2em",
                    marginTop: "0em",
                };
                //stile riga prodotti
                t.stileRowProdotti = {
                    marginLeft: "2em",
                    marginRight: "2em",
                    marginTop: "-1em",
                    width: "100%",
                    marginBottom: "1em",
                };
                //stile icon prodotti
                t.stileIconProdotti = {
                    width: "25px",
                    height: "25px",
                    marginTop: "0em",
                    marginLeft: "0em",
                };
                //stile Img fintire (standard / pvd / retrò)
                t.stileColFinitureImg = {
                    minWidth: "20%",
                    marginTop: "2%",
                };
                //stile extation panel finiture
                t.stileExtationPanel = {
                    width: "90%",
                    height: "0%",
                    marginTop: "-1em",
                };
                //stile card prodotti
                t.stileCardProdotti = {
                    marginBottom: "10em",
                    marginLeft: "0em",
                    height: "auto",
                };
                //stile img SI maniglioni / alzanti
                t.stileImgProdottoSiManiglioneAlzante.height = "385px";
                //stile paragrafo NO maniglioni / alzanti
                t.stileParagrafoNoManiglioneAlzante = {
                    marginTop: "0em",
                    width: "300px",
                };
                //stile paragrafo SI maniglioni / alzanti
                t.stileParagrafoSiManiglioneAlzante = {
                    marginTop: "-2.2em",
                    marginLeft: "0em",
                    width: "300px",
                };
                //paragrafo extation panel NO maniglioni / alzanti
                t.stilePanelNoManiglioneAlzante = {
                    marginTop: "6em",
                    marginLeft: "-4.1em",
                    fontSize: "11px",
                };
                //paragrafo extation panel SI maniglioni / alzanti
                t.stilePanelSiManiglioneAlzante = {
                    marginTop: "6em",
                    marginLeft: "-2.3em",
                    fontSize: "11px",
                };
                //linea NO maniglioni / alzanti
                t.stileLineaNoManiglioneAlzante = {
                    marginTop: "-1.5em",
                    marginLeft: "0em",
                    fontSize: "12px",
                };
                //linea SI maniglioni / alzanti
                t.stileLineaSiManiglioneAlzante = {
                    marginTop: "-1.5em",
                    marginLeft: "0em",
                };
                //finiture standard NO maniglioni / alzanti
                t.stileFinitureStandardNoManiglioneAlzante = {
                    minWidth: "100%",
                    marginLeft: "-2.8em",
                    marginTop: "0em",
                };
                //finiture standard SI maniglioni / alzanti
                t.stileFinitureStandardSiManiglioneAlzante = {
                    width: "120%",
                    marginLeft: "-1.7em",
                    marginTop: "0em",
                };
                //finiture standard scorrevoli
                t.stileFinitureStandardSiScorrevoli = {
                    minWidth: "100%",
                    marginLeft: "-3em",
                    marginTop: "0.3em",
                };
            } else if (value > 1280 && value <= 1540) {
                //tablet apple / surface (windows)
                //img prodotto NO maniglioni / alzanti
                t.stileImgProdottoNoManiglioneAlzante = {
                    minWidth: "371px",
                    height: "auto",
                    cursor: "pointer",
                    backgroundColor: "white",
                    marginLeft: "-2em",
                    marginTop: "3.3em",
                    marginBottom: "6.3em",
                };
                //img prodotto SI maniglioni / alzanti
                t.stileImgProdottoSiManiglioneAlzante = {
                    height: "0px",
                    minWidth: "auto",
                    cursor: "pointer",
                    backgroundColor: "white",
                    marginLeft: "-2em",
                    marginTop: "0em",
                };
                //stile riga prodotti
                t.stileRowProdotti = {
                    marginLeft: "2em",
                    marginRight: "2em",
                    marginTop: "-2em",
                    width: "100%",
                    marginBottom: "0em",
                };
                //stile icon prodotti
                t.stileIconProdotti = {
                    width: "25px",
                    height: "25px",
                    marginTop: "0em",
                    marginLeft: "0em",
                };
                //stile Img fintire (standard / pvd / retrò)
                t.stileColFinitureImg = {
                    minWidth: "20%",
                    marginTop: "2%",
                };
                //stile extation panel finiture
                t.stileExtationPanel = {
                    width: "90%",
                    height: "0%",
                    marginTop: "-1em",
                };
                //stile card prodotti
                t.stileCardProdotti = {
                    marginBottom: "10em",
                    marginLeft: "0em",
                    height: "auto",
                };
                //stile img SI maniglioni / alzanti
                t.stileImgProdottoSiManiglioneAlzante.height = "385px";
                //stile paragrafo NO maniglioni / alzanti
                t.stileParagrafoNoManiglioneAlzante = {
                    marginTop: "0em",
                    width: "300px",
                };
                //stile paragrafo SI maniglioni / alzanti
                t.stileParagrafoSiManiglioneAlzante = {
                    marginLeft: "-1em",
                    marginTop: "-2.2em",
                    width: "300px",
                };
                //paragrafo extation panel NO maniglioni / alzanti
                t.stilePanelNoManiglioneAlzante = {
                    marginTop: "6em",
                    marginLeft: "-4.1em",
                    fontSize: "11px",
                };
                //paragrafo extation panel SI maniglioni / alzanti
                t.stilePanelSiManiglioneAlzante = {
                    marginTop: "6em",
                    marginLeft: "-4.1em",
                    fontSize: "11px",
                };
                //linea NO maniglioni / alzanti
                t.stileLineaNoManiglioneAlzante = {
                    marginTop: "-1.5em",
                    marginLeft: "0em",
                    fontSize: "12px",
                };
                //linea SI maniglioni / alzanti
                t.stileLineaSiManiglioneAlzante = {
                    marginTop: "-1.5em",
                    marginLeft: "-1.6em",
                    fontSize: "12px",
                };
                //finiture standard NO maniglioni / alzanti
                t.stileFinitureStandardNoManiglioneAlzante = {
                    minWidth: "100%",
                    marginLeft: "-2.8em",
                    marginTop: "0em",
                };
                //finiture standard SI maniglioni / alzanti
                t.stileFinitureStandardSiManiglioneAlzante = {
                    width: "120%",
                    marginLeft: "-2.8em",
                    marginTop: "0em",
                };
                //finiture standard scorrevoli
                t.stileFinitureStandardSiScorrevoli = {
                    minWidth: "100%",
                    marginLeft: "-3em",
                    marginTop: "0.3em",
                };
            } else if (value > 1540 && value <= 1920) {
                //classic pc FULL HD 16:9 / 	16:10 --> 1680
                //img prodotto NO maniglioni / alzanti
                t.stileImgProdottoNoManiglioneAlzante = {
                    minWidth: "371px",
                    height: "auto",
                    cursor: "pointer",
                    backgroundColor: "white",
                    marginLeft: "-2em",
                    marginTop: "3.3em",
                    marginBottom: "6.3em",
                };
                //img prodotto SI maniglioni / alzanti
                t.stileImgProdottoSiManiglioneAlzante = {
                    height: "0px",
                    minWidth: "auto",
                    cursor: "pointer",
                    backgroundColor: "white",
                    marginLeft: "-2em",
                    marginTop: "1em",
                };
                //stile riga prodotti
                t.stileRowProdotti = {
                    marginLeft: "3em",
                    marginRight: "2em",
                    marginTop: "1em",
                    width: "100%",
                    marginBottom: "0em",
                };
                //stile icon prodotti
                t.stileIconProdotti = {
                    width: "30px",
                    height: "30px",
                    marginTop: "0em",
                    marginLeft: "0em",
                };
                //stile Img fintire (standard / pvd / retrò)
                t.stileColFinitureImg = {
                    minWidth: "20%",
                    marginTop: "2%",
                };
                //stile extation panel finiture
                t.stileExtationPanel = {
                    width: "90%",
                    height: "0%",
                    marginTop: "-1em",
                };
                //stile card prodotti
                t.stileCardProdotti = {
                    marginBottom: "10em",
                    marginLeft: "0em",
                    height: "auto",
                };
                //stile img SI maniglioni / alzanti
                t.stileImgProdottoSiManiglioneAlzante.height = "385px";
                //stile paragrafo NO maniglioni / alzanti
                t.stileParagrafoNoManiglioneAlzante = {
                    marginTop: "0em",
                    width: "300px",
                };
                //stile paragrafo SI maniglioni / alzanti
                t.stileParagrafoSiManiglioneAlzante = {
                    marginTop: "-3em",
                    width: "300px",
                    marginLeft: "-1em",
                };
                //paragrafo extation panel NO maniglioni / alzanti
                t.stilePanelNoManiglioneAlzante = {
                    marginTop: "6em",
                    marginLeft: "-4.1em",
                    fontSize: "11px",
                };
                //paragrafo extation panel SI maniglioni / alzanti
                t.stilePanelSiManiglioneAlzante = {
                    marginTop: "6em",
                    marginLeft: "-4.1em",
                    fontSize: "11px",
                };
                //linea NO maniglioni / alzanti
                t.stileLineaNoManiglioneAlzante = {
                    marginTop: "-1.5em",
                    marginLeft: "0em",
                    fontSize: "12px",
                };
                //linea SI maniglioni / alzanti
                t.stileLineaSiManiglioneAlzante = {
                    marginTop: "-1.5em",
                    marginLeft: "-1.6em",
                    fontSize: "12px",
                };
                //finiture standard NO maniglioni / alzanti
                t.stileFinitureStandardNoManiglioneAlzante = {
                    minWidth: "100%",
                    marginLeft: "-2.8em",
                    marginTop: "0em",
                };
                //finiture standard SI maniglioni / alzanti
                t.stileFinitureStandardSiManiglioneAlzante = {
                    width: "120%",
                    marginLeft: "-2.8em",
                    marginTop: "0em",
                };
                //finiture standard scorrevoli
                t.stileFinitureStandardSiScorrevoli = {
                    minWidth: "100%",
                    marginLeft: "-3em",
                    marginTop: "0.3em",
                };
            } else if (value > 1920 && value <= 2100) {
                //classic pc (16:10 / 16:9 / 21:9)
            } else if (value > 2100 && value <= 2560) {
                //pc monitor (16:9)
                //img prodotto NO maniglioni / alzanti
                t.stileImgProdottoNoManiglioneAlzante = {
                    minWidth: "500px",
                    height: "auto",
                    cursor: "pointer",
                    backgroundColor: "white",
                    marginLeft: "0em",
                    marginTop: "9.5em",
                    marginBottom: "0em",
                };
                //img prodotto SI maniglioni / alzanti
                t.stileImgProdottoSiManiglioneAlzante = {
                    height: "650px",
                    minWidth: "auto",
                    cursor: "pointer",
                    backgroundColor: "white",
                    marginLeft: "-1em",
                    marginTop: "-5em",
                };
                //stile riga prodotti
                t.stileRowProdotti = {
                    marginLeft: "7em",
                    marginRight: "0em",
                    marginTop: "-2em",
                    width: "95%",
                    marginBottom: "25em",
                };
                //stile card prodotti
                t.stileCardProdotti = {
                    marginBottom: "10em",
                    marginLeft: "5em",
                    height: "10em",
                };
                //stile paragrafo NO maniglioni / alzanti
                t.stileParagrafoNoManiglioneAlzante = {
                    marginTop: "4.5em",
                    marginLeft: "2em",
                    width: "390px",
                };
                //stile paragrafo SI maniglioni / alzanti
                t.stileParagrafoSiManiglioneAlzante = {
                    marginTop: "-3em",
                    marginLeft: "0em",
                    marginBottom: "1em",
                    width: "390px",
                };
                //stile icon prodotti
                t.stileIconProdotti = {
                    width: "30",
                    height: "30px",
                    marginTop: "0em",
                    marginLeft: "0em",
                };
                //linea NO maniglioni / alzanti
                t.stileLineaNoManiglioneAlzante = {
                    marginTop: "-1.5em",
                    marginLeft: "3.3em",
                    fontSize: "12px",
                };
                //linea SI maniglioni / alzanti
                t.stileLineaSiManiglioneAlzante = {
                    marginTop: "-1.5em",
                    marginLeft: "0em",
                    fontSize: "12px",
                };
                //paragrafo extation panel NO maniglioni / alzanti
                t.stilePanelNoManiglioneAlzante = {
                    marginTop: "32em",
                    marginLeft: "-6.5em",
                    fontSize: "11px",
                };
                //paragrafo extation panel SI maniglioni / alzanti
                t.stilePanelSiManiglioneAlzante = {
                    marginTop: "32em",
                    marginLeft: "-4em",
                    fontSize: "11px",
                };
                //stile extation panel finiture
                t.stileExtationPanel = {
                    width: "100%",
                    height: "0%",
                    marginTop: "-26em",
                };
                //stile Img fintire (standard / pvd / retrò)
                t.stileColFinitureImg = {
                    minWidth: "20%",
                    marginTop: "2%",
                };
                //finiture standard NO maniglioni / alzanti
                t.stileFinitureStandardNoManiglioneAlzante = {
                    minWidth: "100%",
                    marginLeft: "-4.5em",
                    marginTop: "0em",
                };
                //finiture standard SI maniglioni / alzanti
                t.stileFinitureStandardSiManiglioneAlzante = {
                    width: "120%",
                    marginLeft: "-2.8em",
                    marginTop: "0em",
                };
                //finiture standard scorrevoli
                t.stileFinitureStandardSiScorrevoli = {
                    minWidth: "100%",
                    marginLeft: "-4.5em",
                    marginTop: "0.3em",
                };
            } else if (value > 2560 && value <= 3840) {
                //TV 4K
                //img prodotto NO maniglioni / alzanti
                t.stileImgProdottoNoManiglioneAlzante = {
                    minWidth: "650px",
                    height: "auto",
                    cursor: "pointer",
                    backgroundColor: "white",
                    marginLeft: "0em",
                    marginTop: "15em",
                    marginBottom: "0em",
                };
                //img prodotto SI maniglioni / alzanti
                t.stileImgProdottoSiManiglioneAlzante = {
                    height: "750px",
                    minWidth: "auto",
                    cursor: "pointer",
                    backgroundColor: "white",
                    marginLeft: "-1em",
                    marginTop: "-5em",
                };
                //stile riga prodotti
                t.stileRowProdotti = {
                    marginLeft: "10em",
                    marginRight: "0em",
                    marginTop: "15em",
                    width: "95%",
                    marginBottom: "38em",
                };
                //stile card prodotti
                t.stileCardProdotti = {
                    marginBottom: "10em",
                    marginLeft: "15em",
                    height: "10em",
                };
                //stile paragrafo NO maniglioni / alzanti
                t.stileParagrafoNoManiglioneAlzante = {
                    marginTop: "1em",
                    marginLeft: "1.5em",
                    width: "500px",
                };
                //stile paragrafo SI maniglioni / alzanti
                t.stileParagrafoSiManiglioneAlzante = {
                    marginTop: "-1em",
                    marginLeft: "0em",
                    marginBottom: "1em",
                    width: "500px",
                };
                //stile icon prodotto
                t.stileIconProdotti = {
                    width: "40",
                    height: "40px",
                    marginTop: "0em",
                    marginLeft: "0em",
                };
                //linea NO maniglioni / alzanti
                t.stileLineaNoManiglioneAlzante = {
                    marginTop: "-1.5em",
                    marginLeft: "2.9em",
                    fontSize: "18px",
                };
                //linea SI maniglioni / alzanti
                t.stileLineaSiManiglioneAlzante = {
                    marginTop: "-1.5em",
                    marginLeft: "0em",
                    fontSize: "18px",
                };
                //stile extation panel finiture
                t.stileExtationPanel = {
                    width: "125%",
                    height: "0%",
                    marginTop: "-37em",
                };
                //paragrafo extation panel NO maniglioni / alzanti
                t.stilePanelNoManiglioneAlzante = {
                    marginTop: "32em",
                    marginLeft: "-6em",
                    fontSize: "16px",
                };
                //paragrafo extation panel SI maniglioni / alzanti
                t.stilePanelSiManiglioneAlzante = {
                    marginTop: "32em",
                    marginLeft: "-6.2em",
                    fontSize: "16px",
                };
                //stile Img fintire (standard / pvd / retrò)
                t.stileColFinitureImg = {
                    minWidth: "20%",
                    marginTop: "2%",
                };
                //finiture standard NO maniglioni / alzanti
                t.stileFinitureStandardNoManiglioneAlzante = {
                    minWidth: "130%",
                    marginLeft: "-6.5em",
                    marginTop: "0em",
                };
                //finiture standard SI maniglioni / alzanti
                t.stileFinitureStandardSiManiglioneAlzante = {
                    width: "130%",
                    marginLeft: "-6em",
                    marginTop: "0em",
                };
                //finiture standard scorrevoli
                t.stileFinitureStandardSiScorrevoli = {
                    minWidth: "150%",
                    marginLeft: "-8.5em",
                    marginTop: "0.3em",
                };
            }
        },

        //MENU TONDO
        getStiliMenuTondo(t, value) {
            //controll dimension display devices
            //fare proporzione tra una dimensione e l'altra per scalare tutti gli stili //2340 --> 4K = +50%
            t.stileRowProdotti = {}; //riga prodotti
            t.stileIconProdotti = {}; // icons prodotti
            t.stileColFinitureImg = {}; //colonna finiture
            t.stileExtationPanel = {}; //extation panel finiture
            t.stileCardProdotti = {}; // card prodotti
            t.stileImgProdottoNoManiglioneAlzante = {}; //img prodotti NO maniglioni / alzanti
            t.stileImgProdottoSiManiglioneAlzante = {}; //img prodotti SI maniglioni / alzanti
            t.stileParagrafoNoManiglioneAlzante = {}; //paragrafo NO maniglioni / alzanti
            t.stileParagrafoSiManiglioneAlzante = {}; //paragrafo SI maniglioni / alzanti
            t.stileLineaNoManiglioneAlzante = {}; //linea NO maniglioni / alzanti
            t.stileLineaSiManiglioneAlzante = {}; //linea SI maniglioni / alzanti
            t.stilePanelNoManiglioneAlzante = {}; //paragrafo extation panel NO maniglioni / alzanti
            t.stilePanelSiManiglioneAlzante = {}; //paragrafo extation panel SI maniglioni / alzanti
            t.stileFinitureStandardNoManiglioneAlzante = {}; //finiture standard NO maniglioni / alzanti
            t.stileFinitureStandardSiManiglioneAlzante = {}; //finiture standard SI maniglioni / alzanti
            t.stileFinitureStandardSiScorrevoli = {}; //finiture standard scorrevoli

            if (value <= 768) {
                //smartphone
                //img prodotto NO maniglioni / alzanti
                t.stileImgProdottoNoManiglioneAlzante = {
                    minWidth: "371px",
                    height: "auto",
                    cursor: "pointer",
                    backgroundColor: "white",
                    marginLeft: "-2em",
                    marginTop: "3.3em",
                    marginBottom: "6.3em",
                };
                //img prodotto SI maniglioni / alzanti
                t.stileImgProdottoSiManiglioneAlzante = {
                    height: "0px",
                    minWidth: "auto",
                    cursor: "pointer",
                    backgroundColor: "white",
                    marginLeft: "-2em",
                    marginTop: "0em",
                };
                //stile riga prodotti
                t.stileRowProdotti = {
                    marginTop: "-3em",
                    marginLeft: "0em",
                    marginRight: "0em",
                    width: "100%",
                    marginBottom: "0em",
                };
                //stile icon prodotti
                t.stileIconProdotti = {
                    width: "25px",
                    height: "25px",
                    marginTop: "0em",
                    marginLeft: "0em",
                };
                //stile Col Img fintire (standard / pvd / retrò)
                t.stileColFinitureImg = {
                    minWidth: "20%",
                    marginTop: "5%",
                };
                //stile extation panel finiture
                t.stileExtationPanel = {
                    width: "87%",
                    height: "0%",
                    marginTop: "-2em",
                };
                //stile card prodotti
                t.stileCardProdotti = {
                    marginBottom: "15em",
                    marginLeft: "0em",
                    height: "auto",
                };
                //stile img SI maniglioni / alzanti
                t.stileImgProdottoSiManiglioneAlzante.height = "500px";
                //stile paragrafo NO maniglioni / alzanti
                t.stileParagrafoNoManiglioneAlzante = {
                    marginTop: "-2em",
                    width: "300px",
                };
                //stile paragrafo SI maniglioni / alzanti
                t.stileParagrafoSiManiglioneAlzante = {
                    marginLeft: "-0.9em",
                    marginTop: "-2.2em",
                    width: "300px",
                };
                //paragrafo extation panel NO maniglioni / alzanti
                t.stilePanelNoManiglioneAlzante = {
                    marginTop: "6em",
                    marginLeft: "-4.5em",
                    fontSize: "11px",
                };
                //paragrafo extation panel SI maniglioni / alzanti
                t.stilePanelSiManiglioneAlzante = {
                    marginTop: "12em",
                    marginLeft: "-4.5em",
                    fontSize: "11px",
                };
                //linea NO maniglioni / alzanti
                t.stileLineaNoManiglioneAlzante = {
                    marginTop: "-1.5em",
                    marginLeft: "0em",
                    fontSize: "12px",
                };
                //linea SI maniglioni / alzanti
                t.stileLineaSiManiglioneAlzante = {
                    marginTop: "-1.5em",
                    marginLeft: "-1.5em",
                    fontSize: "12px",
                };
                //finiture standard NO maniglioni / alzanti
                t.stileFinitureStandardNoManiglioneAlzante = {
                    minWidth: "120%",
                    marginLeft: "-3.3em",
                    marginTop: "0em",
                };
                //finiture standard SI maniglioni / alzanti
                t.stileFinitureStandardSiManiglioneAlzante = {
                    width: "120%",
                    marginLeft: "-3.3em",
                    marginTop: "0em",
                };
                //finiture standard scorrevoli
                t.stileFinitureStandardSiScorrevoli = {
                    minWidth: "130%",
                    marginLeft: "-2.7em",
                    marginTop: "-0.5em",
                };
            } else if (value > 768 && value <= 1050) {
                //tablet (1024)
                //img prodotto NO maniglioni / alzanti
                t.stileImgProdottoNoManiglioneAlzante = {
                    minWidth: "371px",
                    height: "auto",
                    cursor: "pointer",
                    backgroundColor: "white",
                    marginLeft: "-2em",
                    marginTop: "3.3em",
                    marginBottom: "6.3em",
                };
                //img prodotto SI maniglioni / alzanti
                t.stileImgProdottoSiManiglioneAlzante = {
                    height: "0px",
                    minWidth: "auto",
                    cursor: "pointer",
                    backgroundColor: "white",
                    marginLeft: "-2em",
                    marginTop: "0em",
                };
                //stile riga prodotti
                t.stileRowProdotti = {
                    marginLeft: "2em",
                    marginRight: "2em",
                    marginTop: "-1.5em",
                    width: "100%",
                    marginBottom: "0em",
                };
                //stile icon prodotti
                t.stileIconProdotti = {
                    width: "25px",
                    height: "25px",
                    marginTop: "0em",
                    marginLeft: "0em",
                };
                //stile Img fintire (standard / pvd / retrò)
                t.stileColFinitureImg = {
                    minWidth: "20%",
                    marginTop: "2%",
                };
                //stile extation panel finiture
                t.stileExtationPanel = {
                    width: "90%",
                    height: "0%",
                    marginTop: "-1em",
                };
                //stile card prodotti
                t.stileCardProdotti = {
                    marginBottom: "10em",
                    marginLeft: "0em",
                    height: "auto",
                };
                //stile img SI maniglioni / alzanti
                t.stileImgProdottoSiManiglioneAlzante.height = "385px";
                //stile paragrafo NO maniglioni / alzanti
                t.stileParagrafoNoManiglioneAlzante = {
                    marginTop: "0em",
                    width: "300px",
                };
                //stile paragrafo SI maniglioni / alzanti
                t.stileParagrafoSiManiglioneAlzante = {
                    marginTop: "-2.2em",
                    marginLeft: "0em",
                    width: "300px",
                };
                //paragrafo extation panel NO maniglioni / alzanti
                t.stilePanelNoManiglioneAlzante = {
                    marginTop: "6em",
                    marginLeft: "-4.1em",
                    fontSize: "11px",
                };
                //paragrafo extation panel SI maniglioni / alzanti
                t.stilePanelSiManiglioneAlzante = {
                    marginTop: "6em",
                    marginLeft: "-2.3em",
                    fontSize: "11px",
                };
                //linea NO maniglioni / alzanti
                t.stileLineaNoManiglioneAlzante = {
                    marginTop: "-1.5em",
                    marginLeft: "0em",
                    fontSize: "12px",
                };
                //linea SI maniglioni / alzanti
                t.stileLineaSiManiglioneAlzante = {
                    marginTop: "-1.5em",
                    marginLeft: "0em",
                    fontSize: "12px",
                };
                //finiture standard NO maniglioni / alzanti
                t.stileFinitureStandardNoManiglioneAlzante = {
                    minWidth: "100%",
                    marginLeft: "-2.8em",
                    marginTop: "0em",
                };
                //finiture standard SI maniglioni / alzanti
                t.stileFinitureStandardSiManiglioneAlzante = {
                    width: "120%",
                    marginLeft: "-1.7em",
                    marginTop: "0em",
                };
                //finiture standard scorrevoli
                t.stileFinitureStandardSiScorrevoli = {
                    minWidth: "100%",
                    marginLeft: "-3em",
                    marginTop: "0.3em",
                };
            } else if (value > 1050 && value <= 1280) {
                //HD (16:9 / 16:10)
                //img prodotto NO maniglioni / alzanti
                t.stileImgProdottoNoManiglioneAlzante = {
                    minWidth: "371px",
                    height: "auto",
                    cursor: "pointer",
                    backgroundColor: "white",
                    marginLeft: "-2em",
                    marginTop: "3.3em",
                    marginBottom: "6.3em",
                };
                //img prodotto SI maniglioni / alzanti
                t.stileImgProdottoSiManiglioneAlzante = {
                    height: "0px",
                    minWidth: "auto",
                    cursor: "pointer",
                    backgroundColor: "white",
                    marginLeft: "-2em",
                    marginTop: "0em",
                };
                //stile riga prodotti
                t.stileRowProdotti = {
                    marginLeft: "2em",
                    marginRight: "2em",
                    marginTop: "-1em",
                    width: "100%",
                    marginBottom: "1em",
                };
                //stile icon prodotti
                t.stileIconProdotti = {
                    width: "25px",
                    height: "25px",
                    marginTop: "0em",
                    marginLeft: "0em",
                };
                //stile Img fintire (standard / pvd / retrò)
                t.stileColFinitureImg = {
                    minWidth: "20%",
                    marginTop: "2%",
                };
                //stile extation panel finiture
                t.stileExtationPanel = {
                    width: "90%",
                    height: "0%",
                    marginTop: "-1em",
                };
                //stile card prodotti
                t.stileCardProdotti = {
                    marginBottom: "10em",
                    marginLeft: "0em",
                    height: "auto",
                };
                //stile img SI maniglioni / alzanti
                t.stileImgProdottoSiManiglioneAlzante.height = "385px";
                //stile paragrafo NO maniglioni / alzanti
                t.stileParagrafoNoManiglioneAlzante = {
                    marginTop: "0em",
                    width: "300px",
                };
                //stile paragrafo SI maniglioni / alzanti
                t.stileParagrafoSiManiglioneAlzante = {
                    marginTop: "-2.2em",
                    marginLeft: "0em",
                    width: "300px",
                };
                //paragrafo extation panel NO maniglioni / alzanti
                t.stilePanelNoManiglioneAlzante = {
                    marginTop: "6em",
                    marginLeft: "-4.1em",
                    fontSize: "11px",
                };
                //paragrafo extation panel SI maniglioni / alzanti
                t.stilePanelSiManiglioneAlzante = {
                    marginTop: "6em",
                    marginLeft: "-2.3em",
                    fontSize: "11px",
                };
                //linea NO maniglioni / alzanti
                t.stileLineaNoManiglioneAlzante = {
                    marginTop: "-1.5em",
                    marginLeft: "0em",
                    fontSize: "12px",
                };
                //linea SI maniglioni / alzanti
                t.stileLineaSiManiglioneAlzante = {
                    marginTop: "-1.5em",
                    marginLeft: "0em",
                };
                //finiture standard NO maniglioni / alzanti
                t.stileFinitureStandardNoManiglioneAlzante = {
                    minWidth: "100%",
                    marginLeft: "-2.8em",
                    marginTop: "0em",
                };
                //finiture standard SI maniglioni / alzanti
                t.stileFinitureStandardSiManiglioneAlzante = {
                    width: "120%",
                    marginLeft: "-1.7em",
                    marginTop: "0em",
                };
                //finiture standard scorrevoli
                t.stileFinitureStandardSiScorrevoli = {
                    minWidth: "100%",
                    marginLeft: "-3em",
                    marginTop: "0.3em",
                };
            } else if (value > 1280 && value <= 1540) {
                //tablet apple / surface (windows)
                //img prodotto NO maniglioni / alzanti
                t.stileImgProdottoNoManiglioneAlzante = {
                    minWidth: "371px",
                    height: "auto",
                    cursor: "pointer",
                    backgroundColor: "white",
                    marginLeft: "-2em",
                    marginTop: "3.3em",
                    marginBottom: "6.3em",
                };
                //img prodotto SI maniglioni / alzanti
                t.stileImgProdottoSiManiglioneAlzante = {
                    height: "0px",
                    minWidth: "auto",
                    cursor: "pointer",
                    backgroundColor: "white",
                    marginLeft: "-2em",
                    marginTop: "0em",
                };
                //stile riga prodotti
                t.stileRowProdotti = {
                    marginLeft: "2em",
                    marginRight: "2em",
                    marginTop: "-2em",
                    width: "100%",
                    marginBottom: "0em",
                };
                //stile icon prodotti
                t.stileIconProdotti = {
                    width: "25px",
                    height: "25px",
                    marginTop: "0em",
                    marginLeft: "0em",
                };
                //stile Img fintire (standard / pvd / retrò)
                t.stileColFinitureImg = {
                    minWidth: "20%",
                    marginTop: "2%",
                };
                //stile extation panel finiture
                t.stileExtationPanel = {
                    width: "90%",
                    height: "0%",
                    marginTop: "-1em",
                };
                //stile card prodotti
                t.stileCardProdotti = {
                    marginBottom: "10em",
                    marginLeft: "0em",
                    height: "auto",
                };
                //stile img SI maniglioni / alzanti
                t.stileImgProdottoSiManiglioneAlzante.height = "385px";
                //stile paragrafo NO maniglioni / alzanti
                t.stileParagrafoNoManiglioneAlzante = {
                    marginTop: "0em",
                    width: "300px",
                };
                //stile paragrafo SI maniglioni / alzanti
                t.stileParagrafoSiManiglioneAlzante = {
                    marginLeft: "-1em",
                    marginTop: "-2.2em",
                    width: "300px",
                };
                //paragrafo extation panel NO maniglioni / alzanti
                t.stilePanelNoManiglioneAlzante = {
                    marginTop: "6em",
                    marginLeft: "-4.1em",
                    fontSize: "11px",
                };
                //paragrafo extation panel SI maniglioni / alzanti
                t.stilePanelSiManiglioneAlzante = {
                    marginTop: "6em",
                    marginLeft: "-4.1em",
                    fontSize: "11px",
                };
                //linea NO maniglioni / alzanti
                t.stileLineaNoManiglioneAlzante = {
                    marginTop: "-1.5em",
                    marginLeft: "0em",
                    fontSize: "12px",
                };
                //linea SI maniglioni / alzanti
                t.stileLineaSiManiglioneAlzante = {
                    marginTop: "-1.5em",
                    marginLeft: "-1.6em",
                    fontSize: "12px",
                };
                //finiture standard NO maniglioni / alzanti
                t.stileFinitureStandardNoManiglioneAlzante = {
                    minWidth: "100%",
                    marginLeft: "-2.8em",
                    marginTop: "0em",
                };
                //finiture standard SI maniglioni / alzanti
                t.stileFinitureStandardSiManiglioneAlzante = {
                    width: "120%",
                    marginLeft: "-2.8em",
                    marginTop: "0em",
                };
                //finiture standard scorrevoli
                t.stileFinitureStandardSiScorrevoli = {
                    minWidth: "100%",
                    marginLeft: "-3em",
                    marginTop: "0.3em",
                };
            } else if (value > 1540 && value <= 1920) {
                //classic pc FULL HD 16:9 / 	16:10 --> 1680
                //img prodotto NO maniglioni / alzanti
                t.stileImgProdottoNoManiglioneAlzante = {
                    minWidth: "371px",
                    height: "auto",
                    cursor: "pointer",
                    backgroundColor: "white",
                    marginLeft: "-2em",
                    marginTop: "3.3em",
                    marginBottom: "6.3em",
                };
                //img prodotto SI maniglioni / alzanti
                t.stileImgProdottoSiManiglioneAlzante = {
                    height: "0px",
                    minWidth: "auto",
                    cursor: "pointer",
                    backgroundColor: "white",
                    marginLeft: "-2em",
                    marginTop: "1em",
                };
                //stile riga prodotti
                t.stileRowProdotti = {
                    marginLeft: "3em",
                    marginRight: "2em",
                    marginTop: "1em",
                    width: "100%",
                    marginBottom: "0em",
                };
                //stile icon prodotti
                t.stileIconProdotti = {
                    width: "30px",
                    height: "30px",
                    marginTop: "0em",
                    marginLeft: "0em",
                };
                //stile Img fintire (standard / pvd / retrò)
                t.stileColFinitureImg = {
                    minWidth: "20%",
                    marginTop: "2%",
                };
                //stile extation panel finiture
                t.stileExtationPanel = {
                    width: "90%",
                    height: "0%",
                    marginTop: "-1em",
                };
                //stile card prodotti
                t.stileCardProdotti = {
                    marginBottom: "10em",
                    marginLeft: "0em",
                    height: "auto",
                };
                //stile img SI maniglioni / alzanti
                t.stileImgProdottoSiManiglioneAlzante.height = "385px";
                //stile paragrafo NO maniglioni / alzanti
                t.stileParagrafoNoManiglioneAlzante = {
                    marginTop: "0em",
                    width: "300px",
                };
                //stile paragrafo SI maniglioni / alzanti
                t.stileParagrafoSiManiglioneAlzante = {
                    marginTop: "-3em",
                    width: "300px",
                    marginLeft: "-1em",
                };
                //paragrafo extation panel NO maniglioni / alzanti
                t.stilePanelNoManiglioneAlzante = {
                    marginTop: "6em",
                    marginLeft: "-4.1em",
                    fontSize: "11px",
                };
                //paragrafo extation panel SI maniglioni / alzanti
                t.stilePanelSiManiglioneAlzante = {
                    marginTop: "6em",
                    marginLeft: "-4.1em",
                    fontSize: "11px",
                };
                //linea NO maniglioni / alzanti
                t.stileLineaNoManiglioneAlzante = {
                    marginTop: "-1.5em",
                    marginLeft: "0em",
                    fontSize: "12px",
                };
                //linea SI maniglioni / alzanti
                t.stileLineaSiManiglioneAlzante = {
                    marginTop: "-1.5em",
                    marginLeft: "-1.6em",
                    fontSize: "12px",
                };
                //finiture standard NO maniglioni / alzanti
                t.stileFinitureStandardNoManiglioneAlzante = {
                    minWidth: "100%",
                    marginLeft: "-2.8em",
                    marginTop: "0em",
                };
                //finiture standard SI maniglioni / alzanti
                t.stileFinitureStandardSiManiglioneAlzante = {
                    width: "120%",
                    marginLeft: "-2.8em",
                    marginTop: "0em",
                };
                //finiture standard scorrevoli
                t.stileFinitureStandardSiScorrevoli = {
                    minWidth: "100%",
                    marginLeft: "-3em",
                    marginTop: "0.3em",
                };
            } else if (value > 1920 && value <= 2100) {
                //classic pc (16:10 / 16:9 / 21:9)
            } else if (value > 2100 && value <= 2560) {
                //pc monitor (16:9)
                //img prodotto NO maniglioni / alzanti
                t.stileImgProdottoNoManiglioneAlzante = {
                    minWidth: "500px",
                    height: "auto",
                    cursor: "pointer",
                    backgroundColor: "white",
                    marginLeft: "0em",
                    marginTop: "9.5em",
                    marginBottom: "0em",
                };
                //img prodotto SI maniglioni / alzanti
                t.stileImgProdottoSiManiglioneAlzante = {
                    height: "650px",
                    minWidth: "auto",
                    cursor: "pointer",
                    backgroundColor: "white",
                    marginLeft: "-1em",
                    marginTop: "-5em",
                };
                //stile riga prodotti
                t.stileRowProdotti = {
                    marginLeft: "7em",
                    marginRight: "0em",
                    marginTop: "-2em",
                    width: "95%",
                    marginBottom: "25em",
                };
                //stile card prodotti
                t.stileCardProdotti = {
                    marginBottom: "10em",
                    marginLeft: "5em",
                    height: "10em",
                };
                //stile paragrafo NO maniglioni / alzanti
                t.stileParagrafoNoManiglioneAlzante = {
                    marginTop: "4.5em",
                    marginLeft: "2em",
                    width: "390px",
                };
                //stile paragrafo SI maniglioni / alzanti
                t.stileParagrafoSiManiglioneAlzante = {
                    marginTop: "-3em",
                    marginLeft: "0em",
                    marginBottom: "1em",
                    width: "390px",
                };
                //stile icon prodotti
                t.stileIconProdotti = {
                    width: "30",
                    height: "30px",
                    marginTop: "0em",
                    marginLeft: "0em",
                };
                //linea NO maniglioni / alzanti
                t.stileLineaNoManiglioneAlzante = {
                    marginTop: "-1.5em",
                    marginLeft: "3.3em",
                    fontSize: "12px",
                };
                //linea SI maniglioni / alzanti
                t.stileLineaSiManiglioneAlzante = {
                    marginTop: "-1.5em",
                    marginLeft: "0em",
                    fontSize: "12px",
                };
                //paragrafo extation panel NO maniglioni / alzanti
                t.stilePanelNoManiglioneAlzante = {
                    marginTop: "32em",
                    marginLeft: "-6.5em",
                    fontSize: "11px",
                };
                //paragrafo extation panel SI maniglioni / alzanti
                t.stilePanelSiManiglioneAlzante = {
                    marginTop: "32em",
                    marginLeft: "-4em",
                    fontSize: "11px",
                };
                //stile extation panel finiture
                t.stileExtationPanel = {
                    width: "100%",
                    height: "0%",
                    marginTop: "-26em",
                };
                //stile Img fintire (standard / pvd / retrò)
                t.stileColFinitureImg = {
                    minWidth: "20%",
                    marginTop: "2%",
                };
                //finiture standard NO maniglioni / alzanti
                t.stileFinitureStandardNoManiglioneAlzante = {
                    minWidth: "100%",
                    marginLeft: "-4.5em",
                    marginTop: "0em",
                };
                //finiture standard SI maniglioni / alzanti
                t.stileFinitureStandardSiManiglioneAlzante = {
                    width: "120%",
                    marginLeft: "-2.8em",
                    marginTop: "0em",
                };
                //finiture standard scorrevoli
                t.stileFinitureStandardSiScorrevoli = {
                    minWidth: "100%",
                    marginLeft: "-4.5em",
                    marginTop: "0.3em",
                };
            } else if (value > 2560 && value <= 3840) {
                //TV 4K
                //img prodotto NO maniglioni / alzanti
                t.stileImgProdottoNoManiglioneAlzante = {
                    minWidth: "650px",
                    height: "auto",
                    cursor: "pointer",
                    backgroundColor: "white",
                    marginLeft: "0em",
                    marginTop: "15em",
                    marginBottom: "0em",
                };
                //img prodotto SI maniglioni / alzanti
                t.stileImgProdottoSiManiglioneAlzante = {
                    height: "750px",
                    minWidth: "auto",
                    cursor: "pointer",
                    backgroundColor: "white",
                    marginLeft: "-1em",
                    marginTop: "-5em",
                };
                //stile riga prodotti
                t.stileRowProdotti = {
                    marginLeft: "10em",
                    marginRight: "0em",
                    marginTop: "0em",
                    width: "95%",
                    marginBottom: "38em",
                };
                //stile card prodotti
                t.stileCardProdotti = {
                    marginBottom: "10em",
                    marginLeft: "15em",
                    height: "10em",
                };
                //stile paragrafo NO maniglioni / alzanti
                t.stileParagrafoNoManiglioneAlzante = {
                    marginTop: "1em",
                    marginLeft: "1.5em",
                    width: "500px",
                };
                //stile paragrafo SI maniglioni / alzanti
                t.stileParagrafoSiManiglioneAlzante = {
                    marginTop: "-1em",
                    marginLeft: "0em",
                    marginBottom: "1em",
                    width: "500px",
                };
                //stile icon prodotto
                t.stileIconProdotti = {
                    width: "40",
                    height: "40px",
                    marginTop: "0em",
                    marginLeft: "0em",
                };
                //linea NO maniglioni / alzanti
                t.stileLineaNoManiglioneAlzante = {
                    marginTop: "-1.5em",
                    marginLeft: "2.9em",
                    fontSize: "18px",
                };
                //linea SI maniglioni / alzanti
                t.stileLineaSiManiglioneAlzante = {
                    marginTop: "-1.5em",
                    marginLeft: "0em",
                    fontSize: "18px",
                };
                //stile extation panel finiture
                t.stileExtationPanel = {
                    width: "125%",
                    height: "0%",
                    marginTop: "-37em",
                };
                //paragrafo extation panel NO maniglioni / alzanti
                t.stilePanelNoManiglioneAlzante = {
                    marginTop: "32em",
                    marginLeft: "-6em",
                    fontSize: "16px",
                };
                //paragrafo extation panel SI maniglioni / alzanti
                t.stilePanelSiManiglioneAlzante = {
                    marginTop: "32em",
                    marginLeft: "-6.2em",
                    fontSize: "16px",
                };
                //stile Img fintire (standard / pvd / retrò)
                t.stileColFinitureImg = {
                    minWidth: "20%",
                    marginTop: "2%",
                };
                //finiture standard NO maniglioni / alzanti
                t.stileFinitureStandardNoManiglioneAlzante = {
                    minWidth: "130%",
                    marginLeft: "-6.5em",
                    marginTop: "0em",
                };
                //finiture standard SI maniglioni / alzanti
                t.stileFinitureStandardSiManiglioneAlzante = {
                    width: "130%",
                    marginLeft: "-6em",
                    marginTop: "0em",
                };
                //finiture standard scorrevoli
                t.stileFinitureStandardSiScorrevoli = {
                    minWidth: "150%",
                    marginLeft: "-8.5em",
                    marginTop: "0.3em",
                };
            }
        },

        //MENU RETRO'
        getStiliMenuRetrò(t, value) {
            //controll dimension display devices
            //fare proporzione tra una dimensione e l'altra per scalare tutti gli stili //2340 --> 4K = +50%
            t.stileRowProdotti = {}; //riga prodotti
            t.stileIconProdotti = {}; // icons prodotti
            t.stileColFinitureImg = {}; //colonna finiture
            t.stileExtationPanel = {}; //extation panel finiture
            t.stileCardProdotti = {}; // card prodotti
            t.stileImgProdottoNoManiglioneAlzante = {}; //img prodotti NO maniglioni / alzanti
            t.stileImgProdottoSiManiglioneAlzante = {}; //img prodotti SI maniglioni / alzanti
            t.stileParagrafoNoManiglioneAlzante = {}; //paragrafo NO maniglioni / alzanti
            t.stileParagrafoSiManiglioneAlzante = {}; //paragrafo SI maniglioni / alzanti
            t.stileLineaNoManiglioneAlzante = {}; //linea NO maniglioni / alzanti
            t.stileLineaSiManiglioneAlzante = {}; //linea SI maniglioni / alzanti
            t.stilePanelNoManiglioneAlzante = {}; //paragrafo extation panel NO maniglioni / alzanti
            t.stilePanelSiManiglioneAlzante = {}; //paragrafo extation panel SI maniglioni / alzanti
            t.stileFinitureStandardNoManiglioneAlzante = {}; //finiture standard NO maniglioni / alzanti
            t.stileFinitureStandardSiManiglioneAlzante = {}; //finiture standard SI maniglioni / alzanti
            t.stileFinitureStandardSiScorrevoli = {}; //finiture standard scorrevoli

            if (value <= 768) {
                //smartphone
                //img prodotto NO maniglioni / alzanti
                t.stileImgProdottoNoManiglioneAlzante = {
                    minWidth: "371px",
                    height: "auto",
                    cursor: "pointer",
                    backgroundColor: "white",
                    marginLeft: "-2em",
                    marginTop: "3.3em",
                    marginBottom: "6.3em",
                };
                //img prodotto SI maniglioni / alzanti
                t.stileImgProdottoSiManiglioneAlzante = {
                    height: "0px",
                    minWidth: "auto",
                    cursor: "pointer",
                    backgroundColor: "white",
                    marginLeft: "-2em",
                    marginTop: "0em",
                };
                //stile riga prodotti
                t.stileRowProdotti = {
                    marginTop: "-3em",
                    marginLeft: "0em",
                    marginRight: "0em",
                    width: "100%",
                    marginBottom: "0em",
                };
                //stile icon prodotti
                t.stileIconProdotti = {
                    width: "25px",
                    height: "25px",
                    marginTop: "0em",
                    marginLeft: "0em",
                };
                //stile Col Img fintire (standard / pvd / retrò)
                t.stileColFinitureImg = {
                    minWidth: "20%",
                    marginTop: "5%",
                };
                //stile extation panel finiture
                t.stileExtationPanel = {
                    width: "87%",
                    height: "0%",
                    marginTop: "-2em",
                };
                //stile card prodotti
                t.stileCardProdotti = {
                    marginBottom: "15em",
                    marginLeft: "0em",
                    height: "auto",
                };
                //stile img SI maniglioni / alzanti
                t.stileImgProdottoSiManiglioneAlzante.height = "500px";
                //stile paragrafo NO maniglioni / alzanti
                t.stileParagrafoNoManiglioneAlzante = {
                    marginTop: "-2em",
                    width: "300px",
                };
                //stile paragrafo SI maniglioni / alzanti
                t.stileParagrafoSiManiglioneAlzante = {
                    marginLeft: "-0.9em",
                    marginTop: "-2.2em",
                    width: "300px",
                };
                //paragrafo extation panel NO maniglioni / alzanti
                t.stilePanelNoManiglioneAlzante = {
                    marginTop: "6em",
                    marginLeft: "-4.5em",
                    fontSize: "11px",
                };
                //paragrafo extation panel SI maniglioni / alzanti
                t.stilePanelSiManiglioneAlzante = {
                    marginTop: "12em",
                    marginLeft: "-4.5em",
                    fontSize: "11px",
                };
                //linea NO maniglioni / alzanti
                t.stileLineaNoManiglioneAlzante = {
                    marginTop: "-1.5em",
                    marginLeft: "0em",
                    fontSize: "12px",
                };
                //linea SI maniglioni / alzanti
                t.stileLineaSiManiglioneAlzante = {
                    marginTop: "-1.5em",
                    marginLeft: "-1.5em",
                    fontSize: "12px",
                };
                //finiture standard NO maniglioni / alzanti
                t.stileFinitureStandardNoManiglioneAlzante = {
                    minWidth: "120%",
                    marginLeft: "-3.3em",
                    marginTop: "0em",
                };
                //finiture standard SI maniglioni / alzanti
                t.stileFinitureStandardSiManiglioneAlzante = {
                    width: "120%",
                    marginLeft: "-3.3em",
                    marginTop: "0em",
                };
                //finiture standard scorrevoli
                t.stileFinitureStandardSiScorrevoli = {
                    minWidth: "130%",
                    marginLeft: "-2.7em",
                    marginTop: "-0.5em",
                };
            } else if (value > 768 && value <= 1050) {
                //tablet (1024)
                //img prodotto NO maniglioni / alzanti
                t.stileImgProdottoNoManiglioneAlzante = {
                    minWidth: "371px",
                    height: "auto",
                    cursor: "pointer",
                    backgroundColor: "white",
                    marginLeft: "-2em",
                    marginTop: "3.3em",
                    marginBottom: "6.3em",
                };
                //img prodotto SI maniglioni / alzanti
                t.stileImgProdottoSiManiglioneAlzante = {
                    height: "0px",
                    minWidth: "auto",
                    cursor: "pointer",
                    backgroundColor: "white",
                    marginLeft: "-2em",
                    marginTop: "0em",
                };
                //stile riga prodotti
                t.stileRowProdotti = {
                    marginLeft: "2em",
                    marginRight: "2em",
                    marginTop: "-1.5em",
                    width: "100%",
                    marginBottom: "0em",
                };
                //stile icon prodotti
                t.stileIconProdotti = {
                    width: "25px",
                    height: "25px",
                    marginTop: "0em",
                    marginLeft: "0em",
                };
                //stile Img fintire (standard / pvd / retrò)
                t.stileColFinitureImg = {
                    minWidth: "20%",
                    marginTop: "2%",
                };
                //stile extation panel finiture
                t.stileExtationPanel = {
                    width: "90%",
                    height: "0%",
                    marginTop: "-1em",
                };
                //stile card prodotti
                t.stileCardProdotti = {
                    marginBottom: "10em",
                    marginLeft: "0em",
                    height: "auto",
                };
                //stile img SI maniglioni / alzanti
                t.stileImgProdottoSiManiglioneAlzante.height = "385px";
                //stile paragrafo NO maniglioni / alzanti
                t.stileParagrafoNoManiglioneAlzante = {
                    marginTop: "0em",
                    width: "300px",
                };
                //stile paragrafo SI maniglioni / alzanti
                t.stileParagrafoSiManiglioneAlzante = {
                    marginTop: "-2.2em",
                    marginLeft: "0em",
                    width: "300px",
                };
                //paragrafo extation panel NO maniglioni / alzanti
                t.stilePanelNoManiglioneAlzante = {
                    marginTop: "6em",
                    marginLeft: "-4.1em",
                    fontSize: "11px",
                };
                //paragrafo extation panel SI maniglioni / alzanti
                t.stilePanelSiManiglioneAlzante = {
                    marginTop: "6em",
                    marginLeft: "-2.3em",
                    fontSize: "11px",
                };
                //linea NO maniglioni / alzanti
                t.stileLineaNoManiglioneAlzante = {
                    marginTop: "-1.5em",
                    marginLeft: "0em",
                    fontSize: "12px",
                };
                //linea SI maniglioni / alzanti
                t.stileLineaSiManiglioneAlzante = {
                    marginTop: "-1.5em",
                    marginLeft: "0em",
                    fontSize: "12px",
                };
                //finiture standard NO maniglioni / alzanti
                t.stileFinitureStandardNoManiglioneAlzante = {
                    minWidth: "100%",
                    marginLeft: "-2.8em",
                    marginTop: "0em",
                };
                //finiture standard SI maniglioni / alzanti
                t.stileFinitureStandardSiManiglioneAlzante = {
                    width: "120%",
                    marginLeft: "-1.7em",
                    marginTop: "0em",
                };
                //finiture standard scorrevoli
                t.stileFinitureStandardSiScorrevoli = {
                    minWidth: "100%",
                    marginLeft: "-3em",
                    marginTop: "0.3em",
                };
            } else if (value > 1050 && value <= 1280) {
                //HD (16:9 / 16:10)
                //img prodotto NO maniglioni / alzanti
                t.stileImgProdottoNoManiglioneAlzante = {
                    minWidth: "371px",
                    height: "auto",
                    cursor: "pointer",
                    backgroundColor: "white",
                    marginLeft: "-2em",
                    marginTop: "3.3em",
                    marginBottom: "6.3em",
                };
                //img prodotto SI maniglioni / alzanti
                t.stileImgProdottoSiManiglioneAlzante = {
                    height: "0px",
                    minWidth: "auto",
                    cursor: "pointer",
                    backgroundColor: "white",
                    marginLeft: "-2em",
                    marginTop: "0em",
                };
                //stile riga prodotti
                t.stileRowProdotti = {
                    marginLeft: "2em",
                    marginRight: "2em",
                    marginTop: "-1em",
                    width: "100%",
                    marginBottom: "1em",
                };
                //stile icon prodotti
                t.stileIconProdotti = {
                    width: "25px",
                    height: "25px",
                    marginTop: "0em",
                    marginLeft: "0em",
                };
                //stile Img fintire (standard / pvd / retrò)
                t.stileColFinitureImg = {
                    minWidth: "20%",
                    marginTop: "2%",
                };
                //stile extation panel finiture
                t.stileExtationPanel = {
                    width: "90%",
                    height: "0%",
                    marginTop: "-1em",
                };
                //stile card prodotti
                t.stileCardProdotti = {
                    marginBottom: "10em",
                    marginLeft: "0em",
                    height: "auto",
                };
                //stile img SI maniglioni / alzanti
                t.stileImgProdottoSiManiglioneAlzante.height = "385px";
                //stile paragrafo NO maniglioni / alzanti
                t.stileParagrafoNoManiglioneAlzante = {
                    marginTop: "0em",
                    width: "300px",
                };
                //stile paragrafo SI maniglioni / alzanti
                t.stileParagrafoSiManiglioneAlzante = {
                    marginTop: "-2.2em",
                    marginLeft: "0em",
                    width: "300px",
                };
                //paragrafo extation panel NO maniglioni / alzanti
                t.stilePanelNoManiglioneAlzante = {
                    marginTop: "6em",
                    marginLeft: "-4.1em",
                    fontSize: "11px",
                };
                //paragrafo extation panel SI maniglioni / alzanti
                t.stilePanelSiManiglioneAlzante = {
                    marginTop: "6em",
                    marginLeft: "-2.3em",
                    fontSize: "11px",
                };
                //linea NO maniglioni / alzanti
                t.stileLineaNoManiglioneAlzante = {
                    marginTop: "-1.5em",
                    marginLeft: "0em",
                    fontSize: "12px",
                };
                //linea SI maniglioni / alzanti
                t.stileLineaSiManiglioneAlzante = {
                    marginTop: "-1.5em",
                    marginLeft: "0em",
                };
                //finiture standard NO maniglioni / alzanti
                t.stileFinitureStandardNoManiglioneAlzante = {
                    minWidth: "100%",
                    marginLeft: "-2.8em",
                    marginTop: "0em",
                };
                //finiture standard SI maniglioni / alzanti
                t.stileFinitureStandardSiManiglioneAlzante = {
                    width: "120%",
                    marginLeft: "-1.7em",
                    marginTop: "0em",
                };
                //finiture standard scorrevoli
                t.stileFinitureStandardSiScorrevoli = {
                    minWidth: "100%",
                    marginLeft: "-3em",
                    marginTop: "0.3em",
                };
            } else if (value > 1280 && value <= 1540) {
                //tablet apple / surface (windows)
                //img prodotto NO maniglioni / alzanti
                t.stileImgProdottoNoManiglioneAlzante = {
                    minWidth: "371px",
                    height: "auto",
                    cursor: "pointer",
                    backgroundColor: "white",
                    marginLeft: "-2em",
                    marginTop: "3.3em",
                    marginBottom: "6.3em",
                };
                //img prodotto SI maniglioni / alzanti
                t.stileImgProdottoSiManiglioneAlzante = {
                    height: "0px",
                    minWidth: "auto",
                    cursor: "pointer",
                    backgroundColor: "white",
                    marginLeft: "-2em",
                    marginTop: "0em",
                };
                //stile riga prodotti
                t.stileRowProdotti = {
                    marginLeft: "2em",
                    marginRight: "2em",
                    marginTop: "0em",
                    width: "100%",
                    marginBottom: "0em",
                };
                //stile icon prodotti
                t.stileIconProdotti = {
                    width: "25px",
                    height: "25px",
                    marginTop: "0em",
                    marginLeft: "0em",
                };
                //stile Img fintire (standard / pvd / retrò)
                t.stileColFinitureImg = {
                    minWidth: "20%",
                    marginTop: "2%",
                };
                //stile extation panel finiture
                t.stileExtationPanel = {
                    width: "90%",
                    height: "0%",
                    marginTop: "-1em",
                };
                //stile card prodotti
                t.stileCardProdotti = {
                    marginBottom: "10em",
                    marginLeft: "0em",
                    height: "auto",
                };
                //stile img SI maniglioni / alzanti
                t.stileImgProdottoSiManiglioneAlzante.height = "385px";
                //stile paragrafo NO maniglioni / alzanti
                t.stileParagrafoNoManiglioneAlzante = {
                    marginTop: "0em",
                    width: "300px",
                };
                //stile paragrafo SI maniglioni / alzanti
                t.stileParagrafoSiManiglioneAlzante = {
                    marginLeft: "-1em",
                    marginTop: "-2.2em",
                    width: "300px",
                };
                //paragrafo extation panel NO maniglioni / alzanti
                t.stilePanelNoManiglioneAlzante = {
                    marginTop: "6em",
                    marginLeft: "-4.1em",
                    fontSize: "11px",
                };
                //paragrafo extation panel SI maniglioni / alzanti
                t.stilePanelSiManiglioneAlzante = {
                    marginTop: "6em",
                    marginLeft: "-4.1em",
                    fontSize: "11px",
                };
                //linea NO maniglioni / alzanti
                t.stileLineaNoManiglioneAlzante = {
                    marginTop: "-1.5em",
                    marginLeft: "0em",
                    fontSize: "12px",
                };
                //linea SI maniglioni / alzanti
                t.stileLineaSiManiglioneAlzante = {
                    marginTop: "-1.5em",
                    marginLeft: "-1.6em",
                    fontSize: "12px",
                };
                //finiture standard NO maniglioni / alzanti
                t.stileFinitureStandardNoManiglioneAlzante = {
                    minWidth: "100%",
                    marginLeft: "-2.8em",
                    marginTop: "0em",
                };
                //finiture standard SI maniglioni / alzanti
                t.stileFinitureStandardSiManiglioneAlzante = {
                    width: "120%",
                    marginLeft: "-2.8em",
                    marginTop: "0em",
                };
                //finiture standard scorrevoli
                t.stileFinitureStandardSiScorrevoli = {
                    minWidth: "100%",
                    marginLeft: "-3em",
                    marginTop: "0.3em",
                };
            } else if (value > 1540 && value <= 1920) {
                //classic pc FULL HD 16:9 / 	16:10 --> 1680
                //img prodotto NO maniglioni / alzanti
                t.stileImgProdottoNoManiglioneAlzante = {
                    minWidth: "371px",
                    height: "auto",
                    cursor: "pointer",
                    backgroundColor: "white",
                    marginLeft: "-2em",
                    marginTop: "3.3em",
                    marginBottom: "6.3em",
                };
                //img prodotto SI maniglioni / alzanti
                t.stileImgProdottoSiManiglioneAlzante = {
                    height: "0px",
                    minWidth: "auto",
                    cursor: "pointer",
                    backgroundColor: "white",
                    marginLeft: "-2em",
                    marginTop: "1em",
                };
                //stile riga prodotti
                t.stileRowProdotti = {
                    marginLeft: "3em",
                    marginRight: "2em",
                    marginTop: "1em",
                    width: "100%",
                    marginBottom: "0em",
                };
                //stile icon prodotti
                t.stileIconProdotti = {
                    width: "30px",
                    height: "30px",
                    marginTop: "0em",
                    marginLeft: "0em",
                };
                //stile Img fintire (standard / pvd / retrò)
                t.stileColFinitureImg = {
                    minWidth: "20%",
                    marginTop: "2%",
                };
                //stile extation panel finiture
                t.stileExtationPanel = {
                    width: "90%",
                    height: "0%",
                    marginTop: "-1em",
                };
                //stile card prodotti
                t.stileCardProdotti = {
                    marginBottom: "10em",
                    marginLeft: "0em",
                    height: "auto",
                };
                //stile img SI maniglioni / alzanti
                t.stileImgProdottoSiManiglioneAlzante.height = "385px";
                //stile paragrafo NO maniglioni / alzanti
                t.stileParagrafoNoManiglioneAlzante = {
                    marginTop: "0em",
                    width: "300px",
                };
                //stile paragrafo SI maniglioni / alzanti
                t.stileParagrafoSiManiglioneAlzante = {
                    marginTop: "-3em",
                    width: "300px",
                    marginLeft: "-1em",
                };
                //paragrafo extation panel NO maniglioni / alzanti
                t.stilePanelNoManiglioneAlzante = {
                    marginTop: "6em",
                    marginLeft: "-4.1em",
                    fontSize: "11px",
                };
                //paragrafo extation panel SI maniglioni / alzanti
                t.stilePanelSiManiglioneAlzante = {
                    marginTop: "6em",
                    marginLeft: "-4.1em",
                    fontSize: "11px",
                };
                //linea NO maniglioni / alzanti
                t.stileLineaNoManiglioneAlzante = {
                    marginTop: "-1.5em",
                    marginLeft: "0em",
                    fontSize: "12px",
                };
                //linea SI maniglioni / alzanti
                t.stileLineaSiManiglioneAlzante = {
                    marginTop: "-1.5em",
                    marginLeft: "-1.6em",
                    fontSize: "12px",
                };
                //finiture standard NO maniglioni / alzanti
                t.stileFinitureStandardNoManiglioneAlzante = {
                    minWidth: "100%",
                    marginLeft: "-2.8em",
                    marginTop: "0em",
                };
                //finiture standard SI maniglioni / alzanti
                t.stileFinitureStandardSiManiglioneAlzante = {
                    width: "120%",
                    marginLeft: "-2.8em",
                    marginTop: "0em",
                };
                //finiture standard scorrevoli
                t.stileFinitureStandardSiScorrevoli = {
                    minWidth: "100%",
                    marginLeft: "-3em",
                    marginTop: "0.3em",
                };
            } else if (value > 1920 && value <= 2100) {
                //classic pc (16:10 / 16:9 / 21:9)
            } else if (value > 2100 && value <= 2560) {
                //pc monitor (16:9)
                //img prodotto NO maniglioni / alzanti
                t.stileImgProdottoNoManiglioneAlzante = {
                    minWidth: "500px",
                    height: "auto",
                    cursor: "pointer",
                    backgroundColor: "white",
                    marginLeft: "0em",
                    marginTop: "9.5em",
                    marginBottom: "0em",
                };
                //img prodotto SI maniglioni / alzanti
                t.stileImgProdottoSiManiglioneAlzante = {
                    height: "650px",
                    minWidth: "auto",
                    cursor: "pointer",
                    backgroundColor: "white",
                    marginLeft: "-1em",
                    marginTop: "-5em",
                };
                //stile riga prodotti
                t.stileRowProdotti = {
                    marginLeft: "7em",
                    marginRight: "0em",
                    marginTop: "-2em",
                    width: "95%",
                    marginBottom: "25em",
                };
                //stile card prodotti
                t.stileCardProdotti = {
                    marginBottom: "10em",
                    marginLeft: "5em",
                    height: "10em",
                };
                //stile paragrafo NO maniglioni / alzanti
                t.stileParagrafoNoManiglioneAlzante = {
                    marginTop: "4.5em",
                    marginLeft: "2em",
                    width: "390px",
                };
                //stile paragrafo SI maniglioni / alzanti
                t.stileParagrafoSiManiglioneAlzante = {
                    marginTop: "-3em",
                    marginLeft: "0em",
                    marginBottom: "1em",
                    width: "390px",
                };
                //stile icon prodotti
                t.stileIconProdotti = {
                    width: "30",
                    height: "30px",
                    marginTop: "0em",
                    marginLeft: "0em",
                };
                //linea NO maniglioni / alzanti
                t.stileLineaNoManiglioneAlzante = {
                    marginTop: "-1.5em",
                    marginLeft: "3.3em",
                    fontSize: "12px",
                };
                //linea SI maniglioni / alzanti
                t.stileLineaSiManiglioneAlzante = {
                    marginTop: "-1.5em",
                    marginLeft: "0em",
                    fontSize: "12px",
                };
                //paragrafo extation panel NO maniglioni / alzanti
                t.stilePanelNoManiglioneAlzante = {
                    marginTop: "32em",
                    marginLeft: "-6.5em",
                    fontSize: "11px",
                };
                //paragrafo extation panel SI maniglioni / alzanti
                t.stilePanelSiManiglioneAlzante = {
                    marginTop: "32em",
                    marginLeft: "-4em",
                    fontSize: "11px",
                };
                //stile extation panel finiture
                t.stileExtationPanel = {
                    width: "100%",
                    height: "0%",
                    marginTop: "-26em",
                };
                //stile Img fintire (standard / pvd / retrò)
                t.stileColFinitureImg = {
                    minWidth: "20%",
                    marginTop: "2%",
                };
                //finiture standard NO maniglioni / alzanti
                t.stileFinitureStandardNoManiglioneAlzante = {
                    minWidth: "100%",
                    marginLeft: "-4.5em",
                    marginTop: "0em",
                };
                //finiture standard SI maniglioni / alzanti
                t.stileFinitureStandardSiManiglioneAlzante = {
                    width: "120%",
                    marginLeft: "-2.8em",
                    marginTop: "0em",
                };
                //finiture standard scorrevoli
                t.stileFinitureStandardSiScorrevoli = {
                    minWidth: "100%",
                    marginLeft: "-4.5em",
                    marginTop: "0.3em",
                };
            } else if (value > 2560 && value <= 3840) {
                //TV 4K
                //img prodotto NO maniglioni / alzanti
                t.stileImgProdottoNoManiglioneAlzante = {
                    minWidth: "650px",
                    height: "auto",
                    cursor: "pointer",
                    backgroundColor: "white",
                    marginLeft: "0em",
                    marginTop: "15em",
                    marginBottom: "0em",
                };
                //img prodotto SI maniglioni / alzanti
                t.stileImgProdottoSiManiglioneAlzante = {
                    height: "750px",
                    minWidth: "auto",
                    cursor: "pointer",
                    backgroundColor: "white",
                    marginLeft: "-1em",
                    marginTop: "-5em",
                };
                //stile riga prodotti
                t.stileRowProdotti = {
                    marginLeft: "10em",
                    marginRight: "0em",
                    marginTop: "0em",
                    width: "95%",
                    marginBottom: "38em",
                };
                //stile card prodotti
                t.stileCardProdotti = {
                    marginBottom: "10em",
                    marginLeft: "15em",
                    height: "10em",
                };
                //stile paragrafo NO maniglioni / alzanti
                t.stileParagrafoNoManiglioneAlzante = {
                    marginTop: "1em",
                    marginLeft: "1.5em",
                    width: "500px",
                };
                //stile paragrafo SI maniglioni / alzanti
                t.stileParagrafoSiManiglioneAlzante = {
                    marginTop: "-1em",
                    marginLeft: "0em",
                    marginBottom: "1em",
                    width: "500px",
                };
                //stile icon prodotto
                t.stileIconProdotti = {
                    width: "40",
                    height: "40px",
                    marginTop: "0em",
                    marginLeft: "0em",
                };
                //linea NO maniglioni / alzanti
                t.stileLineaNoManiglioneAlzante = {
                    marginTop: "-1.5em",
                    marginLeft: "2.9em",
                    fontSize: "18px",
                };
                //linea SI maniglioni / alzanti
                t.stileLineaSiManiglioneAlzante = {
                    marginTop: "-1.5em",
                    marginLeft: "0em",
                    fontSize: "18px",
                };
                //stile extation panel finiture
                t.stileExtationPanel = {
                    width: "125%",
                    height: "0%",
                    marginTop: "-37em",
                };
                //paragrafo extation panel NO maniglioni / alzanti
                t.stilePanelNoManiglioneAlzante = {
                    marginTop: "32em",
                    marginLeft: "-6em",
                    fontSize: "16px",
                };
                //paragrafo extation panel SI maniglioni / alzanti
                t.stilePanelSiManiglioneAlzante = {
                    marginTop: "32em",
                    marginLeft: "-6.2em",
                    fontSize: "16px",
                };
                //stile Img fintire (standard / pvd / retrò)
                t.stileColFinitureImg = {
                    minWidth: "20%",
                    marginTop: "2%",
                };
                //finiture standard NO maniglioni / alzanti
                t.stileFinitureStandardNoManiglioneAlzante = {
                    minWidth: "130%",
                    marginLeft: "-6.5em",
                    marginTop: "0em",
                };
                //finiture standard SI maniglioni / alzanti
                t.stileFinitureStandardSiManiglioneAlzante = {
                    width: "130%",
                    marginLeft: "-6em",
                    marginTop: "0em",
                };
                //finiture standard scorrevoli
                t.stileFinitureStandardSiScorrevoli = {
                    minWidth: "150%",
                    marginLeft: "-8.5em",
                    marginTop: "0.3em",
                };
            }
        },

        //MENU STANDARD
        getStiliMenuStandard(t, value) {
            //controll dimension display devices
            //fare proporzione tra una dimensione e l'altra per scalare tutti gli stili //2340 --> 4K = +50%
            t.stileRowProdotti = {}; //riga prodotti
            t.stileIconProdotti = {}; // icons prodotti
            t.stileColFinitureImg = {}; //colonna finiture
            t.stileExtationPanel = {}; //extation panel finiture
            t.stileCardProdotti = {}; // card prodotti
            t.stileImgProdottoNoManiglioneAlzante = {}; //img prodotti NO maniglioni / alzanti
            t.stileImgProdottoSiManiglioneAlzante = {}; //img prodotti SI maniglioni / alzanti
            t.stileParagrafoNoManiglioneAlzante = {}; //paragrafo NO maniglioni / alzanti
            t.stileParagrafoSiManiglioneAlzante = {}; //paragrafo SI maniglioni / alzanti
            t.stileLineaNoManiglioneAlzante = {}; //linea NO maniglioni / alzanti
            t.stileLineaSiManiglioneAlzante = {}; //linea SI maniglioni / alzanti
            t.stilePanelNoManiglioneAlzante = {}; //paragrafo extation panel NO maniglioni / alzanti
            t.stilePanelSiManiglioneAlzante = {}; //paragrafo extation panel SI maniglioni / alzanti
            t.stileFinitureStandardNoManiglioneAlzante = {}; //finiture standard NO maniglioni / alzanti
            t.stileFinitureStandardSiManiglioneAlzante = {}; //finiture standard SI maniglioni / alzanti
            t.stileFinitureStandardSiScorrevoli = {}; //finiture standard scorrevoli

            if (value <= 768) {
                //smartphone
                //img prodotto NO maniglioni / alzanti
                t.stileImgProdottoNoManiglioneAlzante = {
                    minWidth: "371px",
                    height: "auto",
                    cursor: "pointer",
                    backgroundColor: "white",
                    marginLeft: "-2em",
                    marginTop: "3.3em",
                    marginBottom: "6.3em",
                };
                //img prodotto SI maniglioni / alzanti
                t.stileImgProdottoSiManiglioneAlzante = {
                    height: "0px",
                    minWidth: "auto",
                    cursor: "pointer",
                    backgroundColor: "white",
                    marginLeft: "-2em",
                    marginTop: "0em",
                };
                //stile riga prodotti
                t.stileRowProdotti = {
                    marginTop: "-3em",
                    marginLeft: "0em",
                    marginRight: "0em",
                    width: "100%",
                    marginBottom: "0em",
                };
                //stile icon prodotti
                t.stileIconProdotti = {
                    width: "25px",
                    height: "25px",
                    marginTop: "0em",
                    marginLeft: "0em",
                };
                //stile Col Img fintire (standard / pvd / retrò)
                t.stileColFinitureImg = {
                    minWidth: "20%",
                    marginTop: "5%",
                };
                //stile extation panel finiture
                t.stileExtationPanel = {
                    width: "87%",
                    height: "0%",
                    marginTop: "-2em",
                };
                //stile card prodotti
                t.stileCardProdotti = {
                    marginBottom: "15em",
                    marginLeft: "0em",
                    height: "auto",
                };
                //stile img SI maniglioni / alzanti
                t.stileImgProdottoSiManiglioneAlzante.height = "500px";
                //stile paragrafo NO maniglioni / alzanti
                t.stileParagrafoNoManiglioneAlzante = {
                    marginTop: "-2em",
                    width: "300px",
                };
                //stile paragrafo SI maniglioni / alzanti
                t.stileParagrafoSiManiglioneAlzante = {
                    marginLeft: "-0.9em",
                    marginTop: "-2.2em",
                    width: "300px",
                };
                //paragrafo extation panel NO maniglioni / alzanti
                t.stilePanelNoManiglioneAlzante = {
                    marginTop: "6em",
                    marginLeft: "-4.5em",
                    fontSize: "11px",
                };
                //paragrafo extation panel SI maniglioni / alzanti
                t.stilePanelSiManiglioneAlzante = {
                    marginTop: "12em",
                    marginLeft: "-4.5em",
                    fontSize: "11px",
                };
                //linea NO maniglioni / alzanti
                t.stileLineaNoManiglioneAlzante = {
                    marginTop: "-1.5em",
                    marginLeft: "0em",
                    fontSize: "12px",
                };
                //linea SI maniglioni / alzanti
                t.stileLineaSiManiglioneAlzante = {
                    marginTop: "-1.5em",
                    marginLeft: "-1.5em",
                    fontSize: "12px",
                };
                //finiture standard NO maniglioni / alzanti
                t.stileFinitureStandardNoManiglioneAlzante = {
                    minWidth: "120%",
                    marginLeft: "-3.3em",
                    marginTop: "0em",
                };
                //finiture standard SI maniglioni / alzanti
                t.stileFinitureStandardSiManiglioneAlzante = {
                    width: "120%",
                    marginLeft: "-3.3em",
                    marginTop: "0em",
                };
                //finiture standard scorrevoli
                t.stileFinitureStandardSiScorrevoli = {
                    minWidth: "130%",
                    marginLeft: "-2.7em",
                    marginTop: "-0.5em",
                };
            } else if (value > 768 && value <= 1050) {
                //tablet (1024)
                //img prodotto NO maniglioni / alzanti
                t.stileImgProdottoNoManiglioneAlzante = {
                    minWidth: "371px",
                    height: "auto",
                    cursor: "pointer",
                    backgroundColor: "white",
                    marginLeft: "-2em",
                    marginTop: "3.3em",
                    marginBottom: "6.3em",
                };
                //img prodotto SI maniglioni / alzanti
                t.stileImgProdottoSiManiglioneAlzante = {
                    height: "0px",
                    minWidth: "auto",
                    cursor: "pointer",
                    backgroundColor: "white",
                    marginLeft: "-2em",
                    marginTop: "0em",
                };
                //stile riga prodotti
                t.stileRowProdotti = {
                    marginLeft: "2em",
                    marginRight: "2em",
                    marginTop: "-1.5em",
                    width: "100%",
                    marginBottom: "0em",
                };
                //stile icon prodotti
                t.stileIconProdotti = {
                    width: "25px",
                    height: "25px",
                    marginTop: "0em",
                    marginLeft: "0em",
                };
                //stile Img fintire (standard / pvd / retrò)
                t.stileColFinitureImg = {
                    minWidth: "20%",
                    marginTop: "2%",
                };
                //stile extation panel finiture
                t.stileExtationPanel = {
                    width: "90%",
                    height: "0%",
                    marginTop: "-1em",
                };
                //stile card prodotti
                t.stileCardProdotti = {
                    marginBottom: "10em",
                    marginLeft: "0em",
                    height: "auto",
                };
                //stile img SI maniglioni / alzanti
                t.stileImgProdottoSiManiglioneAlzante.height = "385px";
                //stile paragrafo NO maniglioni / alzanti
                t.stileParagrafoNoManiglioneAlzante = {
                    marginTop: "0em",
                    width: "300px",
                };
                //stile paragrafo SI maniglioni / alzanti
                t.stileParagrafoSiManiglioneAlzante = {
                    marginTop: "-2.2em",
                    marginLeft: "0em",
                    width: "300px",
                };
                //paragrafo extation panel NO maniglioni / alzanti
                t.stilePanelNoManiglioneAlzante = {
                    marginTop: "6em",
                    marginLeft: "-4.1em",
                    fontSize: "11px",
                };
                //paragrafo extation panel SI maniglioni / alzanti
                t.stilePanelSiManiglioneAlzante = {
                    marginTop: "6em",
                    marginLeft: "-2.3em",
                    fontSize: "11px",
                };
                //linea NO maniglioni / alzanti
                t.stileLineaNoManiglioneAlzante = {
                    marginTop: "-1.5em",
                    marginLeft: "0em",
                    fontSize: "12px",
                };
                //linea SI maniglioni / alzanti
                t.stileLineaSiManiglioneAlzante = {
                    marginTop: "-1.5em",
                    marginLeft: "0em",
                    fontSize: "12px",
                };
                //finiture standard NO maniglioni / alzanti
                t.stileFinitureStandardNoManiglioneAlzante = {
                    minWidth: "100%",
                    marginLeft: "-2.8em",
                    marginTop: "0em",
                };
                //finiture standard SI maniglioni / alzanti
                t.stileFinitureStandardSiManiglioneAlzante = {
                    width: "120%",
                    marginLeft: "-1.7em",
                    marginTop: "0em",
                };
                //finiture standard scorrevoli
                t.stileFinitureStandardSiScorrevoli = {
                    minWidth: "100%",
                    marginLeft: "-3em",
                    marginTop: "0.3em",
                };
            } else if (value > 1050 && value <= 1280) {
                //HD (16:9 / 16:10)
                //img prodotto NO maniglioni / alzanti
                t.stileImgProdottoNoManiglioneAlzante = {
                    minWidth: "371px",
                    height: "auto",
                    cursor: "pointer",
                    backgroundColor: "white",
                    marginLeft: "-2em",
                    marginTop: "3.3em",
                    marginBottom: "6.3em",
                };
                //img prodotto SI maniglioni / alzanti
                t.stileImgProdottoSiManiglioneAlzante = {
                    height: "0px",
                    minWidth: "auto",
                    cursor: "pointer",
                    backgroundColor: "white",
                    marginLeft: "-2em",
                    marginTop: "0em",
                };
                //stile riga prodotti
                t.stileRowProdotti = {
                    marginLeft: "2em",
                    marginRight: "2em",
                    marginTop: "-1em",
                    width: "100%",
                    marginBottom: "1em",
                };
                //stile icon prodotti
                t.stileIconProdotti = {
                    width: "25px",
                    height: "25px",
                    marginTop: "0em",
                    marginLeft: "0em",
                };
                //stile Img fintire (standard / pvd / retrò)
                t.stileColFinitureImg = {
                    minWidth: "20%",
                    marginTop: "2%",
                };
                //stile extation panel finiture
                t.stileExtationPanel = {
                    width: "90%",
                    height: "0%",
                    marginTop: "-1em",
                };
                //stile card prodotti
                t.stileCardProdotti = {
                    marginBottom: "10em",
                    marginLeft: "0em",
                    height: "auto",
                };
                //stile img SI maniglioni / alzanti
                t.stileImgProdottoSiManiglioneAlzante.height = "385px";
                //stile paragrafo NO maniglioni / alzanti
                t.stileParagrafoNoManiglioneAlzante = {
                    marginTop: "0em",
                    width: "300px",
                };
                //stile paragrafo SI maniglioni / alzanti
                t.stileParagrafoSiManiglioneAlzante = {
                    marginTop: "-2.2em",
                    marginLeft: "0em",
                    width: "300px",
                };
                //paragrafo extation panel NO maniglioni / alzanti
                t.stilePanelNoManiglioneAlzante = {
                    marginTop: "6em",
                    marginLeft: "-4.1em",
                    fontSize: "11px",
                };
                //paragrafo extation panel SI maniglioni / alzanti
                t.stilePanelSiManiglioneAlzante = {
                    marginTop: "6em",
                    marginLeft: "-2.3em",
                    fontSize: "11px",
                };
                //linea NO maniglioni / alzanti
                t.stileLineaNoManiglioneAlzante = {
                    marginTop: "-1.5em",
                    marginLeft: "0em",
                    fontSize: "12px",
                };
                //linea SI maniglioni / alzanti
                t.stileLineaSiManiglioneAlzante = {
                    marginTop: "-1.5em",
                    marginLeft: "0em",
                };
                //finiture standard NO maniglioni / alzanti
                t.stileFinitureStandardNoManiglioneAlzante = {
                    minWidth: "100%",
                    marginLeft: "-2.8em",
                    marginTop: "0em",
                };
                //finiture standard SI maniglioni / alzanti
                t.stileFinitureStandardSiManiglioneAlzante = {
                    width: "120%",
                    marginLeft: "-1.7em",
                    marginTop: "0em",
                };
                //finiture standard scorrevoli
                t.stileFinitureStandardSiScorrevoli = {
                    minWidth: "100%",
                    marginLeft: "-3em",
                    marginTop: "0.3em",
                };
            } else if (value > 1280 && value <= 1540) {
                //tablet apple / surface (windows)
                //img prodotto NO maniglioni / alzanti
                t.stileImgProdottoNoManiglioneAlzante = {
                    minWidth: "371px",
                    height: "auto",
                    cursor: "pointer",
                    backgroundColor: "white",
                    marginLeft: "-2em",
                    marginTop: "3.3em",
                    marginBottom: "6.3em",
                };
                //img prodotto SI maniglioni / alzanti
                t.stileImgProdottoSiManiglioneAlzante = {
                    height: "0px",
                    minWidth: "auto",
                    cursor: "pointer",
                    backgroundColor: "white",
                    marginLeft: "-2em",
                    marginTop: "0em",
                };
                //stile riga prodotti
                t.stileRowProdotti = {
                    marginLeft: "2em",
                    marginRight: "2em",
                    marginTop: "-2em",
                    width: "100%",
                    marginBottom: "0em",
                };
                //stile icon prodotti
                t.stileIconProdotti = {
                    width: "25px",
                    height: "25px",
                    marginTop: "0em",
                    marginLeft: "0em",
                };
                //stile Img fintire (standard / pvd / retrò)
                t.stileColFinitureImg = {
                    minWidth: "20%",
                    marginTop: "2%",
                };
                //stile extation panel finiture
                t.stileExtationPanel = {
                    width: "90%",
                    height: "0%",
                    marginTop: "-1em",
                };
                //stile card prodotti
                t.stileCardProdotti = {
                    marginBottom: "10em",
                    marginLeft: "0em",
                    height: "auto",
                };
                //stile img SI maniglioni / alzanti
                t.stileImgProdottoSiManiglioneAlzante.height = "385px";
                //stile paragrafo NO maniglioni / alzanti
                t.stileParagrafoNoManiglioneAlzante = {
                    marginTop: "0em",
                    width: "300px",
                };
                //stile paragrafo SI maniglioni / alzanti
                t.stileParagrafoSiManiglioneAlzante = {
                    marginLeft: "-1em",
                    marginTop: "-2.2em",
                    width: "300px",
                };
                //paragrafo extation panel NO maniglioni / alzanti
                t.stilePanelNoManiglioneAlzante = {
                    marginTop: "6em",
                    marginLeft: "-4.1em",
                    fontSize: "11px",
                };
                //paragrafo extation panel SI maniglioni / alzanti
                t.stilePanelSiManiglioneAlzante = {
                    marginTop: "6em",
                    marginLeft: "-4.1em",
                    fontSize: "11px",
                };
                //linea NO maniglioni / alzanti
                t.stileLineaNoManiglioneAlzante = {
                    marginTop: "-1.5em",
                    marginLeft: "0em",
                    fontSize: "12px",
                };
                //linea SI maniglioni / alzanti
                t.stileLineaSiManiglioneAlzante = {
                    marginTop: "-1.5em",
                    marginLeft: "-1.6em",
                    fontSize: "12px",
                };
                //finiture standard NO maniglioni / alzanti
                t.stileFinitureStandardNoManiglioneAlzante = {
                    minWidth: "100%",
                    marginLeft: "-2.8em",
                    marginTop: "0em",
                };
                //finiture standard SI maniglioni / alzanti
                t.stileFinitureStandardSiManiglioneAlzante = {
                    width: "120%",
                    marginLeft: "-2.8em",
                    marginTop: "0em",
                };
                //finiture standard scorrevoli
                t.stileFinitureStandardSiScorrevoli = {
                    minWidth: "100%",
                    marginLeft: "-3em",
                    marginTop: "0.3em",
                };
            } else if (value > 1540 && value <= 1920) {
                //classic pc FULL HD 16:9 / 	16:10 --> 1680
                //img prodotto NO maniglioni / alzanti
                t.stileImgProdottoNoManiglioneAlzante = {
                    minWidth: "371px",
                    height: "auto",
                    cursor: "pointer",
                    backgroundColor: "white",
                    marginLeft: "-2em",
                    marginTop: "3.3em",
                    marginBottom: "6.3em",
                };
                //img prodotto SI maniglioni / alzanti
                t.stileImgProdottoSiManiglioneAlzante = {
                    height: "0px",
                    minWidth: "auto",
                    cursor: "pointer",
                    backgroundColor: "white",
                    marginLeft: "-2em",
                    marginTop: "1em",
                };
                //stile riga prodotti
                t.stileRowProdotti = {
                    marginLeft: "3em",
                    marginRight: "2em",
                    marginTop: "1em",
                    width: "100%",
                    marginBottom: "0em",
                };
                //stile icon prodotti
                t.stileIconProdotti = {
                    width: "30px",
                    height: "30px",
                    marginTop: "0em",
                    marginLeft: "0em",
                };
                //stile Img fintire (standard / pvd / retrò)
                t.stileColFinitureImg = {
                    minWidth: "20%",
                    marginTop: "2%",
                };
                //stile extation panel finiture
                t.stileExtationPanel = {
                    width: "90%",
                    height: "0%",
                    marginTop: "-1em",
                };
                //stile card prodotti
                t.stileCardProdotti = {
                    marginBottom: "10em",
                    marginLeft: "0em",
                    height: "auto",
                };
                //stile img SI maniglioni / alzanti
                t.stileImgProdottoSiManiglioneAlzante.height = "385px";
                //stile paragrafo NO maniglioni / alzanti
                t.stileParagrafoNoManiglioneAlzante = {
                    marginTop: "0em",
                    width: "300px",
                };
                //stile paragrafo SI maniglioni / alzanti
                t.stileParagrafoSiManiglioneAlzante = {
                    marginTop: "-3em",
                    width: "300px",
                    marginLeft: "-1em",
                };
                //paragrafo extation panel NO maniglioni / alzanti
                t.stilePanelNoManiglioneAlzante = {
                    marginTop: "6em",
                    marginLeft: "-4.1em",
                    fontSize: "11px",
                };
                //paragrafo extation panel SI maniglioni / alzanti
                t.stilePanelSiManiglioneAlzante = {
                    marginTop: "6em",
                    marginLeft: "-4.1em",
                    fontSize: "11px",
                };
                //linea NO maniglioni / alzanti
                t.stileLineaNoManiglioneAlzante = {
                    marginTop: "-1.5em",
                    marginLeft: "0em",
                    fontSize: "12px",
                };
                //linea SI maniglioni / alzanti
                t.stileLineaSiManiglioneAlzante = {
                    marginTop: "-1.5em",
                    marginLeft: "-1.6em",
                    fontSize: "12px",
                };
                //finiture standard NO maniglioni / alzanti
                t.stileFinitureStandardNoManiglioneAlzante = {
                    minWidth: "100%",
                    marginLeft: "-2.8em",
                    marginTop: "0em",
                };
                //finiture standard SI maniglioni / alzanti
                t.stileFinitureStandardSiManiglioneAlzante = {
                    width: "120%",
                    marginLeft: "-2.8em",
                    marginTop: "0em",
                };
                //finiture standard scorrevoli
                t.stileFinitureStandardSiScorrevoli = {
                    minWidth: "100%",
                    marginLeft: "-3em",
                    marginTop: "0.3em",
                };
            } else if (value > 1920 && value <= 2100) {
                //classic pc (16:10 / 16:9 / 21:9)
            } else if (value > 2100 && value <= 2560) {
                //pc monitor (16:9)
                //img prodotto NO maniglioni / alzanti
                t.stileImgProdottoNoManiglioneAlzante = {
                    minWidth: "500px",
                    height: "auto",
                    cursor: "pointer",
                    backgroundColor: "white",
                    marginLeft: "0em",
                    marginTop: "9.5em",
                    marginBottom: "0em",
                };
                //img prodotto SI maniglioni / alzanti
                t.stileImgProdottoSiManiglioneAlzante = {
                    height: "650px",
                    minWidth: "auto",
                    cursor: "pointer",
                    backgroundColor: "white",
                    marginLeft: "-1em",
                    marginTop: "-5em",
                };
                //stile riga prodotti
                t.stileRowProdotti = {
                    marginLeft: "7em",
                    marginRight: "0em",
                    marginTop: "-2em",
                    width: "95%",
                    marginBottom: "25em",
                };
                //stile card prodotti
                t.stileCardProdotti = {
                    marginBottom: "10em",
                    marginLeft: "5em",
                    height: "10em",
                };
                //stile paragrafo NO maniglioni / alzanti
                t.stileParagrafoNoManiglioneAlzante = {
                    marginTop: "4.5em",
                    marginLeft: "2em",
                    width: "390px",
                };
                //stile paragrafo SI maniglioni / alzanti
                t.stileParagrafoSiManiglioneAlzante = {
                    marginTop: "-3em",
                    marginLeft: "0em",
                    marginBottom: "1em",
                    width: "390px",
                };
                //stile icon prodotti
                t.stileIconProdotti = {
                    width: "30",
                    height: "30px",
                    marginTop: "0em",
                    marginLeft: "0em",
                };
                //linea NO maniglioni / alzanti
                t.stileLineaNoManiglioneAlzante = {
                    marginTop: "-1.5em",
                    marginLeft: "3.3em",
                    fontSize: "12px",
                };
                //linea SI maniglioni / alzanti
                t.stileLineaSiManiglioneAlzante = {
                    marginTop: "-1.5em",
                    marginLeft: "0em",
                    fontSize: "12px",
                };
                //paragrafo extation panel NO maniglioni / alzanti
                t.stilePanelNoManiglioneAlzante = {
                    marginTop: "32em",
                    marginLeft: "-6.5em",
                    fontSize: "11px",
                };
                //paragrafo extation panel SI maniglioni / alzanti
                t.stilePanelSiManiglioneAlzante = {
                    marginTop: "32em",
                    marginLeft: "-4em",
                    fontSize: "11px",
                };
                //stile extation panel finiture
                t.stileExtationPanel = {
                    width: "100%",
                    height: "0%",
                    marginTop: "-26em",
                };
                //stile Img fintire (standard / pvd / retrò)
                t.stileColFinitureImg = {
                    minWidth: "20%",
                    marginTop: "2%",
                };
                //finiture standard NO maniglioni / alzanti
                t.stileFinitureStandardNoManiglioneAlzante = {
                    minWidth: "100%",
                    marginLeft: "-4.5em",
                    marginTop: "0em",
                };
                //finiture standard SI maniglioni / alzanti
                t.stileFinitureStandardSiManiglioneAlzante = {
                    width: "120%",
                    marginLeft: "-2.8em",
                    marginTop: "0em",
                };
                //finiture standard scorrevoli
                t.stileFinitureStandardSiScorrevoli = {
                    minWidth: "100%",
                    marginLeft: "-4.5em",
                    marginTop: "0.3em",
                };
            } else if (value > 2560 && value <= 3840) {
                //TV 4K
                //img prodotto NO maniglioni / alzanti
                t.stileImgProdottoNoManiglioneAlzante = {
                    minWidth: "650px",
                    height: "auto",
                    cursor: "pointer",
                    backgroundColor: "white",
                    marginLeft: "0em",
                    marginTop: "15em",
                    marginBottom: "0em",
                };
                //img prodotto SI maniglioni / alzanti
                t.stileImgProdottoSiManiglioneAlzante = {
                    height: "750px",
                    minWidth: "auto",
                    cursor: "pointer",
                    backgroundColor: "white",
                    marginLeft: "-1em",
                    marginTop: "-5em",
                };
                //stile riga prodotti
                t.stileRowProdotti = {
                    marginLeft: "10em",
                    marginRight: "0em",
                    marginTop: "0em",
                    width: "95%",
                    marginBottom: "38em",
                };
                //stile card prodotti
                t.stileCardProdotti = {
                    marginBottom: "10em",
                    marginLeft: "15em",
                    height: "10em",
                };
                //stile paragrafo NO maniglioni / alzanti
                t.stileParagrafoNoManiglioneAlzante = {
                    marginTop: "1em",
                    marginLeft: "1.5em",
                    width: "500px",
                };
                //stile paragrafo SI maniglioni / alzanti
                t.stileParagrafoSiManiglioneAlzante = {
                    marginTop: "-1em",
                    marginLeft: "0em",
                    marginBottom: "1em",
                    width: "500px",
                };
                //stile icon prodotto
                t.stileIconProdotti = {
                    width: "40",
                    height: "40px",
                    marginTop: "0em",
                    marginLeft: "0em",
                };
                //linea NO maniglioni / alzanti
                t.stileLineaNoManiglioneAlzante = {
                    marginTop: "-1.5em",
                    marginLeft: "2.9em",
                    fontSize: "18px",
                };
                //linea SI maniglioni / alzanti
                t.stileLineaSiManiglioneAlzante = {
                    marginTop: "-1.5em",
                    marginLeft: "0em",
                    fontSize: "18px",
                };
                //stile extation panel finiture
                t.stileExtationPanel = {
                    width: "125%",
                    height: "0%",
                    marginTop: "-37em",
                };
                //paragrafo extation panel NO maniglioni / alzanti
                t.stilePanelNoManiglioneAlzante = {
                    marginTop: "32em",
                    marginLeft: "-6em",
                    fontSize: "16px",
                };
                //paragrafo extation panel SI maniglioni / alzanti
                t.stilePanelSiManiglioneAlzante = {
                    marginTop: "32em",
                    marginLeft: "-6.2em",
                    fontSize: "16px",
                };
                //stile Img fintire (standard / pvd / retrò)
                t.stileColFinitureImg = {
                    minWidth: "20%",
                    marginTop: "2%",
                };
                //finiture standard NO maniglioni / alzanti
                t.stileFinitureStandardNoManiglioneAlzante = {
                    minWidth: "130%",
                    marginLeft: "-6.5em",
                    marginTop: "0em",
                };
                //finiture standard SI maniglioni / alzanti
                t.stileFinitureStandardSiManiglioneAlzante = {
                    width: "130%",
                    marginLeft: "-6em",
                    marginTop: "0em",
                };
                //finiture standard scorrevoli
                t.stileFinitureStandardSiScorrevoli = {
                    minWidth: "150%",
                    marginLeft: "-8.5em",
                    marginTop: "0.3em",
                };
            }
        },

        //MENU PVD
        getStiliMenuPvd(t, value) {
            //controll dimension display devices
            //fare proporzione tra una dimensione e l'altra per scalare tutti gli stili //2340 --> 4K = +50%
            t.stileRowProdotti = {}; //riga prodotti
            t.stileIconProdotti = {}; // icons prodotti
            t.stileColFinitureImg = {}; //colonna finiture
            t.stileExtationPanel = {}; //extation panel finiture
            t.stileCardProdotti = {}; // card prodotti
            t.stileImgProdottoNoManiglioneAlzante = {}; //img prodotti NO maniglioni / alzanti
            t.stileImgProdottoSiManiglioneAlzante = {}; //img prodotti SI maniglioni / alzanti
            t.stileParagrafoNoManiglioneAlzante = {}; //paragrafo NO maniglioni / alzanti
            t.stileParagrafoSiManiglioneAlzante = {}; //paragrafo SI maniglioni / alzanti
            t.stileLineaNoManiglioneAlzante = {}; //linea NO maniglioni / alzanti
            t.stileLineaSiManiglioneAlzante = {}; //linea SI maniglioni / alzanti
            t.stilePanelNoManiglioneAlzante = {}; //paragrafo extation panel NO maniglioni / alzanti
            t.stilePanelSiManiglioneAlzante = {}; //paragrafo extation panel SI maniglioni / alzanti
            t.stileFinitureStandardNoManiglioneAlzante = {}; //finiture standard NO maniglioni / alzanti
            t.stileFinitureStandardSiManiglioneAlzante = {}; //finiture standard SI maniglioni / alzanti
            t.stileFinitureStandardSiScorrevoli = {}; //finiture standard scorrevoli

            if (value <= 768) {
                //smartphone
                //img prodotto NO maniglioni / alzanti
                t.stileImgProdottoNoManiglioneAlzante = {
                    minWidth: "371px",
                    height: "auto",
                    cursor: "pointer",
                    backgroundColor: "white",
                    marginLeft: "-2em",
                    marginTop: "3.3em",
                    marginBottom: "6.3em",
                };
                //img prodotto SI maniglioni / alzanti
                t.stileImgProdottoSiManiglioneAlzante = {
                    height: "0px",
                    minWidth: "auto",
                    cursor: "pointer",
                    backgroundColor: "white",
                    marginLeft: "-2em",
                    marginTop: "0em",
                };
                //stile riga prodotti
                t.stileRowProdotti = {
                    marginTop: "-3em",
                    marginLeft: "0em",
                    marginRight: "0em",
                    width: "100%",
                    marginBottom: "0em",
                };
                //stile icon prodotti
                t.stileIconProdotti = {
                    width: "25px",
                    height: "25px",
                    marginTop: "0em",
                    marginLeft: "0em",
                };
                //stile Col Img fintire (standard / pvd / retrò)
                t.stileColFinitureImg = {
                    minWidth: "20%",
                    marginTop: "5%",
                };
                //stile extation panel finiture
                t.stileExtationPanel = {
                    width: "87%",
                    height: "0%",
                    marginTop: "-2em",
                };
                //stile card prodotti
                t.stileCardProdotti = {
                    marginBottom: "15em",
                    marginLeft: "0em",
                    height: "auto",
                };
                //stile img SI maniglioni / alzanti
                t.stileImgProdottoSiManiglioneAlzante.height = "500px";
                //stile paragrafo NO maniglioni / alzanti
                t.stileParagrafoNoManiglioneAlzante = {
                    marginTop: "-2em",
                    width: "300px",
                };
                //stile paragrafo SI maniglioni / alzanti
                t.stileParagrafoSiManiglioneAlzante = {
                    marginLeft: "-0.9em",
                    marginTop: "-2.2em",
                    width: "300px",
                };
                //paragrafo extation panel NO maniglioni / alzanti
                t.stilePanelNoManiglioneAlzante = {
                    marginTop: "6em",
                    marginLeft: "-4.5em",
                    fontSize: "11px",
                };
                //paragrafo extation panel SI maniglioni / alzanti
                t.stilePanelSiManiglioneAlzante = {
                    marginTop: "12em",
                    marginLeft: "-4.5em",
                    fontSize: "11px",
                };
                //linea NO maniglioni / alzanti
                t.stileLineaNoManiglioneAlzante = {
                    marginTop: "-1.5em",
                    marginLeft: "0em",
                    fontSize: "12px",
                };
                //linea SI maniglioni / alzanti
                t.stileLineaSiManiglioneAlzante = {
                    marginTop: "-1.5em",
                    marginLeft: "-1.5em",
                    fontSize: "12px",
                };
                //finiture standard NO maniglioni / alzanti
                t.stileFinitureStandardNoManiglioneAlzante = {
                    minWidth: "120%",
                    marginLeft: "-3.3em",
                    marginTop: "0em",
                };
                //finiture standard SI maniglioni / alzanti
                t.stileFinitureStandardSiManiglioneAlzante = {
                    width: "120%",
                    marginLeft: "-3.3em",
                    marginTop: "0em",
                };
                //finiture standard scorrevoli
                t.stileFinitureStandardSiScorrevoli = {
                    minWidth: "130%",
                    marginLeft: "-2.7em",
                    marginTop: "-0.5em",
                };
            } else if (value > 768 && value <= 1050) {
                //tablet (1024)
                //img prodotto NO maniglioni / alzanti
                t.stileImgProdottoNoManiglioneAlzante = {
                    minWidth: "371px",
                    height: "auto",
                    cursor: "pointer",
                    backgroundColor: "white",
                    marginLeft: "-2em",
                    marginTop: "3.3em",
                    marginBottom: "6.3em",
                };
                //img prodotto SI maniglioni / alzanti
                t.stileImgProdottoSiManiglioneAlzante = {
                    height: "0px",
                    minWidth: "auto",
                    cursor: "pointer",
                    backgroundColor: "white",
                    marginLeft: "-2em",
                    marginTop: "0em",
                };
                //stile riga prodotti
                t.stileRowProdotti = {
                    marginLeft: "2em",
                    marginRight: "2em",
                    marginTop: "-1.5em",
                    width: "100%",
                    marginBottom: "0em",
                };
                //stile icon prodotti
                t.stileIconProdotti = {
                    width: "25px",
                    height: "25px",
                    marginTop: "0em",
                    marginLeft: "0em",
                };
                //stile Img fintire (standard / pvd / retrò)
                t.stileColFinitureImg = {
                    minWidth: "20%",
                    marginTop: "2%",
                };
                //stile extation panel finiture
                t.stileExtationPanel = {
                    width: "90%",
                    height: "0%",
                    marginTop: "-1em",
                };
                //stile card prodotti
                t.stileCardProdotti = {
                    marginBottom: "10em",
                    marginLeft: "0em",
                    height: "auto",
                };
                //stile img SI maniglioni / alzanti
                t.stileImgProdottoSiManiglioneAlzante.height = "385px";
                //stile paragrafo NO maniglioni / alzanti
                t.stileParagrafoNoManiglioneAlzante = {
                    marginTop: "0em",
                    width: "300px",
                };
                //stile paragrafo SI maniglioni / alzanti
                t.stileParagrafoSiManiglioneAlzante = {
                    marginTop: "-2.2em",
                    marginLeft: "0em",
                    width: "300px",
                };
                //paragrafo extation panel NO maniglioni / alzanti
                t.stilePanelNoManiglioneAlzante = {
                    marginTop: "6em",
                    marginLeft: "-4.1em",
                    fontSize: "11px",
                };
                //paragrafo extation panel SI maniglioni / alzanti
                t.stilePanelSiManiglioneAlzante = {
                    marginTop: "6em",
                    marginLeft: "-2.3em",
                    fontSize: "11px",
                };
                //linea NO maniglioni / alzanti
                t.stileLineaNoManiglioneAlzante = {
                    marginTop: "-1.5em",
                    marginLeft: "0em",
                    fontSize: "12px",
                };
                //linea SI maniglioni / alzanti
                t.stileLineaSiManiglioneAlzante = {
                    marginTop: "-1.5em",
                    marginLeft: "0em",
                    fontSize: "12px",
                };
                //finiture standard NO maniglioni / alzanti
                t.stileFinitureStandardNoManiglioneAlzante = {
                    minWidth: "100%",
                    marginLeft: "-2.8em",
                    marginTop: "0em",
                };
                //finiture standard SI maniglioni / alzanti
                t.stileFinitureStandardSiManiglioneAlzante = {
                    width: "120%",
                    marginLeft: "-1.7em",
                    marginTop: "0em",
                };
                //finiture standard scorrevoli
                t.stileFinitureStandardSiScorrevoli = {
                    minWidth: "100%",
                    marginLeft: "-3em",
                    marginTop: "0.3em",
                };
            } else if (value > 1050 && value <= 1280) {
                //HD (16:9 / 16:10)
                //img prodotto NO maniglioni / alzanti
                t.stileImgProdottoNoManiglioneAlzante = {
                    minWidth: "371px",
                    height: "auto",
                    cursor: "pointer",
                    backgroundColor: "white",
                    marginLeft: "-2em",
                    marginTop: "3.3em",
                    marginBottom: "6.3em",
                };
                //img prodotto SI maniglioni / alzanti
                t.stileImgProdottoSiManiglioneAlzante = {
                    height: "0px",
                    minWidth: "auto",
                    cursor: "pointer",
                    backgroundColor: "white",
                    marginLeft: "-2em",
                    marginTop: "0em",
                };
                //stile riga prodotti
                t.stileRowProdotti = {
                    marginLeft: "2em",
                    marginRight: "2em",
                    marginTop: "-1em",
                    width: "100%",
                    marginBottom: "1em",
                };
                //stile icon prodotti
                t.stileIconProdotti = {
                    width: "25px",
                    height: "25px",
                    marginTop: "0em",
                    marginLeft: "0em",
                };
                //stile Img fintire (standard / pvd / retrò)
                t.stileColFinitureImg = {
                    minWidth: "20%",
                    marginTop: "2%",
                };
                //stile extation panel finiture
                t.stileExtationPanel = {
                    width: "90%",
                    height: "0%",
                    marginTop: "-1em",
                };
                //stile card prodotti
                t.stileCardProdotti = {
                    marginBottom: "10em",
                    marginLeft: "0em",
                    height: "auto",
                };
                //stile img SI maniglioni / alzanti
                t.stileImgProdottoSiManiglioneAlzante.height = "385px";
                //stile paragrafo NO maniglioni / alzanti
                t.stileParagrafoNoManiglioneAlzante = {
                    marginTop: "0em",
                    width: "300px",
                };
                //stile paragrafo SI maniglioni / alzanti
                t.stileParagrafoSiManiglioneAlzante = {
                    marginTop: "-2.2em",
                    marginLeft: "0em",
                    width: "300px",
                };
                //paragrafo extation panel NO maniglioni / alzanti
                t.stilePanelNoManiglioneAlzante = {
                    marginTop: "6em",
                    marginLeft: "-4.1em",
                    fontSize: "11px",
                };
                //paragrafo extation panel SI maniglioni / alzanti
                t.stilePanelSiManiglioneAlzante = {
                    marginTop: "6em",
                    marginLeft: "-2.3em",
                    fontSize: "11px",
                };
                //linea NO maniglioni / alzanti
                t.stileLineaNoManiglioneAlzante = {
                    marginTop: "-1.5em",
                    marginLeft: "0em",
                    fontSize: "12px",
                };
                //linea SI maniglioni / alzanti
                t.stileLineaSiManiglioneAlzante = {
                    marginTop: "-1.5em",
                    marginLeft: "0em",
                };
                //finiture standard NO maniglioni / alzanti
                t.stileFinitureStandardNoManiglioneAlzante = {
                    minWidth: "100%",
                    marginLeft: "-2.8em",
                    marginTop: "0em",
                };
                //finiture standard SI maniglioni / alzanti
                t.stileFinitureStandardSiManiglioneAlzante = {
                    width: "120%",
                    marginLeft: "-1.7em",
                    marginTop: "0em",
                };
                //finiture standard scorrevoli
                t.stileFinitureStandardSiScorrevoli = {
                    minWidth: "100%",
                    marginLeft: "-3em",
                    marginTop: "0.3em",
                };
            } else if (value > 1280 && value <= 1540) {
                //tablet apple / surface (windows)
                //img prodotto NO maniglioni / alzanti
                t.stileImgProdottoNoManiglioneAlzante = {
                    minWidth: "371px",
                    height: "auto",
                    cursor: "pointer",
                    backgroundColor: "white",
                    marginLeft: "-2em",
                    marginTop: "3.3em",
                    marginBottom: "6.3em",
                };
                //img prodotto SI maniglioni / alzanti
                t.stileImgProdottoSiManiglioneAlzante = {
                    height: "0px",
                    minWidth: "auto",
                    cursor: "pointer",
                    backgroundColor: "white",
                    marginLeft: "-2em",
                    marginTop: "0em",
                };
                //stile riga prodotti
                t.stileRowProdotti = {
                    marginLeft: "2em",
                    marginRight: "2em",
                    marginTop: "-2em",
                    width: "100%",
                    marginBottom: "0em",
                };
                //stile icon prodotti
                t.stileIconProdotti = {
                    width: "25px",
                    height: "25px",
                    marginTop: "0em",
                    marginLeft: "0em",
                };
                //stile Img fintire (standard / pvd / retrò)
                t.stileColFinitureImg = {
                    minWidth: "20%",
                    marginTop: "2%",
                };
                //stile extation panel finiture
                t.stileExtationPanel = {
                    width: "90%",
                    height: "0%",
                    marginTop: "-1em",
                };
                //stile card prodotti
                t.stileCardProdotti = {
                    marginBottom: "10em",
                    marginLeft: "0em",
                    height: "auto",
                };
                //stile img SI maniglioni / alzanti
                t.stileImgProdottoSiManiglioneAlzante.height = "385px";
                //stile paragrafo NO maniglioni / alzanti
                t.stileParagrafoNoManiglioneAlzante = {
                    marginTop: "0em",
                    width: "300px",
                };
                //stile paragrafo SI maniglioni / alzanti
                t.stileParagrafoSiManiglioneAlzante = {
                    marginLeft: "-1em",
                    marginTop: "-2.2em",
                    width: "300px",
                };
                //paragrafo extation panel NO maniglioni / alzanti
                t.stilePanelNoManiglioneAlzante = {
                    marginTop: "6em",
                    marginLeft: "-4.1em",
                    fontSize: "11px",
                };
                //paragrafo extation panel SI maniglioni / alzanti
                t.stilePanelSiManiglioneAlzante = {
                    marginTop: "6em",
                    marginLeft: "-4.1em",
                    fontSize: "11px",
                };
                //linea NO maniglioni / alzanti
                t.stileLineaNoManiglioneAlzante = {
                    marginTop: "-1.5em",
                    marginLeft: "0em",
                    fontSize: "12px",
                };
                //linea SI maniglioni / alzanti
                t.stileLineaSiManiglioneAlzante = {
                    marginTop: "-1.5em",
                    marginLeft: "-1.6em",
                    fontSize: "12px",
                };
                //finiture standard NO maniglioni / alzanti
                t.stileFinitureStandardNoManiglioneAlzante = {
                    minWidth: "100%",
                    marginLeft: "-2.8em",
                    marginTop: "0em",
                };
                //finiture standard SI maniglioni / alzanti
                t.stileFinitureStandardSiManiglioneAlzante = {
                    width: "120%",
                    marginLeft: "-2.8em",
                    marginTop: "0em",
                };
                //finiture standard scorrevoli
                t.stileFinitureStandardSiScorrevoli = {
                    minWidth: "100%",
                    marginLeft: "-3em",
                    marginTop: "0.3em",
                };
            } else if (value > 1540 && value <= 1920) {
                //classic pc FULL HD 16:9 / 	16:10 --> 1680
                //img prodotto NO maniglioni / alzanti
                t.stileImgProdottoNoManiglioneAlzante = {
                    minWidth: "371px",
                    height: "auto",
                    cursor: "pointer",
                    backgroundColor: "white",
                    marginLeft: "-2em",
                    marginTop: "3.3em",
                    marginBottom: "6.3em",
                };
                //img prodotto SI maniglioni / alzanti
                t.stileImgProdottoSiManiglioneAlzante = {
                    height: "0px",
                    minWidth: "auto",
                    cursor: "pointer",
                    backgroundColor: "white",
                    marginLeft: "-2em",
                    marginTop: "1em",
                };
                //stile riga prodotti
                t.stileRowProdotti = {
                    marginLeft: "3em",
                    marginRight: "2em",
                    marginTop: "1em",
                    width: "100%",
                    marginBottom: "0em",
                };
                //stile icon prodotti
                t.stileIconProdotti = {
                    width: "30px",
                    height: "30px",
                    marginTop: "0em",
                    marginLeft: "0em",
                };
                //stile Img fintire (standard / pvd / retrò)
                t.stileColFinitureImg = {
                    minWidth: "20%",
                    marginTop: "2%",
                };
                //stile extation panel finiture
                t.stileExtationPanel = {
                    width: "90%",
                    height: "0%",
                    marginTop: "-1em",
                };
                //stile card prodotti
                t.stileCardProdotti = {
                    marginBottom: "10em",
                    marginLeft: "0em",
                    height: "auto",
                };
                //stile img SI maniglioni / alzanti
                t.stileImgProdottoSiManiglioneAlzante.height = "385px";
                //stile paragrafo NO maniglioni / alzanti
                t.stileParagrafoNoManiglioneAlzante = {
                    marginTop: "0em",
                    width: "300px",
                };
                //stile paragrafo SI maniglioni / alzanti
                t.stileParagrafoSiManiglioneAlzante = {
                    marginTop: "-3em",
                    width: "300px",
                    marginLeft: "-1em",
                };
                //paragrafo extation panel NO maniglioni / alzanti
                t.stilePanelNoManiglioneAlzante = {
                    marginTop: "6em",
                    marginLeft: "-4.1em",
                    fontSize: "11px",
                };
                //paragrafo extation panel SI maniglioni / alzanti
                t.stilePanelSiManiglioneAlzante = {
                    marginTop: "6em",
                    marginLeft: "-4.1em",
                    fontSize: "11px",
                };
                //linea NO maniglioni / alzanti
                t.stileLineaNoManiglioneAlzante = {
                    marginTop: "-1.5em",
                    marginLeft: "0em",
                    fontSize: "12px",
                };
                //linea SI maniglioni / alzanti
                t.stileLineaSiManiglioneAlzante = {
                    marginTop: "-1.5em",
                    marginLeft: "-1.6em",
                    fontSize: "12px",
                };
                //finiture standard NO maniglioni / alzanti
                t.stileFinitureStandardNoManiglioneAlzante = {
                    minWidth: "100%",
                    marginLeft: "-2.8em",
                    marginTop: "0em",
                };
                //finiture standard SI maniglioni / alzanti
                t.stileFinitureStandardSiManiglioneAlzante = {
                    width: "120%",
                    marginLeft: "-2.8em",
                    marginTop: "0em",
                };
                //finiture standard scorrevoli
                t.stileFinitureStandardSiScorrevoli = {
                    minWidth: "100%",
                    marginLeft: "-3em",
                    marginTop: "0.3em",
                };
            } else if (value > 1920 && value <= 2100) {
                //classic pc (16:10 / 16:9 / 21:9)
            } else if (value > 2100 && value <= 2560) {
                //pc monitor (16:9)
                //img prodotto NO maniglioni / alzanti
                t.stileImgProdottoNoManiglioneAlzante = {
                    minWidth: "500px",
                    height: "auto",
                    cursor: "pointer",
                    backgroundColor: "white",
                    marginLeft: "0em",
                    marginTop: "9.5em",
                    marginBottom: "0em",
                };
                //img prodotto SI maniglioni / alzanti
                t.stileImgProdottoSiManiglioneAlzante = {
                    height: "650px",
                    minWidth: "auto",
                    cursor: "pointer",
                    backgroundColor: "white",
                    marginLeft: "-1em",
                    marginTop: "-5em",
                };
                //stile riga prodotti
                t.stileRowProdotti = {
                    marginLeft: "7em",
                    marginRight: "0em",
                    marginTop: "-2em",
                    width: "95%",
                    marginBottom: "25em",
                };
                //stile card prodotti
                t.stileCardProdotti = {
                    marginBottom: "10em",
                    marginLeft: "5em",
                    height: "10em",
                };
                //stile paragrafo NO maniglioni / alzanti
                t.stileParagrafoNoManiglioneAlzante = {
                    marginTop: "4.5em",
                    marginLeft: "2em",
                    width: "390px",
                };
                //stile paragrafo SI maniglioni / alzanti
                t.stileParagrafoSiManiglioneAlzante = {
                    marginTop: "-3em",
                    marginLeft: "0em",
                    marginBottom: "1em",
                    width: "390px",
                };
                //stile icon prodotti
                t.stileIconProdotti = {
                    width: "30",
                    height: "30px",
                    marginTop: "0em",
                    marginLeft: "0em",
                };
                //linea NO maniglioni / alzanti
                t.stileLineaNoManiglioneAlzante = {
                    marginTop: "-1.5em",
                    marginLeft: "3.3em",
                    fontSize: "12px",
                };
                //linea SI maniglioni / alzanti
                t.stileLineaSiManiglioneAlzante = {
                    marginTop: "-1.5em",
                    marginLeft: "0em",
                    fontSize: "12px",
                };
                //paragrafo extation panel NO maniglioni / alzanti
                t.stilePanelNoManiglioneAlzante = {
                    marginTop: "32em",
                    marginLeft: "-6.5em",
                    fontSize: "11px",
                };
                //paragrafo extation panel SI maniglioni / alzanti
                t.stilePanelSiManiglioneAlzante = {
                    marginTop: "32em",
                    marginLeft: "-4em",
                    fontSize: "11px",
                };
                //stile extation panel finiture
                t.stileExtationPanel = {
                    width: "100%",
                    height: "0%",
                    marginTop: "-26em",
                };
                //stile Img fintire (standard / pvd / retrò)
                t.stileColFinitureImg = {
                    minWidth: "20%",
                    marginTop: "2%",
                };
                //finiture standard NO maniglioni / alzanti
                t.stileFinitureStandardNoManiglioneAlzante = {
                    minWidth: "100%",
                    marginLeft: "-4.5em",
                    marginTop: "0em",
                };
                //finiture standard SI maniglioni / alzanti
                t.stileFinitureStandardSiManiglioneAlzante = {
                    width: "120%",
                    marginLeft: "-2.8em",
                    marginTop: "0em",
                };
                //finiture standard scorrevoli
                t.stileFinitureStandardSiScorrevoli = {
                    minWidth: "100%",
                    marginLeft: "-4.5em",
                    marginTop: "0.3em",
                };
            } else if (value > 2560 && value <= 3840) {
                //TV 4K
                //img prodotto NO maniglioni / alzanti
                t.stileImgProdottoNoManiglioneAlzante = {
                    minWidth: "650px",
                    height: "auto",
                    cursor: "pointer",
                    backgroundColor: "white",
                    marginLeft: "0em",
                    marginTop: "15em",
                    marginBottom: "0em",
                };
                //img prodotto SI maniglioni / alzanti
                t.stileImgProdottoSiManiglioneAlzante = {
                    height: "750px",
                    minWidth: "auto",
                    cursor: "pointer",
                    backgroundColor: "white",
                    marginLeft: "-1em",
                    marginTop: "-5em",
                };
                //stile riga prodotti
                t.stileRowProdotti = {
                    marginLeft: "10em",
                    marginRight: "0em",
                    marginTop: "15em",
                    width: "95%",
                    marginBottom: "38em",
                };
                //stile card prodotti
                t.stileCardProdotti = {
                    marginBottom: "10em",
                    marginLeft: "15em",
                    height: "10em",
                };
                //stile paragrafo NO maniglioni / alzanti
                t.stileParagrafoNoManiglioneAlzante = {
                    marginTop: "1em",
                    marginLeft: "1.5em",
                    width: "500px",
                };
                //stile paragrafo SI maniglioni / alzanti
                t.stileParagrafoSiManiglioneAlzante = {
                    marginTop: "-1em",
                    marginLeft: "0em",
                    marginBottom: "1em",
                    width: "500px",
                };
                //stile icon prodotto
                t.stileIconProdotti = {
                    width: "40",
                    height: "40px",
                    marginTop: "0em",
                    marginLeft: "0em",
                };
                //linea NO maniglioni / alzanti
                t.stileLineaNoManiglioneAlzante = {
                    marginTop: "-1.5em",
                    marginLeft: "2.9em",
                    fontSize: "18px",
                };
                //linea SI maniglioni / alzanti
                t.stileLineaSiManiglioneAlzante = {
                    marginTop: "-1.5em",
                    marginLeft: "0em",
                    fontSize: "18px",
                };
                //stile extation panel finiture
                t.stileExtationPanel = {
                    width: "125%",
                    height: "0%",
                    marginTop: "-37em",
                };
                //paragrafo extation panel NO maniglioni / alzanti
                t.stilePanelNoManiglioneAlzante = {
                    marginTop: "32em",
                    marginLeft: "-6em",
                    fontSize: "16px",
                };
                //paragrafo extation panel SI maniglioni / alzanti
                t.stilePanelSiManiglioneAlzante = {
                    marginTop: "32em",
                    marginLeft: "-6.2em",
                    fontSize: "16px",
                };
                //stile Img fintire (standard / pvd / retrò)
                t.stileColFinitureImg = {
                    minWidth: "20%",
                    marginTop: "2%",
                };
                //finiture standard NO maniglioni / alzanti
                t.stileFinitureStandardNoManiglioneAlzante = {
                    minWidth: "130%",
                    marginLeft: "-6.5em",
                    marginTop: "0em",
                };
                //finiture standard SI maniglioni / alzanti
                t.stileFinitureStandardSiManiglioneAlzante = {
                    width: "130%",
                    marginLeft: "-6em",
                    marginTop: "0em",
                };
                //finiture standard scorrevoli
                t.stileFinitureStandardSiScorrevoli = {
                    minWidth: "150%",
                    marginLeft: "-8.5em",
                    marginTop: "0.3em",
                };
            }
        },

        //MENU RETRO
        getStiliMenuRetro(t, value) {
            //controll dimension display devices
            //fare proporzione tra una dimensione e l'altra per scalare tutti gli stili //2340 --> 4K = +50%
            t.stileRowProdotti = {}; //riga prodotti
            t.stileIconProdotti = {}; // icons prodotti
            t.stileColFinitureImg = {}; //colonna finiture
            t.stileExtationPanel = {}; //extation panel finiture
            t.stileCardProdotti = {}; // card prodotti
            t.stileImgProdottoNoManiglioneAlzante = {}; //img prodotti NO maniglioni / alzanti
            t.stileImgProdottoSiManiglioneAlzante = {}; //img prodotti SI maniglioni / alzanti
            t.stileParagrafoNoManiglioneAlzante = {}; //paragrafo NO maniglioni / alzanti
            t.stileParagrafoSiManiglioneAlzante = {}; //paragrafo SI maniglioni / alzanti
            t.stileLineaNoManiglioneAlzante = {}; //linea NO maniglioni / alzanti
            t.stileLineaSiManiglioneAlzante = {}; //linea SI maniglioni / alzanti
            t.stilePanelNoManiglioneAlzante = {}; //paragrafo extation panel NO maniglioni / alzanti
            t.stilePanelSiManiglioneAlzante = {}; //paragrafo extation panel SI maniglioni / alzanti
            t.stileFinitureStandardNoManiglioneAlzante = {}; //finiture standard NO maniglioni / alzanti
            t.stileFinitureStandardSiManiglioneAlzante = {}; //finiture standard SI maniglioni / alzanti
            t.stileFinitureStandardSiScorrevoli = {}; //finiture standard scorrevoli

            if (value <= 768) {
                //smartphone
                //img prodotto NO maniglioni / alzanti
                t.stileImgProdottoNoManiglioneAlzante = {
                    minWidth: "371px",
                    height: "auto",
                    cursor: "pointer",
                    backgroundColor: "white",
                    marginLeft: "-2em",
                    marginTop: "3.3em",
                    marginBottom: "6.3em",
                };
                //img prodotto SI maniglioni / alzanti
                t.stileImgProdottoSiManiglioneAlzante = {
                    height: "0px",
                    minWidth: "auto",
                    cursor: "pointer",
                    backgroundColor: "white",
                    marginLeft: "-2em",
                    marginTop: "0em",
                };
                //stile riga prodotti
                t.stileRowProdotti = {
                    marginTop: "-3em",
                    marginLeft: "0em",
                    marginRight: "0em",
                    width: "100%",
                    marginBottom: "0em",
                };
                //stile icon prodotti
                t.stileIconProdotti = {
                    width: "25px",
                    height: "25px",
                    marginTop: "0em",
                    marginLeft: "0em",
                };
                //stile Col Img fintire (standard / pvd / retrò)
                t.stileColFinitureImg = {
                    minWidth: "20%",
                    marginTop: "5%",
                };
                //stile extation panel finiture
                t.stileExtationPanel = {
                    width: "87%",
                    height: "0%",
                    marginTop: "-2em",
                };
                //stile card prodotti
                t.stileCardProdotti = {
                    marginBottom: "15em",
                    marginLeft: "0em",
                    height: "auto",
                };
                //stile img SI maniglioni / alzanti
                t.stileImgProdottoSiManiglioneAlzante.height = "500px";
                //stile paragrafo NO maniglioni / alzanti
                t.stileParagrafoNoManiglioneAlzante = {
                    marginTop: "-2em",
                    width: "300px",
                };
                //stile paragrafo SI maniglioni / alzanti
                t.stileParagrafoSiManiglioneAlzante = {
                    marginLeft: "-0.9em",
                    marginTop: "-2.2em",
                    width: "300px",
                };
                //paragrafo extation panel NO maniglioni / alzanti
                t.stilePanelNoManiglioneAlzante = {
                    marginTop: "6em",
                    marginLeft: "-4.5em",
                    fontSize: "11px",
                };
                //paragrafo extation panel SI maniglioni / alzanti
                t.stilePanelSiManiglioneAlzante = {
                    marginTop: "12em",
                    marginLeft: "-4.5em",
                    fontSize: "11px",
                };
                //linea NO maniglioni / alzanti
                t.stileLineaNoManiglioneAlzante = {
                    marginTop: "-1.5em",
                    marginLeft: "0em",
                    fontSize: "12px",
                };
                //linea SI maniglioni / alzanti
                t.stileLineaSiManiglioneAlzante = {
                    marginTop: "-1.5em",
                    marginLeft: "-1.5em",
                    fontSize: "12px",
                };
                //finiture standard NO maniglioni / alzanti
                t.stileFinitureStandardNoManiglioneAlzante = {
                    minWidth: "120%",
                    marginLeft: "-3.3em",
                    marginTop: "0em",
                };
                //finiture standard SI maniglioni / alzanti
                t.stileFinitureStandardSiManiglioneAlzante = {
                    width: "120%",
                    marginLeft: "-3.3em",
                    marginTop: "0em",
                };
                //finiture standard scorrevoli
                t.stileFinitureStandardSiScorrevoli = {
                    minWidth: "130%",
                    marginLeft: "-2.7em",
                    marginTop: "-0.5em",
                };
            } else if (value > 768 && value <= 1050) {
                //tablet (1024)
                //img prodotto NO maniglioni / alzanti
                t.stileImgProdottoNoManiglioneAlzante = {
                    minWidth: "371px",
                    height: "auto",
                    cursor: "pointer",
                    backgroundColor: "white",
                    marginLeft: "-2em",
                    marginTop: "3.3em",
                    marginBottom: "6.3em",
                };
                //img prodotto SI maniglioni / alzanti
                t.stileImgProdottoSiManiglioneAlzante = {
                    height: "0px",
                    minWidth: "auto",
                    cursor: "pointer",
                    backgroundColor: "white",
                    marginLeft: "-2em",
                    marginTop: "0em",
                };
                //stile riga prodotti
                t.stileRowProdotti = {
                    marginLeft: "2em",
                    marginRight: "2em",
                    marginTop: "-1.5em",
                    width: "100%",
                    marginBottom: "0em",
                };
                //stile icon prodotti
                t.stileIconProdotti = {
                    width: "25px",
                    height: "25px",
                    marginTop: "0em",
                    marginLeft: "0em",
                };
                //stile Img fintire (standard / pvd / retrò)
                t.stileColFinitureImg = {
                    minWidth: "20%",
                    marginTop: "2%",
                };
                //stile extation panel finiture
                t.stileExtationPanel = {
                    width: "90%",
                    height: "0%",
                    marginTop: "-1em",
                };
                //stile card prodotti
                t.stileCardProdotti = {
                    marginBottom: "10em",
                    marginLeft: "0em",
                    height: "auto",
                };
                //stile img SI maniglioni / alzanti
                t.stileImgProdottoSiManiglioneAlzante.height = "385px";
                //stile paragrafo NO maniglioni / alzanti
                t.stileParagrafoNoManiglioneAlzante = {
                    marginTop: "0em",
                    width: "300px",
                };
                //stile paragrafo SI maniglioni / alzanti
                t.stileParagrafoSiManiglioneAlzante = {
                    marginTop: "-2.2em",
                    marginLeft: "0em",
                    width: "300px",
                };
                //paragrafo extation panel NO maniglioni / alzanti
                t.stilePanelNoManiglioneAlzante = {
                    marginTop: "6em",
                    marginLeft: "-4.1em",
                    fontSize: "11px",
                };
                //paragrafo extation panel SI maniglioni / alzanti
                t.stilePanelSiManiglioneAlzante = {
                    marginTop: "6em",
                    marginLeft: "-2.3em",
                    fontSize: "11px",
                };
                //linea NO maniglioni / alzanti
                t.stileLineaNoManiglioneAlzante = {
                    marginTop: "-1.5em",
                    marginLeft: "0em",
                    fontSize: "12px",
                };
                //linea SI maniglioni / alzanti
                t.stileLineaSiManiglioneAlzante = {
                    marginTop: "-1.5em",
                    marginLeft: "0em",
                    fontSize: "12px",
                };
                //finiture standard NO maniglioni / alzanti
                t.stileFinitureStandardNoManiglioneAlzante = {
                    minWidth: "100%",
                    marginLeft: "-2.8em",
                    marginTop: "0em",
                };
                //finiture standard SI maniglioni / alzanti
                t.stileFinitureStandardSiManiglioneAlzante = {
                    width: "120%",
                    marginLeft: "-1.7em",
                    marginTop: "0em",
                };
                //finiture standard scorrevoli
                t.stileFinitureStandardSiScorrevoli = {
                    minWidth: "100%",
                    marginLeft: "-3em",
                    marginTop: "0.3em",
                };
            } else if (value > 1050 && value <= 1280) {
                //HD (16:9 / 16:10)
                //img prodotto NO maniglioni / alzanti
                t.stileImgProdottoNoManiglioneAlzante = {
                    minWidth: "371px",
                    height: "auto",
                    cursor: "pointer",
                    backgroundColor: "white",
                    marginLeft: "-2em",
                    marginTop: "3.3em",
                    marginBottom: "6.3em",
                };
                //img prodotto SI maniglioni / alzanti
                t.stileImgProdottoSiManiglioneAlzante = {
                    height: "0px",
                    minWidth: "auto",
                    cursor: "pointer",
                    backgroundColor: "white",
                    marginLeft: "-2em",
                    marginTop: "0em",
                };
                //stile riga prodotti
                t.stileRowProdotti = {
                    marginLeft: "2em",
                    marginRight: "2em",
                    marginTop: "-1em",
                    width: "100%",
                    marginBottom: "1em",
                };
                //stile icon prodotti
                t.stileIconProdotti = {
                    width: "25px",
                    height: "25px",
                    marginTop: "0em",
                    marginLeft: "0em",
                };
                //stile Img fintire (standard / pvd / retrò)
                t.stileColFinitureImg = {
                    minWidth: "20%",
                    marginTop: "2%",
                };
                //stile extation panel finiture
                t.stileExtationPanel = {
                    width: "90%",
                    height: "0%",
                    marginTop: "-1em",
                };
                //stile card prodotti
                t.stileCardProdotti = {
                    marginBottom: "10em",
                    marginLeft: "0em",
                    height: "auto",
                };
                //stile img SI maniglioni / alzanti
                t.stileImgProdottoSiManiglioneAlzante.height = "385px";
                //stile paragrafo NO maniglioni / alzanti
                t.stileParagrafoNoManiglioneAlzante = {
                    marginTop: "0em",
                    width: "300px",
                };
                //stile paragrafo SI maniglioni / alzanti
                t.stileParagrafoSiManiglioneAlzante = {
                    marginTop: "-2.2em",
                    marginLeft: "0em",
                    width: "300px",
                };
                //paragrafo extation panel NO maniglioni / alzanti
                t.stilePanelNoManiglioneAlzante = {
                    marginTop: "6em",
                    marginLeft: "-4.1em",
                    fontSize: "11px",
                };
                //paragrafo extation panel SI maniglioni / alzanti
                t.stilePanelSiManiglioneAlzante = {
                    marginTop: "6em",
                    marginLeft: "-2.3em",
                    fontSize: "11px",
                };
                //linea NO maniglioni / alzanti
                t.stileLineaNoManiglioneAlzante = {
                    marginTop: "-1.5em",
                    marginLeft: "0em",
                    fontSize: "12px",
                };
                //linea SI maniglioni / alzanti
                t.stileLineaSiManiglioneAlzante = {
                    marginTop: "-1.5em",
                    marginLeft: "0em",
                };
                //finiture standard NO maniglioni / alzanti
                t.stileFinitureStandardNoManiglioneAlzante = {
                    minWidth: "100%",
                    marginLeft: "-2.8em",
                    marginTop: "0em",
                };
                //finiture standard SI maniglioni / alzanti
                t.stileFinitureStandardSiManiglioneAlzante = {
                    width: "120%",
                    marginLeft: "-1.7em",
                    marginTop: "0em",
                };
                //finiture standard scorrevoli
                t.stileFinitureStandardSiScorrevoli = {
                    minWidth: "100%",
                    marginLeft: "-3em",
                    marginTop: "0.3em",
                };
            } else if (value > 1280 && value <= 1540) {
                //tablet apple / surface (windows)
                //img prodotto NO maniglioni / alzanti
                t.stileImgProdottoNoManiglioneAlzante = {
                    minWidth: "371px",
                    height: "auto",
                    cursor: "pointer",
                    backgroundColor: "white",
                    marginLeft: "-2em",
                    marginTop: "3.3em",
                    marginBottom: "6.3em",
                };
                //img prodotto SI maniglioni / alzanti
                t.stileImgProdottoSiManiglioneAlzante = {
                    height: "0px",
                    minWidth: "auto",
                    cursor: "pointer",
                    backgroundColor: "white",
                    marginLeft: "-2em",
                    marginTop: "0em",
                };
                //stile riga prodotti
                t.stileRowProdotti = {
                    marginLeft: "2em",
                    marginRight: "2em",
                    marginTop: "0em",
                    width: "100%",
                    marginBottom: "0em",
                };
                //stile icon prodotti
                t.stileIconProdotti = {
                    width: "25px",
                    height: "25px",
                    marginTop: "0em",
                    marginLeft: "0em",
                };
                //stile Img fintire (standard / pvd / retrò)
                t.stileColFinitureImg = {
                    minWidth: "20%",
                    marginTop: "2%",
                };
                //stile extation panel finiture
                t.stileExtationPanel = {
                    width: "90%",
                    height: "0%",
                    marginTop: "-1em",
                };
                //stile card prodotti
                t.stileCardProdotti = {
                    marginBottom: "10em",
                    marginLeft: "0em",
                    height: "auto",
                };
                //stile img SI maniglioni / alzanti
                t.stileImgProdottoSiManiglioneAlzante.height = "385px";
                //stile paragrafo NO maniglioni / alzanti
                t.stileParagrafoNoManiglioneAlzante = {
                    marginTop: "0em",
                    width: "300px",
                };
                //stile paragrafo SI maniglioni / alzanti
                t.stileParagrafoSiManiglioneAlzante = {
                    marginLeft: "-1em",
                    marginTop: "-2.2em",
                    width: "300px",
                };
                //paragrafo extation panel NO maniglioni / alzanti
                t.stilePanelNoManiglioneAlzante = {
                    marginTop: "6em",
                    marginLeft: "-4.1em",
                    fontSize: "11px",
                };
                //paragrafo extation panel SI maniglioni / alzanti
                t.stilePanelSiManiglioneAlzante = {
                    marginTop: "6em",
                    marginLeft: "-4.1em",
                    fontSize: "11px",
                };
                //linea NO maniglioni / alzanti
                t.stileLineaNoManiglioneAlzante = {
                    marginTop: "-1.5em",
                    marginLeft: "0em",
                    fontSize: "12px",
                };
                //linea SI maniglioni / alzanti
                t.stileLineaSiManiglioneAlzante = {
                    marginTop: "-1.5em",
                    marginLeft: "-1.6em",
                    fontSize: "12px",
                };
                //finiture standard NO maniglioni / alzanti
                t.stileFinitureStandardNoManiglioneAlzante = {
                    minWidth: "100%",
                    marginLeft: "-2.8em",
                    marginTop: "0em",
                };
                //finiture standard SI maniglioni / alzanti
                t.stileFinitureStandardSiManiglioneAlzante = {
                    width: "120%",
                    marginLeft: "-2.8em",
                    marginTop: "0em",
                };
                //finiture standard scorrevoli
                t.stileFinitureStandardSiScorrevoli = {
                    minWidth: "100%",
                    marginLeft: "-3em",
                    marginTop: "0.3em",
                };
            } else if (value > 1540 && value <= 1920) {
                //classic pc FULL HD 16:9 / 	16:10 --> 1680
                //img prodotto NO maniglioni / alzanti
                t.stileImgProdottoNoManiglioneAlzante = {
                    minWidth: "371px",
                    height: "auto",
                    cursor: "pointer",
                    backgroundColor: "white",
                    marginLeft: "-2em",
                    marginTop: "3.3em",
                    marginBottom: "6.3em",
                };
                //img prodotto SI maniglioni / alzanti
                t.stileImgProdottoSiManiglioneAlzante = {
                    height: "0px",
                    minWidth: "auto",
                    cursor: "pointer",
                    backgroundColor: "white",
                    marginLeft: "-2em",
                    marginTop: "1em",
                };
                //stile riga prodotti
                t.stileRowProdotti = {
                    marginLeft: "3em",
                    marginRight: "2em",
                    marginTop: "1em",
                    width: "100%",
                    marginBottom: "0em",
                };
                //stile icon prodotti
                t.stileIconProdotti = {
                    width: "30px",
                    height: "30px",
                    marginTop: "0em",
                    marginLeft: "0em",
                };
                //stile Img fintire (standard / pvd / retrò)
                t.stileColFinitureImg = {
                    minWidth: "20%",
                    marginTop: "2%",
                };
                //stile extation panel finiture
                t.stileExtationPanel = {
                    width: "90%",
                    height: "0%",
                    marginTop: "-1em",
                };
                //stile card prodotti
                t.stileCardProdotti = {
                    marginBottom: "10em",
                    marginLeft: "0em",
                    height: "auto",
                };
                //stile img SI maniglioni / alzanti
                t.stileImgProdottoSiManiglioneAlzante.height = "385px";
                //stile paragrafo NO maniglioni / alzanti
                t.stileParagrafoNoManiglioneAlzante = {
                    marginTop: "0em",
                    width: "300px",
                };
                //stile paragrafo SI maniglioni / alzanti
                t.stileParagrafoSiManiglioneAlzante = {
                    marginTop: "-3em",
                    width: "300px",
                    marginLeft: "-1em",
                };
                //paragrafo extation panel NO maniglioni / alzanti
                t.stilePanelNoManiglioneAlzante = {
                    marginTop: "6em",
                    marginLeft: "-4.1em",
                    fontSize: "11px",
                };
                //paragrafo extation panel SI maniglioni / alzanti
                t.stilePanelSiManiglioneAlzante = {
                    marginTop: "6em",
                    marginLeft: "-4.1em",
                    fontSize: "11px",
                };
                //linea NO maniglioni / alzanti
                t.stileLineaNoManiglioneAlzante = {
                    marginTop: "-1.5em",
                    marginLeft: "0em",
                    fontSize: "12px",
                };
                //linea SI maniglioni / alzanti
                t.stileLineaSiManiglioneAlzante = {
                    marginTop: "-1.5em",
                    marginLeft: "-1.6em",
                    fontSize: "12px",
                };
                //finiture standard NO maniglioni / alzanti
                t.stileFinitureStandardNoManiglioneAlzante = {
                    minWidth: "100%",
                    marginLeft: "-2.8em",
                    marginTop: "0em",
                };
                //finiture standard SI maniglioni / alzanti
                t.stileFinitureStandardSiManiglioneAlzante = {
                    width: "120%",
                    marginLeft: "-2.8em",
                    marginTop: "0em",
                };
                //finiture standard scorrevoli
                t.stileFinitureStandardSiScorrevoli = {
                    minWidth: "100%",
                    marginLeft: "-3em",
                    marginTop: "0.3em",
                };
            } else if (value > 1920 && value <= 2100) {
                //classic pc (16:10 / 16:9 / 21:9)
            } else if (value > 2100 && value <= 2560) {
                //pc monitor (16:9)
                //img prodotto NO maniglioni / alzanti
                t.stileImgProdottoNoManiglioneAlzante = {
                    minWidth: "500px",
                    height: "auto",
                    cursor: "pointer",
                    backgroundColor: "white",
                    marginLeft: "0em",
                    marginTop: "9.5em",
                    marginBottom: "0em",
                };
                //img prodotto SI maniglioni / alzanti
                t.stileImgProdottoSiManiglioneAlzante = {
                    height: "650px",
                    minWidth: "auto",
                    cursor: "pointer",
                    backgroundColor: "white",
                    marginLeft: "-1em",
                    marginTop: "-5em",
                };
                //stile riga prodotti
                t.stileRowProdotti = {
                    marginLeft: "7em",
                    marginRight: "0em",
                    marginTop: "-2em",
                    width: "95%",
                    marginBottom: "25em",
                };
                //stile card prodotti
                t.stileCardProdotti = {
                    marginBottom: "10em",
                    marginLeft: "5em",
                    height: "10em",
                };
                //stile paragrafo NO maniglioni / alzanti
                t.stileParagrafoNoManiglioneAlzante = {
                    marginTop: "4.5em",
                    marginLeft: "2em",
                    width: "390px",
                };
                //stile paragrafo SI maniglioni / alzanti
                t.stileParagrafoSiManiglioneAlzante = {
                    marginTop: "-3em",
                    marginLeft: "0em",
                    marginBottom: "1em",
                    width: "390px",
                };
                //stile icon prodotti
                t.stileIconProdotti = {
                    width: "30",
                    height: "30px",
                    marginTop: "0em",
                    marginLeft: "0em",
                };
                //linea NO maniglioni / alzanti
                t.stileLineaNoManiglioneAlzante = {
                    marginTop: "-1.5em",
                    marginLeft: "3.3em",
                    fontSize: "12px",
                };
                //linea SI maniglioni / alzanti
                t.stileLineaSiManiglioneAlzante = {
                    marginTop: "-1.5em",
                    marginLeft: "0em",
                    fontSize: "12px",
                };
                //paragrafo extation panel NO maniglioni / alzanti
                t.stilePanelNoManiglioneAlzante = {
                    marginTop: "32em",
                    marginLeft: "-6.5em",
                    fontSize: "11px",
                };
                //paragrafo extation panel SI maniglioni / alzanti
                t.stilePanelSiManiglioneAlzante = {
                    marginTop: "32em",
                    marginLeft: "-4em",
                    fontSize: "11px",
                };
                //stile extation panel finiture
                t.stileExtationPanel = {
                    width: "100%",
                    height: "0%",
                    marginTop: "-26em",
                };
                //stile Img fintire (standard / pvd / retrò)
                t.stileColFinitureImg = {
                    minWidth: "20%",
                    marginTop: "2%",
                };
                //finiture standard NO maniglioni / alzanti
                t.stileFinitureStandardNoManiglioneAlzante = {
                    minWidth: "100%",
                    marginLeft: "-4.5em",
                    marginTop: "0em",
                };
                //finiture standard SI maniglioni / alzanti
                t.stileFinitureStandardSiManiglioneAlzante = {
                    width: "120%",
                    marginLeft: "-2.8em",
                    marginTop: "0em",
                };
                //finiture standard scorrevoli
                t.stileFinitureStandardSiScorrevoli = {
                    minWidth: "100%",
                    marginLeft: "-4.5em",
                    marginTop: "0.3em",
                };
            } else if (value > 2560 && value <= 3840) {
                //TV 4K
                //img prodotto NO maniglioni / alzanti
                t.stileImgProdottoNoManiglioneAlzante = {
                    minWidth: "650px",
                    height: "auto",
                    cursor: "pointer",
                    backgroundColor: "white",
                    marginLeft: "0em",
                    marginTop: "15em",
                    marginBottom: "0em",
                };
                //img prodotto SI maniglioni / alzanti
                t.stileImgProdottoSiManiglioneAlzante = {
                    height: "750px",
                    minWidth: "auto",
                    cursor: "pointer",
                    backgroundColor: "white",
                    marginLeft: "-1em",
                    marginTop: "-5em",
                };
                //stile riga prodotti
                t.stileRowProdotti = {
                    marginLeft: "10em",
                    marginRight: "0em",
                    marginTop: "10em",
                    width: "95%",
                    marginBottom: "38em",
                };
                //stile card prodotti
                t.stileCardProdotti = {
                    marginBottom: "10em",
                    marginLeft: "15em",
                    height: "10em",
                };
                //stile paragrafo NO maniglioni / alzanti
                t.stileParagrafoNoManiglioneAlzante = {
                    marginTop: "1em",
                    marginLeft: "1.5em",
                    width: "500px",
                };
                //stile paragrafo SI maniglioni / alzanti
                t.stileParagrafoSiManiglioneAlzante = {
                    marginTop: "-1em",
                    marginLeft: "0em",
                    marginBottom: "1em",
                    width: "500px",
                };
                //stile icon prodotto
                t.stileIconProdotti = {
                    width: "40",
                    height: "40px",
                    marginTop: "0em",
                    marginLeft: "0em",
                };
                //linea NO maniglioni / alzanti
                t.stileLineaNoManiglioneAlzante = {
                    marginTop: "-1.5em",
                    marginLeft: "2.9em",
                    fontSize: "18px",
                };
                //linea SI maniglioni / alzanti
                t.stileLineaSiManiglioneAlzante = {
                    marginTop: "-1.5em",
                    marginLeft: "0em",
                    fontSize: "18px",
                };
                //stile extation panel finiture
                t.stileExtationPanel = {
                    width: "125%",
                    height: "0%",
                    marginTop: "-37em",
                };
                //paragrafo extation panel NO maniglioni / alzanti
                t.stilePanelNoManiglioneAlzante = {
                    marginTop: "32em",
                    marginLeft: "-6em",
                    fontSize: "16px",
                };
                //paragrafo extation panel SI maniglioni / alzanti
                t.stilePanelSiManiglioneAlzante = {
                    marginTop: "32em",
                    marginLeft: "-6.2em",
                    fontSize: "16px",
                };
                //stile Img fintire (standard / pvd / retrò)
                t.stileColFinitureImg = {
                    minWidth: "20%",
                    marginTop: "2%",
                };
                //finiture standard NO maniglioni / alzanti
                t.stileFinitureStandardNoManiglioneAlzante = {
                    minWidth: "130%",
                    marginLeft: "-6.5em",
                    marginTop: "0em",
                };
                //finiture standard SI maniglioni / alzanti
                t.stileFinitureStandardSiManiglioneAlzante = {
                    width: "130%",
                    marginLeft: "-6em",
                    marginTop: "0em",
                };
                //finiture standard scorrevoli
                t.stileFinitureStandardSiScorrevoli = {
                    minWidth: "150%",
                    marginLeft: "-8.5em",
                    marginTop: "0.3em",
                };
            }
        },

        //MENU FINITURE
        getStiliMenuFiniture(t, value) {
            //controll dimension display devices
            //fare proporzione tra una dimensione e l'altra per scalare tutti gli stili //2340 --> 4K = +50%
            t.stileRowProdotti = {}; //riga prodotti
            t.stileIconProdotti = {}; // icons prodotti
            t.stileColFinitureImg = {}; //colonna finiture
            t.stileExtationPanel = {}; //extation panel finiture
            t.stileCardProdotti = {}; // card prodotti
            t.stileImgProdottoNoManiglioneAlzante = {}; //img prodotti NO maniglioni / alzanti
            t.stileImgProdottoSiManiglioneAlzante = {}; //img prodotti SI maniglioni / alzanti
            t.stileParagrafoNoManiglioneAlzante = {}; //paragrafo NO maniglioni / alzanti
            t.stileParagrafoSiManiglioneAlzante = {}; //paragrafo SI maniglioni / alzanti
            t.stileLineaNoManiglioneAlzante = {}; //linea NO maniglioni / alzanti
            t.stileLineaSiManiglioneAlzante = {}; //linea SI maniglioni / alzanti
            t.stilePanelNoManiglioneAlzante = {}; //paragrafo extation panel NO maniglioni / alzanti
            t.stilePanelSiManiglioneAlzante = {}; //paragrafo extation panel SI maniglioni / alzanti
            t.stileFinitureStandardNoManiglioneAlzante = {}; //finiture standard NO maniglioni / alzanti
            t.stileFinitureStandardSiManiglioneAlzante = {}; //finiture standard SI maniglioni / alzanti
            t.stileFinitureStandardSiScorrevoli = {}; //finiture standard scorrevoli

            if (value <= 768) {
                //smartphone
                //img prodotto NO maniglioni / alzanti
                t.stileImgProdottoNoManiglioneAlzante = {
                    minWidth: "371px",
                    height: "auto",
                    cursor: "pointer",
                    backgroundColor: "white",
                    marginLeft: "-2em",
                    marginTop: "3.3em",
                    marginBottom: "6.3em",
                };
                //img prodotto SI maniglioni / alzanti
                t.stileImgProdottoSiManiglioneAlzante = {
                    height: "0px",
                    minWidth: "auto",
                    cursor: "pointer",
                    backgroundColor: "white",
                    marginLeft: "-2em",
                    marginTop: "0em",
                };
                //stile riga prodotti
                t.stileRowProdotti = {
                    marginTop: "-3em",
                    marginLeft: "0em",
                    marginRight: "0em",
                    width: "100%",
                    marginBottom: "0em",
                };
                //stile icon prodotti
                t.stileIconProdotti = {
                    width: "25px",
                    height: "25px",
                    marginTop: "0em",
                    marginLeft: "0em",
                };
                //stile Col Img fintire (standard / pvd / retrò)
                t.stileColFinitureImg = {
                    minWidth: "20%",
                    marginTop: "5%",
                };
                //stile extation panel finiture
                t.stileExtationPanel = {
                    width: "87%",
                    height: "0%",
                    marginTop: "-2em",
                };
                //stile card prodotti
                t.stileCardProdotti = {
                    marginBottom: "15em",
                    marginLeft: "0em",
                    height: "auto",
                };
                //stile img SI maniglioni / alzanti
                t.stileImgProdottoSiManiglioneAlzante.height = "500px";
                //stile paragrafo NO maniglioni / alzanti
                t.stileParagrafoNoManiglioneAlzante = {
                    marginTop: "-2em",
                    width: "300px",
                };
                //stile paragrafo SI maniglioni / alzanti
                t.stileParagrafoSiManiglioneAlzante = {
                    marginLeft: "-0.9em",
                    marginTop: "-2.2em",
                    width: "300px",
                };
                //paragrafo extation panel NO maniglioni / alzanti
                t.stilePanelNoManiglioneAlzante = {
                    marginTop: "6em",
                    marginLeft: "-4.5em",
                    fontSize: "11px",
                };
                //paragrafo extation panel SI maniglioni / alzanti
                t.stilePanelSiManiglioneAlzante = {
                    marginTop: "12em",
                    marginLeft: "-4.5em",
                    fontSize: "11px",
                };
                //linea NO maniglioni / alzanti
                t.stileLineaNoManiglioneAlzante = {
                    marginTop: "-1.5em",
                    marginLeft: "0em",
                    fontSize: "12px",
                };
                //linea SI maniglioni / alzanti
                t.stileLineaSiManiglioneAlzante = {
                    marginTop: "-1.5em",
                    marginLeft: "-1.5em",
                    fontSize: "12px",
                };
                //finiture standard NO maniglioni / alzanti
                t.stileFinitureStandardNoManiglioneAlzante = {
                    minWidth: "120%",
                    marginLeft: "-3.3em",
                    marginTop: "0em",
                };
                //finiture standard SI maniglioni / alzanti
                t.stileFinitureStandardSiManiglioneAlzante = {
                    width: "120%",
                    marginLeft: "-3.3em",
                    marginTop: "0em",
                };
                //finiture standard scorrevoli
                t.stileFinitureStandardSiScorrevoli = {
                    minWidth: "130%",
                    marginLeft: "-2.7em",
                    marginTop: "-0.5em",
                };
            } else if (value > 768 && value <= 1050) {
                //tablet (1024)
                //img prodotto NO maniglioni / alzanti
                t.stileImgProdottoNoManiglioneAlzante = {
                    minWidth: "371px",
                    height: "auto",
                    cursor: "pointer",
                    backgroundColor: "white",
                    marginLeft: "-2em",
                    marginTop: "3.3em",
                    marginBottom: "6.3em",
                };
                //img prodotto SI maniglioni / alzanti
                t.stileImgProdottoSiManiglioneAlzante = {
                    height: "0px",
                    minWidth: "auto",
                    cursor: "pointer",
                    backgroundColor: "white",
                    marginLeft: "-2em",
                    marginTop: "0em",
                };
                //stile riga prodotti
                t.stileRowProdotti = {
                    marginLeft: "2em",
                    marginRight: "2em",
                    marginTop: "0em",
                    width: "100%",
                    marginBottom: "0em",
                };
                //stile icon prodotti
                t.stileIconProdotti = {
                    width: "25px",
                    height: "25px",
                    marginTop: "0em",
                    marginLeft: "0em",
                };
                //stile Img fintire (standard / pvd / retrò)
                t.stileColFinitureImg = {
                    minWidth: "20%",
                    marginTop: "2%",
                };
                //stile extation panel finiture
                t.stileExtationPanel = {
                    width: "90%",
                    height: "0%",
                    marginTop: "-1em",
                };
                //stile card prodotti
                t.stileCardProdotti = {
                    marginBottom: "10em",
                    marginLeft: "0em",
                    height: "auto",
                };
                //stile img SI maniglioni / alzanti
                t.stileImgProdottoSiManiglioneAlzante.height = "385px";
                //stile paragrafo NO maniglioni / alzanti
                t.stileParagrafoNoManiglioneAlzante = {
                    marginTop: "0em",
                    width: "300px",
                };
                //stile paragrafo SI maniglioni / alzanti
                t.stileParagrafoSiManiglioneAlzante = {
                    marginTop: "-2.2em",
                    marginLeft: "0em",
                    width: "300px",
                };
                //paragrafo extation panel NO maniglioni / alzanti
                t.stilePanelNoManiglioneAlzante = {
                    marginTop: "6em",
                    marginLeft: "-4.1em",
                    fontSize: "11px",
                };
                //paragrafo extation panel SI maniglioni / alzanti
                t.stilePanelSiManiglioneAlzante = {
                    marginTop: "6em",
                    marginLeft: "-2.3em",
                    fontSize: "11px",
                };
                //linea NO maniglioni / alzanti
                t.stileLineaNoManiglioneAlzante = {
                    marginTop: "-1.5em",
                    marginLeft: "0em",
                    fontSize: "12px",
                };
                //linea SI maniglioni / alzanti
                t.stileLineaSiManiglioneAlzante = {
                    marginTop: "-1.5em",
                    marginLeft: "0em",
                    fontSize: "12px",
                };
                //finiture standard NO maniglioni / alzanti
                t.stileFinitureStandardNoManiglioneAlzante = {
                    minWidth: "100%",
                    marginLeft: "-2.8em",
                    marginTop: "0em",
                };
                //finiture standard SI maniglioni / alzanti
                t.stileFinitureStandardSiManiglioneAlzante = {
                    width: "120%",
                    marginLeft: "-1.7em",
                    marginTop: "0em",
                };
                //finiture standard scorrevoli
                t.stileFinitureStandardSiScorrevoli = {
                    minWidth: "100%",
                    marginLeft: "-3em",
                    marginTop: "0.3em",
                };
            } else if (value > 1050 && value <= 1280) {
                //HD (16:9 / 16:10)
                //img prodotto NO maniglioni / alzanti
                t.stileImgProdottoNoManiglioneAlzante = {
                    minWidth: "371px",
                    height: "auto",
                    cursor: "pointer",
                    backgroundColor: "white",
                    marginLeft: "-2em",
                    marginTop: "3.3em",
                    marginBottom: "6.3em",
                };
                //img prodotto SI maniglioni / alzanti
                t.stileImgProdottoSiManiglioneAlzante = {
                    height: "0px",
                    minWidth: "auto",
                    cursor: "pointer",
                    backgroundColor: "white",
                    marginLeft: "-2em",
                    marginTop: "0em",
                };
                //stile riga prodotti
                t.stileRowProdotti = {
                    marginLeft: "2em",
                    marginRight: "2em",
                    marginTop: "0em",
                    width: "100%",
                    marginBottom: "1em",
                };
                //stile icon prodotti
                t.stileIconProdotti = {
                    width: "25px",
                    height: "25px",
                    marginTop: "0em",
                    marginLeft: "0em",
                };
                //stile Img fintire (standard / pvd / retrò)
                t.stileColFinitureImg = {
                    minWidth: "20%",
                    marginTop: "2%",
                };
                //stile extation panel finiture
                t.stileExtationPanel = {
                    width: "90%",
                    height: "0%",
                    marginTop: "-1em",
                };
                //stile card prodotti
                t.stileCardProdotti = {
                    marginBottom: "10em",
                    marginLeft: "0em",
                    height: "auto",
                };
                //stile img SI maniglioni / alzanti
                t.stileImgProdottoSiManiglioneAlzante.height = "385px";
                //stile paragrafo NO maniglioni / alzanti
                t.stileParagrafoNoManiglioneAlzante = {
                    marginTop: "0em",
                    width: "300px",
                };
                //stile paragrafo SI maniglioni / alzanti
                t.stileParagrafoSiManiglioneAlzante = {
                    marginTop: "-2.2em",
                    marginLeft: "0em",
                    width: "300px",
                };
                //paragrafo extation panel NO maniglioni / alzanti
                t.stilePanelNoManiglioneAlzante = {
                    marginTop: "6em",
                    marginLeft: "-4.1em",
                    fontSize: "11px",
                };
                //paragrafo extation panel SI maniglioni / alzanti
                t.stilePanelSiManiglioneAlzante = {
                    marginTop: "6em",
                    marginLeft: "-2.3em",
                    fontSize: "11px",
                };
                //linea NO maniglioni / alzanti
                t.stileLineaNoManiglioneAlzante = {
                    marginTop: "-1.5em",
                    marginLeft: "0em",
                    fontSize: "12px",
                };
                //linea SI maniglioni / alzanti
                t.stileLineaSiManiglioneAlzante = {
                    marginTop: "-1.5em",
                    marginLeft: "0em",
                };
                //finiture standard NO maniglioni / alzanti
                t.stileFinitureStandardNoManiglioneAlzante = {
                    minWidth: "100%",
                    marginLeft: "-2.8em",
                    marginTop: "0em",
                };
                //finiture standard SI maniglioni / alzanti
                t.stileFinitureStandardSiManiglioneAlzante = {
                    width: "120%",
                    marginLeft: "-1.7em",
                    marginTop: "0em",
                };
                //finiture standard scorrevoli
                t.stileFinitureStandardSiScorrevoli = {
                    minWidth: "100%",
                    marginLeft: "-3em",
                    marginTop: "0.3em",
                };
            } else if (value > 1280 && value <= 1540) {
                //tablet apple / surface (windows)
                //img prodotto NO maniglioni / alzanti
                t.stileImgProdottoNoManiglioneAlzante = {
                    minWidth: "371px",
                    height: "auto",
                    cursor: "pointer",
                    backgroundColor: "white",
                    marginLeft: "-2em",
                    marginTop: "3.3em",
                    marginBottom: "6.3em",
                };
                //img prodotto SI maniglioni / alzanti
                t.stileImgProdottoSiManiglioneAlzante = {
                    height: "0px",
                    minWidth: "auto",
                    cursor: "pointer",
                    backgroundColor: "white",
                    marginLeft: "-2em",
                    marginTop: "0em",
                };
                //stile riga prodotti
                t.stileRowProdotti = {
                    marginLeft: "2em",
                    marginRight: "2em",
                    marginTop: "0em",
                    width: "100%",
                    marginBottom: "0em",
                };
                //stile icon prodotti
                t.stileIconProdotti = {
                    width: "25px",
                    height: "25px",
                    marginTop: "0em",
                    marginLeft: "0em",
                };
                //stile Img fintire (standard / pvd / retrò)
                t.stileColFinitureImg = {
                    minWidth: "20%",
                    marginTop: "2%",
                };
                //stile extation panel finiture
                t.stileExtationPanel = {
                    width: "90%",
                    height: "0%",
                    marginTop: "-1em",
                };
                //stile card prodotti
                t.stileCardProdotti = {
                    marginBottom: "10em",
                    marginLeft: "0em",
                    height: "auto",
                };
                //stile img SI maniglioni / alzanti
                t.stileImgProdottoSiManiglioneAlzante.height = "385px";
                //stile paragrafo NO maniglioni / alzanti
                t.stileParagrafoNoManiglioneAlzante = {
                    marginTop: "0em",
                    width: "300px",
                };
                //stile paragrafo SI maniglioni / alzanti
                t.stileParagrafoSiManiglioneAlzante = {
                    marginLeft: "-1em",
                    marginTop: "-2.2em",
                    width: "300px",
                };
                //paragrafo extation panel NO maniglioni / alzanti
                t.stilePanelNoManiglioneAlzante = {
                    marginTop: "6em",
                    marginLeft: "-4.1em",
                    fontSize: "11px",
                };
                //paragrafo extation panel SI maniglioni / alzanti
                t.stilePanelSiManiglioneAlzante = {
                    marginTop: "6em",
                    marginLeft: "-4.1em",
                    fontSize: "11px",
                };
                //linea NO maniglioni / alzanti
                t.stileLineaNoManiglioneAlzante = {
                    marginTop: "-1.5em",
                    marginLeft: "0em",
                    fontSize: "12px",
                };
                //linea SI maniglioni / alzanti
                t.stileLineaSiManiglioneAlzante = {
                    marginTop: "-1.5em",
                    marginLeft: "-1.6em",
                    fontSize: "12px",
                };
                //finiture standard NO maniglioni / alzanti
                t.stileFinitureStandardNoManiglioneAlzante = {
                    minWidth: "100%",
                    marginLeft: "-2.8em",
                    marginTop: "0em",
                };
                //finiture standard SI maniglioni / alzanti
                t.stileFinitureStandardSiManiglioneAlzante = {
                    width: "120%",
                    marginLeft: "-2.8em",
                    marginTop: "0em",
                };
                //finiture standard scorrevoli
                t.stileFinitureStandardSiScorrevoli = {
                    minWidth: "100%",
                    marginLeft: "-3em",
                    marginTop: "0.3em",
                };
            } else if (value > 1540 && value <= 1920) {
                //classic pc FULL HD 16:9 / 	16:10 --> 1680
                //img prodotto NO maniglioni / alzanti
                t.stileImgProdottoNoManiglioneAlzante = {
                    minWidth: "371px",
                    height: "auto",
                    cursor: "pointer",
                    backgroundColor: "white",
                    marginLeft: "-2em",
                    marginTop: "3.3em",
                    marginBottom: "6.3em",
                };
                //img prodotto SI maniglioni / alzanti
                t.stileImgProdottoSiManiglioneAlzante = {
                    height: "0px",
                    minWidth: "auto",
                    cursor: "pointer",
                    backgroundColor: "white",
                    marginLeft: "-2em",
                    marginTop: "1em",
                };
                //stile riga prodotti
                t.stileRowProdotti = {
                    marginLeft: "3em",
                    marginRight: "2em",
                    marginTop: "0em",
                    width: "100%",
                    marginBottom: "0em",
                };
                //stile icon prodotti
                t.stileIconProdotti = {
                    width: "30px",
                    height: "30px",
                    marginTop: "0em",
                    marginLeft: "0em",
                };
                //stile Img fintire (standard / pvd / retrò)
                t.stileColFinitureImg = {
                    minWidth: "20%",
                    marginTop: "2%",
                };
                //stile extation panel finiture
                t.stileExtationPanel = {
                    width: "90%",
                    height: "0%",
                    marginTop: "-1em",
                };
                //stile card prodotti
                t.stileCardProdotti = {
                    marginBottom: "10em",
                    marginLeft: "0em",
                    height: "auto",
                };
                //stile img SI maniglioni / alzanti
                t.stileImgProdottoSiManiglioneAlzante.height = "385px";
                //stile paragrafo NO maniglioni / alzanti
                t.stileParagrafoNoManiglioneAlzante = {
                    marginTop: "0em",
                    width: "300px",
                };
                //stile paragrafo SI maniglioni / alzanti
                t.stileParagrafoSiManiglioneAlzante = {
                    marginTop: "-3em",
                    width: "300px",
                    marginLeft: "-1em",
                };
                //paragrafo extation panel NO maniglioni / alzanti
                t.stilePanelNoManiglioneAlzante = {
                    marginTop: "6em",
                    marginLeft: "-4.1em",
                    fontSize: "11px",
                };
                //paragrafo extation panel SI maniglioni / alzanti
                t.stilePanelSiManiglioneAlzante = {
                    marginTop: "6em",
                    marginLeft: "-4.1em",
                    fontSize: "11px",
                };
                //linea NO maniglioni / alzanti
                t.stileLineaNoManiglioneAlzante = {
                    marginTop: "-1.5em",
                    marginLeft: "0em",
                    fontSize: "12px",
                };
                //linea SI maniglioni / alzanti
                t.stileLineaSiManiglioneAlzante = {
                    marginTop: "-1.5em",
                    marginLeft: "-1.6em",
                    fontSize: "12px",
                };
                //finiture standard NO maniglioni / alzanti
                t.stileFinitureStandardNoManiglioneAlzante = {
                    minWidth: "100%",
                    marginLeft: "-2.8em",
                    marginTop: "0em",
                };
                //finiture standard SI maniglioni / alzanti
                t.stileFinitureStandardSiManiglioneAlzante = {
                    width: "120%",
                    marginLeft: "-2.8em",
                    marginTop: "0em",
                };
                //finiture standard scorrevoli
                t.stileFinitureStandardSiScorrevoli = {
                    minWidth: "100%",
                    marginLeft: "-3em",
                    marginTop: "0.3em",
                };
            } else if (value > 1920 && value <= 2100) {
                //classic pc (16:10 / 16:9 / 21:9)
            } else if (value > 2100 && value <= 2560) {
                //pc monitor (16:9)
                //img prodotto NO maniglioni / alzanti
                t.stileImgProdottoNoManiglioneAlzante = {
                    minWidth: "500px",
                    height: "auto",
                    cursor: "pointer",
                    backgroundColor: "white",
                    marginLeft: "0em",
                    marginTop: "9.5em",
                    marginBottom: "0em",
                };
                //img prodotto SI maniglioni / alzanti
                t.stileImgProdottoSiManiglioneAlzante = {
                    height: "650px",
                    minWidth: "auto",
                    cursor: "pointer",
                    backgroundColor: "white",
                    marginLeft: "-1em",
                    marginTop: "-5em",
                };
                //stile riga prodotti
                t.stileRowProdotti = {
                    marginLeft: "7em",
                    marginRight: "0em",
                    marginTop: "0em",
                    width: "95%",
                    marginBottom: "25em",
                };
                //stile card prodotti
                t.stileCardProdotti = {
                    marginBottom: "10em",
                    marginLeft: "5em",
                    height: "10em",
                };
                //stile paragrafo NO maniglioni / alzanti
                t.stileParagrafoNoManiglioneAlzante = {
                    marginTop: "4.5em",
                    marginLeft: "2em",
                    width: "390px",
                };
                //stile paragrafo SI maniglioni / alzanti
                t.stileParagrafoSiManiglioneAlzante = {
                    marginTop: "-3em",
                    marginLeft: "0em",
                    marginBottom: "1em",
                    width: "390px",
                };
                //stile icon prodotti
                t.stileIconProdotti = {
                    width: "30",
                    height: "30px",
                    marginTop: "0em",
                    marginLeft: "0em",
                };
                //linea NO maniglioni / alzanti
                t.stileLineaNoManiglioneAlzante = {
                    marginTop: "-1.5em",
                    marginLeft: "3.3em",
                    fontSize: "12px",
                };
                //linea SI maniglioni / alzanti
                t.stileLineaSiManiglioneAlzante = {
                    marginTop: "-1.5em",
                    marginLeft: "0em",
                    fontSize: "12px",
                };
                //paragrafo extation panel NO maniglioni / alzanti
                t.stilePanelNoManiglioneAlzante = {
                    marginTop: "32em",
                    marginLeft: "-6.5em",
                    fontSize: "11px",
                };
                //paragrafo extation panel SI maniglioni / alzanti
                t.stilePanelSiManiglioneAlzante = {
                    marginTop: "32em",
                    marginLeft: "-4em",
                    fontSize: "11px",
                };
                //stile extation panel finiture
                t.stileExtationPanel = {
                    width: "100%",
                    height: "0%",
                    marginTop: "-26em",
                };
                //stile Img fintire (standard / pvd / retrò)
                t.stileColFinitureImg = {
                    minWidth: "20%",
                    marginTop: "2%",
                };
                //finiture standard NO maniglioni / alzanti
                t.stileFinitureStandardNoManiglioneAlzante = {
                    minWidth: "100%",
                    marginLeft: "-4.5em",
                    marginTop: "0em",
                };
                //finiture standard SI maniglioni / alzanti
                t.stileFinitureStandardSiManiglioneAlzante = {
                    width: "120%",
                    marginLeft: "-2.8em",
                    marginTop: "0em",
                };
                //finiture standard scorrevoli
                t.stileFinitureStandardSiScorrevoli = {
                    minWidth: "100%",
                    marginLeft: "-4.5em",
                    marginTop: "0.3em",
                };
            } else if (value > 2560 && value <= 3840) {
                //TV 4K
                //img prodotto NO maniglioni / alzanti
                t.stileImgProdottoNoManiglioneAlzante = {
                    minWidth: "650px",
                    height: "auto",
                    cursor: "pointer",
                    backgroundColor: "white",
                    marginLeft: "0em",
                    marginTop: "15em",
                    marginBottom: "0em",
                };
                //img prodotto SI maniglioni / alzanti
                t.stileImgProdottoSiManiglioneAlzante = {
                    height: "750px",
                    minWidth: "auto",
                    cursor: "pointer",
                    backgroundColor: "white",
                    marginLeft: "-1em",
                    marginTop: "-5em",
                };
                //stile riga prodotti
                t.stileRowProdotti = {
                    marginLeft: "10em",
                    marginRight: "0em",
                    marginTop: "0em",
                    width: "95%",
                    marginBottom: "38em",
                };
                //stile card prodotti
                t.stileCardProdotti = {
                    marginBottom: "10em",
                    marginLeft: "15em",
                    height: "10em",
                };
                //stile paragrafo NO maniglioni / alzanti
                t.stileParagrafoNoManiglioneAlzante = {
                    marginTop: "1em",
                    marginLeft: "1.5em",
                    width: "500px",
                };
                //stile paragrafo SI maniglioni / alzanti
                t.stileParagrafoSiManiglioneAlzante = {
                    marginTop: "-1em",
                    marginLeft: "0em",
                    marginBottom: "1em",
                    width: "500px",
                };
                //stile icon prodotto
                t.stileIconProdotti = {
                    width: "40",
                    height: "40px",
                    marginTop: "0em",
                    marginLeft: "0em",
                };
                //linea NO maniglioni / alzanti
                t.stileLineaNoManiglioneAlzante = {
                    marginTop: "-1.5em",
                    marginLeft: "2.9em",
                    fontSize: "18px",
                };
                //linea SI maniglioni / alzanti
                t.stileLineaSiManiglioneAlzante = {
                    marginTop: "-1.5em",
                    marginLeft: "0em",
                    fontSize: "18px",
                };
                //stile extation panel finiture
                t.stileExtationPanel = {
                    width: "125%",
                    height: "0%",
                    marginTop: "-37em",
                };
                //paragrafo extation panel NO maniglioni / alzanti
                t.stilePanelNoManiglioneAlzante = {
                    marginTop: "32em",
                    marginLeft: "-6em",
                    fontSize: "16px",
                };
                //paragrafo extation panel SI maniglioni / alzanti
                t.stilePanelSiManiglioneAlzante = {
                    marginTop: "32em",
                    marginLeft: "-6.2em",
                    fontSize: "16px",
                };
                //stile Img fintire (standard / pvd / retrò)
                t.stileColFinitureImg = {
                    minWidth: "20%",
                    marginTop: "2%",
                };
                //finiture standard NO maniglioni / alzanti
                t.stileFinitureStandardNoManiglioneAlzante = {
                    minWidth: "130%",
                    marginLeft: "-6.5em",
                    marginTop: "0em",
                };
                //finiture standard SI maniglioni / alzanti
                t.stileFinitureStandardSiManiglioneAlzante = {
                    width: "130%",
                    marginLeft: "-6em",
                    marginTop: "0em",
                };
                //finiture standard scorrevoli
                t.stileFinitureStandardSiScorrevoli = {
                    minWidth: "150%",
                    marginLeft: "-8.5em",
                    marginTop: "0.3em",
                };
            }
        },

        //PAGE RICERCA
        getStiliPageRicerca(t, value) {
            //controll dimension display devices
            //fare proporzione tra una dimensione e l'altra per scalare tutti gli stili //2340 --> 4K = +50%
            t.stileRowProdotti = {}; //riga prodotti
            t.stileIconProdotti = {}; // icons prodotti
            t.stileColFinitureImg = {}; //colonna finiture
            t.stileExtationPanel = {}; //extation panel finiture
            t.stileCardProdotti = {}; // card prodotti
            t.stileImgProdottoNoManiglioneAlzante = {}; //img prodotti NO maniglioni / alzanti
            t.stileImgProdottoSiManiglioneAlzante = {}; //img prodotti SI maniglioni / alzanti
            t.stileParagrafoNoManiglioneAlzante = {}; //paragrafo NO maniglioni / alzanti
            t.stileParagrafoSiManiglioneAlzante = {}; //paragrafo SI maniglioni / alzanti
            t.stileLineaNoManiglioneAlzante = {}; //linea NO maniglioni / alzanti
            t.stileLineaSiManiglioneAlzante = {}; //linea SI maniglioni / alzanti
            t.stilePanelNoManiglioneAlzante = {}; //paragrafo extation panel NO maniglioni / alzanti
            t.stilePanelSiManiglioneAlzante = {}; //paragrafo extation panel SI maniglioni / alzanti
            t.stileFinitureStandardNoManiglioneAlzante = {}; //finiture standard NO maniglioni / alzanti
            t.stileFinitureStandardSiManiglioneAlzante = {}; //finiture standard SI maniglioni / alzanti
            t.stileFinitureStandardSiScorrevoli = {}; //finiture standard scorrevoli

            if (value <= 768) {
                //smartphone
                //img prodotto NO maniglioni / alzanti
                t.stileImgProdottoNoManiglioneAlzante = {
                    minWidth: "371px",
                    height: "auto",
                    cursor: "pointer",
                    backgroundColor: "white",
                    marginLeft: "-2em",
                    marginTop: "3.3em",
                    marginBottom: "6.3em",
                };
                //img prodotto SI maniglioni / alzanti
                t.stileImgProdottoSiManiglioneAlzante = {
                    height: "0px",
                    minWidth: "auto",
                    cursor: "pointer",
                    backgroundColor: "white",
                    marginLeft: "-2em",
                    marginTop: "0em",
                };
                //stile riga prodotti
                t.stileRowProdotti = {
                    marginTop: "-3em",
                    marginLeft: "0em",
                    marginRight: "0em",
                    width: "100%",
                    marginBottom: "-5em",
                };
                //stile icon prodotti
                t.stileIconProdotti = {
                    width: "25px",
                    height: "25px",
                    marginTop: "0em",
                    marginLeft: "0em",
                };
                //stile Col Img fintire (standard / pvd / retrò)
                t.stileColFinitureImg = {
                    minWidth: "20%",
                    marginTop: "5%",
                };
                //stile extation panel finiture
                t.stileExtationPanel = {
                    width: "87%",
                    height: "0%",
                    marginTop: "-2em",
                };
                //stile card prodotti
                t.stileCardProdotti = {
                    marginBottom: "15em",
                    marginLeft: "0em",
                    height: "auto",
                };
                //stile img SI maniglioni / alzanti
                t.stileImgProdottoSiManiglioneAlzante.height = "500px";
                //stile paragrafo NO maniglioni / alzanti
                t.stileParagrafoNoManiglioneAlzante = {
                    marginTop: "-2em",
                    width: "300px",
                };
                //stile paragrafo SI maniglioni / alzanti
                t.stileParagrafoSiManiglioneAlzante = {
                    marginLeft: "-0.9em",
                    marginTop: "-2.2em",
                    width: "300px",
                };
                //paragrafo extation panel NO maniglioni / alzanti
                t.stilePanelNoManiglioneAlzante = {
                    marginTop: "0.5em",
                    marginLeft: "-4.5em",
                    fontSize: "11px",
                };
                //paragrafo extation panel SI maniglioni / alzanti
                t.stilePanelSiManiglioneAlzante = {
                    marginTop: "6.5em",
                    marginLeft: "-4.5em",
                    fontSize: "11px",
                };
                //linea NO maniglioni / alzanti
                t.stileLineaNoManiglioneAlzante = {
                    marginTop: "-1.5em",
                    marginLeft: "0em",
                    fontSize: "12px",
                };
                //linea SI maniglioni / alzanti
                t.stileLineaSiManiglioneAlzante = {
                    marginTop: "-1.5em",
                    marginLeft: "-1.5em",
                    fontSize: "12px",
                };
                //finiture standard NO maniglioni / alzanti
                t.stileFinitureStandardNoManiglioneAlzante = {
                    minWidth: "120%",
                    marginLeft: "-3.3em",
                    marginTop: "0em",
                };
                //finiture standard SI maniglioni / alzanti
                t.stileFinitureStandardSiManiglioneAlzante = {
                    width: "120%",
                    marginLeft: "-3.3em",
                    marginTop: "0em",
                };
                //finiture standard scorrevoli
                t.stileFinitureStandardSiScorrevoli = {
                    minWidth: "130%",
                    marginLeft: "-2.7em",
                    marginTop: "-0.5em",
                };
            } else if (value > 768 && value <= 1050) {
                //tablet (1024)
                //img prodotto NO maniglioni / alzanti
                t.stileImgProdottoNoManiglioneAlzante = {
                    minWidth: "371px",
                    height: "auto",
                    cursor: "pointer",
                    backgroundColor: "white",
                    marginLeft: "-2em",
                    marginTop: "3.3em",
                    marginBottom: "6.3em",
                };
                //img prodotto SI maniglioni / alzanti
                t.stileImgProdottoSiManiglioneAlzante = {
                    height: "0px",
                    minWidth: "auto",
                    cursor: "pointer",
                    backgroundColor: "white",
                    marginLeft: "-2em",
                    marginTop: "0em",
                };
                //stile riga prodotti
                t.stileRowProdotti = {
                    marginLeft: "2em",
                    marginRight: "2em",
                    marginTop: "-3em",
                    width: "100%",
                    marginBottom: "-7em",
                };
                //stile icon prodotti
                t.stileIconProdotti = {
                    width: "25px",
                    height: "25px",
                    marginTop: "0em",
                    marginLeft: "0em",
                };
                //stile Img fintire (standard / pvd / retrò)
                t.stileColFinitureImg = {
                    minWidth: "20%",
                    marginTop: "2%",
                };
                //stile extation panel finiture
                t.stileExtationPanel = {
                    width: "90%",
                    height: "0%",
                    marginTop: "-1em",
                };
                //stile card prodotti
                t.stileCardProdotti = {
                    marginBottom: "10em",
                    marginLeft: "0em",
                    height: "auto",
                };
                //stile img SI maniglioni / alzanti
                t.stileImgProdottoSiManiglioneAlzante.height = "385px";
                //stile paragrafo NO maniglioni / alzanti
                t.stileParagrafoNoManiglioneAlzante = {
                    marginTop: "0em",
                    width: "300px",
                };
                //stile paragrafo SI maniglioni / alzanti
                t.stileParagrafoSiManiglioneAlzante = {
                    marginTop: "-2.2em",
                    marginLeft: "0em",
                    width: "300px",
                };
                //paragrafo extation panel NO maniglioni / alzanti
                t.stilePanelNoManiglioneAlzante = {
                    marginTop: "0.5em",
                    marginLeft: "-4.1em",
                    fontSize: "11px",
                };
                //paragrafo extation panel SI maniglioni / alzanti
                t.stilePanelSiManiglioneAlzante = {
                    marginTop: "0.5em",
                    marginLeft: "-2.5em",
                    fontSize: "11px",
                };
                //linea NO maniglioni / alzanti
                t.stileLineaNoManiglioneAlzante = {
                    marginTop: "-1.5em",
                    marginLeft: "0em",
                    fontSize: "12px",
                };
                //linea SI maniglioni / alzanti
                t.stileLineaSiManiglioneAlzante = {
                    marginTop: "-1.5em",
                    marginLeft: "0em",
                    fontSize: "12px",
                };
                //finiture standard NO maniglioni / alzanti
                t.stileFinitureStandardNoManiglioneAlzante = {
                    minWidth: "100%",
                    marginLeft: "-3em",
                    marginTop: "0em",
                };
                //finiture standard SI maniglioni / alzanti
                t.stileFinitureStandardSiManiglioneAlzante = {
                    width: "120%",
                    marginLeft: "-1.7em",
                    marginTop: "0em",
                };
                //finiture standard scorrevoli
                t.stileFinitureStandardSiScorrevoli = {
                    minWidth: "100%",
                    marginLeft: "-3em",
                    marginTop: "0.3em",
                };
            } else if (value > 1050 && value <= 1280) {
                //HD (16:9 / 16:10)
                //img prodotto NO maniglioni / alzanti
                t.stileImgProdottoNoManiglioneAlzante = {
                    minWidth: "371px",
                    height: "auto",
                    cursor: "pointer",
                    backgroundColor: "white",
                    marginLeft: "-2em",
                    marginTop: "3.3em",
                    marginBottom: "6.3em",
                };
                //img prodotto SI maniglioni / alzanti
                t.stileImgProdottoSiManiglioneAlzante = {
                    height: "0px",
                    minWidth: "auto",
                    cursor: "pointer",
                    backgroundColor: "white",
                    marginLeft: "-2em",
                    marginTop: "0em",
                };
                //stile riga prodotti
                t.stileRowProdotti = {
                    marginLeft: "2em",
                    marginRight: "2em",
                    marginTop: "-3em",
                    width: "100%",
                    marginBottom: "-6em",
                };
                //stile icon prodotti
                t.stileIconProdotti = {
                    width: "25px",
                    height: "25px",
                    marginTop: "0em",
                    marginLeft: "0em",
                };
                //stile Img fintire (standard / pvd / retrò)
                t.stileColFinitureImg = {
                    minWidth: "20%",
                    marginTop: "2%",
                };
                //stile extation panel finiture
                t.stileExtationPanel = {
                    width: "90%",
                    height: "0%",
                    marginTop: "-1em",
                };
                //stile card prodotti
                t.stileCardProdotti = {
                    marginBottom: "10em",
                    marginLeft: "0em",
                    height: "auto",
                };
                //stile img SI maniglioni / alzanti
                t.stileImgProdottoSiManiglioneAlzante.height = "385px";
                //stile paragrafo NO maniglioni / alzanti
                t.stileParagrafoNoManiglioneAlzante = {
                    marginTop: "0em",
                    width: "300px",
                };
                //stile paragrafo SI maniglioni / alzanti
                t.stileParagrafoSiManiglioneAlzante = {
                    marginTop: "-2.2em",
                    marginLeft: "0em",
                    width: "300px",
                };
                //paragrafo extation panel NO maniglioni / alzanti
                t.stilePanelNoManiglioneAlzante = {
                    marginTop: "0.5em",
                    marginLeft: "-4.1em",
                    fontSize: "11px",
                };
                //paragrafo extation panel SI maniglioni / alzanti
                t.stilePanelSiManiglioneAlzante = {
                    marginTop: "0.5em",
                    marginLeft: "-2.5em",
                    fontSize: "11px",
                };
                //linea NO maniglioni / alzanti
                t.stileLineaNoManiglioneAlzante = {
                    marginTop: "-1.5em",
                    marginLeft: "0em",
                    fontSize: "12px",
                };
                //linea SI maniglioni / alzanti
                t.stileLineaSiManiglioneAlzante = {
                    marginTop: "-1.5em",
                    marginLeft: "0em",
                    fontSize: "12px",
                };
                //finiture standard NO maniglioni / alzanti
                t.stileFinitureStandardNoManiglioneAlzante = {
                    minWidth: "100%",
                    marginLeft: "-3em",
                    marginTop: "0em",
                };
                //finiture standard SI maniglioni / alzanti
                t.stileFinitureStandardSiManiglioneAlzante = {
                    width: "120%",
                    marginLeft: "-1.7em",
                    marginTop: "0em",
                };
                //finiture standard scorrevoli
                t.stileFinitureStandardSiScorrevoli = {
                    minWidth: "100%",
                    marginLeft: "-3em",
                    marginTop: "0.3em",
                };
            } else if (value > 1280 && value <= 1540) {
                //tablet apple / surface (windows)
                //img prodotto NO maniglioni / alzanti
                t.stileImgProdottoNoManiglioneAlzante = {
                    minWidth: "371px",
                    height: "auto",
                    cursor: "pointer",
                    backgroundColor: "white",
                    marginLeft: "-2em",
                    marginTop: "3.3em",
                    marginBottom: "6.3em",
                };
                //img prodotto SI maniglioni / alzanti
                t.stileImgProdottoSiManiglioneAlzante = {
                    height: "0px",
                    minWidth: "auto",
                    cursor: "pointer",
                    backgroundColor: "white",
                    marginLeft: "-2em",
                    marginTop: "0em",
                };
                //stile riga prodotti
                t.stileRowProdotti = {
                    marginLeft: "1em",
                    marginRight: "2em",
                    marginTop: "-3em",
                    width: "100%",
                    marginBottom: "-6em",
                };
                //stile icon prodotti
                t.stileIconProdotti = {
                    width: "25px",
                    height: "25px",
                    marginTop: "0em",
                    marginLeft: "0em",
                };
                //stile Img fintire (standard / pvd / retrò)
                t.stileColFinitureImg = {
                    minWidth: "20%",
                    marginTop: "2%",
                };
                //stile extation panel finiture
                t.stileExtationPanel = {
                    width: "90%",
                    height: "0%",
                    marginTop: "-1em",
                };
                //stile card prodotti
                t.stileCardProdotti = {
                    marginBottom: "10em",
                    marginLeft: "0em",
                    height: "auto",
                };
                //stile img SI maniglioni / alzanti
                t.stileImgProdottoSiManiglioneAlzante.height = "385px";
                //stile paragrafo NO maniglioni / alzanti
                t.stileParagrafoNoManiglioneAlzante = {
                    marginTop: "0em",
                    width: "300px",
                };
                //stile paragrafo SI maniglioni / alzanti
                t.stileParagrafoSiManiglioneAlzante = {
                    marginLeft: "-1em",
                    marginTop: "-2.2em",
                    width: "300px",
                };
                //paragrafo extation panel NO maniglioni / alzanti
                t.stilePanelNoManiglioneAlzante = {
                    marginTop: "0.5em",
                    marginLeft: "-4.1em",
                    fontSize: "11px",
                };
                //paragrafo extation panel SI maniglioni / alzanti
                t.stilePanelSiManiglioneAlzante = {
                    marginTop: "0.5em",
                    marginLeft: "-4.1em",
                    fontSize: "11px",
                };
                //linea NO maniglioni / alzanti
                t.stileLineaNoManiglioneAlzante = {
                    marginTop: "-1.5em",
                    marginLeft: "0em",
                    fontSize: "12px",
                };
                //linea SI maniglioni / alzanti
                t.stileLineaSiManiglioneAlzante = {
                    marginTop: "-1.5em",
                    marginLeft: "-1.6em",
                    fontSize: "12px",
                };
                //finiture standard NO maniglioni / alzanti
                t.stileFinitureStandardNoManiglioneAlzante = {
                    minWidth: "100%",
                    marginLeft: "-2.8em",
                    marginTop: "0em",
                };
                //finiture standard SI maniglioni / alzanti
                t.stileFinitureStandardSiManiglioneAlzante = {
                    width: "120%",
                    marginLeft: "-2.8em",
                    marginTop: "0em",
                };
                //finiture standard scorrevoli
                t.stileFinitureStandardSiScorrevoli = {
                    minWidth: "100%",
                    marginLeft: "-3em",
                    marginTop: "0.3em",
                };
            } else if (value > 1540 && value <= 1920) {
                //classic pc FULL HD 16:9 / 	16:10 --> 1680
                //img prodotto NO maniglioni / alzanti
                t.stileImgProdottoNoManiglioneAlzante = {
                    minWidth: "371px",
                    height: "auto",
                    cursor: "pointer",
                    backgroundColor: "white",
                    marginLeft: "-2em",
                    marginTop: "3.3em",
                    marginBottom: "6.3em",
                };
                //img prodotto SI maniglioni / alzanti
                t.stileImgProdottoSiManiglioneAlzante = {
                    height: "0px",
                    minWidth: "auto",
                    cursor: "pointer",
                    backgroundColor: "white",
                    marginLeft: "-2em",
                    marginTop: "0em",
                };
                //stile riga prodotti
                t.stileRowProdotti = {
                    marginLeft: "2em",
                    marginRight: "2em",
                    marginTop: "-2.5em",
                    width: "100%",
                    marginBottom: "-6em",
                };
                //stile icon prodotti
                t.stileIconProdotti = {
                    width: "30px",
                    height: "30px",
                    marginTop: "0em",
                    marginLeft: "0em",
                };
                //stile Img fintire (standard / pvd / retrò)
                t.stileColFinitureImg = {
                    minWidth: "20%",
                    marginTop: "2%",
                };
                //stile extation panel finiture
                t.stileExtationPanel = {
                    width: "90%",
                    height: "0%",
                    marginTop: "-1em",
                };
                //stile card prodotti
                t.stileCardProdotti = {
                    marginBottom: "10em",
                    marginLeft: "0em",
                    height: "auto",
                };
                //stile img SI maniglioni / alzanti
                t.stileImgProdottoSiManiglioneAlzante.height = "385px";
                //stile paragrafo NO maniglioni / alzanti
                t.stileParagrafoNoManiglioneAlzante = {
                    marginTop: "0em",
                    width: "300px",
                };
                //stile paragrafo SI maniglioni / alzanti
                t.stileParagrafoSiManiglioneAlzante = {
                    marginTop: "-2.2em",
                    width: "300px",
                    marginLeft: "-1em",
                };
                //paragrafo extation panel NO maniglioni / alzanti
                t.stilePanelNoManiglioneAlzante = {
                    marginTop: "0.5em",
                    marginLeft: "-4.1em",
                    fontSize: "11px",
                };
                //paragrafo extation panel SI maniglioni / alzanti
                t.stilePanelSiManiglioneAlzante = {
                    marginTop: "0.5em",
                    marginLeft: "-4.1em",
                    fontSize: "11px",
                };
                //linea NO maniglioni / alzanti
                t.stileLineaNoManiglioneAlzante = {
                    marginTop: "-1.5em",
                    marginLeft: "0em",
                    fontSize: "12px",
                };
                //linea SI maniglioni / alzanti
                t.stileLineaSiManiglioneAlzante = {
                    marginTop: "-1.5em",
                    marginLeft: "-1.6em",
                    fontSize: "12px",
                };
                //finiture standard NO maniglioni / alzanti
                t.stileFinitureStandardNoManiglioneAlzante = {
                    minWidth: "100%",
                    marginLeft: "-2.8em",
                    marginTop: "0em",
                };
                //finiture standard SI maniglioni / alzanti
                t.stileFinitureStandardSiManiglioneAlzante = {
                    width: "120%",
                    marginLeft: "-2.8em",
                    marginTop: "0em",
                };
                //finiture standard scorrevoli
                t.stileFinitureStandardSiScorrevoli = {
                    minWidth: "100%",
                    marginLeft: "-3em",
                    marginTop: "0.3em",
                };
            } else if (value > 1920 && value <= 2100) {
                //classic pc (16:10 / 16:9 / 21:9)
            } else if (value > 2100 && value <= 2560) {
                //pc monitor (16:9)
                //img prodotto NO maniglioni / alzanti
                t.stileImgProdottoNoManiglioneAlzante = {
                    minWidth: "500px",
                    height: "auto",
                    cursor: "pointer",
                    backgroundColor: "white",
                    marginLeft: "0em",
                    marginTop: "9.5em",
                    marginBottom: "0em",
                };
                //img prodotto SI maniglioni / alzanti
                t.stileImgProdottoSiManiglioneAlzante = {
                    height: "650px",
                    minWidth: "auto",
                    cursor: "pointer",
                    backgroundColor: "white",
                    marginLeft: "-1em",
                    marginTop: "-5em",
                };
                //stile riga prodotti
                t.stileRowProdotti = {
                    marginLeft: "8em",
                    marginRight: "0em",
                    marginTop: "10em",
                    width: "95%",
                    marginBottom: "23em",
                };
                //stile card prodotti
                t.stileCardProdotti = {
                    marginBottom: "10em",
                    marginLeft: "5em",
                    height: "10em",
                };
                //stile paragrafo NO maniglioni / alzanti
                t.stileParagrafoNoManiglioneAlzante = {
                    marginTop: "4.5em",
                    marginLeft: "2em",
                    width: "390px",
                };
                //stile paragrafo SI maniglioni / alzanti
                t.stileParagrafoSiManiglioneAlzante = {
                    marginTop: "-3em",
                    marginLeft: "0em",
                    marginBottom: "1em",
                    width: "390px",
                };
                //stile icon prodotti
                t.stileIconProdotti = {
                    width: "30",
                    height: "30px",
                    marginTop: "0em",
                    marginLeft: "0em",
                };
                //linea NO maniglioni / alzanti
                t.stileLineaNoManiglioneAlzante = {
                    marginTop: "-1.5em",
                    marginLeft: "3.3em",
                    fontSize: "12px",
                };
                //linea SI maniglioni / alzanti
                t.stileLineaSiManiglioneAlzante = {
                    marginTop: "-1.5em",
                    marginLeft: "0em",
                    fontSize: "12px",
                };
                //paragrafo extation panel NO maniglioni / alzanti
                t.stilePanelNoManiglioneAlzante = {
                    marginTop: "28em",
                    marginLeft: "-6.5em",
                    fontSize: "11px",
                };
                //paragrafo extation panel SI maniglioni / alzanti
                t.stilePanelSiManiglioneAlzante = {
                    marginTop: "28.5em",
                    marginLeft: "-4em",
                    fontSize: "11px",
                };
                //stile extation panel finiture
                t.stileExtationPanel = {
                    width: "100%",
                    height: "0%",
                    marginTop: "-28em",
                };
                //stile Img fintire (standard / pvd / retrò)
                t.stileColFinitureImg = {
                    minWidth: "20%",
                    marginTop: "2%",
                };
                //finiture standard NO maniglioni / alzanti
                t.stileFinitureStandardNoManiglioneAlzante = {
                    minWidth: "100%",
                    marginLeft: "-4.5em",
                    marginTop: "0em",
                };
                //finiture standard SI maniglioni / alzanti
                t.stileFinitureStandardSiManiglioneAlzante = {
                    width: "120%",
                    marginLeft: "-2.8em",
                    marginTop: "0em",
                };
                //finiture standard scorrevoli
                t.stileFinitureStandardSiScorrevoli = {
                    minWidth: "100%",
                    marginLeft: "-4.5em",
                    marginTop: "0.3em",
                };
            } else if (value > 2560 && value <= 3840) {
                //TV 4K
                //img prodotto NO maniglioni / alzanti
                t.stileImgProdottoNoManiglioneAlzante = {
                    minWidth: "650px",
                    height: "auto",
                    cursor: "pointer",
                    backgroundColor: "white",
                    marginLeft: "0em",
                    marginTop: "15em",
                    marginBottom: "0em",
                };
                //img prodotto SI maniglioni / alzanti
                t.stileImgProdottoSiManiglioneAlzante = {
                    height: "750px",
                    minWidth: "auto",
                    cursor: "pointer",
                    backgroundColor: "white",
                    marginLeft: "-1em",
                    marginTop: "-5em",
                };
                //stile riga prodotti
                t.stileRowProdotti = {
                    marginLeft: "14em",
                    marginRight: "0em",
                    marginTop: "15em",
                    width: "95%",
                    marginBottom: "38em",
                };
                //stile card prodotti
                t.stileCardProdotti = {
                    marginBottom: "10em",
                    marginLeft: "15em",
                    height: "10em",
                };
                //stile paragrafo NO maniglioni / alzanti
                t.stileParagrafoNoManiglioneAlzante = {
                    marginTop: "1em",
                    marginLeft: "1.5em",
                    width: "500px",
                };
                //stile paragrafo SI maniglioni / alzanti
                t.stileParagrafoSiManiglioneAlzante = {
                    marginTop: "-1em",
                    marginLeft: "0em",
                    marginBottom: "1em",
                    width: "500px",
                };
                //stile icon prodotto
                t.stileIconProdotti = {
                    width: "40",
                    height: "40px",
                    marginTop: "0em",
                    marginLeft: "0em",
                };
                //linea NO maniglioni / alzanti
                t.stileLineaNoManiglioneAlzante = {
                    marginTop: "-1.5em",
                    marginLeft: "2.9em",
                    fontSize: "18px",
                };
                //linea SI maniglioni / alzanti
                t.stileLineaSiManiglioneAlzante = {
                    marginTop: "-1.5em",
                    marginLeft: "0em",
                    fontSize: "18px",
                };
                //stile extation panel finiture
                t.stileExtationPanel = {
                    width: "125%",
                    height: "0%",
                    marginTop: "-37em",
                };
                //paragrafo extation panel NO maniglioni / alzanti
                t.stilePanelNoManiglioneAlzante = {
                    marginTop: "28em",
                    marginLeft: "-6em",
                    fontSize: "16px",
                };
                //paragrafo extation panel SI maniglioni / alzanti
                t.stilePanelSiManiglioneAlzante = {
                    marginTop: "28.5em",
                    marginLeft: "-6.2em",
                    fontSize: "16px",
                };
                //stile Img fintire (standard / pvd / retrò)
                t.stileColFinitureImg = {
                    minWidth: "20%",
                    marginTop: "2%",
                };
                //finiture standard NO maniglioni / alzanti
                t.stileFinitureStandardNoManiglioneAlzante = {
                    minWidth: "130%",
                    marginLeft: "-6.5em",
                    marginTop: "0em",
                };
                //finiture standard SI maniglioni / alzanti
                t.stileFinitureStandardSiManiglioneAlzante = {
                    width: "130%",
                    marginLeft: "-6em",
                    marginTop: "0em",
                };
                //finiture standard scorrevoli
                t.stileFinitureStandardSiScorrevoli = {
                    minWidth: "150%",
                    marginLeft: "-8.5em",
                    marginTop: "0.3em",
                };
            }
        },
    },
}