// Debug 
const url_debug = 'http://localhost:16637';    // Localhost
const url_Debug = "https://www.battisti.cloud"   // Internet
//const url_debug = "http://192.168.90.204:2082"    // intranet
//const url_debug = "http://www.battisti.cloud:2082"    // internet Test

const GOOGLE_API_KEY = 'AIzaSyCjIpVF-K9a0LsbhCXnkeWMowWmP-8iFHQ';
const urlPrivacy = "https://http://192.168.90.204:2084//UserFile/privacy/privacy.pdf"//concordare dove prendere il file della privacy

export default {

  getUrl() {
    if (window.location.host.includes("localhost"))
      return url_debug

    return window.location.protocol + "//" + window.location.host
  },

  getUrlDettProd(){
    if (window.location.host.includes("localhost"))
      return "http://www.ecolineamaniglie.com"

    return window.location.protocol + "//" + window.location.host
  },

  getUrlApi() {

    let url = this.getUrl();

    if (url.includes("localhost"))
      url += "/api"
    else
      url += "/api/api"

    return url
  },

  /* getUrlPrivacy() {
    return urlPrivacy
  }, */

  getUrl_Update() {

    let url = this.getUrlApi();

    if (window.location.host.includes("localhost"))
      url = ""
    else
      url += "/app/update"

    return url
  },

  /*   getGoogleKey() {
      return GOOGLE_API_KEY
    } */
}