
const app = {
    namespaced: true,

    state: {
        selectedCliente: null,
        loading: false,
        message: '',
        prodotti: [],
        filtroProdotti: "",
        dettaglio: {},
        nomeMenu: {},
        nomeRicerca: {},
        listaRicerca: [],
        descrizioneProdotto: "",
        secondaDesrcizioneProdotto: "",
        dettaglioTipo: "",
        filtroMenu: "",
        filtriMenu: { nomeProdotto: "", nomeProdotto2: "", nomeProdotto3: "", text: "", url: "", finitura: "" },
        nomePagina: "",
        urlRicerca: "",
        boolPage: false,
        waitLoginAzienda: null,
        urlPage: null,
        scrollY: 0,
        finituraFiltro: "",
    },

    mutations: {
        setCliente: (state, data) => {
            state.selectedCliente = data
        },

        setLoading(state, vis) {
            state.loading = vis
        },

        showMessage(state, data) {
            state.message = data

        },

        setProdotti(state, data) {
            state.prodotti = data
        },

        setFiltroProdotti(state, data) {
            state.filtroProdotti = data
        },

        //dettaglio prodotto
        setDettaglio(state, data) {
            state.dettaglio = data
        },

        setNomeMenu(state, data) {
            state.nomeMenu = data
        },

        //per ricerca di un prodotto
        setNomeRicerca(state, data) {
            state.nomeRicerca = data
        },

        //memorizzo il risultato della ricerca
        setListaRicerca(state, data) {
            state.listaRicerca = data
        },

        //descrizione dettaglio prodotto
        setDescrizioneProdotto(state, data) {
            state.descrizioneProdotto = data
        },

        //seconda descrizione dettaglio prodotto
        setSecondaDesrcizioneProdotto(state, data) {
            state.sacondaDesrcizioneProdotto = data
        },

        //tipo dettaglio prodotto (Maniglioni - Alzanti)
        setDettaglioTipo(state, data) {
            state.dettaglioTipo = data
        },

        //filtro nome del Menu'
        setFiltroMenu(state, data) {
            state.filtroMenu = data
        },

        //oggetto con tutti i filtri
        setFiltriMenu(state, data) {
            state.filtriMenu = data
        },

        //nome pagina toolbar
        setNomePagina(state, data) {
            state.nomePagina = data
        },

        //dettaglio prodotto
        setUrlRicerca(state, data) {
            state.urlRicerca = data
        },

        //bool per il controllo dell'url pagina
        setBoolPage(state, data) {
            state.boolPage = data
        },

        setUrlPage(state, data) {
            state.urlPage = data;
        },

        setWaitLoginAzienda: (state, data) => {
            state.waitLoginAzienda = data
        },

        //controllare QUANTO l'utente scrolla la pagina
        setScrollY(state, data) {
            state.scrollY = data
        },

        //menu Finitura
        setFinituraFiltro: (state, data) => {
            state.finituraFiltro = data
        },
    },

    getters: {
        getCliente: state => state.selectedCliente,
        getDettaglio: state => { return state.dettaglio },
        getNomeMenu: state => { return state.nomeMenu },
        getNomeRicerca: state => { return state.nomeRicerca },
        getListaRicerca: state => { return state.listaRicerca },
        getDescrizioneProdotto: state => { return state.descrizioneProdotto },
        getSecondaDesrcizioneProdotto: state => { return state.secondaDesrcizioneProdotto },
        getDettaglioTipo: state => { return state.dettaglioTipo },
        getFiltroMenu: state => { return state.filtroMenu },
        getFiltriMenu: state => { return state.filtriMenu },
        getNomePagina: state => { return state.nomePagina },
        getUrlRicerca: state => { return state.urlRicerca },
        getBoolPage: state => { return state.boolPage },
        getUrlPage: state => state.urlPage,
        getWaitLoginAzienda: state => state.waitLoginAzienda,
        getScrollY: state => { return state.scrollY },
        getFinituraFiltro: state => { return state.finituraFiltro },
    }
}

export default app