<template>
  <div id="pagina" :style="isTelefono() > 1920 ? { 'margin-left': '10vh' } : {}">
    <!-- VISUALIZZAZIONE TELEFONO -->
    <v-row v-if="isTelefono() <= 768" class="d-flex child-flex mb-0">
      <!-- NOME PRODOTTO + IMG TIPO -->
      <v-col cols="12">
        <v-row>
          <v-col cols="9">
            <v-card-title
              style="font-size: 25px; margin-left: 0%; margin-right: 0%; margin-top: -1em"
              ><!-- v-on:click="DescrizioneProdotto()" -->
              {{ Url.NomeProdotto }}
              <!-- NOVITA', DISPONIBILITA', RESTYLING -->
              <icone-dettaglio-prodotto :Icone="Icone"></icone-dettaglio-prodotto>
            </v-card-title>
          </v-col>
          <v-spacer></v-spacer>
          <!-- ICONE PDF -->
          <v-col cols="3">
            <v-row style="margin-top: -1em">
              <icone @chiudi="chiudi" @vistaAngolare="vistaAngolare"> </icone>
            </v-row>
          </v-col>
        </v-row>
      </v-col>

      <!-- DESCRIZIONE PRODOTTO -->
      <v-col cols="12">
        <v-row class="ml-5">
          <span
            style="
              background-color: trasparent;
              position: absolute;
              font-size: 15px;
              max-width: 85%;
              margin-top: -1em;
            "
          >
            {{ descrizioneProdotto }}
          </span>
        </v-row>
      </v-col>

      <!-- FINITURE PRODOTTO -->
      <v-col class="mt-15" cols="12">
        <v-row class="ml-1 mt-1">
          <finiture-dettaglio-prodotto-telefono
            :Url="Url"
            :finitureStandard="finitureStandard"
            :finiturePvd="finiturePvd"
            :finitureRetro="finitureRetro"
            @getDettFiniture="getDettFiniture"
          ></finiture-dettaglio-prodotto-telefono>
        </v-row>
      </v-col>

      <!-- IMG PRODOTTO -->
      <img-prodotto-dettaglio-new
        :Url="Url"
        :urlImgProdotto="urlImgProdotto"
        :codiceComposizione="codiceComposizione"
        :secondaDescrizioneProdotto="secondaDescrizioneProdotto"
      ></img-prodotto-dettaglio-new>

      <!-- IMMAGINI COMPOSIZIONI -->
      <v-sheet
        v-if="Url.Tipo != 'Alzanti'"
        elevation="0"
        max-width="100%"
        style="margin-top: -5%; margin-left: -5%; margin-right: -5%"
      >
        <v-slide-group show-arrows v-model="composizioni">
          <v-slide-item class="pa-0" v-for="(item, i) in composizioni" :key="i">
            <v-img
              v-on:click="getDettComposizione(item)"
              style="
                width: 90px;
                height: 140px;
                margin-top: 0px;
                cursor: pointer;
                margin-top: 0px;
                padding: 0px;
                padding-bottom: 0px;
                padding-top: 0px;
                padding-left: 0px;
                padding-right: 0px;
              "
              :src="item.url"
              :lazy-src="item.url"
              aspect-ratio="1"
              class="ma-1"
            >
              <template v-slot:placeholder>
                <v-row class="fill-height ma-0" align="center" justify="center">
                  <v-progress-circular
                    indeterminate
                    color="black lighten-5"
                  ></v-progress-circular>
                </v-row>
              </template>
            </v-img>
          </v-slide-item>
        </v-slide-group>
      </v-sheet>
    </v-row>

    <!-- VISUALIZZAZIONE DA TABLET <= 1024 -->
    <v-row v-if="isTelefono() > 768 && isTelefono() <= 1050" style="margin-top: 0em">
      <!-- CONFIGURAZIONI -->
      <v-responsive
        class="overflow-x-auto"
        style="margin-top: 2%; margin-left: 0em"
        max-height="1000"
        max-width="250"
      >
        <h3 class="mb-0" style="margin-left: 2em">Configurazioni</h3>
        <scroll-bar-surface
          :Url="Url"
          :benched="benched"
          :datiComposizioni="datiComposizioni"
          @getDettComposizione="getDettComposizione"
        >
        </scroll-bar-surface>
      </v-responsive>

      <!-- IMMAGINE PRODOTTO -->
      <v-col cols="5" style="margin-top: -2%; margin-left: 2%">
        <img-prodotto-dettaglio-tablet
          :Url="Url"
          :urlImgProdotto="urlImgProdotto"
          :secondaDescrizioneProdotto="secondaDescrizioneProdotto"
          :codiceComposizione="codiceComposizione"
          :Icone="Icone"
        ></img-prodotto-dettaglio-tablet>
      </v-col>

      <!-- FINITURE PRODOTTO -->
      <v-col cols="3">
        <!-- DESCRIZIONE + NOME PRODOTTO -->
        <v-row style="margin-left: -70%; margin-top: 3%" class="d-flex child-flex">
          <!-- NOME PRODOTTO / DESCRIZIONE -->
          <v-row style="background-color: trasparent; position: absolute; margin-top: 0%">
            <span
              style="
                background-color: trasparent;
                position: absolute;
                font-size: 15px;
                width: 20em;
                margin-top: 4%;
              "
            >
              <h1>
                {{ Url.NomeProdotto }}
              </h1>
              {{ descrizioneProdotto }}
            </span>
          </v-row>
        </v-row>
        <v-row style="margin-left: -74%" class="d-flex child-flex">
          <v-col cols="8"> </v-col>
          <v-col cols="4">
            <!-- ICONE -->
            <div class="mt-5" style="margin-left: 50%">
              <icone-dettaglio-prodotto-tablet
                @chiudi="chiudi"
                @vistaAngolare="vistaAngolare"
              >
              </icone-dettaglio-prodotto-tablet>
            </div>
          </v-col>
        </v-row>

        <v-row style="margin-top: 100%; margin-left: -5%; min-width: 130%">
          <!-- FINITURE STANDARD - PVD - RETRO' -->
          <finiture-dettaglio-prodotto-tablet
            :Url="Url"
            :finitureStandard="finitureStandard"
            :finiturePvd="finiturePvd"
            :finitureRetro="finitureRetro"
            @getDettFiniture="getDettFiniture"
          >
          </finiture-dettaglio-prodotto-tablet>
        </v-row>
      </v-col>
    </v-row>

    <!-- VISUALIZZAZIONE DA SURFACE <= 1540 (1360x768) -->
    <v-row v-if="isTelefono() > 1050 && isTelefono() <= 1540" style="margin-top: -1em">
      <!-- CONFIGURAZIONI -->
      <v-responsive
        class="overflow-x-auto"
        style="margin-top: 2%; margin-left: 1em"
        max-height="1000"
        max-width="250"
      >
        <h3 class="mb-0" style="margin-left: 2em">Configurazioni</h3>
        <scroll-bar-surface
          :Url="Url"
          :benched="benched"
          :datiComposizioni="datiComposizioni"
          @getDettComposizione="getDettComposizione"
        >
        </scroll-bar-surface>
      </v-responsive>

      <!-- IMMAGINE PRODOTTO -->
      <v-col cols="5" style="margin-top: -2%; margin-left: 2%">
        <img-prodotto-dettaglio-surface
          :Url="Url"
          :urlImgProdotto="urlImgProdotto"
          :secondaDescrizioneProdotto="secondaDescrizioneProdotto"
          :codiceComposizione="codiceComposizione"
          :Icone="Icone"
        ></img-prodotto-dettaglio-surface>
      </v-col>

      <!-- FINITURE PRODOTTO -->
      <v-col cols="4">
        <!-- DESCRIZIONE + NOME PRODOTTO -->
        <v-row style="margin-left: -30%; margin-top: 3%" class="d-flex child-flex">
          <!-- NOME PRODOTTO / DESCRIZIONE -->
          <v-row style="background-color: trasparent; position: absolute; margin-top: 0%">
            <span
              style="
                background-color: trasparent;
                position: absolute;
                font-size: 15px;
                width: 25em;
                margin-top: 4%;
              "
            >
              <h1>
                {{ Url.NomeProdotto }}
              </h1>
              {{ descrizioneProdotto }}
            </span>
          </v-row>
        </v-row>
        <v-row style="margin-left: -33%" class="d-flex child-flex">
          <!-- DESCRIZIONE -->
          <v-col cols="10"> </v-col>
          <v-col cols="2">
            <!-- ICONE -->
            <div class="mt-6" style="margin-left: -70%">
              <icone @chiudi="chiudi" @vistaAngolare="vistaAngolare"> </icone>
            </div>
          </v-col>
        </v-row>

        <v-row
          :style="[
            isTelefono() == 1360
              ? { 'margin-top': '30%', 'margin-left': '0%' }
              : { 'margin-top': '35%', 'margin-left': '0%' },
          ]"
        >
          <!-- FINITURE STANDARD - PVD - RETRO' -->
          <finiture-dettaglio-prodotto-surface
            :Url="Url"
            :finitureStandard="finitureStandard"
            :finiturePvd="finiturePvd"
            :finitureRetro="finitureRetro"
            @getDettFiniture="getDettFiniture"
          >
          </finiture-dettaglio-prodotto-surface>
        </v-row>
      </v-col>
    </v-row>

    <!-- VISUALIZZAZIONE DA PC <= 1920 -->
    <v-row v-if="isTelefono() > 1540 && isTelefono() <= 1920" style="margin-top: 0em">
      <!-- CONFIGURAZIONI PRODOTTO -->
      <v-col cols="4">
        <v-responsive class="overflow-x-auto" style="margin-top: 5%" max-height="1000">
          <h3 class="mb-6" style="margin-left: 15%">Configurazioni</h3>
          <!-- SCROLL-BAR CONFIGURAZIONI TUTTI PRODOTTI -->
          <scroll-bar
            :Url="Url"
            :benched="benched"
            :datiComposizioni="datiComposizioni"
            @getDettComposizione="getDettComposizione"
          >
          </scroll-bar>
        </v-responsive>
      </v-col>
      <!-- IMMAGINE PRODOTTO -->
      <v-col cols="5" style="margin-top: 0em; margin-left: -2%">
        <dettaglio-prodotto
          :Url="Url"
          :urlImgProdotto="urlImgProdotto"
          :secondaDescrizioneProdotto="secondaDescrizioneProdotto"
          :codiceComposizione="codiceComposizione"
          :Icone="Icone"
        ></dettaglio-prodotto>
      </v-col>
      <!-- FINITURE PRODOTTO -->
      <v-col cols="3">
        <!-- DESCRIZIONE + NOME PRODOTTO -->
        <v-row style="margin-left: -60%; margin-top: 5%" class="d-flex child-flex">
          <!-- NOME PRODOTTO / DESCRIZIONE -->
          <v-row style="background-color: trasparent; position: absolute; margin-top: 0%">
            <span
              style="
                background-color: trasparent;
                position: absolute;
                font-size: 15px;
                width: 25em;
                margin-top: 3%;
              "
            >
              <h1>
                {{ Url.NomeProdotto }}
              </h1>
              {{ descrizioneProdotto }}
            </span>
            <!-- <v-btn
              class="ml-2 mt-3"
              icon
              small
              color="primary"
              dark
              v-on:click="esportaPdf()"
              ><v-icon>download</v-icon></v-btn
            > -->
          </v-row>
        </v-row>
        <v-row style="margin-left: -63%" class="d-flex child-flex">
          <v-col cols="10"> </v-col>
          <v-col cols="2">
            <!-- ICONE -->
            <div class="mt-7" style="margin-left: -50%">
              <icone @chiudi="chiudi" @vistaAngolare="vistaAngolare"> </icone>
            </div>
          </v-col>
        </v-row>

        <v-row style="margin-top: 55%; margin-left: -50%">
          <!-- FINITURE STANDARD - PVD - RETRO' -->
          <finiture-prodotto
            :Url="Url"
            :finitureStandard="finitureStandard"
            :finiturePvd="finiturePvd"
            :finitureRetro="finitureRetro"
            @getDettFiniture="getDettFiniture"
          >
          </finiture-prodotto>
        </v-row>
      </v-col>
    </v-row>

    <!-- VISUALIZZAZIONE DA MONITOR <= 2560 -->
    <v-row v-if="isTelefono() > 1920 && isTelefono() <= 2560" style="margin-top: 0em">
      <!-- CONFIGURAZIONI PRODOTTO -->
      <v-col cols="4">
        <v-responsive class="overflow-x-auto" style="margin-top: 10%" max-height="100%">
          <h1 class="mb-6" style="margin-left: 15%">Configurazioni</h1>
          <!-- SCROLL-BAR CONFIGURAZIONI TUTTI PRODOTTI -->
          <scroll-bar-monitor
            :Url="Url"
            :benched="benched"
            :datiComposizioni="datiComposizioni"
            @getDettComposizione="getDettComposizione"
          >
          </scroll-bar-monitor>
        </v-responsive>
      </v-col>
      <!-- IMMAGINE PRODOTTO -->
      <v-col cols="5" style="margin-top: 3%; margin-left: -3%">
        <img-prodotto-dettaglio-new-monitor
          :Url="Url"
          :urlImgProdotto="urlImgProdotto"
          :secondaDescrizioneProdotto="secondaDescrizioneProdotto"
          :codiceComposizione="codiceComposizione"
          :Icone="Icone"
        ></img-prodotto-dettaglio-new-monitor>
      </v-col>
      <!-- FINITURE PRODOTTO -->
      <v-col cols="3">
        <!-- DESCRIZIONE + NOME PRODOTTO -->
        <v-row style="margin-left: -60%; margin-top: 15%" class="d-flex child-flex">
          <!-- NOME PRODOTTO / DESCRIZIONE -->
          <v-row style="background-color: trasparent; position: absolute; margin-top: 0%">
            <span
              style="
                background-color: trasparent;
                position: absolute;
                font-size: 16px;
                width: 25em;
                margin-top: 2%;
              "
            >
              <h1>
                {{ Url.NomeProdotto }}
              </h1>
              {{ descrizioneProdotto }}
            </span>
          </v-row>
        </v-row>
        <v-row style="margin-left: -62%" class="d-flex child-flex">
          <v-col cols="10"> </v-col>
          <v-col cols="2">
            <!-- ICONE -->
            <div class="mt-7" style="margin-left: -50%">
              <icone @chiudi="chiudi" @vistaAngolare="vistaAngolare"> </icone>
            </div>
          </v-col>
        </v-row>

        <v-row style="margin-top: 55%; margin-left: -50%">
          <!-- FINITURE STANDARD - PVD - RETRO' -->
          <finiture-dettaglio-prodotto-monitor
            :Url="Url"
            :finitureStandard="finitureStandard"
            :finiturePvd="finiturePvd"
            :finitureRetro="finitureRetro"
            @getDettFiniture="getDettFiniture"
          >
          </finiture-dettaglio-prodotto-monitor>
        </v-row>
      </v-col>
    </v-row>

    <!-- VISUALIZZAZIONE DA TV > 2560 && <= 3840 -->
    <v-row v-if="isTelefono() > 2560 && isTelefono() <= 3840" style="margin-top: -3.5em">
      <!-- CONFIGURAZIONI PRODOTTO -->
      <v-col cols="4">
        <v-responsive class="overflow-x-auto" style="margin-top: 10%" max-height="1700">
          <h1 class="mb-6" style="margin-left: 15%">Configurazioni</h1>
          <!-- SCROLL-BAR CONFIGURAZIONI TUTTI PRODOTTI -->
          <scroll-bar-tv
            :Url="Url"
            :benched="benched"
            :datiComposizioni="datiComposizioni"
            @getDettComposizione="getDettComposizione"
          >
          </scroll-bar-tv>
        </v-responsive>
      </v-col>
      <!-- IMMAGINE PRODOTTO -->
      <v-col cols="5" style="margin-top: 5%">
        <img-prodotto-dettaglio-new-tv
          :Url="Url"
          :urlImgProdotto="urlImgProdotto"
          :secondaDescrizioneProdotto="secondaDescrizioneProdotto"
          :codiceComposizione="codiceComposizione"
          :Icone="Icone"
        ></img-prodotto-dettaglio-new-tv>
      </v-col>
      <!-- FINITURE PRODOTTO -->
      <v-col cols="3">
        <!-- NOME PRODOTTO -->
        <v-row style="margin-left: -60%; margin-top: 15%" class="d-flex child-flex">
          <!-- NOME PRODOTTO / DESCRIZIONE -->
          <v-row style="background-color: trasparent; position: absolute; margin-top: 0%">
            <span
              style="
                background-color: trasparent;
                position: absolute;
                font-size: 24px;
                width: 35em;
                margin-top: 2%;
              "
            >
              <h1 style="font-size: 40px">
                {{ Url.NomeProdotto }}
              </h1>
              {{ descrizioneProdotto }}
            </span>
          </v-row>
        </v-row>
        <v-row style="margin-left: -61%" class="d-flex child-flex">
          <v-col cols="10"> </v-col>
          <v-col cols="2">
            <!-- ICONE -->
            <div class="mt-7" style="margin-left: -50%">
              <icone @chiudi="chiudi" @vistaAngolare="vistaAngolare"> </icone>
            </div>
          </v-col>
        </v-row>

        <v-row style="margin-top: 50%; margin-left: -50%">
          <!-- FINITURE STANDARD - PVD - RETRO' -->
          <finiture-dettaglio-prodotto-tv
            :Url="Url"
            :finitureStandard="finitureStandard"
            :finiturePvd="finiturePvd"
            :finitureRetro="finitureRetro"
            @getDettFiniture="getDettFiniture"
          >
          </finiture-dettaglio-prodotto-tv>
        </v-row>
      </v-col>
    </v-row>

    <!-- VISTA PRODOTTO 360° -->
    <dialog-angolare :dialog="dialogAngolare" @chiudiDialog="vistaAngolare()">
    </dialog-angolare>

    <!-- SOTTO COMPOSIZIONI -->
    <img-prodotto
      :dialog="dialogProdotto"
      :src="src"
      :nomeDelProdotto="nomeDelProdotto"
      @chiudiDialog="imgProdottoDetttaglio(dettaglio)"
    >
    </img-prodotto>

    <!-- SCHEDA TECNICA -->
    <pdf-viewer
      :dialog="dialog"
      :src="src"
      :type="type"
      :nomeDelProdotto="nomeDelProdotto"
      @chiudi="chiudi()"
    >
    </pdf-viewer>
  </div>
</template>

<script>
import {
  get_finiture,
  get_composizione,
  get_dettComposizione,
  get_dettFinitura,
  get_downloadPdf,
  get_downloadImgComposizione,
  get_descrizione,
  get_icone,
} from "@/api/api.prodotti.js";

import PdfViewer from "@/components/Dialog/PdfViewer.vue";
import DialogAngolare from "@/components/Dialog/DialogAngolare.vue";
import ImgProdotto from "@/components/Dialog/ImgProdotto.vue";
import ScrollBar from "@/components/ScrollBarConfigurazioni/ScrollBar.vue";
import FinitureProdotto from "@/components/FinitureDettaglioProdotto/FinitureProdotto.vue";
import Icone from "@/components/IconeDettaglioProdotto/Icone.vue";
import DettaglioProdotto from "@/components/ImgProdottoDettaglio/ImgProdotto.vue";
import IconeDettaglioProdotto from "@/components/IconeDettaglioProdotto/IconeDettaglioProdotto.vue";
import FinitureDettaglioProdottoTelefono from "@/components/FinitureDettaglioProdotto/FinitureDettaglioProdottoTelefono.vue";
import ImgProdottoDettaglioNew from "@/components/ImgProdottoDettaglio/ImgProdottoDettaglioNew.vue";
import ScrollBarMonitor from "@/components/ScrollBarConfigurazioni/ScrollbarMonitor.vue";
import ImgProdottoDettaglioNewMonitor from "@/components/ImgProdottoDettaglio/ImgProdottoDettaglioNewMonitor.vue";
import FinitureDettaglioProdottoMonitor from "@/components/FinitureDettaglioProdotto/FinitureDettaglioProdottoMonitor.vue";
import ScrollBarSurface from "@/components/ScrollBarConfigurazioni/ScrollbarSurface.vue";
import ImgProdottoDettaglioSurface from "@/components/ImgProdottoDettaglio/ImgProdottoDettaglioSurface.vue";
import FinitureDettaglioProdottoSurface from "@/components/FinitureDettaglioProdotto/FinitureDettaglioProdottoSurface.vue";
import ImgProdottoDettaglioTablet from "@/components/ImgProdottoDettaglio/ImgProdottoDettaglioTablet.vue";
import IconeDettaglioProdottoTablet from "@/components/IconeDettaglioProdotto/IconeDettaglioProdottoTablet.vue";
import FinitureDettaglioProdottoTablet from "@/components/FinitureDettaglioProdotto/FinitureDettaglioProdottoTablet.vue";
import ScrollBarTv from "@/components/ScrollBarConfigurazioni/ScrollBarTv.vue";
import ImgProdottoDettaglioNewTv from "@/components/ImgProdottoDettaglio/ImgProdottoDettaglioNewTv.vue";
import FinitureDettaglioProdottoTv from "@/components/FinitureDettaglioProdotto/FinitureDettaglioProdottoTv.vue";

import objectMenu from "@/views/JS/objectMenu.js";

export default {
  components: {
    DialogAngolare,
    ImgProdotto,
    PdfViewer,
    ScrollBar,
    FinitureProdotto,
    Icone,
    DettaglioProdotto,
    IconeDettaglioProdotto,
    FinitureDettaglioProdottoTelefono,
    ImgProdottoDettaglioNew,
    ScrollBarMonitor,
    ImgProdottoDettaglioNewMonitor,
    FinitureDettaglioProdottoMonitor,
    ScrollBarSurface,
    ImgProdottoDettaglioSurface,
    FinitureDettaglioProdottoSurface,
    ImgProdottoDettaglioTablet,
    IconeDettaglioProdottoTablet,
    FinitureDettaglioProdottoTablet,
    ScrollBarTv,
    ImgProdottoDettaglioNewTv,
    FinitureDettaglioProdottoTv,
  },

  data: () => ({
    //parametri URL
    params: "",
    //URL
    Url: {},
    //URL Prodotto
    urlImgProdotto: "",
    //descrizione prodotto
    descrizioneProdotto: "",
    secondaDescrizioneProdotto: "",
    //codice composizione
    codiceComposizione: "",
    //img prodotto icon
    Icone: objectMenu.methods.getIcone(),

    //composizioni
    Parametri: {},
    datiComposizioni: [],
    datiFinitureStandard: [],
    datiFiniturePvd: [],
    datiFinitureRetro: [],

    //finitura
    Finitura: {},
    finitura: false,
    suffissoComposizione: 0,

    //dialog
    dialogAngolare: false,
    dialogProdotto: false,
    dialog: false,
    src: "",
    type: "pdf",

    //img prodotto
    nomeDelProdotto: "",
    cartella: "",
    composizioneNome: "",
    extantionNome: "",

    //scrollbar composizioni
    benched: 12,

    //download scheda tecnica
    tipo: "",
    tipoComposizione: "",

    imgComposizione: "",

    //stile visualizzazione display devices
    stileConfigurazioni: {},
  }),

  methods: {
    //PDF PAGE
    esportaPdf() {
      try {
        let bar = document.getElementById("pagina");
        let exp = new Exporter([bar]);
        exp
          .export_pdf()
          .then((pdf) =>
            pdf.save(this.Url.NomeProdotto + "_" + this.Url.CodTestata + ".pdf")
          );
      } catch (error) {
        console.log(error);
      }
    },

    //Finiture
    async GetFiniture() {
      this.datiFinitureStandard = [];
      this.datiFiniturePvd = [];
      this.datiFinitureRetro = [];
      try {
        let res = await get_finiture(this.Url);
        this.datiFinitureStandard = res.data.listaFinitureStandard;
        this.datiFiniturePvd = res.data.listaFiniturePvd;
        this.datiFinitureRetro = res.data.listaFinitureRetro;
      } catch (error) {
        console.error(error);
      }
    },

    //Composizioni
    async GetComposizioni() {
      this.datiComposizioni = [];
      let res = "";
      let composizione = {};

      //controllo se è stata selezionata una finitura diversa da quella caricata
      if (this.finitura === false) {
        res = await get_composizione(this.Url);
        this.datiComposizioni = res.data;
        if (this.Url.Tipo != "Scorrevoli") {
          // gli Scorrevoli possono avere una composizione diversa (non solo la prima) perché nell'indice si vedono tutte le composizioni
          if (this.Url.TipoComposizione == "") {
            this.urlImgProdotto = this.datiComposizioni[0].url; //url img prodotto
            this.suffissoComposizione = this.datiComposizioni[0].suffisso; //suffisso composizione img prodotto
          } else {
            composizione = this.datiComposizioni.filter(
              (x) => x.nomeComposizione === this.Url.TipoComposizione
            );
            this.urlImgProdotto = composizione[0].url; //url img prodotto
            this.suffissoComposizione = composizione[0].suffisso; //suffisso composizione img prodotto
          }
        } else {
          this.searchComposizioneScorrevole();
        }
      } else {
        res = await get_composizione(this.Finitura);
        this.datiComposizioni = res.data;
        this.searchComposizione(); // setto url img prodotto aggiornata (composizione e finitura new)
      }

      //ricavo secondaDescrizione prodotto
      composizione = this.datiComposizioni.filter(
        (x) => x.suffisso === this.suffissoComposizione
      );
      this.secondaDescrizioneProdotto = composizione[0].secondaDescrizione; //seconda descrizione prodotto
      this.secondaDescrizioneProdotto = this.convertiTestoGrassetto(
        this.secondaDescrizioneProdotto
      ); //converto il testo in grassetto
      //this.getCodiceComposizione(this.datiComposizioni[0]); //get codice composizione
      this.getCodiceComposizione(composizione[0]); //get codice composizione
    },

    searchComposizioneScorrevole() {
      // carico la prima composizione (solo per Scorrevoli)
      let temp = this.datiComposizioni.filter((x) => x.suffisso === this.Url.Suffisso); // cerco la composizione visualizzata con la nuova finitura nelle composizioni
      this.urlImgProdotto = temp[0].url; //url img prodotto
      this.suffissoComposizione = temp[0].suffisso; //suffisso composizione img prodotto
    },

    searchComposizione() {
      this.Finitura.suffisso = this.suffissoComposizione; // sostituisco suffisso indice con suffisso composizione visualizzata
      let temp = this.datiComposizioni.filter(
        (x) => x.suffisso === this.Finitura.suffisso
      ); // cerco la composizione visualizzata con la nuova finitura nelle composizioni
      this.urlImgProdotto = temp[0].url; //url img prodotto
    },

    getCodiceComposizione(composizione) {
      if (composizione.cdCompletoProdotto != null)
        this.codiceComposizione = composizione.cdCompletoProdotto;
      else this.codiceComposizione = composizione.cdCompletoProdottoDettaglio;
    },

    async getIconProdotto() {
      let composizione = await get_icone(this.Url.CodTestata, this.Url); //parametri + oggetto
      this.Icone = {
        novitaProdotto: composizione.data.novita,
        disponibilitaProdotto: composizione.data.disponibileProssimamente,
        restyling: composizione.data.restyling,
      };
    },

    //colore prodotto composizione
    async getDettComposizione(item) {
      if (item.nomeComposizione) {
        //SETTO INDIRIZZO URL AGGIORNATO
        this.Url.TipoComposizione = item.nomeComposizione;
        this.Url.Suffisso = item.suffisso;
        this.Url.Finitura = item.finiture;
        this.params.set("TipoComposizione", item.nomeComposizione); //setto il tipo di composizione
        this.params.set("Suffisso", item.suffisso); //setto il suffisso
        this.params.set("Finitura", item.finiture); //setto il tipo finitura

        //ricarico pagina con url aggiornato
        //window.location.search = this.params; //aggiungo la nuova composizione nell'URL

        //aggiungo parametri nuovi in url (NO ricarico pagina)
        var queryString = new URLSearchParams(this.params).toString();
        var newUrl =
          window.location.protocol +
          "//" +
          window.location.host +
          window.location.pathname +
          "?" +
          queryString;
        window.history.pushState({ path: newUrl }, "", newUrl);

        try {
          let res = await get_dettComposizione(item);
          this.urlImgProdotto = res.data.url; //url img prodotto
          this.suffissoComposizione = res.data.suffisso; //suffisso composizione img prodotto
          this.secondaDescrizioneProdotto = res.data.secondaDescrizione; //seconda descrizione prodotto
          this.secondaDescrizioneProdotto = this.convertiTestoGrassetto(
            this.secondaDescrizioneProdotto
          ); //converto il testo in grassetto

          this.getCodiceComposizione(res.data); //get codice composizione
        } catch (err) {
          console.error(err);
        }
      }
    },

    //colore prodotto finitura
    async getDettFiniture(item) {
      //SETTO INDIRIZZO URL AGGIORNATO
      this.Url.CodiceFinitura = item.codiceFinitura;
      this.Url.NomeFinitura = item.tipoFinitura;
      this.Url.Finitura = item.finiture;
      this.params.set("CodFinitura", item.codiceFinitura); //setto il codice finitura
      this.params.set("NomeFinitura", item.tipoFinitura); //setto il nome finitura
      this.params.set("Finitura", item.finiture); //setto il tipo finitura
      if (this.suffissoComposizione != "" && this.suffissoComposizione != null) {
        this.Url.Suffisso = this.suffissoComposizione;
        this.params.set("Suffisso", this.suffissoComposizione); //setto il suffisso
      }

      //ricarico pagina con url aggiornato
      //window.location.search = this.params; //aggiungo il codice finitura nell'URL

      //aggiungo parametri nuovi in url (NO ricarico pagina)
      var queryString = new URLSearchParams(this.params).toString();
      var newUrl =
        window.location.protocol +
        "//" +
        window.location.host +
        window.location.pathname +
        "?" +
        queryString;
      window.history.pushState({ path: newUrl }, "", newUrl);
      //ricavo altezza scroll composizioni
      let scroll = document.getElementById("scrollable"); //scroll-bar composizioni
      let H = scroll.scrollTop; //get scroll top

      if (this.Url.Tipo == "Maniglioni") item.suffisso = this.suffissoComposizione;
      try {
        let res = await get_dettFinitura(item);
        this.Finitura = res.data; // salvo la nuova finitura (appena selezionata)
      } catch (error) {
        console.error(error);
      }
      if (this.Finitura.tipo == null || this.Finitura.tipo == "")
        //controllo per MANIGLIONI e ALZANTI (non hanno il campo tipo)
        this.Finitura.tipo = this.Url.Tipo;
      this.finitura = true;

      await this.GetComposizioni(); // ricarico configurazioni aggiornate (finitura appena selezionata)
      //scroll-bar composizioni
      scroll.scroll({
        top: H,
        left: 0,
        behavior: "smooth", //animazione
      });
    },

    getDatiFinitureStandard() {
      //filtro finiture 'Standard' per prodotto
      let res = this.datiFinitureStandard;
      if (this.Url.Tipo == "Scorrevoli") {
        //controllo per Kit Scorrevoli (necessita del filtro 'Suffisso')
        res = res.filter(
          (f) =>
            f.codTestata == this.Url.CodTestata &&
            f.nomeProdotto == this.Url.NomeProdotto &&
            f.suffisso == this.Url.Suffisso
        );
      } else {
        //NO Scorrevole
        res = res.filter(
          (f) =>
            f.codTestata == this.Url.CodTestata && f.nomeProdotto == this.Url.NomeProdotto
        );
      }

      return res;
    },

    getDatiFiniturePvd() {
      //filtro finiture 'Standard' per prodotto
      let res = this.datiFiniturePvd;
      if (this.Url.Tipo == "Scorrevoli") {
        //controllo per Kit Scorrevoli (necessita del filtro 'Suffisso')
        res = res.filter(
          (f) =>
            f.codTestata == this.Url.CodTestata &&
            f.nomeProdotto == this.Url.NomeProdotto &&
            f.suffisso == this.Url.Suffisso
        );
      } else {
        //NO Scorrevole
        res = res.filter(
          (f) =>
            f.codTestata == this.Url.CodTestata && f.nomeProdotto == this.Url.NomeProdotto
        );
      }

      return res;
    },

    getDatiFinitureRetro() {
      //filtro finiture 'Standard' per prodotto
      let res = this.datiFinitureRetro;
      if (this.Url.Tipo == "Scorrevoli") {
        //controllo per Kit Scorrevoli (necessita del filtro 'Suffisso')
        res = res.filter(
          (f) =>
            f.codTestata == this.Url.CodTestata &&
            f.nomeProdotto == this.Url.NomeProdotto &&
            f.suffisso == this.Url.Suffisso
        );
      } else {
        //NO Scorrevole
        res = res.filter(
          (f) =>
            f.codTestata == this.Url.CodTestata && f.nomeProdotto == this.Url.NomeProdotto
        );
      }

      return res;
    },

    async getDescrizioneProdotto() {
      let res = await get_descrizione(this.Url.CodTestata, this.Url); //parametri + oggetto
      this.descrizioneProdotto = res.data;
    },

    isTelefono() {
      // visualizzazione telefono
      return window.innerWidth;
    },

    //VISTA 3D PRODOTTO
    vistaAngolare() {
      if (this.dialogAngolare == true) {
        this.dialogAngolare = false;
      } else {
        this.dialogAngolare = true;
      }
    },

    //DOWNMLOAD IMG PRODOTTO /SOTTO COMPOSIZIONI
    imgProdottoDetttaglio(dettaglio) {
      if (this.dialogProdotto == true) {
        this.dialogProdotto = false;
      } else {
        this.nomeDelProdotto = dettaglio.tipoProdotto;
        this.src = dettaglio.url;
        this.dialogProdotto = true;
      }
    },

    //SCHEDA TECNICA
    async chiudi() {
      if (this.dialog == true) {
        this.dialog = false;
      } else {
        let schedaTecnica = this.Url; //oggetto di supporto per non variare oggetto principale (Url)
        /* this.item.tipo = this.tipo; //se vuoto per sicurezza
        this.item.tipoComposizione = this.tipoComposizione; //se vuoto per sicurezza */
        if (this.Url.Tipo == "Maniglie") {
          schedaTecnica.tipo = "1_" + this.Url.Tipo;
        } else if (this.Url.Tipo == "Pomoli") {
          schedaTecnica.tipo = "2_" + this.Url.Tipo;
        } else if (this.Url.Tipo == "Maniglioni") {
          schedaTecnica.tipo = "3_" + this.Url.Tipo;
        } else if (this.Url.Tipo == "Alzanti") {
          schedaTecnica.tipo = "4_" + this.Url.Tipo;
        } else if (this.Url.Tipo == "Scorrevoli") {
          schedaTecnica.tipo = "5_" + this.Url.Tipo;
        } else if (this.Url.Tipo == "Coordinati e Accessori") {
          schedaTecnica.tipo = "6_" + this.Url.Tipo;
        }

        let res = await get_downloadPdf(
          `${schedaTecnica.tipo}/${schedaTecnica.Nome}/${schedaTecnica.NomeProdotto}`,
          schedaTecnica
        );
        /* let res = await get_downloadPdf(
          schedaTecnica.Tipo +
          "/" +
          schedaTecnica.Nome +
          "/" +
          schedaTecnica.NomeProdotto,
          schedaTecnica
        ); */
        this.src = res.data;
        /* let str = this.src.split("/");
        this.nomeDelProdotto = str[9]; */

        if (res.data != null) {
          this.dialog = true;
        }
      }
    },

    //DOWNLOAD SINGOLA IMG COMPOSIZIONE
    async getImgComposizione(dettaglio) {
      this.dettaglio.tipo = this.tipo; //se vuoto per sicurezza
      this.dettaglio.tipoComposizione = this.tipoComposizione; //se vuoto per sicurezza
      try {
        let res = await get_downloadImgComposizione(
          `${dettaglio.tipo}/${dettaglio.tipoComposizione}/${dettaglio.nomeProdotto}`,
          dettaglio
        );
        this.imgComposizione = res.data;
      } catch (err) {
        err.message;
      }

      window.open(res.data);
    },

    //grassetto testo
    convertiTestoGrassetto(valore) {
      let firstvariable = '"';
      let secondvariable = '"';

      let regExString = new RegExp(
        "(?:" + firstvariable + ")(.*?)(?:" + secondvariable + ")",
        "ig"
      );

      let testRE = regExString.exec(valore);

      if (testRE && testRE.length > 1) {
        valore = valore.replace(
          firstvariable + testRE[1] + secondvariable,
          "<b>" + testRE[1] + "</b><br>"
        );

        // Controllo ricorsione
        if (valore.includes(firstvariable)) valore = this.convertiTestoGrassetto(valore);
      }

      // Sostituisce underscore
      if (valore) valore = valore.replaceAll("_", "&nbsp;").replaceAll("#", "&nbsp;");

      return valore;
    },

    //URL immagini
    /* getUrlImmagini() {
      return this.$load.getUrlDettProd() + "/immagini";
    }, */

    dimensionDisplay(value) {
      //controll dimension display devices
      //fare proporzione tra una dimensione e l'altra per scalare tutti gli stili
      if (value <= 768) {
        //smartphone
      } else if (value > 768 && value <= 1050) {
        //tablet (1024
        //stile riga prodotti
      } else if (value > 1050 && value <= 1280) {
        //HD (16:9 / 16:10)
      } else if (value > 1280 && value <= 1540) {
        //tablet apple / surface (windows)
      } else if (value > 1540 && value <= 1920) {
        //classic pc FULL HD 16:9 / 	16:10 --> 1680
      } else if (value > 1920 && value <= 2100) {
        //classic pc (16:10 / 16:9 / 21:9)
      } else if (value > 2100 && value <= 2560) {
        //pc monitor (16:9)
      } else if (value > 2560 && value <= 3840) {
        //TV 4K
      }
    },
  },

  created() {
    //recupero arams url
    this.params = new URLSearchParams(window.location.search);
    this.Url = {
      NomeProdotto: this.params.get("Prodotto"),
      Tipo: this.params.get("Tipo"),
      Finitura: this.params.get("Finitura"),
      CodTestata: this.params.get("CodTestata"),
      CodiceFinitura: this.params.get("CodFinitura"),
      Suffisso: this.params.get("Suffisso"),
      NomeFinitura: this.params.get("NomeFinitura"),
      Nome: this.params.get("Composizione"),
      TipoComposizione: this.params.get("TipoComposizione"),
    };
    //this.dimensionDisplay(this.isTelefono());
    //console.log(this.Url);
  },

  mounted() {
    this.getDescrizioneProdotto(); //descrizione prodotto
    this.getIconProdotto(); //icon prodotto dettaglio
    this.GetComposizioni(); //composizioni
    this.GetFiniture(); //finiture (standard / pvd / retrò)
  },

  computed: {
    composizioni() {
      let res = this.datiComposizioni;
      return res;
    },
    finitureStandard() {
      return this.getDatiFinitureStandard();
    },
    finiturePvd() {
      return this.getDatiFiniturePvd();
    },
    finitureRetro() {
      return this.getDatiFinitureRetro();
    },
  },

  watch: {
    urlImgProdotto() {
      return this.urlImgProdotto;
    },
    suffissoComposizione() {
      return this.suffissoComposizione;
    },
  },
};
</script>

<style>
/* width */
::-webkit-scrollbar {
  width: 5px;
  height: 200px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #e40520;
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #e40520;
}

.v-carousel__controls__item {
  color: #e40520 !important;
}
</style>
