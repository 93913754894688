<template>
  <div>
    <!-- Barra menu laterale delle voci -->
    <Left-Bar ref="leftBar"></Left-Bar>

    <!-- Toolbar superiore -->
    <Tool-Bar @showLeftBar="showLeftBar()"> </Tool-Bar>

    <v-main class="pa-0">
      <v-container fluid>
        <!-- Router view -->
        <router-view></router-view>
      </v-container>
    </v-main>

    <!-- <v-footer class="caption" app inset
      >Copyright © 2022 Ecolinea. BATTISTI FRANCESCO SNC - C.F./.IVA IT
      01845870045 - CCIAA CUNEO 139172 - REGISTRO SOCIETA N.2522 TRIBUNALE DI
      SALUZZO Cookies policy</v-footer
    > -->

    <v-footer
      class="pa-0"
      fixed
      app
      :style="[isTelefono() < 460 ? { height: '3%' } : { height: '2.5%' }]"
      ><v-spacer></v-spacer>
      <p style="font-size: 10px">
        © {{ new Date().getFullYear() }} BATTISTI FRANCESCO SNC - P.IVA IT 01845870045 -
        <a v-on:click="goToPagePrivacy()" style="color: black" target="_blank">Privacy</a>
        <a
          class="ml-1"
          v-on:click="goToPageCookies()"
          style="color: black"
          target="_blank"
          >Cookies</a
        >
      </p>
    </v-footer>
  </div>
</template>

<script>
import { mapState } from "vuex";

import LeftBar from "@/components/layout/Leftbar";
import ToolBar from "@/components/layout/Toolbar";

import storage from "@/services/storage.js";

//import { get_datiUserInfo } from "@/api/api.user.js";

export default {
  components: {
    LeftBar,
    ToolBar,
  },

  data: (vm) => ({
    //urlPrivacy: vm.$load.getUrlPrivacy(),
  }),

  methods: {
    showLeftBar() {
      this.$refs.leftBar.leftBarVis = !this.$refs.leftBar.leftBarVis;
    },

    goToPagePrivacy() {
      this.$router.push("/privacy");
    },

    goToPageCookies() {
      this.$router.push("/cookies");
    },

    isTelefono() {
      return window.innerWidth;
      //return this.$i2kFunctions.isSmartphone();
    },
  },

  mounted() {},

  computed: {
    ...mapState({
      loading: (state) => state.app.loading,
      utente: (state) => state.user.descUser,
      role: (state) => state.user.role,
      group: (state) => state.user.group,
      message: (state) => state.app.message,
      waitLoginAzienda: (state) => state.app.waitLoginAzienda,
    }),

    auth() {
      return this.$store.getters["auth/isAuthenticated"];
    },
  },

  watch: {
    message() {
      this.$toast.alert(this.message);
    },

    waitLoginAzienda() {
      this.userInfo();
    },
  },
};
</script>
