<template>
  <!-- DISPLAY SURFACE > 768 / <= 1050-->
  <div>
    <!-- IMG PRODOTTO MANIGLIE / POMOLI -->
    <v-img
      v-if="Url.Tipo == 'Maniglie' || Url.Tipo == 'Pomoli'"
      style="
        width: 400px;
        height: 610px;
        margin-top: 5em;
        margin-left: -1em;
        cursor: pointer;
        background-color: trasparent;
      "
      :src="urlImgProdotto"
      :lazy-src="urlImgProdotto"
      aspect-ratio="1"
    >
      <template v-slot:placeholder>
        <v-row class="fill-height ma-0" align="center" justify="center">
          <v-progress-circular
            indeterminate
            color="black lighten-5"
          ></v-progress-circular>
        </v-row>
      </template>
    </v-img>

    <!-- IMG PRODOTTO SCORREVOLI -->
    <v-img
      v-if="Url.Tipo == 'Scorrevoli'"
      style="
        width: 500px;
        height: 610px;
        margin-top: 5em;
        margin-left: 0em;
        cursor: pointer;
        background-color: trasparent;
      "
      :src="urlImgProdotto"
      :lazy-src="urlImgProdotto"
      aspect-ratio="1"
    >
      <template v-slot:placeholder>
        <v-row class="fill-height ma-0" align="center" justify="center">
          <v-progress-circular
            indeterminate
            color="black lighten-5"
          ></v-progress-circular>
        </v-row>
      </template>
    </v-img>

    <!-- IMG PRODOTTO MANIGLIONI / ALZANTI -->
    <v-img
      v-if="Url.Tipo == 'Maniglioni' || Url.Tipo == 'Alzanti'"
      style="
        width: 450px;
        height: 740px;
        margin-top: 0em;
        margin-left: -4em;
        cursor: pointer;
        background-color: trasparent;
      "
      :src="urlImgProdotto"
      :lazy-src="urlImgProdotto"
      aspect-ratio="1"
    >
      <template v-slot:placeholder>
        <v-row class="fill-height ma-0" align="center" justify="center">
          <v-progress-circular
            indeterminate
            color="black lighten-5"
          ></v-progress-circular>
        </v-row>
      </template>
    </v-img>

    <!-- CODICE PRODOTTO - SECONDA DESCRIZIONE PRODOTTO - ICONA PRODOTTO -->
    <v-row
      :style="[
        Url.Tipo == 'Maniglioni' || Url.Tipo == 'Alzanti'
          ? { 'margin-left': '16vh', 'margin-top': '-27vh' }
          : { 'margin-left': '20vh', 'margin-top': '-22vh' },
      ]"
      class="d-flex child-flex"
    >
      <v-col cols="12">
        <span style="margin-top: -2.5em; margin-left: 10em; position: absolute">
          <img
            v-if="Icone.novitaProdotto == true"
            style="width: 60px; height: 60px; float: right"
            :src="require('@/assets/img/Novità.png')"
          />
          <img
            v-if="Icone.disponibilitaProdotto == true"
            style="width: 60px; height: 60px; float: right"
            :src="require('@/assets/img/DisponibileMaggio23.png')"
          />
          <img
            v-if="Icone.restyling == true"
            style="width: 60px; height: 60px; float: right"
            :src="require('@/assets/img/Restyling.png')"
          />
        </span>
        <h3>
          {{ codiceComposizione }}
        </h3>
        <div
          :style="[
            isTelefono() > 768 && isTelefono() <= 1050
              ? { width: '15em', 'font-size': '15px' }
              : { width: '17em', 'font-size': '15px' },
          ]"
        >
          <span v-html="secondaDescrizioneProdotto"> </span>
        </div>
        <h3 class="mt-5" v-if="Utente != '' && Prezzo.prezzoListino != 0">
          Prezzo Listino: <span style="color: #e40520">{{ Prezzo.prezzoListino }} €</span>
        </h3>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { get_prezzo } from "@/api/api.prodotti.js";
import { get_datiUserInfo } from "@/api/api.user.js";

export default {
  props: {
    Url: { type: Object, default: null },
    urlImgProdotto: { type: String, default: "" },
    secondaDescrizioneProdotto: { type: String, default: "" },
    codiceComposizione: { type: String, default: "" },
    Icone: { type: Object, default: null },
  },

  components: {},

  data: () => ({
    //prezzo
    Prezzo: {},
    //utente
    Utente: {},
  }),

  methods: {
    async GetPrezzo() {
      let par = {
        UserId: this.Utente.userId,
        CdArticolo: "ECO" + this.codiceComposizione.replace(/\s/g, ""), //this.Url.CodTestata + this.Url.CodiceFinitura + this.Url.Suffisso,
      };

      let res = await get_prezzo(par);
      this.Prezzo = res.data;
      //set to decimal
      this.Prezzo.prezzoListino = this.Prezzo.prezzoListino.toFixed(2);
      this.Prezzo.prezzoScontato = this.Prezzo.prezzoScontato.toFixed(2);
    },

    async userInfo() {
      try {
        // User info
        let res = await get_datiUserInfo();
        if (res.data != null) {
          this.$store.commit("user/SET_USER", res.data); //dati utente
          this.Utente = res.data;
        }
      } catch (error) {
        console.log(error);
      }
    },

    isTelefono() {
      // visualizzazione telefono
      return window.innerWidth;
    },
  },

  created() {},

  async mounted() {
    await this.userInfo();
    await this.GetPrezzo(); //prezzo dettaglio prodotto
  },

  watch: {
    codiceComposizione() {
      this.GetPrezzo();
    },
  },

  computed: {},
};
</script>
