var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-navigation-drawer',{style:([_vm.isTelefono() <= 1920 ? { width: '30vh' } : { width: '28vh' }]),attrs:{"clipped":_vm.$vuetify.breakpoint.mdAndUp,"fixed":"","app":"","mobile-breakpoint":"1380","dark":"","color":"white"},model:{value:(_vm.leftBarVis),callback:function ($$v) {_vm.leftBarVis=$$v},expression:"leftBarVis"}},[(_vm.isTelefono() > 768)?_c('div',{staticClass:"ma-0 pa-0",staticStyle:{"height":"5vh"}}):_vm._e(),_c('div',{staticClass:"ma-0 pa-0",style:([
      _vm.isTelefono() > 768 && _vm.isTelefono() > 1360
        ? { 'border-right': 'solid 1px black', height: '25vh' }
        : { 'border-right': 'solid 1px black', height: '27vh' } ])},[_c('v-list',{style:([_vm.isTelefono() <= 768 ? {} : { 'margin-top': '-1em' }]),attrs:{"dense":"","nav":""}},[_vm._l((_vm.filtro1),function(item,i){return [(item.text == 'Modelli')?_c('v-list-group',{key:i,attrs:{"append-icon":"","value":true,"no-action":"","color":"black","active-class":_vm.checkRoute(item.link) ? 'black' : '',"disabled":true},scopedSlots:_vm._u([{key:"activator",fn:function(){return [_c('v-list-item-content',{staticClass:"font-weight-light"},[_c('v-list-item-title',{staticClass:"pa-1",class:{ 'text-h4': _vm.isTelefono() > 2560, 'pa-2': _vm.isTelefono() > 2560 },style:([
                  _vm.isTelefono() <= 1920
                    ? { 'font-size': '18px', height: '20px' }
                    : { 'font-size': '25px', height: '30px' } ])},[_vm._v(_vm._s(item.text))])],1)]},proxy:true}],null,true)},_vm._l((item.sub),function(itm,i){return _c('v-list-item',{key:i,staticClass:"pl-5",staticStyle:{"margin-top":"-1vh","margin-left":"0.5em"},style:({
              backgroundColor:
                _vm.filtriMenu.nomeProdotto == itm.text && _vm.filtriMenu.url != '/'
                  ? '#8E8E8E'
                  : 'transparent',
              color:
                _vm.filtriMenu.nomeProdotto == itm.text && _vm.filtriMenu.url != '/'
                  ? 'white'
                  : 'black',
            }),attrs:{"link":""},on:{"click":function($event){return _vm.gotoPage(itm.link)}}},[_c('v-list-item-content',{class:{ telefoni: _vm.isTelefono() <= 768 }},[(_vm.isTelefono() < 3840)?_c('v-list-item-title',{staticClass:"pa-1 font-weight-light",class:{ telefono: _vm.isTelefono() <= 768 },style:([
                  _vm.isTelefono() <= 1920
                    ? { 'font-size': '16px', height: '20px' }
                    : { 'font-size': '21px', height: '2vh', 'margin-top': '1vh' } ])},[_vm._v(" "+_vm._s(itm.text)+" ")]):_c('v-list-item-title',{staticClass:"pa-1 font-weight-light",style:([
                  _vm.isTelefono() > 2560
                    ? { 'font-size': '28px', height: '2vh', 'margin-top': '1.5vh' }
                    : { 'font-size': '25px', height: '2vh', 'margin-top': '1.5vh' } ])},[_vm._v(" "+_vm._s(itm.text)+" ")])],1)],1)}),1):_vm._e()]})],2)],1),_c('div',{staticClass:"ma-0 pa-0",style:([
      _vm.isTelefono() > 1360 && _vm.isTelefono() > 768
        ? { 'border-right': 'solid 1px black', height: '17vh' }
        : { 'border-right': 'solid 1px black', height: '19vh' } ])},[_c('v-list',{attrs:{"dense":"","nav":""}},[_vm._l((_vm.filtro2),function(item,x){return [(item.text == 'Linea')?_c('v-list-group',{key:x,attrs:{"append-icon":"","value":true,"no-action":"","color":"black","active-class":_vm.checkRoute(item.link) ? 'white' : '',"disabled":true},scopedSlots:_vm._u([{key:"activator",fn:function(){return [_c('v-list-item-content',{staticClass:"font-weight-light"},[_c('v-list-item-title',{staticClass:"pa-1",class:{ 'text-h4': _vm.isTelefono() > 2560, 'pa-2': _vm.isTelefono() > 2560 },style:([
                  _vm.isTelefono() <= 1920
                    ? { 'font-size': '18px', height: '20px' }
                    : { 'font-size': '25px', height: '30px' } ])},[_vm._v(_vm._s(item.text))])],1)]},proxy:true}],null,true)},_vm._l((item.sub),function(itm,i){return _c('v-list-item',{key:i,staticClass:"pl-5",staticStyle:{"margin-top":"-1vh","margin-left":"0.5em"},style:({
              backgroundColor:
                _vm.filtriMenu.nomeProdotto2 == itm.text ? '#8E8E8E' : 'transparent',
              color: _vm.filtriMenu.nomeProdotto2 == itm.text ? 'white' : 'black',
            }),attrs:{"color":"white","link":"","dense":""},on:{"click":function($event){return _vm.gotoPage(itm.link)}}},[_c('v-list-item-content',{class:{ telefoni: _vm.isTelefono() <= 768 }},[(_vm.isTelefono() < 3840)?_c('v-list-item-title',{staticClass:"pa-1 font-weight-light",class:{ telefono: _vm.isTelefono() <= 768 },style:([
                  _vm.isTelefono() <= 1920
                    ? { 'font-size': '16px', height: '20px' }
                    : { 'font-size': '21px', height: '2vh', 'margin-top': '1vh' } ])},[_vm._v(" "+_vm._s(itm.text)+" ")]):_c('v-list-item-title',{staticClass:"pa-1 font-weight-light",style:([
                  _vm.isTelefono() > 2560
                    ? { 'font-size': '28px', height: '2vh', 'margin-top': '1.5vh' }
                    : { 'font-size': '25px', height: '2vh', 'margin-top': '1.5vh' } ])},[_vm._v(" "+_vm._s(itm.text)+" ")])],1)],1)}),1):_vm._e()]})],2)],1),_c('div',{staticClass:"ma-0 pa-0",style:([
      _vm.isTelefono() > 1360 && _vm.isTelefono() <= 1920
        ? { 'border-right': 'solid 1px black', height: '43vh' }
        : { 'border-right': 'solid 1px black', height: '40vh' } ])},[_c('v-list',{attrs:{"dense":"","nav":""}},[_vm._l((_vm.filtro3),function(item,z){return [(item.text == 'Finiture Standard')?_c('v-list-group',{key:z,attrs:{"append-icon":"","value":true,"no-action":"","color":"black","disabled":true},scopedSlots:_vm._u([{key:"activator",fn:function(){return [_c('v-list-item-content',{staticClass:"font-weight-light"},[_c('v-list-item-title',{staticClass:"pa-1",class:{
                  menu: item.text === _vm.colore,
                  coloreTesto: item.text != _vm.colore,
                  'text-h4': _vm.isTelefono() > 2560,
                  'pa-2': _vm.isTelefono() > 2560,
                },style:([
                  _vm.isTelefono() <= 1920
                    ? { 'font-size': '18px', height: '20px' }
                    : { 'font-size': '25px', height: '2vh' } ]),on:{"click":function($event){_vm.gotoPage(item.link), _vm.setColoreMenu(item)}}},[_vm._v(_vm._s(item.text))])],1)]},proxy:true}],null,true)},[_c('div',_vm._l((_vm.standard),function(itm,i){return _c('v-list-item-avatar',{key:i,staticClass:"ma-0 pa-0 ml-1",staticStyle:{"width":"5vh","height":"5vh"},attrs:{"link":""},on:{"click":function($event){_vm.setFiltroFinitura(itm), _vm.gotoPageFiniture(item.link)}}},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                var on = ref.on;
                var attrs = ref.attrs;
return [_c('img',_vm._g(_vm._b({staticStyle:{"cursor":"pointer"},style:([
                      _vm.codiceFinitura != itm.codiceFinitura
                        ? { width: '5vh', height: '5vh' }
                        : { width: '6vh', height: '6vh' } ]),attrs:{"src":itm.url}},'img',attrs,false),on))]}}],null,true)},[_c('span',[_vm._v(_vm._s(itm.tipoFinitura))])])],1)}),1)]):_vm._e()]})],2),_c('v-list',{attrs:{"dense":"","nav":""}},[_vm._l((_vm.filtro3),function(item,z){return [(item.text == 'Finiture PVD')?_c('v-list-group',{key:z,attrs:{"append-icon":"","value":true,"no-action":"","color":"black","disabled":true},scopedSlots:_vm._u([{key:"activator",fn:function(){return [_c('v-list-item-content',{staticClass:"font-weight-light"},[_c('v-list-item-title',{staticClass:"pa-1",class:{
                  menu: item.text === _vm.colore,
                  coloreTesto: item.text != _vm.colore,
                  'text-h4': _vm.isTelefono() > 2560,
                  'pa-2': _vm.isTelefono() > 2560,
                },style:([
                  _vm.isTelefono() <= 1920
                    ? { 'font-size': '18px', height: '20px' }
                    : { 'font-size': '25px', height: '2vh' } ]),on:{"click":function($event){_vm.gotoPage(item.link), _vm.setColoreMenu(item)}}},[_vm._v(_vm._s(item.text))])],1)]},proxy:true}],null,true)},[_c('div',_vm._l((_vm.pvd),function(itm,i){return _c('v-list-item-avatar',{key:i,staticClass:"ma-0 pa-0 ml-1",staticStyle:{"width":"5vh","height":"5vh"},attrs:{"link":""},on:{"click":function($event){_vm.setFiltroFinitura(itm), _vm.gotoPageFiniture(item.link)}}},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                var on = ref.on;
                var attrs = ref.attrs;
return [_c('img',_vm._g(_vm._b({staticStyle:{"cursor":"pointer"},style:([
                      _vm.codiceFinitura != itm.codiceFinitura
                        ? { width: '5vh', height: '5vh' }
                        : { width: '6vh', height: '6vh' } ]),attrs:{"src":itm.url}},'img',attrs,false),on))]}}],null,true)},[_c('span',[_vm._v(_vm._s(itm.tipoFinitura))])])],1)}),1)]):_vm._e()]})],2),_c('v-list',{attrs:{"dense":"","nav":""}},[_vm._l((_vm.filtro3),function(item,z){return [(item.text == 'Finiture Retrò')?_c('v-list-group',{key:z,attrs:{"append-icon":"","value":true,"no-action":"","color":"black","disabled":true},scopedSlots:_vm._u([{key:"activator",fn:function(){return [_c('v-list-item-content',{staticClass:"font-weight-light"},[_c('v-list-item-title',{staticClass:"pa-1",class:{
                  menu: item.text === _vm.colore,
                  coloreTesto: item.text != _vm.colore,
                  'text-h4': _vm.isTelefono() > 2560,
                  'pa-2': _vm.isTelefono() > 2560,
                },style:([
                  _vm.isTelefono() <= 1920
                    ? { 'font-size': '18px', height: '20px' }
                    : { 'font-size': '25px', height: '2vh' } ]),on:{"click":function($event){_vm.gotoPage(item.link), _vm.setColoreMenu(item)}}},[_vm._v(_vm._s(item.text))])],1)]},proxy:true}],null,true)},[_c('div',_vm._l((_vm.retro),function(itm,i){return _c('v-list-item-avatar',{key:i,staticClass:"ma-0 pa-0 ml-1",staticStyle:{"width":"5vh","height":"5vh"},attrs:{"link":""},on:{"click":function($event){_vm.setFiltroFinitura(itm), _vm.gotoPageFiniture(item.link)}}},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                var on = ref.on;
                var attrs = ref.attrs;
return [_c('img',_vm._g(_vm._b({staticStyle:{"cursor":"pointer"},style:([
                      _vm.codiceFinitura != itm.codiceFinitura
                        ? { width: '5vh', height: '5vh' }
                        : { width: '6vh', height: '6vh' } ]),attrs:{"src":itm.url}},'img',attrs,false),on))]}}],null,true)},[_c('span',[_vm._v(_vm._s(itm.tipoFinitura))])])],1)}),1)]):_vm._e()]})],2)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }