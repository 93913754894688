<template>
  <div class="mt-0" style="background-color: white">
    <!-- NESSUN PRODOTTO TROVATO PER COMBINAZIONE FILTRI MENU' -->
    <h1
      style="text-align: center; margin-top: 0%; margin-right: 25%"
      v-if="noProdotti == true"
    >
      La ricerca non ha prodotto risultati
    </h1>

    <v-progress-circular
      class="mt-3"
      :size="50"
      style="width: 100%"
      v-if="loading === true"
      :active="loading"
      :indeterminate="loading"
      absolute
      bottom
      color="#E40520"
    ></v-progress-circular>

    <v-row
      :style="{
        'margin-left': stileRowProdotti.marginLeft,
        'margin-top': stileRowProdotti.marginTop,
        'margin-right': stileRowProdotti.marginRight,
        width: stileRowProdotti.width,
      }"
    >
      <!-- PRODOTTO IMG -->
      <!-- cols="2" -->
      <v-col
        :style="{
          width: stileRowProdotti.width,
          'margin-bottom': stileRowProdotti.marginBottom,
        }"
        v-for="(item, i) in getDati"
        :key="i"
        class="d-flex child-flex ml-10 mt-10"
      >
        <div class="card" style="height: 815px">
          <div
            :style="{
              'margin-bottom': stileCardProdotti.marginBottom,
              'margin-left': stileCardProdotti.marginLeft,
              height: stileCardProdotti.height,
            }"
            class="face face1"
          >
            <div class="content">
              <img
                v-on:click="apri(item)"
                :style="{
                  'min-width': stileImgProdotto.minWidth,
                  height: stileImgProdotto.height,
                  cursor: stileImgProdotto.cursor,
                  'background-color': stileImgProdotto.backgroundColor,
                  'margin-left': stileImgProdotto.marginLeft,
                }"
                :src="item.url"
                :lazy-src="item.url"
                aspect-ratio="1"
              />
              <v-row>
                <!-- NO 3840 -->
                <p
                  v-if="isTelefono() <= 2560"
                  class="text-h6"
                  style="margin-bottom: 1em"
                  :style="{
                    'margin-top': stileParagrafoNoManiglioneAlzante.marginTop,
                    'margin-left': stileParagrafoNoManiglioneAlzante.marginLeft,
                    width: stileParagrafoNoManiglioneAlzante.width,
                  }"
                >
                  {{ item.nomeProdotto }}
                  <v-icon v-if="isTelefono() <= 1540" style="float: right"
                    >zoom_in</v-icon
                  >
                  <img
                    v-if="item.novita == true"
                    :style="{
                      width: stileIconProdotti.width,
                      height: stileIconProdotti.height,
                      'margin-top': stileIconProdotti.marginTop,
                      'margin-left': stileIconProdotti.marginLeft,
                    }"
                    :src="require('@/assets/img/Novità.png')"
                  />
                  <img
                    v-if="item.disponibileProssimamente == true"
                    :style="{
                      width: stileIconProdotti.width,
                      height: stileIconProdotti.height,
                      'margin-top': stileIconProdotti.marginTop,
                      'margin-left': stileIconProdotti.marginLeft,
                    }"
                    :src="require('@/assets/img/DisponibileMaggio23.png')"
                  />
                  <img
                    v-if="item.restyling == true"
                    :style="{
                      width: stileIconProdotti.width,
                      height: stileIconProdotti.height,
                      'margin-top': stileIconProdotti.marginTop,
                      'margin-left': stileIconProdotti.marginLeft,
                    }"
                    :src="require('@/assets/img/Restyling.png')"
                  />
                  <v-divider style="margin-top: 0em" color="black"></v-divider>
                </p>
                <!-- SI 3840 -->
                <p
                  v-else
                  class="text-h4"
                  style="margin-bottom: 1em"
                  :style="{
                    'margin-top': stileParagrafoNoManiglioneAlzante.marginTop,
                    'margin-left': stileParagrafoNoManiglioneAlzante.marginLeft,
                    width: stileParagrafoNoManiglioneAlzante.width,
                  }"
                >
                  {{ item.nomeProdotto }}
                  <v-icon v-if="isTelefono() <= 1540" style="float: right"
                    >zoom_in</v-icon
                  >
                  <img
                    v-if="item.novita == true"
                    :style="{
                      width: stileIconProdotti.width,
                      height: stileIconProdotti.height,
                      'margin-top': stileIconProdotti.marginTop,
                      'margin-left': stileIconProdotti.marginLeft,
                    }"
                    :src="require('@/assets/img/Novità.png')"
                  />
                  <img
                    v-if="item.disponibileProssimamente == true"
                    :style="{
                      width: stileIconProdotti.width,
                      height: stileIconProdotti.height,
                      'margin-top': stileIconProdotti.marginTop,
                      'margin-left': stileIconProdotti.marginLeft,
                    }"
                    :src="require('@/assets/img/DisponibileMaggio23.png')"
                  />
                  <img
                    v-if="item.restyling == true"
                    :style="{
                      width: stileIconProdotti.width,
                      height: stileIconProdotti.height,
                      'margin-top': stileIconProdotti.marginTop,
                      'margin-left': stileIconProdotti.marginLeft,
                    }"
                    :src="require('@/assets/img/Restyling.png')"
                  />
                  <v-divider style="margin-top: 0em" color="black"></v-divider>
                </p>
                <p
                  :style="{
                    'margin-top': stileLineaNoManiglioneAlzante.marginTop,
                    'margin-left': stileLineaNoManiglioneAlzante.marginLeft,
                    'font-size': stileLineaNoManiglioneAlzante.fontSize,
                  }"
                >
                  Linea: {{ item.nome }}
                </p>
              </v-row>
            </div>
          </div>

          <!-- EXTENSION DESCRIZIONE E FINITURE -->
          <div v-if="isTelefono() < 3840" class="face face2">
            <div
              :style="{
                'min-width': stileExtationPanel.minWidth,
                height: stileExtationPanel.height,
              }"
              class="content"
            >
              <p
                :style="{
                  'margin-top': stileParagrafoDescrizione.marginTop,
                  'margin-left': stileParagrafoDescrizione.marginLeft,
                  'font-size': stileParagrafoDescrizione.fontSize,
                }"
              >
                {{ item.descrizione }}
              </p>

              <!-- FINITURE STANDARD -->
              <v-row
                :style="{
                  'min-width': stileRowFiniture.minWidth,
                  'margin-left': stileRowFiniture.marginLeft,
                  'margin-top': stileRowFiniture.marginTop,
                }"
              >
                <v-col
                  :style="{
                    'min-width': stileColFinitureImg.minWidth,
                    'margin-top': stileColFinitureImg.marginTop,
                  }"
                  style="padding: 0"
                  v-for="(item, i) in getDatiFinitureStandard(item)"
                  :key="i"
                  class="d-flex child-flex"
                  cols="2"
                >
                  <v-card elevation="0" style="max-width: 30px">
                    <v-tooltip top>
                      <template v-slot:activator="{ on, attrs }">
                        <v-img
                          v-on:click="getDettFiniture(item)"
                          v-bind="attrs"
                          v-on="on"
                          style="width: auto; height: auto; cursor: pointer"
                          :src="item.url"
                          :lazy-src="item.url"
                        >
                          <template v-slot:placeholder>
                            <v-row
                              class="fill-height ma-0"
                              align="center"
                              justify="center"
                            >
                              <v-progress-circular
                                indeterminate
                                color="black lighten-5"
                              ></v-progress-circular>
                            </v-row>
                          </template>
                        </v-img>
                      </template>
                      <span>{{ item.tipoFinitura }}</span>
                    </v-tooltip>
                  </v-card>
                </v-col>
              </v-row>

              <!-- FINITURE PVD -->
              <v-row
                :style="{
                  'min-width': stileRowFiniture.minWidth,
                  'margin-left': stileRowFiniture.marginLeft,
                  'margin-top': stileRowFiniture.marginTop,
                }"
              >
                <v-col
                  :style="{
                    'min-width': stileColFinitureImg.minWidth,
                    'margin-top': stileColFinitureImg.marginTop,
                  }"
                  style="padding: 0"
                  v-for="(item, i) in getDatiFiniturePvd(item)"
                  :key="i"
                  class="d-flex child-flex"
                  cols="2"
                >
                  <v-card elevation="0" style="max-width: 30px">
                    <v-tooltip top>
                      <template v-slot:activator="{ on, attrs }">
                        <v-img
                          v-on:click="getDettFiniture(item)"
                          v-bind="attrs"
                          v-on="on"
                          style="width: auto; height: auto; cursor: pointer"
                          :src="item.url"
                          :lazy-src="item.url"
                        >
                          <template v-slot:placeholder>
                            <v-row
                              class="fill-height ma-0"
                              align="center"
                              justify="center"
                            >
                              <v-progress-circular
                                indeterminate
                                color="black lighten-5"
                              ></v-progress-circular>
                            </v-row>
                          </template>
                        </v-img>
                      </template>
                      <span>{{ item.tipoFinitura }}</span>
                    </v-tooltip>
                  </v-card>
                </v-col>
              </v-row>

              <!-- FINITURE RETRO' -->
              <v-row
                class="mt-3"
                :style="{
                  'min-width': stileRowFiniture.minWidth,
                  'margin-left': stileRowFiniture.marginLeft,
                  'margin-top': stileRowFiniture.marginTop,
                }"
              >
                <v-col
                  :style="{
                    'min-width': stileColFinitureImg.minWidth,
                    'margin-top': stileColFinitureImg.marginTop,
                  }"
                  style="padding: 0"
                  v-for="(item, i) in getDatiFinitureRetro(item)"
                  :key="i"
                  class="d-flex child-flex"
                  cols="2"
                >
                  <v-card elevation="0" style="max-width: 30px">
                    <v-tooltip top>
                      <template v-slot:activator="{ on, attrs }">
                        <v-img
                          v-on:click="getDettFiniture(item)"
                          v-bind="attrs"
                          v-on="on"
                          style="width: auto; height: auto; cursor: pointer"
                          :src="item.url"
                          :lazy-src="item.url"
                        >
                          <template v-slot:placeholder>
                            <v-row
                              class="fill-height ma-0"
                              align="center"
                              justify="center"
                            >
                              <v-progress-circular
                                indeterminate
                                color="black lighten-5"
                              ></v-progress-circular>
                            </v-row>
                          </template>
                        </v-img>
                      </template>
                      <span>{{ item.tipoFinitura }}</span>
                    </v-tooltip>
                  </v-card>
                </v-col>
              </v-row>
            </div>
          </div>

          <div v-else class="face face3">
            <div
              :style="{
                'min-width': stileExtationPanel.minWidth,
                height: stileExtationPanel.height,
                'margin-left': stileExtationPanel.marginLeft,
              }"
              class="content"
            >
              <p
                :style="{
                  'margin-top': stileParagrafoDescrizione.marginTop,
                  'margin-left': stileParagrafoDescrizione.marginLeft,
                  'font-size': stileParagrafoDescrizione.fontSize,
                }"
              >
                {{ item.descrizione }}
              </p>

              <!-- FINITURE STANDARD -->
              <v-row
                :style="{
                  'min-width': stileRowFiniture.minWidth,
                  'margin-left': stileRowFiniture.marginLeft,
                  'margin-top': stileRowFiniture.marginTop,
                }"
              >
                <v-col
                  :style="{
                    'min-width': stileColFinitureImg.minWidth,
                    'margin-top': stileColFinitureImg.marginTop,
                  }"
                  style="padding: 0"
                  v-for="(item, i) in getDatiFinitureStandard(item)"
                  :key="i"
                  class="d-flex child-flex"
                  cols="2"
                >
                  <v-card elevation="0" style="max-width: 50px">
                    <v-tooltip top>
                      <template v-slot:activator="{ on, attrs }">
                        <v-img
                          v-on:click="getDettFiniture(item)"
                          v-bind="attrs"
                          v-on="on"
                          :style="{
                            width: stileImgFiniture.width,
                            height: stileImgFiniture.height,
                          }"
                          style="cursor: pointer"
                          :src="item.url"
                          :lazy-src="item.url"
                        >
                          <template v-slot:placeholder>
                            <v-row
                              class="fill-height ma-0"
                              align="center"
                              justify="center"
                            >
                              <v-progress-circular
                                indeterminate
                                color="black lighten-5"
                              ></v-progress-circular>
                            </v-row>
                          </template>
                        </v-img>
                      </template>
                      <span>{{ item.tipoFinitura }}</span>
                    </v-tooltip>
                  </v-card>
                </v-col>
              </v-row>

              <!-- FINITURE PVD -->
              <v-row
                :style="{
                  'min-width': stileRowFiniture.minWidth,
                  'margin-left': stileRowFiniture.marginLeft,
                  'margin-top': stileRowFiniture.marginTop,
                }"
              >
                <v-col
                  :style="{
                    'min-width': stileColFinitureImg.minWidth,
                    'margin-top': stileColFinitureImg.marginTop,
                  }"
                  style="padding: 0"
                  v-for="(item, i) in getDatiFiniturePvd(item)"
                  :key="i"
                  class="d-flex child-flex"
                  cols="2"
                >
                  <v-card elevation="0" style="max-width: 50px">
                    <v-tooltip top>
                      <template v-slot:activator="{ on, attrs }">
                        <v-img
                          v-on:click="getDettFiniture(item)"
                          v-bind="attrs"
                          v-on="on"
                          :style="{
                            width: stileImgFiniture.width,
                            height: stileImgFiniture.height,
                          }"
                          style="cursor: pointer"
                          :src="item.url"
                          :lazy-src="item.url"
                        >
                          <template v-slot:placeholder>
                            <v-row
                              class="fill-height ma-0"
                              align="center"
                              justify="center"
                            >
                              <v-progress-circular
                                indeterminate
                                color="black lighten-5"
                              ></v-progress-circular>
                            </v-row>
                          </template>
                        </v-img>
                      </template>
                      <span>{{ item.tipoFinitura }}</span>
                    </v-tooltip>
                  </v-card>
                </v-col>
              </v-row>

              <!-- FINITURE RETRO' -->
              <v-row
                class="mt-3"
                :style="{
                  'min-width': stileRowFiniture.minWidth,
                  'margin-left': stileRowFiniture.marginLeft,
                  'margin-top': stileRowFiniture.marginTop,
                }"
              >
                <v-col
                  :style="{
                    'min-width': stileColFinitureImg.minWidth,
                    'margin-top': stileColFinitureImg.marginTop,
                  }"
                  style="padding: 0"
                  v-for="(item, i) in getDatiFinitureRetro(item)"
                  :key="i"
                  class="d-flex child-flex"
                  cols="2"
                >
                  <v-card elevation="0" style="max-width: 50px">
                    <v-tooltip top>
                      <template v-slot:activator="{ on, attrs }">
                        <v-img
                          v-on:click="getDettFiniture(item)"
                          v-bind="attrs"
                          v-on="on"
                          :style="{
                            width: stileImgFiniture.width,
                            height: stileImgFiniture.height,
                          }"
                          style="cursor: pointer"
                          :src="item.url"
                          :lazy-src="item.url"
                        >
                          <template v-slot:placeholder>
                            <v-row
                              class="fill-height ma-0"
                              align="center"
                              justify="center"
                            >
                              <v-progress-circular
                                indeterminate
                                color="black lighten-5"
                              ></v-progress-circular>
                            </v-row>
                          </template>
                        </v-img>
                      </template>
                      <span>{{ item.tipoFinitura }}</span>
                    </v-tooltip>
                  </v-card>
                </v-col>
              </v-row>
            </div>
          </div>
        </div>
      </v-col>
    </v-row>

    <!-- scroll btn -->
    <scroll-top></scroll-top>
  </div>
</template>

<script lang="js">
import ScrollTop from "@/components/ricerca/ScrollTop.vue";
import { mapState } from "vuex";
import { get_prodotti, get_dettFinitura, get_composizione } from "@/api/api.prodotti.js";

import objectMenu from "@/views/JS/objectMenu.js";
import display from "@/views/JS/dimensionDisplay.js";

export default {
  components: {
    ScrollTop,
  },

  data: () => ({
    datiProdotti: [],
    datiFinitureStandard: [],
    datiFiniturePvd: [],
    datiComposizioni: [],
    datiFinitureRetro: [],

    //colore finitura
    finitura: objectMenu.methods.getFinitura(),

    //nome del menu
    //nomeMenu: { text: "Maniglioni", url: "", nomeProdotto: "Maniglioni", nomeProdotto2: "", nomeProdotto3: "" },

    //scroll pagina array che visualizza i prodotti
    pagineScorri: [],

    //pannello di espansione
    valore: false,

    //PER FILTRI TRA I MENU
    filtriMenu: objectMenu.methods.getFiltriMenuManiglioni(),

    noProdotti: null,
    loading: false,

    //stile visualizzazione display devices
    stileRowProdotti: {},
    stileCardProdotti: {},
    stileImgProdotto: {},
    stileParagrafoNoManiglioneAlzante: {},
    stileIconProdotti: {},
    stileLineaNoManiglioneAlzante: {},
    stileRowFiniture: {},
    stileColFinitureImg: {},
    stileImgFiniture: {},
    stileExtationPanel: {},
    stileParagrafoDescrizione: {},
  }),

  methods: {
    tendinaDescrizione(item) {
      item.descrizioneTendinaProdotto = !item.descrizioneTendinaProdotto;
    },

    //carico prodotti per menu
    async caricaDati() {
      //this.nomeMenu.url = window.location.pathname;
      this.loading = true;

      this.datiProdotti = [];
      this.datiFinitureStandard = [];
      this.datiFiniturePvd = [];
      this.datiFinitureRetro = [];

      let res = await get_prodotti(this.filtriMenu);

      this.datiProdotti = res.data.listaProdotti;
      this.datiFinitureStandard = res.data.listaFinitureStandard;
      this.datiFiniturePvd = res.data.listaFiniturePvd;
      this.datiFinitureRetro = res.data.listaFinitureRetro;

      //controllo scroll page per evitare il conflitto con il component Scroll
      if (this.$store.getters["app/getScrollY"] === 0) {
        //faccio vedere solo i primi 16 elementi (ma li carico tutti --> get_prodotti)
        for (let i = 0; i < this.datiProdotti.length; i++) {
          if (i == 16) {
            break;
          } else {
            this.pagineScorri.push(this.datiProdotti[i]);
          }
        }
      } else {
        this.pagineScorri = this.datiProdotti;
      }

      if (this.datiProdotti.length == 0) {
        this.noProdotti = true;
      }

      this.loading = false;
    },

    //carico altri prodotti quando scorro
    scroll() {
      let nearBottomOfWindow =
        window.scrollY + window.innerHeight >=
        document.documentElement.offsetHeight - 300;

      if (nearBottomOfWindow == false) return;

      if (this.pagineScorri.length == 0) return;

      //12 esimo elemento di array datiProdotti
      let trovato = this.pagineScorri[this.pagineScorri.length - 1];
      let indice = 0;

      //parto dall'ultimo elemento e ne aggiungo sempre 16 alla volta (scrollo)
      for (let i = this.pagineScorri.length - 1; i < this.datiProdotti.length; i++) {
        if (this.datiProdotti[i].tipoProdotto != trovato.tipoProdotto) {
          indice = indice + 1;
          this.pagineScorri.push(this.datiProdotti[i]);
          if (indice == 16) {
            indice = 0;
            break;
          }
        }
      }
    },

    //filtro finiture 'Standard' per prodotto
    getDatiFinitureStandard(item) {
      let res = this.datiFinitureStandard;
      res = res.filter(
        (f) => f.codTestata == item.codTestata && f.nomeProdotto == item.nomeProdotto
      );

      //elimino elemento se array > di 5 finiture
      while (res.length > 5) {
        res.pop();
      }

      return res;
    },

    //filtro finiture 'Pvp' per prodotto
    getDatiFiniturePvd(item) {
      let res = this.datiFiniturePvd;
      res = res.filter(
        (f) => f.codTestata == item.codTestata && f.nomeProdotto == item.nomeProdotto
      );

      //elimino elemento se array > di 5 finiture
      while (res.length > 5) {
        res.pop();
      }

      return res;
    },

    //filtro finiture 'Retro' per prodotto
    getDatiFinitureRetro(item) {
      let res = this.datiFinitureRetro;
      res = res.filter(
        (f) => f.codTestata == item.codTestata && f.nomeProdotto == item.nomeProdotto
      );

      //elimino elemento se array > di 5 finiture
      while (res.length > 5) {
        res.pop();
      }

      return res;
    },

    //colore prodotto (finitura)
    async getDettFiniture(item) {
      let res = await get_dettFinitura(item, this.filtriMenu);
      this.finitura = res.data;
    },

    async GetComposizioni(item) {
      let res1 = await get_composizione(item);
      this.datiComposizioni = res1.data;

      //this.getDettComposizione(this.datiComposizioni[0]);
    },

    apri(item) {
      this.$store.commit("app/setNomeMenu", this.filtriMenu.text);
      //this.filtriMenu.nomeProdotto = "";
      this.$store.commit("app/setNomeRicerca", this.filtriMenu.nomeProdotto);
      item.cdCompletoProdotto = "";
      //this.$store.commit("app/setDettaglio", item);
      //this.$router.push("/dettaglioOld");
      //setto parametri per dettaglio prodotto
      let parametri =
        //"/dettaglio" +
        "?Prodotto=" +
        item.nomeProdotto +
        "&Tipo=" +
        item.tipo +
        "&Finitura=" +
        item.finiture +
        "&CodTestata=" +
        item.codTestata +
        "&CodFinitura=" +
        item.codiceFinitura +
        "&Suffisso=" +
        item.suffisso +
        "&NomeFinitura=" +
        item.tipoFinitura +
        "&Composizione=" +
        item.tipoComposizione +
        "&TipoComposizione=" +
        "";
      //vado al dettaglio prodotto
      this.$router.push("/dettaglio" + parametri);

      this.$store.commit("app/setScrollY", window.pageYOffset);
    },

    isTelefono() {
      return window.innerWidth;
      //return this.$i2kFunctions.isSmartphone();
    },

    dimensionDisplay(value) {
      return display.methods.getStiliMenuManiglioni(this, value);
    },
  },

  created() {
    this.dimensionDisplay(this.isTelefono());
    this.filtriMenu = this.$store.getters["app/getFiltriMenu"];
    window.addEventListener("scroll", this.scroll);
  },

  beforeDestroy() {
    window.removeEventListener("scroll", this.scroll);
  },

  async mounted() {
    await this.caricaDati();

    // scroll to a specific position on the page (return to menu from /dettaglio)
    if (this.$store.getters["app/getScrollY"] != 0) {
      window.scrollTo({
        top: this.$store.getters["app/getScrollY"],
        left: 0,
        behavior: "smooth",
      });
    }
  },

  computed: {
    ...mapState({
      filtroProdotti: (state) => state.app.filtroProdotti,
    }),

    getDati() {
      let res = this.pagineScorri;

      if (this.finitura.nomeProdotto != "") {
        for (let i = 0; i < res.length; i++) {
          if (res[i].nomeProdotto == this.finitura.nomeProdotto) {
            res[i].url = this.finitura.url;
          }
        }
      }

      return res;
    },
  },

  watch: {
    loading(val) {
      if (val) return this.loading;
    },
  },
};
</script>

<style scoped>
@media screen and (min-width: 960px) {
  .filters {
    flex-wrap: nowrap;
  }

  .filters--chips {
    overflow-x: auto;
    flex-wrap: wrap;
  }
}

.filters--chips {
  display: flex;
  flex: 1 1 auto;
  flex-wrap: nowrap;
  overflow-x: scroll;
}

.container .card {
  position: relative;
  margin-bottom: -5.5em;
}

.container .card .face {
  width: 300px;
  height: 200px;
  transition: 0.5s;
  margin-bottom: 0em;
}

.container .card .face.face1 {
  position: relative;
  background: white;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;
  transform: translateY(30px);
  margin-bottom: 0em;
}

.container .card:hover .face.face1 {
  background: white;
  transform: translateY(0);
}

.container .card .face.face1 .content {
  opacity: 10;
  transition: 0.5s;
}

.container .card:hover .face.face1 .content {
  opacity: 1;
}

.container .card .face.face1 .content img {
  max-width: 100%;
}

.container .card .face.face2 {
  position: relative;
  background: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
  margin-top: 0em;
  box-sizing: border-box;
  box-shadow: 0 20px 50px rgba(255, 255, 255, alpha);
  transform: translateY(-200px);
}

.container .card .face.face3 {
  position: relative;
  background: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
  margin-top: 0em;
  box-sizing: border-box;
  box-shadow: 0 20px 50px rgba(255, 255, 255, alpha);
  transform: translateY(-270px);
}

.container .card:hover .face.face2 {
  transform: translateY(0);
}

.container .card:hover .face.face3 {
  transform: translateY(0);
}

.container .card .face.face2 .content p {
  margin: 0;
  padding: 0;
}

.container .card .face.face3 .content p {
  margin: 0;
  padding: 0;
}
</style>
