<template>
  <v-dialog scrollable v-model="dialogComputed" width="80%">
    <v-card tile color="white">
      <v-toolbar style="z-index: 10" color="black">
        <v-toolbar-title style="color: white">{{ nomeDelProdotto }} </v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn style="color: white" icon @click="closeDialog()">
          <v-icon>close</v-icon>
        </v-btn>
      </v-toolbar>
      <!-- <v-card-text> -->
      <!-- :style="[
          isTelefono() > 460
            ? { height: '1600px', width: '100%' }
            : { height: '600px', width: '100%' },
        ]"   -->
      <v-img
        :src="src"
        :lazy-src="src"
        aspect-ratio="1"
        class="white lighten-2 mb-5"
        style="min-height: 50%; max-width: 50%; margin-top: -10em"
      >
      </v-img>
      <!-- <div style="width: 100%; height: 100%; text-align: center">
                    <iframe v-if="src" :src="src" width="100%" height="100%">Caricamento....</iframe>
                </div> -->
      <!-- </v-card-text> -->
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    dialog: { type: Boolean },
    src: { type: String },
    nomeDelProdotto: { type: String },
  },
  data() {
    return {
      page: 1,
      pageNo: 1,
      numPages: 0,
      pdfdata: undefined,
      errors: [],
      scale: 1, // 'page-width'
      isLoading: true,
    };
  },

  methods: {
    closeDialog() {
      this.$emit("chiudiDialog");
    },

    isTelefono() {
      return window.innerWidth;
      //return this.$i2kFunctions.isSmartphone();
    },
  },

  mounted() {},

  computed: {
    dialogComputed: {
      get() {
        return this.dialog;
      },
      set(value) {
        this.closeDialog();
      },
    },
  },
};
</script>
