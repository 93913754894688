<template>
  <div style="max-width: 100%">
    <!-- FINITURE NO MANIGLIONI / ALZANTI / SCORREVOLI -->
    <v-sheet
      v-if="
        Url.Tipo != 'Maniglioni' &&
        Url.Tipo != 'Alzanti' &&
        Url.Tipo != 'Scorrevoli' &&
        finitureStandard != 0
      "
      elevation="0"
      max-width="95%"
      style="margin-top: 10px; margin-left: 0%, margin-right: 0%"
    >
      <v-slide-group show-arrows v-model="this.finitureStandard">
        <v-slide-item
          class="pa-0"
          v-for="(dettaglio, i) in getDatiFinitureTelefono()"
          :key="i"
        >
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-img
                v-on:click="getDettFiniture(dettaglio)"
                v-bind="attrs"
                v-on="on"
                style="
                  width: 90px;
                  height: 40px;
                  margin-top: 0px;
                  cursor: pointer;
                  margin-top: 0px;
                  padding: 0px;
                  padding-bottom: 0px;
                  padding-top: 0px;
                  padding-left: 0px;
                  padding-right: 0px;
                "
                :src="dettaglio.url"
                :lazy-src="dettaglio.url"
                aspect-ratio="1"
                class="ma-1"
              >
                <template v-slot:placeholder>
                  <v-row class="fill-height ma-0" align="center" justify="center">
                    <v-progress-circular
                      indeterminate
                      color="black lighten-5"
                    ></v-progress-circular>
                  </v-row>
                </template>
              </v-img>
            </template>
            <span>{{ dettaglio.tipoFinitura }}</span>
          </v-tooltip>
        </v-slide-item>
      </v-slide-group>
    </v-sheet>

    <!-- FINITURE SI MANIGLIONI / ALZANTI -->
    <v-sheet
      v-if="(Url.Tipo == 'Maniglioni' || Url.Tipo == 'Alzanti') && finitureStandard != 0"
      elevation="0"
      max-width="95%"
      style="margin-top: 0px; margin-left: 0%, margin-right: 0%;"
    >
      <v-slide-group show-arrows v-model="this.finitureStandard">
        <v-slide-item
          class="pa-0"
          v-for="(dettaglio, i) in getDatiFinitureTelefono()"
          :key="i"
        >
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-img
                v-on:click="getDettFiniture(dettaglio)"
                v-bind="attrs"
                v-on="on"
                style="
                  width: 60px;
                  height: 110px;
                  margin-top: 0px;
                  cursor: pointer;
                  margin-top: 0px;
                  padding: 0px;
                  padding-bottom: 0px;
                  padding-top: 0px;
                  padding-left: 0px;
                  padding-right: 0px;
                "
                :src="dettaglio.url"
                :lazy-src="dettaglio.url"
                aspect-ratio="1"
                class="ma-1"
              >
                <template v-slot:placeholder>
                  <v-row class="fill-height ma-0" align="center" justify="center">
                    <v-progress-circular
                      indeterminate
                      color="black lighten-5"
                    ></v-progress-circular>
                  </v-row>
                </template>
              </v-img>
            </template>
            <span>{{ dettaglio.tipoFinitura }}</span>
          </v-tooltip>
        </v-slide-item>
      </v-slide-group>
    </v-sheet>

    <!-- FINITURE SI SCORREVOLI -->
    <v-sheet
      v-if="Url.Tipo == 'Scorrevoli' && (finitureStandard != 0 || finitureRetro != 0)"
      elevation="0"
      max-width="95%"
      style="margin-top: 0px; margin-left: 0%, margin-right: 0%"
    >
      <!-- FINITURE STANDARD - PVD -->
      <v-slide-group
        v-if="Url.NomeProdotto != 'Kit Ginevra'"
        show-arrows
        v-model="this.finitureStandard"
      >
        <v-slide-item
          class="pa-0"
          v-for="(dettaglio, i) in getDatiFinitureTelefono()"
          :key="i"
        >
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-img
                v-on:click="getDettFiniture(dettaglio)"
                v-bind="attrs"
                v-on="on"
                style="
                  width: 45px;
                  height: 45px;
                  margin-top: 0px;
                  cursor: pointer;
                  margin-top: 0px;
                  padding: 0px;
                  padding-bottom: 0px;
                  padding-top: 0px;
                  padding-left: 0px;
                  padding-right: 0px;
                "
                :src="dettaglio.url"
                :lazy-src="dettaglio.url"
                aspect-ratio="1"
                class="ma-1"
              >
                <template v-slot:placeholder>
                  <v-row class="fill-height ma-0" align="center" justify="center">
                    <v-progress-circular
                      indeterminate
                      color="black lighten-5"
                    ></v-progress-circular>
                  </v-row>
                </template>
              </v-img>
            </template>
            <span>{{ dettaglio.tipoFinitura }}</span>
          </v-tooltip>
        </v-slide-item>
      </v-slide-group>

      <!-- FINITURE RETRO' -->
      <v-slide-group v-else show-arrows v-model="this.finitureRetro">
        <v-slide-item
          class="pa-0"
          v-for="(dettaglio, i) in getDatiFinitureTelefono()"
          :key="i"
        >
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-card elevation="0" style="width: 60px; height: auto">
                <v-img
                  v-on:click="getDettFiniture(dettaglio)"
                  v-bind="attrs"
                  v-on="on"
                  style="
                    width: 45px;
                    height: 45px;
                    margin-top: 0px;
                    cursor: pointer;
                    margin-top: 0px;
                    padding: 0px;
                    padding-bottom: 0px;
                    padding-top: 0px;
                    padding-left: 0px;
                    padding-right: 0px;
                  "
                  :src="dettaglio.url"
                  :lazy-src="dettaglio.url"
                  aspect-ratio="1"
                  class="ma-1"
                >
                  <template v-slot:placeholder>
                    <v-row class="fill-height ma-0" align="center" justify="center">
                      <v-progress-circular
                        indeterminate
                        color="black lighten-5"
                      ></v-progress-circular>
                    </v-row>
                  </template>
                </v-img>
              </v-card>
            </template>
            <span>{{ dettaglio.tipoFinitura }}</span>
          </v-tooltip>
        </v-slide-item>
      </v-slide-group>
    </v-sheet>
  </div>
</template>

<script>
export default {
  props: {
    Url: { type: Object, default: null },
    finitureStandard: { type: Array, default: null },
    finiturePvd: { type: Array, default: null },
    finitureRetro: { type: Array, default: null },
  },

  components: {},

  data: () => ({
    listaFinitureScorrevoli: [],
  }),

  methods: {
    //per Maniglioni - Alzanti visualizzazione telefono
    getDatiFinitureTelefono() {
      //list all finiture
      let listaTot = [];
      this.listaFinitureScorrevoli = [];

      let res = this.finitureStandard;
      let res1 = this.finiturePvd;
      let res2 = this.finitureRetro;

      //STANDARD
      if (this.Url.Tipo.substring(0, 1) == "S") {
        //controllo per Kit Scorrevoli (necessita del filtro 'Suffisso')
        res = res.filter(
          (f) =>
            f.codTestata == this.Url.CodTestata &&
            f.nomeProdotto == this.Url.NomeProdotto &&
            f.suffisso == this.Url.Suffisso
        );
      } else {
        res = res.filter(
          (f) =>
            f.codTestata == this.Url.CodTestata && f.nomeProdotto == this.Url.NomeProdotto
        );
      }

      //PVD
      if (this.Url.Tipo.substring(0, 1) == "S") {
        //controllo per Kit Scorrevoli (necessita del filtro 'Suffisso')
        res1 = res1.filter(
          (f) =>
            f.codTestata == this.Url.CodTestata &&
            f.nomeProdotto == this.Url.NomeProdotto &&
            f.suffisso == this.Url.Suffisso
        );
      } else {
        res1 = res1.filter(
          (f) =>
            f.codTestata == this.Url.CodTestata && f.nomeProdotto == this.Url.NomeProdotto
        );
      }

      //RETRO'
      if (this.Url.Tipo.substring(0, 1) == "S") {
        //controllo per Kit Scorrevoli (necessita del filtro 'Suffisso')
        res2 = res2.filter(
          (f) =>
            f.codTestata == this.Url.CodTestata &&
            f.nomeProdotto == this.Url.NomeProdotto &&
            f.suffisso == this.Url.Suffisso
        );
      } else {
        res2 = res2.filter(
          (f) =>
            f.codTestata == this.Url.CodTestata && f.nomeProdotto == this.Url.NomeProdotto
        );
      }

      //unisco tutte le liste finiture
      listaTot = res.concat(res1.concat(res2));

      return listaTot;
    },
    /* getDatiFinitureTelefono() {
          this.$emit("getDatiFinitureTelefono");
        }, */

    getDettFiniture(item) {
      this.$emit("getDettFiniture", item);
    },
  },

  created() {},

  mounted() {},

  computed: {},
};
</script>
