<template>
  <v-navigation-drawer
    :style="[isTelefono() <= 1920 ? { width: '30vh' } : { width: '28vh' }]"
    :clipped="$vuetify.breakpoint.mdAndUp"
    fixed
    app
    mobile-breakpoint="1380"
    dark
    color="white"
    v-model="leftBarVis"
  >
    <!-- #1E1E1E -->
    <!-- MENU: HOME -->
    <div v-if="isTelefono() > 768" class="ma-0 pa-0" style="height: 5vh">
      <!-- <v-list v-if="isTelefono() > 768" :value="true" dense nav>
        <template>
          <v-list-item v-for="(item, y) in filtro" class="font-weight-light black--text" :value="true" :key="y"
            :class="checkRoute(item.link) ? 'elevation-6' : ''">
            <v-list-item-content>
              <v-list-item-title :style="[
                isTelefono() <= 2560
                  ? { 'font-size': '18px', height: '20px' }
                  : { 'font-size': '21px', height: '25px' },
              ]" v-text="item.text"></v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </template>
      </v-list> -->
    </div>

    <!-- MENU: MODELLI -->
    <div
      class="ma-0 pa-0"
      :style="[
        isTelefono() > 768 && isTelefono() > 1360
          ? { 'border-right': 'solid 1px black', height: '25vh' }
          : { 'border-right': 'solid 1px black', height: '27vh' },
      ]"
    >
      <v-list :style="[isTelefono() <= 768 ? {} : { 'margin-top': '-1em' }]" dense nav>
        <template v-for="(item, i) in filtro1">
          <v-list-group
            append-icon=""
            :value="true"
            v-if="item.text == 'Modelli'"
            :key="i"
            no-action
            color="black"
            :active-class="checkRoute(item.link) ? 'black' : ''"
            :disabled="true"
          >
            <template v-slot:activator>
              <v-list-item-content class="font-weight-light">
                <v-list-item-title
                  class="pa-1"
                  :style="[
                    isTelefono() <= 1920
                      ? { 'font-size': '18px', height: '20px' }
                      : { 'font-size': '25px', height: '30px' },
                  ]"
                  :class="{ 'text-h4': isTelefono() > 2560, 'pa-2': isTelefono() > 2560 }"
                  >{{ item.text }}</v-list-item-title
                >
              </v-list-item-content>
            </template>

            <v-list-item
              style="margin-top: -1vh; margin-left: 0.5em"
              v-for="(itm, i) in item.sub"
              :key="i"
              link
              class="pl-5"
              :style="{
                backgroundColor:
                  filtriMenu.nomeProdotto == itm.text && filtriMenu.url != '/'
                    ? '#8E8E8E'
                    : 'transparent',
                color:
                  filtriMenu.nomeProdotto == itm.text && filtriMenu.url != '/'
                    ? 'white'
                    : 'black',
              }"
              @click="gotoPage(itm.link)"
            >
              <v-list-item-content :class="{ telefoni: isTelefono() <= 768 }">
                <v-list-item-title
                  v-if="isTelefono() < 3840"
                  :style="[
                    isTelefono() <= 1920
                      ? { 'font-size': '16px', height: '20px' }
                      : { 'font-size': '21px', height: '2vh', 'margin-top': '1vh' },
                  ]"
                  class="pa-1 font-weight-light"
                  :class="{ telefono: isTelefono() <= 768 }"
                >
                  {{ itm.text }}
                </v-list-item-title>

                <v-list-item-title
                  v-else
                  :style="[
                    isTelefono() > 2560
                      ? { 'font-size': '28px', height: '2vh', 'margin-top': '1.5vh' }
                      : { 'font-size': '25px', height: '2vh', 'margin-top': '1.5vh' },
                  ]"
                  class="pa-1 font-weight-light"
                >
                  {{ itm.text }}
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list-group>
        </template>
      </v-list>
    </div>

    <!-- MENU: LINEA -->
    <div
      class="ma-0 pa-0"
      :style="[
        isTelefono() > 1360 && isTelefono() > 768
          ? { 'border-right': 'solid 1px black', height: '17vh' }
          : { 'border-right': 'solid 1px black', height: '19vh' },
      ]"
    >
      <!-- style="height: 18vh" -->
      <v-list dense nav>
        <template v-for="(item, x) in filtro2">
          <v-list-group
            append-icon=""
            :value="true"
            v-if="item.text == 'Linea'"
            :key="x"
            no-action
            color="black"
            :active-class="checkRoute(item.link) ? 'white' : ''"
            :disabled="true"
          >
            <template v-slot:activator>
              <v-list-item-content class="font-weight-light">
                <v-list-item-title
                  class="pa-1"
                  :style="[
                    isTelefono() <= 1920
                      ? { 'font-size': '18px', height: '20px' }
                      : { 'font-size': '25px', height: '30px' },
                  ]"
                  :class="{ 'text-h4': isTelefono() > 2560, 'pa-2': isTelefono() > 2560 }"
                  >{{ item.text }}</v-list-item-title
                >
              </v-list-item-content>
            </template>

            <v-list-item
              color="white"
              style="margin-top: -1vh; margin-left: 0.5em"
              v-for="(itm, i) in item.sub"
              :key="i"
              link
              dense
              class="pl-5"
              :style="{
                backgroundColor:
                  filtriMenu.nomeProdotto2 == itm.text ? '#8E8E8E' : 'transparent',
                color: filtriMenu.nomeProdotto2 == itm.text ? 'white' : 'black',
              }"
              @click="gotoPage(itm.link)"
            >
              <v-list-item-content :class="{ telefoni: isTelefono() <= 768 }">
                <v-list-item-title
                  v-if="isTelefono() < 3840"
                  :style="[
                    isTelefono() <= 1920
                      ? { 'font-size': '16px', height: '20px' }
                      : { 'font-size': '21px', height: '2vh', 'margin-top': '1vh' },
                  ]"
                  class="pa-1 font-weight-light"
                  :class="{ telefono: isTelefono() <= 768 }"
                >
                  {{ itm.text }}
                </v-list-item-title>

                <v-list-item-title
                  v-else
                  :style="[
                    isTelefono() > 2560
                      ? { 'font-size': '28px', height: '2vh', 'margin-top': '1.5vh' }
                      : { 'font-size': '25px', height: '2vh', 'margin-top': '1.5vh' },
                  ]"
                  class="pa-1 font-weight-light"
                >
                  {{ itm.text }}
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list-group>
        </template>
      </v-list>
    </div>

    <!-- MENU: FINITURE -->
    <div
      class="ma-0 pa-0"
      :style="[
        isTelefono() > 1360 && isTelefono() <= 1920
          ? { 'border-right': 'solid 1px black', height: '43vh' }
          : { 'border-right': 'solid 1px black', height: '40vh' },
      ]"
    >
      <!-- STANDARD -->
      <v-list dense nav>
        <template v-for="(item, z) in filtro3">
          <v-list-group
            append-icon=""
            :value="true"
            v-if="item.text == 'Finiture Standard'"
            :key="z"
            no-action
            color="black"
            :disabled="true"
          >
            <template v-slot:activator>
              <v-list-item-content class="font-weight-light">
                <v-list-item-title
                  @click="gotoPage(item.link), setColoreMenu(item)"
                  :class="{
                    menu: item.text === colore,
                    coloreTesto: item.text != colore,
                    'text-h4': isTelefono() > 2560,
                    'pa-2': isTelefono() > 2560,
                  }"
                  class="pa-1"
                  :style="[
                    isTelefono() <= 1920
                      ? { 'font-size': '18px', height: '20px' }
                      : { 'font-size': '25px', height: '2vh' },
                  ]"
                  >{{ item.text }}</v-list-item-title
                >
              </v-list-item-content>
            </template>

            <div>
              <v-list-item-avatar
                v-for="(itm, i) in standard"
                :key="i"
                link
                @click="setFiltroFinitura(itm), gotoPageFiniture(item.link)"
                class="ma-0 pa-0 ml-1"
                style="width: 5vh; height: 5vh"
              >
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <img
                      :style="[
                        codiceFinitura != itm.codiceFinitura
                          ? { width: '5vh', height: '5vh' }
                          : { width: '6vh', height: '6vh' },
                      ]"
                      style="cursor: pointer"
                      :src="itm.url"
                      v-bind="attrs"
                      v-on="on"
                    />
                  </template>
                  <span>{{ itm.tipoFinitura }}</span>
                </v-tooltip>
              </v-list-item-avatar>
            </div>
          </v-list-group>
        </template>
      </v-list>
      <!-- PVD -->
      <v-list dense nav>
        <template v-for="(item, z) in filtro3">
          <v-list-group
            append-icon=""
            :value="true"
            v-if="item.text == 'Finiture PVD'"
            :key="z"
            no-action
            color="black"
            :disabled="true"
          >
            <template v-slot:activator>
              <v-list-item-content class="font-weight-light">
                <v-list-item-title
                  class="pa-1"
                  @click="gotoPage(item.link), setColoreMenu(item)"
                  :class="{
                    menu: item.text === colore,
                    coloreTesto: item.text != colore,
                    'text-h4': isTelefono() > 2560,
                    'pa-2': isTelefono() > 2560,
                  }"
                  :style="[
                    isTelefono() <= 1920
                      ? { 'font-size': '18px', height: '20px' }
                      : { 'font-size': '25px', height: '2vh' },
                  ]"
                  >{{ item.text }}</v-list-item-title
                >
              </v-list-item-content>
            </template>

            <div>
              <v-list-item-avatar
                v-for="(itm, i) in pvd"
                :key="i"
                link
                @click="setFiltroFinitura(itm), gotoPageFiniture(item.link)"
                class="ma-0 pa-0 ml-1"
                style="width: 5vh; height: 5vh"
              >
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <img
                      :style="[
                        codiceFinitura != itm.codiceFinitura
                          ? { width: '5vh', height: '5vh' }
                          : { width: '6vh', height: '6vh' },
                      ]"
                      style="cursor: pointer"
                      :src="itm.url"
                      v-bind="attrs"
                      v-on="on"
                    />
                  </template>
                  <span>{{ itm.tipoFinitura }}</span>
                </v-tooltip>
              </v-list-item-avatar>
            </div>
          </v-list-group>
        </template>
      </v-list>
      <!-- RETRO' -->
      <v-list dense nav>
        <template v-for="(item, z) in filtro3">
          <v-list-group
            append-icon=""
            :value="true"
            v-if="item.text == 'Finiture Retrò'"
            :key="z"
            no-action
            color="black"
            :disabled="true"
          >
            <template v-slot:activator>
              <v-list-item-content class="font-weight-light">
                <v-list-item-title
                  class="pa-1"
                  @click="gotoPage(item.link), setColoreMenu(item)"
                  :class="{
                    menu: item.text === colore,
                    coloreTesto: item.text != colore,
                    'text-h4': isTelefono() > 2560,
                    'pa-2': isTelefono() > 2560,
                  }"
                  :style="[
                    isTelefono() <= 1920
                      ? { 'font-size': '18px', height: '20px' }
                      : { 'font-size': '25px', height: '2vh' },
                  ]"
                  >{{ item.text }}</v-list-item-title
                >
              </v-list-item-content>
            </template>

            <div>
              <v-list-item-avatar
                v-for="(itm, i) in retro"
                :key="i"
                link
                @click="setFiltroFinitura(itm), gotoPageFiniture(item.link)"
                class="ma-0 pa-0 ml-1"
                style="width: 5vh; height: 5vh"
              >
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <img
                      :style="[
                        codiceFinitura != itm.codiceFinitura
                          ? { width: '5vh', height: '5vh' }
                          : { width: '6vh', height: '6vh' },
                      ]"
                      style="cursor: pointer"
                      :src="itm.url"
                      v-bind="attrs"
                      v-on="on"
                    />
                  </template>
                  <span>{{ itm.tipoFinitura }}</span>
                </v-tooltip>
              </v-list-item-avatar>
            </div>
          </v-list-group>
        </template>
      </v-list>
    </div>
  </v-navigation-drawer>
</template>

<script>
import { get_filtriFiniture } from "@/api/api.prodotti.js";

import { mapState } from "vuex";

export default {
  name: "LeftDrawer",

  data: () => ({
    admins: [
      ["Management", "mdi-account-multiple-outline"],
      ["Settings", "mdi-cog-outline"],
    ],
    cruds: [
      ["Create", "mdi-plus-outline"],
      ["Read", "mdi-file-outline"],
      ["Update", "mdi-update"],
      ["Delete", "mdi-delete"],
    ],
    leftBarVis: null,

    selectedItem: null,
    //HOME
    filtro: [{ text: "Sfoglia il Catalogo" }],
    //PRODOTTI
    filtro1: [
      {
        text: "Modelli",
        link: "/prodotti",
        sub: [
          { text: "Tutti i Modelli", link: "/prodotti/tutto" }, //text: "Tutti i Modelli"
          { text: "Maniglie", link: "/prodotti/maniglie" },
          { text: "Pomoli", link: "/prodotti/pomoli" },
          { text: "Maniglioni", link: "/prodotti/maniglioni" },
          { text: "Alzanti", link: "/prodotti/alzanti" },
          { text: "Scorrevoli", link: "/prodotti/scorrevoli" },
          //{ text: "Coordinati - Accessori", link: "/prodotti/coordinati" },
          //{ text: "Accessori", link: "/prodotti/accessori" },
        ],
      },
    ],
    //LINEA
    filtro2: [
      {
        text: "Linea",
        link: "/linea",
        sub: [
          { text: "Tutte le Linee", link: "/linea/tutte" },
          { text: "Quadra", link: "/linea/quadra" },
          { text: "Tonda", link: "/linea/tonda" },
          { text: "Retrò", link: "/linea/retro" },
        ],
      },
    ],
    //FINTITURE
    filtro3: [
      /* {
        text: "Finiture",
        link: "/finiture",
        sub: [ */
      { text: "Finiture Standard", link: "/finiture/standard" },
      { text: "Finiture PVD", link: "/finiture/pvd" },
      { text: "Finiture Retrò", link: "/finiture/retro" },
      /*  ],
     }, */
    ],

    standard: [],
    pvd: [],
    retro: [],

    //PER FILTRI TRA I MENU
    filtriMenu: {
      nomeProdotto: "",
      nomeProdotto2: "",
      nomeProdotto3: "",
      text: "",
      url: "",
      finitura: "",
      codiceFinitura: "",
    },

    //per style e filtri 'Finiture'
    colore: "",
    link: "",
    codiceFinitura: "",

    //oggetto di appoggo per filtri menu
    /* lastFilter: {
      nomeProdotto: "",
      nomeProdotto2: "",
      nomeProdotto3: "",
      text: "",
      url: "",
    }, */
  }),

  methods: {
    gotoPage(pagina) {
      console.log(pagina);
      this.$store.commit("app/setScrollY", 0);
      //setto a vuoto il prodotto cercato ogni volta che si cambia pagina
      this.$store.commit("app/setNomeRicerca", "");
      //per controllare il cambio pagina per il nome del prodotto cercato
      if (this.$store.getters["app/getBoolPage"] == true) {
        this.$store.commit("app/setBoolPage", false);
      }

      if (pagina == window.location.pathname) {
        //unset filtri Menu
        this.unsetFiltri(pagina);
      } else {
        //set filtri menu
        this.setFiltri(pagina);
      }

      //this.$store.commit("app/setFiltroMenu", pagina);
      //this.filtriMenu.nomeProdotto = this.$store.getters["app/getFiltriMenu"];
      this.$store.commit("app/setFiltriMenu", this.filtriMenu);
      if (this.filtriMenu.text == "Maniglie") {
        this.$store.commit("app/setNomePagina", "Maniglie");
      } else {
        this.$store.commit("app/setNomePagina", this.filtriMenu.text);
      }
      //this.lastFilter = this.$store.getters["app/getFiltriMenu"];
      //go to page
      this.$router.push({ path: pagina }).catch(() => {});
    },

    gotoPageFiniture(link) {
      this.link = link;
      this.$store.commit("app/setScrollY", 0);
      //setto a vuoto il prodotto cercato ogni volta che si cambia pagina
      this.$store.commit("app/setNomeRicerca", "");
      //per controllare il cambio pagina per il nome del prodotto cercato
      if (this.$store.getters["app/getBoolPage"] == true) {
        this.$store.commit("app/setBoolPage", false);
      }

      if (this.link == window.location.pathname) {
        //unset filtri Menu
        this.unsetFiltri(this.link);
      } else {
        //set filtri menu
        this.setFiltri(this.link);
      }

      //this.$store.commit("app/setFiltroMenu", pagina);
      //this.filtriMenu.nomeProdotto = this.$store.getters["app/getFiltriMenu"];
      this.$store.commit("app/setFiltriMenu", this.filtriMenu);
      if (this.filtriMenu.text == "Maniglie") {
        this.$store.commit("app/setNomePagina", "Maniglie");
      } else {
        this.$store.commit("app/setNomePagina", this.filtriMenu.text);
      }
      //this.lastFilter = this.$store.getters["app/getFiltriMenu"];
      //go to 'PageFiniture'
      this.$store.commit("app/setFinituraFiltro", this.filtriMenu.finitura);
      //setto parametro Finitura
      this.$router.push("/finiture?Finitura=" + this.filtriMenu.finitura);
    },

    //get finiture dal menu 'Finiture'
    async getMenuFiniture() {
      this.standard = [];
      this.pvd = [];
      this.retro = [];

      let res = await get_filtriFiniture();
      this.standard = res.data.menuStandard;
      this.pvd = res.data.menuPvd;
      this.retro = res.data.menuRetro;
    },

    setFiltroFinitura(item) {
      if (item.codiceFinitura != this.codiceFinitura) {
        //carico pagina con  filtro finitura (quando si cambia da una finitura selezionata ad un'altra)
        this.filtriMenu.finitura = item.tipoFinitura;
        this.filtriMenu.codiceFinitura = item.codiceFinitura;
        this.showPermanent(item); //style img finitura
      } else {
        //carico pagina senza filtro finitura
        this.codiceFinitura = "";
        var suppLink = this.link.split("/", 3);
        this.link = "/" + suppLink[1] + "/" + suppLink[2];
        this.$router.push({ path: this.link }).catch(() => {});
      }
    },

    setFiltri(pag) {
      switch (pag) {
        case "/":
          this.$store.commit("app/setFiltroMenu", "Tutti i Modelli");
          this.filtriMenu.nomeProdotto = this.$store.getters["app/getFiltroMenu"];
          this.filtriMenu.nomeProdotto2 = "";
          this.filtriMenu.nomeProdotto3 = "";
          this.filtriMenu.text = "Tutti i Modelli";
          this.filtriMenu.url = "/";
          this.filtriMenu.finitura = "";
          this.filtriMenu.codiceFinitura = "";
          this.colore = "";
          this.codiceFinitura = "";
          break;
        case "/prodotti/tutto":
          this.$store.commit("app/setFiltroMenu", "Tutti i Modelli");
          this.filtriMenu.nomeProdotto = this.$store.getters["app/getFiltroMenu"];
          this.filtriMenu.nomeProdotto2 = "";
          this.filtriMenu.nomeProdotto3 = "";
          this.filtriMenu.text = "Tutti i Modelli";
          this.filtriMenu.url = "/prodotti/tutto";
          this.filtriMenu.finitura = "";
          this.filtriMenu.codiceFinitura = "";
          this.colore = "";
          this.codiceFinitura = "";
          break;
        case "/prodotti/maniglie":
          this.$store.commit("app/setFiltroMenu", "Maniglie");
          this.filtriMenu.nomeProdotto = this.$store.getters["app/getFiltroMenu"];
          this.filtriMenu.text = "Maniglie";
          this.filtriMenu.url = "/prodotti/maniglie";
          break;
        case "/prodotti/pomoli":
          this.$store.commit("app/setFiltroMenu", "Pomoli");
          this.filtriMenu.nomeProdotto = this.$store.getters["app/getFiltroMenu"];
          this.filtriMenu.text = "Pomoli";
          this.filtriMenu.url = "/prodotti/pomoli";
          break;
        case "/prodotti/maniglioni":
          this.$store.commit("app/setFiltroMenu", "Maniglioni");
          this.filtriMenu.nomeProdotto = this.$store.getters["app/getFiltroMenu"];
          this.filtriMenu.text = "Maniglioni";
          this.filtriMenu.url = "/prodotti/maniglioni";
          break;
        case "/prodotti/alzanti":
          this.$store.commit("app/setFiltroMenu", "Alzanti");
          this.filtriMenu.nomeProdotto = this.$store.getters["app/getFiltroMenu"];
          this.filtriMenu.text = "Alzanti";
          this.filtriMenu.url = "/prodotti/alzanti";
          break;
        case "/prodotti/scorrevoli":
          this.$store.commit("app/setFiltroMenu", "Scorrevoli");
          this.filtriMenu.nomeProdotto = this.$store.getters["app/getFiltroMenu"];
          this.filtriMenu.text = "Scorrevoli";
          this.filtriMenu.url = "/prodotti/scorrevoli";
          break;
        case "/prodotti/coordinati":
          this.$store.commit("app/setFiltroMenu", "Coordinati");
          this.filtriMenu.nomeProdotto = this.$store.getters["app/getFiltroMenu"];
          this.filtriMenu.text = "Coordinati";
          this.filtriMenu.url = "/prodotti/coordinati";
          break;
        case "/linea/quadra":
          this.$store.commit("app/setFiltroMenu", "Quadra");
          this.filtriMenu.nomeProdotto2 = this.$store.getters["app/getFiltroMenu"];
          this.filtriMenu.text = "Quadra";
          this.filtriMenu.url = "/linea/quadra";
          break;
        case "/linea/tonda":
          this.$store.commit("app/setFiltroMenu", "Tonda");
          this.filtriMenu.nomeProdotto2 = this.$store.getters["app/getFiltroMenu"];
          this.filtriMenu.text = "Tonda";
          this.filtriMenu.url = "/linea/tonda";
          break;
        case "/linea/retro":
          this.$store.commit("app/setFiltroMenu", "Retrò");
          this.filtriMenu.nomeProdotto2 = this.$store.getters["app/getFiltroMenu"];
          this.filtriMenu.text = "Retrò";
          this.filtriMenu.url = "/linea/retro";
          break;
        case "/linea/tutte":
          this.$store.commit("app/setFiltroMenu", "Tutte le Linee");
          this.filtriMenu.nomeProdotto2 = this.$store.getters["app/getFiltroMenu"];
          this.filtriMenu.text = "Tutte";
          this.filtriMenu.url = "/linea/tutte";
          break;
        case "/finiture/standard":
          this.$store.commit("app/setFiltroMenu", "Standard");
          this.filtriMenu.nomeProdotto3 = this.$store.getters["app/getFiltroMenu"];
          this.filtriMenu.text = "Standard";
          this.filtriMenu.url = "/finiture/standard";
          this.colore = "";
          break;
        case "/finiture/pvd":
          this.$store.commit("app/setFiltroMenu", "Pvd");
          this.filtriMenu.nomeProdotto3 = this.$store.getters["app/getFiltroMenu"];
          this.filtriMenu.text = "Pvd";
          this.filtriMenu.url = "/finiture/pvd";
          this.colore = "";
          break;
        case "/finiture/retro":
          this.$store.commit("app/setFiltroMenu", "Retro");
          this.filtriMenu.nomeProdotto3 = this.$store.getters["app/getFiltroMenu"];
          this.filtriMenu.text = "Retro";
          this.filtriMenu.url = "/finiture/retro";
          this.colore = "";
          break;
      }
    },

    unsetFiltri(pag) {
      if (
        this.filtriMenu.nomeProdotto != "" &&
        this.filtriMenu.nomeProdotto2 == "" &&
        this.filtriMenu.nomeProdotto3 == ""
      ) {
        //da scommentare quando filtri funzionano definitivamente
        //this.$toast.alert("Non è possibile togliere il filtro.");
      } else {
        switch (pag) {
          case "/":
            this.$store.commit("app/setFiltroMenu", "Tutti i Modelli");
            this.filtriMenu.nomeProdotto = this.$store.getters["app/getFiltroMenu"];
            this.filtriMenu.nomeProdotto2 = "";
            this.filtriMenu.nomeProdotto3 = "";
            this.filtriMenu.text = "Tutti i Modelli";
            this.filtriMenu.url = "/";
            this.filtriMenu.finitura = "";
            this.filtriMenu.codiceFinitura = "";
            this.colore = "";
            break;
          case "/prodotti/tutto":
            this.$store.commit("app/setFiltroMenu", "Tutti i Modelli");
            this.filtriMenu.nomeProdotto = this.$store.getters["app/getFiltroMenu"];
            this.filtriMenu.nomeProdotto2 = "";
            this.filtriMenu.nomeProdotto3 = "";
            this.filtriMenu.text = "Tutti i Modelli";
            this.filtriMenu.url = "/prodotti/tutto";
            this.filtriMenu.finitura = "";
            this.filtriMenu.codiceFinitura = "";
            this.colore = "";
            break;
          case "/prodotti/maniglie":
            this.$store.commit("app/setFiltroMenu", "Maniglie");
            this.filtriMenu.nomeProdotto = this.$store.getters["app/getFiltroMenu"];
            this.filtriMenu.text = "Maniglie";
            this.filtriMenu.url = "/prodotti/maniglie";
            break;
          case "/prodotti/pomoli":
            this.$store.commit("app/setFiltroMenu", "Pomoli");
            this.filtriMenu.nomeProdotto = this.$store.getters["app/getFiltroMenu"];
            this.filtriMenu.text = "Pomoli";
            this.filtriMenu.url = "/prodotti/pomoli";
            break;
          case "/prodotti/maniglioni":
            this.$store.commit("app/setFiltroMenu", "Maniglioni");
            this.filtriMenu.nomeProdotto = this.$store.getters["app/getFiltroMenu"];
            this.filtriMenu.text = "Maniglioni";
            this.filtriMenu.url = "/prodotti/maniglioni";
            break;
          case "/prodotti/alzanti":
            this.$store.commit("app/setFiltroMenu", "Alzanti");
            this.filtriMenu.nomeProdotto = this.$store.getters["app/getFiltroMenu"];
            this.filtriMenu.text = "Alzanti";
            this.filtriMenu.url = "/prodotti/alzanti";
            break;
          case "/prodotti/scorrevoli":
            this.$store.commit("app/setFiltroMenu", "Scorrevoli");
            this.filtriMenu.nomeProdotto = this.$store.getters["app/getFiltroMenu"];
            this.filtriMenu.text = "Scorrevoli";
            this.filtriMenu.url = "/prodotti/scorrevoli";
            break;
          case "/prodotti/coordinati":
            this.$store.commit("app/setFiltroMenu", "Coordinati");
            this.filtriMenu.nomeProdotto = this.$store.getters["app/getFiltroMenu"];
            this.filtriMenu.text = "Coordinati";
            this.filtriMenu.url = "/prodotti/coordinati";
            break;
          case "/linea/quadra":
            this.$store.commit("app/setFiltroMenu", "Quadra");
            this.filtriMenu.nomeProdotto2 = this.$store.getters["app/getFiltroMenu"];
            this.filtriMenu.text = "Quadra";
            this.filtriMenu.url = "/linea/quadra";
            break;
          case "/linea/tonda":
            this.$store.commit("app/setFiltroMenu", "Tonda");
            this.filtriMenu.nomeProdotto2 = this.$store.getters["app/getFiltroMenu"];
            this.filtriMenu.text = "Tonda";
            this.filtriMenu.url = "/linea/tonda";
            break;
          case "/linea/retro":
            this.$store.commit("app/setFiltroMenu", "Retrò");
            this.filtriMenu.nomeProdotto2 = this.$store.getters["app/getFiltroMenu"];
            this.filtriMenu.text = "Retrò";
            this.filtriMenu.url = "/linea/retro";
            break;
            ù;
          case "/linea/tutte":
            this.$store.commit("app/setFiltroMenu", "Tutte le Linee");
            this.filtriMenu.nomeProdotto2 = this.$store.getters["app/getFiltroMenu"];
            this.filtriMenu.text = "Tutte";
            this.filtriMenu.url = "/linea/tutte";
            break;
          case "/finiture/standard":
            this.$store.commit("app/setFiltroMenu", "Standard");
            this.filtriMenu.nomeProdotto3 = this.$store.getters["app/getFiltroMenu"];
            this.filtriMenu.text = "Standard";
            this.filtriMenu.url = "/finiture/standard";
            this.colore = "";
            this.codiceFinitura = "";
            this.filtriMenu.finitura = "";
            this.filtriMenu.codiceFinitura = "";
            break;
          case "/finiture/pvd":
            this.$store.commit("app/setFiltroMenu", "Pvd");
            this.filtriMenu.nomeProdotto3 = this.$store.getters["app/getFiltroMenu"];
            this.filtriMenu.text = "Pvd";
            this.filtriMenu.url = "/finiture/pvd";
            this.colore = "";
            this.codiceFinitura = "";
            this.filtriMenu.finitura = "";
            this.filtriMenu.codiceFinitura = "";
            break;
          case "/finiture/retro":
            this.$store.commit("app/setFiltroMenu", "Retro");
            this.filtriMenu.nomeProdotto3 = this.$store.getters["app/getFiltroMenu"];
            this.filtriMenu.text = "Retro";
            this.filtriMenu.url = "/finiture/retro";
            this.colore = "";
            this.codiceFinitura = "";
            this.filtriMenu.finitura = "";
            this.filtriMenu.codiceFinitura = "";
            break;
        }
      }
    },

    checkRoute(link) {
      return link === this.$route.path || link === this.$route.meta.pathLink;
    },

    getSubitems(pagina) {
      if (pagina == "Modelli") return this.prodotti;
    },

    gotoFilter(s) {
      this.$store.commit("app/setFiltroProdotti", s);
    },

    checkFilter(pagina, sub) {
      if (pagina == "Modelli") {
        return sub;
      }
    },

    setColoreMenu(item) {
      //per menu Standard / PVD / Retrò
      this.colore = item.text;
    },

    isTelefono() {
      return window.innerWidth;
    },

    showPermanent(item) {
      this.codiceFinitura = item.codiceFinitura;
    },
  },

  created() {
    this.filtriMenu = {
      nomeProdotto: "Tutti i Modelli",
      nomeProdotto2: "",
      nomeProdotto3: "",
      text: "Tutti i Modelli",
      url: "/",
      finitura: "",
      codiceFinitura: "",
    };
    //this.lastFilter = this.$store.getters["app/getFiltriMenu"];
    this.$store.commit("app/setFiltriMenu", this.filtriMenu);
  },

  async mounted() {
    await this.getMenuFiniture();
  },

  computed: {
    ...mapState({
      prodotti: (state) => state.app.prodotti,
      filtroProdotti: (state) => state.app.filtroProdotti,
    }),
  },

  watch: {
    colore(val) {
      if (val) {
        return this.colore;
      }
    },
  },
};
</script>

<style scoped>
.menu {
  color: red;
}
.coloreTesto {
  color: black;
}
.telefono {
  margin-top: -1vh;
}
.telefoni {
  margin-top: -2vh;
}
</style>
