<template>
    <div></div>
</template>
<script>
import { get_parametriDettProdotto } from "@/api/api.prodotti.js";

export default {

    data: () => ({}),

    async mounted() {
        var url = window.location.href.split("/");

        //chiamata api per ricavare il primo prodotto trovato con quel CodTestata
        //per settare i parametri nella pagina DettaglioProdotto.vue
        let testata = {
            CodTestata: url[4]
        }
        let item = await get_parametriDettProdotto(url[4], testata);
        item.data.cdCompletoProdotto = "";
        let parametri =
        //"/dettaglio" +
        "?Prodotto=" +
        item.data.nomeProdotto +
        "&Tipo=" +
        item.data.tipo +
        "&Finitura=" +
        item.data.finiture +
        "&CodTestata=" +
        item.data.codTestata +
        "&CodFinitura=" +
        item.data.codiceFinitura +
        "&Suffisso=" +
        item.data.suffisso +
        "&NomeFinitura=" +
        item.data.tipoFinitura +
        "&Composizione=" +
        item.data.tipoComposizione +
        "&TipoComposizione=" +
        "";
      //vado al dettaglio prodotto
      this.$router.push("/dettaglio" + parametri);
    },
};
</script>