<template>
  <!-- DISPLAY TELEFONO -->
  <div>
    <!-- PRODOTTO MANIGLIE - POMOLI -->
    <v-row
      v-if="Url.Tipo != 'Maniglioni' && Url.Tipo != 'Alzanti' && Url.Tipo != 'Scorrevoli'"
      style="width: 100%"
      class="ml-2 mt-0"
    >
      <v-col cols="9">
        <!-- v-on:click="DescrizioneProdotto(), getImgComposizione(dettaglio)" -->
        <v-img
          style="
            width: auto;
            height: 36vh;
            margin-top: 0px;
            cursor: pointer;
            padding-bottom: 0px;
            padding-top: 0px;
          "
          :src="urlImgProdotto"
          :lazy-src="urlImgProdotto"
          aspect-ratio="1"
          class="center"
        >
          <template v-slot:placeholder>
            <v-row class="fill-height ma-0" align="center" justify="center">
              <v-progress-circular
                indeterminate
                color="black lighten-5"
              ></v-progress-circular>
            </v-row>
          </template>
        </v-img>
      </v-col>

      <v-col style="z-index: 1; margin-left: -30%; margin-top: 50%" cols="3">
        <div style="min-width: 12em">
          <h4>
            {{ codiceComposizione }}
          </h4>
        </div>
        <div style="width: 14em; font-size: 12px">
          <span v-html="secondaDescrizioneProdotto"></span>
        </div>
        <div v-if="Utente != ''" style="min-width: 12em">
          <h4 v-if="Prezzo.prezzoListino">
            Prezzo Listino:
            <span style="color: #e40520">{{ Prezzo.prezzoListino }} €</span>
          </h4>
        </div>
      </v-col>
    </v-row>

    <!-- PRODOTTO SI MANIGLIONI -->
    <v-row
      v-if="Url.Tipo == 'Maniglioni'"
      style="width: 100%; margin-top: -20%"
      class="mt-0"
    >
      <v-col cols="9">
        <!-- v-on:click="DescrizioneProdotto(), getImgComposizione(dettaglio)" -->
        <v-img
          style="
            width: auto;
            height: 50vh;
            margin-top: -2em;
            margin-bottom: 0em;
            cursor: pointer;
            padding-bottom: 30px;
            padding-top: 0px;
          "
          :src="urlImgProdotto"
          :lazy-src="urlImgProdotto"
          aspect-ratio="1"
          class="center"
        >
          <template v-slot:placeholder>
            <v-row class="fill-height ma-0" align="center" justify="center">
              <v-progress-circular
                indeterminate
                color="black lighten-5"
              ></v-progress-circular>
            </v-row>
          </template>
        </v-img>
      </v-col>

      <v-col style="z-index: 10; margin-left: -30%; margin-top: 45%" cols="3">
        <div style="min-width: 12em">
          <h4>
            {{ codiceComposizione }}
          </h4>
        </div>
        <div style="width: 14em; font-size: 12px">
          <span v-html="secondaDescrizioneProdotto"></span>
        </div>
        <div v-if="Utente != ''" style="min-width: 12em">
          <h4 v-if="Prezzo.prezzoListino">
            Prezzo Listino:
            <span style="color: #e40520">{{ Prezzo.prezzoListino }} €</span>
          </h4>
        </div>
      </v-col>
    </v-row>

    <!-- PRODOTTO SI ALZANTI -->
    <v-row v-if="Url.Tipo == 'Alzanti'" style="width: 100%" class="mt-0">
      <v-col cols="9">
        <!-- v-on:click="DescrizioneProdotto(), getImgComposizione(dettaglio)" -->
        <v-img
          style="
            width: auto;
            height: 50vh;
            margin-top: -2em;
            cursor: pointer;
            padding-bottom: 0px;
            padding-top: 0px;
          "
          :src="urlImgProdotto"
          :lazy-src="urlImgProdotto"
          aspect-ratio="1"
          class="center"
        >
          <template v-slot:placeholder>
            <v-row class="fill-height ma-0" align="center" justify="center">
              <v-progress-circular
                indeterminate
                color="black lighten-5"
              ></v-progress-circular>
            </v-row>
          </template>
        </v-img>
      </v-col>

      <v-col style="z-index: 10; margin-left: -30%; margin-top: 50%" cols="3">
        <div style="min-width: 12em">
          <h4>
            {{ codiceComposizione }}
          </h4>
        </div>
        <div style="width: 14em; font-size: 12px">
          <span v-html="secondaDescrizioneProdotto"></span>
        </div>
        <div v-if="Utente != ''" style="min-width: 12em">
          <h4 v-if="Prezzo.prezzoListino">
            Prezzo Listino:
            <span style="color: #e40520">{{ Prezzo.prezzoListino }} €</span>
          </h4>
        </div>
      </v-col>
    </v-row>

    <!-- PRODOTTO SI SCORREVOLI -->
    <v-row
      v-if="Url.Tipo == 'Scorrevoli'"
      style="width: 100%; margin-top: -25%"
      class="ml-2 mt-0"
    >
      <v-col cols="9">
        <!-- v-on:click="DescrizioneProdotto(), getImgComposizione(dettaglio)" -->
        <v-img
          style="
            width: auto;
            height: auto;
            margin-top: 0px;
            cursor: pointer;
            padding-bottom: 0px;
            padding-top: 0px;
          "
          :src="urlImgProdotto"
          :lazy-src="urlImgProdotto"
          aspect-ratio="1"
          class="center"
        >
          <template v-slot:placeholder>
            <v-row class="fill-height ma-0" align="center" justify="center">
              <v-progress-circular
                indeterminate
                color="black lighten-5"
              ></v-progress-circular>
            </v-row>
          </template>
        </v-img>
      </v-col>

      <v-col style="z-index: 10; margin-left: -30%; margin-top: 55%" cols="3">
        <div style="min-width: 12em">
          <h4>
            {{ codiceComposizione }}
          </h4>
        </div>
        <div style="width: 14em; font-size: 12px">
          <span v-html="secondaDescrizioneProdotto"></span>
        </div>
        <div v-if="Utente != ''" style="min-width: 12em">
          <h4 v-if="Prezzo.prezzoListino">
            Prezzo Listino:
            <span style="color: #e40520">{{ Prezzo.prezzoListino }} €</span>
          </h4>
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { get_prezzo } from "@/api/api.prodotti.js";
import { get_datiUserInfo } from "@/api/api.user.js";

export default {
  props: {
    Url: { type: Object, default: null },
    urlImgProdotto: { type: String, default: "" },
    secondaDescrizioneProdotto: { type: String, default: "" },
    codiceComposizione: { type: String, default: "" },
  },

  components: {},

  data: () => ({
    //prezzo
    Prezzo: {},
    //utente
    Utente: {},
  }),

  methods: {
    async GetPrezzo() {
      let par = {
        UserId: this.Utente.userId,
        CdArticolo: "ECO" + this.codiceComposizione.replace(/\s/g, ""), //this.Url.CodTestata + this.Url.CodiceFinitura + this.Url.Suffisso,
      };

      let res = await get_prezzo(par);
      this.Prezzo = res.data;
      //set to decimal
      this.Prezzo.prezzoListino = this.Prezzo.prezzoListino.toFixed(2);
      this.Prezzo.prezzoScontato = this.Prezzo.prezzoScontato.toFixed(2);
    },

    async userInfo() {
      try {
        // User info
        let res = await get_datiUserInfo();
        if (res.data != null) {
          this.$store.commit("user/SET_USER", res.data); //dati utente
          this.Utente = res.data;
        }
      } catch (error) {
        console.log(error);
      }
    },
  },

  created() {},

  async mounted() {
    await this.userInfo();
    await this.GetPrezzo(); //prezzo dettaglio prodotto
  },

  watch: {
    codiceComposizione() {
      this.GetPrezzo();
    },
  },

  computed: {},
};
</script>
